import React, { Component } from "react";
import Aux from "../global/AuxComp";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Form,  Input,  } from 'reactstrap';
import { Button } from 'reactstrap';
import ApiEndpoints from "../../constants/api_endpoints";
import store from "../../store";
import { setLoader } from "../../actions/action-creators";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowOtp: false,
      isError : null,
      mobile_no : null,
      otp : null
    };
    
  }

  otpSend = async (e) => {
    e.preventDefault();
    store.dispatch(setLoader(true))
    try{
      if(this.state.mobile_no == null){
        this.setState({isError : "Please Enter mobile number"})
        return false;
      }
      var body={
        mobile : this.state.mobile_no
      }
      await ApiEndpoints.Auth.sendOtp(body);
      this.setState ({isShowOtp : true})
    } 
    catch (error) {
        console.log (error)
        this.setState({isError : "Sorry!, Not able to send OTP"})
    }
    store.dispatch(setLoader(false))
  }

  login = async (e) => {
    e.preventDefault();
    store.dispatch(setLoader(true))
    try{
      if(!this.state.mobile_no || !this.state.otp){
        store.dispatch(setLoader(false))
        this.setState({isError : "Mobile number or OTP can not be empty"})
        return false;
      } 
      var body =  {
        mobile : this.state.mobile_no,
        otp : this.state.otp
      };
      await ApiEndpoints.Auth.loginOtp(body);
      await this.props.getUser();
      if(!store.getState().user){
        this.setState({isError : "You don't have Admin access" })
      }
    }
    catch (error) {
      this.setState({isError : error.response.data.message })
    }
    store.dispatch(setLoader(false))
  }

  setNumber = (e) =>{
    this.setState({mobile_no : e.target.value})
  }

  setOtp = (e) =>{
    this.setState({otp : e.target.value})
  }
  render() {
    return (

      <div style={{padding: '0 5% 0 5%', flex: "auto"}} >
      <div  style={{minHeight: "calc(100vh - 180px)"}}> 
     
                <Row>
                    <Col className="border border-secondary mt-5 mx-auto p-5 rounded" sm="12" lg="3">

                      <Row >
                        <Col className="py-2 text-center"><h2 className="">Business Login</h2></Col>
                      </Row>
                      <form onSubmit={ this.state.isShowOtp == false ? this.otpSend : this.login}>

                      <Row className="mt-3">
                        <Col className="py-2">
                          <Input
                          id="login_mobile"
                          type="text"
                          name="number"
                          className="bg-light border-dark"
                          placeholder="Enter Mobile Number"
                          onChange={this.setNumber}/>
                        </Col>
                      </Row>
                    { this.state.isShowOtp == true ?
                      <Row className="mt-3">
                        <Col className="py-3">
                          <Input
                          id="login_otp"
                          type="text"
                          name="number"
                          placeholder="Enter otp"
                          className="bg-light border-dark "

                          onChange={this.setOtp}
                          />
                          
                        </Col>
                      </Row>
                      : 
                      ""
                    }
                     { this.state.isShowOtp == false ?
                      <Row className="my-3 ">
                        <Col className="py-2">
                          <Button type="submit" id="generate_otp" size="lg" block className="buttonred">Generate OTP</Button>
                        </Col>
                      </Row>
                     :
                      <Row className="my-3 ">
                        <Col className="py-2">
                          <Button type="submit" id="login_submit" className="buttonred" size="lg" block >Login</Button>
                        </Col>
                      </Row>
                    
                     }
                     </form>
                      { this.state.isError != null ?
                      <Row>
                        <Col>
                          <p className="">{this.state.isError}</p>
                        </Col>
                      </Row>
                      :
                      ""
                      }
                    </Col>
                </Row>
                </div>
            </div>
                
    );
  }
}

export default Login;
