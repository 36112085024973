import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import Aux from "../../global/AuxComp";
import {setSuccess, setError, setLoader } from '../../../actions/action-creators';
import store from '../../../store';
import { Button, Collapse } from 'reactstrap';
import { Card, CardContent, Chip, Icon, TablePagination } from "@material-ui/core";
import SelectList from "../../global/form/select";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import CONSTANTS from "../../../constants";
import InputFeild from "../../global/form/input";
import Popover from "reactstrap/lib/Popover";
import Endpoints from "../../../constants/api_endpoints";
import UncontrolledPopover from "reactstrap/lib/UncontrolledPopover";
import ReactDatePicker from "react-datepicker";
import { groupArrayOnKey } from "../../../actions";
import UserCard from "../user_profile/user_card"
const START_DATE = "2021-08-02";
const payoutType = {
    "GENERIC": "All Entries",
    "JOB": "Project Specific Entries",
    "REFERRAL": "Referral Entries",
}
class CompanyCreate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          isCreatePayout: false,
          jobList : [],
          payouts: [],
          isOpenPayoutData: null,
          payoutData: [],
          reviewData: [],
          payoutPageNumber: 0,
          payoutDataPageNumber: 0,
          reviewPayoutPageNumber: 0,
          isOpenPayoutDataTransaction: null,
          payoutDataTransactions: [],
          sortPayoutData: [],
          sortPayoutReview: [],
          reviewPayoutSearch: "",
          payoutDataSearch: "",
          isConfirm: false
        }
    }

    componentDidMount(){
        this.getJob();
        this.fetchPayouts();
    }
    
    getJob = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
            jobList = jobList
                .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
                .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
            
            await this.setState({ jobList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchPayouts =  async () => {
        store.dispatch(setLoader(true))
        try {
            var param = {
                entity_type : Object.keys(payoutType),
                // entity_id : this.props.job.job_id
            }
            var resp = await Endpoints.JobTaskPayment.fetchPayouts(param);
            this.setState({payouts : resp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    togglePayoutDetail = async (payout_id) => {
        let { isOpenPayoutData } = this.state;
        if(isOpenPayoutData !== payout_id){
            store.dispatch(setLoader(true))
            try{
                let resp = await ApiEndpoints.JobTaskPayment.fetchPayoutData({payout_id});
                this.setState({isOpenPayoutData : payout_id, payoutData : resp.data.data, sortPayoutData: []})
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false))
        }
        else{
            this.setState({isOpenPayoutData : null, payoutData : []})
        }
    }


    retryPayout = async (payout_id) => {
        if(!window.confirm("Are you sure?")) return;
        store.dispatch(setLoader(true))
        let { payouts } = this.state;
        try{
            let payoutDataResp = await ApiEndpoints.JobTaskPayment.fetchPayoutData({payout_id});
            var failedPayoutData = payoutDataResp.data.data.filter(o => ["BANK_DETAIL_MISSING", "ERROR"].includes(o.status))
            await ApiEndpoints.JobTaskPayment.retryPayoutData({payout_data_id: failedPayoutData.map(o => o.payout_data_id)});
            payouts = payouts.map(data => {
                if(data.payout_id === payout_id) data.failed_count = data.failed_count - failedPayoutData.length;
                return data;
            });
            this.setState({payouts, isOpenPayoutData: false, payoutData: []});
            store.dispatch(setSuccess({show: true, message: "Retry Payout request submitted successfully"}));
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    retryPayoutData = async (payout_data_id) => {
        if(!window.confirm("Are you sure?")) return;
        store.dispatch(setLoader(true))
        let { payoutData } = this.state;
        try{
            var resp = await ApiEndpoints.JobTaskPayment.retryPayoutData({payout_data_id});
            payoutData = payoutData.map(data => {
                if(data.payout_data_id === payout_data_id) {
                    data = {...data, ...(resp.data.data || {})};
                }
                return data;
            });
            this.setState({payoutData});
            store.dispatch(setSuccess({show: true, message: "Retry Payout request submitted successfully"}));
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
        
    }

    sortPayoutData = (key) => {
        let { sortPayoutData, payoutData } = this.state;
        var order = sortPayoutData[0] === key && sortPayoutData[1] === "asc" ? "desc" : "asc";
        payoutData = payoutData.sort((a, b) => {
            var v1 = key === "first_name" ? (a.User.first_name || "").toLowerCase() : a[key];
            var v2 = key === "first_name" ? (b.User.first_name || "").toLowerCase() : b[key];
            return (order === "asc" ? v1 > v2 : v1 < v2) ? 1 : -1;
        });
        this.setState({payoutData, sortPayoutData: [key, order]});
    }

    onChangePayoutReview = (k, v) => {
        let { isCreatePayout } = this.state;
        isCreatePayout[k] = v;
        isCreatePayout.payable_data = [];
        isCreatePayout.isReviewed = false;
        this.setState({isCreatePayout});
    }

    reviewPayment = async (e) => {
        let { isCreatePayout } = this.state;
        store.dispatch(setLoader(true))
        try{
            let params = {
                start_date : moment(isCreatePayout.start_date || START_DATE).startOf("day").toDate(),
                end_date : moment(isCreatePayout.end_date || moment()).endOf("day").toDate(),
                job_id : isCreatePayout.job_id,
                entity_type : isCreatePayout.payoutType,
            }
            if(isCreatePayout.is_include_terminated) params.is_include_terminated = 1;

            let resp = await ApiEndpoints.JobTaskPayment.fetchPayoutDueAmount(params);
            var payable_data = resp.data.data;
            payable_data = payable_data.sort((a, b) => (a.isAddedPAN === b.isAddedPAN) ? 0 : a.isAddedPAN ? 1 : -1 );
            payable_data = payable_data.map(obj => {
                if(!obj.isAddedPAN && obj.data.filter(trans => !trans.remark.toLowerCase().includes("refer")).length) obj.isSelected = false;
                    else obj.isSelected = true; 
                return obj;
            });
            isCreatePayout.payable_data = payable_data;
            isCreatePayout.isReviewed = true;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
        this.setState({isCreatePayout});
    }
    
    
    onReviewTransactionSelect = (e, userId) => {
        let { isCreatePayout } = this.state;
        // isCreatePayout.payable_data[i].isSelected = !!e.target.checked;
        isCreatePayout.payable_data = isCreatePayout.payable_data.map(transaction => {
            if(transaction.user_id === userId) transaction.isSelected = e.target.checked;
            return transaction;
        })
        this.setState({isCreatePayout});
    }

    sortPayoutReview = (key) => {
        let { sortPayoutReview, isCreatePayout } = this.state;
        var order = sortPayoutReview[0] === key && sortPayoutReview[1] === "asc" ? "desc" : "asc";
        isCreatePayout.payable_data = isCreatePayout.payable_data.sort((a, b) => {
            var v1 = key === "first_name" ? a.User.first_name : a[key];
            var v2 = key === "first_name" ? b.User.first_name : b[key];
            return (order === "asc" ? v1 > v2 : v1 < v2) ? 1 : -1;
        });
        if(key === "first_name") isCreatePayout.payable_data = this.setUserWithoutBankOnTop(isCreatePayout.payable_data);
        this.setState({isCreatePayout, sortPayoutReview: [key, order]});
    }


    payoutInitiate = async (e) => {
        var pass = prompt("Enter the password to initiate payment:");
        if(!pass) return false;
        let { payable_data, start_date, end_date, payout_title, is_include_terminated, job_id, payoutType} = this.state.isCreatePayout;
        store.dispatch(setLoader(true))
        try{
            if(!payout_title) throw new Error("Payout Title required!")
            let selectedTransactionIds = payable_data.filter(t => t.isSelected).reduce((prev, curr) => {prev = prev.concat(curr.data.map(o => o.transaction_id)); return prev;}, [])
            let params = {
                start_date : moment(start_date || START_DATE).startOf("day").toDate(),
                end_date : moment(end_date || moment()).endOf("day").toDate(),
                job_id,
                payout_title,
                passcode: pass,
                entity_type : payoutType,
                transaction_id: selectedTransactionIds,
                is_include_terminated,
            }
            await ApiEndpoints.JobTaskPayment.payoutInit(params);
            this.fetchPayouts();
            this.setState({isCreatePayout : false, payoutPageNumber: 0});
            store.dispatch(setSuccess({show: true, message: "Payout request submitted successfully"}));
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    changeReviewView = async (viewType) => {
        let { isCreatePayout } = this.state;
        isCreatePayout.reviewView = viewType;
        if(!isCreatePayout.reviewDataByEntity){
            let allTransactionsByEntity = (isCreatePayout.payable_data || []).reduce((prev, curr) => {
                curr.data.map(trans => {
                    let key = ["JOB", "JOB_TASK"].includes(trans.UserWalletLogs[0].entity_type) ? trans.UserWalletLogs[0].entity_type + "::" + trans.UserWalletLogs[0].entity_id : trans.UserWalletLogs[0].entity_type;
                    if(!prev[key]) prev[key] = {user_id: [], amount: 0};
                    prev[key].amount = trans.type = 1 ? prev[key].amount + Number(trans.amount) : prev[key].amount - trans.amount;
                    if(prev[key].user_id.indexOf(trans.user_id) === -1) prev[key].user_id.push(trans.user_id);
                });
                return prev;
            }, {});
            allTransactionsByEntity = Object.keys(allTransactionsByEntity).map(transTypeKey => {
                return {
                    entity_type : transTypeKey.split("::")[0],
                    entity_id : transTypeKey.split("::")[1],
                    userCount: allTransactionsByEntity[transTypeKey].user_id.length,
                    amount: allTransactionsByEntity[transTypeKey].amount
                }
            });
            let jobTaskIds = allTransactionsByEntity.filter(o => o.entity_type === "JOB_TASK").map(o => o.entity_id);
            if(jobTaskIds.length){
                let jobTaskResp = await ApiEndpoints.JobTask.fetchBasic({job_task_id: jobTaskIds});
                jobTaskResp = jobTaskResp.data.data.reduce((prev, curr) => { prev[curr.job_task_id] = curr.job_id; return prev; }, {});
                allTransactionsByEntity = allTransactionsByEntity.map(trans => {
                    if(trans.entity_type === "JOB_TASK") trans.job_id = jobTaskResp[trans.entity_id];
                    return trans;
                })
            }
            isCreatePayout.reviewDataByEntity = allTransactionsByEntity;
            this.setState({ isCreatePayout })
        }
        this.setState({ viewType });
    }


    render() {
        let { jobList, isCreatePayout, payouts, isOpenPayoutData, payoutData, payoutDataTransactions, isOpenPayoutDataTransaction } = this.state;
        let { payoutPageNumber, payoutDataPageNumber, sortPayoutData, reviewPayoutPageNumber, sortPayoutReview, reviewPayoutSearch, payoutDataSearch } = this.state;
        let reviewData = isCreatePayout.payable_data || [];
        let reviewDataByEntity = isCreatePayout.reviewDataByEntity || [];
        let totalReviewAmount = reviewData.reduce((prev, transaction) => transaction.isSelected ? prev + transaction.amount : prev, 0);
        reviewData = reviewData.filter(transaction =>  reviewPayoutSearch ? ((transaction.User.first_name || "").toLowerCase().includes(reviewPayoutSearch.toLowerCase()) || ("PMW"+transaction.User.id).toLowerCase().includes(reviewPayoutSearch.toLowerCase())) : true)
        payoutData = payoutData.filter(transaction =>  payoutDataSearch ? ((transaction.User.first_name || "").toLowerCase().includes(payoutDataSearch.toLowerCase()) || ("PMW"+transaction.User.id).toLowerCase().includes(payoutDataSearch.toLowerCase())) : true)
        let jobById = groupArrayOnKey(jobList, "job_id", true);
        return (
          <>
            <Button onClick={() => this.setState({isCreatePayout : isCreatePayout ? false : {}})} className="buttonred m-2">Add Request</Button>
            
            <Collapse className="card p-3" isOpen={!!isCreatePayout}>
                <Row>
                    <Col xs={6}>
                        <SelectList placeholder="Select* " value={isCreatePayout.payoutType} onChange ={(e) => this.onChangePayoutReview("payoutType", e.target.value)} >
                            { Object.keys(payoutType).map(type =>  <option value={type}>{payoutType[type]}</option> ) }
                        </SelectList>
                    </Col>
                    { isCreatePayout.payoutType === "JOB" &&
                    <Col xs={6}>
                        <SelectList search={true} placeholder="Select Project" value={isCreatePayout.job_id} onChange ={(e) => this.onChangePayoutReview("job_id", e.target.value)} >
                            { jobList.map(job => 
                            <option key={job.job_id} value={job.job_id}> 
                                {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                            </option>)}
                        </SelectList>
                    </Col>
                    }
                </Row>
                <Row>
                    <Col className="mt-3">
                        <ReactDatePicker
                            selected={isCreatePayout ? isCreatePayout.start_date : null}
                            onChange={date => this.onChangePayoutReview("start_date", date)}
                            isClearable
                            dateFormat='dd-MMM-yyyy'
                            id="payout_start_date"
                            maxDate={isCreatePayout ? isCreatePayout.end_date : null}
                            placeholderText="Start Date"
                        />
                    </Col>
                    <Col className="mt-3">
                        <ReactDatePicker
                            minDate={isCreatePayout ? isCreatePayout.start_date : null}
                            selected={isCreatePayout ? isCreatePayout.end_date : null}
                            onChange={date => this.onChangePayoutReview("end_date", date)}
                            isClearable
                            dateFormat='dd-MMM-yyyy'
                            id="payout_end_date"
                            placeholderText="End Date"
                        />
                    </Col>
                </Row>
                <Row className="my-3" >
                    <Col><input type="checkbox" checked={!!isCreatePayout.is_include_terminated} onChange={(e) => this.onChangePayoutReview("is_include_terminated", e.target.checked)} /> Is Include Terminated Partners ?</Col>
                </Row>
                <Row><Col className="text-center"><Button onClick={this.reviewPayment} className="buttonred m-2">Review</Button></Col></Row>

                {
                isCreatePayout.isReviewed &&

                <Aux>
                    <Row className="mt-2">
                        <Col><div className={"text-center shadow-sm clickable small border " + (!isCreatePayout.reviewView ? "bg-light" : "")} onClick={() => this.changeReviewView(0)}>Partner View</div></Col>
                        <Col><div className={"text-center shadow-sm clickable small border " + (!!isCreatePayout.reviewView ? "bg-light" : "")} onClick={() => this.changeReviewView(1)}>Entities View</div></Col>
                    </Row>
                    {!isCreatePayout.reviewView ? 
                        <>
                            <Row className="border-bottom border-top py-2 mt-2 bg-light font-weight-bold align-items-center">
                                <Col xs={1}>#</Col>
                                <Col xs={1}></Col>
                                <Col xs={7} className="d-flex align-items-center justify-content-between">
                                    <span>Partner</span>
                                    <div style={{width: "50%"}}><InputFeild style={{fontSize: 10}} className="float-right" icon="search" onChange={ (e) => this.setState({reviewPayoutSearch :  e.target.value, reviewPayoutPageNumber: 0}) }  placeholder="Search Partner" /></div>
                                    <Icon onClick={() => this.sortPayoutReview("first_name")} style={{cursor: "pointer", color: sortPayoutReview[0] === "first_name" ? "#000":"#ccc", transform: `rotate(${sortPayoutReview[0] === "first_name" && sortPayoutReview[1] === "desc" ? 0:180}deg)`, fontSize: 18}}>sort</Icon>
                                </Col>
                                <Col xs={3}>Amount <Icon onClick={() => this.sortPayoutReview("amount")} style={{cursor: "pointer", color: sortPayoutReview[0] === "amount" ? "#000":"#ccc", transform: `rotate(${sortPayoutReview[0] === "amount" && sortPayoutReview[1] === "desc" ? 0:180}deg)`, float: "right", fontSize: 18}}>sort</Icon></Col>
                            </Row>
                            { reviewData.length ?
                                reviewData.slice(reviewPayoutPageNumber*20, reviewPayoutPageNumber*20+20).map((transaction, i) => {
                                i = i + (reviewPayoutPageNumber * 20);
                                return <Row key={i} className="py-1 border-bottom align-items-center">
                                    <Col xs={1}>{i+1}</Col>
                                    <Col xs={1}><input checked={transaction.isSelected} onChange={(e) => this.onReviewTransactionSelect(e, transaction.user_id)} type="checkbox" /> </Col>
                                    <Col xs={7} className="d-flex">
                                        <UserCard data={transaction.User} />
                                        {!transaction.isAddedPAN && <Icon className="align-self-center ml-2 text-danger" fontSize="small" title="PAN Detail Not Added">credit_card_off</Icon>}
                                    </Col>
                                    <Col xs={3}><abbr title="Transaction Details"  id={`review_transaction_info_${transaction.user_id}`}>{transaction.amount}</abbr>
                                        <UncontrolledPopover style={{maxHeight: 500, overflowY: "scroll"}} trigger="legacy" placement="bottom" target={`review_transaction_info_${transaction.user_id}`}>
                                            <div className="p-2">
                                                {transaction.data.map((detail, i) =>  <p key={i}><b>{i+1}. </b> {detail.type === 1 ? "Cr" : "Dr"} {detail.amount} - {moment(detail.created_on).utcOffset("+05:30").format("DD/MM/YYYY, ha")} - {detail.reason}</p>)}
                                            </div>
                                        </UncontrolledPopover>
                                    </Col>
                                </Row>
                                })
                                :
                                <Row className="py-1">
                                    <Col className="text-center text-muted"><em>Not found Pending Wallet Transactions</em></Col>
                                </Row>
                            }
                            {reviewData.length > 20 && 
                            <TablePagination rowsPerPageOptions={[20]} rowsPerPage={20} component="div" count={reviewData.length} page={this.state.reviewPayoutPageNumber}
                                onChangePage={ (e, page) => this.setState({reviewPayoutPageNumber : page}) }
                            />}
                        </>
                        :
                        <>
                            <Row className="border-bottom border-top py-2 mt-2 bg-light font-weight-bold align-items-center">
                                <Col xs={1}>#</Col>
                                <Col xs={8}>Entity Name</Col>
                                <Col xs={3}>Amount</Col>
                            </Row>
                            { reviewDataByEntity.length ?
                                reviewDataByEntity.map((transaction, i) => {
                                return <Row key={i} className="py-1 border-bottom align-items-center">
                                    <Col xs={1}>{i+1}</Col>
                                    <Col xs={1}>
                                        {transaction.job_id && jobById[transaction.job_id] && <img className="rounded-circle ml-2" src={jobById[transaction.job_id].Employer.logo_key} alt="" style={{width: 40, padding: 5 }} />}
                                    </Col>
                                    <Col xs={7}>
                                        {transaction.job_id && jobById[transaction.job_id] ? jobById[transaction.job_id].Employer.employer_name + "-" + jobById[transaction.job_id].title : transaction.entity_type + (parseInt(transaction.entity_id) ? " - " + transaction.entity_id : "")}
                                    </Col>
                                    <Col xs={3}>
                                        <abbr>{transaction.amount}</abbr>
                                    </Col>
                                </Row>
                                })
                                :
                                <Row className="py-1">
                                    <Col className="text-center text-muted"><em>Not found Pending Wallet Transactions</em></Col>
                                </Row>
                            }
                        </>
                    }
                    <Row className="my-2 pt-2 border-top">
                        {isCreatePayout.isSured && <Col xs={12} className="my-2">
                            <InputFeild value={isCreatePayout.payout_title || ""} onChange={(e) => {isCreatePayout.payout_title = e.target.value; this.setState({isCreatePayout})}} placeholder="Enter Payout title" />
                        </Col>}
                        {!!reviewData.length &&
                            <Col xs={8}>
                                <h6>Total Amount : {totalReviewAmount}</h6>
                                <em className="text-danger">* Rs. {totalReviewAmount} will be credit to {reviewData.filter(o => o.isSelected).length} Partners for {moment(isCreatePayout.start_date || START_DATE).utcOffset("+05:30").format("Do MMM'YY")} - {moment(isCreatePayout.end_date || moment()).utcOffset("+05:30").format("Do MMM'YY")}</em>
                            </Col>
                        }
                        <Col className="text-right">
                            {
                            !isCreatePayout.isSured ? 
                            <Aux>
                                <Button onClick={() => { this.setState({isCreatePayout : false})}} className="mx-2 py-2" size="sm" style={{fontSize: 12}} color="secondary">Cancel</Button>
                                <Button disabled={!reviewData.length} onClick={() => {isCreatePayout.isSured = true; this.setState({isCreatePayout})}} className="buttonred py-2">Confirm</Button>
                            </Aux>
                            :
                            <Aux>
                                <Button onClick={() => {isCreatePayout.isSured = false; this.setState({isCreatePayout})}} className="mx-2 py-2" size="sm" style={{fontSize: 12}} color="secondary">Cancel</Button>
                                <Button size="sm" onClick={this.payoutInitiate} className="buttonred py-2">Submit Request</Button>
                            </Aux>
                            }
                        </Col>
                    </Row>
                </Aux>
                }
            </Collapse>



            <Row className="bg-light border-bottom border-top font-weight-bold py-2 mt-4 align-items-center ">
                        <Col style={{maxWidth: 40}}>#</Col>
                        <Col xs={3}>Title</Col>
                        <Col xs={3} className="text-center">Date</Col>
                        <Col className="text-center">Total Amount</Col>
                        <Col className="text-center">Total Payouts</Col>
                        <Col className="text-center">Success Payouts</Col>
                        <Col className="text-center">Status</Col>
                    </Row>
                    {payouts.length ?
                    payouts.slice(payoutPageNumber*10, payoutPageNumber*10+10).map((payout, i) => 
                    <Aux key={i}>
                        <Row className="py-2 border-bottom align-items-center">
                            <Col style={{maxWidth: 40}}>{i+1+(payoutPageNumber * 10)}</Col>
                            <Col xs={3}>
                                <span>{payout.payout_title || "-"}</span>
                                <span className="d-block text-secondary" style={{fontSize: 10}}>
                                    {payoutType[payout.entity_type]} Payout 
                                    {payout.entity_type === "JOB" && jobById[payout.entity_id] ? ": "+ jobById[payout.entity_id].Employer.employer_name + "-" + jobById[payout.entity_id].title : ""}
                                </span>
                            </Col>
                            <Col xs={3} className="text-center">
                                <span className="d-block" style={{fontSize: 12}}>{moment(payout.created_on).utcOffset("+05:30").format("Do MMM'YY")} </span>
                                <span className="text-secondary" style={{fontSize: 10}}>{moment(payout.start_date).utcOffset("+05:30").format("Do MMM'YY")} - {moment(payout.end_date).utcOffset("+05:30").format("Do MMM'YY")}</span>
                            </Col>
                            <Col className="text-center">{payout.total_amount}</Col>
                            <Col className="text-center">{payout.data_count}</Col>
                            <Col className="text-center">{payout.success_count}</Col>
                            <Col className="text-center">
                                <div style={{ cursor: "pointer"}} className="d-flex align-items-center text-center justify-content-center">
                                    {payout.data_count === payout.success_count ? 
                                    <Icon className="text-success" onClick={() => this.togglePayoutDetail(payout.payout_id)} style={{fontSize: 20}}>done_all</Icon>
                                    :
                                    <>
                                        <Icon className="text-warning" onClick={() => this.togglePayoutDetail(payout.payout_id)} style={{fontSize: 20}}>warning</Icon>
                                        {!!payout.failed_count && <><Icon onClick={() => this.retryPayout(payout.payout_id)} style={{cursor: "pointer"}} className="ml-1 align-self-center">refresh</Icon>{payout.failed_count}</>}
                                    </>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Collapse isOpen={isOpenPayoutData === payout.payout_id} className="mt-2">
                            <Card>
                                <CardContent style={{fontSize: 12}}>
                                <h5>Payout Data</h5>
                                <Row className="bg-light align-items-center border-bottom border-top font-weight-bold py-2">
                                    <Col style={{maxWidth: 30}}>#</Col>
                                    <Col xs={3} className="d-flex align-items-center justify-content-between">
                                        <span>Partner</span>
                                        <div style={{width: "50%"}}>
                                            <InputFeild style={{fontSize: 10}} className="float-right" icon="search" onChange={ (e) => this.setState({payoutDataSearch :  e.target.value, payoutDataPageNumber : 0}) }  placeholder="Search Partner" />
                                        </div>
                                        <Icon onClick={() => this.sortPayoutData("first_name")} style={{cursor: "pointer", color: sortPayoutData[0] === "first_name" ? "#000":"#ccc", transform: `rotate(${sortPayoutData[0] === "first_name" && sortPayoutData[1] === "desc" ? 0:180}deg)`, fontSize: 18}}>sort</Icon>
                                    </Col>
                                    <Col>Req Id</Col>
                                    <Col xs={2}>Amount <Icon onClick={() => this.sortPayoutData("amount")} style={{cursor: "pointer", color: sortPayoutData[0] === "amount" ? "#000":"#ccc", transform: `rotate(${sortPayoutData[0] === "amount" && sortPayoutData[1] === "desc" ? 0:180}deg)`, float: "right", fontSize: 18}}>sort</Icon></Col>
                                    <Col>Status <Icon onClick={() => this.sortPayoutData("status")} style={{cursor: "pointer", color: sortPayoutData[0] === "status" ? "#000":"#ccc", transform: `rotate(${sortPayoutData[0] === "status" && sortPayoutData[1] === "desc" ? 0:180}deg)`, float: "right", fontSize: 18}}>sort</Icon></Col>
                                    <Col xs={2}>Action</Col>
                                </Row>
                                
                                {payoutData.slice(payoutDataPageNumber*20, payoutDataPageNumber*20+20).map((data, i) => {
                                return <Row key={i} className="py-1 border-bottom align-items-center">
                                        <Col style={{maxWidth: 30}}>{i+1+(payoutDataPageNumber * 20)}</Col>
                                        <Col>
                                            <UserCard data={data.User} />
                                        </Col>
                                        <Col>{data.bank_req_id}</Col>
                                        <Col xs={2}>
                                            <abbr onClick={() => this.openPayoutDataTransaction(data.payout_data_id)} title="Transaction Details" id={`payout_transactions_${data.payout_data_id}`}>{data.amount}</abbr>
                                            <Popover style={{maxHeight: 500, overflowY: "scroll"}} trigger="legacy" isOpen={isOpenPayoutDataTransaction === data.payout_data_id} toggle={() => this.setState({isOpenPayoutDataTransaction : null, payoutDataTransactions : []})} placement="bottom" target={`payout_transactions_${data.payout_data_id}`}>
                                                {isOpenPayoutDataTransaction === data.payout_data_id && 
                                                <div className="p-2">
                                                {payoutDataTransactions.map((detail, i) => 
                                                    <p key={i}><b>{i+1}. </b> {detail.type === 1 ? "Cr" : "Dr"} {detail.amount} - {moment(detail.created_on).utcOffset("+05:30").format("DD/MM/YYYY, ha")} - {detail.reason}</p>
                                                )}
                                                </div>}
                                            </Popover>
                                        </Col>
                                        <Col>
                                            <span className="d-block">{data.status_text}</span>
                                            <em>{data.bank_status_comment}</em>
                                        </Col>
                                        <Col xs={2}>
                                        {data.status === "COMPLETED" ? 
                                            <Icon className="text-success" style={{fontSize: 18}}>done_all</Icon>
                                            :
                                            (["BANK_DETAIL_MISSING", "ERROR"].indexOf(data.status) !== -1 ? 
                                            <div className="d-flex">
                                                <Icon style={{fontSize: 18}} className=" align-self-center text-danger">error</Icon>
                                                <Icon onClick={() => this.retryPayoutData(data.payout_data_id)} style={{cursor: "pointer"}} className=" align-self-center">refresh</Icon>
                                            </div>
                                            :
                                            <Icon style={{fontSize: 18}} className="text-warning">warning</Icon>
                                            )
                                        }
                                        </Col>
                                    </Row>
                                }) }
                                {payoutData.length > 20 && 
                                <TablePagination rowsPerPageOptions={[20]} rowsPerPage={20} component="div" count={payoutData.length} page={payoutDataPageNumber}
                                    onChangePage={ (e, page) => this.setState({payoutDataPageNumber : page}) }
                                />}
                                </CardContent>
                            </Card>
                        </Collapse>
                    </Aux>
                    ) 
                    :
                    <Row className="text-muted text-center py-2">
                        <Col><em>No Data</em></Col>
                    </Row>
                    }
                    {payouts.length > 10 && 
                        <TablePagination rowsPerPageOptions={[10]} rowsPerPage={10} component="div" count={payouts.length} page={payoutPageNumber}
                            onChangePage={ (e, page) => this.setState({payoutPageNumber : page}) }
                        />
                    }




            <ConfirmAction isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

          </>
        );
    }
}

export default CompanyCreate;
