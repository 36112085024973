import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import Aux from "../../global/AuxComp";
import { setSuccess, setError, setLoader } from "../../../actions/action-creators";
import store from "../../../store";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {  Chip, Icon, TablePagination } from "@material-ui/core";
import SelectList from "../../global/form/select";
import moment from "moment";
import UserCard from "../user_profile/user_card"
import InputField from "../../global/form/input";
import ReactDatePicker from "react-datepicker";
import ConfirmAction from "../../global/confirm-action";

class ServicePurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceData: [],
      isFilters: {},
      rowsPerPage : 20,
      currentPage : 0,
      isConfirm: false,
      searchUser: "",
      searchTrans: "",
      searchAccount: "",
      refundDetails: false
    };
  }

  async componentDidMount() {
    await this.setState({ isFilters: this.props.user_id ? { service_type: "MOBILE_RECHARGE" } : { service_type: "MOBILE_RECHARGE", start_date: moment().subtract(10, "days").toDate(), end_date: moment().toDate() } })
    this.fetchServiceReport();
  }

  fetchServiceReport = async () => {
    store.dispatch(setLoader(true));
    let { isFilters } = this.state;
    try {
      let body = { ...isFilters }
      if(this.props.user_id) body.user_id = this.props.user_id;

      if(body.start_date)body.start_date = moment(body.start_date).startOf("day").toDate();
      if(body.end_date) body.end_date = moment(body.end_date).endOf("day").toDate();
      let apiData = await ApiEndpoints.ServicePurchase.fetchData(body);
      let serviceData = apiData.data.data;
      await this.setState({ serviceData });
    } catch (e) {
      store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
    }
    store.dispatch(setLoader(false));
  };

  raiseDispute = async (data) => {
    store.dispatch(setLoader(true));
    let { serviceData } = this.state;
    try {
      let body = { service_purchase_id: data.service_purchase_id }
      await ApiEndpoints.ServicePurchase.raiseDispute(body);
      serviceData = serviceData.map(obj => {
        if(obj.service_purchase_id === data.service_purchase_id) obj.dispute_status = 1;
        return obj;
      })
      await this.setState({ serviceData });
    } catch (e) {
      store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
    }
    store.dispatch(setLoader(false));
  };

  render() {
    let { serviceData, isFilters, searchUser, searchTrans, searchAccount, refundDetails } = this.state;

    let { user_id: userIdProps } = this.props;
    if(searchUser) {
      searchUser = searchUser.toLowerCase().replace("pmw", "");
      serviceData = serviceData.filter(data => ((data.User.first_name || "")+ " " + data.User.id).toLowerCase().includes(searchUser));
    }

    if(searchTrans) {
      searchTrans = searchTrans.toString();
      serviceData = serviceData.filter(data => (data.service_purchase_id).toString().includes(searchTrans));
    }
    if(searchAccount) {
      searchAccount = searchAccount.toString();
      serviceData = serviceData.filter(data => (data.account_number).toString().includes(searchAccount));
    }


    return (
      <div className="p-2" style={{overflowWrap: "break-Word", fontSize: 14}}>
        <Row className="my-3 align-items-center justify-content-end">
          
          <Col xs={"auto"} className="text-right">
            <Icon fontSize="small">filter_alt</Icon>
          </Col>
          <Col xs={2}>
            <SelectList placeholder="Service Type" value={isFilters.service_type} onChange={e => {isFilters.service_type= e.target.value; this.setState({isFilters})}}>
              <option value="MOBILE_RECHARGE">Mobile Recharge</option>
              <option value="DTH_RECHARGE">DTH Recharge</option>
            </SelectList>
          </Col>
          <Col xs={3}>
              <ReactDatePicker
                  selected={isFilters.start_date || null}
                  onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                  dateFormat='dd-MMM-yyyy'
                  placeholderText="Start Date"
              />
          </Col>
          <Col xs={3}>
              <ReactDatePicker
                  selected={isFilters.end_date || null}
                  onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                  dateFormat='dd-MMM-yyyy'
                  placeholderText="End Date"
              />
          </Col>
          <Col xs={2}>
            <Button color="dark" size="sm" onClick={() => this.fetchServiceReport()}>Apply</Button>
          </Col>
        </Row>
        <Row noGutters>
          <Col xs={3} className="px-1">
            <InputField placeholder="Search" onChange={(e) => this.setState({searchUser: e.target.value, currentPage: 0})} />
          </Col>
          <Col xs={2} className="px-1">
            <InputField placeholder="Search" type="number" onChange={(e) => this.setState({searchAccount: e.target.value, currentPage: 0})} />
          </Col>
          <Col xs={1}></Col>
          <Col xs={1}>
            <InputField placeholder="Search" type="number" onChange={(e) => this.setState({searchTrans: e.target.value, currentPage: 0})} />
          </Col>
        </Row>

        {
          serviceData
          .slice(this.state.currentPage*this.state.rowsPerPage, this.state.currentPage*this.state.rowsPerPage+this.state.rowsPerPage)
          .map(obj => 
            <Row className="my-2 py-1 align-items-center shadow-sm">
              {!userIdProps && 
              <Col xs={3}>
                  <UserCard data={obj.User} />
              </Col>
              }
              <Col xs={userIdProps ? 3 : 2} className="text-center">
                <span className="">{obj.account_number}</span>
                <span className=""> | {obj.operator_code}</span>
              </Col>
              <Col xs={userIdProps ? 2 :1}>&#x20B9; {obj.amount/100}</Col>
              <Col xs={userIdProps ? 2 :1}>RTXN{obj.service_purchase_id}</Col>
              <Col xs={2}>{moment(obj.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</Col>
              <Col xs={3}>
                  {this.getStatusBadge(obj.status)}
                  {this.getRefundBadge(obj)}
                  {this.getDisputeBadge(obj)}
              </Col>
            </Row>
          )
        }

        <TablePagination labelRowsPerPage="" rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={serviceData.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentPage}
            onChangePage={ (e, page) => this.setState({currentPage : page}) }
            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
        />

        <Modal centered isOpen={!!refundDetails} toggle={() => this.setState({ refundDetails: false })}>
          <ModalHeader toggle={() => this.setState({ refundDetails: false })} >Refund Details</ModalHeader>
          <ModalBody>
            <pre>
              { JSON.stringify(JSON.parse(refundDetails || "{}"), null, 4) }
            </pre>
          </ModalBody>
        </Modal>

        <ConfirmAction isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

      </div>
    );
  }

  getStatusBadge = (status) => {
    const statusData = {
      10: { label: "Payment Not Done", style: { color: "#000", background: "#ccc" } },
      20: { label: "Payment Waiting", style: { color: "#000", background: "#ccc" } },
      30: { label: "Recharge Pending", style: { color: "#000", background: "#ffbf09" } },
      40: { label: "Recharge Failed", style: { color: "#fff", background: "#dc3545" } },
      50: { label: "Recharge Success", style: { color: "#fff", background: "#198755" } }
    }
    return <span className="d-inline-block py-1 px-2 mr-2" style={{ ...statusData[status].style, fontSize: 12, borderRadius: 6 }}>{statusData[status].label}</span>
  }
  getRefundBadge = (data) => {
    if(!data.refund_status) return <></>;
    return <span className="d-inline-block py-1 px-2 mr-2 clickable" style={{color: "#fff", background: "#0292bd", fontSize: 12, borderRadius: 6 }} onClick={() => this.setState({ refundDetails: data.refund_status_detail })}>Amount Refunded</span>
  }
  getDisputeBadge = (data) => {
    return data.dispute_status ? <span className="d-inline-block py-1 px-2 mr-2" style={{color: "#fff", background: "#dc3545", fontSize: 12, borderRadius: 6 }}>{data.dispute_status === 1 ? "Dispute Raised" : "Dispute Closed"}</span>
    :
    moment().diff(moment(data.created_on), "hours") < 96 && data.status === 50 ? <Button color="danger" size="sm" style={{fontSize: 12, borderRadius: 6}} onClick={() => this.setState({ isConfirm: () => this.raiseDispute(data) })}>Raise Dispute</Button> : <></>
  }
}

export default {
  ServicePurchase, 
  getStatusBadge: new ServicePurchase().getStatusBadge,
  getRefundBadge: new ServicePurchase().getRefundBadge,
  getDisputeBadge: new ServicePurchase().getDisputeBadge
}
