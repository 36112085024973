
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, ModalFooter } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import { Badge, ButtonGroup, Checkbox, Chip, Icon, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import UserCard from "../user_profile/user_card"
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import { Link } from "react-router-dom";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import PartnerDetail from "../user_profile";
import WhatsappChat from "../../global/whatsapp_chat";
import OutboundCallingStatus from "../../global/outbound-calling-status";


const APPLICANTS_STATES = {
    JOINED: { name: "Trained" },
    IN_TASK: { name: "In-Task" },
    TASK_1: { name: "Task >= 1" },
    TASK_5: { name: "Task >= 5" },
    TASK_10: { name: "Task >= 10" },
}

class WebinarReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attendeesList: [],
            feedback: {},
           isConfirm: false,
           isConfirmMessage: "",
           searchAttendeeText: "",
           selectedState: "JOINED",
           rowsPerPage : 20,
           currentUserPage : 0,
           currentAttendeePage: 0,

           isOpenProfileModel: false,
            callDetails: null,
            whatsappChat: false,
            isOpenNotificationModal: false,
            
        }
    }

    componentDidMount(){
        this.fetchAttendees();
    }

    fetchAttendees = async () =>{
        store.dispatch(setLoader(true));
        let eventId = this.props.location.state.event_id;
        let { selectedState } = this.state;
        try{
            let data = [];
            if(selectedState === "JOINED") {
                let resp = await ApiEndpoints.Events.fetchEventAttendees({event_id: eventId, status: 2});
                data = resp.data.data;
            } else {
                let body = {event_id: eventId};
                if(selectedState === "TASK_1") body.taskCountFilter = [1, 4]
                if(selectedState === "TASK_5") body.taskCountFilter = [5, 9]
                if(selectedState === "TASK_10") body.taskCountFilter = [10]
                let resp = await ApiEndpoints.Events.fetchEventAttendeesFunnel(body);
                data = resp.data.data;
            }
            
            this.setState({ attendeesList: data });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    selectState = async (state) => {
        await this.setState({selectedState: state});
        this.fetchAttendees();
    }   



    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : this.state.isOpenNotificationModal,
            notification_type : e.target.notification_type.value,
            origin_type : "GENERAL",
            sms : false
        } 
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }
  

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "EVENT",
                subentity_type: "EVENT_STATUS",
                entity_id: this.props.location.state.event_id,
                subentity_id: 2,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }


    showCallData = async (user) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                // status: "completed",
                user_id: user.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    render() {
        // var userRole = store.getState().user.UserRole
       
        let { attendeesList, selectedState,searchAttendeeText } = this.state;
        if(searchAttendeeText)  attendeesList = attendeesList.filter( obj =>  searchAttendeeText ? ((obj.User.first_name || "").toLowerCase().includes(searchAttendeeText.toLowerCase()) || ("PMW"+obj.User.id).toLowerCase().includes(searchAttendeeText.toLowerCase())) : true);
        return (
            <>
            <div className="m-1 ">                        
                <Row className={"mt-2 pb-2"}>
                    <Col>
                        <Link style={{color: "#000"}} to="/webinar"><Icon className="align-middle">arrow_back</Icon></Link>
                        <h5 className="ml-3 d-inline-block">Webinar Report</h5>
                    </Col>
                    <Col></Col>
                </Row>
                <Row noGutters className="border-bottom border-top w-100 border" style={{overflowX: "scroll"}}>
                    <Col md={12} xs={12}>
                        <ButtonGroup variant="text" aria-label="text button group">
                            {
                                Object.keys(APPLICANTS_STATES).map((statusKey, i) => 
                                    <Button key={i} className="px-4" onClick={() => this.selectState(statusKey)} style={{ fontWeight: "bold", fontSize: 14, color: "#000", textDecoration: "none", ...(selectedState === statusKey ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">{APPLICANTS_STATES[statusKey].name}</Button>
                                )
                            }
                        </ButtonGroup>
                    </Col>
                </Row>
                <Row className="justify-content-end">
                    <Col xs={12} lg={4} >
                        <InputFeild onChange={(e) => this.setState({searchAttendeeText: e.target.value, currentAttendeePage: 0})} icon="search" placeholder="Search by Name/PMW-Id" />
                    </Col>
                </Row>
                {
                    attendeesList.map((att, i) => {
                        return <Row key={i} className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                            <Col xs={5}><UserCard data={att.User} /></Col>
                            <Col md={2} xs={12} className="text-center my-1">
                                <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(att.User)}>{att.connected_call_counts || ""}</span> 
                                <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!att.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(att.User), isConfirmMessage : "Are you sure to connect with "+ att.User.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                                <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!att.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: att.User})}><img src={WhatsappIcon} alt="" style={{fontSize:16, cursor: "pointer"} }/></Button></Badge>
                                <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: att.user_id})}>notifications_none</Icon></Button>
                            </Col>
                        </Row>
                    })
                }
            </div>



            <Modal isOpen={this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
                <form onSubmit={this.handleNotificationSubmit}>
                    <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                        <ModalBody>
                            <label className="radio-inline">General Notification</label>
                            <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                            <InputFeild required placeholder="Title" name="title" />
                            <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                            {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                        </ModalBody>
                        <ModalFooter style={{height: "45px"}}>
                        <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                        <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
                    </ModalFooter>
                </form>
            </Modal>


            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
            {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}
            {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

            {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

        
        </>
            
            
        );
    }
}
export default WebinarReport;
