
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Col, Input, Row } from "reactstrap";
import { Button, Chip, FormControl, Select, TextField } from "@material-ui/core";
import moment from "moment";
import SelectList from "../form/select";
import CONSTANTS from "../../../constants";



class TicketTransfer extends Component {
    constructor(props) {
      super(props);
      this.state = {
       editTicket: {},
       teamMembers: [],
       taskData: [],
       rechargeData: []
      }
    }

    componentDidMount(){
      if(this.props.ticketData.ticket_team_id && this.props.transferOpts.transfer)  this.selectTeamMember(this.props.ticketData.ticket_team_id)
    }

    onChangeTicket = async (k, v, e) => {
      let { editTicket } = this.state;
      let { ticketData } = this.props;
      if(ticketData[k] === v) delete editTicket[k];
      else editTicket[k] = v;
      await this.setState({ editTicket });

      if(k === "ticket_type"){
        if([20, 30, 40].includes(v)) this.onChangeTicket("tag_type", "JOB");
        else if([60].includes(v)) this.onChangeTicket("tag_type", "SERVICE_PURCHASE");
        else this.onChangeTicket("tag_type", null);
      }
      if(k === "tag_type") this.onChangeTicket("tag_id", null);
      if(k === "ticket_team_id") {
        this.onChangeTicket("agent_user_id", null);
        this.selectTeamMember(v);
      }
      if(editTicket.tag_type === "SERVICE_PURCHASE" && k === 'tag_id' && !!v) this.fetchRechargeData(parseInt(v) === 1 ? "MOBILE_RECHARGE" : "DTH");
      if(editTicket.tag_type === "SERVICE_PURCHASE" && editTicket.ref_type === "ID" && k === "ref_id" && e) {
        await this.setState({ editTicket })
      }

      if([30, 40].includes(editTicket.ticket_type) && k === 'tag_id' && !!v) this.fetchJobFormResponse();

      this.setTicketTitle();

    }

    setTicketTitle = () => {
      let { editTicket, taskData, rechargeData } = this.state;
      if(editTicket.tag_type === "JOB" && editTicket.ref_type === "FORM_RESPONSE" && editTicket.ref_id){
        let formResponse = taskData.filter(o => o.form_response_id === parseInt(editTicket.ref_id))[0];
        editTicket.ticket_title = formResponse.name + " - " + formResponse.number;
      }
      else if(editTicket.tag_type === "JOB" && editTicket.tag_id && !editTicket.ref_id){
        let jobData = store.getState().jobList.filter(job => job.job_id === parseInt(editTicket.tag_id))[0];
        editTicket.ticket_title = jobData.Employer.employer_name + " - " + jobData.title;
      }
      else if(editTicket.tag_type === "SERVICE_PURCHASE" && editTicket.ref_id){
        let recharge = rechargeData.filter(o => o.service_purchase_id === parseInt(editTicket.ref_id))[0];
        editTicket.ticket_title = "RTXN" + recharge.service_purchase_id + " / " + recharge.account_number + " - " + recharge.amount/100;
      }
      else if (editTicket.tag_type === "SERVICE_PURCHASE"  && editTicket.tag_id && !editTicket.ref_id){
        editTicket.ticket_title = editTicket.tag_id === 1 ? "Prepaid Mobile Recharge" : "DTH Recharge"
      }
      else if(editTicket.ticket_type){
        editTicket.ticket_title = CONSTANTS.TICKET_TYPE_TEXT[editTicket.ticket_type];
      }
      this.setState({ editTicket })
    }
      
  
    editTicket = async () => {
      let { editTicket } = this.state;
      let { ticketData } = this.props;
      store.dispatch(setLoader(true));
      try{
        if(("ticket_team_id" in editTicket) || ("agent_user_id" in editTicket)){
          let transferBody = {
            ticket_id: ticketData.ticket_id,
            ticket_team_id: "ticket_team_id" in editTicket ? editTicket.ticket_team_id : ticketData.ticket_team_id,
            agent_user_id: "agent_user_id" in editTicket ? editTicket.agent_user_id : ticketData.agent_user_id,
            comment: editTicket.comment
          }
          let resp = await ApiEndpoints.Support.transferTicket(transferBody);
          this.props.addChatData(resp.data.data);
        }
        if(["ticket_type", "tag_type", "tag_id", "ref_type", "ref_id"].filter(k => k in editTicket).length || editTicket.ticket_title){
          let updateBody={ ticket_id: ticketData.ticket_id };
          if("ticket_type" in editTicket) updateBody.ticket_type = editTicket.ticket_type;
          if("tag_type" in editTicket) updateBody.tag_type = editTicket.tag_type;
          if("tag_id" in editTicket) updateBody.tag_id = editTicket.tag_id;
          if("ref_type" in editTicket) updateBody.ref_type = editTicket.ref_type;
          if("ref_id" in editTicket) updateBody.ref_id = editTicket.ref_id;
          if(editTicket.ticket_title) updateBody.ticket_title = editTicket.ticket_title;

          await ApiEndpoints.Support.updateTicket(updateBody);
        }
        this.props.updateTicketData({...ticketData, ...editTicket});
        this.props.onClose();
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    selectTeamMember = async (teamId) => {
      store.dispatch(setLoader(true));
      try{
        let resp = await ApiEndpoints.Support.fetchTeamMembers({ticket_team_id: teamId});
        this.setState({ teamMembers: resp.data.data })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    fetchRechargeData = async (type) => {
      store.dispatch(setLoader(true));
      let { editTicket } = this.state;
      let { ticketData } = this.props;
      try {
        let body = { user_id: ticketData.user_id, service_type: type  }
        let apiData = await ApiEndpoints.ServicePurchase.fetchData(body);
        let rechargeData = apiData.data.data;
        editTicket.ref_type = "ID"
        await this.setState({ rechargeData, editTicket });
      } catch (e) {
        store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
      }
      store.dispatch(setLoader(false));
    }

    fetchJobFormResponse = async (jobId) => {
      store.dispatch(setLoader(true));
      let { ticketData } = this.props;
      let { editTicket } = this.state;
      try{
        let resp = await ApiEndpoints.Support.fetchUserFormResponse({user_id:  ticketData.user_id, job_id: editTicket.tag_id, status: editTicket.ticket_type === 30 ? 0 : 2});
        editTicket.ref_type = "FORM_RESPONSE"
        this.setState({ taskData: resp.data.data, editTicket })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    render() {
      // let user = store.getState().user;
      let { editTicket, teamMembers, rechargeData, taskData } = this.state;
      let { ticketData, transferOpts } = this.props;
      let jobList = store.getState().jobList;
        return(
        <div className="h-100" style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
          <main className="h-100 p-4" style={{ overflowY: "auto" }}>
            <strong>Transfer / Edit Ticket</strong>

            {transferOpts.transfer && <>
            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Transfer To</span></Col>
              <Col>
                <FormControl variant="outlined" size="small" fullWidth>
                  <SelectList placeholder="Select Team" value={("ticket_team_id" in editTicket ? editTicket.ticket_team_id : ticketData.ticket_team_id)} onChange={e => this.onChangeTicket("ticket_team_id", parseInt(e.target.value))}>
                    {
                      Object.keys(CONSTANTS.TICKET_TEAMS).map(teamKey => <option value={teamKey}>{CONSTANTS.TICKET_TEAMS[teamKey]}</option>)
                    }
                  </SelectList>
                </FormControl>
              </Col>
            </Row>
            <Row className="align-items-center mt-4">
              <Col xs={4}></Col>
              <Col>
                <FormControl variant="outlined" size="small" fullWidth>
                  <SelectList placeholder="Select Member" value={"agent_user_id" in editTicket ? editTicket.agent_user_id : ticketData.agent_user_id} onChange={e => this.onChangeTicket("agent_user_id", e.target.value)}>
                    {
                      teamMembers.map(member => <option value={member.user_id}>{member.User.first_name}</option>)
                    }
                  </SelectList>
                </FormControl>
              </Col>
            </Row>
          </>}
          {transferOpts.edit && <>
            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Issue Type</span></Col>
              <Col>
                <FormControl variant="outlined" size="small" fullWidth>
                  <SelectList placeholder="Select Issue Type" value={"ticket_type" in editTicket ? editTicket.ticket_type : ticketData.ticket_type} onChange={e => this.onChangeTicket("ticket_type", parseInt(e.target.value))}>
                    {
                      Object.keys(CONSTANTS.TICKET_TYPE_TEXT).map(typeKey => <option value={typeKey}>{CONSTANTS.TICKET_TYPE_TEXT[typeKey]}</option>)
                    }
                  </SelectList>
                </FormControl>
              </Col>
            </Row>
            {(ticketData.ticket_type === null || ticketData.ticket_type === 0) &&
            <Row className="align-items-center mt-4">
                <Col xs={4}><span className="small">Ticket Title</span></Col>
                <Col xs={8}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <Input placeholder="Enter short title of ticket" value={editTicket.ticket_title} onChange={e => this.onChangeTicket("ticket_title", e.target.value)} />
                  </FormControl>
                </Col>
            </Row>
            }
            
            {["JOB","SERVICE_PURCHASE"].includes("tag_type" in editTicket ? editTicket.tag_type : ticketData.tag_type) &&
            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Project / Module</span></Col>
              <Col>
                <FormControl  variant="outlined" size="small" fullWidth>
                  {('tag_type' in editTicket ? editTicket.tag_type : ticketData.tag_type) === "JOB" &&
                    <SelectList search={true} placeholder="Select Project" value={"tag_id" in editTicket ? editTicket.tag_id : ticketData.tag_id} onChange={(e) => this.onChangeTicket("tag_id", parseInt(e.target.value))}>
                        {jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                            {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                          </option>
                        )}
                    </SelectList>
                  }
                  {('tag_type' in editTicket ? editTicket.tag_type : ticketData.tag_type) === "SERVICE_PURCHASE" &&
                    <SelectList search={true} placeholder="Select Service" value={"tag_id" in editTicket ? editTicket.tag_id : ticketData.tag_id} onChange={(e) => this.onChangeTicket("tag_id", parseInt(e.target.value))}>
                        <option value="1">Prepaid Mobile Recharge</option>
                        <option value="2">DTH Recharge</option>
                    </SelectList>
                  }
                </FormControl>
              </Col>
            </Row>
            }
            {('tag_type' in editTicket ? editTicket.tag_type : ticketData.tag_type) === "SERVICE_PURCHASE" && ('ref_type' in editTicket ? editTicket.ref_type : ticketData.ref_type) === "ID" &&
            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Recharge Transaction</span></Col>
              <Col>
                <FormControl  variant="outlined" size="small" fullWidth>
                    <SelectList search={true} placeholder="Select Recharge" value={"ref_id" in editTicket ? editTicket.ref_id : ticketData.ref_id} onChange={(e) => this.onChangeTicket("ref_id", parseInt(e.target.value), e)}>
                        {rechargeData.map((recharge, i) => <option key={i} value={recharge.service_purchase_id}>RTXN{recharge.service_purchase_id}-{recharge.account_number}-₹{Math.round(recharge.amount/100)}</option>)}
                    </SelectList>
                </FormControl>
              </Col>
            </Row>
            }
            {[30, 40].includes(editTicket.ticket_type) && ('ref_type' in editTicket ? editTicket.ref_type : ticketData.ref_type) === "FORM_RESPONSE" &&
            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Onboardings</span></Col>
              <Col>
                <FormControl  variant="outlined" size="small" fullWidth>
                    <SelectList search={true} placeholder="Select Onboarding" value={"ref_id" in editTicket ? editTicket.ref_id : ticketData.ref_id} onChange={(e) => this.onChangeTicket("ref_id", parseInt(e.target.value), e)}>
                      {
                      taskData.map(resp => 
                      <option value={resp.form_response_id}>
                          <span className="d-block">{resp.name} - {resp.number}</span>{" "}
                          <span className="small">({moment(resp.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")})</span>
                      </option>
                      )}                    
                    </SelectList>
                </FormControl>
              </Col>
            </Row>
            }
            {/* <Row className="align-items-center mt-4">
              <Col xs={4}>
                <span className="small">Task / Transaction</span>
              </Col>
              <Col>
                <FormControl  variant="outlined" size="small" fullWidth>
                  <SelectList disabled placeholder="Select Transaction">
                    <option>General</option>
                    <option>Training</option>
                    <option>Payments Issues</option>
                    <option>Task Verification Issue</option>
                    <option>Recharge Service Issue</option>
                  </SelectList>
                </FormControl>
              </Col>
            </Row> */}

            <span className="small d-block my-4">Description</span>
            <FormControl  variant="outlined" size="small" fullWidth>
              <TextField
                disabled={!Object.keys(editTicket).length}
                onChange={(e) => this.onChangeTicket("comment", e.target.value)}
                variant="outlined"
                placeholder="Comment Description"
                multiline
                rows={4}
              />
            </FormControl>
          </>}
          </main>
          <footer style={{flex: 0, position: "sticky", bottom: 0, background: "#fff", marginLeft: -15, marginRight: -15}} className="px-3">
            <Row className="align-items-center justify-content-end py-4 border-top mt-4">
              <Col xs={3}>
                <Button className="w-100 text-danger border-danger" variant="outlined" color="secondary" style={{borderRadius: 25}} onClick={this.props.onClose}>Cancel</Button>
              </Col>
              <Col xs={3}>
                <Button disabled={!Object.keys(editTicket).length} onClick={() => this.editTicket()} className="w-100 bg-danger" variant="contained" color="secondary" style={{borderRadius: 25}}>Submit</Button>
              </Col>
            </Row>
          </footer>

        </div>
        );
     }
  }
  export default withRouter(props => <TicketTransfer {...props} />);


