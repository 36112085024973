import moment from "moment";

export const FILTERS = {
  "GENDER": type => ({
    "type": "GENDER",
    "val": type,
    "operator": "EQUALS"
  }),
  "AGE": type => {
    let value = []
    if (type === 0) return null;
    if (type < 20) value = [moment().subtract(20, "years"), moment().subtract(18, "years")];
    else value = [moment().subtract(type + 5, "years"), moment().subtract(type, "years")]
    return {
      type: "AGE",
      val: value,
      operator: "TIME_BETWEEN"
    };
  },

  "LOCATION_STATE": type => ({
    type: "LOCATION_STATE",
    val: type,
    operator: "INCLUDE"
  }),

  "LOCATION_PINCODE": type => ({
    type: "LOCATION_PINCODE",
    val: type.split(","),
    operator: "INCLUDE"
  }),

  "VISITED_ONLY": type => ({
    type: "FIRST_TASK",
    operator: "IS_NULL",
    job_id: type
  }),

  "VISITED_ON": type => {
    type = JSON.parse(type);
    const time = {
      "Today": { operator: "TIME_AFTER", val: moment().subtract(type[0], type[1]).startOf("day") },
      "Yesterday": { operator: "TIME_BETWEEN", val: [moment().subtract(2, "days").startOf("day"), moment().subtract(1, "day").startOf("day")] },
      "This Year": { operator: "TIME_AFTER", val: moment().startOf("year") }
    };
    return {
      type: "APPLICATION_ADDED",
      ...(time[type[3]] || { operator: "HOURS_AFTER", val: type[0]*24 })
    }
  },

  "APP_INSTALLED": type => ({
    type: "APP_INSTALLED",
    operator: "NOT_NULL"
  }),

  "LAST_TASK": type => ({
    type: "LAST_TASK",
    operator: "NOT_NULL"
  }),

  "LAST_VERIFIED_TASK": type => ({
    type: "LAST_VERIFIED_TASK",
    operator: "NOT_NULL"
  }),

  "APP_INSTALLED_ON": type => {
    type = JSON.parse(type);
    const time = {
      "Today": { operator: "TIME_AFTER", val: moment().subtract(type[0], type[1]).startOf("day") },
      "Yesterday": { operator: "TIME_BETWEEN", val: [moment().subtract(2, "days").startOf("day"), moment().subtract(1, "day").startOf("day")] }
    };
    
    return {
      type: "APP_INSTALLED",
      ...(time[type[3]] || { operator: "HOURS_AFTER", val: type[0]*24 })
    }
  },

  "LAST_TASK_ON": type => {
    type = JSON.parse(type);
    const time = {
      "Today": { operator: "TIME_AFTER", val: moment().subtract(type[0], type[1]).startOf("day") },
      "Yesterday": { operator: "TIME_BETWEEN", val: [moment().subtract(2, "days").startOf("day"), moment().subtract(1, "day").startOf("day")] }
    };
    
    return {
      type: "LAST_TASK",
      ...(time[type[3]] || { operator: "HOURS_AFTER", val: type[0]*24 })
    }
  },

  "LAST_VERIFIED_TASK_ON": type => {
    type = JSON.parse(type);
    const time = {
      "Today": { operator: "TIME_AFTER", val: moment().subtract(type[0], type[1]).startOf("day") },
      "Yesterday": { operator: "TIME_BETWEEN", val: [moment().subtract(2, "days").startOf("day"), moment().subtract(1, "day").startOf("day")] }
    };
    
    return {
      type: "LAST_VERIFIED_TASK",
      ...(time[type[3]] || { operator: "HOURS_AFTER", val: type[0]*24 })
    }
  },

  "APPLICATION_JOBS": type => ({
    type: "APPLICATION_JOBS",
    val: type,
    operator: "INCLUDE"
  }),

  "PMW_ID": type => ({
    type: "PMW_ID",
    val: type.split(",").map(value => String(value).replace("PMW", "")),
    operator: "INCLUDE"
  }),
  
  "MOBILE": type => ({
    type: "MOBILE_NUMBER",
    val: type,
    operator: "EQUALS"
  })
};