import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from 'reactstrap/lib/Button';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select"
import InputFeild from "../../global/form/input";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import CONSTANTS from "../../../constants"
import { Chip } from "@material-ui/core";
import  OutboundCallingStatus  from "../../global/outbound-calling-status";

class AddLeads extends Component {
    constructor(props) {
        super(props);
        let activeJobLocations = this.props.jobData.JobLocations.filter(o => !o.archive_status);
        this.state = {
            mobile: null,
            email: "",
            whatsapp_no: null,
            first_name: null,
            user_id: null,
            job_id: this.props.jobData.job_id,
            origin: null,
            job_location_id: activeJobLocations[0] && activeJobLocations[0].job_location_id,
            jobLocations: this.props.jobData.JobLocations,
            isCallInitiated: false,
            isReadyToSubmit : true,
            isCallFailed: false,
            callingDetails: {},
            success: "",
            error: ""
        }
    }

    
    async componentDidMount(){
    }

    

    submitInitiateCall = async (e) => {
        e.preventDefault();
        let { mobile, first_name, user_id, job_id, whatsapp_no } = this.state;
        store.dispatch(setLoader(true))
        try {
            if(!user_id){
                var resp = await ApiEndpoints.User.create({mobile, first_name});
                user_id = resp.data.data.user_id;
                this.setState({user_id});
            }
            await ApiEndpoints.Notification.sendSMSByTemplate({user_id, template: "INVITE_ON_APP", template_id: "1207161722691538331"});
            var body = {
                user_id: user_id,
                entity_type: "JOB",
                entity_id: job_id,
                subentity_type: "APPLICATION_STATUS",
                subentity_id: "0",
                direction: 2
            };
            let callResp = await ApiEndpoints.Calling.connectOutboundCall(body);
            this.setState({callingDetails: callResp.data.data, isCallInitiated: true});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message.Message || e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    submitLead = async (e) => {
        e.preventDefault();
        if(e.nativeEvent.submitter.name === "retryCall") return this.submitInitiateCall(e);
        let { mobile, email, first_name, user_id, job_id, job_location_id, origin, whatsapp_no } = this.state;
        var loggedInUser = store.getState().user;
        store.dispatch(setLoader(true))
        try{
            if(!user_id){
                var resp = await ApiEndpoints.User.create({mobile, first_name, email});
                user_id = resp.data.data.user_id;
                await this.setState({user_id});
                await ApiEndpoints.Notification.sendSMSByTemplate({user_id, template: "INVITE_ON_APP", template_id: "1207161722691538331"});

            }
            
            if(!job_id || !job_location_id || !origin) throw new Error ("Please fill all feilds");
            var body = {
                user_id,
                job_id,
                job_location_id,
                recruiter_id: loggedInUser.user_id,
                lead_origin: origin
            }
            // if(!whatsapp_no) throw new Error("Whatsapp number mandatory!");
            await ApiEndpoints.UserProfile.updateProfile({user_id, whatsapp_no: whatsapp_no || mobile});
            await ApiEndpoints.Applicants.addLead(body);
            // await this.sendWhatsappInvitation();               
            this.props.toggleOpenModal();
            this.props.getApplicantsByState("LEADS");
        }   
        catch(e){
            console.log(e);
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }          
        store.dispatch(setLoader(false))                                                      
    }

    sendWhatsappInvitation = async () => {
        let { first_name, whatsapp_no } = this.state;
        var waOpts = {
            mobile_no: whatsapp_no,
            message_text: AppInviteMessage(first_name || "Partner"),
            job_id: this.state.job_id,
        }
        await ApiEndpoints.Whatsapp.addConversation(waOpts)
        // waOpts.mediaUrl = "https://s3.ap-south-1.amazonaws.com/pmw.assets.public/PMW_Intro/hindi.mp4";
        // await ApiEndpoints.Whatsapp.addConversation(waOpts)
    }

    onCallUpdate = (data) => {
        if(!["completed", "in-progress"].includes(data.status)) this.setState({isCallFailed: true});
    }

    render() {
        let {user_id, mobile, email, first_name, origin, job_location_id, jobLocations, isCallInitiated, isReadyToSubmit, success, error, isCallFailed, whatsapp_no } = this.state;
        return (
            <form className="py-2" onSubmit={this.submitLead}>
            {/* <form className="py-2" onSubmit={isCallInitiated ? this.submitLead : this.submitInitiateCall}> */}
                <InputLabel>Partner Name</InputLabel>
                <InputFeild required disabled={isCallInitiated} value={first_name} onChange={(e) => this.setState({first_name: e.target.value})} placeholder="Enter the name" />
                <InputLabel className="mt-3">Mobile Number</InputLabel>
                <InputFeild required min={10} max={10} disabled={isCallInitiated} value={mobile} inputProps={{pattern: "^[0-9]{10}"}} onChange={(e) => this.setState({mobile: e.target.value})} placeholder="Enter 10 digit mobile number" />
                <InputLabel className="mt-3">Email ID</InputLabel>
                <InputFeild required disabled={isCallInitiated} value={email} onChange={(e) => this.setState({email: e.target.value})} placeholder="Enter Email ID" />
                {isCallInitiated && <>
                    <InputLabel className="mt-3">Whatsapp Number</InputLabel>
                    <InputFeild min={10} max={10} value={whatsapp_no} inputProps={{pattern: "^[0-9]{10}"}} onChange={(e) => this.setState({whatsapp_no: e.target.value})} placeholder="Enter 10 digit Whatsapp number" />
                </>}
                <InputLabel className="mt-3">Select Location</InputLabel>
                <SelectList placeholder="Select Location" value={job_location_id} onChange={(e) => this.setState({job_location_id : e.target.value})}>
                    {jobLocations.map(loc => 
                        <option value={loc.job_location_id}>
                            {loc.city}
                            {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                        </option>
                    )}
                </SelectList>
                <InputLabel className="mt-3" htmlFor="origin_select">Lead Origin</InputLabel>
                <SelectList required placeholder="Select Origin" value={origin} onChange={(e) => this.setState({origin: e.target.value})}>
                    {Object.keys(CONSTANTS.APPLICATION_ORIGIN).map(originStatus => 
                        <option value={originStatus}>{CONSTANTS.APPLICATION_ORIGIN[originStatus]}</option>
                    )}
                </SelectList>
                
                
                <hr className="mt-3" />
                <Row>
                    <Col xs={12} className="text-right">
                        
                        <Button className="buttonred mr-2" onClick={this.props.toggleOpenModal}>Cancel</Button>
                        {
                            (isCallFailed) &&
                            <Button name="retryCall" className="buttonred mr-2">Retry Call</Button>
                        }
                        {/* {isCallInitiated ?                         
                        <Button disabled={!isReadyToSubmit} className="buttonred">Submit Lead</Button>
                        :
                        <Button className="buttonred">Initiate Call</Button>
                        } */}
                        <Button className="buttonred">Submit Lead</Button>

                    </Col>
                </Row>
                {success && <em style={{color: "rgb(12, 68, 12)", fontSize: 12}}>{success}</em>}
                {error && <em style={{color: "#f00", fontSize: 12}}>{error}</em>}

                {!!this.state.callingDetails.Sid && <OutboundCallingStatus calledUser={{user_id, first_name}} calling_id={this.state.callingDetails.Sid} onUpdateCall={this.onCallUpdate} onClose={() => this.setState({callingDetails : {} })} />}

            </form>
        ) 
    }
}

export default AddLeads;

const AppInviteMessage = (name) => `Hi ${name},

*PickMyWork* is an income multiplier platform, register on the PickMyWork app and start earning by selling products and services from top companies of India and the world. Zero entry fee, zero training fee, zero investments with weekly payments. Do your own Thing. Be your own Boss.

If you want to earn with *PickMyWork* then please click on the below button to download app and start working.`
