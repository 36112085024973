import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, DropdownItem, DropdownMenu, DropdownToggle, Progress, Table } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import {uploadFile, downloadApiFile} from "../../../actions";
import InputFeild from "../../global/form/input";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import ManageInterviewer from "./manage_interviewer";
import ManageRecruiter from "./manage_recruiter";
import ManageJobMedia from "./job_media"
import JobQuestion from "./job_question";
import DatePicker from "react-datepicker";
import WorkOrder from "./work_order";
import ConfirmAction from "../../global/confirm-action";
import ManageManager from "./manage_manager";
import ManageOpsAssociate from "./manage_ops_associate";
import ManageTL from "./manage_team_leader";
import ManageLocation from "./manage_location";
import CONSTANTS from "../../../constants";
import JobTaskDetail from "./job_task_details";
import {  Box, Chip, Tab, Tabs, Tooltip} from "@material-ui/core";
import { TrackEvent } from "../../../actions/ga"
import AddStory from "../giggers/add-story";
import ScheduleNotifications from "../giggers/schedule_notifications";
import SendNotifications from "../giggers/send_notification";
import JobInformation from "./job_information";
import JobRequirement from "./job_requirement";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer } from "recharts";
import { Receipt } from "@material-ui/icons";

let moment = require("moment");

  
class ProjectOverview extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedJob :  this.props.selectedJob ,       
        applicantCounts: {},
        clientInvoiceList: [],
        isOpenUploadTaskResponseModal : false,
        isOpenUploadTaskModal : false,
        isOpenUploadUsersModal : false,
        uploadTaskLoader : false,
        isOpenLocationModal : false,
        isOpenRecruiterModal : false,
        isOpenInterviewerModal : false,
        isOpenManagerModal : false,
        isOpenOpsAssociateModal : false,
        isOpenTLModal : false,
        isOpenMaterialModal : false,
        isOpenWorkOrderModal : false,
        isOpenTrainingModal : false,
        isOpenJobQuesModal : false,
        isOpenPaymentCertificateModal : false,
        recruiters : [],
        isOpenLocationDeleteModal : false,
        isAddNewLocation : false,
        currentJobLocation: {},
        isOpenCreateProjectModal : false,
        isOpenConfirm : false,
        certificateList : [],
        isShowOlderCertificate : false,
        confirmMessage: null,
        isOpenDownload: false,
        selectedTaskCycle : {},
        isOpenCustomTaskCycle: false,
        isOpenStories: false,
        isOpenNotificationModal: false,
        isOpenProjectInfo: false,
        isOpenProjectRequirement: false,
        eventList:[],
        webinarReport:[],
        isOpenTaskMenu: false,
        }
       }

    async componentDidMount(){
        await this.getApiFn();
        this.setSelectedJob();
        await this.selectTaskCycle("CURR_MONTH");
        this.fetchUpcomingWebinar()     
    }

    

    getApiFn = async () => {
        try{
            let apiData = await ApiEndpoints.Static.getFunctions();
            this.getCycleDates = eval(apiData.data.data.getCycleDates);           
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))            
        }
    }

    setSelectedJob = async () => {
        let selectedJob = this.props.selectedJob;
        var body = { job_id : selectedJob.job_id }
        store.dispatch(setLoader(true))

        try{
            let paymentapiData = await ApiEndpoints.JobTaskPayment.fetch(body);
            this.setState({selectedJobPayment:paymentapiData.data.data})
            selectedJob.recruiterUserId = [];
            selectedJob.interviewerUserId = [];
            selectedJob.managerUserId = [];
            selectedJob.cityManagerUserId = [];
            selectedJob.teamLeaderUserId = [];

            var accessOpts = {
                access_type : "JOB_LOCATION",
                access_type_id : selectedJob.JobLocations.map(loc => loc.job_location_id).join(",")
            }
            let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
            var locationAccess = accessResp.data.data.reduce((prev, curr) => {
                prev[curr.access_type_id] = prev[curr.access_type_id] || [];
                prev[curr.access_type_id].push(curr);
                return prev;
            }, {})
            await Promise.all( (selectedJob.JobLocations.map(async loc => {
                let recruiters = locationAccess[loc.job_location_id] ? locationAccess[loc.job_location_id].filter(access => access.UserRole && access.UserRole.role_id === 4) : [];
                let interviewers = locationAccess[loc.job_location_id] ? locationAccess[loc.job_location_id].filter(access => access.UserRole && access.UserRole.role_id === 5) : [];
                let managers = locationAccess[loc.job_location_id] ? locationAccess[loc.job_location_id].filter(access => access.UserRole && access.UserRole.role_id === 6) : [];
                let CityManagers = locationAccess[loc.job_location_id] ? locationAccess[loc.job_location_id].filter(access => access.UserRole && access.UserRole.role_id === 7) : [];
                let teamLeaders = locationAccess[loc.job_location_id] ? locationAccess[loc.job_location_id].filter(access => access.UserRole && access.UserRole.role_id === 8) : [];
                loc.recruiters = recruiters;
                loc.interviewers = interviewers;
                loc.managers = managers;
                loc.CityManagers = CityManagers;
                loc.teamLeaders = teamLeaders;
                selectedJob.recruiterUserId = selectedJob.recruiterUserId.concat(recruiters.filter(o => selectedJob.recruiterUserId.indexOf(o.user_id) === -1).map(o => o.user_id));
                selectedJob.interviewerUserId = selectedJob.interviewerUserId.concat(interviewers.filter(o => selectedJob.interviewerUserId.indexOf(o.user_id) === -1).map(o => o.user_id));
                selectedJob.managerUserId = selectedJob.managerUserId.concat(managers.filter(o => selectedJob.managerUserId.indexOf(o.user_id) === -1).map(o => o.user_id));
                selectedJob.CityManagersUserId = selectedJob.cityManagerUserId.concat(CityManagers.filter(o => selectedJob.cityManagerUserId.indexOf(o.user_id) === -1).map(o => o.user_id));
                selectedJob.teamLeaderUserId = selectedJob.teamLeaderUserId.concat(teamLeaders.filter(o => selectedJob.teamLeaderUserId.indexOf(o.user_id) === -1).map(o => o.user_id));
            })));
            selectedJob.recruiterCount = selectedJob.recruiterUserId.length;
            selectedJob.interviewerCount = selectedJob.interviewerUserId.length;
            selectedJob.storeManagerCount = selectedJob.managerUserId.length;
            selectedJob.CityManagerCount = selectedJob.cityManagerUserId.length;
            selectedJob.teamLeaderCount = selectedJob.teamLeaderUserId.length;
        }
        catch(e){
            selectedJob.applicants = {};
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        await this.setState({selectedJob })

        store.dispatch(setLoader(false))
    }
    selectApplicantCounts = async (selectedCycle) => {
        let selectedJob = this.props.selectedJob;
        var body = { job_id : selectedJob.job_id }
        if(selectedCycle.start_date) body.start_date = selectedCycle.start_date;
        if(selectedCycle.end_date) body.end_date = selectedCycle.end_date;
        store.dispatch(setLoader(true))
        try{
            let applicantResp = await ApiEndpoints.Applicants.count({...body, is_stale: "0"});
            let staleApplicantResp = await ApiEndpoints.Applicants.count({...body, is_stale: "1"});
            let applicantCounts= applicantResp.data.data.reduce((prev, curr) => {prev[curr.status] = curr; return prev;}, {})
            applicantCounts.stale = staleApplicantResp.data.data.reduce((prev, curr) => prev + curr.count, 0) ;
            this.setState({applicantCounts});
        }
        catch(e){
            selectedJob.applicants = {};
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    selectTaskCycle = async (cycle, dates) => {
        let { selectedJob } = this.props;
        await this.setState({selectedTaskCycle : { refresh: true }})
        var data = {};
        // if(cycle === "CURR_CYCLE") data = this.getCycleDates(selectedJob.pay_cycle_type, selectedJob.pay_cycle_origin, 0);
        // if(cycle === "PREV_CYCLE") data = this.getCycleDates(selectedJob.pay_cycle_type, selectedJob.pay_cycle_origin, 1);
        if(cycle === "CURR_CYCLE") {
            data.start_date = moment().startOf("isoWeek").toDate();
            data.end_date = moment().endOf("isoWeek").toDate();
        }
        if(cycle === "PREV_CYCLE") {
            data.start_date = moment().subtract(1, "isoWeek").startOf("isoWeek").toDate();
            data.end_date = moment().subtract(1, "isoWeek").endOf("isoWeek").toDate();
        }
        if(cycle === "CURR_MONTH") {
            data.start_date = moment().startOf("month").toDate();
            data.end_date = moment().endOf("month").toDate();
        }
        if(cycle === "PREV_MONTH") {
            data.start_date = moment().subtract(1, "month").startOf("month").toDate();
            data.end_date = moment().subtract(1, "month").endOf("month").toDate();
        }
        if(dates) {
            if(dates[0]) data.start_date = dates[0];
            if(dates[1]) data.end_date = dates[1];
        }
        data.type = cycle;
        this.selectApplicantCounts(data)
        this.fetchWebinarReport(data)
        this.fetchClientInvoice(data);
        await this.setState({selectedTaskCycle : data, isOpenCustomTaskCycle: false});
    }

    fetchClientInvoice = async (selectedCycle) => {
        let selectedJob = this.props.selectedJob;
        if(!selectedJob.client_id) return null;
        var body = { 
            client_id : selectedJob.client_id,
            entity_type: "JOB",
            entity_id: selectedJob.job_id
        }
        if(selectedCycle.start_date) body.start_date = selectedCycle.start_date;
        if(selectedCycle.end_date) body.end_date = selectedCycle.end_date;
        try{
            let invoiceResp = await ApiEndpoints.Invoice.fetchInvoiceByDateRange(body);
            let clientInvoiceList = invoiceResp.data.data.filter(invoice => invoice.status < 30).map(invoice => {
                invoice.selectedJobAmount = invoice.InvoiceItems.reduce((prev, curr) => prev + curr.amount, 0);
                invoice.selectedJobAmountText = invoice.InvoiceItems.reduce((prev, curr) => (prev.push(curr.quantity + "*₹"+curr.rate_per_item/100), prev), []).join(", ");
                return invoice;
            });
            console.log(clientInvoiceList, "clientInvoiceList");
            this.setState({clientInvoiceList });
        }
        catch(e){
            console.log(e)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    
    uploadTaskResponseByCsv = async (e) =>{
        e.preventDefault();
        this.setState({uploadTaskLoader : true})
        let {selectedJob} = this.state;
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))

        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                job_id : selectedJob.job_id,
                job_location_id : "0"
            }
            let resp = await ApiEndpoints.StoreManager.uploadTask(body)
            this.setState({isOpenUploadTaskModal : false});
            if(!resp.data.data){
                downloadApiFile(resp);
                store.dispatch(setError({show: true, message: "There was some error to upload data, please check downloaded CSV"}))
            }
            else{
                store.dispatch(setSuccess({show: true, message: resp.data.message}))
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({uploadTaskLoader : false})
        store.dispatch(setLoader(false))

    }

    uploadUsersResponseByCsv = async (e) =>{
        e.preventDefault();
        let {selectedJob, isOpenUploadUsersModal} = this.state;
        isOpenUploadUsersModal.loading = true;
        this.setState({isOpenUploadUsersModal});
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))
        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                job_id : selectedJob.job_id,
                // job_location_id : isOpenUploadUsersModal.job_location_id
            }
            let resp = await ApiEndpoints.StoreManager.uploadUsers(body)
            this.setState({isOpenUploadUsersModal : false});
            if(!resp.data.data){
                downloadApiFile(resp);
                store.dispatch(setError({show: true, message: "There was some error to upload data, please check downloaded CSV"}))
            }
            else{
                store.dispatch(setSuccess({show: true, message: resp.data.message}))
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }
    generatePaymentCertificate = async () => {
        TrackEvent("PAYMENT", "PAYMENT_CERTIFICATE_GENERATE", "PAYMENT_CERTIFICATE");
        store.dispatch(setLoader(true))
        let { isOpenPaymentCertificateModal, selectedJob } = this.state;
        if(!isOpenPaymentCertificateModal.start_date || !isOpenPaymentCertificateModal.end_date)
            store.dispatch(setError({show: true, message : "Select Cycle Start and End Date to generate Payment Certificate"}))

        try {
            var body = {
                job_id : selectedJob.job_id,
                job_provider : isOpenPaymentCertificateModal.job_provider,
                issue_date : isOpenPaymentCertificateModal.issue_date,
                start_date : moment(isOpenPaymentCertificateModal.start_date).startOf("day").toDate(),
                end_date : moment(isOpenPaymentCertificateModal.end_date).endOf("day").toDate()
            }
            var resp = await ApiEndpoints.JobTaskPayment.generatePaymentcertificate(body);
            store.dispatch(setSuccess({show: true, message: "Request Submitted to generate Payment Certificate successfully!"}))
            this.setState({isOpenPaymentCertificateModal : false })
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


   
      fetchCertificateList = async () => {
        TrackEvent("PAYMENT", "PAYMENT_CERTIFICATE_FETCH", "PAYMENT_CERTIFICATE");
        store.dispatch(setLoader(true))
        let {selectedJob } = this.state;
          try{
            var body = {
                job_id : selectedJob.job_id,            }
            let resp = await ApiEndpoints.JobTaskPayment.fetchCertificateList(body);
            this.setState({certificateList : resp.data.data})
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
      }

    
        downloadCertficateList = async (start_date, end_date, company_name, issue_date) => {
                store.dispatch(setLoader(true))
                let {selectedJob } = this.state;
                try{
                    var body = {
                        job_id : selectedJob.job_id,
                        start_date,
                        end_date,
                        company_name,
                        issue_date
                    }
                    let resp = await ApiEndpoints.JobTaskPayment.downloadPaymentCertificateList(body);
                    downloadApiFile(resp, "Certficate Data.csv")
                }
                catch(e){
                    store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
                }
                store.dispatch(setLoader(false))
            }

    
    downloadCandidateReport = async () => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.state.selectedJob.job_id
            }
            var resp = await ApiEndpoints.Jobs.downloadGiggerReport(body);
            downloadApiFile(resp, "Task Response.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadApplicants = async (type) => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.state.selectedJob.job_id,
                type
            } 
            var resp = await ApiEndpoints.Jobs.downloadApplicants(body);
            downloadApiFile(resp, "Application Data "+type+".csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    fetchUpcomingWebinar = async () => {
        store.dispatch(setLoader(true));
        let selectedJob = this.props.selectedJob;
        try{
            var body = {
                entity_type: "JOB",
                entity_id: selectedJob.job_id,
               start_date_range : moment().toDate()
            }               
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            this.setState({ eventList: resp.data.data.rows })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    fetchWebinarReport = async (selectedCycle)=>{
        store.dispatch(setLoader(true))
        try{   
            var body = { 
                job_id : this.state.selectedJob.job_id,
                start_time : selectedCycle.start_date,
                end_time : selectedCycle.end_date 
            }    
            let resp = await ApiEndpoints.Events.fetchWebinarReport(body);
             this.setState({webinarReport : resp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
   
    }
    
    render() {
        let { isOpenDownload, certificateList, uploadTaskLoader , isOpenUploadTaskModal , selectedJob, isOpenDeleteModal, isOpenLocationModal ,isOpenRecruiterModal, isOpenInterviewerModal , isOpenWorkOrderModal , isOpenTrainingModal, isOpenJobQuesModal, clientInvoiceList} = this.state;
        let { applicantCounts, isOpenPaymentCertificateModal,isOpenManagerModal, isOpenOpsAssociateModal, isOpenTLModal , isShowOlderCertificate, isOpenUploadUsersModal, selectedTaskCycle, isOpenCustomTaskCycle, isOpenNotificationModal,eventList,webinarReport} = this.state;
        var userRole = store.getState().user.UserRole   

        return(
        <>
        <div className="mt-1">
            <Row className="align-items-center">
                <Col>
                    <Row className={"align-items-center"}>
                            <Col xs={1}>
                                <Icon style={{cursor: "pointer",color:"#f1061c"}} className="align-middle" onClick={this.props.onClose}>arrow_back</Icon>           
                            </Col>
                            <Col xs={"auto"}>
                                <img className="rounded-circle ml-2" style={{width: 40, padding: 5}} src={selectedJob.Employer.logo_key || "/images/dummy_profile.jpg"} alt="" /> 
                            </Col>
                            <Col>
                                <strong> {selectedJob.Employer.employer_name +  "  - "   + selectedJob.title}</strong>
                            </Col>
                    </Row>   
                </Col>
                <Col xs={"auto"} className="text-right">  
                    {["SHORTLISTED", "IN_TASK", "TERMINATED"].map(status => 
                    <span className="small pr-2">
                        <strong className="text-muted"  style={{fontSize: 12}}> {CONSTANTS.APPLICANTS_STATES[status].name}: </strong>
                        {status === "INACTIVE" ? 
                            <span >{applicantCounts["stale"] || 0}</span>
                            :
                            <span>{applicantCounts[CONSTANTS.APPLICATION_STATUS[status]] ? applicantCounts[CONSTANTS.APPLICATION_STATUS[status]].count :  0}</span>   
                        }
                    </span>
                    )}
                    <Button outline color="danger" size="sm" className="mr-3" onClick={()=> {this.setState({isOpenStories : {}})}} style={{fontSize: 14}}><Icon className="mr-1 align-middle" fontSize="small">add_circle_outline_outlined</Icon>Add Story</Button>
                    <Button outline color="danger" size="sm" onClick={()=> {this.setState({isOpenNotificationModal : 1})}} style={{fontSize: 14}}><Icon className="mr-1 align-middle" fontSize="small">add_alert_outlined</Icon>Add Notification</Button>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col lg={2} xs={12} className=""  style={{background:"#f8f9fa"}}>     
                    <span className="d-flex text-muted small mb-3 mt-3 font-weight-bold"><Icon className="mr-3" style={{fontSize:"20px"}}>settings_sharp</Icon> Manage Project</span>
                    <div className="pr-1 mb-4" >
                        <Button id="add_location_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenLocationModal : true})}} type="button" className="w-100 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}> { selectedJob.JobLocations.length ? selectedJob.JobLocations.length+" Location" : <em style={{fontSize: "11px"}}>No Location Added</em> }</Button>
                        <Button id="project_info_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenProjectInfo : true})}} type="button"  className=" w-100 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Project Info</Button> <br />
                        <Button id="project_requirement_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenProjectRequirement : "PARTNER"})}} type="button"  className=" w-100 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Partner Requirement</Button> <br />
                        <Button id="project_requirement_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenProjectRequirement : "CUSTOMER"})}} type="button"  className=" w-100 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Customer Eligibility</Button> <br />
                        {/* <Button id="project_workorder_popup" outline color="danger" size="sm" onClick={()=> {this.setState({isOpenWorkOrderModal : true})}} type="button"  className=" w-100 mt-1" style={{fontSize: 12}}>Work Order</Button> <br /> */}
                        {/* <Button id="project_media_popup" outline color="danger" size="sm" onClick={()=> {this.setState({isOpenTrainingModal : true})}} type="button"  className=" w-100 mt-1" style={{fontSize: 12}}>Training / JD Videos</Button> <br /> */}
                    </div>

                    <span className="d-flex text-muted small mb-3 font-weight-bold"><Icon className="mr-3" style={{fontSize:"20px"}}>vpn_key</Icon> Manager Access</span>
                    <div className="pr-1 mb-4">
                        <Button id="project_recruiter_popup"variant="contained" size="md"  onClick={()=> {this.setState({isOpenRecruiterModal : true})}} type="button"  className=" w-100 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>{selectedJob.recruiterCount ? selectedJob.recruiterCount + " Recruiter" : <em>No Recruiter Added</em>}</Button> <br />
                        {/* <Button id="project_interviewer_popup" outline color="danger" size="sm" onClick={()=> {this.setState({isOpenInterviewerModal : true})}} type="button"  className=" w-100 mt-1" style={{fontSize: 12}}>{selectedJob.interviewerCount ? selectedJob.interviewerCount + " Interviewer" : <em>No Interviewer Added</em>}</Button> <br />     */}
                        <Button id="project_city_manager_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenManagerModal : true})}} type="button"  className=" w-100 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Manager</Button> <br />
                        <Button id="project_city_manager_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenOpsAssociateModal : true})}} type="button"  className=" w-100 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Ops Associate</Button> <br />
                        <Button id="project_team_leader_popup" variant="contained" size="md" onClick={()=> {this.setState({isOpenTLModal : true})}} type="button"  className=" w-100 mb-3 mt-2 shadow-sm" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}>Team Leader</Button> <br />
                    </div>

                    <span className="d-flex text-muted small mb-2 font-weight-bold"><Icon className="mr-3" style={{fontSize:"20px"}}>more_horiz</Icon> Other</span>
                    <div className="pr-1 mb-5">
                    <Button id="downloads" style={{fontSize: 12,background:"#ffffff",color:"#ff0000", border:"medium solid white"}}  className="w-100 mt-2 mb-3 shadow-sm" variant="contained" size="md" onClick={()=> {this.setState({isOpenDownload : true})}}> Download</Button>
                    </div>
                </Col>

                <Col className="" style={{overflowX: "auto"}}>  
                        <Row className="align-items-center mt-2">
                            <Col xs={3}><span className="font-weight-bold text-muted">Project Dashboard</span></Col>
                            <Col className="text-right">
                                <em className="mr-2">Filter By :</em>
                                {[
                                    { type: "ALL", label: "All" },
                                    { type: "CURR_MONTH", label: "This Month" },
                                    { type: "PREV_MONTH", label: "Last Month" },
                                    { type: "CURR_CYCLE", label: "This Cycle" },
                                    { type: "PREV_CYCLE", label: "Last Cycle" },
                                    { type: "CUSTOM", label: "Custom " }
                                ].map(filter => 
                                    filter.type === "CUSTOM" ? 
                                    <Chip size="small" label={`Custom : ${moment(selectedTaskCycle.start_date).utcOffset("+05:30").format("Do MMM") } - ${moment(selectedTaskCycle.end_date).utcOffset("+05:30").format("Do MMM")}`} onClick={() => {this.setState({isOpenCustomTaskCycle: {}})} } variant="outlined" style={{ fontSize: 10, background : selectedTaskCycle.type === filter.type ? "#212529" : "white",color: selectedTaskCycle.type === filter.type ?"white":"#212529"}} clickable className="ml-1" />  
                                    :
                                    <Chip size="small" label={filter.label} onClick={() => this.selectTaskCycle(filter.type) } variant="outlined" style={{ fontSize: 10, background : selectedTaskCycle.type === filter.type ? "#212529" : "white",color: selectedTaskCycle.type === filter.type ?"white":"#212529"}} clickable className="ml-1" />  
                                )}
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col lg={3} xs={4} className="">
                                <Row noGutters className="align-items-center border border-light shadow-sm" style={{cursor: "pointer", borderRadius: 8}} >
                                    <Col xs={"auto"} className="" >
                                        <div className="p-1 my-3 mx-2" style={{borderRadius: 10, background: "#e9e9e9" }}>
                                            <Icon className="ml-1 d-flex align-items-center" style={{color: "#ccc"}}><Receipt style={{position:'absolute',fontSize:"smaller"}}/></Icon>
                                        </div>
                                    </Col>
                                    <Col className="font-weight-bold">
                                        <span className="d-block" style={{fontSize:12}}>Invoice Amount</span>
                                        <span>₹{clientInvoiceList.reduce((prev, curr) => prev + curr.selectedJobAmount, 0)/100}</span>
                                    </Col>
                                </Row>
                            </Col>  
                            <Col lg={9} xs={8}>
                                <Row  className="shadow-sm border border-light py-2 align-items-center w-100" style={{borderRadius: 8, flexWrap: "nowrap", overflowX: "auto"}}>
                                        {clientInvoiceList.length ? 
                                        clientInvoiceList.map(invoice => {
                                            return <Col xs={4} className="px-2">
                                                <Row noGutters className="align-items-center shadow-sm border border-light py-1 px-2">
                                                    {invoice.status === 20 && <Icon style={{position: "absolute", right: 5, top: "-3px", color: "green", fontSize: 14}}>check_circle</Icon>}
                                                    <Col xs={6} title={invoice.selectedJobAmountText}>
                                                        <span style={{fontSize: 14, fontWeight: 700}}>₹{invoice.selectedJobAmount/100}</span>
                                                        <span style={{fontSize: 10}} className="small d-block">#{invoice.serial_number}</span>
                                                    </Col>
                                                    <Col xs={6} className="text-right text-muted">
                                                        <span className="small d-block">{moment(invoice.start_date).utcOffset("+05:30").format("Do MMM'YY")}</span>
                                                        <span className="small d-block">{moment(invoice.end_date).utcOffset("+05:30").format("Do MMM'YY")}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        })
                                        :
                                        <Col className="text-center"><span className="d-block small text-center my-3 text-muted ">No Invoices for selected date range</span></Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                        

                       
                                
                        <JobTaskDetail 
                            jobData={this.state.selectedJob} 
                            getCycleDates={this.getCycleDates} 
                            selectedTaskCycle={selectedTaskCycle} 
                            onEditTaskDetail={this.onEditTaskDetail} 
                        />
                                
                             
                        <Row noGutters className="mt-3 p-2 text-center align-items-center" style={{background:"#f3f3f3",borderRadius: 8}}>                                       
                            <Col lg={1} xs={4} style={{maxWidth: "12.5%"}} className="">
                                <Icon className="align-middle" style={{fontSize:"30px",color:""}}>co_present</Icon> 
                            </Col>
                            
                            {[
                                {label: "Total Webinars", count: webinarReport.webinar_count},
                                {label: "Attendees", count: webinarReport.attendees_count},
                                {label: "In Task", count: webinarReport.in_task_count},
                                {label: "Task - 1", count: webinarReport.task1},
                                {label: "Task - 5", count: webinarReport.task5},
                                {label: "Task - 10", count: webinarReport.task10}
                            ].map(data => 
                                <Col>
                                    <div className=" p-1 text-center">
                                        <strong style={{fontSize: 12}}>{data.label}</strong>
                                        <br />
                                        <strong >{data.count || 0}</strong>
                                    </div>
                                </Col>   
                            )}
                            <Col xs={12} style={{fontSize: 12}} className="pl-4 text-left">
                                <strong> Upcoming Webinar </strong>
                                {eventList.length ?
                                eventList.map(event => 
                                    <Tooltip title={event.title}>
                                        <strong><Chip  size="small"  label={moment(event.start_date).utcOffset("+5:30").format("Do MMM'YY - hh:mm A")} variant="outlined"  style={{ fontSize: 10}} clickable className="ml-2"/></strong>
                                    </Tooltip>
                                )
                                :
                                <em className="ml-3">No Upcoming webinar</em>
                                }
                            </Col>          

                        </Row>

                        <Row noGutters className="p-2 shadow-sm align-items-center mt-3 text-center" style={{background: "#f3f8fe", borderRadius: 8, opacity: 0.4, pointerEvents: "none", position: "relative"}} >
                            <p style={{position: "absolute", left: "45%", top: "25", color: "#f00"}}>Coming Soon..</p>                                       
                            <Col lg={1} xs={4} className="" style={{maxWidth: "12.5%"}}>
                                <Icon className="align-middle" style={{fontSize:"30px",color:"#1773e6"}}>support_agent</Icon> 
                            </Col>
                            {[
                                {label: "Total Callings", count: 0},
                                {label: "Completed", count: 0},
                                {label: "Calls Duration", count: 0},
                                {label: "Interested", count: 0},
                                {label: "Join Webinar", count: 0},
                                {label: "In Task (1)", count: 0},
                                {label: "In Task (5)", count: 0},
                            ].map(callData => 
                                <Col>
                                    <div className=" p-1 text-center">
                                        <span style={{fontSize: 12}}>{callData.label}</span>
                                        <br />
                                        <strong >{callData.count || 0}</strong>
                                    </div>
                                </Col>  
                            )}
                        </Row>
                
                </Col>
            </Row>





                <Modal isOpen={!!isOpenCustomTaskCycle} toggle={()=> {this.setState({isOpenCustomTaskCycle : false})}} >
                        <ModalBody className="p-5">
                            <DatePicker
                                placeholderText="Start Date"
                                selected={isOpenCustomTaskCycle.startDate || null}
                                onChange={(date) => {isOpenCustomTaskCycle.startDate = moment(date).startOf("day").toDate(); this.setState({isOpenCustomTaskCycle});}}
                                selectsStart
                                autoComplete="false"
                                dateFormat='dd-MMM-yyyy'
                                startDate={isOpenCustomTaskCycle.startDate || null}
                                endDate={isOpenCustomTaskCycle.endDate || null}
                            />
                            <DatePicker
                                className="mt-3"
                                placeholderText="End Date"
                                selected={isOpenCustomTaskCycle.endDate || null}
                                onChange={(date) => {isOpenCustomTaskCycle.endDate = moment(date).endOf("day").toDate(); this.setState({isOpenCustomTaskCycle});}}
                                selectsEnd
                                autoComplete="false"
                                dateFormat='dd-MMM-yyyy'
                                startDate={isOpenCustomTaskCycle.startDate || null}
                                endDate={isOpenCustomTaskCycle.endDate || null}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.selectTaskCycle("CUSTOM", [isOpenCustomTaskCycle.startDate, isOpenCustomTaskCycle.endDate])} color="danger" >Apply Filter</Button>{' '}
                        </ModalFooter>
                    </Modal>

                    <Modal size="lg" className="modal-dialog-centered" isOpen={isOpenDownload} toggle={()=> {this.setState({isOpenDownload : false})}} >
                        <ModalBody className="p-3">
                            <Row>
                                <Col xs={3} className="text-center">
                                    <Button id="applicants_login_download" size="sm" className="buttonred " onClick={this.downloadCandidateReport}>Login Status</Button> 
                                </Col>
                                <Col xs={3} className="text-center">
                                    <Button id="applicants_onboarding_download" size="sm" className="buttonred " onClick={() => this.downloadApplicants("ONBOARDING")}>Onboarding Data</Button> 
                                </Col>
                                <Col xs={3} className="text-center">
                                    <Button id="applicants_shortlisted_download" size="sm" className="buttonred " onClick={() => this.downloadApplicants("SHORTLISTED")}>Selected Data</Button> 
                                </Col>
                                <Col xs={3} className="text-center">
                                    <Button id="applicants_terminated_download" size="sm" className="buttonred " onClick={() => this.downloadApplicants("TERMINATED")}>Terminated Data</Button> 
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>                    

                    <Modal isOpen={isOpenUploadTaskModal} toggle={()=> {this.setState({isOpenUploadTaskModal : false})}} >
                        <form onSubmit={this.uploadTaskResponseByCsv}>
                        <ModalHeader toggle={()=> {this.setState({isOpenUploadTaskModal : false})}}>Upload Task by CSV</ModalHeader>
                        <ModalBody className="p-5">
                            <input id="ext_project_upload_task_input" required type="file" name="csvFile" />
                            {/* <p>*You have to upload the file that you have downloaded and change the `Status Comment` column</p> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="ext_project_upload_task_submit" disabled={uploadTaskLoader} type="submit" color="primary" >Update Status</Button>{' '}
                            <Button id="ext_project_upload_task_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenUploadTaskModal : false})}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>

                    <Modal isOpen={!!isOpenUploadUsersModal} toggle={()=> {this.setState({isOpenUploadUsersModal : false})}} >
                        <form onSubmit={this.uploadUsersResponseByCsv}>
                        <ModalHeader toggle={()=> {this.setState({isOpenUploadUsersModal : false})}}>Upload Users by CSV</ModalHeader>
                        <ModalBody className="p-5">
                            {/* <SelectList placeholder="Select Location" value={isOpenUploadUsersModal.job_location_id} onChange ={(e)=> {isOpenUploadUsersModal.job_location_id = e.target.value; this.setState({isOpenUploadUsersModal})}} >
                                { 
                                selectedJob.JobLocations && selectedJob.JobLocations.map(loc => 
                                <option key={loc.job_location_id} value={loc.job_location_id}> {loc.city} </option>
                                )
                                }
                            </SelectList> */}
                            <input id="ext_project_upload_users_input" required type="file" name="csvFile" className="mt-3" />
                            <p style={{fontSize: 12}}><em>Download Sample File, Fill the details and upload here</em></p>
                            <a target="_blank" rel="noopener noreferrer" href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/user_upload_template.xlsx">Download Sample File</a>
                            {/* <p>*You have to upload the file that you have downloaded and change the `Status Comment` column</p> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="ext_project_upload_users_submit" disabled={!!isOpenUploadUsersModal.loading} type="submit" color="primary" >Upload</Button>{' '}
                            <Button id="ext_project_upload_users_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenUploadUsersModal : false})}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>


                    

                    <Modal size="lg" onOpened={this.fetchCertificateList} isOpen={!!isOpenPaymentCertificateModal} toggle={()=> {this.setState({isOpenPaymentCertificateModal : false})}} >
                        
                        <ModalHeader toggle={()=> {this.setState({isOpenPaymentCertificateModal : false})}}>Payment Certificate</ModalHeader>
                        <ModalBody className="p-4">
                            <Row className="mb-4">
                                <Col>
                                    <InputFeild id="certificate_generate_company_input" placeholder="Company Name" required onChange={(e) => {isOpenPaymentCertificateModal.job_provider = e.target.value; this.setState({isOpenPaymentCertificateModal})}} />
                                </Col>
                                <Col>
                                    <InputFeild id="certificate_generate_date_input" placeholder="Date" required onChange={(e) => {isOpenPaymentCertificateModal.issue_date = e.target.value; this.setState({isOpenPaymentCertificateModal})}} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DatePicker
                                        selected={this.state.isOpenPaymentCertificateModal ? this.state.isOpenPaymentCertificateModal.start_date : null}
                                        onChange={date => {isOpenPaymentCertificateModal.start_date = date; this.setState({isOpenPaymentCertificateModal})}}
                                        className="mt-3"
                                        autoComplete="false"
                                        dateFormat='dd-MMM-yyyy'
                                        id="certificate_generate_startDate_input"
                                        placeholderText="Start Date"
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={this.state.isOpenPaymentCertificateModal ? this.state.isOpenPaymentCertificateModal.end_date : null}
                                        onChange={date => {isOpenPaymentCertificateModal.end_date = date; this.setState({isOpenPaymentCertificateModal})}}
                                        className="mt-3"
                                        autoComplete="false"
                                        dateFormat='dd-MMM-yyyy'
                                        id="certificate_generate_endDate_input"
                                        placeholderText="End Date"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-4">
                                    <Button id="payment_certificate_generate_submit" size="sm" onClick={this.generatePaymentCertificate} color="dark">Generate Payment Certificate</Button>
                                </Col>
                            </Row>

                            <Table bordered className="table-sm mt-4 mb-0">
                                <thead>
                                    <tr>
                                    <th>Issue Date</th>
                                    <th>Company Name</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>No. of generated</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {certificateList.slice(0, isShowOlderCertificate ? certificateList.length : 5).map((list, i) =>
                                        <tr>
                                        <td>{moment(list.issue_date).utcOffset("+05:30").format("DD-MMM-YYYY")}</td>
                                        <td>{list.company_name} </td>
                                        <td>{moment(list.start_date).utcOffset("+05:30").format("DD-MMM-YYYY")}</td>
                                        <td>{moment(list.end_date).utcOffset("+05:30").format("DD-MMM-YYYY")}</td> 
                                        <td>{list.generated} </td>
                                        <td><Icon id={"download_certificate_data_"+i} onClick={() => this.downloadCertficateList(list.start_date, list.end_date, list.company_name, list.issue_date )} className="" style={{cursor: "pointer", fontSize: "19px"}}>get_app</Icon></td>
                                        </tr>
                                   )}
                                </tbody>
                            </Table>  
                            <Button id="certificate_submit" onClick={()=> {this.setState({isShowOlderCertificate : !isShowOlderCertificate})}} className="pl-0" color="link">{isShowOlderCertificate ? "Show Less":"Show Older"}</Button>
                        </ModalBody>
                    </Modal>

                    
                    <Modal isOpen={isOpenDeleteModal} toggle={()=> {this.setState({isOpenDeleteModal : false})}} >
                        <form>
                        <ModalHeader toggle={()=> {this.setState({isOpenDeleteModal : false})}}>Delete</ModalHeader>
                        <ModalBody>
                            <b>Are you sure to delete  ?</b>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="delete_popup_submit" onClick={this.deletedJob}  className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Sure</Button>{' '}
                            <Button id="delete_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenDeleteModal : false})}} style={{fontSize: "12px", height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>


                    <Modal size="lg" isOpen={isOpenLocationModal} toggle={()=> {this.setState({isOpenLocationModal : false})}} >
                        <ModalHeader toggle={()=> {this.setState({isOpenLocationModal : false})}}>Location</ModalHeader>
                        <ModalBody>
                            {isOpenLocationModal && <ManageLocation tree_level={1} job={selectedJob} onClose={() => this.setState({isOpenLocationModal : false}) } onRefresh={(selectedJob) => this.setState({selectedJob})}  />}
                        </ModalBody>
                    </Modal>

                   

                    <Modal size={"lg"} isOpen={isOpenRecruiterModal} toggle={()=> {this.setState({isOpenRecruiterModal : false})}} >
                        <ModalBody>
                        {isOpenRecruiterModal && <ManageRecruiter job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="project_recruiter_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenRecruiterModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size={"lg"} isOpen={isOpenInterviewerModal} toggle={()=> {this.setState({isOpenInterviewerModal : false})}} >
                        <ModalBody>
                        {isOpenInterviewerModal && <ManageInterviewer job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="project_interviewer_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenInterviewerModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal size={"lg"} isOpen={isOpenManagerModal} toggle={()=> {this.setState({isOpenManagerModal : false})}} >
                        <ModalBody>
                        {isOpenManagerModal && <ManageManager job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="project_citymanager_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenManagerModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal size={"lg"} isOpen={isOpenOpsAssociateModal} toggle={()=> {this.setState({isOpenOpsAssociateModal : false})}} >
                        <ModalBody>
                        {isOpenOpsAssociateModal && <ManageOpsAssociate job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="project_ops_associate_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenOpsAssociateModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal size={"lg"} isOpen={isOpenTLModal} toggle={()=> {this.setState({isOpenTLModal : false})}} >
                        <ModalBody>
                        {isOpenTLModal && <ManageTL job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                        </ModalBody>
                        <ModalFooter>
                            <Button id="project_citymanager_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenTLModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                    <Modal size={"lg"} isOpen={isOpenWorkOrderModal} toggle={()=> {this.setState({isOpenWorkOrderModal : false})}} >
                        <ModalHeader toggle={()=> {this.setState({isOpenWorkOrderModal : false})}}>Work Order <br />                            
                        </ModalHeader>
                        <ModalBody>
                            {isOpenWorkOrderModal && <WorkOrder  selectedJob={selectedJob} toggle={()=> {this.setState({isOpenWorkOrderModal : false})}} /> }
                        </ModalBody>
                    </Modal>


                    <Modal size="lg" isOpen={isOpenTrainingModal} toggle={()=> {this.setState({isOpenTrainingModal : false})}} >
                        {/* <ModalHeader toggle={()=> {this.setState({isOpenTrainingModal : false})}}>Media <br /></ModalHeader> */}
                        <ModalBody>
                            {isOpenTrainingModal && <ManageJobMedia selectedJob={selectedJob} />}
                        </ModalBody>
                    
                    </Modal>

                    <Modal size="lg" isOpen={isOpenJobQuesModal} toggle={()=> {this.setState({isOpenJobQuesModal : false})}} >
                            <form>
                        <ModalBody className="border-bottom">
                                <JobQuestion job={selectedJob}/>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="question_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenJobQuesModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>

                   

                    <Modal size="lg" isOpen={this.state.isOpenProjectInfo} toggle={()=> {this.setState({isOpenProjectInfo : false})}} >
                        <ModalHeader toggle={()=> {this.setState({isOpenProjectInfo : false})}}>Project Information </ModalHeader>
                        <ModalBody className="border-bottom">
                                <JobInformation job={selectedJob}/>
                        </ModalBody>
                    </Modal>

                    <Modal size="lg" isOpen={this.state.isOpenProjectRequirement} toggle={()=> {this.setState({isOpenProjectRequirement : false})}} >
                        <ModalHeader toggle={()=> {this.setState({isOpenProjectRequirement : false})}}>{this.state.isOpenProjectRequirement === "PARTNER" ? "Partner Requirement" : "Customer Requirement "}</ModalHeader>
                        <ModalBody className="border-bottom">
                               {!!this.state.isOpenProjectRequirement && <JobRequirement userType={this.state.isOpenProjectRequirement} job={selectedJob} onClose={() => this.setState({ isOpenProjectRequirement: false })} />}
                        </ModalBody>
                    </Modal>

                    
                    {!!this.state.isOpenStories && <AddStory defaultNotificationTitle={selectedJob.Employer.employer_name+" - "+selectedJob.title}  prepareFilter={() => ({ job_id: selectedJob.job_id, filters: ["JOB_INVOLVED"] })}  isOpenStory={true} userCount={"this project all Involved"} handleClose={()=> {this.setState({isOpenStories : false})}} />}


                    <Modal size="lg" isOpen={!!this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal : false, sendNotificationOpts: {}, originType: "GENERAL"})}>
                        <ModalHeader toggle={() => this.setState({isOpenNotificationModal : false})}>
                            <Row className="px-2">
                                <Col xs="auto" onClick={() => this.setState({isOpenNotificationModal: 1, sendNotificationOpts: {}})} className={" " + (isOpenNotificationModal === 1 && "border-bottom")} style={{cursor: "pointer"}}><h6>Send Now</h6></Col>
                                {/* <Col xs="auto" className="border-right px-2"></Col>
                                <Col xs="auto" onClick={() => this.setState({isOpenNotificationModal: 2})} className={"ml-2 " + (isOpenNotificationModal === 2 && "border-bottom")} style={{cursor: "pointer"}}><h6>Schedule</h6></Col> */}
                            </Row>
                        </ModalHeader>
                        <ModalBody>
                            {isOpenNotificationModal === 1 && <SendNotifications defaultNotificationTitle={selectedJob.Employer.employer_name+" - "+selectedJob.title}  prepareFilter={() => ({ job_id: selectedJob.job_id, filters: ["JOB_STATUS"], application_status : 7 })} redirectOpts={{originType: "JOB_TASK", job_id: selectedJob.job_id}} onClose={() => this.setState({isOpenNotificationModal : false})}  userCount={"In-Task"}/>}
                            {isOpenNotificationModal === 2 && <ScheduleNotifications templates={[]} filters={{ job_id: selectedJob.job_id, filters: ["JOB_STATUS"], application_status : 7 }} filterList={[{name : "In Task", type : "JOB_STATUS", scope : "JOB_SPECIFIC",params : {application_status : 7}}]} jobList={[selectedJob]} fetchFilter={{ origin_type: "JOB", origin_id: selectedJob.job_id }} />}
                        </ModalBody>
                    </Modal>



                    <ConfirmAction message={this.state.confirmMessage} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

                    
            </div>
            </>
    );
  }
 }
export default ProjectOverview;
