import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Checkbox, Chip, Icon, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import Collapse from "reactstrap/lib/Collapse";
import Card from "reactstrap/lib/Card";
import DatePicker from "react-datepicker";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";
import { downloadApiFile } from "../../../actions";

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {
           eventList : [],
           rowsPerPage : 20,
           currentUserPage : 0,
           currentAttendeePage: 0,
           isAddNewEvent: false,
           isOpenConfirm: false,
           searchText: "",
           searchAttendeeText: "",
           isShowAttendees: false,
           eventCounts: 0,
           isFilters : false,
        
        }
    }

    componentDidMount(){
       
        this.fetchEvents();
    }

    fetchEvents = async (page = 0) => {
        store.dispatch(setLoader(true));
        let {isFilters} = this.state;
        try{
            var body = {
                limit: 20,
                offset: 20 * page
            }
            if(isFilters.job_id) body = {...body, entity_type: "JOB", entity_id: isFilters.job_id}
            if(this.props.job_id) body = {...body, entity_type: "JOB", entity_id: this.props.job_id}
            if(isFilters.start_date) body = {...body, start_date_range: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, start_date_range: moment(isFilters.end_date).endOf("day").toDate()}
               
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            var data = resp.data.data.rows || [];
            var eventCounts = resp.data.data.count || 0
            this.setState({eventList: data, eventCounts, currentUserPage: page})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    downloadEvents = async (opts = {}) => {
        store.dispatch(setLoader(true));
        let {isFilters} = this.state;
        try {
            var body = {download: true};
            if(isFilters.job_id) body = {...body, entity_type: "JOB", entity_id: isFilters.job_id}
            if(isFilters.start_date) body = {...body, start_date_range: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, start_date_range: moment(isFilters.end_date).endOf("day").toDate()}
           
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            downloadApiFile(resp, "Events Data.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }


    onChangeAddEvent = (k, v) => {
        let { isAddNewEvent } = this.state;
        var job = this.props.jobList;
        isAddNewEvent[k] = v;
        if(k === "job_id") {
            job = job.filter(job => job.job_id == v)[0];
            isAddNewEvent.title = job.Employer.employer_name+"-"+job.title;
        }
        else if(k === "job_location_id") {
            job = job.filter(job => job.job_id == isAddNewEvent.job_id)[0]
            var jobLocation = job.JobLocations.filter(loc => loc.job_location_id == v)[0];
            isAddNewEvent.title = job.Employer.employer_name+"-"+job.title + " ("+ jobLocation.city +")" 
        }
        this.setState({isAddNewEvent})
    }

    createEvent = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try {
            let {title, description, date, start_time, end_time, event_tags, job_id, job_location_id} = this.state.isAddNewEvent;
            var body = {
                title, description, event_tags,
                entity_type: job_location_id ? "JOB_LOCATION" : (job_id ? "JOB" : "GENERAL"),
                entity_id: job_location_id || job_id,
                start_date: moment(moment(date).format("YYYY-MM-DD")+" "+moment(start_time).format("HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").toDate(),
                end_date: moment(moment(date).format("YYYY-MM-DD")+" "+moment(end_time).format("HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").toDate(),
            };
            await ApiEndpoints.Events.createEvent(body);
            this.setState({isAddNewEvent: false})
            await this.fetchEvents();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    delete = async (event_id) =>{
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Events.inactiveEvent({event_id});
            await this.fetchEvents();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    showEventAttendees = async (event) =>{
        let { isShowAttendees } = this.state;
        if(isShowAttendees.event && isShowAttendees.event.event_id === event.event_id) return this.setState({ isShowAttendees: false });
        store.dispatch(setLoader(true))
        try{
            var resp = await Promise.all([
                ApiEndpoints.Events.fetchEventAttendees({event_id: event.event_id}),
                ApiEndpoints.Events.fetchEventFeedback({event_id: event.event_id})
            ]);
            this.setState({ isShowAttendees: { 
                event, 
                data: resp[0].data.data, 
                searchAttendeeText: "", 
                currentAttendeePage: 0,
                feedback: resp[1].data.data || { training_rating: {}, feedback_status: {} }
            } });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    selectAttendees = (event_attendee_id) => {
        let { isShowAttendees } = this.state;
        if(!isShowAttendees.selected) isShowAttendees.selected = [];
        var i = isShowAttendees.selected.indexOf(event_attendee_id);
        if(i === -1) isShowAttendees.selected.push(event_attendee_id);
        else isShowAttendees.selected.splice(i, 1);
        this.setState({ isShowAttendees })
    }

    updateEventAttendees = async (status) =>{
        let { isShowAttendees } = this.state;
        store.dispatch(setLoader(true))
        try{
            var body = {
                event_attendee_id: isShowAttendees.selected,
                status
            }
            await ApiEndpoints.Events.updateEventAttendees(body);
            var resp = await ApiEndpoints.Events.fetchEventAttendees({event_id: isShowAttendees.event.event_id});
            isShowAttendees.selected = [];
            
            isShowAttendees.data = resp.data.data;
            this.setState({ isShowAttendees });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    toggleFilter = async () => {
        let { isFilters } = this.state;
        if(isFilters) {
            await this.setState({isFilters: false});
            this.fetchEvents();
        }
        else this.setState({ isFilters: {} })
    }

    toggleCreateEvent = () => {
      let { isAddNewEvent } = this.state;
      if(isAddNewEvent) isAddNewEvent = false
        else  {
            isAddNewEvent = {};
            if(this.props.job_id) isAddNewEvent.job_id = this.props.job_id;
        }
      this.setState({isAddNewEvent});
    }

    render() {
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
       
        let { eventList, isAddNewEvent, searchText, isShowAttendees, searchAttendeeText, isFilters} = this.state;
        let { jobList, isOnlyView } = this.props;
        if(searchText)  eventList = eventList.filter(obj => obj.title.toLowerCase().includes(searchText.toLowerCase()) );

        return (
            <>

                
                            <Row className="border-bottom py-2 mb-3 align-items-end">
                                <Col>
                                    {!isOnlyView && <Button className="mb-3 buttonred" onClick={() => this.toggleCreateEvent()}>Add Event</Button>}
                                </Col>
                                <Col xs={5}>
                                    <InputFeild onChange={(e) => this.setState({searchText: e.target.value})} icon="search" placeholder="Search" />
                                </Col>
                                <Col xs="auto">
                                    <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.toggleFilter()}>{isFilters ? "filter_alt_off" : "filter_alt"}</Icon>
                                    <Button onClick={() => this.downloadEvents()} size="sm" className="p-0" color="secondary" outline><Icon className="align-middle" style={{fontSize: 18}}>download</Icon></Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!isFilters}>
                                <Row className="justify-content-end">
                                    <Col xs={12} lg={6} className="border mx-3 mb-3">
                                       {!this.props.job_id && <SelectList className="mt-2" placeholder="Select Project" multiple value={isFilters.job_id || []} onChange ={(e) => {isFilters.job_id = e.target.value; this.setState({isFilters})}} >
                                        {jobList.map(job => 
                                            <option key={job.job_id} value={job.job_id}>
                                                {job.Employer.employer_name +  "-"  + job.title}
                                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                            </option>
                                        )}
                                        </SelectList>
                                         }
                                        <Row className={"mt-3"}>
                                            <Col>
                                                <DatePicker
                                                    selected={isFilters.start_date || null}
                                                    onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                                                    isClearable
                                                    dateFormat='dd-MMM-yyyy'
                                                    placeholderText="Start Date"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    selected={isFilters.end_date || null}
                                                    onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                                                    isClearable
                                                    dateFormat='dd-MMM-yyyy'
                                                    placeholderText="End Date"
                                                />
                                            </Col>
                                        </Row>
                                       <div className="text-right py-3"><Button color="dark" size="sm" onClick={() => this.fetchEvents()}>Apply Filter</Button></div>
                                    </Col>
                                </Row>
                            </Collapse>
                            <Collapse isOpen={!!isAddNewEvent}>
                                <Card className="p-3">
                                    <form onSubmit={this.createEvent}>
                                        <Row>
                                            <Col>
                                                {!this.props.job_id && <SelectList search={true} className="mt-2" placeholder="All Projects" value={isAddNewEvent.job_id}  onChange={({target}) => this.onChangeAddEvent("job_id", target.value) }>
                                                    {
                                                    jobList.map(job => 
                                                    <option value={job.job_id}>
                                                        {job.Employer.employer_name +  "  - "   + job.title}
                                                        {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                                    </option> 
                                                    )}
                                                </SelectList>
                                                }
                                                {
                                                    !!isAddNewEvent.job_id &&
                                                    <span style={{color: "#f33", fontWeight: "bold", fontSize: 12, display: "block"}}>
                                                    *This Event will show only to selected Project Locations</span>
                                                }
                                            </Col>
                                            {/* {isAddNewEvent.job_id && <Col>
                                                <SelectList className="mt-2" placeholder="All Locations" value={isAddNewEvent.job_location_id}  onChange={({target}) => this.onChangeAddEvent("job_location_id", target.value) }>
                                                    {
                                                    jobList.filter(job => job.job_id === isAddNewEvent.job_id)[0].JobLocations.map(loc => 
                                                    <option value={loc.job_location_id}>{loc.city}</option> 
                                                    )}
                                                </SelectList>
                                            </Col>} */}
                                        </Row>
                                        <InputFeild className="mt-2" required placeholder="Event Title" value={isAddNewEvent.title || ""} onChange={({target}) =>  this.onChangeAddEvent("title", target.value) } />
                                        <InputFeild className="mt-2" placeholder="Event Description" value={isAddNewEvent.description || ""} onChange={({target}) =>  this.onChangeAddEvent("description", target.value)} />
                                        <Row className="mt-2 align-items-center">
                                            <Col>
                                                <DatePicker id="event_date" required
                                                    selected={isAddNewEvent.date && moment(isAddNewEvent.date).toDate()}
                                                    autoComplete="off"
                                                    onChange={date => this.onChangeAddEvent("date", date)}
                                                    placeholderText="Event Date" minDate={new Date()} dateFormat="dd-MMM-yyyy EE"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker id="event_start_time" required
                                                    placeholderText="Start Time"
                                                    selected={isAddNewEvent.start_time}
                                                    onChange={(date) =>  this.onChangeAddEvent("start_time", date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    autoComplete="off"
                                                    dateFormat="h:mm aa"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker id="event_end_time" required
                                                    placeholderText="End Time"
                                                    selected={isAddNewEvent.end_time}
                                                    onChange={(date) =>  this.onChangeAddEvent("end_time", date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    autoComplete="off"
                                                    dateFormat="h:mm aa"
                                                />
                                            </Col>
                                        </Row>
                                        
                                        <SelectList className="mt-2" placeholder="Event Tags" value={isAddNewEvent.event_tags}
                                          onChange={({target}) =>  this.onChangeAddEvent("event_tags", target.value)}>
                                            {
                                            ["New", "Recommended", "Popular", "High Earnings", "Top Rated", "Trending", "Easy"].map(v => 
                                            <option value={v}>{v}</option> 
                                            )}
                                        </SelectList> 
                                        <div className="text-right mt-3"><Button className="buttonred" type="submit">Create Event</Button></div>
                                    </form>
                                </Card>
                            </Collapse>

                            <Row className={"py-1 bg-light border-bottom text-center"}>
                                <Col xs={5}>Event</Col>
                                <Col xs={3}>Time</Col>
                                <Col xs={2}>Join Link</Col>
                                <Col xs={2}>Actions</Col>
                            </Row>
                            {eventList.length ? <>
                            {
                                eventList
                                // .slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage)
                                .map((event, i) => {
                                    var meetLink = event.calendar_data && event.calendar_data.meetData ? 
                                     event.calendar_data.meetData[0].uri : null;
                                    var rating = event.total_rating ? 
                                    (event.positive_rating || 0)/(event.total_rating || 0)*5 : 0;
                                    var joiningRatio = moment().isAfter(event.end_date) ? 
                                    (event.attendees_joined || 0) / (event.attendeesCount || 0) * 100 : null
                                    return <>
                                    <Row className={"align-items-center text-center py-2 border-bottom"} style={{fontSize: 14}}>
                                        <Col xs={5} className="text-left">
                                            <strong>{(this.state.currentUserPage*this.state.rowsPerPage) + i+1}. </strong>
                                            <strong>{event.title}</strong> {event.event_tags && <span className="text-secondary ml-2 text-secondary px-1" style={{fontSize: 12, borderRadius: 10, background: "#ddd"}}>{event.event_tags}</span> }
                                            <span className="text-secondary d-block" style={{fontSize: 10}}>{event.description}</span>
                                        </Col>
                                        <Col xs={3}>
                                            <span className="d-block">{moment(event.start_date).utcOffset("+05:30").format("Do MMM'YY")}</span>
                                            <span className="d-block" style={{fontSize: 12}}>{moment(event.start_date).utcOffset("+05:30").format("hh:mmA")} - {moment(event.end_date).utcOffset("+05:30").format("hh:mmA")}</span>
                                            <span className="small text-muted">Created: {moment(event.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                                        </Col>
                                        <Col xs={2}>
                                            {meetLink && <a target="_blank" href={meetLink}><Icon className="pt-1" style={{fontSize: 18}}>link</Icon>Meet Link</a>}
                                            <span className="d-block text-muted small">By: {event.User.first_name}</span>
                                        </Col>
                                        <Col xs={2} className="text-left">
                                            <div className="align-middle d-inline-block text-center mr-3" style={{cursor: "pointer"}} 
                                            onClick={() => this.showEventAttendees(event)}>
                                            
                                                <Icon style={{ fontSize: 18, color: joiningRatio !== null ? 
                                                    (joiningRatio >= 60 ? "green" : (joiningRatio >= 30 ? "orange" : "red")) : "black"}} 
                                                     className="align-middle">people</Icon>
                                                <span className="d-block">{event.attendees_joined || 0} / {event.attendeesCount || 0}</span>
                                            </div>
                                            {!!event.total_rating && <div className="align-middle d-inline-block text-center mr-3" style={{cursor: "pointer"}} onClick={() => this.showEventAttendees(event)}>
                                                <Icon style={{ fontSize: 18, color: rating >= 3.5 ? "green" : (rating >= 2.5 ? "orange" : "red")}}  className="align-middle">star</Icon>
                                                <span className="d-block">{ rating.toFixed(1) }</span>
                                            </div>}
                                            { (!event.attendeesCount || isAdmin) && !isOnlyView &&
                                          
                                            <Icon className="align-middle" style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isOpenConfirm :  () => this.delete(event.event_id) })}} >delete</Icon>
                                           
                                            }
                                          
                                        </Col>
                                    </Row>

                                    {isShowAttendees.event && isShowAttendees.event.event_id === event.event_id && <div className="m-1 p-3 shadow">
                                       
                                        
                                        <Row className={"mt-2 pb-2 border-bottom"}>
                                            <Col><strong className="text-secondary">Participants (Joined: {isShowAttendees.data.filter(o => o.status === 2).length})</strong></Col>
                                            {isShowAttendees.selected && !!isShowAttendees.selected.length && <Col className="text-right"><Button onClick={() => this.updateEventAttendees(2)} className="py-0 buttonred">Mark Joined</Button></Col>}
                                            <Col><InputFeild onChange={(e) => this.setState({searchAttendeeText: e.target.value, currentAttendeePage: 0})} icon="search" placeholder="Search by Name/PMW-Id" /></Col>
                                        </Row>
                                        {moment().isAfter(isShowAttendees.event.end_date) && !!isShowAttendees.data.length && <Row className={"my-2 border-bottom pb-2"}>
                                            <Col xs={4}>
                                                <span className="small font-weight-bold">How was the experience?</span>
                                                <Row className={" mt-2"}>
                                                    <Col>
                                                        <Icon fontSize="large" className="text-success align-middle">thumb_up</Icon>{" "}
                                                        <strong className="align-middle text-secondary">{isShowAttendees.feedback.training_rating["3"] || 0}</strong>
                                                        <Icon fontSize="large" className="text-danger align-middle ml-3">thumb_down</Icon>{" "}
                                                        <strong className="align-middle text-secondary">{(isShowAttendees.feedback.training_rating["2"] || 0)+(isShowAttendees.feedback.training_rating["1"] || 0)}</strong>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={4} className="text-center">
                                                <span className="small font-weight-bold mb-2 ">Why not liked?</span>
                                                <Chip size="small" label={<span>Trainer did not explain properly <b> - {isShowAttendees.feedback.feedback_status["12"] || 0}</b></span>} /> 
                                                <Chip size="small" label={<span>Did not understand training <b> - {isShowAttendees.feedback.feedback_status["11"] || 0}</b></span>} /> 
                                                <Chip size="small" label={<span>Error while joining meeting <b> - {isShowAttendees.feedback.feedback_status["13"] || 0}</b></span>} /> 
                                            </Col>
                                            <Col xs={4} className="text-center ">
                                                <span className="small font-weight-bold mb-2 ">Why not joined?</span>
                                                <Chip size="small" label={<span>I'm not interested in this Project <b> - {isShowAttendees.feedback.feedback_status["2"] || 0}</b></span>} /> 
                                                <Chip size="small" label={<span>I was busy that time <b> - {isShowAttendees.feedback.feedback_status["1"] || 0}</b></span>} /> 
                                                {/* <Chip size="small" className="ml-2" label={<span>Having trouble joining training <b> - {isShowAttendees.feedback.feedback_status["3"] || 0}</b></span>} />  */}
                                                <Chip size="small" label={<span>I will join next session <b> - {(isShowAttendees.feedback.feedback_status["4"] || 0) + (isShowAttendees.feedback.feedback_status["5"] || 0)}</b></span>} /> 
                                            </Col>
                                        </Row>}
                                        <Row noGutters className="" style={{borderRadius: 8, fontSize: 14}}>
                                            {isShowAttendees.data
                                            .filter(att =>  searchAttendeeText ? ( (att.User.first_name || "").toLowerCase().includes(searchAttendeeText.toLowerCase()) || ("PMW"+att.User.id).toLowerCase().includes(searchAttendeeText.toLowerCase()) ) : true)
                                            .slice(this.state.currentAttendeePage*15, this.state.currentAttendeePage*15 + 15).map(att => 
                                                <Col xs={6} md={4}>
                                                    <Row noGutters className={"align-items-center border m-1 shadow-sm p-1"}>
                                                        <Col xs={2}>
                                                            {/* {att.status === 2  ? 
                                                                <Icon fontSize="small">task_alt</Icon> :
                                                                <input type="checkbox" className="m-2" checked={isShowAttendees.selected && isShowAttendees.selected.includes(att.event_attendee_id)} onChange={(e) => this.selectAttendees(att.event_attendee_id)} />
                                                            } */}
                                                        </Col>      
                                                        <Col xs={10}>
                                                            <strong className="d-block">{att.User.first_name}</strong>
                                                            <span className="small">PMW{att.User.id}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                        <TablePagination rowsPerPageOptions={[15]} component="div" count={isShowAttendees.data.length} rowsPerPage={15} page={this.state.currentAttendeePage}
                                            onChangePage={ (e, page) => this.setState({currentAttendeePage : page}) }
                                        
                                        />
                                    </div>}
                                </>
                                })
                            }
                            <TablePagination rowsPerPageOptions={[20]} component="div" count={this.state.eventCounts} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                                // onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                                onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                                onChangePage={ (e, page) => this.fetchEvents(page) }

                            />
                            </>
                            :
                            <em className="d-block text-center text-secondary mt-3">No events created yet</em>
                            }
                        
                        
                        <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

            </>
            
            
        );
    }
}
export default Events;
