import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody, ModalBody, Modal, ModalHeader } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { downloadApiFile} from "../../../actions";
import DatePicker from "react-datepicker";
import moment from "moment";
import Aux from "../../global/AuxComp";


class ManageMaterialDownloads extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpenTransactionDownload : false,
        stores :[],
        isOpenDownloads: false
      }
    }
    componentDidMount(){
        
    }

    downloadMaterial = async () => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.props.job.job_id ,
                job_location_id: this.props.store.job_location_id
               }
            var resp = await ApiEndpoints.StoreManager.downloadMaterial(body);
            downloadApiFile(resp, "Download Material.csv")

        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadTransaction = async () => {
        store.dispatch(setLoader(true))
        let {selectedStoreId, isOpenTransactionDownload} = this.state;
        try {
            var body = {
                job_id : this.props.job.job_id ,
                store_id : this.props.store.store_id,
                start_date : isOpenTransactionDownload.start_date ? moment(isOpenTransactionDownload.start_date).startOf("day").toDate() : null,
                end_date : isOpenTransactionDownload.end_date ? moment(isOpenTransactionDownload.end_date).endOf("day").toDate() : null
               }
            var resp = await ApiEndpoints.StoreManager.downloadTransaction(body);
            downloadApiFile(resp, "Download Material.csv")

        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    


       render(){
           let { isOpenDownloads, isOpenTransactionDownload} = this.state;
           let {job, store} = this.props;
           return(   
            <Aux>
                <Button disabled={!store} color="link" onClick={() => this.setState({isOpenDownloads : !isOpenDownloads})}  >
                    <Icon style={{fontSize: 18, color: "#000"}}>save_alt</Icon>
                </Button>
                <Modal centered={true} isOpen={isOpenDownloads} toggle={()=> {this.setState({isOpenDownloads : false, isOpenTransactionDownload: false})}} >
                    <ModalHeader className="py-1"><h6>Download Report for Selected Store</h6></ModalHeader>
                    <ModalBody>
                       <Row>
                           <Col className="">
                               <Button onClick={this.downloadMaterial} className="buttonred mr-3">Download Store Report</Button>
                               <Button onClick={() => this.setState({isOpenTransactionDownload : isOpenTransactionDownload ? false : {}})} className="buttonred">Download Issue Report</Button>
                           </Col>
                       </Row>
                       <Collapse isOpen={!!isOpenTransactionDownload}>
                            <Card className="mt-2 mb-3">
                            <CardBody>
                                <Row className=" mt-1">
                                    <Col>
                                        <DatePicker
                                            selected={isOpenTransactionDownload ? isOpenTransactionDownload.start_date : null}
                                            onChange={date => {isOpenTransactionDownload.start_date = date; this.setState({isOpenTransactionDownload})}}
                                            className="mt-3"
                                            dateFormat='dd-MMM-yyyy'
                                            placeholderText="Start Date"
                                        />
                                    </Col>
                                    <Col>
                                        <DatePicker
                                            selected={isOpenTransactionDownload ? isOpenTransactionDownload.end_date : null}
                                            onChange={date => {isOpenTransactionDownload.end_date = date; this.setState({isOpenTransactionDownload})}}
                                            className="mt-3"
                                            dateFormat='dd-MMM-yyyy'
                                            placeholderText="End Date"
                                        />
                                    </Col>
                                    <Col className="mt-3">
                                        <Button onClick={this.downloadTransaction} className="buttonred" style={{height: "23px", paddingTop: "1px"}} >Download</Button>{' '}
                                    </Col>
                                </Row>
                            </CardBody>
                            </Card>
                        </Collapse>
                    </ModalBody>
                </Modal>

                        
            </Aux>

           );
       }
    }
export default ManageMaterialDownloads;
