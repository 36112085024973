
import React, { Component } from "react";
import {Icon,Switch} from '@material-ui/core';
import {withRouter} from 'react-router-dom';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { Button, TextField } from "@material-ui/core";
import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import moment from "moment";
import OutboundCallingStatus from "../outbound-calling-status";
import ConfirmAction from "../confirm-action";
import TicketTransfer from "./ticket_transfer";
import CONSTANTS from "../../../constants";
import { getTextTranslation, groupArrayOnKey, uploadFile } from "../../../actions";
import {io} from "socket.io-client";
import ServicePurchase from "../../views/reports/service_purchase";
import JobInformation from "../../views/project/job_information";
import SelectList from "../form/select";
import RichTextEditor from "../../global/form/Richtext";
import TICKET_CONSTANTS from "./ticket_message_templates";

var socket = null;

class TicketDetail extends Component {
    constructor(props) {
      super(props);
      this.state = {
       chatData: [],
       isTransfer: false,
       addChat : {},
       jobData: null,
       formResponseData: null,
       servicePurchaseData: null,
       isShowJobDetails: null,
       isTranslatedView:false,
       languages:["en","hi","ta","te","kn"],
       languageMap:{
        "en":"English",
        "hi":"Hindi",
        "ta":"Tamil",
        "te":"Telugu",
        "kn":"Kannada"
        },
        isShowTemplates: false,
        selectedReplyTemplateCategory: "GENERAL"
      }
    }

    componentDidMount(){
      this.fetchTicketConversation();
      this.getTicketMetaData();
      
      const SERVER = process.env.REACT_APP_BASE_URL;
      socket = io (SERVER, { transports: ['websocket']});
      socket.emit("join_ticket", {id: this.props.ticketData.ticket_id} );

      socket.on('error', err => {
        console.log("Socket Error: ", err);
        socket.emit("join_ticket", {id: this.props.ticketData.ticket_id} );
      });
      socket.on('disconnect', () => {
        console.log("Socket Disconnect");
        socket.emit("join_ticket", {id: this.props.ticketData.ticket_id} )
      })

      socket.on('TICKET_CHAT_ADD', (resp) => {
        parseInt(resp.direction) === 1 && this.addChatData({...resp, created_on: moment().toDate(), User: this.props.ticketData.User })
      });
    }

    componentWillUnmount(){
      socket.disconnect();
    }

    fetchTicketConversation = async () => {
      store.dispatch(setLoader(true));
      try{
        let body = { ticket_id: this.props.ticketData.ticket_id };
        if(this.props.ticketData.ref_type === "FORM_RESPONSE" && this.props.ticketData.ref_id) body.form_response_id = this.props.ticketData.ref_id;
        if(this.props.ticketData.tag_type === "SERVICE_PURCHASE" && this.props.ticketData.ref_id) body.service_purchase_id = this.props.ticketData.ref_id;
        let apiData = await ApiEndpoints.Support.fetchTicketConversation(body);
        this.setState({chatData: apiData.data.data});
        if(apiData.data.message && apiData.data.message.formResponse) this.setState({ formResponseData: apiData.data.message.formResponse })
        if(apiData.data.message && apiData.data.message.servicePurchase) this.setState({ servicePurchaseData: apiData.data.message.servicePurchase })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    getTicketMetaData = () => {
      let ticketData = this.props.ticketData;
      if(ticketData.tag_type === "JOB"){
        let jobList = store.getState().jobList;
        jobList = groupArrayOnKey(jobList, "job_id", true);
        this.setState({ jobData: jobList[ticketData.tag_id] || null })
      }
    }

    makePhoneCall = async (user) => {
      store.dispatch(setLoader(true));
        let { ticketData } = this.props;
        try{
          if(this.state.callDetails){
              store.dispatch(setError({show: true, message: "You are already on another call."}))
              return store.dispatch(setLoader(false));
          }
          var body = { user_id : user.user_id, entity_type: "PORTFOLIO", ticket_id: this.props.ticketData.ticket_id, direction: 2 }
          if(!ticketData.agent_user_id) body.map_agent = true;
          let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
          let chatData = this.state.chatData;
          chatData.push(resp.data.data);
          this.setState({ callDetails: { calling_id : resp.data.data.Sid, user: user }, chatData });
        }
        catch(e){
          store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
        }
        store.dispatch(setLoader(false));
    }
    
    updateLastCall = (callData) => {
      let chatData = this.state.chatData;
      let chatIndex = (chatData || []).findIndex(obj => obj.call_log_id === callData.call_log_id);
      chatData[chatIndex] = callData;
      this.setState({chatData})
    }

    fileChangedHandler = (e) => {
      let { addChat } = this.state;
      if(e){
        let file = e.target.files[0];
        e.target.value = null;
        var fileSize = file.size / 1024 / 1024;
        if( (fileSize >= 15 )) return store.dispatch(setError({show: true, message: "File size is "+fileSize.toFixed(2)+"MB, It should be less than 15MB"}));
        addChat.file = file;
      } else{
        addChat.file = null;
        this.setState({file: null})
      }
      this.setState({addChat})
    }

    addChatData = (chat) => {
      let { chatData } = this.state;
      chatData.push(chat);
      this.setState({ chatData })
    }


    updateBookmarkStatus = async () => {
      store.dispatch(setLoader(true));
      let { ticketData } = this.props;
      try{
        let bookmarkStatus = ticketData.is_bookmarked ? 0 : 1;
        let body = { ticket_id: ticketData.ticket_id, is_bookmarked: bookmarkStatus}
        await ApiEndpoints.Support.updateTicket(body);
        ticketData.is_bookmarked = bookmarkStatus;
        this.props.updateTicketData(ticketData, "is_bookmarked");
      }
      catch(e){
        store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
      }
      store.dispatch(setLoader(false));
    }


    sendChat = async () => {
      store.dispatch(setLoader(true));
      let { ticketData } = this.props;
      let { addChat } = this.state;
      try{
        var body = { ticket_id: ticketData.ticket_id, chat_message: addChat.message, language: ticketData.language};
        if(addChat.showTranslateOptions && addChat.translatedText) body.chat_message = body.chat_message + '\n'+addChat.translatedText;
        if(addChat.file){
            let fileResp = await uploadFile(addChat.file, "TICKET");
            body.file_id = fileResp.file_id;
        }
        if(!ticketData.agent_user_id) body.map_agent = true;
        let resp = await ApiEndpoints.Support.sendChat(body);
        this.addChatData({...resp.data.data, created_on: moment().toDate(), User: store.getState().user })
        this.setState({ addChat: {} });
      }
      catch(e){
        store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
      }
      store.dispatch(setLoader(false));
    }

    showChatAttachment = async (fileId) => {
      store.dispatch(setLoader(true));
      try{
        var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
        const link = document.createElement('a');
        link.href = fileResp.data.data[0].url;
        link.setAttribute('target', "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
      catch(e){
        console.log(e)
        store.dispatch(setError({show: true, message: "Something went wrong"}))
      }
      store.dispatch(setLoader(false));
    }
  
    markResolveTicket = () => {
      let { ticketData } = this.props;
      if(ticketData.ticket_type === null || ticketData.ticket_type === 'NA' || (String(ticketData.ticket_type) === "0" && ticketData.ticket_title === "Others")) this.setState({ isTransfer: { edit: true } });
      else this.setState({ isConfirm: () => this.props.markTicketResolved(ticketData), isConfirmMessage: null })
    }


    translateUserChatText = async (chat) => {
      store.dispatch(setLoader(true));
      let { chatData } = this.state;
      try{
      chat.translatedText = await getTextTranslation(chat.chat_message, null, "en");
      chatData = chatData.map(obj => obj.ticket_chat_id === chat.ticket_chat_id ? chat : obj);
      this.setState({ chatData });
      }
      catch(e){
        console.log(e)
        store.dispatch(setError({show: true, message: "Something went wrong"}))
      }
      store.dispatch(setLoader(false));

    }

    translateAddChat = async (source, target) => {
      store.dispatch(setLoader(true));
      let { addChat } = this.state;
      try{
        if(addChat.message){
          addChat.translatedText = await getTextTranslation(addChat.message, source, target);
          this.setState({ addChat });
        }
      }
      catch(e){
        console.log(e)
        store.dispatch(setError({show: true, message: "Something went wrong"}))
      }
      store.dispatch(setLoader(false));
    }

    onChangeLanguage = async (value) => {
      store.dispatch(setLoader(true));
      let { ticketData } = this.props;
      try{
        var body = { ticket_id: ticketData.ticket_id, language: value};
        await ApiEndpoints.Support.updateTicket(body);
        ticketData.language=value;
      }
      catch(e){
        console.log(e.response.data.message)
        store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Something went wrong"}));
      }
      store.dispatch(setLoader(false));
    }

    render() {
      let { chatData, addChat,  isTransfer, jobData, formResponseData, servicePurchaseData, isShowJobDetails, languages, languageMap, isShowTemplates, selectedReplyTemplateCategory} = this.state;
      let { ticketData } = this.props;
      chatData = [...chatData].reverse();
      
      return isShowTemplates ? this.TicketMessageTemplates(selectedReplyTemplateCategory) 
      : 
      isTransfer ? <TicketTransfer {...this.props} transferOpts={isTransfer} addChatData={this.addChatData} onClose={() => this.setState({ isTransfer: false })} />
      :
      (
          <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
					  <header style={{flex: 0, position: "sticky", top: 0, zIndex: 99}}>
              <Row className="py-1 align-items-center border-bottom" style={{background: "#fff"}}>
                <Col xs={1} className="text-center p-0">
                  {jobData ? 
                    <img className="rounded-circle clickable" style={{width: "90%" , aspectRatio: "1"}} src={jobData.Employer.logo_key || DUMMY_PROFILE_IMG}  alt="Logo Pic.." onClick={() => this.setState({ isShowJobDetails: jobData })} />
                    :
                    <Icon className="align-middle" style={{width: "auto", height: "auto", padding: 5, background: "#ccc", borderRadius: "50%"}}>{CONSTANTS.TICKET_TYPE_ICON[ticketData.ticket_type] || "question_mark"}</Icon>
                  }
                </Col>
                <Col>
                  <div>
                    <span className="d-block">{ticketData.ticket_title}</span>
                    <span className="small">{ticketData.tag_title}</span>
                  </div>
                  {
                    !!jobData && <div className="clickable" onClick={() => this.setState({ isShowJobDetails: jobData })}>
                      <span className="small">{jobData.Employer.employer_name}-{jobData.title}  {jobData.archive_status !== 0 && <span style={{fontSize: 8}} className="ml-3 bg-light shadow-sm px-2 border font-weight-bold py-1">{CONSTANTS.ARCHIVE_STATUS_TEXT[jobData.archive_status]} </span> }</span>
                    </div>
                  }
                  {/* <span className="d-block small mb-2"><Switch size="small" color="secondary" checked={this.state.isTranslatedView} onChange={(e) => {this.setState({isTranslatedView:!this.state.isTranslatedView}); console.log(this.state.isTranslatedView)}}  />{" "} View Translation</span> */}
                </Col>
                <Col xs={3} className="text-right  align-items-center " style={{display:"flex" , justifyContent:"space-between"}}>
                    {/* <span className="rounded-circle  p-2 mr-2" style={{cursor: "pointer", background: ticketData.is_bookmarked ? "#fdf2f2" : "#f4f4f4"}} onClick={() => this.updateBookmarkStatus(ticketData) } >
                      <Icon className="align-middle" fontSize="small" style={{color: ticketData.is_bookmarked ? "#e40001" : "#000"}}>push_pin</Icon>
                    </span> */}
                    <div className="w-[40%]" style={{display:"flex",flexDirection:"column"}}>
                      <span className="small mr-1">#{ticketData.ticket_id}</span>
                      {/* {!!ticketData.language && <span className="small align-items-center" style={{display:"flex"}}>{ticketData.language}<Icon fontSize="small" style={{fontSize:"14px",marginLeft:3}}>g_translate</Icon></span>} */}
                      {!!ticketData.language && <SelectList placeholder="Language type" value={ticketData.language || ""} onChange ={(e) => this.onChangeLanguage(e.target.value)} >
                      {languages.map((lang, i) => 
                          <option key={i} value={lang}>{languageMap[lang]}</option>
                      )}
                    </SelectList>}
                    </div>
                    <span className="rounded-circle  p-2 mr-1" style={{cursor: "pointer"}}><Icon className="align-middle" onClick={() => this.setState({ isTransfer: {edit: true, transfer: true} })}>call_made</Icon></span>
                </Col>
              </Row>
              {!!formResponseData && this.formResponseDetails(formResponseData) }
              {!!servicePurchaseData && this.servicePurchaseDetails(servicePurchaseData) }
            </header>
            <main style={{height: "100%", overflowY: "auto", marginLeft: -15, marginRight: -15, display: "flex", flexDirection: "column-reverse"}} className="bg-light px-3 ">
                {
                  chatData.map(chat => {
                    if(chat.ticket_chat_id)
                      return (
                        <Row className={"my-2 "+ (parseInt(chat.direction) === 2 ? "justify-content-end":"")}>
                          <Col xs={8}>
                              <div className="card px-3 py-2 shadow-sm" style={{background: "#fff", borderRadius: 8, fontSize: 14, position: "relative"}}>
                                  <Row className="mb-1" noGutters style={{fontSize: 12}}>
                                      <Col className="text-secondary font-weight-bold">{this.getUserName(chat.User)}</Col>
                                      <Col className="text-secondary text-right">{moment(chat.created_on).utcOffset("+05:30").format("Do MMM, hh:mm A")}</Col>
                                  </Row>
                                  <span style={{whiteSpace: "pre-line",marginBottom:2}}>
                                  {!!chat.translated_message && <div className="border-bottom" style={{paddingTop:2,paddingBottom:2}} dangerouslySetInnerHTML={{__html:chat.translated_message}} />}
                                  </span>
                                  <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html:chat.chat_message}} />
                                  {/* {!!chat?.translated_message &&  <span className="d-block my-2"><strong>Translated</strong> {chat?.translated_message}</span>} */}
                                  {!!chat.file_id  && <span className="clickable d-block text-danger my-1" onClick={() => this.showChatAttachment(chat.file_id)}><Icon className="text-danger align-middle mr-2" style={{transform: "rotate(45deg)", fontSize: 14}}>attach_file</Icon>Attachment</span>}
                                  {/* {parseInt(chat.direction) === 1 && !!chat.chat_message && !chat.translatedText && <Icon className="clickable" style={{position: "absolute", right: 3, bottom: 3, fontSize: 14}} onClick={() => this.translateUserChatText(chat) }>g_translate</Icon>} */}
                              </div>
                          </Col>
                        </Row>
                      )
                    else if (chat.call_log_id)
                      return <Row className={"my-2 "+ (chat.direction === 2 ? "justify-content-end":"")}>
                          <Col xs={8}>
                              <div className="card px-3 py-2 shadow-sm" style={{background: "#fff", borderRadius: 8, fontSize: 14}}>
                                  <Row className="mb-1" noGutters style={{fontSize: 12}}>
                                      <Col className="text-secondary font-weight-bold">{this.getUserName(chat.User)}</Col>
                                      <Col className="text-secondary text-right">{moment(chat.created_on).utcOffset("+05:30").format("Do MMM, hh:mm A")}</Col>
                                  </Row>
                                  <Row className="my-2">
                                      <Col><Icon className="mr-2 align-middle" style={{fontSize: 16}}>call</Icon>{chat.direction === 2 ? "Outgoing Call" : "Incoming Call"}</Col>
                                      <Col className="text-capitalize text-right">{ chat.status }</Col>
                                  </Row>
                                  {!!chat.call_recording_url && <audio className="py-2" preload={false} controls><source src={chat.call_recording_url} type="audio/ogg" /></audio>}
                              </div>
                          </Col>
                        </Row>
                    else 
                      return <Row className={"my-2 justify-content-center"}>
                          <Col xs={8}>
                              <div className="px-3 py-1 shadow-sm" style={{background: "#ededed", borderRadius: 24, fontSize: 12}}>
                                  <Row className="align-items-center" noGutters >
                                      {chat.field_type === "agent_user_id" && <Col xs={1}><Icon style={{fontSize: 16, background: "#fff", borderRadius: "50%", padding: 1}}  fontSize="small">call_made</Icon></Col>}
                                      <Col className="text-secondary font-weight-bold">{this.getUserName(chat.User)}</Col>
                                      <Col className="text-secondary text-right">{moment(chat.created_on).utcOffset("+05:30").format("Do MMM, hh:mm A")}</Col>
                                  </Row>
                                  {chat.field_type === "agent_user_id" && <span className="d-block">Transferred ticket to {this.getUserName(chat.currUser)}</span>}
                                  {chat.field_type === "status" && <span className="d-block">Ticket updated to {CONSTANTS.TICKET_STATUS_TEXT[chat.field_value]}</span>}
                                  {!!chat.comment && <span className="d-block">{chat.comment}</span>}
                              </div>
                          </Col>
                        </Row>
                  })
                }
            </main>
            <footer style={{flex: 0, position: "sticky", bottom: 0, background: "#fff", marginLeft: -15, marginRight: -15}} className="px-3">
              <Row className="align-items-center my-3 py-1">
                {!!addChat.file && 
                  <Col xs={12}>
                    <a target="_blank" href={URL.createObjectURL(addChat.file)}>
                      <span className="px-2 my-2 d-inline-block card py-1 small text-dark bg-light" style={{borderRadius: 25}}>File Attached - {addChat.file.name}</span>
                    </a>
                    <Icon fontSize="small" className="align-middle ml-2 clickable" onClick={() => this.fileChangedHandler(null)}>cancel</Icon>
                  </Col>
                }
                {/* {
                  !!addChat.showTranslateOptions && <Col xs={12} className="">
                      <span onClick={() => this.translateAddChat("hi", "kn") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small mr-2" style={{borderRadius: 24}}>{"Hindi -> Kannada"}</span>
                      <span onClick={() => this.translateAddChat("hi", "ta") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small mr-2" style={{borderRadius: 24}}>{"Hindi -> Tamil"}</span>
                      <span onClick={() => this.translateAddChat("hi", "te") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small" style={{borderRadius: 24}}>{"Hindi -> Telugu"}</span>
                      <br />
                      <span onClick={() => this.translateAddChat("en", "kn") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small mr-2" style={{borderRadius: 24}}>{"English -> Kannada"}</span>
                      <span onClick={() => this.translateAddChat("en", "ta") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small mr-2" style={{borderRadius: 24}}>{"English -> Tamil"}</span>
                      <span onClick={() => this.translateAddChat("en", "te") } className="d-inline-block clickable mb-2 bg-light border px-2 py-1 small" style={{borderRadius: 24}}>{"English -> Telugu"}</span>
                      {!!addChat.translatedText && <span className="d-block mt-2">{addChat.translatedText}</span>}
                  </Col>
                } */}
                <Col>
                  <div>
                    <RichTextEditor height={false} hideToolbar={true} placeholder="Enter message here..." value={addChat.message || ""} onChange={(v) => {addChat.message = v; this.setState({addChat})}}/>
                    <input accept="image/png, image/jpg, image/jpeg, video/mp4, application/pdf" id="icon-button-photo" style={{display: "none"}} onChange={this.fileChangedHandler} type="file"/>
                    <Icon onClick={() => document.getElementById('icon-button-photo').click()} style={{cursor: "pointer", position: "absolute", right: 15, top: 15}}>attach_file</Icon>
                  </div>
                  {/* <TextField
                    placeholder='Enter message here...'
                    required={true}
                    InputProps={{
                      inputProps: {maxLength: 750},
                      style: {borderRadius: 8, padding: 10, borderColor: "red"},
                      endAdornment: <> </>
                    }}
                    className="w-100" variant="outlined" multiline
                    value={addChat.message || ""}
                    onChange={(e) => {addChat.message = e.target.value; this.setState({addChat})}}
                  /> */}
                </Col>
                <Col xs={2} className="text-right">
                  <Button disabled={!addChat.message} onClick={() => this.sendChat()} variant="contained" className="bg-danger text-light" color="secondary" style={{borderRadius: 24}} >send</Button>
                </Col>
              </Row>
              <Row className="my-3 py-1 align-items-center">
                    <Col xs="auto">
                      <Button size="small" disabled={!this.props.prevTicket} onClick={() => this.props.selectTicket(this.props.prevTicket)} variant="outlined" className="text-capitalize" style={{borderRadius: 24, minWidth: 45}} >
                        <Icon style={{fontSize: 14}} className="align-middle">arrow_back_ios</Icon>
                      </Button>
                    </Col>
                    
                    <Col xs="auto">
                      <Button size="small" variant="outlined" color="primary" className="text-capitalize w-100" style={{borderRadius: 24, minWidth: 45}} onClick={() => this.setState({isConfirm: () => this.makePhoneCall(ticketData.User), isConfirmMessage : "Are you sure to connect with "+ ticketData.User.first_name+"?"})}>
                        <Icon style={{fontSize: 22}} className="align-middle">phone</Icon>
                      </Button>
                    </Col>
                    <Col>
                      <Button size="small" variant="outlined" color="default" className="text-capitalize w-100" style={{borderRadius: 24}} onClick={() => this.setState({ isShowTemplates: true })}>
                        <Icon style={{fontSize: 14}} className="align-middle mr-1">message</Icon>Templates
                      </Button>
                    </Col>
                    <Col>
                      <Button disabled={[30, 40].indexOf(ticketData.status) !== -1} size="small" variant="outlined" className="text-capitalize bg-light w-100" style={{borderRadius: 24}} onClick={() => this.markResolveTicket() }>
                        <Icon style={{fontSize: 14}} className="align-middle mr-1">chat</Icon>{ticketData.status === 40 ? "Closed" : ticketData.status === 30 ? "Resolved" : "Ask to Close"}
                      </Button>
                    </Col>
                    <Col xs="auto">
                      <Button size="small" disabled={!this.props.nextTicket} onClick={() => this.props.selectTicket(this.props.nextTicket)} variant="outlined" className="text-capitalize" style={{borderRadius: 24, minWidth: 45}}><Icon style={{fontSize: 14}} fontSize="small" className="align-middle">arrow_forward_ios</Icon></Button>
                    </Col>
              </Row>
            </footer>

            <Modal centered size="lg" isOpen={!!isShowJobDetails} toggle={() => this.setState({ isShowJobDetails: null })}>
              <ModalBody  toggle={() => this.setState({ isShowJobDetails: null })}>
                {!!isShowJobDetails && <JobInformation job={isShowJobDetails} isOnlyView={true} {...(formResponseData && formResponseData.status === 2 ? { selectedTab: "REQUIREMENTS_TAB"} : {} ) } />}
              </ModalBody>
            </Modal>


            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
            {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} onUpdateCall={ this.updateLastCall } showOnlyStatus={true} isSkipCallFeedback={true} />}

          </div>
        );
     }

    getUserName = (chatUser) => {
      if(!chatUser) return "";
      let user = store.getState().user;
      return user.user_id === chatUser.user_id ? "You" : (chatUser.first_name || chatUser.id)
    }

    formResponseDetails = (formResponseData) => {
      return (
        <Row className="border-bottom py-1 small align-items-center">
          <Col>
            <span className="d-block"><Icon className="align-middle mr-2" style={{fontSize: 14}}>add_circle_outline</Icon>{moment(formResponseData.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
            <span><Icon className="align-middle mr-2" style={{fontSize: 14}}>update</Icon>{moment(formResponseData.modified_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
          </Col>
          <Col> 
            <span className="bg-light border rounded px-2 py-1 small">{ formResponseData.status === 2 ? "Rejected" : formResponseData.status === 1 ? "Verified" : "Pending" }</span>
            {formResponseData.sub_status == 1 && <span className="text-danger border border-danger rounded px-2 py-1 small ml-2" >Partial</span>}
            {!!formResponseData.archive_status && <span className="text-danger border border-danger rounded px-2 py-1 small ml-2" style={{backgroundColor: "#ff000020"}}>Expired</span>}
          </Col>
          <Col>
            <span><Icon className="align-middle mr-2" style={{fontSize: 14}}>comment</Icon>{formResponseData.archive_status && !formResponseData.status_comment ? "Unable to track lead" : formResponseData.status_comment}</span>
          </Col>
        </Row>
      );
    }

    servicePurchaseDetails = (servicePurchaseData) => {
      return (
        <Row className="border-bottom py-1 small align-items-center">
          <Col>
            <span className=""><Icon className="align-middle mr-2" style={{fontSize: 14}}>add_circle_outline</Icon>{moment(servicePurchaseData.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}{(" - ")}</span>
            <span className="">RTXN{(servicePurchaseData.service_purchase_id)}</span>
            <span className="mr-2 d-block"><Icon className="align-middle mr-2" style={{fontSize: 14}}>update</Icon>{moment(servicePurchaseData.modified_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
          </Col>
          <Col xs="6"> 
            {ServicePurchase.getStatusBadge(servicePurchaseData.status)}
            {ServicePurchase.getRefundBadge(servicePurchaseData)}
            {ServicePurchase.getDisputeBadge(servicePurchaseData)}
          </Col>
        </Row>
      );
    }

    TicketMessageTemplates = (selectedReplyTemplateCategory) => {
      return <div>
        <Row className="border-bottom align-items-center">
          <Col className=""><span className="small font-weight-bold">Message Templates</span></Col>
          <Col xs="auto"><Icon className="m-1 clickable" onClick={() => this.setState({ isShowTemplates: false })}>close</Icon></Col>
        </Row>
        <div style={{position: "absolute", width: "100%", height: "92%", overflowY: "scroll", marginLeft: "-12px", marginRight: "-12px"}}>
        {TICKET_CONSTANTS.REPLY_TEMPLATES.map(templateGroup => {
          return <>
            <div className="card border bg-light mt-2 p-2 clickable font-weight-bold small"  onClick={() => this.setState({ selectedReplyTemplateCategory: selectedReplyTemplateCategory === templateGroup.type ? false : templateGroup.type })}>
              {templateGroup.label}
              <Icon style={{position: "absolute", right: 10}}>expand_more</Icon>
            </div> 
            <div>
              { selectedReplyTemplateCategory === templateGroup.type &&
                templateGroup.texts.map(template => {
                  let randomNumber = Math.floor(Math.random()*template.text.length);
                  return <div className="small card shadow-sm m-2 p-2 clickable" onClick={() => this.setState({ addChat: { message: template.text[randomNumber] }, isShowTemplates: false })}>
                    <span className="d-block font-weight-bold text-secondary">{template.label}</span>
                    <span className="limit-line-3">{template.text[randomNumber]}</span>
                  </div>
                })
              }
            </div>
          </>
        }) }
        </div>
      </div>
    }
  }
  export default withRouter(props => <TicketDetail {...props} />);


