import React, { Component } from 'react';
import Routes from './components/routes';
import {withRouter} from 'react-router-dom';
import { setUser } from './actions/action-creators';
import { connect } from 'react-redux';


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      variable:"hello"
      }
  }

  render(){
    return (
      <Routes />
    );
  }
}


const mapStateToProps = state => ({
  ...state
})
const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(setUser(user))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
