
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import { Button, Modal, ModalBody, ModalHeader ,ModalFooter, Table } from "reactstrap";
import { Badge, Icon, TablePagination } from "@material-ui/core";
import UserCard from "../user_profile/user_card";
import moment from "moment";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import OutboundCallingStatus from "../../global/outbound-calling-status";
import WhatsappChat from "../../global/whatsapp_chat";


class ToBeActivate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: [],
            currentUserPage: 0,
            rowsPerPage: 20,
            userCount: 0,
            searchText: "",
            callData: [],
            whatsappChat: false,

            
        };
    }


    componentDidMount(){
        this.fetchToBeActiveUser();
    }

    fetchToBeActiveUser = async(page = 0) => {
        store.dispatch(setLoader(true))
        try{
            var body = { 
                limit: this.state.rowsPerPage ,
                offset : page * this.state.rowsPerPage
            };
            if(this.state.searchText) body.search = this.state.searchText.toLowerCase().replace("pmw", "");
            let apiData = await ApiEndpoints.ToBeActivePartner.fetchToBeActiveUser(body);
            let userList = apiData.data.data.rows;
            let userCount = apiData.data.data.count;
            this.setState({ userList, userCount, currentUserPage: page })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    showCallData = async (user) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                user_id: user.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "GENERAL",
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }

    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : this.state.isOpenNotificationModal,
            notification_type : e.target.notification_type.value,
            origin_type : "GENERAL",
            sms : false
        } 
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }

  render() {
    let { userList, userCount , searchText} = this.state;
    // if(searchText) {
    //     searchText = searchText.toLowerCase().replace("pmw", "");
    //     userList = userList.filter(obj =>  (obj.User.first_name || "").toLowerCase().includes(searchText) ||  ("" + obj.User.id).includes(searchText));
    // }

    // var userRole = store.getState().user.UserRole
    // var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;

    return (<>
      

        <Row className={"my-2 pt-2 border-top align-items-end"}>
            <Col xs={12} md={8}>
                <strong className="m-0">To Be Activate Partners</strong>
            </Col>  
            
            <Col className="justify-self-end">
                <form onSubmit={async (e) => {e.preventDefault(); this.fetchToBeActiveUser()}}>
                    <InputFeild onChange={(e) => this.setState({searchText :e.target.value})} value={this.state.searchText} icon="search" onClickIcon={this.applyFilter} placeholder=" search by Name/PMW-Id" />
                </form>
            </Col>
           
        </Row>

        {
        userList.length ? 
        <>
        {
        userList
        .map((mappedUser, i) =>
             <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                <Col>
                    <Row noGutters className="align-items-center">
                        <Col className="align-items-center">
                            <UserCard data={mappedUser.User} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} xs={12} className="text-center my-1">
                    <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(mappedUser)}>{mappedUser.connected_call_counts || ""}</span> 
                    <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(mappedUser), isConfirmMessage : "Are you sure to connect with "+ mappedUser.User.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: mappedUser})}><img src={WhatsappIcon} alt="" style={{fontSize:16, cursor: "pointer"} }/></Button></Badge> */}
                    <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: mappedUser.user_id})}>notifications_none</Icon></Button>
                    {/* <Button outline className="px-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.showApplicationComment(applicant)}>comment</Icon></Button> */}
                </Col>
                <Col md={2} xs={12} className="text-center">
                    {
                         !!mappedUser.isPendingWhatsapp &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Whatsapp - {moment(mappedUser.isPendingWhatsapp.created_on).fromNow()} </span>
                    }
                    {
                       !!mappedUser.isMissedCall &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(mappedUser.isMissedCall.created_on).fromNow()} </span>
                    }
                </Col>
                <Col md={3} xs={12} className="text-center">
                <em style={{fontSize: 12}}>{mappedUser.Event.title}</em> 
                </Col>

                <Col md={2} xs={12} className="text-right"> 
                    <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{moment(mappedUser.Event && mappedUser.Event.start_date).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                </Col>
            </Row>
            )}
            <TablePagination rowsPerPageOptions={[20, 40, 60, 100, 200]} component="div" count={userCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
               onChangePage={ (e, page) => this.fetchToBeActiveUser(page) }
               onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
            />
            </>
            : 
            <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
            
        }

                <Modal isOpen={this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
                    <form onSubmit={this.handleNotificationSubmit}>
                        <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                            <ModalBody>
                                <label className="radio-inline"><input type="radio" className="mr-3" name="notification_type" value="NOTIFICATION" checked />General Notification</label>
                                <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                                <InputFeild required placeholder="Title" name="title" />
                                <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                                {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                            </ModalBody>
                            <ModalFooter style={{height: "45px"}}>
                            <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                            <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>

                <Modal size={"lg"} centered isOpen={!!this.state.callData.length} toggle={()=> {this.setState({callData : []})}} >
                    <ModalHeader toggle={()=> {this.setState({callData : []})}}>Call Details</ModalHeader>
                    <ModalBody className="p-2">
                    <Table bordered className="table-sm mt-2 mb-0 text-center" style={{fontSize: 14}}>
                                <thead>
                                    <tr>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.callData.map((call, i) =>
                                    <tr>
                                      <td>{(call.userDetail || {}).first_name}</td>
                                      <td>{call.direction === 1 ? "In" : "Out"}</td>
                                      <td>
                                        {call.call_recording_url ?
                                        <audio preload={true} style={{maxWidth: 185}} controls><source src={call.call_recording_url} type="audio/ogg"  /></audio>
                                        :
                                        <span>{call.status}</span>
                                        }
                                      </td> 
                                      <td><span>{call.comment}</span></td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        {/* <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em> */}
                                      </td>
                                    </tr>
                                  ) 
                                  }
                                </tbody>
                            </Table>  
                    </ModalBody>
            </Modal>


        {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

    </>)
  }
}


export default ToBeActivate;
