import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser, groupArrayOnKey } from "../../../actions";
import ConfirmAction from "../../global/confirm-action";

class ManageRecruiter extends Component {
    constructor(props) {
      super(props);
      this.state = {
            recruiters : [],
            isAddNewRecruiter : false,
            isOpenAddRecruiter : false,
            selectedRecruiter : {},
            selectedJobLocations : [],
            user : {},
            editRecruiter : {},
            editJobLocationId : [],
            isOpenConfirm : false,
        }
    }
    componentDidMount(){
        this.fetchRecruiters();
    }
    
    fetchRecruiters = async () => {
        store.dispatch(setLoader(true))
        let { job } = this.props;
        try{
          var accessOpts = {
            access_type : "EMPLOYER",
            access_type_id : this.props.job.employer_id,
            role_id: 4
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let recruiters = accessResp.data.data
          var locationAccess = {};
          if(job.JobLocations.length){
              let locationAccessResp = await ApiEndpoints.User.fetchMappedAccess({
                access_type : "JOB_LOCATION",
                access_type_id : job.JobLocations.map(loc => loc.job_location_id).join(","),
                role_id: 4
              });
              locationAccess = locationAccessResp.data.data;
              var locations = groupArrayOnKey(job.JobLocations, "job_location_id", true)
              locationAccess = locationAccess.map(access => {
                 access.location = locations[access.access_type_id] || null;
                 return access;
              })
              locationAccess = groupArrayOnKey(locationAccess, "user_id")
          }
          recruiters = recruiters.map(manager => {
              manager.JobLocations = (locationAccess[manager.user_id] || []).filter(access => !!access.location).map(access => {return {...access.location, ...access}});
              return manager;
          })
          this.setState({recruiters})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }
    onChangeRecruiter = (e) =>{
        let {recruiters} = this.state;
        if (e.target.value !== "ADD_NEW") 
        this.setState({selectedRecruiter : {...recruiters[e.target.value], index : e.target.value} });
        else this.setState({isOpenAddRecruiter : true, user : {}})
    }

    addRecruiter = async (e) =>{
        e.preventDefault();
        let { user} = this.state;
        store.dispatch(setLoader(true))
          try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var body = {...user, role_id : 4, access_type : "EMPLOYER", access_type_id : this.props.job.employer_id}
            await addUser(body);
            await this.fetchRecruiters()
            this.setState({isOpenAddRecruiter : false , user : {}});
            store.dispatch(setSuccess({show: true, message: "Recruiter Added!"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    addRecruiterToLocation = async () =>{
        let { selectedJobLocations, selectedRecruiter } = this.state;
        store.dispatch(setLoader(true))
        try{
            await Promise.all(selectedJobLocations.map(job_location_id => {
              var userBody = {
                user_id : selectedRecruiter.user_id,
                user_role_id : selectedRecruiter.user_role_id,
                access_type : "JOB_LOCATION",
                access_type_id : job_location_id
              }
              return addUser(userBody)
            }));
          this.setState({isAddNewRecruiter : false, selectedJobLocations : [], selectedRecruiter : {}})
          this.fetchRecruiters();
          store.dispatch(setSuccess({show: true, message: "Recruiter have been associated with Locations"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))


    }

    editRecruiter = async () => {
        let {editJobLocationId, editRecruiter} = this.state;
        store.dispatch(setLoader(true))
        try{
            let mappedJobLocationIds = editRecruiter.JobLocations.map(o => o.job_location_id);
            let deletedAccessId = editRecruiter.JobLocations.filter(a => editJobLocationId.indexOf(a.job_location_id) === -1).map(a => a.access_id);
            let newJobLocationIds = editJobLocationId.filter(v => mappedJobLocationIds.indexOf(v) === -1);
            deletedAccessId.length && await ApiEndpoints.User.inactiveAccess({access_id : deletedAccessId});
            await Promise.all(newJobLocationIds.map(job_location_id => {
                var userBody = {
                  user_id : editRecruiter.user_id,
                  user_role_id : editRecruiter.user_role_id,
                  access_type : "JOB_LOCATION",
                  access_type_id : job_location_id
                }
                return addUser(userBody)
            }));
            this.setState({editRecruiter : {}})
            this.fetchRecruiters();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    deletedRecruiter = async (access_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                access_id
              }
            await ApiEndpoints.User.inactiveAccess(body);
            await this.fetchRecruiters()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

       render(){
           let {recruiters, isAddNewRecruiter, selectedRecruiter, selectedJobLocations, isOpenAddRecruiter, editRecruiter, editJobLocationId} = this.state;
           let {job} = this.props;
           
           return(
               
            <div>
                <Row>
                    <Col>
                    <Button className="buttonred my-3" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isAddNewRecruiter : true})}>Add Recruiter</Button>{' '}
                    </Col>
                </Row>
                <Row>
                    <Col xs={1} ><h6>S.No</h6> </Col>
                    <Col xs={6}> <h6>Recruiter</h6> </Col>
                    <Col xs={5}> <h6>City Name</h6> </Col>
                </Row>
                {isAddNewRecruiter && 
                <Row>
                    <Col xs={1} ></Col>
                    <Col xs={6} style={{ marginBottom: "10px"}}>
                    <SelectList addNew={true} placeholder="Select Recruiter" value={selectedRecruiter.index} required onChange={(e) => this.onChangeRecruiter(e)} >
                        {
                            recruiters.map((recruiter, i) => 
                                    <option value={i}>{recruiter.User.first_name} | {recruiter.User.mobile}</option>
                            )
                        }
                        </SelectList>
                    </Col>
                    <Col xs={3}  style={{ marginBottom: "10px"}}>
                        <SelectList multiple="checkbox" placeholder="Select City" value={selectedJobLocations} required onChange={(e) => this.setState({selectedJobLocations : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                    </Col>
                    <Col xs={2}> 
                        <div style={{marginTop: "7px"}}>
                            <Icon onClick={this.addRecruiterToLocation} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                            <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isAddNewRecruiter : false})}>close</Icon>
                        </div>
                    </Col>
                </Row>}
                <Collapse isOpen={isOpenAddRecruiter}>
                    <Card>
                    <CardBody>
                    <form onSubmit={this.addRecruiter}>
                            <Row style={{marginTop: "-20px"}}>
                                <Col xs={4}>
                                    <InputFeild required name="first_name" onChange={this.onChangeUser}  className="mt-3" placeholder="Name" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild required name="mobile" type="number" onChange={this.onChangeUser} className="mt-3" placeholder="Phone Number" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild name="email" onChange={this.onChangeUser} className="mt-3" placeholder="Email Address" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "20px", float: "right"}}>
                                <Col style={{marginRight : "-22px"}}>
                                    <Button type="submit" className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </Col>
                                <Col>
                                    <Button onClick={() => {this.setState({isOpenAddRecruiter: false})}}  type="button" color="secondary" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    </Card>
                </Collapse>
                {recruiters.map((recruiter, i) => 
                    editRecruiter.user_id !== recruiter.user_id ?
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={6}>{recruiter.User.first_name+" | "+recruiter.User.mobile}</Col>
                        <Col xs={5}>
                            <span className="float-left">{recruiter.JobLocations.map(loc => loc.city).join(", ") }</span>
                            <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedRecruiter([recruiter.access_id, ...(recruiter.JobLocations.map(loc => loc.access_id))])}) } style={{cursor: "pointer"}} fontSize="small" className="float-right ml-3">delete</Icon>

                            <Icon onClick={() => this.setState({editRecruiter : recruiter, editJobLocationId : recruiter.JobLocations.map(obj => obj.job_location_id)})} style={{cursor: "pointer"}} fontSize="small" className="float-right">edit</Icon>
                        </Col>
                    </Row> : 
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={6}>{recruiter.User.first_name+" | "+recruiter.User.mobile}</Col>
                        <Col xs={4}>
                            <SelectList multiple="checkbox" placeholder="Select City" value={editJobLocationId} required onChange={(e) => this.setState({editJobLocationId : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option key={i} value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                        </Col>
                        <Col>
                            <div style={{marginTop: "7px"}}>
                                <Icon onClick={this.editRecruiter} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({editRecruiter : {}})}>close</Icon>
                            </div>
                        </Col>
                    </Row>
                )}
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />

            </div>

           );
       }
    }
export default ManageRecruiter;
