
import React, { Component } from 'react';
import { Drawer, Icon } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import store from '../../store';
import moment from 'moment';
import WhatsappChat from './whatsapp_chat';


class WhatsappChatMultiple extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users : this.props.users
				.sort((a, b) => new Date(b.modified_on) < new Date(a.modified_on) ? -1 : 1)
				.sort((a, b) => b.isPendingWhatsapp ? 1 : -1)
		}
	}

	componentDidMount() {
	}
	
	
	render() {
		
		let loggedInUser = store.getState().user;
		let { selectedJob, isOpen } = this.props;
		let { whatsappChat, users } = this.state;
		return (
			<Drawer PaperProps={{style: {maxWidth: 420, width: "100%"}}} style={{position: "relative"}} anchor={"right"} open={isOpen} onClose={this.props.onClose}>
				<div style={{width: "100%", height: "100vh", display: "flex", flexDirection: "column", background: "#8eff0015"}}>
					<Row className="align-items-center">
						<Col>
							<h6 className="m-3">New users or Not started any Project</h6>
						</Col>
						<Col xs={"auto"} className="text-right">
							<Icon style={{cursor: "pointer"}} onClick={this.props.onClose}>close</Icon>
						</Col>
					</Row>
					{
						users.map(chat => 
							<Row noGutters className="border-bottom py-2 m-1 align-items-center" style={{background: "#0901", fontSize: 14, cursor: "pointer", borderRadius: 8}} onClick={() => this.setState({whatsappChat: chat})}>
								<Col xs={2} className="text-center"><img src='/images/dummy_pic.jpg' width="35" style={{borderRadius: "50%"}} alt='profile_pic' /></Col>
								<Col xs={6}>
									<strong>{chat.User && chat.User.first_name ? chat.User.first_name : " - "}</strong>
									{chat.isPendingWhatsapp && <div className='ml-3 d-inline-block' style={{ background: "red", padding: 5, borderRadius: 5 }}></div>}
									<span className='d-block'>
										{ chat.User && chat.User.id ? <>PMW{chat.User.id}</> : "Lead User"}
									</span>
									<span>{chat.mobile_no}</span>
								</Col>
								<Col xs={4} style={{fontSize: 12}} className="text-muted text-right pr-1">
									<span>Chat Started On</span> <br />
									<strong>{moment(chat.created_on).utcOffset("+05:30").format("Do MMM'YY")}</strong><br />
									<span>{moment(chat.created_on).utcOffset("+05:30").format("hh:mmA")}</span><br />
									{chat.entity_type === "ONBOARDED" && <strong>*App Installed</strong>}
								</Col>
							</Row>
						)
					}
					
				</div>
				{!!this.state.whatsappChat && <WhatsappChat user={whatsappChat.User || { mobile: whatsappChat.mobile_no }} entity_type={whatsappChat.entity_type} entity_id={null} jobTitle={"PickMyWork" || (selectedJob.Employer.employer_name + "-" + selectedJob.title)} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})}  />}

			</Drawer>
		)
	}
}

export default WhatsappChatMultiple;
