import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from 'reactstrap/lib/Button';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select"
import InputFeild from "../../global/form/input";
import moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import CONSTANTS from "../../../constants"
import { Chip } from "@material-ui/core";
import  OutboundCallingStatus  from "../../global/outbound-calling-status";
import { uploadFile } from "../../../actions";

class UploadLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            job_id: this.props.jobData.job_id,
            origin: null,
            job_location_id: null,
            jobLocations: this.props.jobData.JobLocations,
            isReadyToSubmit: true,
            success: "",
            error: ""
        }
    }

    
    async componentDidMount(){
    }


    uploadLeadByCsv = async (e) =>{
        e.preventDefault();
        this.setState({isReadyToSubmit : false})
        var file = e.target.csvFile.files[0];
        let { job_id, job_location_id, origin } = this.state;
        var loggedInUser = store.getState().user;
        store.dispatch(setLoader(true))
        try{
            if(!job_id || !job_location_id || !origin) throw new Error ("Please fill all fields");

            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                job_id,
                job_location_id,
                recruiter_id: loggedInUser.user_id,
                lead_origin: origin,
                key : fileResp.file_key
            }
            let resp = await ApiEndpoints.Jobs.uploadLead(body);
            store.dispatch(setSuccess({show: true, message: resp.data.data.length + " Records created successfully"}))
            this.props.close && this.props.close()
            // downloadApiFile(resp, "wallet Upload Response.csv")
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({isReadyToSubmit : true})
        store.dispatch(setLoader(false))
    }

    render() {
        let { origin, job_location_id, jobLocations, isReadyToSubmit, success, error } = this.state;
        return (
            <form className="py-2" onSubmit={this.uploadLeadByCsv}>
                
                <InputLabel className="mt-3">Select Location</InputLabel>
                <SelectList placeholder="Select Location" value={job_location_id} onChange={(e) => this.setState({job_location_id : e.target.value})}>
                    {jobLocations.map(loc => 
                        <option value={loc.job_location_id}>
                            {loc.city}
                            {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                        </option>
                    )}
                </SelectList>
                <InputLabel className="mt-3" htmlFor="origin_select">Lead Origin</InputLabel>
                <SelectList required placeholder="Select Origin" value={origin} onChange={(e) => this.setState({origin: e.target.value})}>
                    {Object.keys(CONSTANTS.APPLICATION_ORIGIN).map(originStatus => 
                        <option value={originStatus}>{CONSTANTS.APPLICATION_ORIGIN[originStatus]}</option>
                    )}
                </SelectList>

                <input id="upload_leads" required type="file" name="csvFile" className="mt-4 mb-2" />
                <p style={{fontSize: 12}}><em>Download Sample File, Fill the details and upload here</em></p>
                <a target="_blank" download={true} rel="noopener noreferrer" href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/upload+leads+sample.xlsx">Download Sample File</a>
                            
                <hr className="mt-3" />
                <Row>
                    <Col xs={12} className="text-right">
                        
                        <Button className="buttonred mr-2" onClick={this.props.toggleOpenModal}>Cancel</Button>
                     
                        <Button disabled={!isReadyToSubmit} className="buttonred">Submit Lead</Button>
                      
                    </Col>
                </Row>
                {success && <em style={{color: "rgb(12, 68, 12)", fontSize: 12}}>{success}</em>}
                {error && <em style={{color: "#f00", fontSize: 12}}>{error}</em>}
            </form>
        ) 
    }
}

export default UploadLeads;
