
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import { Button, Modal, ModalBody, ModalHeader ,ModalFooter, Table, ButtonGroup } from "reactstrap";
import { Badge, Icon, TablePagination } from "@material-ui/core";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import UserCard from "../user_profile/user_card";
import OutboundCallingStatus from "../../global/outbound-calling-status";


class ToBeActivate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStateTab: 1,
            isFilters: { start_date: moment().subtract(3, "days").toDate(), end_date: moment().toDate() },
            userList: [],
            currentUserPage: 0,
            rowsPerPage: 20,
            userCount: 0,
            callDetails: false
        };
    }


    componentDidMount(){

        this.changeUserTab(this.state.activeStateTab);

    }

    changeUserTab = async (tab, page) =>{
        await this.setState({userList: [], userCount: 0, currentUserPage: 0, activeStateTab: tab});

        this.fetchUsers(page);
    }


    fetchUsers = async(page = 0) => {
        store.dispatch(setLoader(true))
        try{
            let { isFilters, activeStateTab } = this.state;
            var body = { 
                limit: this.state.rowsPerPage ,
                offset : page * this.state.rowsPerPage,
                ...isFilters
            };
            if(activeStateTab > 1) body.status =  activeStateTab === 3 ? 2 : 0;
            if(body.start_date) body.start_date = moment(body.start_date).startOf("day").toDate();
            if(body.end_date) body.end_date = moment(body.end_date).endOf("day").toDate();
            if(body.search) body.search = body.search.toLowerCase().replace("pmw", "");
            let apiData = activeStateTab > 1 ? 
                await ApiEndpoints.ToBeActivePartner.fetchFirstTaskAttemptedUser(body)
                :
                await ApiEndpoints.ToBeActivePartner.fetchInterestedUser(body)
            let userList = apiData.data.data.rows || [];
            let userCount = apiData.data.data.count || 0;
            this.setState({ userList, userCount, currentUserPage: page })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
        try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.User.user_id,
                entity_type: "POTENTIAL_USER",
                subentity_type: this.state.activeStateTab === 1 ? "INTERESTED" : "TASK_ATTEMPTED",
                subentity_id: this.state.activeStateTab > 2 ? 2 : 0,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user.User
            }});
        }
        catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
        }
        store.dispatch(setLoader(false));
    }

    showCallData = () => {
        window.alert("Feature coming soon..")
    }
    
  render() {
    let { isFilters, activeStateTab, userList, userCount } = this.state;
   
    return (<>
      
        <Row className={"my-2 py-2 border-bottom align-items-end"}>
            <Col md={5} xs={12}>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button className="px-3" onClick={() => this.changeUserTab(1)} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === 1 ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">Interested</Button>
                    <Button className="px-3" onClick={() => this.changeUserTab(2)} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === 2 ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">Pending Task</Button>
                    <Button className="pr-3" onClick={() => this.changeUserTab(3)} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === 3 ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">Rejected Task</Button>
                </ButtonGroup>
            </Col>
            <Col xs={2} className="justify-self-end">
                <InputFeild onChange={(e) => {isFilters.search = e.target.value; this.setState({isFilters} ) }} value={isFilters.search} icon="search" placeholder="search by Name/PMW-Id" />
            </Col>
            <Col xs={2}>
                <ReactDatePicker
                    selected={isFilters.start_date || null}
                    onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                    dateFormat='dd-MMM-yyyy'
                    placeholderText="Start Date"
                />
            </Col>
            <Col xs={2}>
                <ReactDatePicker
                    selected={isFilters.end_date || null}
                    onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                    dateFormat='dd-MMM-yyyy'
                    placeholderText="End Date"
                />
            </Col>
            <Col xs={1}>
                <Button color="dark" size="sm" onClick={() => this.changeUserTab(activeStateTab)}>Apply</Button>
            </Col>
        </Row>


        {
        userList.length ? 
        <>
            {userList.map((mappedUser, i) =>
                <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                    <Col>
                        <UserCard data={mappedUser.User} />
                    </Col>
                    <Col md={2} xs={12} className="text-center my-1">
                        <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(mappedUser)}>{mappedUser.connected_call_counts || ""}</span> 
                        <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(mappedUser), isConfirmMessage : "Are you sure to connect with "+ mappedUser.User.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                    </Col>
                    <Col md={2} xs={12} className="text-center">
                        {!!mappedUser.isMissedCall && <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(mappedUser.isMissedCall.created_on).fromNow()} </span>}
                    </Col>
                    <Col md={3} xs={12} className="text-center">
                        {activeStateTab === 1 && <em style={{fontSize: 12}}>{mappedUser.application_count} Projects</em> }
                        {activeStateTab > 1 && <em style={{fontSize: 12}}>{mappedUser.jobData.job_id ? mappedUser.jobData.Employer.employer_name+" - "+mappedUser.jobData.title : ""}</em> }
                    </Col>
                    <Col md={2} xs={12} className="text-right"> 
                        <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{moment(mappedUser.created_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                    </Col>
                </Row>
            )}
            <TablePagination rowsPerPageOptions={[20, 40, 60, 100, 200]} component="div" count={userCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
               onChangePage={ (e, page) => this.changeUserTab(activeStateTab, page) }
               onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
            />
        </>
        : 
        <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
        }

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

      
    </>)
  }
}


export default ToBeActivate;
