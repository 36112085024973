import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import Table from "reactstrap/lib/Table";
import { TablePagination } from "@material-ui/core";


class UserPayouts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            payoutData : [],
            currentPage: 0,
          
        }
    }

    componentDidMount(){
        this.showPayoutData();
        // this.fetchPayoutData();
    }
    // fetchPayoutData = async (selectedJob) => {
    //     store.dispatch(setLoader(true))
    //     try{
    //         var body = { user_id : this.props.user_id }
    //         if(selectedJob){
    //             body.entity_type = ["JOB", "JOB_HELP"];
    //             body.entity_id = selectedJob
    //         }
    //         let resp = await ApiEndpoints.JobTaskPayment.fetchUserPayouts(body)
    //         let payoutData = resp.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on) )
    //         this.setState({ payoutData, selectedJob, currentPage: 0 })
    //     }
    //     catch(e){
    //         store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
    //     }
    //     store.dispatch(setLoader(false))
    // }
  
    showPayoutData = async (user_id, job_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
               user_id: this.props.user_id
            };
            let payoutDataResp = await ApiEndpoints.JobTaskPayment.fetchUserPayouts(body);
            let payoutData = payoutDataResp.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
         await this.setState({ payoutData })

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      
    
    render() {
        let { payoutData } = this.state;
        return (
            <div className="px-1 text-center" style={{fontSize: 14}}>                   
                   <Table>
                       <thead>
                           <tr>
                               <th>Payout Title</th>
                               <th>Amount</th>
                               <th>Status</th>
                           </tr>
                       </thead>
                       <tbody>

                           {payoutData.length ?
                            payoutData
                            .slice(this.state.currentPage*10, this.state.currentPage*10 + 10)
                            .map(payout => 
                               <tr>
                                   <td>
                                       <strong className="d-block">{payout.Payout.payout_title}</strong>
                                       <span className="small">{moment(payout.created_on).utcOffset("+05:30").format("Do MMM YY")}</span>
                                    </td>
                                   <td>{payout.amount}</td>
                                   <td>{payout.status_text}</td>
                               </tr>
                           ) :<em className="m-4 text-secondary d-block">No Payout data</em> }
                       </tbody>
                 
                   </Table>                     
                   <TablePagination rowsPerPageOptions={[15]}  count={payoutData.length} rowsPerPage={10}
                    page={this.state.currentPage}
                    onChangePage={ (e, page) => this.setState({currentPage : page}) }
                     component="div"/>
            </div>
           
        );
    }
}
export default UserPayouts;
