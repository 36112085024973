import React, { Component }from "react";
import  { Button, Row, Col } from "reactstrap/lib";
import store from "../../../store";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import moment from "moment";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Icon } from "@material-ui/core";


class UpcomingWebinar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            eventList: []
        }
    }

    componentDidMount(){
        this.fetchUpcomingWebinar();
    }

    fetchUpcomingWebinar = async () => {
        let { user_id, job_id } = this.props;
        store.dispatch(setLoader(true))
        try{
            var body = {
               start_date_range : moment().toDate()
            }
            if(job_id) body = {...body, entity_type: "JOB", entity_id: job_id}
            else body = {...body, entity_type: "GENERAL"}
               
            let resp = await ApiEndpoints.Events.fetchEvents(body);

            this.setState({ eventList: resp.data.data.rows })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    sendWebinarInvite = async (e, event) => {
        let { user_id } = this.props;
        store.dispatch(setLoader(true))
        try{
            var body = {
                user_id,
                event_id: event.event_id,
                status: 1
            }               
            await ApiEndpoints.Events.insertEventAttendees(body);
            store.dispatch(setSuccess({show: true, message: "Webinar invite sent to whatsapp successfully!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    render() {
        let { eventList } = this.state;
        return (
        <>        
            <h6>Upcoming Webinars</h6>
            {
                !eventList.length ? <em>No Upcoming webinar</em>
                :
                <Row noGutters className="flex-nowrap" style={{overflowX: "scroll"}}>
                    {eventList.map(event => 
                        <Col xs={10} lg={5}>
                            <div className="shadow-sm p-2 m-1" style={{borderRadius: 12, fontSize: 14}}>
                                <Row noGutters className="align-items-center">
                                    <Col>
                                        <span className="d-block font-weight-bold">{event.title}</span>
                                        <span>{moment(event.start_date).utcOffset("+5:30").format("Do MMM'YY - hh:mm A")}</span>
                                    </Col>
                                    <Col xs={2} className="text-center">
                                        <Button color="light" size="sm" className="" onClick={(e) => this.sendWebinarInvite(e, event)}><Icon style={{fontSize: 16}} className="align-middle">{"share"}</Icon></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )}
                </Row>

            }
        </>   
        );
    }
}
export default UpcomingWebinar;
