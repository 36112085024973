
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, ModalFooter } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Badge, Chip, Icon, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";
import UploadLeads from "./upload_leads";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import CustomerCard from "./customer_card";
import Checkbox from '@material-ui/core/Checkbox';

class Leads extends Component {
    constructor(props) {
        super(props);
        this.LEAD_COLORS = {
            0: "#ccc",
            1: "#00f000",
            2: "#f00"
        }
        this.state = {
          jobList: [],
          leadsDataCount: 0,
          leadsData : [] ,
          isAddLead: false,
          selectedJob: null,
          rowsPerPage: 20,
          currentUserPage: 0,
          isConfirm: false,
          callDetails: false,
          searchText:"",
          selectedLeadIds: [],
          statusFilter: "",
          isOpenUpdateStatus: false
        }
    }

    async componentDidMount(){
        let jobList = store.getState().jobList;
        await this.setState({ jobList })
        if(jobList.length) this.getLeads(jobList[0].job_id)
    }





    getLeads = async (jobId, page = 0) => {
      store.dispatch(setLoader(true));
      let { jobList, statusFilter } = this.state;
      try{
        let body = {
            job_id: jobId,
            limit: 20,
            offset: 20 * page
        };
        if(statusFilter !== "") body.status = statusFilter;
        let apiData = await ApiEndpoints.Customer.fetchLeads(body);
        var resp = apiData.data.data;
        let selectedJob = jobList.filter(job => job.job_id == jobId)[0];
        await this.setState({leadsDataCount: resp.count, leadsData: resp.rows, selectedJob, currentUserPage: page })
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))

  }

  makePhoneCall = async (lead) => {
    store.dispatch(setLoader(true));
      try{
        if(this.state.callDetails){
            store.dispatch(setError({show: true, message: "You are already on another call."}))
            store.dispatch(setLoader(false));
            return;
        }
        var body = {
            customer_lead_id : lead.customer_lead_id,
            entity_type: "JOB",
            entity_id: this.state.selectedJob.job_id,
            subentity_type: "CUSTOMER_LEAD",
            subentity_id: lead.customer_lead_id,
            direction: 2
        }
        let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
        
        this.setState({callDetails: {
            calling_id : resp.data.data.Sid,
            user: {first_name: lead.name}
        }});
      }
      catch(e){
        store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
      }
      store.dispatch(setLoader(false));
    }

    sendLeadInvite = async (lead) => {
    store.dispatch(setLoader(true));
      try{
        var body = {
            customer_lead_id : lead.customer_lead_id,
            job_id: this.state.selectedJob.job_id
        }
        await ApiEndpoints.Customer.sendJobInvite(body);
        
        let { leadsData } = this.state;
        leadsData = leadsData.map(obj => {
            if(obj.customer_lead_id === lead.customer_lead_id) obj.isSentInvite = true;
            return obj;
        });
        this.setState({ leadsData });
      }
      catch(e){
        store.dispatch(setError({show: true,  message: e.response ? e.response.data.message : e.toString()  }))
      }
      store.dispatch(setLoader(false));
    }

    onSelectLead = (e) => {
        let {selectedLeadIds} = this.state;
        if(e.target.checked) selectedLeadIds.push(parseInt(e.target.value));
        else {
            selectedLeadIds.splice(selectedLeadIds.indexOf(parseInt(e.target.value)), 1)
        }
        this.setState({selectedLeadIds})
    }

    updateLeadStatus = async (e, status) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let { selectedLeadIds } = this.state;
        try{
            var body = {
                customer_lead_id : selectedLeadIds,
                job_id: this.state.selectedJob.job_id,
                status,
                status_comment: e.target.comment && e.target.comment.value
            }
            await ApiEndpoints.Customer.updateCustomerJobStatus(body);
            
            let { leadsData } = this.state;
            leadsData = leadsData.map(obj => {
                if(selectedLeadIds.indexOf(obj.customer_lead_id) !== -1) {
                    obj.CustomerJobMapping.status = status;
                    obj.CustomerJobMapping.status_comment = body.status_comment;
                }
                return obj;
            });
            this.setState({ leadsData, isOpenUpdateStatus: false, selectedLeadIds: [] });
        }
        catch(e){
            store.dispatch(setError({show: true,  message: e.response ? e.response.data.message : e.toString()  }))
        }
        store.dispatch(setLoader(false));
    }
    

    render() {
        let jobList = store.getState().jobList;
        var userRole = store.getState().user.UserRole
        var isAllowToVerify = !!userRole.filter(role => [1, 3].indexOf(role.role_id) !== -1).length;
        let { leadsData, isAddLead, selectedJob, isConfirm, searchText, selectedLeadIds, statusFilter, isOpenUpdateStatus } = this.state;
        if(searchText)  leadsData = leadsData.filter( obj =>searchText ? ((obj.name || "").toLowerCase().includes(searchText.toLowerCase())) : true);
        return (
            <>
                    <Row className="border-bottom py-2 mb-3 align-items-center justify-content-between">
                        <Col xs={5}>
                            <SelectList placeholder="Select Project" search={true} value={selectedJob && selectedJob.job_id} onChange ={(e) => this.getLeads(e.target.value)} >
                                { jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                            </option>)}
                            </SelectList>
                        </Col>
                        <Col xs={3}></Col>
                        <Col xs={2}>
                            <SelectList placeholder="Verification Status" selectAll="All Status" value={statusFilter} onChange ={async (e) => {await this.setState({ statusFilter: e.target.value }); this.getLeads(selectedJob.job_id, 0); } } >
                                <option value={0}>Requested</option>
                                <option value={1}>Verified</option>
                                <option value={2}>Rejected</option>
                            </SelectList>
                        </Col>
                        <Col xs={"auto"} className="text-right">
                            {!!selectedJob && <Button className="mb-3 buttonred" onClick={() => this.setState({isAddLead: 1})}>Add Lead</Button>}
                        </Col>
                    </Row>
                   
                    
                    <Row>
                        <Col>
                            {!!selectedLeadIds.length && <>
                                <strong className="d-inline-block small mr-3">Update Status: </strong>
                                <Button className="buttonred mr-3" onClick={(e) => this.setState({ isOpenUpdateStatus: 0 })}>Requested</Button>
                                {isAllowToVerify && 
                                <>
                                    <Button className="buttonred mr-3" onClick={(e) => this.setState({isOpenUpdateStatus: 1})}>Verify</Button>
                                    <Button className="buttonred mr-3" onClick={(e) => this.setState({isOpenUpdateStatus: 2})}>Reject</Button>
                                </>
                                }
                            </>}
                        </Col>
                        <Col xs={3}>
                            <InputFeild onChange={(e) => this.setState({searchText: e.target.value})} icon="search" placeholder="Search" />
                        </Col>
                    </Row>

                    {leadsData.length ? <>
                    {
                        leadsData
                        .map((lead, i) => {
                            
                            return <>
                            <Row key={i} noGutters className="border shadow-sm mx-2 py-3 my-2 align-items-center" style={{borderRadius: 12}}>
                                <Col style={{maxWidth: 25, borderLeft: `6px solid ${this.LEAD_COLORS[lead.CustomerJobMapping.status]}`}}>
                                    {!lead.CustomerJobMapping.status ? <Checkbox color="secondary" size="small" value={lead.customer_lead_id} checked={selectedLeadIds.indexOf(lead.customer_lead_id) !== -1} onChange={this.onSelectLead} />: <div style={{height: 40}}>{" "}</div>}
                                </Col>
                                <Col >
                                    <CustomerCard data={lead} />
                                </Col>
                                <Col md={3} xs={12} className="text-center my-1">
                                    {/* <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(lead)}>{lead.connected_call_counts || ""}</span>  */}
                                    <span className="mr-1" style={{}}>{lead.connected_call_counts || ""}</span> 
                                    <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!lead.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(lead), isConfirmMessage : "Are you sure to connect with "+lead.name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                                    <Button outline className="px-1 pb-1 pt-0" color="secondary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.sendLeadInvite(lead), isConfirmMessage : "Are you sure to send SMS invite to "+lead.name+"?"})} style={{fontSize:16, cursor: "pointer"} }>{lead.isSentInvite ? "mark_email_read" : "mail"}</Icon></Button>
                                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!applicant.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: applicant.User})}> <img src={WhatsappIcon} alt="" style={{cursor : "pointer"}} /></Button></Badge> */}
                                    {/* <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: {user_id: applicant.user_id} })}>notifications_none</Icon></Button> */}
                                </Col>
                                <Col md={3} xs={12}>
                                    {!!lead.last_call && <span className="small d-block"><span style={{fontSize: 10}}>&#128222;</span>{lead.last_call.status}-{moment(lead.last_call.call_scheduled_on || lead.last_call.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")} {!!lead.last_call.comment && `(${lead.last_call.comment})`}</span>}
                                    {lead.CustomerJobMapping.status === 2 && <span className="d-block text-danger small">{ lead.CustomerJobMapping.status_comment }</span>}

                                </Col>
                                <Col md={2} xs={12} className="text-right">
                                    <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-secondary d-block">Lead Added</em>
                                    <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{moment(lead.created_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                                </Col>
                            </Row>
                                
                                
                        </>
                        })
                    }
                    <TablePagination rowsPerPageOptions={[20]} component="div" count={this.state.leadsDataCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                        // onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                        onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                        onChangePage={ (e, page) => this.getLeads(selectedJob.job_id, page) }

                    />
                    </>
                    :
                    <em className="d-block text-center text-secondary mt-3">No leads created yet</em>
                    }
                
                        
            <Modal isOpen={isAddLead}>
                <ModalHeader toggle={() => {this.setState({isAddLead : false})}}>
                    <Row className="px-2">
                        {/* <Col xs="auto" onClick={() => this.setState({addLead: 1 })} className={" " + (isAddLead === 1 && "border-bottom")} style={{cursor: "pointer"}}><h6>Add Lead</h6></Col>
                        <Col xs="auto" className="border-right px-2 mr-2"></Col> */}
                        <Col xs="auto" onClick={() => this.setState({isAddLead: 2})} className={"" + (isAddLead === 2 && "border-bottom")} style={{cursor: "pointer"}}><h5>Upload Lead</h5></Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                {!!isAddLead && <UploadLeads jobData={selectedJob} close={() => { this.setState({isAddLead: false}); this.getLeads(selectedJob.job_id) } }/>  } 
                </ModalBody>
            </Modal>

            <Modal isOpen={isOpenUpdateStatus !== false} toggle={() => this.setState({isOpenUpdateStatus : false})}>
                    <ModalHeader toggle={() => this.setState({isOpenUpdateStatus : false})}>Update Status</ModalHeader>
                    <form onSubmit={(e) => this.updateLeadStatus(e, isOpenUpdateStatus)}>
                    <ModalBody>
                        <strong>Are you sure to update status of selected leads?</strong>
                        {isOpenUpdateStatus === 2 && <textarea required={true} name="comment" placeholder="Enter the reason for rejection" rows={3}  className="form-control my-2" />}
                    </ModalBody>
                    <ModalFooter>
                        <em className="text-muted">{selectedLeadIds.length} Lead{selectedLeadIds.length > 1 && "s"} selected</em>{' '}
                        <Button size="sm" color="danger">
                            {isOpenUpdateStatus === 0 && "Mark Requested"}
                            {isOpenUpdateStatus === 1 && "Verify"}
                            {isOpenUpdateStatus === 2 && "Reject"}
                        </Button>
                        <Button size="sm" className="ml-3" onClick={() => this.setState({isOpenUpdateStatus : false})}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>

            {!!this.state.callDetails && <OutboundCallingStatus showOnlyStatus={true} calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}


            <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!isConfirm} callback={isConfirm} onClose={() => this.setState({ isConfirm: false }) } />

            </>
            
            
        );
    }
}
export default Leads;
