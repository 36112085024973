
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Checkbox, Chip, Icon, Switch, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import Collapse from "reactstrap/lib/Collapse";
import Card from "reactstrap/lib/Card";
import DatePicker from "react-datepicker";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";
import { downloadApiFile } from "../../../actions";
import WebinarAttendance from "./attendance";
import { Link } from "react-router-dom";

class Webinar extends Component {
    constructor(props) {
        super(props);
        this.state = {
           jobList : store.getState().jobList,
           jobCategory: [],
           eventList : [],
           rowsPerPage : 20,
           currentUserPage : 0,
           currentAttendeePage: 0,
           isAddNewEvent: false,
           isOpenConfirm: false,
           searchText: "",
           isOpenEventAttendance: false,
           eventCounts: 0,
           isFilters : false,
           isShowUpcoming: false,
           trainerList: []
        }
    }

    componentDidMount(){
        this.fetchEvents();
        this.fetchTrainerList();
        this.getJobCategory();
    }


    getJobCategory = async () => {
        try {
            let resp = await ApiEndpoints.Jobs.fetchCategory();
            this.setState({jobCategory: resp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    fetchEvents = async (page = 0) => {
        store.dispatch(setLoader(true));
        let {isFilters, isShowUpcoming} = this.state;
        try{
            var body = {
                limit: 20,
                offset: 20 * page
            }
            if(this.state.searchText) {
                body.search = this.state.searchText.toLowerCase();
            }
            if(!(isFilters.start_date || isFilters.end_date)){
                if(isShowUpcoming) body.start_date_range = moment().toDate();
                else body.end_date_range = moment().toDate();
            }
            if(isFilters.event_host_id) body = {...body, event_host_id: isFilters.event_host_id}
            if(isFilters.job_id) body = {...body, entity_type: "JOB", entity_id: isFilters.job_id}
            if(this.props.job_id) body = {...body, entity_type: "JOB", entity_id: this.props.job_id}
            if(isFilters.start_date) body = {...body, start_date_range: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, end_date_range: moment(isFilters.end_date).endOf("day").toDate()}
               
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            var data = resp.data.data.rows || [];
            var eventCounts = resp.data.data.count || 0
            this.setState({eventList: data, eventCounts, currentUserPage: page})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    downloadEvents = async (opts = {}) => {
        store.dispatch(setLoader(true));
        let {isFilters, isShowUpcoming} = this.state;
        try {
            var body = {download: true};
            if(!(isFilters.start_date || isFilters.end_date)){
                if(isShowUpcoming) body.start_date_range = moment().toDate();
                else body.end_date_range = moment().toDate();
            }
            if(isFilters.event_host_id) body = {...body, event_host_id: isFilters.event_host_id}
            if(isFilters.job_id) body = {...body, entity_type: "JOB", entity_id: isFilters.job_id}
            if(this.props.job_id) body = {...body, entity_type: "JOB", entity_id: this.props.job_id}
            if(isFilters.start_date) body = {...body, start_date_range: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, end_date_range: moment(isFilters.end_date).endOf("day").toDate()}
           console.log(body);
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            downloadApiFile(resp, "Events Data.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }


    onChangeAddEvent = (k, v) => {
        let { isAddNewEvent } = this.state;
        let {jobList, jobCategory} = this.state;
        isAddNewEvent[k] = v;
        if(k === "entity_type")  isAddNewEvent.entity_id = null;
        if(k === "entity_id" && isAddNewEvent.entity_type === "JOB") {
            let job = jobList.filter(job => job.job_id == v)[0];
            isAddNewEvent.title = job.Employer.employer_name+" - "+job.title;
        }
        else if(k === "entity_id" && isAddNewEvent.entity_type === "JOB_CATEGORY") {
            let category = jobCategory.filter(cat => cat.job_category_id == v)[0];
            isAddNewEvent.title = category.title;
        }
        this.setState({isAddNewEvent})
    }

    createEvent = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try {
            let {title, description, date, start_time, end_time, event_tags, entity_type, entity_id, event_host_id,language} = this.state.isAddNewEvent;
            if(!event_host_id)  throw new Error("Please select mandatory field: Host");
            if(!language)  throw new Error("Please select mandatory field: Language");
            if(entity_type === "JOB_CATEGORY" && !entity_id)  throw new Error("Please select mandatory field: Project Category");
            if(entity_type === "JOB" && !entity_id)  throw new Error("Please select mandatory field: Project");
            var body = {
                title, description, event_tags,
                entity_type,
                entity_id,
                start_date: moment(moment(date).format("YYYY-MM-DD")+" "+moment(start_time).format("HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").toDate(),
                end_date: moment(moment(date).format("YYYY-MM-DD")+" "+moment(end_time).format("HH:mm:ss"), "YYYY-MM-DD HH:mm:ss").toDate(),
                event_host_id,
                language: language.toUpperCase()
            };
            await ApiEndpoints.Events.createEvent(body);
            await this.setState({isAddNewEvent: false, isShowUpcoming: true});
            await this.fetchEvents();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    delete = async (event_id) =>{
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Events.inactiveEvent({event_id});
            await this.fetchEvents();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    showEventAttendees = async (event) =>{
        if(!this.props.isOnlyView) this.setState({isOpenEventAttendance: event})
    }

    toggleFilter = async () => {
        let { isFilters } = this.state;
        if(isFilters) {
            await this.setState({isFilters: false});
            this.fetchEvents();
        }
        else this.setState({ isFilters: {} })
    }

    fetchTrainerList = async () => {
        // store.dispatch(setLoader(true))
        try{
            let body = { role_id: 9 };
            let resp = await ApiEndpoints.User.fetchRoleMappedUser(body);
            this.setState({ trainerList: resp.data.data })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        // store.dispatch(setLoader(false))
    }

    toggleCreateEvent = () => {
        let { isAddNewEvent } = this.state;
        if(isAddNewEvent) isAddNewEvent = false
        else {
            isAddNewEvent = { entity_type: "GENERAL" };
            if(this.props.job_id) {
                isAddNewEvent.entity_type = "JOB"
                isAddNewEvent.entity_id = this.props.job_id
            }
        }
        this.setState({isAddNewEvent});
    }
    applyFilter = async () => {
        await this.setState({currentUserPage : 0})
        this.fetchEvents();
    }
    render() {
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
       let isAllowCreate = !this.props.isOnlyView && !!userRole.filter(role => [1, 3].indexOf(role.role_id) > -1).length;
        let {jobList, jobCategory, eventList, isAddNewEvent, searchText, isOpenEventAttendance, isFilters, isShowUpcoming, trainerList} = this.state;
        eventList = eventList.filter(event => isShowUpcoming ? moment().isBefore(event.end_date) : moment().isAfter(event.end_date));

        return (
            <>
                            <Row className="border-bottom py-2 mb-3 align-items-end">
                                <Col>
                                    {isAllowCreate && <Button className="mb-3 buttonred" onClick={() => this.toggleCreateEvent()}>Add Event</Button>}
                                </Col>
                                <Col xs={5}>
                                <form onSubmit={(e) => {e.preventDefault(); this.applyFilter()}}>
                                    <InputFeild onChange={(e) => this.setState({searchText :e.target.value})} value={searchText} icon="search" onClickIcon={this.applyFilter} placeholder=" Search" />
                                </form>
                                </Col>
                                <Col xs="auto">
                                    <Switch size="small" checked={isShowUpcoming} onChange={async (e) => { await this.setState({ isShowUpcoming: e.target.checked }); this.fetchEvents() } } /> <span style={{fontSize: 12}}>Upcoming</span>
                                    <Icon className="align-middle mx-2 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.toggleFilter()}>{isFilters ? "filter_alt_off" : "filter_alt"}</Icon>
                                    <Button onClick={() => this.downloadEvents()} size="sm" className="p-0" color="secondary" outline><Icon className="align-middle" style={{fontSize: 18}}>download</Icon></Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={!!isFilters}>
                                <Row className="justify-content-end">
                                    <Col xs={12} lg={6} className="border mx-3 mb-3">

                                        <SelectList search={"Search"} className="mt-2" placeholder="Webinar Host" value={isFilters.event_host_id}  onChange ={({target}) => {isFilters.event_host_id = target.value; this.setState({isFilters})}} >
                                            {
                                            trainerList.map(trainer => 
                                            <option value={trainer.user_id}>{trainer.User.first_name}</option> 
                                            )}
                                        </SelectList>

                                       {!this.props.job_id && <SelectList search={true} className="mt-2" placeholder="Select Project" multiple value={isFilters.job_id || []} onChange ={(e) => {isFilters.job_id = e.target.value; this.setState({isFilters})}} >
                                        {jobList.map(job => 
                                            <option key={job.job_id} value={job.job_id}>
                                                {job.Employer.employer_name +  "-"  + job.title}
                                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                            </option>
                                        )}
                                        </SelectList>
                                         }
                                        <Row className={"mt-3"}>
                                            <Col>
                                                <DatePicker
                                                    selected={isFilters.start_date || null}
                                                    onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                                                    isClearable
                                                    dateFormat='dd-MMM-yyyy'
                                                    placeholderText="Start Date"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker
                                                    selected={isFilters.end_date || null}
                                                    onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                                                    isClearable
                                                    dateFormat='dd-MMM-yyyy'
                                                    placeholderText="End Date"
                                                />
                                            </Col>
                                        </Row>
                                       <div className="text-right py-3"><Button color="dark" size="sm" onClick={() => this.fetchEvents()}>Apply Filter</Button></div>
                                    </Col>
                                </Row>
                            </Collapse>
                            <Collapse isOpen={!!isAddNewEvent}>
                                <Card className="p-3">
                                    <form onSubmit={this.createEvent}>
                                        <Row>
                                            {!this.props.job_id && <>
                                                <Col>
                                                    <SelectList className="mt-2" placeholder="Select Type" value={isAddNewEvent.entity_type}  onChange={({target}) => this.onChangeAddEvent("entity_type", target.value) }>
                                                        <option value="GENERAL">Generic Webinar</option>
                                                        <option value="JOB_CATEGORY">Project Category</option>
                                                        <option value="JOB">Project</option>
                                                    </SelectList>
                                                </Col>
                                                {isAddNewEvent.entity_type === "JOB_CATEGORY" && 
                                                <Col>
                                                    <SelectList search={true} className="mt-2" placeholder="Select Category" value={isAddNewEvent.entity_id}  onChange={({target}) => this.onChangeAddEvent("entity_id", target.value) }>
                                                        {jobCategory.map(cat => <option value={cat.job_category_id}>{cat.title}</option>)}
                                                    </SelectList>
                                                </Col>
                                                }
                                                {isAddNewEvent.entity_type === "JOB" && 
                                                <Col>
                                                    <SelectList  search={true} className="mt-2" placeholder="Select Project" value={isAddNewEvent.entity_id}  onChange={({target}) => this.onChangeAddEvent("entity_id", target.value) }>
                                                        {jobList.map(job => 
                                                            <option value={job.job_id}>
                                                                {job.Employer.employer_name +  "  - "   + job.title}
                                                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                                            </option> 
                                                        )}
                                                    </SelectList>
                                                </Col>
                                                }
                                            </>}
                                          
                                            <Col>
                                                <SelectList search={true} className="mt-2" required placeholder="Webinar Host" value={isAddNewEvent.event_host_id}  onChange={({target}) => this.onChangeAddEvent("event_host_id", target.value) }>
                                                    {
                                                    trainerList.map(trainer => 
                                                    <option value={trainer.user_id}>{trainer.User.first_name}</option> 
                                                    )}
                                                </SelectList>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col> <InputFeild className="mt-2" required placeholder="Event Title" value={isAddNewEvent.title || ""} onChange={({target}) =>  this.onChangeAddEvent("title", target.value) } /></Col>
                                            <Col>
                                                <SelectList className="mt-2"  required placeholder="Webinar Language" value={isAddNewEvent.language}  onChange={({target}) => this.onChangeAddEvent("language", target.value) }>
                                                    {
                                                    ["English", "Hindi" ,"Kannada", "Telugu", "Tamil"].map(v => 
                                                     <option value={v}>{v}</option> )
                                                    }        
                                                </SelectList>
                                            </Col>
                                        </Row>
                                       
                                        <InputFeild className="mt-2" required placeholder="Event Description" inputProps={{ minLength: 10}} value={isAddNewEvent.description || ""} onChange={({target}) =>  this.onChangeAddEvent("description", target.value)} />
                                        <Row className="mt-2 align-items-center">
                                            <Col>
                                                <DatePicker id="event_date" required
                                                    selected={isAddNewEvent.date && moment(isAddNewEvent.date).toDate()}
                                                    autoComplete="off"
                                                    onChange={date => this.onChangeAddEvent("date", date)}
                                                    placeholderText="Event Date" minDate={new Date()} dateFormat="dd-MMM-yyyy EE"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker id="event_start_time" required
                                                    placeholderText="Start Time"
                                                    selected={isAddNewEvent.start_time}
                                                    onChange={(date) =>  this.onChangeAddEvent("start_time", date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={30}
                                                    timeCaption="Time"
                                                    autoComplete="off"
                                                    dateFormat="h:mm aa"
                                                />
                                            </Col>
                                            <Col>
                                                <DatePicker id="event_end_time" required
                                                    placeholderText="End Time"
                                                    selected={isAddNewEvent.end_time}
                                                    onChange={(date) =>  this.onChangeAddEvent("end_time", date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={15}
                                                    timeCaption="Time"
                                                    autoComplete="off"
                                                    dateFormat="h:mm aa"
                                                />
                                            </Col>
                                        </Row>
                                        
                                        <SelectList className="mt-2" placeholder="Event Tags" value={isAddNewEvent.event_tags}
                                          onChange={({target}) =>  this.onChangeAddEvent("event_tags", target.value)}>
                                            {
                                            ["New", "Recommended", "Popular", "High Earnings", "Top Rated", "Trending", "Easy"].map(v => 
                                            <option value={v}>{v}</option> 
                                            )}
                                        </SelectList> 
                                        <div className="text-right mt-3"><Button className="buttonred" type="submit">Create Event</Button></div>
                                    </form>
                                </Card>
                            </Collapse>

                            <Row className={"py-1 bg-light border-bottom text-center font-weight-bold"}>
                                <Col xs={5}>Event</Col>
                                <Col xs={3}>Time</Col>
                                <Col xs={2}>Partners</Col>
                                <Col xs={1}>Rating</Col>
                                {!this.props.isOnlyView && <Col xs={1}>Actions</Col>}
                            </Row>
                            {eventList.length ? <>
                            {
                                eventList
                                .map((event, i) => {
                                    var meetLink = event.calendar_data && event.calendar_data.meetData ? 
                                     event.calendar_data.meetData[0].uri : null;
                                    var rating = (event.positive_rating + event.negative_rating) ? 
                                    event.positive_rating/(event.positive_rating + event.negative_rating)*5 : 0;
                                    var joiningRatio = moment().isAfter(event.end_date) ? 
                                    (event.attendees_joined || 0) / (event.attendeesCount || 0) * 100 : null
                                    return <>
                                    <Row className={"align-items-center text-center py-2 border-bottom visible-on-hover-container"} style={{fontSize: 14}}>
                                        <Col xs={5} className="text-left">
                                            <strong>{(this.state.currentUserPage*this.state.rowsPerPage) + i+1}. </strong>
                                            <strong>{event.title}</strong> {event.event_tags && <span className="text-secondary ml-2 text-secondary px-1" style={{fontSize: 12, borderRadius: 10, background: "#ddd"}}>{event.event_tags}</span> }
                                            {meetLink && <a target="_blank" className="visible-on-hover-item" href={meetLink+"?app=true"}><Icon className="align-middle ml-2" style={{fontSize: 18}}>link</Icon></a>}
                                            <span className="text-secondary d-block limit-text-1 mt-1" style={{fontSize: 10}} title={event.description}>{event.description}</span>
                                            <span className="text-secondary font-weight-bold d-block limit-text-1 mt-1" style={{fontSize: 10}}>Language: {event.language.charAt(0).toUpperCase() + event.language.slice(1).toLowerCase()} Host: {event.Host && event.Host.first_name} </span>
                                        </Col>
                                        <Col xs={3}>
                                            <span className="d-block">{moment(event.start_date).utcOffset("+05:30").format("Do MMM'YY")}</span>
                                            <span className="d-block" style={{fontSize: 12}}>{moment(event.start_date).utcOffset("+05:30").format("hh:mmA")} - {moment(event.end_date).utcOffset("+05:30").format("hh:mmA")}</span>
                                            <span className="small text-muted limit-text-1">Created: {moment(event.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")} | {event.User.first_name}</span>
                                        </Col>
                                        <Col xs={2} title="Joined / Visited / Registered">
                                           <div className="align-middle d-inline-block text-center mr-3" style={{cursor: "pointer"}} 
                                            onClick={() => this.showEventAttendees(event)}>
                                            
                                                <Icon style={{ fontSize: 18, color: joiningRatio !== null ? 
                                                    (joiningRatio >= 60 ? "green" : (joiningRatio >= 30 ? "orange" : "red")) : "black"}} 
                                                     className="align-middle">people</Icon>
                                                <span className="d-block">{event.attendees_joined || 0} / {event.attendees_attended || 0} / {event.attendeesCount || 0}</span>
                                            </div>
                                          
                                        </Col>
                                        <Col xs={1}>
                                            {!!event.total_rating && <div className="align-middle d-inline-block text-center mr-3" style={{cursor: "pointer"}} onClick={() => this.showEventAttendees(event)}>
                                                <Icon style={{ fontSize: 18, color: rating >= 3.5 ? "green" : (rating >= 2.5 ? "orange" : "red")}}  className="align-middle">star</Icon>
                                                <span className="d-block">{ rating.toFixed(1) }</span>
                                            </div>}
                                        </Col>
                                        {!this.props.isOnlyView && <Col xs={1} className="text-left">
                                            
                                            <Link to={{pathname: "/webinar/report", state: { event_id: event.event_id } }} style={{color: "#000"}}><Icon className="align-middle">analytics</Icon></Link>
                                          
                                            { (!event.attendeesCount || isAdmin) && 
                                            <Icon className="align-middle ml-3 visible-on-hover-item" style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isOpenConfirm :  () => this.delete(event.event_id) })}} >delete</Icon>
                                           
                                            }
                                          
                                        </Col>}
                                    </Row>
                                       
                                        
                                </>
                                })
                            }
                            <TablePagination rowsPerPageOptions={[20]} component="div" count={this.state.eventCounts} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                                // onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                                onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                                onChangePage={ (e, page) => this.fetchEvents(page) }

                            />
                            </>
                            :
                            <em className="d-block text-center text-secondary mt-3">No events created yet</em>
                            }
                        
                        
                        <Modal size="lg" isOpen={isOpenEventAttendance} toggle={()=> {this.setState({isOpenEventAttendance : false})}} >
                            <ModalHeader toggle={()=> {this.setState({isOpenEventAttendance : false})}}>Participants</ModalHeader>
                            <ModalBody className="p-0">
                                { isOpenEventAttendance &&  <WebinarAttendance eventData={isOpenEventAttendance} /> }
                            </ModalBody>
                        </Modal>

                        



                        <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

            </>
            
            
        );
    }
}
export default Webinar;
