
import ReactGA from "react-ga";

export const InitGA = (trackingID) => {           
  ReactGA.initialize(trackingID); 
}

export const TrackUser = (userId) => {           
  ReactGA.set({ userId });
}

export const PageView = (pathname) => {  
  ReactGA.pageview(pathname || window.location.pathname); 
}

export const TrackEvent = (category, action, label) => {
  console.log(category, action, label);
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};