import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setLoader } from '../../actions/action-creators';

class Loader extends Component {


	
	render() {
		let { loader } = this.props;
		return (
			<div className={"text-center "+(!!loader ? "" : "invisible")} style={{opacity : "0.75", position : "absolute", zIndex:"999999999999", height : "100vh", width : "100%", background : "#ccc"}}>
                {/* <div className="loader"></div> */}
				<p className="double" style={{marginTop: "43vh"}}>
				<span className="ouro ouro3">
					<span className="left"><span className="anim"></span></span>
					<span className="right"><span className="anim"></span></span>
				</span>
				</p>
            </div>
		)
	}
}


const mapStateToPropsSuccess = state => ({
	...state
})
const mapDispatchToPropsSuccess = dispatch => ({
	setLoader: (show) => dispatch(setLoader(show)),
})
export default withRouter(connect(mapStateToPropsSuccess, mapDispatchToPropsSuccess)(Loader));
