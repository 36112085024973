
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button } from 'reactstrap';
import { Card, CardBody} from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import Aux from "../../global/AuxComp";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select"
import InputFeild from "../../global/form/input";
import moment from "moment";
import CONSTANTS from "../../../constants";
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactDatePicker from "react-datepicker";
import RichTextEditor from "../../global/form/Richtext";


class UpdateApplicationState extends Component {
    constructor(props) {
      super(props);
      this.state = {
            updateOpts : {},
            questions : [],
        }
      }

      
      async componentDidMount(){
       this.fetchJobQuestions();
      }

      fetchJobQuestions = async () => {
        store.dispatch(setLoader(true))
        try {
            let { job_id, state } = this.props.modalInfo;
            let resp = await ApiEndpoints.Jobs.fetchJobQues({job_id, question_category : state});
            this.setState({questions : resp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }
      onChangeUpdate = (k, v) => {
        let {updateOpts} = this.state;
        updateOpts[k] = v;
        this.setState({updateOpts})
      }

      onChangeCheckbox = (key, e) => {
        let {updateOpts} = this.state;
        var data = updateOpts[key] ? updateOpts[key].split(";;") : [];
        if(e.target.checked) data.push(e.target.value);
        else {
            data.splice(data.indexOf(e.target.value), 1);
        }
        data = data.join(";;");
        updateOpts[key] = data;
        this.setState({updateOpts});
      }


      handleModalSubmit = async(e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        let { user, state, work_order_id, isUpdate, job_id, curr_state } = this.props.modalInfo;
        let {questions, updateOpts} = this.state;
        let { joining_date, comment } = updateOpts;
        var body = {
            comment,
            user_id: user.user_id,
            job_id,
            is_update : isUpdate,
            work_order_id
        } 
        try{
            await Promise.all(questions.map(async question => {
                if(updateOpts["question_"+question.job_question_id]){
                    var body = {
                        job_question_id : question.job_question_id,
                        answer : updateOpts["question_"+question.job_question_id],
                        user_id: user.user_id
                    }
                    var resp = await ApiEndpoints.Applicants.answerAdd(body);
                    return resp.data.data;
                }else return false;
            }));
            if(state === "SHORTLISTED") {
                if(joining_date) body.joining_date = moment(joining_date).toDate();
                await ApiEndpoints.Applicants.shortListed(body);
            }
            if (state === "INTERVIEWED"){
                // var assessmentBody = {
                //     user_id,
                //     assesment_score : e.target.assesment_score.value,
                //     hunger_for_money_score : e.target.hunger_for_money_score.value,
                //     sales_experience_score : e.target.sales_experience_score.value,
                //     communication_score : e.target.communication_score.value,
                //     job_id : this.state.selectedJobId ,
                //     comments : " " ,
                //     is_selected : 0
                // } 
                // await ApiEndpoints.InterviewForm.addInterviewer(assessmentBody);
                await ApiEndpoints.Applicants.interviews(body);
            } 
            if(state === "REJECTED") await ApiEndpoints.Applicants.rejected(body);
            if(state === "TERMINATED") await ApiEndpoints.Applicants.terminate(body); 
            if(["IN_TASK", "QUIT_JOB"].includes(state)) {
                body.status = state;
                await ApiEndpoints.Applicants.updateState(body);
            }
            // if(CONSTANTS.APPLICANTS_STATES[state]) await this.props.getApplicantsByState(state);
            await this.props.getApplicantsByState(curr_state);
            this.props.toggleOpenModal();
            store.dispatch(setSuccess({show: true, message: "Status Updated Successfully" }))
        }
        catch(e){
            this.props.toggleOpenModal();
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }
    render() {
        let { updateOpts, questions } = this.state;
        let { modalInfo = {} } = this.props;
        return (
        <form onSubmit={this.handleModalSubmit}>


            {modalInfo && modalInfo.state === "SHORTLISTED" && 
                <Row>
                    <Col>
                        <ReactDatePicker
                            selected={updateOpts.joining_date ? updateOpts.joining_date : null}
                            onChange={date => this.onChangeUpdate("joining_date", date)}
                            className="my-3"
                            dateFormat='dd-MMM-yyyy'
                            placeholderText="Joining Date (optional)"
                        />
                    </Col>
                </Row>
                }
            {questions.map(question => 
                    <Card className="shadow mt-2" style={{width : 765}}>
                        <CardBody>
                            <Row className="mb-3">
                                <Col xs={12}>
                                    <span className="d-inline-block" style={{fontSize : 15}} dangerouslySetInnerHTML={{__html : question.heading}} /><span></span>{!!question.is_mandatory && <span className="text-danger">*</span>}<br></br>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {question.question_type === "TEXT" && 
                                        <InputFeild placeholder="Enter here" value={updateOpts["question_"+question.job_question_id] || ""}  onChange={(e) => this.onChangeUpdate("question_"+question.job_question_id, e.target.value)}/>
                                    }
                                    {question.question_type === "DROPDOWN" && 
                                        <SelectList placeholder="Select" value={updateOpts["question_"+question.job_question_id] || ""} onChange={(e) => this.onChangeUpdate("question_"+question.job_question_id, e.target.value)}>
                                            {(question.options ||"").split(";;").map(option => 
                                                <option value={option}>{option}</option>
                                            )}
                                        </SelectList>
                                    }
                                    {question.question_type === "TEXTAREA" && 
                                        <textarea placeholder="Enter here" value={updateOpts["question_"+question.job_question_id] || ""} rows={4} className="form-control" onChange={(e) => this.onChangeUpdate("question_"+question.job_question_id, e.target.value)} />
                                    }
                                    {question.question_type === "RADIO" && 
                                        <RadioGroup row aria-label="position" name="communication_score" defaultValue={("").toString()}>
                                            <Row className="w-100">
                                            {(question.options ||"").split(";;").map(option =>
                                            <Col xs={4}>
                                                <FormControlLabel value={option} control={<Radio color="primary" onChange={() => this.onChangeUpdate("question_"+question.job_question_id, option)} />} label={option} labelPlacement="right"/>
                                            </Col>
                                            )}
                                            </Row>
                                        </RadioGroup> 
                                    }
                                    {question.question_type === "CHECKBOX" && 
                                         <RadioGroup row aria-label="position" name="communication_score" defaultValue={("").toString()}>
                                            <Row className="w-100">
                                            {(question.options ||"").split(";;").map(option =>
                                            <Col xs={4}>
                                                <FormControlLabel value={option} control={<Checkbox color="primary" onChange={(e) => this.onChangeCheckbox("question_"+question.job_question_id, e)} />} label={option} labelPlacement="right"/>
                                            </Col>
                                            )}
                                            </Row>
                                        </RadioGroup> 
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                   
            )}
      
                <div>
                {modalInfo && modalInfo.state === "TERMINATED" ? (
                    <>
                    <p>Termination Letter</p> 
                    {this.props.modalInfo.user.email ? 
                     <RichTextEditor name="comment" placeholder="Enter Termination Reason" className="form-control" value={this.state.updateOpts.comment || ""} onChange={(e) => this.onChangeUpdate("comment", e)}/>
                     :
                     <h5 className="text-danger">Email not found, Not be able to send termination letter on Email</h5>
                    }
                    </>
                )
                
                 :
                ( <>
                    <p>Comment</p>
                    <textarea name="comment" className="form-control" type="text" value={this.state.updateOpts.comment || ""} onChange={(e) => this.onChangeUpdate("comment", e.target.value)} />
                </>
               )
                }
                        
                </div>
               
                <Row className={"border-top mt-2"}> 
                        <Col className="text-right mt-2">
                        {modalInfo && modalInfo.state === "TERMINATED" ?
                            <Button  type="submit" size="sm" color="dark">Send Termination</Button>
                            :
                            <Button type="submit" size="sm" color="primary">Continue</Button>
                        }
                        </Col>
                    </Row>
    </form>
    ) }
}

export default UpdateApplicationState;
