
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Icon} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import moment from "moment";
import SelectList from "../../global/form/select";
import { downloadApiFile, uploadFile } from "../../../actions";
import ImageUploadFeild from "../../global/form/upload-image";
import DummyCompanyImage from "../../../assets/images/company-default.png";

class Clients extends Component {
    constructor(props) {
        super(props);
        this.state = {
           clientList : [],
           isOpenCreate: false,
           isOpenConfirm: false
        }
    }

    componentDidMount(){
        this.fetchClients();
    }

    fetchClients = async (page = 0) => {
        store.dispatch(setLoader(true));
        try{
            var body = {}
            let resp = await ApiEndpoints.Invoice.fetchClients(body);
            this.setState({clientList: resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeClient = (k, v) => {
        let { isOpenCreate } = this.state;
        isOpenCreate[k] = v;
        this.setState({isOpenCreate})
    }


    createClient = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try {
            let {client_id, client_name, business_name, email, contact, address, state, logo_key, gst_no, city, pincode } = this.state.isOpenCreate;
            var body = { client_id, client_name, business_name, email, contact, address, state, gst_no, city, pincode };
            if((body.contact || "").includes("*")) delete body.contact;

            // if(body.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(body.email)) throw new Error("Invalid Email Address")
            if(body.contact && !/^([6789][0-9]{9})/.test(body.contact)) throw new Error("Invalid Phone No")
            if(body.gst_no && !/^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})/.test(body.gst_no)) throw new Error("Invalid GST No")

            if(logo_key && typeof logo_key === "object"){
                let fileResp = await uploadFile(logo_key, "COMPANY_DATA");
                body.logo_key = fileResp.file_id;
            }
            
            if(client_id) await ApiEndpoints.Invoice.editClient(body);
            else await ApiEndpoints.Invoice.addClient(body);
            this.setState({isOpenCreate: false});
            await this.fetchClients();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }



    render() {
        let { clientList, isOpenCreate } = this.state;

        return (
            <>
                <Row className="my-3 align-items-end">
                    <Col>
                        <Button className="buttonred" onClick={() => this.setState({isOpenCreate: isOpenCreate ? false : {}})}>Add Client</Button>
                    </Col>
                </Row>
                
                  
                {
                clientList.map((client, i) => 
                    <Row className="border shadow-sm py-2 m-2 align-items-center" style={{ borderRadius: 12 }}>
                        <Col xs={1}>
                            <img className="" src={client.logo_url || DummyCompanyImage} style={{width: "100%"}} alt="logo"/>
                        </Col>
                        <Col xs={4}>
                            <strong className="d-block">{client.client_name}</strong>
                            <span className="small">{client.business_name}</span>
                        </Col>
                        <Col xs={3}>
                            <span className="d-block">{(client.contact || "")}</span>
                            <span className="small">{client.email}</span>
                        </Col>
                        <Col xs={3}>
                            <span className="d-block">{client.gst_no}</span>
                            <span className="small">{client.state}</span>
                        </Col>
                        <Col>
                            <Icon onClick={()=>this.setState({isOpenCreate : {...client}})} className="mr-2" style={{cursor: "pointer", fontSize: 20}}>edit</Icon> 
                        </Col>
                    </Row>
                     
                )
                }
                



                <Modal isOpen={!!isOpenCreate} toggle={()=> {this.setState({isOpenCreate : false})}} >
                    <ModalHeader toggle={() => this.setState({isOpenCreate : false})}>Create new Client</ModalHeader>
                    <ModalBody>
                        <form onSubmit={this.createClient} autoComplete="off">
                            <Row className="mt-3">
                                <Col>
                                    <InputFeild required className="" placeholder="Client Name" onChange={(e) => this.onChangeClient("client_name", e.target.value)} value={isOpenCreate.client_name || ""}/>    
                                </Col>
                                <Col>
                                    <InputFeild required className="" placeholder="Business Legal Name" onChange={(e) => this.onChangeClient("business_name", e.target.value)} value={isOpenCreate.business_name || ""}/>    
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <InputFeild required className="" placeholder="Client Email" onChange={(e) => this.onChangeClient("email", e.target.value)} value={isOpenCreate.email || ""}/>    
                                </Col>
                                <Col>
                                    <InputFeild required type="text" className="" placeholder="Business Contact" onChange={(e) => this.onChangeClient("contact", e.target.value)} value={isOpenCreate.contact || ""}/>    
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <InputFeild required type="text" className="" placeholder="GST Number" onChange={(e) => this.onChangeClient("gst_no", e.target.value)} value={isOpenCreate.gst_no || ""}/>    
                                </Col>
                                <Col>
                                    <SelectList required className="" placeholder="State" value={isOpenCreate.state}  onChange={({target}) =>  this.onChangeClient("state", target.value)}>
                                        {STATES.map((v, i) => <option value={v}>{v}</option>)}
                                    </SelectList>                                   
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <InputFeild required type="text" className="" placeholder="City" onChange={(e) => this.onChangeClient("city", e.target.value)} value={isOpenCreate.city || ""}/>    
                                </Col>
                                <Col>
                                    <InputFeild required type="text" className="" placeholder="Pincode" onChange={(e) => this.onChangeClient("pincode", e.target.value)} value={isOpenCreate.pincode || ""}/>    
                                </Col>
                                
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <InputFeild required className="" placeholder="Address" onChange={(e) => this.onChangeClient("address", e.target.value)} value={isOpenCreate.address || ""}/>    
                                </Col>
                            </Row>
                            
                            <Row className="align-items-end mt-3">
                                <Col xs={6}>
                                    <ImageUploadFeild label="Upload Logo"  src={isOpenCreate.logo_url} onChange={(file) =>  this.onChangeClient("logo_key", file)}/>
                                </Col>
                                <Col xs={6} className="text-right">
                                    <Button type="submit" className="buttonred my-3 float-right">{isOpenCreate.client_id ? "Save":"Add New"} Client</Button>
                                </Col>
                            </Row>
                        </form>
                    </ModalBody>
                </Modal>




                {/* <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} /> */}

            </>
            
            
        );
    }

}
export default Clients;

const STATES = [
    "Andaman & Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra & Nagar Haveli",
    "Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu & Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Orissa",
    "Pondicherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal"
  ]