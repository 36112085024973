
import React, { Component } from 'react';
import Chart from 'react-apexcharts'

export default class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      
    };
  }



  render() {

    let { data, dataKey, axisKey, chartTitle } = this.props;

    var options = {
      chart: {
        events: {
          click: (event, chartContext, config) => { 
            try{
              var axisName = config.config.series[config.seriesIndex].name;
              var xAxisValue = config.globals.categoryLabels[config.dataPointIndex];
              if(this.props.onClick) this.props.onClick(axisName, xAxisValue);
            }
            catch(e){}
          }
        },
        toolbar: {
          show: true,
          // tools: {
          // customIcons: [{
          //   icon: '<i>hello</i>',
          //   click: () => window.alert("hello"),
          //   class: 'float-left' // left / top means the button will be appended to the left most or right most position
          //  }]
          // }
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: chartTitle || 'Data',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: [],
      }
    }


    var xAxis = Object.keys(data).reduce((prev, key) => {
      prev = [...prev, ...data[key].map(o => o[axisKey])];
      return prev;
    }, []);
    options.xaxis.categories = xAxis;
    
    var series = Object.keys(data).map(key => {
      var seriesData = {
        name: key,
        data: []
      };
      xAxis.forEach((xKey, i) => {
        var t = data[key].filter(obj => obj[axisKey] === xKey)[0];
        seriesData.data[i] = t ? t[dataKey] : 0;
      });
      return seriesData;
    })
    return (
    <div id="chart">
      <Chart options={options} series={series} type="line" height={350} />
    </div>


    );
  }
}



// var sampleData = {
//   "INDIVIDUAL": [
//     {
//       "date": "2020-05-16",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 1
//     },
//     {
//       "date": "2020-05-18",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 22
//     },
//     {
//       "date": "2020-05-19",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 35
//     }
//   ],
//   "Facebook": [
//     {
//       "date": "2020-05-16",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 1
//     },
//     {
//       "date": "2020-05-18",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 22
//     },
//     {
//       "date": "2020-05-19",
//       "channel_subtype": "INDIVIDUAL",
//       "count": 35
//     }
//   ]
// }