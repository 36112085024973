import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CustomerBasicDetails from "./customer_basic_details";
import CustomerBusinessDetails from "./customer_business_details";
import CustomerProjectDetails from "./customer_project_details";
import CustomerCallDetails from "./customer_call_details";


class CustomerLeadDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDetailTab : 0,
            
        }
    }
    switchTab = (value) => {
        this.setState({ selectedDetailTab: value })
    }

    render() {
        let { selectedDetailTab } = this.state;

        return (
            <>

                <Modal size="lg" style={{maxWidth: 600}} isOpen={true} toggle={()=> this.props.toggle()} >
                    <ModalHeader toggle={()=> this.props.toggle()} ><h5>Customer Lead Details</h5></ModalHeader>
                    <ModalBody className="pt-0">
                        <Row>
                            <Col xs={3} className="bg-light">
                                {
                                    MENU.map((item, i) => 
                                        <div className="my-4">
                                            <h6 style={{cursor : "pointer", fontSize: 14}} className={"pb-1 "+(selectedDetailTab === i && "border-bottom border-danger")} onClick={() => this.switchTab(i)}>{item}</h6>
                                        </div>
                                    )
                                }
                            </Col>

                            <Col xs={9}>
                                { selectedDetailTab === 0 && <CustomerBasicDetails customer_lead_id={this.props.customer_lead_id}  /> }
                                { selectedDetailTab === 1 && <CustomerBusinessDetails  customer_lead_id={this.props.customer_lead_id}/> }
                                { selectedDetailTab === 2 && <CustomerProjectDetails customer_lead_id={this.props.customer_lead_id} /> }
                                { selectedDetailTab === 3 && <CustomerCallDetails customer_lead_id={this.props.customer_lead_id} /> }

                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
            
            
        );
    }
}
export default CustomerLeadDetail;


const MENU = ["Basic Details", "Business Details", "Projects", "Call Logs"];
