
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import { ModalBody, ModalHeader, ModalFooter, Modal, Popover, PopoverBody, PopoverHeader} from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import {uploadFile, downloadApiFile, groupArrayOnKey} from "../../../actions";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select";
import Aux from "../../global/AuxComp";
import JobCreateEdit from "./job_create_edit";
import DatePicker from "react-datepicker";
import ConfirmAction from "../../global/confirm-action";
import { CircularProgress, Switch } from "@material-ui/core";
import ReferralView from "./referral";
import Events from "./events";
import ProjectOverview from "./overview";
import InputFeild from "../../global/form/input";
import CONSTANTS from "../../../constants";
import { Link } from "react-router-dom";
let moment = require("moment");

class Projects extends Component {
    constructor(props) {
      super(props);
      this.state = {
        jobList : [] ,
        jobMetadata: {},
        selectedJob : null,
        isOpenVerifyBankModal : false,
        isOpenReferral : false,
        isOpenNotification : false,
        isOpenAddWalletModal : false,
        verifyBankLoader : false,
        addWalletLoader : false,
        isOpenDeleteModal : false,        
        isOpenCreateProjectModal : false,
        user : {},
        editJob : null,
        openWalletDownload : false,
        isOpenConfirm : false,
        confirmMessage: null,
        isOpenDownload: false,
        isArchiveConfirm: false,
        isOpenEvents: false,
        jobSearch: "",
        isShowOnlyRecommended: false,
        isShowPendingSteps: false,
        jobCategory: [],
        jobCategoryMapped: [],
        selectedCategory: null,
        isOpenPopover: false
        }
       }

    async componentDidMount(){
        this.getApiFn();
        await this.getJobCategory();
        await this.getAllJob();  
        this.fetchJobMetadata();      
    }
    getApiFn = async () => {
        try{
            let apiData = await ApiEndpoints.Static.getFunctions();
            this.getCycleDates = eval(apiData.data.data.getCycleDates);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
   
    fetchJobTaskAddWallet = async (job_id) => {
        let {isOpenAddWalletModal} = this.state;
        try {
            let taskResp = await ApiEndpoints.JobTask.fetch({job_id});
            isOpenAddWalletModal.taskList = taskResp.data.data;
            this.setState(isOpenAddWalletModal);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    
    getJobCategory = async () => {
        try {
            let resp = await ApiEndpoints.Jobs.fetchCategory();
            this.setState({jobCategory: resp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    fetchJobMetadata = async () => {
        try {
            let resp = await ApiEndpoints.Jobs.fetchJobMetadata();
            this.setState({jobMetadata: groupArrayOnKey(resp.data.data, "job_id", true)});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    getAllJob = async () => {
        store.dispatch(setLoader(true));
        let { jobCategory } = this.state;
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            var jobList = apiData.data.data;
            let jobCategoryIds = jobList.map(o => o.job_category_id);
            let jobCategoryMapped = jobCategory.filter(cat => jobCategoryIds.indexOf(cat.job_category_id) !== -1)
            jobList = jobList
            .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
            .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
            
            if(this.props.match.params.job_id) {
                var job = jobList.filter(job => job.job_id === parseInt(this.props.match.params.job_id))[0];
                if(job) this.setState({ selectedJob: job })
            }
            await this.setState({ jobList, jobCategoryMapped })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    deletedJob = async (jobId) =>{
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.deleteJob({job_id : jobId});
            await this.getAllJob()
            this.setState({isOpenDeleteModal : false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }


    verifyBankByCsv = async (e) =>{
        e.preventDefault();
        this.setState({verifyBankLoader : true})
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))
        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key
            }
            let resp = await ApiEndpoints.UserProfile.verifyBankDetails(body)
            this.setState({isOpenVerifyBankModal : false});
            store.dispatch(setSuccess({show: true, message: resp.data.message}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({verifyBankLoader : false})
        store.dispatch(setLoader(false))
    }

    addWalletByCsv = async (e) =>{
        e.preventDefault();
        this.setState({addWalletLoader : true})
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))
        let { isOpenAddWalletModal } = this.state;
        try{
            if(!isOpenAddWalletModal.sender_ref_type) throw new Error("Mandatory : Select a Wallet entry type")
            if(isOpenAddWalletModal.sender_ref_type === "JOB" && !isOpenAddWalletModal.job_id) throw new Error("Mandatory : Select a Project")
            if(isOpenAddWalletModal.sender_ref_type === "JOB_TASK" && !isOpenAddWalletModal.job_task_id) throw new Error("Mandatory : Select a Task")
            if(!isOpenAddWalletModal.sender_ref_date) throw new Error("Mandatory : Select reference date")
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                sender_ref_date : isOpenAddWalletModal.sender_ref_date,
                sender_ref_type : isOpenAddWalletModal.sender_ref_type || "GENERAL",
                sender_ref_id : isOpenAddWalletModal.sender_ref_type === "JOB" ? isOpenAddWalletModal.job_id : (isOpenAddWalletModal.sender_ref_type === "JOB_TASK" ? isOpenAddWalletModal.job_task_id : null)
            }
            let resp = await ApiEndpoints.Userwallet.addWallet(body)
            this.setState({isOpenAddWalletModal : false});
            store.dispatch(setSuccess({show: true, message: resp.data.message}))
            downloadApiFile(resp, "wallet Upload Response.csv")
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({addWalletLoader : false})
        store.dispatch(setLoader(false))
    }

    downloadWallet = async (isBankPayableSheet) => {
        store.dispatch(setLoader(true))
        let { openWalletDownload } = this.state;
        let {job_id, start_date, end_date, transaction_type} = openWalletDownload;
        try {
            var body = {
                transaction_type: transaction_type === "GENERAL" ? "GENERAL":"JOB_SPECIFIC"
            }
            if(job_id && job_id.length)  body = {...body, filters : ["JOB_INVOLVED"], job_id: job_id.toString()};
            if(start_date) body.start_date = moment(start_date).startOf("day").toDate();
            if(end_date) body.end_date = moment(end_date).endOf("day").toDate();
            var method = isBankPayableSheet ? "downloadWalletDetailsBankPayable" : "downloadWalletDetails";
            var resp = await ApiEndpoints.Userwallet[method](body);
            downloadApiFile(resp, isBankPayableSheet ? "wallet Bank Payable.xlsx":"wallet.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    
    showProjectPendingSetup = async () => {
        store.dispatch(setLoader(true));
        let { isShowPendingSteps, jobList } = this.state;
        console.log(isShowPendingSteps, jobList );
        if(isShowPendingSteps) {
            this.setState({ isShowPendingSteps: false })
            return store.dispatch(setLoader(false));
        }
        try {
            var jobIds = jobList.map(job => job.job_id);
            if(!jobIds.length) return store.dispatch(setLoader(false));
            var body = {job_id: jobIds.toString()};
            var resp = await Promise.all([ApiEndpoints.Jobs.checkJobMandatorySteps(body), ApiEndpoints.Jobs.getJobsRatingData(body)]);
            this.setState({ isShowPendingSteps: {
                pendingData: resp[0].data.data, ratingData: groupArrayOnKey(resp[1].data.data, "job_id", true)} 
            });
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    togglePopover = (job) => {
        this.setState({
            isOpenPopover: this.state.isOpenPopover ? false : {...job}
        });
      }

    onChangeArchiveStatus = (key,value) => {
            let { isOpenPopover } = this.state;
            isOpenPopover[key] = value;
            this.setState({ isOpenPopover });
    }

    archiveStatusSave = async (job) => {
        
        store.dispatch(setLoader(true))
        let { jobList } = this.state;
        try{
            var body ={job_id : job.job_id , archive_status : job.archive_status , archive_message : job.archive_message};
            await ApiEndpoints.Jobs.jobArchive(body);
            jobList = jobList.map(obj => {
                if(obj.job_id === job.job_id){
                    obj = job;
                }
                return obj;
            });
            this.setState({jobList, isOpenPopover: false})
          }
          catch(e){
              store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
  
      }

    
    
    getPerformanceColor = (score) => {
        const interpolateColor = (color1, color2, factor = 0.5) => {
            var result = color1.slice(1).match(/.{2}/g).map((hex) => parseInt(hex, 16));
            color2.slice(1).match(/.{2}/g).forEach((hex, i) => {
                result[i] = Math.round(result[i] + factor * (parseInt(hex, 16) - result[i]));
            });
            return '#' + result.map((n) =>  (256 + n).toString(16).slice(-2)).join('');
        }
        if (score < 40)  return interpolateColor('#FF0000', '#FFA500', score / 40);
        else if (score <= 70) return interpolateColor('#FFA500', '#008000', (score - 40) / 30);
        else return interpolateColor('#008000', '#008000', (score - 70) / 30);
    }

    render() {
        let {editJob,jobList, selectedJob, jobMetadata, isOpenReferral, isOpenEvents, isOpenVerifyBankModal, isOpenAddWalletModal, verifyBankLoader, addWalletLoader, isOpenDeleteModal, isOpenCreateProjectModal } = this.state;
        let { openWalletDownload,  jobSearch, isShowPendingSteps, jobCategory, jobCategoryMapped, selectedCategory } = this.state;
        var userRole = store.getState().user.UserRole;
        let isAdmin = !!userRole.filter(role => role.role_id === 1).length;
        jobList = jobList.filter(job => !!jobSearch ? (job.Employer.employer_name+" - "+job.title).toLowerCase().includes(jobSearch.toLowerCase()) :true);
        jobList = jobList.filter(job => !!selectedCategory ? selectedCategory === job.job_category_id :true);
        if(this.state.isShowOnlyRecommended) jobList = jobList.filter(job => !!job.is_recommended);
        return(
            selectedJob ? 
                <ProjectOverview selectedJob={selectedJob} onClose={() => {this.props.history.push("/project/overview"); this.setState({selectedJob: null}) }} />
                :
                <div className="px-2">
                    <Row className="mb-3" style={{marginTop: "-10px"}}>
                        <strong className="mt-1 pl-4 text-secondary" style={{fontSize: "22px", marginLeft: "-10px"}}>Projects</strong>
                    </Row>
                    <Row className="mb-3">
                        <Col style={{marginRight: "-27px"}}>
                            {isAdmin && <Link to="/company"><Button className="buttonred py-0 mr-2" size="sm"><Icon className="align-middle">fingerprint</Icon></Button></Link>}
                            <Button id="create_project" onClick={()=> {this.setState({editJob : null, isOpenCreateProjectModal : true})}} type="button" className="buttonred" size="xs" style={{ height: "27px", paddingTop: "3px"}}>Create New Project</Button>
                        </Col>

                        <Col xs={10}>
                           

                            {isAdmin ?
                            <Aux>
                                <Button id="add_wallet_popup" onClick={()=> {this.setState({isOpenAddWalletModal : {}})}} type="button"  className="buttonred float-right ml-2" style={{ height: "27px", paddingTop: "3px"}}>Add Wallet Entries</Button> 
                                <Button id="download_wallet_popup" onClick={() => this.setState({openWalletDownload : {}})} type="button"  className="buttonred float-right ml-2" style={{ height: "27px", paddingTop: "3px"}}>Download Wallet Details</Button>
                               
                                {/* <Button id="verify_bank_account_popup" onClick={()=> {this.setState({isOpenVerifyBankModal : true})}} type="button"  className="buttonred float-right " style={{ height: "27px", paddingTop: "3px"}}>Verify Bank Details</Button> */}
                                {/* <Button id="referral_popup" onClick={()=> {this.setState({isOpenReferral : true})}} type="button"  className="buttonred  float-right mr-2" style={{ height: "27px", paddingTop: "3px"}}>Referral</Button> */}
                                {/* {!!isOpenReferral && <ReferralView user_id={isOpenReferral.user_id} job_id={this.state.selectedJobId} toggle={() => {this.setState({isOpenReferral : false})}} /> } */}
                                {/* <Button id="notification_story_popup" onClick={()=> {this.setState({isOpenNotification : true})}} type="button"  className="buttonred  float-right mr-2" style={{ height: "27px", paddingTop: "3px"}}>Notification / Story</Button> */}
                                {/* {!!isOpenNotification && <NotificationView user_id={isOpenNotification.user_id} job_id={this.state.selectedJobId} toggle={() => {this.setState({isOpenNotification : false})}} /> } */}
                           </Aux>
                           :null }
                           
                           {/* <Button id="events_popup" onClick={()=> {this.setState({isOpenEvents : true})}} type="button"  className="buttonred  float-right mr-2" style={{ height: "27px", paddingTop: "3px"}}>Webinar</Button> */}
                            {/* <Modal size="lg" isOpen={!!isOpenEvents} toggle={()=> this.setState({isOpenEvents : false})}>
                                <ModalHeader toggle={()=> this.setState({isOpenEvents : false})}>Events</ModalHeader>
                                <ModalBody className="p-3">
                                    {!!isOpenEvents && <Events user_id={isOpenEvents.user_id} jobList={jobList} /> }
                                </ModalBody>
                            </Modal> */}
                           
                        </Col>
                        
                    </Row>
                    <hr className="m-0" />

                   
                    <Row className={"justify-content-between align-items-center"}>
                        <Col xs={4} md={4} className="mt-2">
                            <Switch id="download_wallet_input4" size="small" checked={!!this.state.isShowPendingSteps} onChange={() =>  this.showProjectPendingSetup() } />
                            <span>Show projects setup pending status?</span>
                        </Col>
                        <Col xs={4} md={4} className="mt-2">
                            <Switch size="small" checked={!!this.state.isShowOnlyRecommended} onChange={() =>  this.setState({ isShowOnlyRecommended: !this.state.isShowOnlyRecommended }) } />
                            <span>Show only Recommended?</span>
                        </Col>
                        <Col xs={4} md={4}>
                            <InputFeild onChange={(e) => this.setState({jobSearch: e.target.value})} icon="search" placeholder="Search Project" />
                        </Col>
                    </Row>

                    <div className="my-2 px-0">
                        {
                            jobCategoryMapped.map(cat => 
                                <span onClick={() => this.setState({selectedCategory: selectedCategory === cat.job_category_id ? null : cat.job_category_id})} className="border px-3 py-1 mr-3" style={{borderRadius: 12, fontSize: 12, cursor: "pointer", background: selectedCategory === cat.job_category_id ? "#bbb" : "#eee"}}>{cat.title}</span>
                            )
                        }
                    </div>

                    <Row noGutters className={"row-eq-height"} style={{overflowY: "scroll", height: "550px"}}>
                    {jobList.map(job => {
                        var activeLocCount = job.JobLocations.filter(loc => !loc.archive_status);
                        var jobMetadataObj = jobMetadata[job.job_id] ? jobMetadata[job.job_id].metaData : {};
                        var clientBufferDays = jobMetadata[job.job_id] ? jobMetadata[job.job_id].clientDataBuffer : 0;
                        var jobStatusColor = job.archive_status === 0 ? "#4BB543" : job.archive_status === 1 ? "#e9a84c" : "#b52023";
                        return <Col lg={6} xs={12} className="p-2">
                            <div className="shadow-sm border-light p-2" style={{borderRadius: 10, backgroundColor: job.theme_color+"10"}}> 
                            <Row className="align-items-center visible-on-hover-container">
                                <Col xs={2} className="text-center">
                                    <img className="rounded-circle" style={{width: "75%", marginTop: "-10px"}} src={job.Employer.logo_key || "/images/dummy_profile.jpg"} alt="" /> 
                                    <div className="text-center" style={{position: "absolute", left: 0, right: 0, bottom: "-15px"}}>
                                        <div className="small d-inline-block px-2 py-0" style={{borderRadius: 12, background: (jobStatusColor)+"33"}} id={"project_"+job.job_id} onClick={() => this.togglePopover(job)}>
                                            {job.archive_status === 0 && <span style={{color: "#4BB543", cursor: "pointer"}}>● On</span>}
                                            {job.archive_status === 1 && <span style={{color: "#e9a84c", cursor: "pointer"}}>● Hold</span>}
                                            {job.archive_status === 2 && <span style={{color: "#b52023", cursor: "pointer"}}>● Off</span>}                                    
                                        </div>
                                    </div>
                                    <Popover target={"project_"+job.job_id} placement="bottom" isOpen={this.state.isOpenPopover.job_id === job.job_id} toggle={() => this.setState({isOpenPopover : false })}>
                                                <PopoverHeader>Status <Icon className="float-right" style={{cursor: "pointer", fontWeight: 800, fontSize: 18}} onClick={() => this.setState({isOpenPopover : false })}>close</Icon></PopoverHeader>
                                                <PopoverBody>
                                                    <Row className="justify-content-center my-3" style={{width: 300}}>
                                                        <Col xs={6}>
                                                            <div className="switch-toggle" style={{fontSize: 12}}>
                                                            {
                                                                Object.keys(CONSTANTS.ARCHIVE_STATUS).map((key, i) => 
                                                                    <div key={i} className={this.state.isOpenPopover.archive_status === CONSTANTS.ARCHIVE_STATUS[key] ? "active" : ""} onClick={() => this.onChangeArchiveStatus("archive_status", CONSTANTS.ARCHIVE_STATUS[key]) }>{key}</div>
                                                                )
                                                            }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {this.state.isOpenPopover.archive_status === 1 && <InputFeild className="my-2 p-0" style={{fontSize: 12}} placeholder={"Enter the Hold message"} value={this.state.isOpenPopover.archive_message || ""} onChange={(e) => this.onChangeArchiveStatus("archive_message", e.target.value) } />}
                                                <Button id={"archive_status_submit"+job.job_id} size="sm" block className="buttonred mt-2" onClick={() => this.archiveStatusSave(this.state.isOpenPopover)}>Save</Button>
                                                </PopoverBody>
                                    </Popover>
                                </Col>
                                <Col style={{cursor: "pointer"}} onClick={() => { this.props.history.push("/project/overview/"+ job.job_id); this.setState({selectedJob: job}) }}>
                                    <strong> 
                                        {job.Employer.employer_name +  "  - "   + job.title}{"  "} 
                                        {job.consumer_type > 1 && <span style={{borderRadius: 10, fontSize: 10}} className="py-1 px-2 ml-2 border bg-light">{job.consumer_type === 3 ? "Data Labeling" : "B2C"}</span>} 
                                    </strong><br />
                                   
                                    <em style={{fontSize: 12}}>
                                        <Icon style={{fontSize: 12, color: activeLocCount.length ? "green" : "red"}} className="align-middle">place</Icon>{' '}
                                        <span className="">{activeLocCount.length || "No"} Locations | {job.JobCategory && job.JobCategory.title}</span>
                                    </em>
                                    {!!job.is_recommended && <strong className="text-success small d-inline-block ml-2"><em style={{fontSize: 12}}>Recommended</em></strong>}

                                    

                                </Col>
                                {/* <span className="border px-2" style={{borderRadius: 8, position:"absolute", top: 8, right: 8, fontSize: 10}}>{job.JobCategory && job.JobCategory.title}</span> */}
                                <Col xs={"auto"} className="text-center">
                                    <div style={{ position: 'relative', display: 'inline-flex' }}>
                                        <CircularProgress style={{color: this.getPerformanceColor(parseInt(jobMetadataObj.VERIFICATION_SCORE) || 0 )}} size={30} thickness={5} variant="determinate" value={parseInt(jobMetadataObj.VERIFICATION_SCORE) || 0 } />
                                        <div style={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span style={{fontSize: 10}}>{Math.round(parseInt(jobMetadataObj.VERIFICATION_SCORE)) || "--"}</span>
                                        </div>
                                    </div>
                                    <span className="d-block small" style={{fontSize: 11}} title={jobMetadataObj.LAST_VERIFICATION_DATE ? moment(jobMetadataObj.LAST_VERIFICATION_DATE).utcOffset("+05:30").format("Do MMM'YY HH:mmA") : ""}>{jobMetadataObj.LAST_VERIFICATION_DATE ? moment().diff(moment(jobMetadataObj.LAST_VERIFICATION_DATE), "days") : "--"} Days Ago</span>
                                    <span className="d-block small" style={{fontSize: 11}}>ETA {jobMetadataObj.LAST_VERIFICATION_DATE && clientBufferDays ? moment(jobMetadataObj.LAST_VERIFICATION_DATE).add(clientBufferDays, "days").format("Do MMM") : "-"}</span>
                                    <div className="visible-on-hover-item" style={{position: "absolute", right: 5, top: "-20px"}}>
                                        <Icon id={"project_edit_popup_"+job.job_id} onClick={()=>this.setState({editJob : job, isOpenCreateProjectModal : true})} className="mr-2 mt-1" style={{cursor: "pointer", fontSize: 16}}>edit</Icon> 
                                        <Icon id={"project_delete_popup_"+job.job_id}  onClick={()=> {this.setState({isOpenDeleteModal : job.job_id})}} className="mt-1" style={{cursor: "pointer", fontSize: 16}}>delete</Icon>
                                    </div>
                                </Col>
                                {isShowPendingSteps && 
                                <Col xs={12}>
                                    <Row  className="align-items-center px-4">
                                        <Col xs={9} style={{fontSize: 12}}>
                                        {
                                            isShowPendingSteps.pendingData && isShowPendingSteps.pendingData[job.job_id] && Object.keys(isShowPendingSteps.pendingData[job.job_id]).map(type => <div>
                                                {!!isShowPendingSteps.pendingData[job.job_id][type].length && <strong>{type}</strong>}
                                                {isShowPendingSteps.pendingData[job.job_id][type].map(step => 
                                                <em className="d-block pl-3">
                                                   {step && step.is_mandatory ? <Icon style={{color: "#f00", fontSize: 14}} className="align-middle">error</Icon> : <Icon style={{color: "#f90", fontSize: 14}} className="align-middle">warning</Icon> }
                                                   <span className="align-middle ml-2">{step && step.label}</span> 
                                                </em>
                                                )}
                                            </div>
                                            )
                                        }
                                       </Col>
                                       <Col xs={3}>
                                       {(() => {
                                        let ratingObj = (isShowPendingSteps.ratingData && isShowPendingSteps.ratingData[job.job_id] && isShowPendingSteps.ratingData[job.job_id]) || {};
                                              return  <div>
                                                    <span style={{color: ratingObj.call_rating ? (ratingObj.call_rating <= 2 ? "#d00" : ratingObj.call_rating < 3.5 ? "#fa0" : "#080") : "#000"}} className="d-block"><Icon style={{fontSize: 16}} className="align-middle">call</Icon>: <span>{ratingObj.call_rating ? parseFloat(ratingObj.call_rating).toFixed(1): ""}</span></span>
                                                    <span style={{color: ratingObj.whatsapp_rating ? (ratingObj.whatsapp_rating <= 2 ? "#d00" : ratingObj.whatsapp_rating < 3.5 ? "#fa0" : "#080") : "#000"}} className="d-block"><Icon style={{fontSize: 16}} className="align-middle">chat_bubble</Icon>: <span>{ratingObj.whatsapp_rating ? parseFloat(ratingObj.whatsapp_rating).toFixed(1): ""}</span></span>
                                                    <span style={{color: ratingObj.event_rating ? (ratingObj.event_rating <= 2 ? "#d00" : ratingObj.event_rating < 3.5 ? "#fa0" : "#080") : "#000"}} className="d-block"><Icon style={{fontSize: 16}} className="align-middle">event</Icon>: <span>{ratingObj.event_rating ? parseFloat(ratingObj.event_rating).toFixed(1): ""}</span></span>
                                                </div>
                                            
                                       })()}
                                       </Col>
                                    </Row>
                                </Col>
                                }
                            </Row>
                            </div>
                        </Col>
                        })}
                    </Row>
                    
                    
                           
                                        
                                
                                        
                                        
                                     
                                
                       




                    {/* <Modal isOpen={isOpenVerifyBankModal} toggle={()=> {this.setState({isOpenVerifyBankModal : false})}} >
                        <form onSubmit={this.verifyBankByCsv}>
                        <ModalHeader toggle={()=> {this.setState({isOpenVerifyBankModal : false})}}>Verify Bank Details by CSV</ModalHeader>
                        <ModalBody className="pb-5 pl-5 pr-5 pb-3">
                            <Row>
                                <Col className="mb-3 mt-0">
                                    <a href ={ApiEndpoints.CONSTANTS.downloadBankDetails("ALL")} target="_blank"> <Button type="button"  className="buttonred float-left" style={{ height: "27px", paddingTop: "3px"}}>Download Bank Details</Button> </a> 
                                </Col>
                            </Row>
                            <input id="verify_bank_input" required type="file" name="csvFile" />
                            <p>*You have to upload the file that you have downloaded and change the status</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="verify_bank_submit" disabled={verifyBankLoader} type="submit" className="buttonred" style={{ height: "27px", paddingTop: "3px"}}>Update Status</Button>{' '}
                            <Button id="verify_bank_cancel" type="button" color="secondary" style={{fontSize: "12px", height: "27px", paddingTop: "3px"}} onClick={()=> {this.setState({isOpenVerifyBankModal : false})}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal> */}

                    <Modal size="lg" isOpen={!!openWalletDownload} toggle={()=> {this.setState({openWalletDownload : false})}} >
                        <ModalHeader toggle={()=> {this.setState({openWalletDownload : false})}}>Download Wallet Amount CSV</ModalHeader>
                        <ModalBody className="p-5">
                            <Row>
                                <Col>
                                <SelectList id="download_wallet_input1" placeholder="Select Job" multiple value={this.state.openWalletDownload.job_id || []} onChange ={(e) => {openWalletDownload.job_id = e.target.value; this.setState({openWalletDownload})}} >
                                { 
                                    jobList.map(job => 
                                    <option key={job.job_id} value={job.job_id}>{job.Employer.employer_name +  "-"  + job.title}</option>
                                    )
                                }
                                </SelectList>
                                </Col>
                            </Row>
                            <Row className="border-top mt-4">
                                <Col>
                                    <DatePicker
                                        selected={openWalletDownload.start_date || null}
                                        onChange={date => {openWalletDownload.start_date= date; this.setState({openWalletDownload})}}
                                        className="mt-3"
                                        dateFormat='dd-MMM-yyyy'
                                        id="download_wallet_input2"
                                        placeholderText="Start Date"
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={openWalletDownload.end_date || null}
                                        onChange={date => {openWalletDownload.end_date= date; this.setState({openWalletDownload})}}
                                        className="mt-3"
                                        dateFormat='dd-MMM-yyyy'
                                        id="download_wallet_input3"
                                        placeholderText="End Date"
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Switch id="download_wallet_input4" size="small" checked={openWalletDownload.transaction_type === "GENERAL"} onChange={() => {openWalletDownload.transaction_type = openWalletDownload.transaction_type === "GENERAL"? "JOB_SPECIFIC":"GENERAL"; this.setState({openWalletDownload})}} />
                                    <span>{openWalletDownload.transaction_type === "GENERAL" ? "General Transactions":"Project Specific Transactions"}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center mt-4">
                                    <Button id="download_wallet_submit" size="sm" onClick={() => this.downloadWallet()} color="dark">Download {openWalletDownload.job_id && openWalletDownload.job_id.length ? "Job":"All"} User Wallet Amount</Button>
                                    <Button id="download_wallet_cancel" size="sm" className="ml-3" onClick={() => this.downloadWallet(true)} color="dark">Download Bank Pay Sheet</Button>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                           
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={!!isOpenAddWalletModal} toggle={()=> {this.setState({isOpenAddWalletModal : false})}} >
                        <form onSubmit={this.addWalletByCsv}>
                        <ModalHeader toggle={()=> {this.setState({isOpenAddWalletModal : false})}}>Add Wallet Details by CSV</ModalHeader>
                        <ModalBody className="px-5 py-3">
                                <SelectList id="add_wallet_input1" placeholder="Select Entries Type" value={isOpenAddWalletModal.sender_ref_type} onChange ={(e) => {isOpenAddWalletModal.sender_ref_type= e.target.value; this.setState({isOpenAddWalletModal})}}>
                                    <option value="GENERAL">General Entries</option>
                                    <option value="JOB">Project Specific Entries</option>
                                    <option value="JOB_TASK">Tasks Specific Entries</option>
                                </SelectList>
                            {(isOpenAddWalletModal.sender_ref_type === "JOB" || isOpenAddWalletModal.sender_ref_type === "JOB_TASK") &&
                            <SelectList search={true} id="add_wallet_input2" placeholder="Select Reference Project" value={this.state.isOpenAddWalletModal.job_id} onChange ={async(e) => {if(isOpenAddWalletModal.sender_ref_type === "JOB_TASK") await this.fetchJobTaskAddWallet(e.target.value); isOpenAddWalletModal.job_id= e.target.value; this.setState({isOpenAddWalletModal})}} >
                                { 
                                jobList.map(job => 
                                <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title} {job.JobLocations.length ? " ( " : ""} {job.JobLocations.map(obj => obj.city).join( ", ")}  {job.JobLocations.length ? " ) " : ""} </option>
                                )
                                }
                            </SelectList>
                            }
                            {isOpenAddWalletModal.sender_ref_type === "JOB_TASK" &&
                            <SelectList id="add_wallet_input3" placeholder="Select Task" value={this.state.isOpenAddWalletModal.job_task_id} onChange ={(e) => {isOpenAddWalletModal.job_task_id= e.target.value; this.setState({isOpenAddWalletModal})}} >
                                { 
                                (isOpenAddWalletModal.taskList || []).map(task => 
                                <option key={task.job_task_id} value={task.job_task_id}> {task.task_title} </option>
                                )
                                }
                            </SelectList>
                            }
                            <DatePicker required
                                        selected={this.state.isOpenAddWalletModal.sender_ref_date}
                                        onChange={date => {isOpenAddWalletModal.sender_ref_date = date; this.setState({isOpenAddWalletModal})}}
                                        className="mt-3"
                                        id="add_wallet_input4"
                                        dateFormat='dd-MMM-yyyy'
                                        placeholderText="Reference Date"
                            />
                            <input id="add_wallet_file_input" required type="file" name="csvFile" className="mt-4 mb-2" />
                            <p style={{fontSize: 12}}><em>Download Sample File, Fill the details and upload here</em></p>
                            <a target="_blank" download={true} rel="noopener noreferrer" href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/payment_upload_sample.xlsx">Download Sample File</a>
                            
                        </ModalBody>
                        <ModalFooter>
                            <Button id="add_wallet_submit" disabled={addWalletLoader} type="submit" color="primary" >Upload Data</Button>{' '}
                            <Button id="add_wallet_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenAddWalletModal : false})}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>

                    <Modal isOpen={!!isOpenDeleteModal} toggle={()=> {this.setState({isOpenDeleteModal : false})}} >
                        <form>
                        <ModalHeader toggle={()=> {this.setState({isOpenDeleteModal : false})}}>Delete</ModalHeader>
                        <ModalBody>
                            <b>Are you sure to delete  ?</b>
                        </ModalBody>
                        <ModalFooter>
                            <Button id="delete_popup_submit" onClick={() => this.deletedJob(isOpenDeleteModal)}  className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Sure</Button>{' '}
                            <Button id="delete_popup_cancel" type="button" color="secondary" onClick={()=> {this.setState({isOpenDeleteModal : false})}} style={{fontSize: "12px", height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>


                    
                    <Modal isOpen={isOpenCreateProjectModal} toggle={()=> {this.setState({isOpenCreateProjectModal : false})}} >
                        <ModalHeader toggle={()=> {this.setState({isOpenCreateProjectModal : false})}}>Create Project <br></br>
                        </ModalHeader>
                        <ModalBody>
                            { isOpenCreateProjectModal && 
                             <JobCreateEdit jobCategoryList={jobCategory} editJob={editJob}  onSuccess={async () => {await this.getAllJob(); this.setState({isOpenCreateProjectModal : false})}} />
                            }
                        </ModalBody>
                    </Modal>

                    <ConfirmAction message={this.state.confirmMessage} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

                    
            </div>
              
    );
  }
 }
export default Projects;
