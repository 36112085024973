import React, { Component, useState }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Checkbox, Chip, Icon, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import UserCard from "../user_profile/user_card"
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import CONSTANTS from "../../../constants";
import WhatsappChat from "../../global/whatsapp_chat";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import OutboundCallingStatus from "../../global/outbound-calling-status";
import PartnerDetail from "../user_profile";

class WebinarAttendance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            attendeesList: [],
            feedback: {},
           isOpenConfirm: false,
           searchAttendeeText: "",
           selectedAttendees: [],
           rowsPerPage : 20,
           currentUserPage : 0,
           currentAttendeePage: 0,
           isAddNewAttendee:false,     
           isConfirm : false,
           isConfirmMessage : null,
           callDetails : null,
           isFeedbackFilter: false
        }
    }
    componentDidMount(){
        this.fetchAttendees();
    }

    createNewAttendees = async (e) =>{
        e.preventDefault();
        store.dispatch(setLoader(true));
        let { eventData } = this.props;
        try {
           
            let body = {
                event_id: eventData.event_id,
                first_name: e.target.first_name.value,
                mobile: e.target.mobile.value
            }
          await ApiEndpoints.Events.createNewAttendees(body);
            this.setState({ isAddNewAttendee: false })
            await this.fetchAttendees()
        } catch (e) {
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchAttendees = async () =>{
        let { eventData } = this.props;
        store.dispatch(setLoader(true))
        try{
            var resp = await Promise.all([
                ApiEndpoints.Events.fetchEventAttendees({event_id: eventData.event_id}),
                ApiEndpoints.Events.fetchEventFeedback({event_id: eventData.event_id})
            ]);
            this.setState({ 
                attendeesList: resp[0].data.data, 
                feedback: resp[1].data.data || { training_rating: {}, feedback_status: {} }

            });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    selectAttendees = (event_attendee_id) => {
        let { selectedAttendees } = this.state;
        var i = selectedAttendees.indexOf(event_attendee_id);
        if(i === -1) selectedAttendees.push(event_attendee_id);
        else selectedAttendees.splice(i, 1);
        this.setState({ selectedAttendees })
    }

    updateEventAttendees = async (status) =>{
        let { selectedAttendees, attendeesList } = this.state;
        let { eventData } = this.props;

        store.dispatch(setLoader(true))
        try{
            var body = {
                event_attendee_id: selectedAttendees,
                status
            }
            await ApiEndpoints.Events.updateEventAttendees(body);
            var resp = await ApiEndpoints.Events.fetchEventAttendees({event_id: eventData.event_id});
            selectedAttendees = [];
            attendeesList = resp.data.data;
            this.setState({ selectedAttendees, attendeesList });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
   
    toggleCreateEvent = (event) => {
        let { isAddNewAttendee } = this.state;
        isAddNewAttendee = !isAddNewAttendee;
        this.setState({isAddNewAttendee});
      }
  
      makePhoneCall = async (user) => {
        let { eventData } = this.props;
        store.dispatch(setLoader(true))
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "EVENT",
                subentity_type: "APPLICATION_STATUS",
                entity_id: eventData.event_id,
                subentity_id: CONSTANTS.APPLICATION_STATUS.IN_TASK,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            this.setState({callDetails: {calling_id : resp.data.data.Sid, user}});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
      }
  
    render() {       
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
        let { attendeesList, feedback, searchAttendeeText, selectedAttendees, isAddNewAttendee,isConfirm, isFeedbackFilter} = this.state;
        let { eventData } = this.props;

        let isAllowAttendance = moment().isAfter(eventData.start_date) && !!userRole.filter(role => role.role_id === 9).length;
        
        let attendeesListFiltered = [...attendeesList];
        if(searchAttendeeText) attendeesListFiltered = attendeesListFiltered.filter(att =>   ( (att.User.first_name || "").toLowerCase().includes(searchAttendeeText.toLowerCase()) || ("PMW"+att.User.id).toLowerCase().includes(searchAttendeeText.toLowerCase()) ))

        if(isFeedbackFilter) attendeesListFiltered = attendeesListFiltered.filter(att => att.training_rating === isFeedbackFilter);
        return (
            <>                         
                    <div className="m-1 p-3 shadow">
                        <Row className={"mt-2 pb-2 border-bottom"}>
                            <Col xs={8}>
                                {!!isAllowAttendance && <Button className="button_outline p-1" onClick={(event) => this.toggleCreateEvent(event)}>Add Attendees</Button>}
                                <strong className="text-secondary ml-3">Registered: {attendeesList.length}</strong>
                                <strong className="text-secondary ml-3">Visited: {attendeesList.filter(o => o.status === 1).length}</strong>
                                <strong className="text-secondary ml-3">Joined: {attendeesList.filter(o => o.status === 2).length}</strong>
                            </Col>
                            <Col xs={4}><InputFeild onChange={(e) => this.setState({searchAttendeeText: e.target.value, currentAttendeePage: 0})} icon="search" placeholder="Search by Name/PMW-Id" /></Col>
                        </Row>
                        
                        {moment().isAfter(eventData.end_date) && !!attendeesList.length && <Row className={"my-2 border-bottom pb-2"}>
                            <Col xs={4}>
                                <span className="small font-weight-bold">How was the experience?</span>
                                <Row className={" mt-2"}>
                                    <Col>
                                        <Icon fontSize="large" className={"text-success align-middle clickable "+ (isFeedbackFilter === 3 ? "border-bottom border-danger" : "") } onClick={() => this.setState({isFeedbackFilter:  isFeedbackFilter === 3 ? false : 3 })}>thumb_up</Icon>{" "}
                                        <strong className="align-middle text-secondary">{feedback.training_rating["3"] || 0}</strong>
                                        <Icon fontSize="large" className={"text-danger align-middle ml-3 clickable "+(isFeedbackFilter === 1 ? "border-bottom border-danger" : "")} onClick={() => this.setState({isFeedbackFilter:  isFeedbackFilter === 1 ? false : 1 })}>thumb_down</Icon>{" "}
                                        <strong className="align-middle text-secondary">{(feedback.training_rating["2"] || 0)+(feedback.training_rating["1"] || 0)}</strong>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={4} className="text-center">
                                <span className="small font-weight-bold mb-2 ">Why not liked?</span>
                                <Chip size="small" label={<span>Trainer did not explain properly <b> - {feedback.feedback_status["12"] || 0}</b></span>} /> 
                                <Chip size="small" label={<span>Did not understand training <b> - {feedback.feedback_status["11"] || 0}</b></span>} /> 
                                <Chip size="small" label={<span>Error while joining meeting <b> - {feedback.feedback_status["13"] || 0}</b></span>} /> 
                            </Col>
                            <Col xs={4} className="text-center ">
                                <span className="small font-weight-bold mb-2 ">Why not joined?</span>
                                <Chip size="small" label={<span>I'm not interested in this Project <b> - {feedback.feedback_status["2"] || 0}</b></span>} /> 
                                <Chip size="small" label={<span>I was busy that time <b> - {feedback.feedback_status["1"] || 0}</b></span>} /> 
                                <Chip size="small" label={<span>I will join next session <b> - {(feedback.feedback_status["4"] || 0) + (feedback.feedback_status["5"] || 0)}</b></span>} /> 
                            </Col>
                        </Row>}
                        <Row>
                            {!!selectedAttendees.length && isAllowAttendance && <Col className="text-right"><Button onClick={() => this.updateEventAttendees(2)} className="my-2 buttonred">Mark Joined</Button></Col>}
                        </Row>
                        <Row noGutters className="" style={{borderRadius: 8, fontSize: 14}}>
                            {attendeesListFiltered
                            .slice(this.state.currentAttendeePage*15, this.state.currentAttendeePage*15 + 15).map(att => 
                                <Col xs={6} md={4}>
                                    <Row noGutters className={"align-items-center border m-1 shadow-sm p-1"}>
                                        {att.status === 1 && <Icon fontSize="small" style={{position: "absolute", top: 5, right: 10}}>link</Icon>}
                                         
                                        <Col xs={1}>
                                
                                            {att.status === 2  ? 
                                                <Icon fontSize="small">task_alt</Icon> :
                                                
                                                (isAllowAttendance &&<input type="checkbox" className="m-2" checked={selectedAttendees.includes(att.event_attendee_id)} onChange={(e) => this.selectAttendees(att.event_attendee_id)} />)
                                                
                                            }
                                        </Col>   
                                            <Col>
                                                <UserCard data={att.User} />
                                           </Col>
                                   
                                            <Col  className="align-item-right" style={{display:"contents"}}>
                                                 <Icon className="d-block align-middle mx-2" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(att.User), isConfirmMessage : "Are you sure to connect with "+att.User.first_name+"?"})}
                                                 style={{fontSize:16, cursor: "pointer"}} >call</Icon>                     
                                               {/* <img src={WhatsappIcon} alt=""  style={{cursor : "pointer"}} onClick={() => this.setState({whatsappChat: att.User})}/> */}
                                        </Col>
                                       
                                      
                                        
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <TablePagination rowsPerPageOptions={[15]} component="div" count={attendeesList.length} rowsPerPage={15} page={this.state.currentAttendeePage}
                            onChangePage={ (e, page) => this.setState({currentAttendeePage : page}) }
                        />
                    </div>     
        
                   <ConfirmAction className="mt-4" isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />
                    <Modal  size="md" isOpen={isAddNewAttendee} 
                    toggle={()=> {this.setState({isAddNewAttendee : false})}} >
                      <ModalHeader toggle={()=> {this.setState({isAddNewAttendee : false})}}>Add Attendees</ModalHeader>
                        <ModalBody className="p-0">
                            <form onSubmit={this.createNewAttendees}>
                                <Row className="ml-5 mr-5">                           
                                    <Col className="py-2 mt-3">
                                        <div className="text-center">
                                           <input className="mt-2 bg-light border-dark" 
                                           required placeholder="Enter Name" name="first_name"
                                           autoComplete="off"
                                          minLength={4} 
                                       
                                        />
                                        </div>    
                                    </Col>                                                     
                                </Row>
                                <Row className="ml-5  mr-5">
                                    <Col className="py-3">
                                        <div className="text-center">
                                            <input className="mt-3 bg-light border-dark "
                                             required placeholder="Enter Mobile Number" name="mobile"
                                             minLength={10}
                                             autoComplete="off"
                                             type="text"
                                             pattern="[0-9]{10}"
                                           />
                                        </div>
                                    </Col>
                                </Row>
                        
                                <Row className="ml-3 mr-3">
                                    <Col className="py-4">
                                        <div className="text-center">
                                            <Button  className="buttonred"  type="submit">Submit</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                            
                        </ModalBody>
                    </Modal>
                    {!!this.state.isOpenUserModel && <PartnerDetail {...this.state.isOpenUserModel} toggle={() => this.setState({isOpenUserModel : false})} />}           
                    <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
                {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

            </>
            
            
        );
    }
}
export default WebinarAttendance;
