


const HelloWorldText = ( 
`
<html>
 <head>
 <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
 <style>
     body{
       //zoom : 0.4;
     }
     .logo1 {
         width: 175px;
     }
     .wrapper {
         background-color: #fff;
         padding: 20px;
         font-family: "Times New Roman";
         width: 1100px;
         /* -webkit-transform-origin: 0 0;
         transform-origin: 0 0;  */
         margin: auto;
         z-index: 99999999;
     }
     .sub-heading-1 {
       font-size: 1.6rem;
       font-weight: 500;
     }
     .sub-heading-2 {
       font-size: 1.4rem;
       font-weight: 500;
     }
     .sub-heading-3 {
       font-size: 1.4rem;
       font-weight: 700; 
     }


 </style>
 </head>
 <body>
 <div class="wrapper sub-heading-1" >
         <div class="row justify-content-between align-items-center  mr-2">
             <img class="logo1" src="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/img/PMW_300.png" alt="logo"/>
             <img style="height:35px" src="https://www.reculta.com/assets/images/logo/Reculta%20white%20logo%202.png" alt="logo"/>
         </div>
           <div class="mx-0 mt-5" >
             <span class="sub-heading-3" >WO/PMW/123</span>
           </div>
           <div class="row mx-0 justify-content-center" >
             <span class="sub-heading-3 " >Order for Work </span>
           </div>
           <div class="mx-0 mt-4" >
             <span>A work order is hereby given for the following to PMW Partner <span class="sub-heading-3" style="border-bottom: 1px dotted;">Name</span> with Aadhaar No. <span class="sub-heading-3" style="border-bottom: 1px dotted;">1234 1234 1234</span></span>
           </div>
           <div class="mx-0 mt-4" >
             <span>Your Partner Code is <span class="sub-heading-3">PMW123456</span></span>
           </div>
           <div class="mx-0  my-4" >
             <span class="r-heading-5" >Work</span>
           </div>
           <div style="min-height:500px" class="sub-heading-2">
             <div class="mx-0" >
               %s
             </div>
             <div class="mx-0" >
               <span class="r-heading-5 my-4" >Remuneration</span>
             </div>
             <div class="mx-0 mt-3" >
               %s
             </div>
             <div class="mx-0" >
                 <span class="r-heading-5 my-4" >Important</span>
               </div>
             <div class="mx-0 my-4">      
                 %s
             </div>
           </div>
           <div class="footer">
               <div class="row mt-5 justify-content-between" >
                 <div class="col-6">
                     <div><span>On behalf of Reculta Solutions Pvt Ltd</span></div>
                     <div class="col-6 border" style="width: 250px; height:150px;">
                       <img onerror="this.style.display = 'none'" style="width : 90%; max-height:90%; margin:5%;" src="" alt="logo"/>
                     </div>
                     <div><span>Utsav Bhattacharjee</span></div>
                     <div><span>Director</span></div>
                     <div><span>Date: Date</span></div>
                 </div>
                 <div class="col-6 text-right">
                   <span>Acknowledged by </span>
                   <div class="row justify-content-end">
                     <div class="col-6 border" style="width: 250px; height:150px;">
                       <img onerror="this.style.display = 'none'" style="width : 90%; max-height:90%; margin:5%;" src="" alt="logo"/>
                     </div>
                   </div>
                   <div><span>Signature of PMW Partner</span></div>
                   <div><span class="r-heading-5">Partner Name : Name</span></div>
                   <div><span class="r-heading-5">Partner ID : PMW123456</span></div>
                   </div>
               </div>
               <div class="row mt-5 justify-content-between">
                 <div class="col-6">
                     <div><span>Reculta Solutions Pvt. Ltd.</span></div>
                     <div><span>www.reculta.com</span></div>
                     <div><span>182, Plot No. 20, Vardhman Charve Complex Community Centre, Pitampura - 110034, New Delhi, India</span></div>
                 </div>
                 <div class="col-6 text-right">
                     <div><span>CIN: U93090DL2017PTC319788</span></div>
                     <div><span>email: support@pickmywork.com</span></div>
                     <div><span>Phone: 0124 4274146</span></div>
                 </div>
               </div>
               </div>
       </div>
  </body>
 </html>`
  );
export default HelloWorldText;
