import React, { Component } from 'react';
import { Chip, Icon, Snackbar } from '@material-ui/core';
import { Alert, Col, Modal, ModalBody, Row } from 'reactstrap';
import store from '../../store';
import ApiEndpoints from "../../constants/api_endpoints";
import { setError, setLoader } from '../../actions/action-creators';
import Button from 'reactstrap/lib/Button';
import SelectList from '../global/form/select';
import CONSTANTS from '../../constants';
import { TrackEvent } from "../../actions/ga"
import JobInformation from '../views/project/job_information';
import UpcomingWebinar from '../views/user_profile/upcoming_webinar';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import PartnerDetail from '../views/user_profile';


class OutboundCallingStatus extends Component {
	constructor(props) {
		super(props);
		this.timer = 0;
		this.state = {
		  callTimer: 0,
		  callDetails : {},
		  commentType: "Callback",
		  commentValue: null,
		  commentText: null,
		  isLoadingInfo: true,
		  selectedJobId: null,
		  showOnlyStatus: true,
		  isOpenUserModel: null
		  }
		}
	componentDidMount() {
		TrackEvent("CALL", "CALL_INITIATED", "CALL_INIT");
		this.fetchCallStatus();
		this.timer = setInterval(this.callTimer, 1000);
	}
	componentWillUnmount() {
		clearInterval(this.interval);
		clearInterval(this.timer);
	}
	
	callTimer = () => {
		this.setState({ callTimer: this.state.callTimer+1 });
		if( (this.state.callTimer >= 20 && this.state.callDetails.status === "in-progress") || this.state.callDetails.status === "completed"){
			this.setState({ showOnlyStatus: false })
		}
		if(this.state.callDetails.status !== "in-progress") clearInterval(this.timer);
	}


	fetchCallStatus = async () => {
		try{
			var body = { call_id : this.props.calling_id }
			
			var resp = await ApiEndpoints.Calling.fetchOutboundCall(body);
			let callDetails = resp.data.data || {};
			if(callDetails.entity_type === "JOB" && callDetails.entity_id && !this.interval) this.setState({selectedJobId: callDetails.entity_id})
			await this.setState({callDetails});

			this.setState({isLoadingInfo: false});

			if(this.props.onUpdateCall) this.props.onUpdateCall(resp.data.data)

			if(resp.data.data.status === "in-progress"){
				this.interval = setTimeout(this.fetchCallStatus, 5000);
			}
			// else{
			//  setTimeout(this.props.onClose, 3000)
			// }
		}
		catch(e){
			this.props.onClose && this.props.onClose();
			store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to fetch call details"}))
		}
	}

	selectJob = async (jobId) => {
		await this.setState({ isLoadingInfo: true });
		this.setState({ selectedJobId: jobId, isLoadingInfo: false })
	}

	closeCall = async () => {
		let { callDetails, commentType, commentValue, commentText } = this.state;
		store.dispatch(setLoader(true))
		try{
			if(!commentType) throw new Error("Mandatory: Select call comment");
			var body = {
				user_id: this.props.calledUser.user_id,
				source_type: "CALL",
				source_id: callDetails.call_log_id,
				comment_type: commentType,
				comment_value: commentValue || CONSTANTS.CALL_COMMENTS[commentType].defaultValue,
				comment_text: commentText,
				ref_type: callDetails.entity_type,
				ref_id: callDetails.entity_id
			};
			callDetails.subentity_type !== "CUSTOMER_LEAD" && await ApiEndpoints.User.addComment(body);
			var callLogsBody = {
				call_log_id : callDetails.call_log_id,
				comment: `${body.comment_type}-${body.comment_text ? body.comment_text : (CONSTANTS.CALL_COMMENTS[body.comment_type].type === "DATE_TIME" ? moment(body.comment_value).utcOffset("+05:30").format("Do MMM'YY HH:mm") : body.comment_value) }`
			};
			await ApiEndpoints.Calling.updateCallData(callLogsBody)

			this.props.onClose();

		}
		catch(e){
			store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to fetch call details"}))
		}
		store.dispatch(setLoader(false));
	}



	render() {
		let user = store.getState().user;
		let jobList = store.getState().jobList;
		let { calledUser = {}, remark="" } = this.props;
		let { callDetails , commentType, commentValue, selectedJobId, isLoadingInfo } = this.state;

		return (
			<Modal size="lg" isOpen={true} style={{zIndex: 1200}}>
				<ModalBody>
					<Row noGutters className="border-bottom align-items-center text-center pb-3 mb-3">
						<Col xs={12} lg={4}>
							<span className='border p-1 mr-3' style={{borderRadius: "50%"}}><Icon fontSize="small" className="align-middle">support_agent</Icon></span>
							<span className='font-weight-bold'>{user.first_name}</span>
						</Col>
						<Col xs={12} lg={4} className="text-center">
							<div className='bg-success py-1 text-light'  style={{borderRadius: 20}}>
								<Icon fontSize="small" className="align-middle mr-3">phone_in_talk</Icon>
								<span className='font-weight-bold'>{callDetails.status}</span>
								<span className='mr-2 float-right'>{this.state.callTimer}</span>
							</div>
						</Col>
						<Col xs={12} lg={4} className="clickable" onClick={(e) => this.setState({ isOpenUserModel: calledUser })}>
							<span className='border p-1 mr-3' style={{borderRadius: "50%"}}><Icon fontSize="small" className="align-middle">person</Icon></span>
							<span className='font-weight-bold'>{calledUser.first_name}</span>
						</Col>
						{!!remark && 
						<Col xs={12} className="text-center">
							<span className='' style={{fontSize: 12}}>Remark: {remark}</span>
						</Col>
						}
					</Row>
				{!this.props.showOnlyStatus && !this.state.showOnlyStatus && 
				<>
					<Row className="mb-2 py-1 border-top">
						<Col xs="6">
							<SelectList placeholder="Select Project" selectAll="General" search={true} value={this.state.selectedJobId} onChange ={(e) => this.selectJob(e.target.value)} >
                                { jobList.map(job => <option key={job.job_id} value={job.job_id}> <img className='mr-2' width={16} src={job.Employer.logo_key} alt="Project logo" /> {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                             	</option>)}
                            </SelectList>
						</Col>
					</Row>
					{!!selectedJobId && !isLoadingInfo && <>
						<Row className="mb-3" style={{maxHeight: 350, overflowY: "scroll"}}>
							<Col>	
								<JobInformation job={{job_id: selectedJobId}} isOnlyView={true} selectedTab="CALLING_TAB" />
							</Col>
						</Row>
					</>
					}
					{!isLoadingInfo && <Row className="border-top border-bottom py-2 align-items-center">
						<Col>
							<UpcomingWebinar user_id={callDetails.direction === 2 ? callDetails.to : callDetails.from} job_id={selectedJobId} />
						</Col>
					</Row>}
				</>
			}
				
				{
				this.state.showOnlyStatus || this.props.isSkipCallFeedback  ?
					<div className='text-center mt-2'><Button onClick={() => this.props.onClose()} color="danger" size="sm" >Close</Button></div>
					:
					<>
						<Row className="border-top border-bottom py-3 align-items-center">
							<Col md={6} xs={12}>
								<SelectList size="small" search={true} value={commentType} placeholder="Partner Status" onChange={(e) => this.setState({commentType: e.target.value, commentValue: null, commentText: null})}>
									{
										Object.keys(CONSTANTS.CALL_COMMENTS).map((reason, i) => 
										<option value={reason}> {reason} </option> 
									)}
								</SelectList>
							</Col>
							{CONSTANTS.CALL_COMMENTS[commentType].type === "DATE_TIME" && 
							<Col md={6} xs={12}>
								<ReactDatePicker 
									selected={commentValue ? moment(commentValue).toDate() : CONSTANTS.CALL_COMMENTS[commentType].defaultValue}
									onChange={date => this.setState({commentValue : date})}
									showTimeSelect
									className=""
									placeholderText={CONSTANTS.CALL_COMMENTS[commentType].label}
									timeFormat="HH:mm"
									timeIntervals={15}
									// minDate={jobDetails.deadline ? moment(jobDetails.deadline).toDate() : new Date()}
									timeCaption="time"
									dateFormat="dd-MMM-yyyy HH:mm"
								/>
							</Col>}
							{CONSTANTS.CALL_COMMENTS[commentType].type === "ENUM" && 
							<Col md={6} xs={12}>
								<SelectList size="small" search={true} value={commentValue || CONSTANTS.CALL_COMMENTS[commentType].defaultValue} placeholder={CONSTANTS.CALL_COMMENTS[commentType].label} onChange={(e) => this.setState({commentValue: e.target.value})}>
									{
										CONSTANTS.CALL_COMMENTS[commentType].values.map((v, i) => 
										<option value={v}> {v} </option> 
									)}
								</SelectList>
							</Col>}
							{!CONSTANTS.CALL_COMMENTS[commentType].type && <Col xs={12} className="mt-2"><textarea onChange={(e) => this.setState({commentText: e.target.value})} className="form-control w-100" rows="2" placeholder='Enter call summery' /></Col> }

						</Row>
						<div className='text-center mt-2'>
							<Button onClick={() => this.closeCall()} color="danger" size="sm" >{this.state.commentId ? "Save & Close" : "Save"}</Button>
						</div>
					</>
				}
				
					{/* {
						callDetails.subentity_type === "CUSTOMER_LEAD" && <div className='text-center mt-2'>
						<Button onClick={() => this.props.onClose()} color="danger" size="sm" >Close</Button>
					</div>
					} */}

				{!!this.state.isOpenUserModel && <PartnerDetail {...this.state.isOpenUserModel} toggle={() => this.setState({isOpenUserModel : false})} />}

				</ModalBody>
			</Modal>
		)
	}
}

export default OutboundCallingStatus;
