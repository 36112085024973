import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, Modal, ModalBody } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";

class CustomerBasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        }
    }

    componentDidMount(){
      
    }

  

     
    render() {
        let {  } = this.state;
        let { customerDetail } = this.props;
        if(!customerDetail.CustomerDatum) customerDetail.CustomerDatum = {}
        if(!customerDetail.CustomerBusinessDatum) customerDetail.CustomerBusinessDatum = {}
        return (
                <div className="" style={{fontSize: 14}}>
                    <Row className="mt-3 border-bottom">
                        <Col xs={3} className="mb-1">
                            <img style={{width: "100%"}} src= {"/images/dummy_profile.jpg"}  alt="Profile"></img>
                        </Col>
                        <Col xs={9} className="mb-2">
                            <span className="text-dark d-block" style={{fontSize: 14}}><Icon style={{fontSize: 14}}>badge</Icon> #{customerDetail.customer_lead_id}</span>
                            <span className="text-dark d-block" style={{fontSize: 15}}><Icon style={{fontSize: 14}}>account_box</Icon> {customerDetail.name}</span>
                            <span className="text-muted d-block" style={{fontSize: 14}}><Icon style={{fontSize: 14}}>email</Icon> {customerDetail.email}</span>                            
                            <span className="text-muted d-block" style={{fontSize: 14}}><Icon style={{fontSize: 14}}>phone</Icon>  {customerDetail.mobile && "******"+customerDetail.mobile.toString().substr(-4)}</span>
                        </Col>
                    </Row>
                            

                    <Row>
                        <Col className="" xs={12}>
                            <span className="d-block font-weight-bold mt-2 mb-4">Personal Details</span>

                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Year of Birth: {customerDetail.CustomerDatum.year_of_birth || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Gender: {customerDetail.CustomerDatum.gender || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Earning: {customerDetail.CustomerDatum.earning || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Financial Score: {customerDetail.CustomerDatum.cibil_score || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Occupation: {customerDetail.CustomerDatum.occupation || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>PAN No: {customerDetail.CustomerDatum.pan_no || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Aadhaar No: {customerDetail.CustomerDatum.aadhaar_no || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Address: {customerDetail.CustomerDatum.address || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Address2: {customerDetail.CustomerDatum.pincode || "-"}, {customerDetail.CustomerDatum.city || "-"}, {customerDetail.CustomerDatum.state || "-"}</span>

                        </Col> 
                        
                        <Col className=""  xs={12}>
                            <span className="d-block font-weight-bold mt-2 mb-4">Business Details</span>

                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Business Name: {customerDetail.CustomerBusinessDatum.business_name || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Type: {customerDetail.CustomerBusinessDatum.business_type || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Category: {customerDetail.CustomerBusinessDatum.category || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>Monthly Earning: {customerDetail.CustomerBusinessDatum.monthly_income || "-"}</span>
                            <span className="text-dark d-block my-2" style={{fontSize: 14}}>GST No: {customerDetail.CustomerBusinessDatum.gst_no || "-"}</span>

                        </Col>
                    </Row>

                            
                      

            </div>
            
        );
    }
}
export default CustomerBasicDetails;
