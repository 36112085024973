import React, { Component } from "react";


class Page404 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
    
  }

  render() {
    
    return (
      <h3 className="text-muted text-center mt-5 pt-5"><em>Oops! Page Not Found</em></h3>
    );
  }
}

export default Page404;