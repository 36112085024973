
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, CardBody, Card } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css";
import { Progress } from 'reactstrap';
import {uploadFile} from "../../../../actions";
import store from "../../../../store";
import { setError , setLoader} from "../../../../actions/action-creators";
import VideoUploadFeild from "../../../global/form/upload-video";
import ConfirmAction from "../../../global/confirm-action";
import ImageUploadFeild from "../../../global/form/upload-image";

class JDVideo extends Component {
    constructor(props) {
      super(props);
      this.addEditTrainingQuesView = React.createRef()  
      this.state = {
            isAddJDMedia: {},
            addJDMediaProgress: false,
            mediaList : [] ,
            isAddMedia: false,
            isOpenConfirm : false,
            
        }
    }
    componentDidMount(){
        this.fetchMedia()
    }

    
    fetchMedia = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id : this.props.selectedJob.job_id ,
                type: "JD",
            }
            let apiData = await ApiEndpoints.Jobs.fetchJobMedia(body);
            let mediaList = apiData.data.data;
            this.setState({ mediaList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    submitJDVideo = async (e) => {
        e.preventDefault();
        let { isAddJDMedia, mediaList } = this.state;
        let jobMedia = mediaList[0];
        var jdVideoResp = await uploadFile(isAddJDMedia.file, "JOB_DATA", (progress) => this.setState({addJDMediaProgress : progress}));
        if(!jobMedia){
            var contentBody = {
                job_id : this.props.selectedJob.job_id ,
                type : "JD",
                title: "Job Description",
                step_number: 1
            };
            var resp = await ApiEndpoints.Jobs.addJobMedia(contentBody);
            jobMedia = resp.data.data;
        }
        var body = {
            job_media_id: jobMedia.job_media_id,
            file_key : jdVideoResp.file_id,
            language : this.props.language,
            media_length : 1,
            description: "Job Description"
        }
        await ApiEndpoints.Jobs.addJobMediaContent(body);
        this.fetchMedia()
        this.setState({addJDMediaProgress : false, isAddJDMedia : {}})
    }

   
    deleteMedia = async (job_media_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {job_media_id};
            await ApiEndpoints.Jobs.inactiveJobMedia(body);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.fetchMedia();
        store.dispatch(setLoader(false));
    }




    deleteMediaContent = async (job_media_content_id) => {
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.inactiveJobMediaContent({job_media_content_id});
            this.fetchMedia();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    addVideoThumbnail = async (mediaContent, file) => {

        let mediaList = this.state.mediaList;
        try{
            let fileUrl = file ? URL.createObjectURL(file) : null;
            var body = {job_media_content_id: mediaContent.job_media_content_id};
            if(file) {
                var fileResp = await uploadFile(file, "JOB_DATA");
                body.thumbnail_file_key =  fileResp.file_id;
            } else body.thumbnail_file_key = 0;
            await ApiEndpoints.Jobs.editJobMediaContent(body);
            mediaList = mediaList.map(media => {
                media.JobMediaContents = media.JobMediaContents.map(content => {
                    if(content.job_media_content_id === mediaContent.job_media_content_id) content.thumbnail_file_url = fileUrl;
                    return content;
                });
                return media;
            });
            this.setState({mediaList});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let {  mediaList, isAddJDMedia } = this.state;
        let { language, isOnlyView } = this.props;
        let jdVideo = mediaList[0] && mediaList[0].JobMediaContents.filter(o => o.language.toLowerCase() === language.toLowerCase())[0];
        return(
          <>
                    
            {
            !jdVideo ?
            ( isOnlyView ? 
                <p className="m-5"><em>No Video</em></p>
                :
                <Card>
                    <CardBody>
                    <form onSubmit={(e) => this.submitJDVideo(e)}>
                        {this.state.addJDMediaProgress !== false && <Progress animated color="info" value={this.state.addJDMediaProgress}>{this.state.addJDMediaProgress}%</Progress>}
                        <Row className={"justify-content-center"}>
                            <Col xs={8}>
                                <VideoUploadFeild id="video_upload_input" required className="mt-2" label={"Add Video"} onChange={(file) => {isAddJDMedia.file = file; this.setState({isAddJDMedia})}}/>
                            </Col>
                        </Row>
                        <Row className="justify-content-end">
                            <Col xs={3}>
                                <Button id="video_upload_submit" disabled={this.state.addJDMediaProgress} className="buttonred">Save</Button>{' '}
                            </Col>
                        </Row>
                    </form>
                    </CardBody>
                </Card>
                )
            :
            <Row className="justify-content-center align-items-center">
                <Col xs={6}  className="my-1">
                    <video style={{width: "100%", borderRadius: 18}} preload="none" className="d-block mb-1" alt="Job Media" src={jdVideo.url} controls />
                    {!isOnlyView && <div><Button id={"delete_media"} onClick={() => this.setState({isOpenConfirm : () => this.deleteMediaContent(jdVideo.job_media_content_id)}) } color="link" className="text-dark float-right p-0" ><Icon style={{fontSize : 18}}>delete</Icon></Button></div>}
                </Col>
                <Col xs={6}>

                    {
                        jdVideo.thumbnail_file_url ? 
                        <div className="visible-on-hover-container">
                            <img style={{width: "100%", borderRadius: 18}} src={jdVideo.thumbnail_file_url} alt="video_thumbnail" />
                            <div>
                                <Icon onClick={()=> this.addVideoThumbnail(jdVideo, null)} className="mt-1 float-right" style={{cursor: "pointer", fontSize: 18}}>delete</Icon>
                            </div>
                        </div>
                        :
                        <ImageUploadFeild required className="my-2 text-center" label="Add Thumbnail" onChange={(file) => this.addVideoThumbnail(jdVideo, file)}/>
                    }

                </Col>
            </Row>
            }
            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
    
        </>
      );
  }
}
export default JDVideo;




