import { ACTIONS } from "../constants/actions";


export const setSuccess = (success) => dispatch => {
  dispatch({
    type: ACTIONS.SET_SUCCESS,
    payload: success
  })
}

export const setError = (error) => dispatch => {
  dispatch({
    type: ACTIONS.SET_ERROR,
    payload: error
  })
}
export const setUser = (user) => dispatch => {
  dispatch({
    type: ACTIONS.SET_USER,
    payload: user
  })
}

export const setJobs = (jobList) => dispatch => {
  dispatch({
    type: ACTIONS.SET_JOBS,
    payload: jobList
  })
}

export const setLoader = (show) => dispatch => {
  dispatch({
    type: ACTIONS.SET_LOADER,
    payload: show
  })
}
