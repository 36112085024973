
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Chip, Icon, TablePagination} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";
import UserAccess from "./user_access";



class ManageAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobList : store.getState().jobList,
            searchUserName: "",
            isShowAccessData: false,
            userData: [],
            isOpenConfirm: false,
            pageNumber: 0
        }
    }

    componentDidMount(){
        this.getAccess();
    }

    getAccess = async () => {
        store.dispatch(setLoader(true));
        try{
            let apiData = await ApiEndpoints.User.fetchAllRoleAccess();
            var resp = apiData.data.data;
            await this.setState({userData:resp })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deleteUser = async (user) => {
        store.dispatch(setLoader(true));
        try{
            let body = { user_role_id: user.UserRole.map(o => o.user_role_id)}
            await ApiEndpoints.User.deleteRole(body);
            await this.getAccess();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let jobList = store.getState().jobList;
        let {isShowAccessData, userData, searchUserName, pageNumber} = this.state;
        if(searchUserName)  userData = userData.filter( obj => searchUserName ? ((obj.first_name || " ").toLowerCase().includes(searchUserName.toLowerCase())) : true);
       
        return (
        <>
            <Row className="border-bottom py-2 mb-3 align-items-center">
                <Col xs={12} lg={8}>
                    <h5>Manage Access</h5>
                </Col>
                <Col xs={12} lg={4} >
                    <InputFeild onChange={(e) => this.setState({searchUserName: e.target.value, currentAttendeePage: 0})} icon="search" placeholder="Search by Name" />
                </Col>
            </Row>
           
                {userData.length ? 
                <>
                    {userData.slice(pageNumber*20, pageNumber*20+20).map((user,index) => {
                           
                            return <Row key={index} className="border shadow-sm mx-3 py-3 my-3 align-items-center" style={{borderRadius: 12}}>
                                <Col xs={3} >
                                    <strong className="d-block">{user.first_name}</strong>
                                    <span className="small">{user.mobile}</span>
                                </Col>
                                <Col md={4} xs={12} className="text-center my-1">
                                    <span>{user.UserRole.map(o => o.role_name).join(", ")} </span>
                                </Col>
                                <Col md={5} xs={12} className="text-right">
                                    <Button className="align-middle buttonred " onClick={() => this.setState({isShowAccessData: user}) } >Accessible Details</Button>
                                    <Icon className="align-middle mx-5" style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isOpenConfirm :  () => this.deleteUser(user) })}}>delete</Icon>
                                </Col>
                            </Row>
                        })
                    }
                    <TablePagination rowsPerPageOptions={[20]} rowsPerPage={20} component="div" count={userData.length} page={pageNumber}
                        onChangePage={ (e, page) => this.setState({pageNumber : page}) }
                    />
                </>
                :
                <em className="d-block text-center text-secondary mt-3">No access data found</em>
                }
                

            <Modal isOpen={!!isShowAccessData}>
                <ModalHeader toggle={() => {this.setState({isShowAccessData : false})}}>User Details</ModalHeader>
                <ModalBody>
                   
                    { isShowAccessData && <UserAccess user={isShowAccessData}  close={() => this.setState({isShowAccessData: false})}  />}           
                </ModalBody>
            </Modal>
            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />
        </>
        );
    }
}
export default ManageAccess;
