


import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css"
import {Chip, Icon, Tab, Tabs} from '@material-ui/core';
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import ImageUploadFeild from "../../../global/form/upload-image";
import { uploadFile } from "../../../../actions";


class JobSharingMaterial extends Component {
    constructor(props) {
      super(props);
      this.state = {
            data: [],
            isAddNew: false
        }
    }

    async componentDidMount(){
        await this.init() ;
    }


    componentDidUpdate(prevProps){
        if(prevProps.language !== this.props.language) {
            this.init() ;
        }
    }

    init = async () =>{
        store.dispatch(setLoader(true))
        try{
            let body = {
                job_id: this.props.jobId,
                material_type: this.props.type,
                language: this.props.language,
                file_type: "IMAGE"
            }
            let apiData = await ApiEndpoints.Jobs.fetchJobMaterial(body);
            this.setState({data: apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addMaterial = async (file) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await uploadFile(file, "JOB_DATA");
            var fileId =  fileResp.file_id;
            var body = {
                job_id: this.props.jobId,
                material_type: this.props.type,
                file_type: "IMAGE",
                language: this.props.language,
                file_id: fileId
            }
            await ApiEndpoints.Jobs.addJobMaterial(body);
            this.setState({ isAddNew: false })
            await this.init();
            store.dispatch(setSuccess({show: true, message: "Content saved successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    deleteJobMaterial = async (data) => {
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.inactiveJobMaterial({job_material_id: data.job_material_id});
            await this.init()
            store.dispatch(setSuccess({show: true, message: "Content deleted successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    

    render(){
        let {data, isAddNew} = this.state;
        let { isOnlyView } = this.props;
        return(
        <>  
            <Row className={"align-items-center m-3"}>
                {
                    data.map(obj => 
                        <Col xs={6} lg={4} className="visible-on-hover-container">
                                                           
                            <img style={{width: "100%"}} src={obj.file_url} alt="project banner" />
                            {!isOnlyView && <div className="visible-on-hover-item" style={{position: "absolute", right: 0, top: 0}}>
                                <Icon onClick={()=> this.deleteJobMaterial(obj)} className="mt-1" style={{cursor: "pointer", fontSize: 16}}>delete</Icon>
                            </div>}
                        </Col>
                    )
                }
                {!!isAddNew &&
                    <Col xs={6} lg={4}>
                            <ImageUploadFeild required className="my-2 text-center" label="Add Image" onChange={(file) => this.addMaterial(file)}/>
                    </Col>
                }
                <Col xs={6} lg={3}>
                    {!isAddNew ? 
                    (!isOnlyView && <span className="d-inline-block bg-light border border-secondary" style={{cursor: "pointer"}} onClick={() => this.setState({isAddNew: {}})}><Icon className="align-middle m-4">add</Icon></span>)
                    :
                    <span className="d-inline-block bg-light border border-secondary" style={{cursor: "pointer"}} onClick={() => this.setState({isAddNew: false})}><Icon className="align-middle m-4">close</Icon></span>
                    }
                </Col>
            </Row>
        </>
        );
    }
}

export default JobSharingMaterial;
