import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Label, CardBody, Card } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon } from "@material-ui/core";
import { addUser, uploadFile, downloadApiFile } from "../../../actions";
import { Link } from "react-router-dom";
import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import moment from "moment";
import MaterialInputAdd from "./material_input_add";

class MaterialInputView extends Component {
    constructor(props) {
        super(props);
        this.state = {
           materialList : [],
           addMaterialInput : {input_type : "JOB"},
           materialInputList : [],
           materialInputCount : [],
           isEdit : {},
           isOpenUploadInMarket: false,
           selectedJobLocationId : this.props.selectedJob && this.props.selectedJob.JobLocations[0] ? this.props.selectedJob.JobLocations[0].job_location_id : null
        }
    }

    componentDidMount(){
        this.fetchMaterial();
        this.fetchMaterialInput();
    }

    onChangeAdd = (key, value) => {
        let {addMaterialInput} = this.state;
        addMaterialInput[key] = value;
        this.setState({addMaterialInput});
    }

    fetchMaterial = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           job_id : this.props.selectedJob.job_id ,
           job_location_id : this.state.selectedJobLocationId
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialItem(body );
          this.setState({materialList : resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    fetchMaterialInput = async () => {
        store.dispatch(setLoader(true))
        let { addMaterialInput, materialList } = this.state;
        try{
          var body = {
           job_id : this.props.selectedJob.job_id ,
           store_id: this.props.store_id,
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialInputTransactions(body );
          this.setState({materialInputList : resp.data.data});
          let countResp = await ApiEndpoints.StoreManager.fetchMaterialInputCount(body);
          this.setState({materialInputCount : countResp.data.data});
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    edit = async (e) => {
        e.preventDefault()
        store.dispatch(setLoader(true))
        let { addMaterialInput, materialList, isEdit } = this.state;
        try{
          var body = {
           store_input_id : isEdit.store_input_id,
           value : e.target.value.value
          }
          let resp = await ApiEndpoints.StoreManager.editMaterialInputTransactions(body);
          this.setState({isEdit : {}})
          this.fetchMaterialInput()
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    setJobLocationId = async (e) => {
        await this.setState({selectedJobLocationId : e.target.value})
        this.fetchMaterial();
        this.fetchMaterialInput();
    }
    uploadInMarket = async (e) =>{
        e.preventDefault();
        e.persist();
        let { isOpenUploadInMarket } = this.state;
        isOpenUploadInMarket.loading = true;
        this.setState({isOpenUploadInMarket});
        var file = e.target.csvFile.files[0];
        store.dispatch(setLoader(true))
        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                job_id : this.props.selectedJob.job_id,
                store_id: this.props.store_id
            }
            var resp = await ApiEndpoints.StoreManager.uploadMaterial(body)
            e.target.reset();
            this.setState({isOpenUploadInMarket : false});
            this.fetchMaterialInput();
            if(!resp.data.data){
                downloadApiFile(resp);
                store.dispatch(setError({show: true, message: "There was some error to upload data, please check downloaded CSV"}))
            }
            else{
                store.dispatch(setSuccess({show: true, message: "Uploaded Successfully!"}))
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    downloadSampleUploadMaterial = async (e) => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.props.selectedJob.job_id,
                store_id: this.props.store_id
            }
            var resp = await ApiEndpoints.StoreManager.downloadSampleUploadMaterial(body);
            downloadApiFile(resp, "Upload Material Sample.csv");
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render() {
        let { selectedJob, applicantList } = this.props;
        let locationList = selectedJob ? selectedJob.JobLocations : [];
        let { addMaterialInput, materialList, materialInputList, isEdit, materialInputCount, isOpenUploadInMarket } = this.state;
        let materialById = materialList.reduce((prev, curr) => {
            prev[curr.material_description_id] = curr;
            return prev;
        }, {});
        return (
            <Aux>

                <Modal size="lg" isOpen={true} toggle={()=> this.props.toggle()} className="modal-dialog-centered">
                    <ModalHeader toggle={()=> this.props.toggle()}>Store Input List</ModalHeader>
                        <ModalBody className="p-3">
                            {/* <Row className="mb-3">
                                <Col xs={6}>
                                <SelectList placeholder="Select Location" value={this.state.selectedJobLocationId} onChange ={this.setJobLocationId} >
                                { 
                                locationList.map(loc => 
                                <option key={loc.job_location_id} value={loc.job_location_id}> {loc.city} </option>
                                )
                                }
                                </SelectList>
                                </Col>
                            </Row> */}
                        <Row className="font-weight-bold">
                            <Col xs={4} md={3}><h6  className="font-weight-bold">Name</h6></Col>
                            <Col xs={4} md={3}><h6  className="font-weight-bold">Left In Market <Icon onClick={()=> this.setState({isOpenUploadInMarket: isOpenUploadInMarket ? false: {}})} style={{cursor:"pointer"}} fontSize="small" className="p-1 cursor-pointer">publish</Icon></h6></Col>
                            <Col xs={4} md={3}><h6  className="font-weight-bold">Left In Store</h6></Col>
                        </Row>
                        <hr />
                        <Collapse isOpen={isOpenUploadInMarket}>
                            <Card>
                                <CardBody>
                                    <form onSubmit={this.uploadInMarket}>
                                        <input required type="file" name="csvFile" className="mt-3" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                        <p style={{fontSize: 12}}><em>Download Sample File, Fill the detils and upload here</em></p>
                                        <Button type="button" color="link" onClick={this.downloadSampleUploadMaterial}>Download Sample File</Button>
                                        <div className="float-right">
                                            <Button disabled={!!isOpenUploadInMarket.loading} size="sm" type="submit" color="primary" >Upload</Button>{' '}
                                            <Button size="sm" type="button" color="secondary" onClick={()=> {this.setState({isOpenUploadInMarket : false})}}>Cancel</Button>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Collapse>
                        {materialInputCount.map(obj => 
                        <Row>
                            <Col xs={4} md={3}>{materialById[obj.material_description_id] && materialById[obj.material_description_id].item_name}</Col>
                            <Col xs={4} md={3}>{obj.in_market}</Col>
                            <Col xs={4} md={3}>{obj.left_in_store}</Col>
                        </Row>
                        )}
                        <div className="w-100" style={{overflowX: "scroll"}}>
                            <div style={{minWidth: 450}}>

                          
                        <Row noGutters className="mt-4 font-weight-bold">
                                <Col xs={1} >#</Col>
                                <Col xs={2}>Name</Col>
                                <Col xs={2}>count</Col>
                                <Col xs={3}>input by</Col>
                                <Col xs={3}>date</Col>
                                <Col xs={1}></Col>
                            </Row>
                        {materialInputList.map((obj, i) =>
                        {
                        return <form onSubmit={this.edit}>
                            <Row noGutters key={i}>
                                <Col xs={1} >{i+1}</Col>
                                <Col xs={2}>{materialById[obj.material_description_id] && materialById[obj.material_description_id].item_name}</Col>
                                <Col xs={2}>
                                {
                                obj.store_input_id == isEdit.store_input_id ?
                                <InputFeild defaultValue={obj.value} name="value"  />
                                :
                                 obj.value
                                 }
                                </Col>
                                <Col xs={3}>{obj.input_type}</Col>
                                <Col xs={3}>{obj.created_on}</Col>
                                <Col xs={1}>
                                    {obj.store_input_id == isEdit.store_input_id ? 
                                        <Aux>
                                            <Button  color="link"><Icon className="cursor-pointer" style={{cursor: "pointer" , fontSize : 15}}>done</Icon></Button>
                                            <Icon className="cursor-pointer" onClick={() => this.setState({isEdit : {}})} style={{cursor: "pointer" , fontSize : 15}}>close</Icon>
                                        </Aux>:
                                        <Icon className="cursor-pointer" onClick={() => this.setState({isEdit : obj})} style={{cursor: "pointer" , fontSize : 15}}>edit</Icon>
                                    }
                                </Col>
                            </Row>
                            </form>
    })}
  </div>
                        </div>
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button className="buttonred" onClick={() => this.submitMaterialInput()} style={{ fontSize: "12px",height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                            <Button type="button" color="secondary" onClick={()=> this.props.toggle()} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button> */}
                        </ModalFooter>
                </Modal>
            </Aux>
            
            
        );
    }
}
export default MaterialInputView;
