import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser, groupArrayOnKey } from "../../../actions";
import ConfirmAction from "../../global/confirm-action";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";

class ManageManager extends Component {
    constructor(props) {
      super(props);
      this.state = {
            managers : [],
            isAddNewManager : false,
            isOpenAddManager : false,
            selectedManager : {},
            selectedJobLocations : [],
            user : {},
            editManager : {},
            editJobLocationId : [],
            isOpenConfirm : false,
            isOpenUsers : false,
            assignedApplicants : [],
            selectedUsers : [],
            mappedUsers : []
        }
    }
    componentDidMount(){
        this.fetchManagers();
    }
    
    fetchManagers = async () => {
        store.dispatch(setLoader(true))
        let { job } = this.props;
        try{
          var accessOpts = {
            access_type : "EMPLOYER",
            access_type_id : job.employer_id,
            role_id: 10
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let managers = accessResp.data.data;
          var locationAccess = {};
          if(job.JobLocations.length){
              let locationAccessResp = await ApiEndpoints.User.fetchMappedAccess({
                access_type : "JOB_LOCATION",
                access_type_id : job.JobLocations.map(loc => loc.job_location_id).join(","),
                role_id: 10
              });
              locationAccess = locationAccessResp.data.data;
              var locations = groupArrayOnKey(job.JobLocations, "job_location_id", true)
              locationAccess = locationAccess.map(access => {
                 access.location = locations[access.access_type_id] || null;
                 return access;
              })
              locationAccess = groupArrayOnKey(locationAccess, "user_id")
          }
          managers = managers.map(manager => {
              manager.JobLocations = (locationAccess[manager.user_id] || []).filter(access => !!access.location).map(access => {return {...access.location, ...access}});
              return manager;
          })
          this.setState({managers})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }
    onChangeManager = (e) =>{
        let {managers} = this.state;
        if (e.target.value !== "ADD_NEW") 
        this.setState({selectedManager : {...managers[e.target.value], index : e.target.value} });
        else this.setState({isOpenAddManager : true, user : {}})
    }

    

    addManagerToLocation = async () =>{
        let { selectedJobLocations, selectedManager } = this.state;
        store.dispatch(setLoader(true))
        try{
            await Promise.all(selectedJobLocations.map(job_location_id => {
              var userBody = {
                user_id : selectedManager.user_id,
                user_role_id : selectedManager.user_role_id,
                access_type : "JOB_LOCATION",
                access_type_id : job_location_id
              }
              return addUser(userBody)
            }));
          this.setState({isAddNewManager : false, selectedJobLocations : [], selectedManager : {}})
          await this.fetchManagers()
          store.dispatch(setSuccess({show: true, message: " manager have been associated with Locations"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addManager = async (e) =>{
        e.preventDefault();
        let { user} = this.state;
        store.dispatch(setLoader(true))
          try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var body = {...user, role_id : 10, access_type : "EMPLOYER", access_type_id : this.props.job.employer_id}
            await addUser(body);
            this.setState({isOpenAddManager : false , user : {}});
            await this.fetchManagers()
            store.dispatch(setSuccess({show: true, message: " Manager Added!"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    editManager = async () => {
        let {editJobLocationId, editManager} = this.state;
        store.dispatch(setLoader(true))
        try{
            let mappedJobLocationIds = editManager.JobLocations.map(o => o.job_location_id);
            let deletedAccessId = editManager.JobLocations.filter(a => editJobLocationId.indexOf(a.job_location_id) === -1).map(a => a.access_id);
            let newJobLocationIds = editJobLocationId.filter(v => mappedJobLocationIds.indexOf(v) === -1);
            deletedAccessId.length && await ApiEndpoints.User.inactiveAccess({access_id : deletedAccessId});
            await Promise.all(newJobLocationIds.map(job_location_id => {
                var userBody = {
                  user_id : editManager.user_id,
                  user_role_id : editManager.user_role_id,
                  access_type : "JOB_LOCATION",
                  access_type_id : job_location_id
                }
                return addUser(userBody)
            }));
            this.setState({editManager : {}})
            await this.fetchManagers()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    deletedManager = async (access_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                access_id
              }
            await ApiEndpoints.User.inactiveAccess(body);
            await this.fetchManagers()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    fetchAssignedUsers = async (manager) => {
        store.dispatch(setLoader(true))
        this.setState({assignUsers : [], selectedUsers : [], mappedUsers : []});
        try{
            var body = {
                job_id : this.props.job.job_id,
                job_location_id : manager.JobLocations.map(loc => loc.job_location_id)
            }
            let applicantsResp = await ApiEndpoints.Applicants.fetch(body);
            // let assignedApplicants = Object.keys(applicantsResp.data.data).reduce((array, status) => {array = array.concat(applicantsResp.data.data[status]); return array;}, []);
            let assignedApplicants = applicantsResp.data.data;
            let assignedResp = await ApiEndpoints.User.getAssignedUser({manager_id: manager.user_id, ...body});
            let selectedUsers = assignedResp.data.data.map(obj => obj.user_id);
            this.setState({assignedApplicants, selectedUsers, mappedUsers : selectedUsers});
            await this.fetchManagers();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    assignUsers = async () => {
        store.dispatch(setLoader(true))
        let { isOpenUsers, selectedUsers, mappedUsers } = this.state;
        try{
            var body = {
                manager_id : isOpenUsers.user_id,
            }
            let assignUsers = selectedUsers.filter(selected => mappedUsers.indexOf(selected) === -1);
            body.user_id = assignUsers;
            if(assignUsers.length) await ApiEndpoints.User.assignUsers(body);
            let removeUsers = mappedUsers.filter(mapped => selectedUsers.indexOf(mapped) === -1);
            body.user_id = removeUsers;
            if(removeUsers.length) await ApiEndpoints.User.inactiveAssignedUser(body);

            this.setState({isOpenUsers : false})
            store.dispatch(setSuccess({show: true, message: "Users assigned successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render(){
        let {managers, isAddNewManager, selectedManager, selectedJobLocations, isOpenAddManager, editManager, editJobLocationId, user, isOpenUsers, assignedApplicants, selectedUsers} = this.state;
        let {job} = this.props;
        
        return(
               
            <div>
                <Row>
                    <Col>
                    <Button className="buttonred my-3" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isAddNewManager : true})}>Add Manager</Button>{' '}
                    </Col>
                </Row>
                <Row>
                    <Col xs={1} ><h6>S.No</h6> </Col>
                    <Col xs={4}> <h6>Manager</h6> </Col>
                    <Col xs={4}> <h6>City Name</h6> </Col>
                </Row>
                {isAddNewManager && 
                <Row>
                    <Col xs={1} ></Col>
                    <Col xs={6} style={{ marginBottom: "10px"}}>
                    <SelectList addNew={true} placeholder="Select Manager" value={selectedManager.index} required onChange={(e) => this.onChangeManager(e)} >
                        {
                            managers.map((manager, i) => 
                                    <option value={i}>{manager.User.first_name} | {manager.User.mobile}</option>
                            )
                        }
                        </SelectList>
                    </Col>
                    <Col xs={3}  style={{ marginBottom: "10px"}}>
                        <SelectList multiple="checkbox" placeholder="Select City" value={selectedJobLocations} required onChange={(e) => this.setState({selectedJobLocations : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                    </Col>
                    <Col xs={2}> 
                        <div style={{marginTop: "7px"}}>
                            <Icon onClick={this.addManagerToLocation} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                            <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isAddNewManager : false})}>close</Icon>
                        </div>
                    </Col>
                </Row>}
                <Collapse isOpen={isOpenAddManager}>
                    <Card>
                    <CardBody>
                        <form onSubmit={this.addManager}>
                            <Row style={{marginTop: "-20px"}}>
                                <Col xs={4}>
                                    <InputFeild required name="first_name" onChange={this.onChangeUser} value={user.first_name || ""}  className="mt-3" placeholder="Name" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild required name="mobile" type="number" onChange={this.onChangeUser} value={user.mobile || ""} className="mt-3" placeholder="Phone Number" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild name="email" onChange={this.onChangeUser} value={user.email || ""} className="mt-3" placeholder="Email Address" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "20px", float: "right"}}>
                                <Col style={{marginRight : "-22px"}}>
                                    <Button type="submit" className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </Col>
                                <Col>
                                    <Button onClick={() => {this.setState({isOpenAddManager: false})}}  type="button" color="secondary" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    </Card>
                </Collapse>
                {managers.map((manager, i) => 
                    editManager.user_id !== manager.user_id ?
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={4}>{manager.User.first_name+" | "+manager.User.mobile}</Col>
                        <Col xs={4}>
                                <span className="float-left">{manager.JobLocations.map(loc => loc.city).join(", ") }</span>
                        </Col>
                        <Col xs={1}>
                            {!!manager.JobLocations.length && <Button className="mb-2" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}} size="sm" color="secondary" onClick={() => {this.setState({isOpenUsers : manager}); this.fetchAssignedUsers(manager)}}>Users</Button>}
                        </Col>
                        <Col xs={2}>
                            <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedManager([manager.access_id, ...(manager.JobLocations.map(loc => loc.access_id))])}) } style={{cursor: "pointer"}} fontSize="small" className="float-right ml-3">delete</Icon>

                            <Icon onClick={() => this.setState({editManager : manager, editJobLocationId : manager.JobLocations.map(obj => obj.job_location_id)})} style={{cursor: "pointer"}} fontSize="small" className="float-right">edit</Icon>
                        </Col>
                        
                    </Row> : 
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={6}>{manager.User.first_name+" | "+manager.User.mobile}</Col>
                        <Col xs={4}>
                            <SelectList multiple="checkbox" placeholder="Select City" value={editJobLocationId} required onChange={(e) => this.setState({editJobLocationId : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option key={i} value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                        </Col>
                        
                        <Col>
                            <div style={{marginTop: "7px"}}>
                                <Icon onClick={this.editManager} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({editManager : {}})}>close</Icon>
                            </div>
                        </Col>
                    </Row>
                )}
                <Dialog open={!!isOpenUsers} onClose={() => this.setState({isOpenUsers : false})}>
                    <DialogTitle>Assign Partners</DialogTitle>
                    <DialogContent>
                        <div  style={{width: 400, padding: 20}}>
                            <SelectList multiple="checkbox" search={true} placeholder="Select Users" value={selectedUsers} onChange={(e) => {console.log(e.target.value);this.setState({selectedUsers : e.target.value})}} >
                                {
                                    assignedApplicants.map(applicant => 
                                        <option key={applicant.user_id} value={applicant.user_id}>
                                            <span style={{display : "block"}}>{applicant.User.first_name}</span>
                                            <span className="" style={{fontSize: 11}}>PMW{applicant.User && applicant.User.id} </span>
                                        </option>
                                    )
                                }
                            </SelectList>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="sm" onClick={() => this.setState({isOpenUsers : false})} color="secondary">
                            Close
                        </Button>
                        <Button size="sm" onClick={this.assignUsers} color="secondary">
                            Save
                        </Button>
                        </DialogActions>
                </Dialog>

                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />

            </div>

           );
       }
    }
export default ManageManager;
