
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Input } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css";
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import SelectList from "../../../global/form/select";
import InputFeild from "../../../global/form/input";
import { Switch } from "@material-ui/core";
import ImageUploadFeild from "../../../global/form/upload-image";

let moment = require("moment");

class JobTaskInputs extends Component {
    constructor(props) {
      super(props);
      this.state = {
            isEditMode: false,
            taskInput: {...this.props.taskInput}
        }
       }

    async componentDidMount(){
        console.log(this.props.taskInput);
    }

    onChangeInput = (k, v) => {
        let { taskInput } = this.state;
        taskInput[k] = v;
        this.setState({ taskInput });
    }

    saveInput = async () => {
        let { taskInput } = this.state;
        let { taskId, onChangeInput, onClose } = this.props;
        store.dispatch(setLoader(true))
        try{
            if(taskInput.sampleImageFile) {
                let fileName = "task_sample/"+taskId + "/sample_image_" + taskInput.input_id + "." + taskInput.sampleImageFile.name.split('.').pop();
                let fileResp = await ApiEndpoints.Files.filePublicPutUrl({file_name: fileName});
                let file_url = fileResp.data.data.url;
                if (!file_url) throw new Error("Unable to upload file. Please try again later.");
                var config = { headers: { "Content-Type": taskInput.sampleImageFile.type }}
                await ApiEndpoints.Files.uploadFileToS3(file_url, taskInput.sampleImageFile, config);
                taskInput.sampleImageUrl = fileResp.data.data.fileUrl;
                delete taskInput.sampleImageFile;
            }
            if(taskInput.input_enum) taskInput.input_enum = taskInput.input_enum.map(o => o.trim()).filter(o => !!o);

            onChangeInput(taskInput);
            onClose();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
       
    }

    render() {
        let { taskInput } = this.state;
        return(
        <div className="border m-3 p-3">
            
            <Row>
                <Col>
                    <InputFeild value={taskInput.label} placeholder="Enter Input Label" onChange={(e) => this.onChangeInput("label", e.target.value)}  />
                </Col>
                <Col>
                    <SelectList placeholder="Select Type" value={taskInput.input_type} onChange={(e) => this.onChangeInput("input_type", e.target.value)} >
                        <option value="TEXT">Text</option>
                        <option value="IMAGE">Image</option>
                        <option value="FILE">File</option>
                        <option value="DROPDOWN">Dropdown</option>
                    </SelectList>
                </Col>
                <Col>
                    <SelectList placeholder="Input By?" value={taskInput.input_by} onChange={(e) => this.onChangeInput("input_by", e.target.value)} >
                        <option value="USER">Partner</option>
                        <option value="CUSTOMER">Customer</option>
                    </SelectList>
                </Col>
            </Row>
            {
            !!["IMAGE"].includes(taskInput.input_type) && <>
                
                <Row className={"mt-2 align-items-center"}>
                    <Col>
                        <ImageUploadFeild id="upload_sample_img" src={taskInput.sampleImageUrl} required className="mt-2" label="Upload Sample Image" onChange={(file) => this.onChangeInput("sampleImageFile", file)}/>
                    </Col>
                    <Col>
                        <label>Is Allow upload from File Manager</label> <Switch size="small" checked={taskInput.isAllowFileManager} onChange={(e) => this.onChangeInput("isAllowFileManager", e.target.checked)} />
                    </Col>
                </Row>
            </>
            }

            {
                taskInput.input_type === "DROPDOWN" && <textarea rows="5" placeholder="Enter dropdown values in multiple line" className="form-control mt-2" value={(taskInput.input_enum || []).join("\n")} onChange={(e) => this.onChangeInput("input_enum", e.target.value.split("\n") ) } />
            }

            <div className="text-right pt-3 pr-3">
                <Icon className="" style={{cursor: "pointer"}} onClick={() => this.saveInput()}>done</Icon>
            </div>       
        </div>
              
    );
  }
 }
export default JobTaskInputs;
