import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Icon, Chip, TablePagination, MenuItem, Menu, ButtonGroup, Button } from "@material-ui/core";
import CONSTANTS from "../../../constants";
import SelectList from "../../global/form/select";
import ConfirmAction from "../../global/confirm-action";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import { groupArrayOnKey } from "../../../actions";
import PartnerMapDetail from "./partner_map_detail";
import AuditTask from "./audit_task";
import RecruitmentComponent from "../recruitment/recruitment";
import ActivePartners from "./active_partners";
import PartnerDetail from "../user_profile";

let moment = require("moment");


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedJobId: null,
            selectedJobLocationId : null,
            jobList : [],
            taskList : [],
            selectedJob : {},
            selectedInTaskType : "",
            selectAllUsersType : "",
            isOpenStatusModal : false,
            handshakeRowsPerPage : 20,
            handshakeCurrentPage : 0,
            allUserCurrPage: 0,
            inTaskRowsPerPage : 20,
            allUserRowsPerPage: 20,
            inTaskCurrPage : 0,
            isConfirm : false,
            isConfirmMessage : null,
            callDetails : null,
            sortInTask : {},
            sortHandshake: {},
            inTaskUsers : [],
            inTaskSearchText : "",
            allUserSearchText : "",
            isOpenMapModel: false,
            isOpenSortInTaskMenu0: false,
            isOpenSortInTaskMenu1: false,
            isOpenSortInTaskMenu2: false,
            isOpenSortTodayMenu1: false,
            isOpenSortTodayMenu2: false,
            isOpenSortHandshakeMenu1: false,
            teamLeaders: [],
            selectedTlId: null,
            activeUserTab: "ACTIVE_TODAY",
            allUsers: [],
            pendingHandshakeData: [],
            isOpenUserModel: null
        }
    }

    async componentDidMount(){
      await this.getApiFn();
      this.getJob();
    }

    getApiFn = async () => {
      store.dispatch(setLoader(true))
      try{
          let apiData = await ApiEndpoints.Static.getFunctions();
          this.getCycleDates = eval(apiData.data.data.getCycleDates);
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }
    getJob = async () => {
      store.dispatch(setLoader(true))
      try{
          let apiData = await ApiEndpoints.Jobs.fetch();
          var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
          jobList = jobList
            .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
            .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
          await this.setState({ jobList });
          if(this.props.match.params.job_id){
            if(this.state.jobList.filter(job => job.job_id == this.props.match.params.job_id).length){
              this.setJobId(this.props.match.params.job_id)
            }
          }
          else if(this.state.jobList.length)  this.setJobId(this.state.jobList[0].job_id)
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    setJobId  = async (job_id) => {
      let { jobList } = this.state;
      store.dispatch(setLoader(true))
      await this.changeUserTab(null);
      var selectedJob = jobList.filter(obj => obj.job_id == job_id)[0];
      if(selectedJob) selectedJob.JobLocations = selectedJob.JobLocations.sort((a, b) =>  a.city > b.city ? 1 : -1)

      await this.setState({selectedJob,selectedJobId : job_id, selectedJobLocationId : null, selectedTask : null, teamLeaders: [], selectedTlId: null})
            
      await this.fetchJobTask();

      if(selectedJob.JobLocations.length === 1) this.setJobLocationId(selectedJob.JobLocations[0].job_location_id)
      else this.changeUserTab("ACTIVE_TODAY");

      this.fetchAuditTaskCount();
      store.dispatch(setLoader(false))
    }

    fetchAuditTaskCount = async () => {
      store.dispatch(setLoader(true))
      this.setState({ auditTaskCount: 0 });
      let { taskList } = this.state;
      var auditTaskCount = 0;
      await Promise.all(taskList.map(async task => {
        var auditVideoInputId = task.formData.input.sections[0].input_groups[0].inputs.filter(input => input.input_type === "VIDEO")[0];
        if(!auditVideoInputId) return;
        auditVideoInputId = auditVideoInputId.input_id;
        var resp = await ApiEndpoints.JobTask.fetchTaskCountByInputFilter({form_id: task.form_id, status: 0, input_id: auditVideoInputId, audit_status: '0'});
        auditTaskCount += resp.data.data;
      }));
      this.setState({auditTaskCount});
      store.dispatch(setLoader(false))
    }

    setJobLocationId = async (job_location_id, selectedTlId) => {
      await this.changeUserTab(null);
      await this.setState({selectedJobLocationId : job_location_id, teamLeaders: [], selectedTlId: selectedTlId && selectedTlId !== this.state.selectedTlId ? selectedTlId : null, activeUserTab: "ACTIVE_TODAY"});
      this.changeUserTab("ACTIVE_TODAY");
      this.getTeamLeaders(job_location_id);
    };

    getTeamLeaders = async (job_location_id) => {
      store.dispatch(setLoader(true))
      try{
        let locationAccessResp = await ApiEndpoints.User.fetchMappedAccess({
          access_type : "JOB_LOCATION",
          access_type_id : job_location_id,
          role_id: 8
        });
        this.setState({teamLeaders: locationAccessResp.data.data})
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    getInTaskUsers = async (dateType="ALL") => {
      store.dispatch(setLoader(true))
      try{
        var body = {
          job_id : this.state.selectedJobId,
          job_location_id : this.state.selectedJobLocationId,
        }
        if(this.state.selectedTlId) body.user_role_id= this.state.selectedTlId;
        if(dateType === "CURR_MONTH"){
          body.start_date = moment().startOf("month").toDate();
        }
        let resp = await ApiEndpoints.Dashboard.fetchInTaskUsers(body);
        await this.setState({inTaskUsers : resp.data.data, selectedInTaskType: dateType})
        this.sortInTask("rejectedCount", "desc");
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    };

    fetchAllUsers = async (type = 'IN_TASK') => {
      if(type === "IN_TASK"){
        this.getInTaskUsers();
        this.setState({selectAllUsersType: "IN_TASK"})
      }
      store.dispatch(setLoader(true))
      try{
        var body = {
          job_id : this.state.selectedJobId,
          job_location_id : this.state.selectedJobLocationId,
          status: type === "ALL" ? ["APPLIED", "INTERVIEWED", "SHORTLISTED", "IN_TRAINING"].toString() : type,
          is_stale: "0"
        }
        if(this.state.selectedTlId) body.user_role_id= this.state.selectedTlId;
        let resp = await ApiEndpoints.Applicants.fetch(body);
        await this.setState({allUsers : resp.data.data, selectAllUsersType: type})
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    };

    fetchJobTask = async () => {
      store.dispatch(setLoader(true))
      let selectedJob = this.state.selectedJob
        try{
          var body = {
              job_id : selectedJob.job_id
            }
            let taskResp = await ApiEndpoints.JobTask.fetch(body);
            await this.setState({taskList : taskResp.data.data})
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    

    
    fetchPendingHandshakes = async () => {
      store.dispatch(setLoader(true))
        try{
          var body = {
            job_id : this.state.selectedJobId,
            job_location_id : this.state.selectedJobLocationId,
          }
          if(this.state.selectedTlId) body.user_role_id= this.state.selectedTlId;
          let pendingData = await ApiEndpoints.Handshake.fetchPendingHandshake(body);
          await this.setState({pendingHandshakeData : pendingData.data.data});

        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    

    toggleOpenMapModel = (user, taskStatus) => {
      this.setState({isOpenMapModel : {user, taskStatus}})
    }

   
    makePhoneCall = async (user) => {
      store.dispatch(setLoader(true))
        try{
          if(this.state.callDetails){
              store.dispatch(setError({show: true, message: "You are already on another call."}))
              store.dispatch(setLoader(false));
              return;
          }
          var body = {
              user_id : user.user_id,
              entity_type: "JOB",
              subentity_type: "APPLICATION_STATUS",
              entity_id: this.state.selectedJobId,
              subentity_id: CONSTANTS.APPLICATION_STATUS.IN_TASK,
              direction: 2
          }
          let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
          this.setState({callDetails: {calling_id : resp.data.data.Sid, user}});
        }
        catch(e){
          // this.setState({callDetails: {calling_id : "cfaf35dac0a5a231659a3188199d14am", user }});
          store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
        }
        store.dispatch(setLoader(false));
    }

   
    
    sortInTask = (type, order) => {
      let { inTaskUsers, sortInTask } = this.state;
      sortInTask= {
        [type] : order || (sortInTask[type] === "asc" ? "desc" : "asc")
      } 
      switch(type){
        case "terminatedTop":
          inTaskUsers = sortInTask[type] === "desc" ? 
          inTaskUsers.sort((a,b) => Number(b.status) - Number(a.status)) 
          : 
          inTaskUsers.sort((a,b) => Number(a.status) - Number(b.status)) 
          break;
        case "pendingHandshakeTop":
          inTaskUsers = sortInTask[type] === "desc" ? 
          inTaskUsers.sort((a,b) => Number(b.handshakeStatus) - Number(a.handshakeStatus)) 
          : 
          inTaskUsers.sort((a,b) => Number(a.handshakeStatus) - Number(b.handshakeStatus)) 
          break;
        case "daysInApp":
          inTaskUsers = sortInTask[type] === "desc" ? 
          inTaskUsers.sort((a,b) => new Date(a.modified_on) - new Date(b.modified_on))
          : 
          inTaskUsers.sort((a,b) => new Date(b.modified_on) - new Date(a.modified_on));
          break;
        case "verifiedCount":
          inTaskUsers = sortInTask[type] === "desc" ? 
          inTaskUsers.sort((a,b) =>  (b.total_tasks[1] || 0) - (a.total_tasks[1] || 0))
          : 
          inTaskUsers.sort((a,b) => (a.total_tasks[1] || 0) - (b.total_tasks[1] || 0))
          break;
        case "rejectedCount":
          inTaskUsers = sortInTask[type] === "desc" ? 
          inTaskUsers.sort((a,b) => (b.total_tasks[2] || 0) - (a.total_tasks[2] || 0))
          : 
          inTaskUsers.sort((a,b) => (a.total_tasks[2] || 0) - (b.total_tasks[2] || 0));
          break;
        default:
      }
      this.setState({inTaskUsers, sortInTask, isOpenSortInTaskMenu0: false, isOpenSortInTaskMenu1: false, isOpenSortInTaskMenu2: false})
    }


    sortHandshake = (type, order) => {
      let { pendingHandshakeData, sortHandshake } = this.state;
      sortHandshake= {
        [type] : order || (sortHandshake[type] === "asc" ? "desc" : "asc")
      } 
      switch(type){
        case "days":
          pendingHandshakeData = sortHandshake[type] === "desc" ? 
          pendingHandshakeData.sort((a,b) => moment().diff(moment(b.ApplicationTransition.created_on), 'days') - moment().diff(moment(a.ApplicationTransition.created_on), 'days') ) 
          : 
          pendingHandshakeData.sort((a,b) => moment().diff(moment(a.ApplicationTransition.created_on), 'days') - moment().diff(moment(b.ApplicationTransition.created_on), 'days') ) 
          break;
        default:
      }
      this.setState({pendingHandshakeData, sortHandshake, isOpenSortHandshakeMenu1: false})
    }

    changeUserTab = (tab) => {
      this.setState({activeUserTab: tab});
      // if(tab === "ACTIVE_TODAY") this.onChangeActiveInactive("PRESENT");
      // if(tab === "ALL") this.fetchAllUsers();
      if(tab === "PENDING_HANDSHAKE") this.fetchPendingHandshakes();
    }

    render() {
      let {jobList, isOpenStatusModal, selectedJob, selectedInTaskType, isConfirm, isOpenMapModel, teamLeaders, selectedTlId, allUsers, pendingHandshakeData} = this.state;
      let {sortHandshake, inTaskSearchText, activeUserTab, allUserSearchText, inTaskUsers, pendingHandshakeSearchText} = this.state;
      let locationList = selectedJob.JobLocations || [];
      let locationListById = groupArrayOnKey(locationList, "job_location_id", true);
      inTaskUsers = inTaskUsers.filter(data => !!inTaskSearchText ? data.User.first_name && data.User.first_name.toLowerCase().includes(inTaskSearchText.toLowerCase()) :true);
      allUsers = allUsers.filter(data => !!allUserSearchText ? data.User.first_name && data.User.first_name.toLowerCase().includes(allUserSearchText.toLowerCase()) :true);
      pendingHandshakeData = pendingHandshakeData.filter(data => !!pendingHandshakeSearchText ? data.User.first_name && data.User.first_name.toLowerCase().includes(pendingHandshakeSearchText.toLowerCase()) :true);
        return (
            <div className="p-0">
              <Row className="mt-3 pb-2">
                <Col>
                  <SelectList search={true} value={this.state.selectedJobId} onChange ={(e) => this.setJobId(e.target.value)} >
                      { 
                      jobList.map(job => 
                      <option key={job.job_id} value={job.job_id}> 
                        {job.Employer.employer_name +  "  -  "  + job.title} 
                        {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                      </option>
                      )
                      }
                  </SelectList>
                </Col>
                <Col>
                  <SelectList search={true} placeholder="Select Location" selectAll="All Locations" value={this.state.selectedJobLocationId} onChange ={(e) => this.setJobLocationId(e.target.value)} >
                  { 
                  locationList.map(loc => 
                  <option key={loc.job_location_id} value={loc.job_location_id}> 
                    {loc.city} 
                    {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                  </option>
                  )
                  }
                  </SelectList>
                </Col>
                <Col xs={12} className="mt-2">
                  {teamLeaders.map((tl, i) => 
                    <Chip onKeyDown={i} size="small" label={tl.User.first_name} onClick={() => this.setJobLocationId(this.state.selectedJobLocationId, tl.user_role_id)} clickable style={{backgroundColor: selectedTlId === tl.user_role_id ? "#999": "eee"}} className="mr-2"/>
                  )}                 
                </Col>
              </Row>

              <Row>
                <Col xs={12} md="auto" className="text-center">
                  <ButtonGroup size="small" color="secondary">
                    <Button style={{fontSize: 10, borderColor: "#F32013", backgroundColor: activeUserTab === "ACTIVE_TODAY" ? "#F32013" : "#FFF", color: activeUserTab === "ACTIVE_TODAY" ? "#FFF" : "#F32013"}} onClick={() => this.changeUserTab("ACTIVE_TODAY")}>Active Partners</Button>
                    <Button style={{fontSize: 10, borderColor: "#F32013", backgroundColor: activeUserTab === "ALL" ? "#F32013" : "#FFF", color: activeUserTab === "ALL" ? "#FFF" : "#F32013", }} onClick={() => this.changeUserTab("ALL")}>All Partners</Button>
                    <Button style={{fontSize: 10, borderColor: "#F32013", backgroundColor: activeUserTab === "PENDING_HANDSHAKE" ? "#F32013" : "#FFF", color: activeUserTab === "PENDING_HANDSHAKE" ? "#FFF" : "#F32013", }} onClick={() => this.changeUserTab("PENDING_HANDSHAKE")}>Hanshake Due</Button>
                  </ButtonGroup>
                </Col>
                <Col className="text-right">
                {
                  store.getState().user.UserRole.filter(role => [1, 3, 4].indexOf(role.role_id) !== -1).length ? 
                    <Button size="small" style={{borderColor: "#F32013", color: "#F32013", fontSize: 10}} color="danger" variant="outlined" onClick={() => {this.setState({isOpenAuditTask : true})}} >Audit Tasks {this.state.auditTaskCount}</Button>
                  : 
                  null 
                }
                </Col>
              </Row>

                <Row>
                  {activeUserTab === "ACTIVE_TODAY" &&
                  <Col md={12} xs={12} className="">
                      {this.state.selectedJobId && <ActivePartners
                        jobList={jobList} 
                        selectedJobId={this.state.selectedJobId}
                        selectedJob={selectedJob}
                        selectedJobLocations={locationList}
                        selectedJobLocationId={this.state.selectedJobLocationId}
                        selectedTlId={this.state.selectedTlId}
                        openUserDetail={(data) => this.setState({isOpenUserModel : data})}
                        openTaskDetail={this.toggleOpenMapModel}
                        taskList={this.state.taskList}
                        makePhoneCall= {(user) => { this.setState({isConfirm: () => this.makePhoneCall(user), isConfirmMessage : "Are you sure to connect with "+user.first_name+"?"}) }}
                      />}
                  </Col>
                  }

                  
                  {activeUserTab === "ALL" &&
                    <Col md={12} xs={12} className="mt-3">
                      <RecruitmentComponent
                        jobList={jobList} 
                        selectedJobId={this.state.selectedJobId}
                        selectedJob={selectedJob}
                        selectedJobLocations={locationList}
                        selectedJobLocationId={this.state.selectedJobLocationId}
                        selectedState= "LEADS"
                      />
                    </Col>
                  }
                 

                  
                 {activeUserTab === "PENDING_HANDSHAKE" &&
                  <Col md={12} xs={12} className="">
                      <Row className="border-bottom my-2 py-2 justify-content-between">
                        <Col xs={12} md={6}>
                          <h5 className="">Pending Handshake</h5>
                        </Col >
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <InputFeild onChange={(e) => this.setState({pendingHandshakeSearchText: e.target.value})} icon="search" placeholder="Search" />
                        </Col>
                      </Row>  
                      <Row className="mt-2"  style={{maxHeight : 600 , overflowY : "scroll"}}>
                        <Col>
                            <Row className="sticky-top bg-light py-2" style={{fontWeight: "bolder", fontSize: 14, zIndex: 99}}  noGutters>
                              <Col xs={6}>User</Col>
                              <Col xs={6}>
                                <span>Due Since</span>
                                <Icon style={{cursor: "pointer"}} className="d-inline-flex align-middle" onClick={(e) => this.setState({isOpenSortHandshakeMenu1: e.currentTarget})}>keyboard_arrow_down</Icon>
                                <Menu anchorEl={this.state.isOpenSortHandshakeMenu1 || null} open={!!this.state.isOpenSortHandshakeMenu1} onClose={() => this.setState({isOpenSortHandshakeMenu1: null})}>
                                    <MenuItem onClick={() => this.sortHandshake("days", "asc")} selected={sortHandshake.days === "asc"}>{"Ascending"}</MenuItem>
                                    <MenuItem onClick={() => this.sortHandshake("days", "desc")} selected={sortHandshake.days === "desc"}>{"Descending"}</MenuItem>
                                </Menu>
                              </Col>
                            </Row>
                              {pendingHandshakeData.length ? pendingHandshakeData.slice(this.state.handshakeCurrentPage*this.state.handshakeRowsPerPage, this.state.handshakeCurrentPage*this.state.handshakeRowsPerPage+this.state.handshakeRowsPerPage).map((data, i) =>
                              {
                                  return <Row key={i} className="align-items-center border-bottom" noGutters style={{fontSize: 14}}>
                                      <Col xs={1}>
                                          <img className="rounded-circle" style={{width: "30px" , height: "30px", fontSize: 8}} src={(data.User && data.User.profile_picture_url) || "/images/dummy_profile.jpg"} onError={(e)=>{e.target.onerror = null; e.target.src="/images/dummy_profile.jpg"}}  alt="Profile Pic.."></img>
                                      </Col>
                                      <Col xs={5}>
                                        <b>{data.User.first_name}</b>
                                        <span className="d-block" style={{fontSize:12}}>PMW{data.User.id}</span>
                                        <span className="d-block" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(data.User), isConfirmMessage : "Are you sure to connect with "+data.User.first_name+"?"})} style={{fontSize:12, cursor: "pointer"}}><span role="img" aria-label="call">&#128222;</span>{data.User.mobile}</span>
                                        <em style={{fontSize: 12}}>{locationListById[data.job_location_id] ? locationListById[data.job_location_id].city : "Location Not Found"}</em>
                                      </Col>
                                      <Col xs={5} class>{moment().diff(moment(data.ApplicationTransition.created_on), 'days')} days</Col>
                                  </Row>
                                }) : 
                                <Row><Col><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
                              }
                         
                        </Col>
                      </Row>
                      <TablePagination labelRowsPerPage="" rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={pendingHandshakeData.length} rowsPerPage={this.state.handshakeRowsPerPage} page={this.state.handshakeCurrentPage}
                                onChangePage={ (e, page) => this.setState({handshakeCurrentPage : page}) }
                                onChangeRowsPerPage={ (e) =>  this.setState({handshakeRowsPerPage : e.target.value})}
                            />
                  </Col>}



                  
                </Row>
                <Modal size={"lg"} isOpen={!!isOpenStatusModal} toggle={()=> {this.setState({isOpenStatusModal : false})}} >  
                            <ModalHeader toggle={()=> {this.setState({isOpenStatusModal : false})}}>Partners Status</ModalHeader>
                    <ModalBody  className="">
                    </ModalBody>
                </Modal>

                <Modal style={{minWidth: "75vw"}} size={"lg"} isOpen={!!isOpenMapModel} toggle={()=> {this.setState({isOpenMapModel : false})}} >  
                            <ModalHeader toggle={()=> {this.setState({isOpenMapModel : false})}}>Partners Basic Details<p style={{fontSize: 15, marginBottom: "-10px", }}>{isOpenMapModel.user && isOpenMapModel.first_name} | PMW{isOpenMapModel.user && isOpenMapModel.id}</p></ModalHeader>
                    <ModalBody  className="">
                    {!!isOpenMapModel && <PartnerMapDetail taskList={this.state.taskList} selectedJob={this.state.selectedJob} user={this.state.isOpenMapModel.user} getCycleDates={this.getCycleDates} selectedCycle={selectedInTaskType} selectedStatus={isOpenMapModel.taskStatus} />}
                    </ModalBody>
                </Modal>

                <Modal size={"lg"} isOpen={!!this.state.isOpenAuditTask} toggle={()=> {this.setState({isOpenAuditTask : false})}} >  
                    <ModalHeader toggle={()=> {this.setState({isOpenAuditTask : false})}}><h6>Task Audit</h6></ModalHeader>
                    <ModalBody  className="">
                    {!!this.state.isOpenAuditTask && <AuditTask taskList={this.state.taskList} selectedJob={this.state.selectedJob} />}
                    </ModalBody>
                </Modal>
                
                {!!this.state.isOpenUserModel && <PartnerDetail {...this.state.isOpenUserModel} toggle={() => this.setState({isOpenUserModel : false})} />}           


                <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
                {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}

            </div>
            
            
        );
    }
}
export default Dashboard;
