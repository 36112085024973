
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Icon } from "@material-ui/core";

import { setError, setLoader } from "../../../actions/action-creators";
import store from "../../../store";
import ApiEndpoints from "../../../constants/api_endpoints";
import ConfirmAction from "../../global/confirm-action";

class UserAccess extends Component {
   constructor(props){
    super(props);
    this.state={  
        accessData : [],
        selectedJob : null,
        isOpenConfirm: false,
    }
   }

    componentDidMount(){
        this.showAccessData()
    }

    showAccessData = async (user) => {
        store.dispatch(setLoader(true));
        try{
            let body = { user_id: this.props.user.user_id }
            let apiResp = await ApiEndpoints.User.fetchMappedAccessDetails(body);
            let accessData = apiResp.data.data;
            let selectedJob = accessData[0] || null;
            this.setState({accessData, selectedJob});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

  
    deleteAccess = async (access_id) => {
        store.dispatch(setLoader(true))
        try {
            await ApiEndpoints.User.inactiveAccess({access_id})
            this.showAccessData(this.state.activeCompany)
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let {accessData, selectedJob} = this.state;
        let { data } = this.props;
        return (
        <>
                     
            <Row>
                <Col xs={6} className="border-right">
                    {accessData.map(job => 
                        <div style={{borderRadius: 8, position: "relative", border: `solid ${selectedJob.job_id === job.job_id ? "#ff000033" : "#ccc"}`}} className="shadow-sm my-2 p-2 visible-on-hover-container" onClick={() => this.setState({ selectedJob: job })}>
                            <strong>{job.project_name}</strong>
                            <div className="visible-on-hover-item" style={{position: "absolute", top: "-10px", right: "-20px"}}>
                                <Icon className="align-middle mr-3 " style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isOpenConfirm :  () => this.deleteAccess([job.access_id, ...job.JobLocations.map(loc => loc.access_id)]) })}} >delete</Icon>
                            </div>
                        </div>
                    )}
                </Col>
                <Col xs={6}>
                    {
                        selectedJob && selectedJob.JobLocations.map(loc => <div style={{borderRadius: 8, position: "relative"}} className="border border-light shadow-sm my-2 p-2 visible-on-hover-container">
                        <strong>{loc.city}</strong>
                        <div className="visible-on-hover-item" style={{position: "absolute", top: 0, right: "-35px"}}>
                            <Icon className="align-middle mr-3 " style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isOpenConfirm :  () => this.deleteAccess(loc.access_id) })}} >delete</Icon>
                        </div>
                    </div>
                    )
                    }
                </Col>
            </Row>

            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

        </>
         
        ) 
    }
}

export default UserAccess;
