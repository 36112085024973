import React, { Component } from "react";
import { Select, MenuItem, Icon, Checkbox, TextField } from '@material-ui/core';

class SelectList extends Component {
    constructor(props) {
      super(props);
      this.state = {
        searchText : "" ,
        }
      }

      stopImmediatePropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
      };

    render() {
        let props = {...this.props};
        let children = [...(props.children || [])];
        delete props.children;
        props.className = "w-100 display-block "+ (props.className || "");
        props.value = props.value ===null || props.value === undefined ? "" : props.value;
        if(props.multiple) props.renderValue= (selected) => selected.length === 0 ? <em>{this.props.placeholder || "Select"}</em> : selected.length + " Selected";
       
        return(
            <div>
                <Select onClose={() => this.setState({searchText: ""})} {...props} displayEmpty  MenuProps={{ autoFocus: false }}  >
                  { this.props.search &&
                    <TextField
                      className="w-100 px-2 mb-2" placeholder="Type to search" autoFocus
                      onChange={(e) => {this.setState({searchText: e.target.value})}}
                      onClickCapture={this.stopImmediatePropagation}
                      onKeyDown={(e) => {if (e.key !== "Escape") {e.stopPropagation();}}}
                      InputProps={{value: this.state.searchText, endAdornment: ( <Icon fontSize="small">search</Icon> )}}
                    />
                  }
                  {this.props.placeholder && <MenuItem disabled value=""><em>{this.props.placeholder}</em></MenuItem>}
                  {this.props.selectAll && <MenuItem value=""><em>{this.props.selectAll}</em></MenuItem>}

                  { props.multiple === "checkbox" &&
                  <MenuItem divider disabled style={{opacity: 1}}>
                    <Checkbox style={{pointerEvents: "all"}} checked={children.length && children.length === props.value.length} onChange={(e) => this.props.onChange({target: {value : e.target.checked ? children.map(item => item.props.value) : []}})} />
                    <em>Select All</em>
                  </MenuItem>
                  }
                  {
                    children
                    .filter(item => this.state.searchText ? item.props.children.toString().toLowerCase().includes(this.state.searchText.toLowerCase()) : true)
                    .map((item, i) => 
                      <MenuItem key={i} value={item.props.value} disabled={item.props.disabled}>
                        {props.multiple === "checkbox" && <Checkbox checked={props.value.indexOf(item.props.value) > -1} /> }
                        {item.props.children}
                      </MenuItem>
                    )
                  }
                  {this.props.addNew && <MenuItem value="ADD_NEW"><Icon>add</Icon>Add New</MenuItem>}

                </Select>
            </div>
        );
     }
    }
    export default SelectList;
