import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { setSuccess, setError } from '../../actions/action-creators';
import { Alert } from 'reactstrap';
import { Snackbar } from '@material-ui/core';

class SuccessAlertComp extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.success.show === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 5000)
		}
	}

	onClose = () => {
		this.props.setSuccess({ show: false })
	}

	render() {
		let { success } = this.props
		return (
			<Snackbar style={{zIndex: 99999}} open={!!success.show} autoHideDuration={6000} onClose={this.onClose}>
				<Alert color="success" isOpen={!!success.show} toggle={this.onClose}>
					{success.message}
				</Alert>
			</Snackbar>
		)
	}
}
class ErrorAlertComp extends Component {

	componentWillReceiveProps(nextProps) {
		if (nextProps.error.show === true) {
			if (this.timeout)
				clearTimeout(this.timeout)
			this.timeout = setTimeout(() => this.onClose(), 5000)
		}
	}

	onClose = () => {
		this.props.setError({ show: false })
	}

	render() {
		let { error } = this.props
		return (
			<Snackbar style={{zIndex: 99999}} open={!!error.show} autoHideDuration={6000} onClose={this.onClose}>
				<Alert color="danger" isOpen={!!error.show} toggle={this.onClose}>
					{error.message}
				</Alert>
			</Snackbar>
		)
	}
}
const mapStateToPropsSuccess = state => ({
	...state
})
const mapDispatchToPropsSuccess = dispatch => ({
	setSuccess: (success) => dispatch(setSuccess(success)),
	setError: (error) => dispatch(setError(error))
})

export const SuccessAlert = withRouter(connect(mapStateToPropsSuccess, mapDispatchToPropsSuccess)(SuccessAlertComp));
export const ErrorAlert = withRouter(connect(mapStateToPropsSuccess, mapDispatchToPropsSuccess)(ErrorAlertComp));

