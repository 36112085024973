import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import InputFeild from "../../global/form/input"
import SelectList from "../../global/form/select"
import "../../../styles/button.css"
import CONSTANTS from "../../../constants"
import { ChromePicker  } from 'react-color';
import ImageUploadFeild from "../../global/form/upload-image";
import Aux from "../../global/AuxComp"
import store from "../../../store";
import { setError, setSuccess } from "../../../actions/action-creators";
import { addUser, uploadFile, copyCodeToClipboard } from "../../../actions";
import { Icon } from "@material-ui/core";
import { Link } from "react-router-dom";
import RichTextEditor  from '../../global/form/Richtext';

class JobCreation extends Component {
    constructor(props) {
      super(props);
      this.state = {
          step : 1,
          companyList : [],
          bannerFile : null,
          descriptionData : {},
          jobDetails : {},     
          jobRequirements : [],
          displayColorPicker: false,
          jobLocation : [],
          currentJobLocation: {},
          recruiters : [],
          isEditJob : props.match.params.job_id || false,
        }
      }

      async componentDidMount() {
        await this.fetchCompany()
        this.fetchRecruiters()
      }
      
      fetchCompany = async () => {
        try{
          let companyResp = await ApiEndpoints.Employer.fetchEmployer();
          this.setState({companyList : companyResp.data.data});
          if(companyResp.data.data.length > 0){
            this.onChangeDetailsForJob("employer_id", companyResp.data.data[0].employer_id)
          }
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        
      }

      fetchRecruiters = async () => {
        try{
          var accessOpts = {
            access_type : "EMPLOYER",
            access_type_id : this.state.jobDetails.employer_id
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let recruiters = accessResp.data.data.filter(access => access.UserRole.role_id === 4);
          this.setState({recruiters})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
      }

      onChangeDetailsForJob = (key, value) =>{
        let { jobDetails } = this.state;
        jobDetails[key] = value;
        this.setState({ jobDetails });
        if(key === "employer_id") this.fetchRecruiters();
      }

      onChangeRequirments = (e) =>{
        let { jobRequirements } = this.state;
        if (e.target.checked) {
          jobRequirements.push(e.target.value)
        }
        else {
          var i = jobRequirements.indexOf(e.target.value)
          jobRequirements.splice(i, 1);
        }
        this.setState({jobRequirements})
      }

      onChangeLocation = (e, type) =>{
        let { currentJobLocation } = this.state;
        if(type === "vacancy"){
          currentJobLocation.vacancy = e.target.value;
        }if(type === "location"){
          currentJobLocation = { ...currentJobLocation, ...CONSTANTS.JOB_LOCATIONS[e.target.value], index : e.target.value};
        }
        this.setState({currentJobLocation })
      }
      onAddLocation = (e) =>{
        e.preventDefault()
        let { currentJobLocation, jobLocation } = this.state;
        jobLocation.push(currentJobLocation)
        e.target.reset();
        this.setState({jobLocation, currentJobLocation : {} })
      }

     
      createProject = async (e) =>{
        e.preventDefault();
        let { jobDetails, jobRequirements, companyList, bannerFile } = this.state;
        try{
          if(!jobDetails.employer_id) throw new Error("Comapny Required to create Project")
          jobDetails.job_category = companyList.filter(obj => obj.employer_id == jobDetails.employer_id)[0].job_type_access || "WALKIN";
          if(bannerFile){
            var bannerResp = await uploadFile(bannerFile, "JOB_DATA");
            jobDetails.banner_key = bannerResp.file_id;
          }
          let jobResp = await ApiEndpoints.Jobs.addJob(jobDetails);
          jobDetails.job_id = jobResp.data.data.job_id;
          this.setState({jobDetails})
          await Promise.all(jobRequirements.map(async requirement => {
              var requirementBody = {
              job_id : jobDetails.job_id,
              is_mandatory : 0,
              ...CONSTANTS.JOB_REQUIREMENTS[requirement]
            }
            await ApiEndpoints.Jobs.addRequirement(requirementBody)
          }))
          this.setState({jobDetails, step : 2})
          store.dispatch(setSuccess({show: true, message: "Project has been Initiated"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
      }

      onChangeLocationRecruiter = (e) => {
        let { recruiters, currentJobLocation } = this.state;
        currentJobLocation.recruiters = currentJobLocation.recruiters || {};
        if(currentJobLocation.recruiters[e.target.value]){
          delete(currentJobLocation.recruiters[e.target.value])
        }else currentJobLocation.recruiters[e.target.value] = recruiters[e.target.value];
        this.setState({currentJobLocation})
      }

      addJobLocation = async (i) =>{
          let { jobLocation, jobDetails } = this.state;
          try{
            if(!jobLocation.length) throw new Error("Location required!")
            await Promise.all(jobLocation.map(async (location, i) => {
              var locationBody = {
                job_id : jobDetails.job_id,
                ...location 
              }
              let locationResp = await ApiEndpoints.Jobs.addLocation(locationBody);
              if(location.recruiters)
              await Promise.all(Object.keys(location.recruiters).map(key => {
                var userBody = {
                  user_id : location.recruiters[key].user_id,
                  user_role_id : location.recruiters[key].user_role_id,
                  access_type : "JOB_LOCATION",
                  access_type_id : locationResp.data.data.job_location_id
                }
                return addUser(userBody)
              }));
              jobLocation[i].job_location_id = locationResp.data.data.job_location_id;
            }))
            this.setState({jobLocation, step : 3})
            store.dispatch(setSuccess({show: true, message: "Lacations have been associated with Project"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }

      }
      
      setNext = () =>{
        this.setState({step : 3})
      }

      onChangeQues = (k, v) => {
        let {descriptionData} = this.state;
        descriptionData[k] = v;
        this.setState({descriptionData})
    }

    render() {
      console.log(this.props.match.params)
      let {step, companyList, jobDetails, currentJobLocation, recruiters, jobLocation, isEditJob , descriptionData} = this.state;
        return(
            <Aux >
                    <Row className="mb-3 border-bottom">
                        <span className="mt-3 pl-4" style={{fontSize: "22px"}}>Create New Project</span>
                    </Row>
                    <Row>
                        <Col xs={4} className={"border-right " + (step !== 1 ? "disabled-div":"")}>
                          <p className="mt-1 border-bottom " style={{fontSize: "18px" }}>Project Details</p>
                          <form onSubmit={this.createProject}>
                            <SelectList value={jobDetails.employer_id} onChange={(e) => this.onChangeDetailsForJob("employer_id", e.target.value)}>
                              {
                                companyList.map((item, i)=> 
                                  <option key={i} value={item.employer_id}> {item.employer_name}</option>
                                )
                              }
                            </SelectList>
                            <ImageUploadFeild required className="mt-3" label="Upload Banner Image" onChange={(file) => this.setState({bannerFile : file})}/>
                            <InputFeild required className="mt-3" placeholder="Project Title" onChange={(e)=>{this.onChangeDetailsForJob("title",e.target.value )}}/>
                            <InputFeild required className="mt-3" placeholder="Project Domain" onChange={(e)=>{this.onChangeDetailsForJob("domain",e.target.value )}}/>
                            <SelectList required placeholder="Project Type" value={jobDetails.type} onChange={(e) => this.onChangeDetailsForJob("type", e.target.value)}>
                                  <option value={"Part-Time"}> {"Part-Time"}</option>
                                  <option value={"Full-Time"}> {"Full-Time"}</option>
                            </SelectList>
                            <InputFeild className="mt-2" placeholder="Maximum Earn Opportunity" onChange={(e)=>{this.onChangeDetailsForJob("max_salary",e.target.value )}}/>
                            <InputFeild className="mt-2" placeholder="Perks" onChange={(e)=>{this.onChangeDetailsForJob("rewards",e.target.value )}}/>
                            <InputFeild className="mt-2" placeholder="Project Duration (months)" onChange={(e)=>{this.onChangeDetailsForJob("duration",e.target.value )}}/>
                            <InputFeild required className="mt-2" placeholder="Project Tagline" onChange={(e)=>{this.onChangeDetailsForJob("tag_line",e.target.value )}}/>
                            <InputFeild required className="mt-2" placeholder="Total No. of Partners Required" onChange={(e)=>{this.onChangeDetailsForJob("vacancy",e.target.value )}}/>
                            <InputFeild className="mt-2 mb-3" placeholder="Project Description" onChange={(e)=>{this.onChangeDetailsForJob("description",e.target.value )}}/>
{/* 
                            <Row className="mb-3">
                                <Col>
                                    <form>
                                        <RichTextEditor value={descriptionData.description || ""}  onChange={(e)=>{this.onChangeDetailsForJob("description",e.target.value )}}/>
                                    </form>
                                </Col>
                            </Row> */}

                            <Button type="button" id="color_picker" onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })} style={{cursor: "pointer"}}>Pick Color</Button>
                            { this.state.displayColorPicker ? <div style={{ position: 'absolute', zIndex: '2'}}>
                              <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} id="close_color_picker"
                                onClick={() => this.setState({ displayColorPicker: false })}></div>
                              <ChromePicker color={jobDetails.theme_color || "#000"} onChange={(color) => this.onChangeDetailsForJob("theme_color", color.hex)} />
                            </div> : null }
                            <p className="text-muted mt-3">Job Requirements</p>
                            <Row className="align-items-center ml-1" style={{fontSize: "12px"}}>
                              {
                                CONSTANTS.JOB_REQUIREMENTS.map((item, i)=> 
                                  <Col xs={6} className="mb-3">   
                                    <Input type="checkbox" name="requirements" value={i} onChange={this.onChangeRequirments}/> 
                                    <span className="pl-3">{item.eligibility_value}</span>
                                  </Col>
                                )
                              }
                              </Row>
                              <Button  type="submit" size="24px" block className="buttonred">Create Project</Button>
                          </form>

                        </Col>

                        <Col xs={4} className={"border-right "  + (step !== 2 ? "disabled-div":"")}>
                          <p className="mt-1 pl-3 border-bottom " style={{fontSize: "18px"}}>Add Locations</p>
                          <form onSubmit={this.onAddLocation}>
                            <SelectList placeholder="Select City" value={currentJobLocation.index} required onChange={(e) => this.onChangeLocation(e, "location")} >
                              {
                                CONSTANTS.JOB_LOCATIONS.map((item, i)=> 
                                  <option value={i}> {item.city}</option>
                                )
                              }
                            </SelectList>
                            <p className="mt-3 pl-3"> Select Recruiters</p>
                            { recruiters.length ? recruiters.map((recruiter, i) =>  
                            <Row className="align-items-center ml-4" style={{fontSize: "14px"}}>
                              <Col xs={12} >   
                                <Input type="checkbox" name="requirements" value={i} onChange={this.onChangeLocationRecruiter}/> 
                                <span className="pl-2">{recruiter.User.first_name} | {recruiter.User.mobile}</span>
                              </Col>
                            </Row>
                            ) : <h6 className="text-muted ml-4"><em>No Recruiters Available, Add First</em></h6>}
                            <InputFeild required className="mt-4" value={currentJobLocation.vacancy || ""}  onChange={(e) => this.onChangeLocation(e, "vacancy")} placeholder="No. of Gig Partners for this Location " />
                          
                            <Row className="my-4 ">
                                <Col xs={5} className="py-2 ml-3">
                                  <Button  type="submit" size="24px" block className="buttonred">Add Location</Button>
                                </Col>
                                <Col xs={5} className="py-2 ml-3">
                                  <Button onClick={this.addJobLocation} type="button" size="24px" block className="buttonred">Next</Button>
                                </Col>
                            </Row>
                          </form>
                          <Row>
                            <Col xs={2}> <h6>S.No</h6> </Col>
                            <Col xs={3}> <h6>Location</h6> </Col>
                            <Col xs={4}> <h6>Recruiters</h6> </Col>
                            <Col xs={3}> <h6>Partners</h6> </Col>
                          </Row>

                          { this.state.jobLocation.map((location, i)=> 
                              <Row>
                                <Col xs={2}>{i+1}</Col>
                                <Col xs={3}>{location.city}</Col>
                                <Col xs={4}>{
                                  location.recruiters && Object.keys(location.recruiters).map((key, i, arr) => location.recruiters[key].User.first_name+ (arr.length !== i+1 ? ", ":""))
                                }</Col>
                                <Col xs={3}>{location.vacancy}</Col>
                              </Row>
                            )
                          }
                        </Col> 
                       
                         <Col xs={4} className={""  + (step !== 3 ? "disabled-div":"")}> 
                            <p className="mt-1 pl-3 border-bottom" style={{fontSize: "18px"}}>Project Preview</p>
                            {jobLocation.map(location => {
                              var encodedJobLocationId = location.job_location_id && Buffer.from(location.job_location_id.toString()).toString('base64');
                              var encodedJobId = jobDetails.job_id && Buffer.from(jobDetails.job_id.toString()).toString('base64');
                              var projectUrl = process.env.REACT_APP_PMWJOBS_URL+"application/"+ encodedJobId + "/"+ encodedJobLocationId;
                              return <div>
                                <h6>Project URL for {location.city}</h6>
                                <div class="input-group my-3">
                                  <InputFeild className="mt-2" type="text" value={projectUrl} />                           
                                  <div class="input-group-append" style={{cursor : "pointer"}} title="Copy URL To Clipboard" onClick={()=> copyCodeToClipboard(projectUrl)}>
                                    <span class="input-group-text"><Icon >file_copy</Icon></span>
                                  </div>
                                </div>
                              </div>
                            })
                            }
                             

                            <Col xs={5} className="py-2 ml-3">
                              <Link to="/project/overview"><Button type="button" size="24px" block className="buttonred ">Manage Project</Button></Link>
                            </Col>
                        </Col> 
                      
                    </Row>
                  </Aux>             

        );
     }
    }
    export default JobCreation;