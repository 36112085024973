import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import Aux from "../../global/AuxComp";
import {setSuccess, setError, setLoader } from '../../../actions/action-creators';
import store from '../../../store';
import { Card, Chip, Icon, TablePagination } from "@material-ui/core";
import SelectList from "../../global/form/select";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import CONSTANTS from "../../../constants";
import InputFeild from "../../global/form/input";
import UserCard from "../user_profile/user_card";

class CompanyCreate extends Component {
    constructor(props) {
      super(props);
      this.state = {
          jobList : [],
          handshakePendingData: [],
          handshakeCompletedData: [],
          selectedJobId: null,
          selectedJobLocationId: null,
          rowsPerPage1 : 20,
          currentUserPage1 : 0,
          rowsPerPage2 : 20,
          currentUserPage2 : 0,
          isConfirm: false,
          pendingSearchText : "",
          doneSearchText: ""
        }
    }

    async componentDidMount(){
      await this.getJob();
     }
    
    getJob = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
            jobList = jobList
                .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
                .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
            
            await this.setState({ jobList })
            if(this.state.jobList.length){
                this.fetchHandshakeData(this.state.jobList[0].job_id)
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchHandshakeData = async (job_id, job_location_id) => {
      store.dispatch(setLoader(true))
      this.setState({rowsPerPage1 : 20, currentUserPage1 : 0, rowsPerPage2 : 20, currentUserPage2 : 0});
      try{
        var body = {
          job_id,
          job_location_id
        }
        let pendingData = await ApiEndpoints.Handshake.fetchPendingHandshake(body);
        let completedData = await ApiEndpoints.Handshake.fetchCompletedHandshake(body);
        await this.setState({ handshakePendingData : pendingData.data.data, handshakeCompletedData: completedData.data.data, selectedJobId: job_id, selectedJobLocationId: job_location_id || null});
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    markHandshake = async (data) => {
      store.dispatch(setLoader(true))
      let { handshakePendingData, handshakeCompletedData } = this.state;
      this.setState({rowsPerPage2 : 20, currentUserPage2 : 0});
      try{
        var body = {
          job_id: data.job_id,
          user_id: data.user_id
        }
        let apiData = await ApiEndpoints.Handshake.add(body);
        data.Handshake = apiData.data.data;
        handshakeCompletedData.unshift(data);
        handshakePendingData = handshakePendingData.filter(o => o.application_id !== data.application_id);
        this.setState({handshakeCompletedData, handshakePendingData});
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    render() {
        let { selectedJobId, selectedJobLocationId, jobList, handshakePendingData, handshakeCompletedData, isConfirm, pendingSearchText, doneSearchText } = this.state;
        var locationList = selectedJobId ? jobList.filter(job => job.job_id === selectedJobId)[0].JobLocations : [];
        locationList = locationList.sort((a, b) => a.city > b.city ? 1 : -1);
        pendingSearchText = pendingSearchText.toLowerCase();
        doneSearchText = doneSearchText.toLowerCase();
        if(pendingSearchText) {
            handshakePendingData = handshakePendingData.filter(obj => {
            return (obj.User ? obj.User.first_name || "" : "").toLowerCase().includes(pendingSearchText) ||
                    ("pmw"+(obj.User ? obj.User.id : "")).includes(pendingSearchText);
            });
        }
        if(doneSearchText) {
            handshakeCompletedData = handshakeCompletedData.filter(obj => {
            return (obj.User ? obj.User.first_name || "" : "").toLowerCase().includes(doneSearchText) ||
                    ("pmw"+(obj.User ? obj.User.id : "")).includes(doneSearchText);
            });
        }
        return (
          <Aux>
            <Row>
                <Col xs={6}>
                    <SelectList search={true} placeholder="Select Project" value={selectedJobId} onChange ={(e) => this.fetchHandshakeData(e.target.value)} >
                        { jobList.map(job => 
                        <option key={job.job_id} value={job.job_id}> 
                            {job.Employer.employer_name +  "  -  "  + job.title}
                            {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                        </option>)}
                    </SelectList>
                </Col>
                <Col>
                    <SelectList search={true} placeholder="Select Location" value={selectedJobLocationId} onChange ={(e) => this.fetchHandshakeData(selectedJobId, e.target.value)} >
                        {locationList.map(loc => 
                        <option key={loc.job_location_id} value={loc.job_location_id}> 
                            {loc.city} 
                            {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                        </option>
                        )}
                    </SelectList>
                </Col>
            </Row>
            <Row className="border m-3">
                <Col className="border-right">
                    <Row className="border-bottom py-2 mb-3">
                        <Col>
                            <h6>Pending Handshake</h6>
                        </Col>
                        <Col xs={5}>
                            <InputFeild onChange={(e) => this.setState({pendingSearchText: e.target.value})} icon="search" placeholder="Search" />
                        </Col>
                    </Row>
                    {
                        handshakePendingData.slice(this.state.currentUserPage1*this.state.rowsPerPage1, this.state.currentUserPage1*this.state.rowsPerPage1+this.state.rowsPerPage1).map(data => 
                            <Card className="my-2 p-3 shadow-sm">
                                    <Row className="align-items-center" style={{fontSize: 14}}>
                                        <Col>
                                            <UserCard data={data.User} />
                                        </Col>
                                        <Col xs={5}>{moment(data.ApplicationTransition.created_on).utcOffset(330).format("Do MMM YYYY hh:mm a")}</Col>
                                        <Col xs={1} className="text-right">
                                            <Icon title="Handshake Done" onClick={() => this.setState({isConfirm : () => this.markHandshake(data) }) } className="text-secondary" style={{fontSize: 20, cursor: "pointer"}}>double_arrow</Icon>
                                        </Col>
                                    </Row>
                            </Card>
                        )
                    }
                    <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={handshakePendingData.length} rowsPerPage={this.state.rowsPerPage1} page={this.state.currentUserPage1}
                            onChangePage={ (e, page) => this.setState({currentUserPage1 : page}) }
                            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage1 : e.target.value})}
                        />
                </Col>
                <Col>
                    <Row className="border-bottom py-2 mb-3">
                        <Col>
                            <h6>Handshake Done</h6>
                        </Col>
                        <Col xs={5}>
                            <InputFeild onChange={(e) => this.setState({doneSearchText: e.target.value})} icon="search" placeholder="Search" />
                        </Col>
                    </Row>
                    {
                        handshakeCompletedData.slice(this.state.currentUserPage2*this.state.rowsPerPage2, this.state.currentUserPage2*this.state.rowsPerPage2+this.state.rowsPerPage2).map(data => 
                            <Card className="my-2 p-3 shadow-sm">
                                    <Row className="align-items-center" style={{fontSize: 14}}>
                                        <Col xs={1}>
                                            <img className="rounded-circle" style={{width: "30px" , height: "30px", fontSize: 8}} src={(data.User && data.User.profile_picture_url) || "/images/dummy_profile.jpg"} onError={(e)=>{e.target.onerror = null; e.target.src="/images/dummy_profile.jpg"}}  alt="Profile Pic.."></img>
                                        </Col>
                                        <Col xs={6}>
                                            <span className="d-block">{data.User.first_name}</span>
                                            <span style={{fontSize: 12}} className="text-secondary">PMW{data.User.id}</span>                                        </Col>
                                        <Col xs={5}>{moment(data.Handshake.created_on).utcOffset(330).format("Do MMM YYYY hh:mma")}</Col>
                                        {/* <Col xs={2} className="text-right">
                                            <Icon title="Handshake Done" onClick={() => this.markHandshake(data)} className="text-secondary" style={{fontSize: 20, cursor: "pointer"}}>double_arrow</Icon>
                                        </Col> */}
                                    </Row>
                            </Card>
                        )
                    }
                    <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={handshakeCompletedData.length} rowsPerPage={this.state.rowsPerPage2} page={this.state.currentUserPage2}
                            onChangePage={ (e, page) => this.setState({currentUserPage2 : page}) }
                            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage2 : e.target.value})}
                    />
                </Col>
            </Row>
            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />

          </Aux>
        );
    }
}

export default CompanyCreate;
