
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Card, CardBody, Collapse, FormGroup, Input } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import InputFeild from "../../global/form/input"
import "../../../styles/button.css"
import { Icon, Switch } from '@material-ui/core';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import CONSTANTS from "../../../constants";

class JobRequirement extends Component {
    constructor(props) {
      super(props);
      this.state = {
            requirementData : [...CONSTANTS.REQUIREMENTS],
            requirementAPIData: [],
            extraDetailsData: [],
            isOpenAddExtraDetails : false,
            isConfirm : false,
        }
    }

    componentDidMount(){
        this.fetchRequirements() ;
        this.props.userType === "PARTNER" && this.fetchExtraDetails()
    }

    componentDidUnmount(){
        this.setState({ requirementData: [] })
    }

    fetchRequirements = async () =>{
        store.dispatch(setLoader(true));
        try{
            let eligibilityFor = this.props.userType === "PARTNER" ? 0 : 1;

            let body = {
                job_id : this.props.job.job_id,
                eligibility_for: eligibilityFor
            }
            let apiData = await ApiEndpoints.Jobs.fetchRequirement(body);
            apiData = apiData.data.data;
            let data = [...this.state.requirementData.filter(o => o.eligibility_for === eligibilityFor)].map(req => {
                apiData.forEach(respObj => {
                    if(respObj.eligibility_for === req.eligibility_for && respObj.eligibility_type === req.eligibility_type && (respObj.eligibility_subtype === req.eligibility_subtype && (respObj.eligibility_value || "" === req.eligibility_value || ""))){
                        req = {...req, ...respObj};
                    }
                });
                return {...req};
            })
            this.setState({requirementData: [...data], requirementAPIData: [...apiData]});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   

    onChangeRequirement = (i, key, value) => {
        let requirementData = [...this.state.requirementData];
        if(key === "job_eligibility_id"){
            if(value && !requirementData[i].job_eligibility_id && !requirementData[i].deleted_id) requirementData[i].job_eligibility_id = "NEW";
            if(value && !requirementData[i].job_eligibility_id && requirementData[i].deleted_id) {
                requirementData[i].job_eligibility_id = requirementData[i].deleted_id
                delete requirementData[i].deleted_id;
            }
            if(!value && requirementData[i].job_eligibility_id){
                requirementData[i].deleted_id = requirementData[i].job_eligibility_id;
                delete requirementData[i].job_eligibility_id;
            }
        } else {
            requirementData[i][key] = value;
        }
        this.setState({ requirementData })
    }

    submitRequirements = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let { requirementData } = this.state;
        try{
            let deletedIds = requirementData.filter(o => o.deleted_id && o.deleted_id !== "NEW").map(o => o.deleted_id);
            if(deletedIds.length) await ApiEndpoints.Jobs.deleteRequirement({job_eligibility_id : deletedIds});
            await Promise.all(requirementData.filter(o => o.job_eligibility_id === "NEW").map( req => ApiEndpoints.Jobs.addRequirement({is_mandatory: 0, ...req, job_id: this.props.job.job_id}) ))
            await Promise.all(requirementData.filter(o => o.job_eligibility_id && o.job_eligibility_id !== "NEW").map(req => ApiEndpoints.Jobs.editRequirement(req)))
            this.props.onClose && this.props.onClose();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    

    fetchExtraDetails = async () =>{
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.JobExtraDetails.fetch({job_id : this.props.job.job_id});
            this.setState({extraDetailsData : apiData.data.data}) ;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    addExtraDetails = async (e) => {
        e.preventDefault();
        e.persist();
        try{
            var body = {
                job_id : this.props.job.job_id ,
                title: e.target.title.value,
                type: e.target.type.value
            }
            await ApiEndpoints.JobExtraDetails.add(body);
            e.target.reset();
            this.setState({isOpenAddExtraDetails : false}) ;
            this.fetchExtraDetails() ;
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deleteExtraDetails = async (job_extra_detail_id) =>{
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.JobExtraDetails.delete({job_extra_detail_id});
            await this.fetchExtraDetails();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    

    render(){
        let {userType, requirementData, isOpenAddExtraDetails , extraDetailsData, isConfirm} = this.state;
        return(
            <div>
                <strong className="d-block text-muted mt-3 mb-2 pb-2 border-bottom">{this.props.userType === "PARTNER" ? "Partner details required before start work": "Customer details for better matching projects"}</strong>
                <Row className={"align-items-center my-2 font-weight-bold"}>
                    <Col xs={2}>Status</Col>
                    <Col xs={5}>Requirement</Col>
                    <Col xs={3}>Value</Col>
                    <Col>Mandatory?</Col>
                </Row>
                <form onSubmit={this.submitRequirements}>
                    {requirementData.map((req, i) => {
                        let isActive = !!req.job_eligibility_id;
                        return <>
                        <Row className={"align-items-center"}>
                            <Col xs={2}><Switch size="small" checked={isActive} onChange={({target}) => this.onChangeRequirement(i, "job_eligibility_id", target.checked)} /></Col>
                            <Col xs={5}>{req.label}</Col>
                            <Col xs={3}>
                                {!!req.inputValue && (<>
                                    {Array.isArray(req.inputValue) ?
                                    <SelectList disabled={!isActive} placeholder="Select Value" value={req.eligibility_value} onChange={({target}) => this.onChangeRequirement(i, "eligibility_value", target.value)} >
                                        {req.inputValue.map((v, i) => <option key={i} value={v} >{v}</option>)}
                                    </SelectList>
                                    :
                                    <InputFeild type={req.inputFormat || "text"} disabled={!isActive} required={isActive} value={req.eligibility_value}  onChange={({target}) => this.onChangeRequirement(i, "eligibility_value", target.value)} placeholder={"Enter value, ex "+req.inputValue}  />
                                    }
                                </>)}
                            </Col>
                            <Col><Switch disabled={!isActive} size="small" checked={!!req.is_mandatory}  onChange={({target}) => this.onChangeRequirement(i, "is_mandatory", target.checked ? 1 : 0)} /></Col>
                        </Row>
                    </>
                    })}
                    <div className="text-right p-4"><Button size="sm" color="danger">Save</Button></div>
                </form>


                {this.props.userType === "PARTNER" && 
                <div>
                    <strong className="d-block text-muted mt-3 mb-2 pb-2 border-bottom">Configure Client ID required before start work</strong>
                    <Button  className="buttonred" style={{height: "23px", paddingTop: "1px" }} onClick={() => {this.setState({isOpenAddExtraDetails : !isOpenAddExtraDetails})}}>Add Input</Button>{' '}
                        <Row>
                            <Col>
                                <Collapse className="w-100" isOpen={isOpenAddExtraDetails}>
                                    <Card className="mt-3">
                                    <CardBody>
                                    <form onSubmit={this.addExtraDetails}>
                                        <Row className="mt-3">
                                            <Col xs={3}>
                                                <FormGroup>
                                                    <Input required name="type" type="select">
                                                        <option  value="">Select</option>
                                                    {
                                                        Object.keys(dataTypes).map(status => 
                                                            <option key={status} value={status}>{dataTypes[status]}</option>
                                                        )
                                                    }
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <InputFeild name="title" required placeholder="Enter Input Title" />
                                            </Col>
                                        </Row>
                                        
                                        <Row className="mt-3">
                                            <Col>
                                                <Button id="ques_cancel" type="button" color="secondary" className="float-right" onClick={()=> {this.setState({isOpenAddExtraDetails : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                                <Button id="ques_save" type="submit" className="buttonred float-right mr-2" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                            </Col>
                                        </Row>
                                        </form>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>

                    <Row className="mt-3 border-bottom pb-1 mb-1">
                        <Col xs={1} ><h6>#</h6> </Col>
                        <Col xs={2} ><h6>Type</h6> </Col>
                        <Col xs={7}> <h6>Title</h6> </Col>
                        <Col xs={2}> <h6>Action</h6> </Col>
                    </Row>

                    {extraDetailsData.map((obj , i)=>
                        <Row key={i} className="mb-1">
                            <Col xs={1} style={{fontSize: "14px"}}>{i+1}</Col>
                            <Col xs={2} style={{fontSize: "14px"}}>{dataTypes[obj.type]}</Col>
                            <Col xs={7} style={{fontSize: "14px"}}>{obj.title}</Col>
                            <Col xs={2}> 
                                <Icon id={"ques_delete" + i} className="ml-2" onClick={()=> {this.setState({isConfirm :  () => this.deleteExtraDetails(obj.job_extra_detail_id)})}} style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon>
                            </Col>
                        </Row>
                    )
                    }

                                
                </div>
                }

            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
                        
        </div>
        


        );
    }
}

export default JobRequirement;



const dataTypes = {
    TEXT: "Text",
    EMAIL: "Email Id",
    PASSWORD: "Password",
    LINK: "Link"
}