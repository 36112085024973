import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";
import moment from "moment";

class CustomerProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList: [],

        }
    }

    componentDidMount(){
        this.fetchCustomerProjectDetails();
    }

    fetchCustomerProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
           let resp = await ApiEndpoints.Customer.customerProjectDetails({customer_lead_id: this.props.customer_lead_id});
           let detailList = resp.data.data;
           this.setState({detailList })
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }  

    render() {
        let { detailList } = this.state;
        return (
           
            <>
                {detailList.map(jobMap => 
                    <div>
                        <Row className="bg-light shadow-sm mt-3 mx-1 border py-2" style={{fontSize: 14, borderRadius: 10}}>
                            <Col xs={1}>
                                {!!jobMap.Job.Employer && !!jobMap.Job.Employer.logo_key && <img className="rounded-circle" alt="project logo" style={{width: "30px" , height: "30px", fontSize: 8, display: "block"}} src={jobMap.Job.Employer.logo_key} />}
                            </Col>
                            <Col xs={11}>
                                <strong>{jobMap.Job.Employer ? jobMap.Job.Employer.employer_name : ""} - { jobMap.Job.title }</strong>
                                <span className="d-block small">Lead added on: {moment(jobMap.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                            </Col>                            
                        </Row>

                    </div>
                    )
                }
                
            </>
                       
        );
    }
}
export default CustomerProjectDetails;
