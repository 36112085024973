
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser } from "../../../actions";
import ConfirmAction from "../../global/confirm-action";

class ManageStoreManager extends Component {
    constructor(props) {
      super(props);
      this.state = {
            storeLocationManagers : [],
            isOpenAddManager : false,
            user : {},
            isOpenConfirm : false,
        }
    }
    componentDidMount(){
        this.fetchManagers();
    }
    
    fetchManagers = async () => {
        store.dispatch(setLoader(true))
        try{
          var accessOpts = {
            access_type : "JOB_LOCATION",
            access_type_id : this.props.store.job_location_id
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let storeLocationManagers = accessResp.data.data.filter(access => access.UserRole && access.UserRole.role_id === 6);
          this.setState({storeLocationManagers})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }

    addManager = async (e) =>{
        e.preventDefault();
        let { user} = this.state;
        store.dispatch(setLoader(true))
          try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var body = {...user, role_id : 6, access_type : "EMPLOYER", access_type_id : this.props.job.employer_id}
            await addUser(body);
            var locationBody = {...user, role_id : 6, access_type : "JOB_LOCATION", access_type_id : this.props.store.job_location_id}
            await addUser(locationBody);
            this.setState({isOpenAddManager : false , user : {}});
            await this.fetchManagers()
            store.dispatch(setSuccess({show: true, message: "Store Manager Added!"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    deletedManager = async (access_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                access_id
              }
            await ApiEndpoints.User.inactiveAccess(body);
            await this.fetchManagers()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

       render(){
           let {isOpenAddManager, user, storeLocationManagers} = this.state;
           let {job} = this.props;
           return(
               
            <div>
                <Row>
                    <Col>
                    <Button className="buttonred my-3" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isOpenAddManager : !isOpenAddManager})}>Add Store Manager</Button>{' '}
                    </Col>
                </Row>
                
                <Collapse isOpen={isOpenAddManager}>
                    <Card>
                    <CardBody>
                        <form onSubmit={this.addManager}>
                            <Row style={{marginTop: "-20px"}}>
                                <Col xs={4}>
                                    <InputFeild name="first_name" onChange={this.onChangeUser} value={user.first_name || ""}  className="mt-3" placeholder="Name*" type="text" inputProps={{pattern: "[a-zA-Z .]{2,145}"}} required />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild required name="mobile" onChange={this.onChangeUser} value={user.mobile || ""} className="mt-3" placeholder="Phone Number*" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild name="email" onChange={this.onChangeUser} value={user.email || ""} className="mt-3" placeholder="Email Address" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "20px", float: "right"}}>
                                <Col style={{marginRight : "-22px"}}>
                                    <Button type="submit" className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </Col>
                                <Col>
                                    <Button onClick={() => {this.setState({isOpenAddManager: false})}}  type="button" color="secondary" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    </Card>
                </Collapse>
                <Row className="mt-2">
                    <Col xs={2} ><h6>S.No</h6> </Col>
                    <Col> <h6>Store Manager</h6> </Col>
                </Row>
                {storeLocationManagers.map((manager, i) => 
                    <Row key={i}>
                        <Col xs={2} >{i+1}</Col>
                        <Col xs={6}>{manager.User.first_name+" | "+manager.User.mobile}</Col>
                        <Col xs={4}>
                            <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedManager(manager.access_id)}) } style={{cursor: "pointer"}} fontSize="small" className="float-right ml-3">delete</Icon>
                        </Col>
                    </Row>
                )}
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />

            </div>

           );
       }
    }
export default ManageStoreManager;
