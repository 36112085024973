
var TICKET_CONSTANTS = {
    REPLY_TEMPLATES : [
        {
            label: "General Messages",
            type: "GENERAL",
            texts: [
                {
                    label: "PickMyWork Intro",
                    text: [
                        "Hi, \n PickMyWork is an earning platform on which you can earn money weekly. There are currently more than 45 products on Pick My Work on which you can earn money by working. Such as Loans, Credit Card, Demat Account, Bank Account.",
                    ]
                },
                {
                    label: "Closure Statement",
                    text: [
                        "If you have further questions, please feel free to ask. Your satisfaction is important to us!",
                        "Hope its resolved your query, If you hve any other query, please feel free to create another ticket. You are important to us!"
                    ]
                },
                {
                    label: "Call Attempt Failure",
                    text: [
                        "We tried calling you, but it seems you were busy at that time. No worries! When is a good time to connect and resolve your query ASAP.\nDear Partner, May I know what issue you are facing right now?"
                    ]
                },
                {
                    label: "Project on HOLD",
                    text: [
                        "Hello Partner\nSorry for your inconvenience. Project is on HOLD for some technical upgradation. Don't worry, your added leads are under verification. And your pending lead's status will next update on the mentioned date in the Task Report."
                    ]
                },
                {
                    label: "Amount not added in Wallet of verified Lead",
                    text: [
                        "Hello Partner\nApologies for the delay caused by a technical issue. Please wait for 24 hrs to get this issue resolved.\nIf you have any further questions, please feel free to ask. Your satisfaction is important to us!"
                    ]
                }
            ]
        },
        {
            label: "Leads related content",
            type: "LEADS",
            texts: [
                {
                    label: "Pending Lead - Next report Update",
                    text: [
                        "Hi Partner,\nPlease check the details of the upcoming verification date in your project’s [View Report] Screen. “Pending Lead status will next update on…” . \nIf you have any further questions, please feel free to ask. Your satisfaction is important to us!",
                    ]
                },
                {
                    label: "Pending Lead - Delay in Report",
                    text: [
                        "Hi Partner,\nSorry for the inconvenience caused. Your pending lead’s verification is not done due to some delay from the client side. Please wait until the next verification report.\nPlease check the details of the upcoming verification date in your project’s [View Report] Screen. “Pending Lead status will next update on…”. \nIf you have any further questions, please feel free to ask. Your satisfaction is important to us!",
                    ]
                },
                {
                    label: "When Rejected",
                    text: [
                        "Hi Partner,\nSorry for the inconvenience caused but your task is rejected due to [Rejection Reason]. If you have any further questions, please feel free to ask. Your satisfaction is important to us!"
                    ]
                },
                {
                    label: "Lead Expired 1",
                    text: [
                        `Hi, We appreciate your efforts. Unfortunately your lead is expired because we couldn't track it in our database. Few known reasons of expiry(Unable to track) are:
                        1. Some apps may be open in Background during onboarding (in Customer mobile).
                        2. Onboarding process was not completed in one attempt (Webpage reloaded).
                        3. Process may have been interrupted by some calls or messages.
                        4. Multiple onboarding may have completed/ attempted in the same device (One account per device).
                        5. The Customer might have already joined the project/bank through a promotional campaign.
                        Ensure these points are taken care of during your next onboarding. Your continued dedication to your next tasks means a lot to us.
                        `
                    ]
                },
                {
                    label: "Lead Expired 2",
                    text: [
                        `Payout is provided for the verified leads only. It is also mentioned in our app.
                        Please continue your work with PickMyWork.
                        We are working hard to ensure our partner’s get better services.
                        `
                    ]
                }
            ]
        },
        // {
        //     label: "Recharge",
        //     type: "RECHARGE",
        //     texts: [
        //         {
        //             label: "Payment Not Done",
        //             text: [
        //                 `Hi, The payment is not received from your end.
        //                 If your amount is deducted, It will be auto refunded into your account within 3-4 working days from your bank end.`
        //             ]
        //         },
        //         {
        //             label: "Team Checking",
        //             text: [
        //                 `Don't worry, Please wait!
        //                 Your details has been forwarded to technical team. It may take upto 24 hours. 
        //                 `
        //             ]
        //         },
        //         {
        //             label: "Refund Confirm",
        //             text: [
        //                 `Hi Partner, Your refund has already initiated from our end.
        //                 It may take upto 3-4 working days to reflect into your deducted bank account.
        //                 `
        //             ]
        //         },
        //         {
        //             label: "Refund Issue",
        //             text: [
        //                 `Your refund already processed from our end and it must be reflected into your debited bank account within 3-4 working days after transaction.
        //                 If you have any issue with this, please share your bank statement of sourced bank account so we we can consult it with our payment service.`
        //             ]
        //         },
        //         {
        //             label: "Wrong Number Recharged",
        //             text: [
        //                 `Hi, Recharge can only be refunded in case of a failure or issue related to the service. 
        //                 Since the recharge was successful, there is no option for a refund in this case. 
        //                 Please be more careful next time to avoid such mistakes.`
        //             ]
        //         }
        //     ]
        // },
        {
            label: "Other",
            type: "OTHER",
            texts: [
                {
                    label: "Transfer to Tech Team",
                    text: [
                        `Hi Partner,
                        Sorry for the inconvenience caused to you. We are discussing it with technical team, Please wait for 24 hrs to get this issue resolved.
                        If you have any further questions, please feel free to ask.
                        Your satisfaction is important to us!
                        `
                    ]
                },
                {
                    label: "Account Deletion",
                    text: [
                        `Hi Partner,
                        Sorry for the inconvenience caused. May I know why you are leaving this earning opportunity so early. 
                        Our Trainers can help you with your query regarding “How to start work”. Kindly Join webinar link [Link]. Or To deactivate your account, please do not use the app for 30 Days.`
                    ]
                }
            ]
        }
        
    ],
}

export default TICKET_CONSTANTS;
