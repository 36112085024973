import React, { Component } from "react";
import DummyImage from "../../../assets/images/dummy.jpg"
import ApiEndpoints from "../../../constants/api_endpoints";
class ImageUploadFeild extends Component {
    constructor(props) {
      super(props);
      this.state = {
        image : this.props.src || null ,
        }
      }
    componentDidUpdate(prevProps){
      if(this.props.src && prevProps.src !== this.props.src){
        if(typeof this.props.src === "string")
          this.setState({image : this.props.src})
        else {
          ApiEndpoints.Files.getFileID({file_id : this.props.src}).then(resp => {
            this.setState({image : resp.data.data.url})
          });
        }
      }
    }
    imgPreview = (type) =>{  
        var file = this.refs.file.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState({
              image: reader.result
          })
        }.bind(this);
        this.props.onChange(file)
    }

    render() {
    
        return(
           <div className={" "+(this.props.className || "")}>
              <div><img className={`${this.props.rounded && "rounded-circle"} `} alt="" src={this.state.image || DummyImage} style={{width: 120, height: 120}} /></div>
              <span className="btn btn-secondary btn-file mt-3">
                {this.props.label ||  "Upload Image"} <input id={this.props.id || "generic_img_upload"} required={!this.state.image && !!this.props.required} ref="file" type="file" multiple={false} onChange={this.imgPreview}  />
              </span>
           </div>
        );
     }
    }
    export default ImageUploadFeild;
