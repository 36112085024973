import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col  } from "reactstrap/lib";
import { Button} from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Icon , Popover} from "@material-ui/core";

import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";

import ReactDatePicker from "react-datepicker";

class TaskScheduleDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
                isOpenConfirm : false,
                isAdd : false,
                isEdit : false,
                partnerList : [],
                mappedPartnerIds: [],
                isShowPartners : false
            }
    }

    deleteSchedule = async (task_schedule_id) => {
        store.dispatch(setLoader(true))
        try{
        var body = {
            task_schedule_id
        }
        await ApiEndpoints.JobTask.inactiveTaskScheduling(body);
        this.props.refresh();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeEdit = (k, v) => {
        let { isEdit } = this.state;
        isEdit[k] = v;
        this.setState({isEdit});
    }
    onChangeAdd = (k, v) => {
        let { isAdd } = this.state;
        isAdd[k] = v;
        this.setState({isAdd});
    }

    editAddScheduling = async (type) => {
        store.dispatch(setLoader(true))
        let { isAdd, isEdit } = this.state;
        let { locationData, taskData } = this.props;

        let data = type === "EDIT" ? isEdit : isAdd;
        try{
        var body = {
            task_schedule_id: data.task_schedule_id,
            start_date: data.start_date,
            end_date: data.end_date,
            job_location_id: locationData.job_location_id,
            job_task_id : taskData.job_task_id
        }
        let resp = type === "EDIT" ?
            await ApiEndpoints.JobTask.editTaskScheduling(body)
            :
            await ApiEndpoints.JobTask.addTaskScheduling(body);
        let userBody = {
            user_id : this.state.mappedPartnerIds,
            task_schedule_id:  type === "EDIT" ?  data.task_schedule_id : resp.data.data.task_schedule_id
        }
        await ApiEndpoints.JobTask.scheduleUsers(userBody);
        this.props.refresh();
        this.setState({isAdd: false, isEdit: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    createCopiedSchedule = async (copiedData) => {
        store.dispatch(setLoader(true))
        let { locationData, taskData } = this.props;
        try{
        var selectedDate = moment(this.props.selected_date).startOf("day").toDate()
        var body = {
            start_date: moment(selectedDate).add(moment(copiedData.start_date).format("HH"), "hours").add(moment(copiedData.start_date).format("mm"), "minutes").toDate(),
            end_date: moment(selectedDate).add(moment(copiedData.end_date).format("HH"), "hours").add(moment(copiedData.end_date).format("mm"), "minutes").toDate(),
            job_location_id: locationData.job_location_id,
            job_task_id : taskData.job_task_id
        }
        let resp = await ApiEndpoints.JobTask.addTaskScheduling(body);
        let userBody = {
            user_id : copiedData.user_id,
            task_schedule_id: resp.data.data.task_schedule_id
        }
        await ApiEndpoints.JobTask.scheduleUsers(userBody);
        this.props.refresh();
        this.setState({isAdd: false, isEdit: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    fetchPartners = async (task_schedule_id) => {
        store.dispatch(setLoader(true));
        let { locationData, taskData } = this.props;
        try{
        var body = {
            job_id : taskData.job_id,
            job_location_id: locationData.ref_id,
            status: "IN_TASK"
        }
        let resp = await ApiEndpoints.Applicants.fetch(body);
        this.setState({partnerList: resp.data.data})
        if(task_schedule_id){
            let mappedUserBody = {
                task_schedule_id
            }
            let mappedUserResp = await ApiEndpoints.JobTask.fetchScheduledUsers(mappedUserBody);
            this.setState({mappedPartnerIds: mappedUserResp.data.data.map(o => o.user_id)})
        }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    copySchedule = async (schedule) => {
        let { locationData, taskData } = this.props;
        await this.fetchPartners(schedule.task_schedule_id);
        this.props.copyTaskSchedule({
            start_date: schedule.start_date,
            end_date: schedule.end_date,
            user_id: this.state.mappedPartnerIds,
            job_location_id: locationData.job_location_id
        })
    }
    render() {
        let { isAdd, isEdit, partnerList, mappedPartnerIds, isShowPartners } = this.state;
        let { schedulingData, locationData, taskData, selected_date, isCopiedSchedule } = this.props;
        return (
            <Aux>
                { schedulingData.length > 0 ?
                    schedulingData.map(scheduling => 
                    <Row noGutters >
                        {
                        isEdit.task_schedule_id === scheduling.task_schedule_id ?
                        <Col>
                            <div className="mb-0">From - <ReactDatePicker onChange={d => this.onChangeEdit("start_date", moment(moment(d).isValid()?d:selected_date).toDate())} selected={moment(scheduling.start_date).utcOffset("+05:30").toDate()} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" placeholderText="Start time"/></div>
                            <div className="mb-0">To - <ReactDatePicker onChange={d => this.onChangeEdit("end_date", moment(moment(d).isValid()?d:selected_date).toDate())} selected={moment(scheduling.end_date || undefined).utcOffset("+05:30").toDate()} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" placeholderText="End time"/></div>
                            <div className="mb-0"> 
                            <SelectList multiple value={mappedPartnerIds} onChange={(e) => this.setState({mappedPartnerIds: e.target.value})} placeholder="Partners" required>
                                    {partnerList.map(partner =>
                                        <option value={partner.user_id}>
                                            {`PMW${partner.User ? (partner.User.id||""):""}`}
                                            {" | "}
                                            {partner.User ? (partner.User.first_name||""):""}
                                        </option>
                                    )}
                            </SelectList>
                            </div>
                            <Row>
                                <Col className="pt-2 text-right">
                                    <Icon onClick={() => this.editAddScheduling("EDIT")} className="mr-3" style={{cursor: "pointer", fontSize: "13px"}}>done</Icon> 
                                    <Icon  onClick={() => this.setState({isEdit: false})} className="" style={{cursor: "pointer", fontSize: "13px"}}>close</Icon>
                                </Col>
                            </Row>
                        </Col>
                        :
                        <Col className="visible-on-hover-container p-1">
                            <div className="visible-on-hover-item" style={{position: "absolute", right: 2, top: "-10px"}}>
                                <Icon onClick={() => {this.copySchedule(scheduling)}} style={{cursor: "pointer", fontSize: "13px"}}>content_copy</Icon>
                                <Icon onClick={() => {this.fetchPartners(scheduling.task_schedule_id); this.setState({isEdit: scheduling})}} style={{cursor: "pointer", fontSize: "13px"}}>edit</Icon>
                                <Icon onClick={() => this.setState({isOpenConfirm : () => this.deleteSchedule(scheduling.task_schedule_id)})} className="ml-1" style={{cursor: "pointer", fontSize: "13px"}}>delete</Icon> 
                            </div>
                            <p className=" mb-0">From - {moment(scheduling.start_date).utcOffset("+05:30").format("hh:mm a")}</p>
                            <p className=" mb-0">To - {moment(scheduling.end_date).utcOffset("+05:30").format("hh:mm a")}</p>
                            <p className=" mb-0">
                                {scheduling.users_count ?
                                <Button onClick={(e) => {this.fetchPartners(scheduling.task_schedule_id);this.setState({isShowPartners: e.currentTarget})}} color="link" size="sm">{scheduling.users_count || 0} Partner(s)</Button> 
                                :
                               <p className=" mb-0 mt-1"><em>{"No Partners"}</em></p>
                                }
                            </p>
                            <Popover
                                id={`popover-${scheduling.task_schedule_id}`}
                                open={!!isShowPartners}
                                anchorEl={isShowPartners}
                                onClose={() => this.setState({isShowPartners : false})}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            >
                                
                               <ul className="m-3">
                                   {partnerList.filter(user => mappedPartnerIds.indexOf(user.user_id) >= 0).map(partner => 
                                    <li>
                                        {`PMW${partner.User ? (partner.User.id||""):""}`}
                                            {" | "}
                                            {partner.User ? (partner.User.first_name||""):""}
                                    </li>
                                  )}
                                </ul>

                            </Popover>
                        </Col>
                        }
                    </Row>
                    )
                    :
                    <Row>
                        {isAdd ? 
                        <Col>
                            <div className="mb-0">From - <ReactDatePicker onChange={d => this.onChangeAdd("start_date", moment(moment(d).isValid()?d:selected_date).toDate())} selected={moment(isAdd.start_date || selected_date).utcOffset("+05:30").toDate()} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" placeholderText="Start time"/></div>
                            <div className="mb-0">To - <ReactDatePicker onChange={d => this.onChangeAdd("end_date", moment(moment(d).isValid()?d:selected_date).toDate())} selected={moment(isAdd.end_date || selected_date).utcOffset("+05:30").toDate()} showTimeSelect showTimeSelectOnly timeIntervals={15} dateFormat="h:mm aa" placeholderText="End time"/></div>
                            <div className="mb-0"> 
                            <SelectList multiple value={mappedPartnerIds} onChange={(e) => this.setState({mappedPartnerIds: e.target.value})} placeholder="Partners" required>
                               
                               {partnerList.map(partner =>
                                    <option value={partner.user_id}>
                                        {`PMW${partner.User ? partner.User.id||"":""}`}
                                        {" | "}
                                        {partner.User ? partner.User.first_name||"":""}
                                    </option>
                                )}
                            </SelectList>
                            </div>
                            <Row>
                                <Col className="pt-2 text-right">
                                    <Icon onClick={() => this.editAddScheduling()} className="mr-3" style={{cursor: "pointer", fontSize: "13px"}}>done</Icon> 
                                    <Icon  onClick={() => this.setState({isAdd: false})} className="" style={{cursor: "pointer", fontSize: "13px"}}>close</Icon>
                                </Col>
                            </Row>
                        </Col>
                        :(
                        isCopiedSchedule ? 
                        <Col className="text-center">
                            <div onClick={() => {this.createCopiedSchedule(isCopiedSchedule)}} style={{background : "#f2e8e8" , height : 25 , width : 25 , borderRadius : 5 ,  marginTop : 20, cursor : "pointer", display: "inline-block"}}> <Icon style={{color : "#c6c0c0"}}>content_paste</Icon> </div>
                        </Col>
                        :
                        <Col className="text-center">
                            <div onClick={() => {this.fetchPartners(); this.setState({isAdd: {}})}} style={{background : "#f2e8e8" , height : 25 , width : 25 , borderRadius : 5 ,  marginTop : 20, cursor : "pointer", display: "inline-block"}}> <Icon style={{color : "#c6c0c0"}}>add</Icon> </div>
                        </Col>
                        )
                        }
                    </Row>
                    }

                  
                    
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

            </Aux>
            
            
        );
    }
}
export default TaskScheduleDetails;
