import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";


class UserBankDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankDetails : [],
        }
    }

    componentDidMount(){
        this.fetchBankDetails();
    }

    fetchBankDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.UserProfile.fetchBankDetails(body)
            let bankDetails = resp.data.data
            this.setState({ bankDetails })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      
    
    render() {
        let { bankDetails } = this.state;
        return (
            <div className="px-1">
                {bankDetails.length ? bankDetails.map(bank => 
                    <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2" >
                        <Col xs={9}>
                            <strong>{bank.bank_name} | {bank.ifsc_code}</strong>
                            <span className="d-block small">{bank.branch_detail}</span>
                            <span className="d-block" style={{fontWeight: 500}}>Acc No: {bank.account_no.replace(bank.account_no.substring(0, 5), "****")} <span className="small">{moment(bank.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span></span>
                        </Col>
                        <Col xs={3} className="text-right">
                            <strong className="d-block" style={{color: BANK_STATUS[bank.status].color}}>{BANK_STATUS[bank.status].label}</strong>
                            {!!bank.is_default && <span className="px-2" style={{background: "#bbb", fontSize: 12, fontWeight: 600, borderRadius: 8}}>Default</span>}
                        </Col>
                    </Row>
                ) : <em className="m-4 text-secondary d-block">No Bank details added</em> }
                     
                   
            </div>
           
        );
    }
}
export default UserBankDetails;

const BANK_STATUS = {
    0: {
        label: "Pending",
        color: "#666"
    },
    1: {
        label: "Verified",
        color: "#090"
    },
    2: {
        label: "Rejected",
        color: "#d00"
    }
}