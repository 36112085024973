import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import SelectList from "../../global/form/select"
import { copyCodeToClipboard } from "../../../actions";
import CONSTANTS from "../../../constants";
import WhatsappChatMultiple from "../../global/whatsapp_chat_multiple"
import { Chip, Icon, Badge, Switch } from "@material-ui/core";
import RecruitmentComponent from "./recruitment";
import moment from "moment";


class Shortlisting extends Component {
    constructor(props) {
      super(props);
      var params = this.props.match.params;
      this.state = {
            jobList: [],
            selectedJob: null,
            selectedJobLocations: [],
            selectedJobId: null,
            selectedJobLocationId: null,
            isMountedRecruitment : false,
            newUserChatData: [],
            eventList:[]

        }
      }

      
    async componentDidMount(){
        store.dispatch(setLoader(true))
        await this.getJob();
        store.dispatch(setLoader(false))
    }

    componentDidUpdate(prevProp, prevState){
        if(prevState.selectedJobId !== this.props.match.params.job_id) this.setJobId(this.props.match.params.job_id, true)
    }

    getJob = async () => {
        try{

            let jobList = store.getState().jobList || [];
            // let apiData = await ApiEndpoints.Jobs.fetch();
            // var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
            // jobList = jobList
            // .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
            // .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
            await this.setState({ jobList });
            if(this.props.match.params.job_id) this.setJobId(this.props.match.params.job_id, true)
            else if(this.state.jobList.length) this.setJobId(this.state.jobList[0].job_id)
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    setJobId = async (job_id, isParamsSelected) => {
        let { jobList } = this.state;
        var selectedJob = jobList.filter(obj => obj.job_id == job_id)[0];
        if(!selectedJob) return;
        await this.setState({selectedJobId : job_id, selectedJobLocationId : null, isMountedRecruitment: false})
        selectedJob.JobLocations = selectedJob.JobLocations.sort((a, b) => a.city > b.city ? 1:-1)
        this.setState({selectedJob, selectedJobLocations:  selectedJob.JobLocations});
        var jobLocationId = null;
        if(isParamsSelected && this.props.match.params.job_location_id) {
            var selectedLoc = selectedJob.JobLocations.filter(loc => loc.job_location_id == this.props.match.params.job_location_id)[0];
            jobLocationId = selectedLoc ? selectedLoc.job_location_id : null;
        }
        else if(selectedJob.JobLocations.length === 1) jobLocationId = selectedJob.JobLocations[0].job_location_id;
        this.setJobLocationId(jobLocationId);
    }

    setJobLocationId = async (jobLocationId) => {
        await this.setState({ isMountedRecruitment: false });
        const query = new URLSearchParams(this.props.location.search);
        if(!query.get("status")) query.set("status", "LEADS");
        if(jobLocationId) this.props.history.push("/recruitment/job/"+this.state.selectedJobId+"/loc/"+jobLocationId+"?"+query.toString());
        else  this.props.history.push("/recruitment/job/"+this.state.selectedJobId+"?"+query.toString());
        await this.setState({selectedJobLocationId : jobLocationId, isMountedRecruitment: true});
    }

    getReferralLink = async () => {
        store.dispatch(setLoader(true));
        try {
            var resp = await ApiEndpoints.Referral.fetchReferralCode({
                referred_by: store.getState().jobList().user.user_id,
                target: "APP",
                channel_type: "USER",
                channel_subtype: "RECRUITER"
            });
            copyCodeToClipboard(resp.data.data[0].referral_link); 
            store.dispatch(setSuccess({show: true, message: "Link Copied to your Clipboard!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    getNewUserWAChats = async () => {
        store.dispatch(setLoader(true));
        try {
            var resp = await ApiEndpoints.Whatsapp.fetchConversationByEntity({ entity_type : "NEW_USER"});
            this.setState({ newUserChatData: resp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }
    
    fetchUpcomingWebinar = async (job_id) => {
        let { user_id } = this.props;
        store.dispatch(setLoader(true))
        try{
            var body = {
               start_date_range : moment().toDate()
            }
            if(job_id) body = {...body, entity_type: "JOB", entity_id: job_id}
            else body = {...body, entity_type: "GENERAL"}
               
            let resp = await ApiEndpoints.Events.fetchEvents(body);

            this.setState({ eventList: resp.data.data.rows, isOpenUpcomingWebinar: true })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render() {
        let { jobList, selectedJobLocations, selectedJobId, selectedJob, selectedJobLocationId,isLoadingInfo,eventList} =this.state;
        return (
       <div>
        {!this.props.showOnlyStatus && 

            <Row className="mb-4 text-align-center">
                <Col xs={7}>
                    <Row>
                        <Col>
                            <SelectList search={true} value={this.state.selectedJobId} onChange ={(e) => this.props.history.push(`/recruitment/job/${e.target.value}`)} >                    
                             { jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                 {/* {job.JobLocations.length ? " ( " : ""} {job.JobLocations.map(obj => obj.city).join( ", ")}  {job.JobLocations.length ? " ) " : ""} */}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                             </option>)}
                            </SelectList>
                        </Col>
                        <Col>
                            <SelectList search={true} placeholder="Select Location" selectAll="All Locations" value={this.state.selectedJobLocationId} onChange ={(e) => this.setJobLocationId(e.target.value)} >
                                {selectedJobLocations.map(loc => 
                                <option key={loc.job_location_id} value={loc.job_location_id}> 
                                    {loc.city} 
                                    {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                                </option>
                                )}
                            </SelectList>
                        </Col>
                    </Row>
                </Col>
                <Col xs={5} className="text-right">
                <Button   size="small" style={{borderColor: "#F32013", color: "#FFFFFF", fontSize: 12}} color="danger" variant="outlined" onClick={() => this.fetchUpcomingWebinar(selectedJobId) } >Upcoming Webinar</Button>
               
                </Col>
                <Modal size={"lg"} isOpen={!!this.state.isOpenUpcomingWebinar} toggle={()=> {this.setState({isOpenUpcomingWebinar : false})}} >  
                   <ModalHeader toggle={()=> {this.setState({isOpenUpcomingWebinar : false})}}><b>Upcoming Webinar</b></ModalHeader>
                   <ModalBody  className="">
                   {!!this.state.isOpenUpcomingWebinar &&!isLoadingInfo &&
               <div job_id={selectedJobId}>
                 
                     {
                         !eventList.length ? <em>No Upcoming webinar</em>
                         :
                         <Row noGutters className="flex-nowrap" style={{overflowY: "scroll"}}>
                             {eventList.map(event => 
                                 <Col xs={10} lg={5}>
                                     <div className="shadow-sm p-2 m-1" style={{borderRadius: 12, fontSize: 14}}>
                                         <Row noGutters className="align-items-center">
                                             <Col>
                                                 <span className="d-block font-weight-bold">{event.title}</span>
                                                 <span>{moment(event.start_date).utcOffset("+5:30").format("Do MMM'YY - hh:mm A")}</span>
                                             </Col>
                                         </Row>
                                     </div>
                                 </Col>
                             )}
                         </Row>
         
                     }
                   </div>
                 }
                   </ModalBody>
               </Modal>             
                
                <Col className="text-right">
                    { selectedJob && selectedJob.job_category === "WALKIN" && this.state.selectedJobLocationId ? 
                    <Button size="sm" className="buttonred" title="Copy URL To Clipboard" onClick={()=> copyCodeToClipboard(process.env.REACT_APP_PMWJOBS_URL+"application/"+Buffer.from(this.state.selectedJobId.toString()).toString('base64') + "/"+Buffer.from(this.state.selectedJobLocationId.toString()).toString('base64') + "/" + Buffer.from(store.getState().jobList().user.user_id).toString('base64'))}>Copy Project URL</Button> : null} 
                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={true}>
                        <Button size="sm" type="button" color="success" className="ml-3" style={{background: "#00990030",color: "#050"}} onClick={this.getNewUserWAChats}>
                            <Icon style={{color: "#050", fontSize: 14, paddingTop: 1}}>whatsapp</Icon> New Users
                        </Button>
                    </Badge> */}
                    {/* <Button size="sm" onClick={this.getReferralLink} type="button"  className="buttonred ml-3">Get Invite Link</Button> */}
                </Col>
            </Row>
    }
            
            {!!this.state.isMountedRecruitment && 
            <RecruitmentComponent 
                jobList={jobList} 
                selectedJobId={selectedJobId}
                selectedJob={selectedJob}
                selectedJobLocations={selectedJobLocations}
                selectedJobLocationId={selectedJobLocationId}
            />}
              
            {!!this.state.newUserChatData.length && <WhatsappChatMultiple selectedJob={selectedJob} users={this.state.newUserChatData} isOpen={!!this.state.newUserChatData.length} onClose={() => this.setState({ newUserChatData: [] })}/>}
        </div>
        );
    }
}

export default Shortlisting;
