import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, Collapse } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ReactDatePicker from "react-datepicker";
import InputFeild from "../../global/form/input";
import ImageUploadFeild from "../../global/form/upload-image";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";







class CreateBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isCreateBanner: false,
          bannerList: [],
          isOpenConfirm: false,
          bannerFile: null,
          draggedBannerIndex: null
          }
         }

        handleDragInput = (e) => {
            this.setState({draggedBannerIndex: e.currentTarget.id});
        }
    
        handleDropInput = async (e) => {
            let { draggedBannerIndex } = this.state;
            let droppedBannerIndex = e.currentTarget.id;
            if(!draggedBannerIndex || !droppedBannerIndex) return; 

            let banners = [...this.state.bannerList];

            let draggedBanner = banners.splice(draggedBannerIndex, 1);

            if(!draggedBanner[0]) return;

            banners.splice(droppedBannerIndex, 0, draggedBanner[0])
            
            banners = banners.map((banner, i) => {
                banner.seq_no = i + 1;
                return banner;
            });
            let updateData = banners.filter((banner, i) => i >= draggedBannerIndex && i <= droppedBannerIndex).map(banner => ({promotion_id: banner.promotion_id, seq_no: banner.seq_no}));
            await Promise.all(updateData.map(async banner => {
                await ApiEndpoints.Marketing.updateBanner(banner)
            }));
            this.setState({ bannerList: [...banners] })
        }
        
        
        componentDidMount(){
            this.fetchBannerList();
            }
    
        fetchBannerList = async() => {
                store.dispatch(setLoader(true))
                try{
                    let apiData = await ApiEndpoints.Marketing.fetchBanners();
                    let bannerList = apiData.data.data;
                    this.setState({ bannerList })
                }
                catch(e){
                    store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
                }
                store.dispatch(setLoader(false));
    
            }
       
        delete = async(promotion_id) => {
                store.dispatch(setLoader(true))
                try{
                    await ApiEndpoints.Marketing.deleteBanner({promotion_id});
                    await this.fetchBannerList();
                }
                catch(e){
                    store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
                }
                store.dispatch(setLoader(false))
            }

        createBanner = async (e) => {
                e.preventDefault();
                let file = this.state.bannerFile;
                let fileName = "promotions/banner/"+ new Date().getTime() + "_" + file.name;
                let fileResp = await ApiEndpoints.Files.filePublicPutUrl({file_name: fileName});
                let file_url = fileResp.data.data.url;
                if (!file_url) throw new Error("Unable to upload file. Please try again later.");
                var config = { headers: { "Content-Type": file.type }}
                await ApiEndpoints.Files.uploadFileToS3(file_url, file, config);
                


                let bannerData = {
                   "purpose": this.state.purpose,
                   "content_type":"IMAGE",
                   "title": this.state.title,
                   "poster_url":  fileResp.data.data.fileUrl,
                   "seq_no": this.state.bannerList.length + 1,
                    "start_date": this.state.start_date,
                    "end_date": this.end_date,
                    "action": `{ "type": "SCREEN", "origin_type": "JOB_CATEGORY"}`
                //    "location": "",
                 };
                 store.dispatch(setLoader(true));
                 await ApiEndpoints.Marketing.createBanner(bannerData);
                 await this.fetchBannerList();
                 await this.setState({isCreateBanner:false})
                 store.dispatch(setLoader(false));
           }


    render() {
        let { isCreateBanner, bannerList } = this.state;
        return (
            <>
                <form onSubmit={this.createBanner}>
                    <Row className="mb-3">
                            <Col style={{marginRight: "-27px"}}>
                                <Button id="Create_New_Banner" onClick={() => this.setState({isCreateBanner : isCreateBanner ? false : {}})} type="button" className="buttonred" size="xs" style={{ height: "27px", paddingTop: "3px"}}>Create New Banner</Button>
                                <Row className={"my-3 border-bottom align-items-center bg-light  pt-2"}>
                                </Row>
                            <Collapse className="card p-3" isOpen={!!isCreateBanner}>
                            <Row className="mt-3 align-items-center">
                                <Col xs={6}><InputFeild   placeholder="Title" value={isCreateBanner.title || ""} onChange={(e) => { isCreateBanner.title=e.target.value; this.setState({ isCreateBanner }) }}/></Col>
                                 <Col xs={6}><InputFeild  placeholder="Purpose" value={isCreateBanner.purpose || ""} onChange={(e) => { isCreateBanner.purpose=e.target.value; this.setState({ isCreateBanner}) }}/></Col>
                            </Row>
                                <Row>
                                    <Col className="mt-3">
                                             <ReactDatePicker
                                                maxDate={isCreateBanner ? isCreateBanner.end_date : null}
                                                selected={isCreateBanner ? isCreateBanner.start_date : null}
                                                onChange={date => {isCreateBanner.start_date = date; this.setState(isCreateBanner)}}
                                                value={isCreateBanner.start_date}
                                                isClearable
                                                dateFormat='dd-MMM-yyyy'
                                                id="start_date"
                                                placeholderText="Start Date"
                                            />
                                    </Col>
                                    <Col className="mt-3">
                                            <ReactDatePicker
                                        
                                                minDate={isCreateBanner ? isCreateBanner.start_date : null}
                                                selected={isCreateBanner ? isCreateBanner.end_date : null}
                                                onChange={date => {isCreateBanner.end_date = date; this.setState(isCreateBanner)}}
                                                value={isCreateBanner.end_date}
                                                isClearable
                                                dateFormat='dd-MMM-yyyy'
                                                id="end_date"
                                                placeholderText="End Date"
                                            />
                                    </Col>
                                </Row>
                                <Row className="mt-3 align-items-center"> 
                                <Col className="text-center">
                                <ImageUploadFeild  src={""} className="" label="Upload Banner Image" onChange={(file) => this.setState({bannerFile : file})}
                                />
                               </Col>
                               </Row>
                                <Row><Col className="text-right"><Button className="buttonred m-2 py-2">Create</Button>
                                   <Button onClick={() => this.setState({isCreateBanner : false})} className="buttonred m-2 py-2">Cancel</Button></Col></Row>

                            </Collapse>
                            </Col>
                            
                    </Row>
                </form>

            <Row className="py-2 m-0 small my-3 font-weight-bold bg-secondary text-light align-items-center" >
                                        <Col xs={2}><strong>Purpose</strong></Col>
                                        <Col xs={3}><strong>Title</strong></Col>
                                        <Col xs={2}><strong>Image</strong></Col>
                                        <Col xs={2}><strong>Start Date</strong></Col>
                                        <Col xs={2}><strong>End Date</strong></Col>
                                        
            </Row>

            {bannerList && bannerList.length ? 
                   <div>
                        {bannerList
                        .map((list, i) => 
                                <Row id={i} className="border-bottom m-0 py-2 my-1 align-items-center" style={{fontSize: 13}} draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={this.handleDragInput} onDrop={this.handleDropInput} >
                                    <Col xs={2}><span>{list.purpose} - {list.promotion_id}</span></Col>
                                    <Col xs={3}><span>{list.title}</span></Col>
                                    <Col xs={2}>
                                     <img className="square ml-2" src={[list.poster_url]} alt="" style={{width: "75%", marginTop: "-10px"}} />
                                    </Col>
                                    <Col xs={2}><span className="d-block" style={{fontSize: 14}}>{moment(list.start_date).utcOffset("+05:30").format("D MMM'YY")} </span></Col>
                                    <Col xs={2}><span className="d-block" style={{fontSize: 14}}>{moment(list.end_date).utcOffset("+05:30").format("D MMM'YY")}</span></Col>
                                    <Col xs={1.5} className="text-right"> 
                                        <Icon>reorder</Icon> 
                                        <Icon className="align ml-3" style={{cursor: "pointer", fontSize: 20}} onClick={()=> {this.setState({isOpenConfirm :  () => this.delete(list.promotion_id) })}} >delete</Icon>
                                    </Col>
                                </Row>
                            )
                        } 
                   </div>
                    : 
                    <em className="d-block m-3 text-center text-muted">No Data</em>
                }      

                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />
         
            </>
        )}};
    
    export default CreateBanner;




   

