
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputField from "../../global/form/input";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import { Button, Icon, Input } from "@material-ui/core";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import DummyCompanyImage from "../../../assets/images/company-default.png";
import InvoicePreview from "./invoice_preview";

class InvoiceCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientList: [],
            invoiceData: {invoice_date: moment().toDate(), items: [] },
            selectedClient: {},
            clientInvoices: [],
            isPreview: false
        };
    }


    componentDidMount(){
        this.fetchClients();
    }

    fetchClients = async() => {
        store.dispatch(setLoader(true))
        try{
            let body = {};
            let apiData = await ApiEndpoints.Invoice.fetchClients(body);
            let clientList = apiData.data.data;
            this.setState({ clientList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchClientInvoices = async() => {
        store.dispatch(setLoader(true))
        try{
            let { selectedClient } = this.state;
            let body = { client_id: selectedClient.client_id };
            let apiData = await ApiEndpoints.Invoice.fetchInvoice(body);
            let clientInvoices = apiData.data.data;
            this.setState({ clientInvoices })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    onChangeInvoiceData = (k, v) => {
        let { invoiceData, clientInvoices } = this.state;
        if(k === "dates"){
            if(v === null) invoiceData.start_date = invoiceData.end_date = null;
            else if(invoiceData.start_date) invoiceData.end_date = moment(v).endOf("day").toDate();
            else {
                invoiceData.start_date = moment(v).startOf("day").toDate();
                invoiceData.end_date = moment(v).endOf("day").toDate();
            }
        }
        else invoiceData[k] = v;

        if(k === "isCreditNote" && v) this.fetchClientInvoices();
        if(k === "credit_note_invoice_id") {
            let creditNotInvoice = clientInvoices.filter(invoice => invoice.invoice_id === parseInt(v))[0];
            invoiceData.creditNoteInvoice = creditNotInvoice;
        }
        this.setState({ invoiceData });
    }

    selectClient = (clientId) => {
        let { clientList } = this.state;
        let selectedClient = clientList.filter(client => client.client_id === parseInt(clientId))[0];
        this.onChangeInvoiceData("client_id", selectedClient.client_id);
        this.getClientJobs(selectedClient.client_id)
        this.setState({ selectedClient });
    }

    getClientJobs = async (clientId) => {
        store.dispatch(setLoader(true));
        let { invoiceData } = this.state;
        try{
            let apiData = await ApiEndpoints.Jobs.fetch({client_id: clientId});
            var jobList = apiData.data.data;
            invoiceData.items = jobList.map(job => {
                return {
                    entity_type: "JOB",
                    entity_id: job.job_id,
                    item_name: job.Employer.employer_name + " - " + job.title, 
                    isActive: true,
                    quantity: 0,
                    rate_per_item: 0,
                    amount: 0
                };
            });
            invoiceData.taxable_amount = 0;
            await this.setState({ jobList, invoiceData })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    onChangeInvoiceItem = (i, k, v) => {
        let { invoiceData } = this.state;
        invoiceData.items[i][k] = v;
        invoiceData.items[i].amount = (invoiceData.items[i].quantity || 0) * (invoiceData.items[i].rate_per_item || 0 )
        invoiceData.taxable_amount = invoiceData.items.filter(o => o.isActive).reduce((total, item) => {
           total = total + (item.quantity || 0) * (item.rate_per_item || 0);
           return total;
        }, 0);
        this.setState({ invoiceData });
    }

    previewInvoice = () => {
        this.setState({ isPreview: !this.state.isPreview  })
    }
    
    onInvoiceAdd = () => {
        this.props.onClose(true);
    }

    appendNewInvoiceItem = (item, i) => {
        let { invoiceData } = this.state;
        invoiceData.items.splice(i+1, 0, {...item, rate_per_item: 0, quantity: 0});
        this.setState({ invoiceData })
    }
  render() {
    let { clientList, invoiceData, selectedClient, clientInvoices, isPreview } = this.state;

    return isPreview ? <InvoicePreview invoiceData={invoiceData} selectedClient={selectedClient} onInvoiceAdd={this.onInvoiceAdd} onClose={this.previewInvoice} /> : (
    <div className="h-100" style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>

        <main className="h-100 p-4" style={{ overflowY: "auto", fontSize: 12 }}>

            <strong className="">New Invoice</strong>

            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Client</span></Col>
                <Col xs={7}>
                    <SelectList search placeholder="Select Client" value={invoiceData.client_id} onChange={({target}) => this.selectClient(target.value)}>
                        {clientList.map(client => <option key={client.client_id} value={client.client_id}>{client.client_name}</option>)}
                    </SelectList>
                </Col>
                {invoiceData.client_id && 
                <Col xs={3}>
                    <input className="mr-3 align-middle" type="checkbox" value={invoiceData.isCreditNote}  onChange={(e) => this.onChangeInvoiceData("isCreditNote", e.target.checked) } />
                    <span>Its a credit note ?</span>
                </Col>
                }
            </Row>
            {
                !!invoiceData.isCreditNote && 
                <Row className="mt-2 align-items-center">
                    <Col xs={2}><span className="">Reference Invoice</span></Col>
                    <Col xs={7}>
                        <SelectList search placeholder="Select Reference Invoice" value={invoiceData.credit_note_invoice_id} onChange={(e) => this.onChangeInvoiceData("credit_note_invoice_id", e.target.value)}>
                            {clientInvoices.map(invoice => <option key={invoice.invoice_id} value={invoice.invoice_id}>{invoice.serial_number} (&#x20B9;{invoice.total_amount/100})</option>)}
                        </SelectList>
                    </Col>
                </Row>
            }
            <Row className="mt-3 align-items-center">
                <Col xs={9}>
                    <Row className=" align-items-center" noGutters>
                        <Col xs={3}><span className="">Bill To</span></Col>
                        <Col xs={9}><InputField disabled placeholder="Business Name" value={selectedClient.business_name} /></Col>
                    </Row>
                    <Row className="mt-3 align-items-center" noGutters>
                        <Col xs={3}><span className="">Address</span></Col>
                        <Col xs={9}><InputField disabled placeholder="Business Address" value={selectedClient.address+", "+selectedClient.state} /></Col>
                    </Row>
                </Col>
                <Col xs={3}><img style={{width: "75%"}} src={selectedClient.logo_url || DummyCompanyImage} alt="company" /></Col>
            </Row>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Duration</span></Col>
                <Col xs={4}>
                    <ReactDatePicker
                        selected={invoiceData.start_date || null}
                        onChange={(dates) => this.onChangeInvoiceData("dates", dates)}
                        startDate={invoiceData.start_date || null}
                        endDate={invoiceData.end_date || null}
                        minDate={invoiceData.start_date ? moment(invoiceData.start_date).toDate() : false}
                        // shouldCloseOnSelect={!(!invoiceData.start_date || Boolean(invoiceData.start_date && invoiceData.end_date))}
                        selectsRange={true}
                        isClearable={true}
                        placeholderText="Select Date"
                        customInput={<div><input value={`${invoiceData.start_date ? moment(invoiceData.start_date).format("Do MMM'YY") : ""} - ${invoiceData.end_date ? moment(invoiceData.end_date).format("Do MMM'YY") : ""}`} /></div>}
                    />
                </Col>
                <Col xs={2}><span className="">Created On</span></Col>
                <Col xs={4}>
                    <ReactDatePicker
                        selected={invoiceData.invoice_date || null}
                        onChange={date => this.onChangeInvoiceData("invoice_date", date)}
                        dateFormat='dd-MMM-yyyy'
                        placeholderText="Select Date"
                        isClearable
                    />
                </Col>
            </Row>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">GSTIN</span></Col>
                <Col xs={4}><InputField disabled placeholder="GST Code" value={selectedClient.gst_no} /></Col>
                <Col xs={2}><span className="">HSN Code</span></Col>
                <Col xs={4}><InputField disabled placeholder="HSN Code" value={"998399"} /></Col>
            </Row>
            {/* <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Place of Supply</span></Col>
                <Col xs={4}><InputField disabled placeholder="State" value={selectedClient.state} /></Col>
                <Col xs={2}><span className="">Contact</span></Col>
                <Col xs={4}><InputField disabled placeholder="Contact" value={selectedClient.contact} /></Col>
            </Row>
            */}
            <Row className="px-4 mt-3 align-items-center">
                <Col xs={8}><span className="">Select Projects</span></Col>
                <Col xs={2}><span className="">Unit Cost</span></Col>
                <Col xs={2}><span className="">Onboardings</span></Col>
            </Row>
            <div className="mt-2 px-3 py-2 border bg-light shadow-sm" style={{borderRadius: 12, height: 200, overflowY: "auto", overflowX: "hidden"}}>
                {invoiceData.items.map((item, i) => 
                    <Row key={i} className="mt-2 align-items-center" style={{ ...( item.isActive ? {} : { opacity: 0.5 } ) }}>
                        <Col xs={1} className="px-1">
                            <input checked={item.isActive} type="checkbox" className="align-middle mr-1" onChange={(e) => this.onChangeInvoiceItem(i, "isActive", e.target.checked)} />
                            <Icon fontSize="small" className="align-middle text-secondary clickable" onClick={() => this.appendNewInvoiceItem(item, i)}>add</Icon>
                        </Col>
                        <Col xs={7}>
                            <InputField disabled={!item.isActive} inputProps={{maxLength: 255}} value={item.item_name} onChange={(e) => this.onChangeInvoiceItem(i, "item_name", e.target.value)} className="" />
                        </Col>
                        <Col xs={2}>
                            <InputField disabled={!item.isActive} inputmode="numeric" value={item.rate_per_item} onChange={(e) => this.onChangeInvoiceItem(i, "rate_per_item", parseInt(e.target.value) || 0)}/>
                        </Col>
                        <Col xs={2}>
                            <InputField disabled={!item.isActive} inputmode="numeric" value={item.quantity} onChange={(e) => this.onChangeInvoiceItem(i, "quantity", parseInt(e.target.value) || 0)}/>
                        </Col>
                    </Row>
                )}
            </div>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Total Amount</span></Col>
                <Col xs={4}><InputField disabled placeholder="10000" value={invoiceData.taxable_amount || 0} /></Col>
                {/* <Col xs={2}><span className="">Due Date</span></Col>
                <Col xs={2}>
                    <ReactDatePicker
                        selected={invoiceData.expiry_date || null}
                        onChange={date => this.onChangeInvoiceData("expiry_date", date)}
                        placeholder="Select Date"
                        dateFormat='dd-MMM-yyyy'
                        isClearable
                    />
                </Col> */}
                <Col xs={6}><input className="align-middle" type="checkbox" checked={invoiceData.tax_reverse_charge} onChange={(e) => this.onChangeInvoiceData("tax_reverse_charge", e.target.checked)} /> <span>Whether Tax payable under reverse charge</span></Col>
            </Row>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Email To</span></Col>
                <Col xs={4} title={selectedClient.email}><InputField disabled value={selectedClient.email} placeholder="Client Email" /></Col>
                <Col xs={6}><input className="align-middle" type="checkbox" checked={invoiceData.isIncludeSign} onChange={(e) => this.onChangeInvoiceData("isIncludeSign", e.target.checked)} /> <span>Is include signature in Invoice?</span></Col>
                {/* <Col xs={2}><span className="">Our GSTIN</span></Col> */}
                {/* <Col xs={4}>
                    <SelectList search placeholder="Select our GST" value={invoiceData.primaryGST} onChange={(e) => this.onChangeInvoiceData("primaryGST", e.target.value)}>
                        <option value={1}>Delhi - 07AAICR3777Q1ZG</option>
                        <option value={2}>Haryana - 06AAICR3777Q1ZI</option>
                    </SelectList>
                </Col> */}
            </Row>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Email CC</span></Col>
                <Col xs={10}><InputField value={invoiceData.cc} onChange={(e) => this.onChangeInvoiceData("cc", e.target.value)}  placeholder="Add multiple email IDs with comma" /></Col>
            </Row>
            <Row className="mt-3 align-items-center">
                <Col xs={2}><span className="">Attachment File</span></Col>
                <Col xs={4}><input type="file" value={invoiceData.attachment_file && invoiceData.attachment_file.file_name} onChange={(e) => this.onChangeInvoiceData("attachment_file", e.target.files[0])}  placeholder="Add Attachment File" /></Col>
            </Row>
        </main>
        <footer style={{flex: 0, position: "sticky", bottom: 0, background: "#fff", marginLeft: -15, marginRight: -15}} className="px-3 w-100">
            <Row className="align-items-center justify-content-end py-3 border-top mt-3">
              <Col xs={3}>
                <Button className="w-100 text-danger border-danger" variant="outlined" color="secondary" style={{borderRadius: 25}} onClick={() => this.props.onClose()}>Cancel</Button>
              </Col>
              <Col xs={3}>
                <Button disabled={!invoiceData.taxable_amount} onClick={() => this.previewInvoice()} className="w-100 bg-danger" variant="contained" color="secondary" style={{borderRadius: 25}}>Preview</Button>
              </Col>
            </Row>
        </footer>
                    
        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

    </div>
    )
  }


}


export default InvoiceCreate;
