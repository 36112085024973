
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import ConfirmAction from "../../global/confirm-action";
import { Button, Icon } from "@material-ui/core";
import moment from "moment";
import { getAmountIntoWords, uploadFile } from "../../../actions";
import { invoiceSignBase64 } from '../../../assets/images/invoice-sign'; // Adjust the path as needed
import QRCode from "react-qr-code";


class InvoicePreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedClient: {},
            invoiceData: this.props.invoiceData
        };
    }


    componentDidMount(){
        this.fetchClient();
    }

    fetchClient = async() => {
        store.dispatch(setLoader(true))
        let { invoiceData } = this.state;
        try{
            let apiData = await ApiEndpoints.Invoice.fetchClients({client_id: invoiceData.client_id, fetchLogoBase64: true});
            let clientList = apiData.data.data;
            this.setState({ selectedClient: clientList[0] || {} })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    getRoundOffAmount = (amount) => {
        amount = amount + (amount * 18 / 100);
        let floatValue = amount % 1;
        return parseFloat(floatValue > 0 ? 1-floatValue : 0).toFixed(2);;
    }
   
    generatePdf = async (isOnlyDownload = false) => {
        // store.dispatch(setLoader(false));
        let { selectedClient } = this.state;
        try{
            var element = document.getElementById('element-to-print');
            let html2Pdf = require("html2pdf.js");
            var opt = {
                margin: 0,
                filename: 'invoice.pdf',
                image: { type: 'jpeg', quality: 1 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
            };
            html2Pdf().set(opt).from(element).save();
            if(!isOnlyDownload){
                let pdfData = await html2Pdf().set(opt).from(element).toPdf().output('blob');
                let fileBody = {file_name: "pmw_invoice_RSPL_C"+selectedClient.client_id+".pdf", file_purpose : "INVOICE"};
                let fileResp = await ApiEndpoints.Files.filePutUrl(fileBody);
                let file_url = fileResp.data.data.url;
                var config = {headers: { "Content-Type": "application/pdf" }}
                await ApiEndpoints.Files.uploadFileToS3(file_url, pdfData, config);
                fileBody.file_key = fileResp.data.data.key;
                var resp = await ApiEndpoints.Files.generateFileID(fileBody);
                return resp.data.data;
            }
        }
        catch(e){
            console.log(e);
            store.dispatch(setError({show: true, message: "Not able to generate PDF"}))
        }
        // store.dispatch(setLoader(false))
    }

    generateInvoice = async () => {
        let { invoiceData } = this.state;
        store.dispatch(setLoader(true))
        try{
            let { client_id, invoice_date, start_date, end_date, taxable_amount, tax_reverse_charge, expiry_date, credit_note_invoice_id, items, cc, attachment_file } = invoiceData;
            // let fileData = await this.generatePdf();
            let invoiceBody = {
                client_id,
                invoice_date: invoice_date ? moment(invoice_date).toDate() : moment().toDate(),
                start_date: moment(start_date).startOf("day").toDate(),
                end_date: moment(end_date).endOf("day").toDate(),
                taxable_amount,
                tax_reverse_charge: tax_reverse_charge ? 1 : 0,  
                cc: cc ? cc.split(",").map(v => v.trim()).filter(v => !!v) : []              
            };
            if(expiry_date) invoiceBody.expiry_date = expiry_date;
            if(credit_note_invoice_id) invoiceBody.credit_note_invoice_id = invoiceData.credit_note_invoice_id;
            invoiceBody.items = items.filter(o => o.isActive).map(item => {
                return {
                    entity_type: item.entity_type,
                    entity_id: item.entity_id,
                    item_name: item.item_name, 
                    quantity: item.quantity, 
                    rate_per_item: item.rate_per_item,
                    amount: item.amount
                }
            });

            if(attachment_file && typeof attachment_file === "object"){
                let fileResp = await uploadFile(attachment_file, "INVOICE_DATA");
                invoiceBody.attachment_file_id = fileResp.file_id;
            }

            let invoiceResp = await ApiEndpoints.Invoice.addInvoice(invoiceBody);
            invoiceResp = invoiceResp.data.data;
            await this.setState({ 
                invoiceData: {
                    ...invoiceData, 
                    invoice_id: invoiceResp[0].invoice_id, 
                    serial_number: invoiceResp[0].serial_number,
                    irn_no: invoiceResp[0].irn_no,
                    einvoice_response: invoiceResp[0].einvoice_response
                } 
            });
            let fileData = await this.generatePdf();
            await ApiEndpoints.Invoice.sendInvoiceMail({
                invoice_id: invoiceResp[0].invoice_id, 
                invoice_file_id: fileData.file_id,
                attachment_file_id: invoiceBody.attachment_file_id,
                sendMail: true,
                cc: cc ? cc.split(",").map(v => v.trim()).filter(v => !!v) : []
            });
            this.props.onInvoiceAdd()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

  render() {
    let { selectedClient, invoiceData } = this.state;
    if(invoiceData.irn_no && invoiceData.einvoice_response){
        invoiceData.SignedQRCode = JSON.parse(invoiceData.einvoice_response).SignedQRCode
    }
    return (
    <div className="" style={{width: "100%", display: "flex", flexDirection: "column"}}>

        <main id="element-to-print"  className="py-1" style={{height: 1100, overflowY: "auto", fontSize: 12, overflowX:"hidden", position: "relative", letterSpacing: "0.01px" }}>

            <Row className="align-items-center px-4 py-3">
                <Col><img width="150" src="/images/PMW_new_ 800X300.png" alt="" /></Col>
                <Col className="text-right"><span style={{fontSize: 24}}>{invoiceData.credit_note_invoice_id ? "CREDIT NOTE" : "TAX INVOICE"}</span></Col>
            </Row>
            <Row className="px-4 py-2 bg-light" noGutters>
                <Col xs={2}><span>Invoice No</span></Col>
                <Col xs={5}><span>{invoiceData.serial_number || "######"}</span></Col>
                <Col xs={2}><span>Dated</span></Col>
                <Col xs={3} className="text-right"><span>{moment(invoiceData.invoice_date).format("DD MMM'YY")}</span></Col>
                <Col xs={2}><span>Place of Supply</span></Col>
                <Col xs={5}><span>{selectedClient.state}</span></Col>
                <Col xs={2}><span>Duration</span></Col>
                <Col xs={3} className="text-right"><span>{moment(invoiceData.start_date).format("DD MMM'YY")} - {moment(invoiceData.end_date).format("DD MMM'YY")}</span></Col>
                {!!invoiceData.irn_no &&
                    <>
                        <Col xs={2}><span>IRN No</span></Col>
                        <Col xs={5}><span>{invoiceData.irn_no || "######"}</span></Col>
                    </>
                }
            </Row>
            <Row className="px-4 align-items-center">
                <Col xs={3}>
                    {!!invoiceData.irn_no && !!invoiceData.einvoice_response && 
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "75%", width: "75%" }}
                            value={JSON.parse(invoiceData.einvoice_response).SignedQRCode}
                            viewBox={`0 0 256 256`}
                        />
                    }
                </Col>
                <Col xs={2} className="text-center"><span>Bill To</span></Col>
                <Col xs={5}>
                    <span className="d-block font-weight-bold">{selectedClient.business_name}</span>
                    <span className="d-block">{selectedClient.address}</span>
                    <span className="d-block">{selectedClient.state}</span>
                    <span>GSTIN No: {selectedClient.gst_no}</span>
                </Col>
                <Col xs={2} className="text-center" style={{minHeight: 70}}>
                    {selectedClient.logo_url && <img id="clientLogo" style={{width: "95%"}} src={'data:image/jpeg;base64,'+selectedClient.logo_url} alt="client" />}
                </Col>
            </Row>
            <Row className="bg-light mt-2 px-4 py-2 font-weight-bold">
                <Col xs={5}><span>Business Promotion Support Services</span></Col>
                <Col xs={2}><span>HSN</span></Col>
                <Col xs={1}><span>Qty</span></Col>
                <Col xs={2}><span>Rate</span></Col>
                <Col xs={2} className="text-right"><span>Amount</span></Col>
            </Row>
            {invoiceData.items.filter(o => o.isActive).map((item, i) => 
                <Row key={i} className="px-4 py-2 border-bottom">
                    <Col xs={5}><span>{item.item_name}</span></Col>
                    <Col xs={2}><span>998399</span></Col>
                    <Col xs={1}><span>{item.quantity}</span></Col>
                    <Col xs={2}><span>&#x20B9;{item.rate_per_item}</span></Col>
                    <Col xs={2} className="text-right"><span>&#x20B9;{item.amount}</span></Col>
                </Row>
            )}
            <Row className="px-4 py-3 border-bottom font-weight-bold">
                    <Col xs={10}><span>Taxable Amount</span></Col>
                    <Col xs={2} className="text-right"><span>&#x20B9;{invoiceData.taxable_amount}</span></Col>
            </Row>
            <Row className="px-4 border-bottom">
                <Col xs={7} className="align-content-center">
                    <span>- Please deduct TDS @ 2%</span>
                    <span className="d-block">- Whether Tax payable under reverse charge: {invoiceData.tax_reverse_charge ? "Yes":"No"}</span>
                    {!!invoiceData.credit_note_invoice_id && <span className="d-block">- This is a credit note for reference invoice: {invoiceData.creditNoteInvoice && invoiceData.creditNoteInvoice.serial_number}</span>}
                </Col>
                <Col xs={5} className="border-left">
                    <Row className="bg-light py-2">
                        <Col><span>CGST (9%)</span></Col>
                        <Col className="text-right"><span>&#x20B9;{selectedClient.state === (invoiceData.primaryGST === 1 ? "Delhi" : "Haryana") ? (invoiceData.taxable_amount || 0) * 9 / 100 : 0}</span></Col>
                    </Row>
                    <Row className="py-2">
                        <Col><span>SGST (9%)</span></Col>
                        <Col className="text-right"><span>&#x20B9;{selectedClient.state === (invoiceData.primaryGST === 1 ? "Delhi" : "Haryana") ? (invoiceData.taxable_amount || 0) * 9 / 100 : 0}</span></Col>
                    </Row>
                    <Row className="bg-light py-2">
                        <Col><span>IGST (18%)</span></Col>
                        <Col className="text-right"><span>&#x20B9;{selectedClient.state !== (invoiceData.primaryGST === 1 ? "Delhi" : "Haryana") ? (invoiceData.taxable_amount || 0) * 18 / 100 : 0}</span></Col>
                    </Row>
                    <Row className="py-2">
                        <Col><span>Round Off</span></Col>
                        <Col className="text-right"><span>&#x20B9;{this.getRoundOffAmount(invoiceData.taxable_amount || 0).toString()}</span></Col>
                    </Row>
                </Col>
            </Row>
            <Row className="px-4">
                <Col xs={5}>
                    <span className="d-block my-1">Account Name: Reculta Solutions Pvt. Ltd.</span>
                    <span className="d-block my-1">Account Number: 50200026132261</span>
                    <span className="d-block my-1">IFSC Code: HDFC0004109</span>
                </Col>
                <Col xs={4}>
                    <span className="d-block my-1 font-weight-bold">Total Amount</span>
                    <span className="d-block my-1 small" style={{textTransform: "capitalize"}}>{getAmountIntoWords(Math.ceil(invoiceData.taxable_amount + (invoiceData.taxable_amount * 18 / 100)))}</span>
                    <span className="d-block my-1">Due Date: {invoiceData.expiry_date ? moment(invoiceData.expiry_date).format("Do-MMM-YYYY") : "Immediate"}</span>
                </Col>
                <Col xs={3} className="text-right"><span className="d-block mt-2" style={{fontSize: 20, fontWeight: "bold"}}>&#x20B9;{Math.ceil(invoiceData.taxable_amount + (invoiceData.taxable_amount * 18 / 100))}</span></Col>
            </Row>
            
            <Row noGutters className="px-2 py-2 mt-4 w-100 align-items-end"  style={{position: "absolute", bottom: invoiceData.isIncludeSign ? 5 : 30}}>
                <Col xs={12} className="border-bottom text-right py-2">
                    {!!invoiceData.isIncludeSign &&  <img src={invoiceSignBase64} alt="protected-img" style={{height: 65}}  />}
                </Col>
                <Col>
                    <span className="d-block font-weight-bold">Reculta Solutions Pvt. Ltd.</span>
                    {invoiceData.primaryGST === 1 ?
                        <>
                            <span className="d-block mt-2">B1/H3, Mohan Cooperative Industrial Area,</span>
                            <span className="d-block ">Mathura Rd, Block B, New Delhi- 110044</span>
                        </>
                        :
                        <>
                            <span className="d-block mt-2">Shyam Telecom Ltd Head Office, 246, HD-014</span>
                            <span className="d-block ">Blue 1 Square, Jaipur Road Udyog Vihar Phase 4, Sector 18, Gurugram, Haryana, 122015</span>
                        </>
                    }
                </Col>
                <Col className="text-right">
                    <span className="d-block">PAN: AAICR3777Q</span>
                    <span className="d-block">GSTIN No: {invoiceData.primaryGST === 1 ? "07AAICR3777Q1ZG" : "06AAICR3777Q1ZI"}</span>
                    <span className="d-block">UAM No: DL09E0001357</span>
                    <span className="d-block">CIN No: U93090DL2017PTC319788</span>
                </Col>
            </Row>
            {!invoiceData.isIncludeSign && 
            <Row className="bg-dark py-2 mt-3" style={{position: "absolute", bottom: 0, width: "103%"}}>
                <Col className="text-center text-light"><span>This is a computer generated invoice and does not require signature.</span></Col>
            </Row>
            }
        </main>
        <footer style={{flex: 0, position: "sticky", bottom: 0, background: "#fff", marginLeft: -15, marginRight: -15}} className="px-3 w-100">
            <Row className="align-items-center justify-content-end py-3 border-top mt-3">
              <Col xs={12} className="text-right">
                <Button className="text-danger border-danger mr-3" variant="outlined" color="secondary" style={{borderRadius: 25}} onClick={this.props.onClose}>Back to Edit</Button>
                <Button onClick={() => this.generatePdf(true)} className="text-danger border-danger mr-3" variant="outlined" color="secondary" style={{borderRadius: 25}}>Download</Button>
                <Button onClick={() => this.generateInvoice()} className=" bg-danger" variant="contained" color="secondary" style={{borderRadius: 25}}>Generate & Send</Button>
              </Col>
            </Row>
        </footer>

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

    </div>
    )
  }


}


export default InvoicePreview;
