import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import CONSTANTS from "../../../constants";
import { Drawer, Icon } from "@material-ui/core";
import TicketDetail from "../../global/support/ticket";
import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import { groupArrayOnKey } from "../../../actions";

class UserTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets : [],
            isShowTicket: false      
        }
    }

    componentDidMount(){
        this.fetchTickets();
    }
    
    fetchTickets = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {  user_id: this.props.user_id, includeAll: true, archive_status: [0, 1]  };
            
            let apiData = await ApiEndpoints.Support.fetchTickets(body);
            let tickets = apiData.data.data;

            let jobList = store.getState().jobList;
            jobList = groupArrayOnKey(jobList, "job_id", true)
            tickets = tickets.map(ticket => {
                if(ticket.tag_type === "JOB" && ticket.tag_id && jobList[ticket.tag_id]) ticket.jobData = jobList[ticket.tag_id];
                return ticket;
            })
         await this.setState({ tickets })

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      
    
    render() {
        let { tickets, isShowTicket } = this.state;
        return (
            <div className="px-1" style={{fontSize: 14}}>                   
                {
                tickets.map(ticket => {
                    return <div style={{fontSize: 14, borderRadius: 12}} className="bg-light border px-2 py-1 my-2 clickable" onClick={() => this.setState({isShowTicket: ticket})}>
                        <Row className="align-items-center">
                            <Col xs={1}>
                            {ticket.jobData ? 
                                <img className="rounded-circle clickable" style={{width: 35 , aspectRatio: "1"}} src={ticket.jobData.Employer.logo_key || DUMMY_PROFILE_IMG}  alt="Logo Pic.." onClick={() => this.setState({ isShowJobDetails: ticket.jobData })} />
                                :
                                <Icon className="align-middle" style={{width: "auto", height: "auto", padding: 5, background: "#ccc", borderRadius: "50%"}}>{CONSTANTS.TICKET_TYPE_ICON[ticket.ticket_type] || "question_mark"}</Icon>
                            }
                            </Col>
                            <Col xs={7}>
                                <div className="pl-2">
                                    <span className="font-weight-bold d-block">{ticket.ticket_title}</span>
                                    <span className="small d-block">
                                        {CONSTANTS.TICKET_TYPE_TEXT[ticket.ticket_type] || ticket.ticket_title || "NA"}
                                        {ticket.jobData ? ` | ${ticket.jobData.Employer.employer_name}-${ticket.jobData.title}` : ""}
                                    </span>
                                    <span className="small">
                                        #{ticket.ticket_id}
                                        {!!ticket.Agent && <span className="ml-2 border px-2 bg-light" style={{borderRadius: 12, fontSize: 10}}>{ticket.Agent.first_name}</span>}
                                        {!!ticket.rating && <span title={ticket.rating_comment || "No Comment"} style={{fontSize: 16}} > { {"1": "😤", "2": "😔", "3": "😐", "4": "😊", "5": "😍"}[ticket.rating]} </span>}
                                        {!!ticket.archive_status && <span className="ml-2 border px-2 border-danger text-danger" style={{borderRadius: 12, fontSize: 10}}>Archived</span>}
                                    </span>
                                </div>
                            </Col>
                            <Col xs={4} className="text-right">
                                <span className="small font-weight-bold">{CONSTANTS.TICKET_STATUS_TEXT[ticket.status] || "Unknown Status"}</span>
                                <span className="d-block" style={{fontSize: 10}}>{moment(ticket.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm A")}</span>
                            </Col>
                        </Row>
                    </div>
                })
                }
                <Drawer anchor={"right"} ModalProps={{style: {zIndex: 99999999}}} PaperProps={{ style: {width: "50%", overflow: "hidden"} }} open={!!isShowTicket} onClose={() => this.setState({isShowTicket: false})}>
                    <div className="p-3  h-100"> 
                        {!!isShowTicket && 
                        <TicketDetail
                            ticketData={ isShowTicket } 
                            selectTicket={this.selectTicket} 
                            markTicketResolved={() => {}}
                            updateTicketData={() => {}}
                            addRemoveUpdateTicket={() => {}}
                            prevTicket={false}
                            nextTicket={false} 
                          />}
                    </div>
                </Drawer>
            </div>
           
        );
    }
}
export default UserTickets;
