import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
import InputFeild from "../../../global/form/input"
import ApiEndpoints from "../../../../constants/api_endpoints";
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import { Icon } from "@material-ui/core";
import { addUser } from "../../../../actions";
import { Link } from "react-router-dom";
import CompanyCreate from "./create";

class Employer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyList : [],
            activeCompany : null,
            activePanel : "MEMBER",
            addMember : {role_id : 3},
            members : [],
            permissionList : [],
            isOpenCreate: false
        }
    }

    componentDidMount(){
        this.fetchCompany();
    }
   
    fetchCompany = async () => {
        store.dispatch(setLoader(true))
        try{
           var resp =  await ApiEndpoints.Employer.fetchEmployer();
           this.setState({companyList : resp.data.data})
           if(resp.data.data.length)
            this.setActiveCompany(resp.data.data[0].employer_id)
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    setActiveCompany = async (employer_id) => {
        store.dispatch(setLoader(true))
        try{
            let body = {
                access_type : "EMPLOYER",
                access_type_id : employer_id
            }
            let accessResp = await ApiEndpoints.User.fetchMappedAccess(body)
            this.setState({activeCompany : employer_id, members : accessResp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeMember = async (e) => {
        store.dispatch(setLoader(true))
        let { addMember } = this.state;
        addMember[e.target.name] = e.target.value;
        if(e.target.name === "role_id"){
            await this.getPermission(e.target.value)
        }
        this.setState({addMember})
        store.dispatch(setLoader(false))
    }

    getPermission = async (role_id) => {
        store.dispatch(setLoader(true))
        try {
            var permissionResp = await ApiEndpoints.Auth.fetchPermission({role_id})
            this.setState({permissionList : permissionResp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addMember = async (e) => {
        e.preventDefault();
        let { addMember, activeCompany } = this.state;
        addMember.access_type = "EMPLOYER";
        addMember.access_type_id = activeCompany;
        store.dispatch(setLoader(true))
        try{
            await addUser(addMember);
            await this.setActiveCompany(activeCompany)
            this.setState({addMember : {role_id : 3}, activePanel : "MEMBER"})
            store.dispatch(setSuccess({show: true, message: "Member Added!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    deleteAccess = async (access_id) => {
        if(window.confirm("Are you sure?")){
            store.dispatch(setLoader(true))
            try {
                await ApiEndpoints.User.inactiveAccess({access_id})
                this.setActiveCompany(this.state.activeCompany)
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false))
        }
    }

    render() {
        let {companyList, activeCompany, activePanel, addMember, members, permissionList, isOpenCreate} = this.state;
        return (
        
            <div>
                <Row className="border-bottom align-items-center">
                    <Col xs={4} className="border-right py-3" >
                        <span style={{fontSize: "18px"}} >Choose Company</span>
                        <Button type="button" className="buttonred ml-4" onClick={() => this.setState({ isOpenCreate: true })}>Add New Company</Button>
                    </Col>             
                    <Col xs={4} className="border-right py-3">
                        <span className="" style={{fontSize: "18px", paddingLeft: "20px"}}>Client's Access</span>
                    </Col>
                    <Col xs={4} className="py-3">
                        <span className="" style={{fontSize: "18px", paddingLeft: "20px"}}>Information Access</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4} className="border-right pt-2"> 
                    {
                    companyList.map((company) => 
                        <Row key={company.employer_id} style={{cursor: "pointer"}} className={"visible-on-hover-container border-bottom mx-2 align-items-center " + (activeCompany === company.employer_id && "row-active")} onClick={()=>this.setActiveCompany(company.employer_id)}>
                            <Col xs={3} className="pr-2 pt-2 pb-1" style={{marginRight: "-20px"}}>
                                <img className="rounded-circle" style={{width: "100%", marginLeft: "-10px"}} src={company.logo_url || "/images/dummy_profile.jpg"} alt="Profile"></img>
                            </Col>
                            <Col className="" style={{wordBreak: "break-all"}}>
                                <span>{company.employer_name}</span>
                                <div className="visible-on-hover-item">
                                <Button onClick={() => this.setState({ isOpenCreate: {company} })} type="button" className="buttonred py-0">Edit</Button>
                                {/* <Link to="/company/create" state={{company}}><Button type="button" className="buttonred py-0">Edit</Button></Link> */}
                                </div>
                            </Col>
                        </Row>
                    )
                    }
                        
                    </Col>
                    <Col xs={4} className="border-right"> 
                        <h5 className="mt-3 mb-3" onClick={()=>this.setState({activePanel : "MEMBER"})} style={{cursor: "pointer"}}>
                            Members
                            <Icon className="float-right">{activePanel === "MEMBER" ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                        </h5>
                        <Collapse isOpen={activePanel === "MEMBER"}>
                            {!members.length ? <h6 className="text-muted ml-3"><em>No Members Added</em></h6>:""}
                                {members.map(access => 
                                <Row style={{cursor: "pointer"}}  key={access.access_id}>
                                    <Col style={{cursor: "pointer"}} onClick={() => this.getPermission(access.UserRole.role_id)}>{access.User && access.User.first_name} | {access.UserRole && access.UserRole.Role.role_name}</Col>
                                    { access.UserRole.role_id === 3 && <Col xs="auto"><Icon style={{fontSize: 14, cursor: "pointer"}} className="align-middle" onClick={() => this.deleteAccess(access.access_id)}>delete</Icon></Col>}
                                </Row>
                                )}
                        </Collapse>
                        <h5 className="mt-3" onClick={()=>this.setState({activePanel : "INVITE"})} style={{cursor: "pointer"}}>
                            Invite Members for the access 
                            <Icon className="float-right">{activePanel === "INVITE" ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                        </h5>
                        <Collapse isOpen={activePanel === "INVITE"}>
                            <form onSubmit={this.addMember}>
                                <InputFeild required className="mt-3 " type="text" inputProps={{pattern: "[0-9]{10}"}} onInput={(e)=>e.target.setCustomValidity("")} onInvalid={(e)=>  e.target.setCustomValidity("Mobile number should be 10 digit numerical value")} placeholder="Enter Mobile No" value={addMember.mobile || ""} name="mobile" onChange={this.onChangeMember}/>
                                <InputFeild required inputProps={{pattern : "[a-zA-Z .]{2,100}"}} className="mt-3 " placeholder="Enter Member Name"  value={addMember.first_name || ""} name="first_name" onChange={this.onChangeMember}/>
                                <InputFeild className="mt-3 " placeholder="Enter Email Address"  value={addMember.email || ""} name="email" onChange={this.onChangeMember}/>
                                <h5 className="mt-2 ml-2">Choose Role</h5>
                                <div className="form-check ml-3">
                                    <Input required className="form-check-input" name="role_id" type="radio" value="3" onChange={this.onChangeMember}/>
                                    <label className="form-check-label ml-3">Project Manager</label>
                                </div>
                                <div className="form-check ml-3">
                                    <Input required className="form-check-input" name="role_id" type="radio" value="4" onChange={this.onChangeMember}/>
                                    <label className="form-check-label ml-3">Recruiters</label>
                                </div>
                                <div className="form-check ml-3">
                                    <Input required className="form-check-input" name="role_id" type="radio" value="5"  onChange={this.onChangeMember}/>
                                    <label className="form-check-label ml-3">Interviewer</label>
                                </div>
                                <Button type="submit" className="buttonred mt-4" block><span>Add Member</span></Button>
                            </form>
                        </Collapse>
                    </Col>

                    <Col xs={4} style={{fontSize: "13px"}}> 
                    {permissionList.length ?
                        permissionList.map(permission => 
                            <Row className="mt-4">
                                <Col><p className="ml-5">{permission.Permission.permission_description}</p></Col>
                            </Row>
                        )
                        :
                        <h6 className="text-muted ml-3 mt-4"><em>No Permissions available</em></h6>
                    }
                    </Col>

                </Row>

                <Modal isOpen={isOpenCreate} toggle={()=> {this.setState({isOpenCreate : false})}} >
                    <ModalHeader toggle={()=> {this.setState({isOpenCreate : false})}}>Create Company<br></br>
                    </ModalHeader>
                    <ModalBody>
                        { isOpenCreate && <CompanyCreate company={isOpenCreate.company} onClose={() => {this.setState({ isOpenCreate: false }); this.fetchCompany() }} />  }
                    </ModalBody>
                </Modal>

        </div>
            
        );
    }
}
export default Employer;
