import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";


class CustomerBusinessDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList : [],
            
        }
    }
   
    componentDidMount(){
        this.fetchBusinessDetails();
    }

    fetchBusinessDetails = async () => {
        try{
           let resp = await ApiEndpoints.Customer.customerDetails({customer_lead_id: this.props.customer_lead_id});
           let detailList = resp.data.data;
           this.setState({detailList})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    render() {
        let { detailList } = this.state;
        return (
            <>

                <Row className="mt-2">
                    <Col>
                        <span className="mt-2" style={{fontSize: 14}}>Business Name</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerBusinessDatum ? list.CustomerBusinessDatum.business_name || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Business Type</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerBusinessDatum ? list.CustomerBusinessDatum.business_type || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Category</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerBusinessDatum ? list.CustomerBusinessDatum.category || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Monthly Income</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerBusinessDatum ? list.CustomerBusinessDatum.monthly_income || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>GST Number</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerBusinessDatum ? list.CustomerBusinessDatum.gst_no || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                
                
            </>
            
            
        );
    }
}
export default CustomerBusinessDetails;
