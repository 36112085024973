import { combineReducers } from 'redux';
import success from './success';
import error from './error';
import user from './user';
import loader from './loader';
import jobList from './jobs';

export default combineReducers({
  success,
  error,
  user,
  loader,
  jobList
});
