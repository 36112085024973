import React, { Component } from 'react';
import Button from 'reactstrap/lib/Button';
import ModalBody from 'reactstrap/lib/ModalBody';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import Icon from '@material-ui/core/Icon';

class ConfirmAction extends Component {


	onClose = () => {
		this.props.onClose();
	}
	executeCallback = async() => {
		await this.props.callback();
		this.onClose()
	}
	render() {
		return (
			<Modal isOpen={!!this.props.isOpen} className="modal-dialog-centered" toggle={this.onClose}>
				<ModalBody className="">
					<h4 className="d-flex py-4 m-0 justify-content-center">{this.props.message || "Are you sure?"}</h4>
				</ModalBody>
					<Row noGutters>
						<Col className="text-light">
							<Button id="delete_confirm_no" outline color={"danger"} onClick={this.onClose} className="w-100 py-3" style={{borderRadius : 0}}><span>No</span></Button>
						</Col>
						<Col className="text-light">
							<Button id="delete_confirm_yes" color="success" onClick={this.executeCallback} className="w-100 py-3" style={{borderRadius : 0}} >Yes</Button>
						</Col>
					</Row>
			</Modal>
		)
	}
}

export default ConfirmAction;
