
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import SelectList from "../../global/form/select"
import "../../../styles/button.css"
import {Icon} from '@material-ui/core';
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import RichTextEditor from "../../global/form/Richtext";
import ConfirmAction from "../../global/confirm-action";

class TaskInstructions extends Component {
    constructor(props) {
      super(props);
      this.state = {
            instructionsList: [
                {
                    task_instruction_id: "NEW_0",
                    task_instructions: "",
                    language: "English"
                }
            ],
            deletedIds: [],
            selectedInstruction: null,
            isSendNotification: false
        }
    }

    async componentDidMount(){
        await this.init() ;
    }

    init = async () =>{
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.JobTask.fetchTaskInstruction({job_task_id : this.props.taskData.job_task_id});
            apiData.data.data.length && await this.setState({instructionsList : apiData.data.data}) ;
            this.state.instructionsList.length === 1 && this.setState({selectedInstruction: this.state.instructionsList[0].task_instruction_id});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeInstruction = (k, v, i) => {
        let { instructionsList } = this.state;
        instructionsList[i][k] = v;
        this.setState({ instructionsList })
    }

    addNewTranslation = () => {
        let { instructionsList } = this.state;
        var selectedLanguages = instructionsList.map(o => o.language);
        var obj = {
            task_instruction_id: "NEW_"+instructionsList.length,
            task_instructions: "",
            language: ["English", "Hindi", "Tamil", "Telugu", "Kannada"].filter(v => selectedLanguages.indexOf(v) === -1)[0]
        };
        instructionsList.push(obj)
        this.setState({ instructionsList, selectedInstruction:  obj.task_instruction_id})
    }

    onDelete = (id, i) => {
        let { instructionsList, deletedIds } = this.state;
        instructionsList.splice(i, 1); 
        if(typeof id !== "string") deletedIds.push(id); 
        this.setState({instructionsList, deletedIds});
    }

    updateTaskInstruction = async () => {
        store.dispatch(setLoader(true));
        let { instructionsList, deletedIds, isSendNotification } = this.state;
        try{
            if(deletedIds.length) await ApiEndpoints.JobTask.deleteTaskInstruction({ task_instruction_id: deletedIds });
            await Promise.all(instructionsList.map((instruction, i) => ApiEndpoints.JobTask.updateTaskInstruction({
                ...instruction, 
                job_task_id: this.props.taskData.job_task_id,
                send_notification: isSendNotification && i === 0 ? true : false
            })))
            store.dispatch(setSuccess({show: true, message: "Updated Successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    render(){
        let { instructionsList, selectedInstruction, isSendNotification} = this.state;
        let selectedLanguages = instructionsList.map(o => o.language);;
        return(
        <>  
            {instructionsList.map((instruction, i) => <>
                <Row className="align-items-center bg-light py-2 shadow-sm border my-2">
                    <Col>
                        {typeof instruction.task_instruction_id === "string" ?
                            <SelectList value={instruction.language} onChange ={(e) => this.onChangeInstruction("language", e.target.value, i)} >
                            {["English", "Hindi", "Tamil", "Telugu", "Kannada"].filter(v => selectedLanguages.indexOf(v) === -1 || v === instruction.language).map(lang =>
                                    <option key={lang} value={lang}>{lang}</option> 
                            )}
                            </SelectList>
                            :
                            <span>{instruction.language}</span>
                        }
                    </Col>
                    <Col xs={3} className="text-right">
                        <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => this.setState({ selectedInstruction: selectedInstruction === instruction.task_instruction_id ? null : instruction.task_instruction_id })} className="mr-2">edit</Icon>
                        <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => this.onDelete(instruction.task_instruction_id, i)}>delete</Icon>
                    </Col>
                </Row>
                {selectedInstruction === instruction.task_instruction_id && <RichTextEditor placeholder="Enter Instruction Data" value={instruction.task_instructions} onChange={(value)=> this.onChangeInstruction("task_instructions", value, i) }/>}
            </>)}
            {selectedLanguages.length < 5 && <Button onClick={this.addNewTranslation} color="link" className="my-2">Add Translation</Button>}
            <Row className="align-items-center mt-3">
                <Col>
                    <input type="checkbox" checked={isSendNotification} onChange={e => this.setState({isSendNotification: e.target.checked}) }  className="mr-2" /> <span style={{fontSize: 12}}>Send Alert to In-Task Partners on Whatsapp ?</span>
                </Col>
                <Col xs="auto">
                    <Button type="button" className="buttonred" onClick={()=> this.updateTaskInstruction()} style={{fontSize: "14px"}}>Update</Button>
                </Col>
            </Row>

        </>
        


        );
    }
}

export default TaskInstructions;
