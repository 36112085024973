import React, { Component } from 'react';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import 'react-quill/dist/quill.bubble.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class RichTextEditor  extends Component {
	constructor(props) {
		super(props);

		// this.modules = {
		// 	toolbar: this.props.basic ?
		// 	[
		// 		['bold', 'italic', 'underline'],
		// 		[{'list': 'ordered'}, {'list': 'bullet'}],
		//     ]
		// 	 :
		// 	[
		//       [{ 'font': [] }],
		//       [{ 'size': ['small', false, 'large', 'huge'] }],
		//       ['bold', 'italic', 'underline'],
		//       [{'list': 'ordered'}, {'list': 'bullet'}],
		//       [{ 'align': [] }],
		//       [{ 'color': [] }, { 'background': [] }],
		//       ['clean']
		//     ]
		// };

		// this.formats = [
		//     'font',
		//     'size',
		//     'bold', 'italic', 'underline',
		//     'list', 'bullet',
		//     'align',
		//     'color', 'background'
	  	// ];

	  	// this.state = {
		// 	comments: '',
		
		// }

		// this.rteChange = this.rteChange.bind(this);
	}

	// rteChange = (content, delta, source, editor) => {
	// 	if(this.props.onChange){
	// 		this.props.onChange(editor.getHTML());
	// 	}
	// 	// console.log(editor.getText()); // plain text
	// 	// console.log(editor.getLength()); // number of characters
	// }

	// render() {
	//     return (
	//       <div>
	// 			<ReactQuill 
	// 				style={{ width: "100%"}} 
	// 				theme="snow"
	// 				placeholder={this.props.placeholder || ""}  
	// 				modules={this.modules}
	// 				formats={this.formats} 
	// 				onChange={this.rteChange}
	// 				value={this.props.value}
	// 			/>
	// 	  </div>

	//     );
	// }

	render() {
		let editorConfig = {placeholder: this.props.placeholder || "Type here"};
		if(this.props.hideToolbar) editorConfig.toolbar = false;
        return (
            <div className="App">
                {/* <h2>Using CKEditor 5 build in React</h2> */}
                <CKEditor
                    editor={ ClassicEditor }
                    data={this.props.value}
                    onInit={ editor => {
                        // You can store the "editor" and use when it is needed.
						this.props.height !== false && editor.editing.view.change((writer) => {
							writer.setStyle(
								"height",
								this.props.height || "120px",
								editor.editing.view.document.getRoot()
							);
						});
                        // console.log( 'Editor is ready to use!', editor );
					} }
					
					config={{...editorConfig }}
                    onChange={ ( event, editor ) => {
                        this.props.onChange(editor.getData());
                        // console.log( { event, editor } );
                    } }
                    onBlur={ ( event, editor ) => {} }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
        );
    }

}

export default RichTextEditor ;