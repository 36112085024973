import {  Badge, Chip, FormGroup, Icon, Input, List } from "@material-ui/core";
import React, { Component } from "react";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import SelectList from "../../global/form/select";
import InputFeild from "../../global/form/input";
import { Button, CardBody, Modal, ModalBody, ModalHeader, Collapse, Card } from "reactstrap";
import moment from "moment";
import CONSTANTS from "../../../constants";
import store from "../../../store";
import { FILTERS } from "../../../constants/campaign_filter";
import { Height } from "@material-ui/icons";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg";
import CampaignLists from "./campaign_list";


class CampaignFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
            filters: [],
            activityFilters: [],
            userFilterList: null,
            genderType: null,
            ageType: null,
            stateType: [],
            interestType: null,
            durationType: null,
            didActivityKey: null,
            didActivityType: null,
            didDurationType: null,
            didNotActivityType: null,
            didNotDurationType: null,
            selectedPmwId: null,
            selectedMobileNumber: null,
            selectedPincode: null,
            isOpenPushNotificationModal: false,
            isOpenWhatsappModal: false,
            isOpenEmailModal: false,
            selectedJobId : null,
            applicationJobId: null,
            selectedQuery: null,
            addUserFilter: {},
            isOpencampaignCreateModal: false,
            isOpenFilterCollapse: null,
            campaignList: [],
            createCampaignDetails: {}
          };

  }

  componentDidMount(){
    this.fetchCampaignList();
}

  setJobId = async (job_id) => {
    let { jobList } = this.state;
    store.dispatch(setLoader(true))

    await this.setState({selectedJobId : job_id,})
    store.dispatch(setLoader(false))
    }

    selectFilterType = async (job_id) => {
      let {isOpenFilterCollapse} = this.state;
    await this.setState({isOpenFilterCollapse :  isOpenFilterCollapse === null ? 1 : null, filters: [], userFilterList: null, ageType : null, genderType: null, stateType: [], interestType: null, durationType: null , didActivityType: null , didDurationType: null , didNotActivityType: null , didNotDurationType: null , selectedQuery: null , selectedPmwId: null , selectedMobileNumber: "" , selectedPincode: null, applicationJobId: null })
    }

    selectApplicationJob = async (type) => {
      let value = type
      if (type && String(type).includes("CATEGORY_")) {
        let categoryId = String(type).replace("CATEGORY_", "");
        let jobList = store.getState().jobList;
        type = jobList.filter(job => job.job_category_id == categoryId).map(job => job.job_id);
      }
      const applicationJobs = FILTERS["APPLICATION_JOBS"](type)
      const allFilters = [...this.state.filters.filter(obj => obj.type !== "APPLICATION_JOBS"), applicationJobs]
      await this.setState({ applicationJobId: value, filters: allFilters, userFilterList: null});
    }

  selectGenderType = async (type) => {
    let genderFilter = FILTERS["GENDER"](type);
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "GENDER"), genderFilter]
    await this.setState({ filters: allFilters , genderType: type, userFilterList: null});
  }

  selectAgeType = async (type) => {
    let ageFilter = FILTERS["AGE"](type);
    if (!ageFilter) return this.setState({ ageType: type });
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "AGE"), ageFilter]
    await this.setState({ ageType: type, filters: allFilters, userFilterList: null});
  }

  selectStateType = async (type) => {
    let stateFilter = FILTERS["LOCATION_STATE"](type);
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "LOCATION_STATE"), stateFilter]
    await this.setState({ stateType: type, filters: allFilters, userFilterList: null});
  }

  selectPincode = async (type) => {
    const pincodeFilter = type ? [FILTERS["LOCATION_PINCODE"](type)] : []
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "LOCATION_PINCODE"), ...pincodeFilter]
    await this.setState({ selectedPincode: type, filters: allFilters, userFilterList: null});
  }

  selectInterestType = async (type) => {
    let interestFilter = FILTERS["VISITED_ONLY"](type);
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "FIRST_TASK"), interestFilter]
    await this.setState({ interestType: type, filters: allFilters, userFilterList: null});
  }

  selectDurationType = async (type) => {
    let interestDuration = FILTERS["VISITED_ON"](type)
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "APPLICATION_ADDED"), interestDuration]
    await this.setState({ durationType: type, filters: allFilters, userFilterList: null});
  }

  selectDidActivityType = async (type) => {
    if (!FILTERS[type]) return null;
    const didActivity = FILTERS[type](type);
    const removableFilters = ["APP_INSTALLED", "LAST_TASK", "LAST_VERIFIED_TASK"];
    if (this.state.didActivityKey) {
      const durationValue = this.state.filters.filter(obj => obj.type === this.state.didActivityKey);
      if (durationValue.length && durationValue[0].val) {
        didActivity.operator = durationValue[0].operator;
        didActivity.val = durationValue[0].val;
      }
    }
    const allFilters = [...this.state.filters.filter(obj => !removableFilters.includes(obj.type)), didActivity]
    await this.setState({ didActivityType: type, didActivityKey: type , filters: allFilters, userFilterList: null});
  }

  selectDidDurationType = async (type) => {
    if (!this.state.didActivityKey || !FILTERS[this.state.didActivityKey]) return null;
    const didDuration = FILTERS[this.state.didActivityKey + "_ON"](type)
    const allFilters = [...this.state.filters.filter(obj => !["APP_INSTALLED", "LAST_TASK", "LAST_VERIFIED_TASK"].includes(obj.type)), didDuration]
    await this.setState({ didDurationType: type, filters: allFilters, userFilterList: null});
  }
  
  selectPmwId = async (type) => {
    const pmwid = type ? [FILTERS["PMW_ID"](type)] : []
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "PMW_ID"), ...pmwid]
    await this.setState({ selectedPmwId: type, filters: allFilters, userFilterList: null});
  }
  selectMobileNumber = async (type) => {
    const mobile = type ? [FILTERS["MOBILE"](type)] : []
    const allFilters = [...this.state.filters.filter(obj => obj.type !== "MOBILE_NUMBER"), ...mobile]
    await this.setState({ selectedMobileNumber: type, filters: allFilters, userFilterList: null});
  }
  selectDidNotActivityType = async (type) => {
    await this.setState({ didNotActivityType: type});
  }
  
  selectDidNotDurationType = async (type) => {
    await this.setState({ didNotDurationType: type});
  }
  selectQuery = async (type) => {
    await this.setState({ selectedQuery: type, userFilterList: null});
  }

  findPartnerFilter = async (e) => {
      let {filters, activityFilters, selectedQuery} = this.state;
      store.dispatch(setLoader(true))
      try{
        var body = {};
        if (selectedQuery) body = { queryData: selectedQuery };
        else {
          if (filters && filters.length) {
            body = { filterData: [] };
            body.filterData.push({
              type: "USER",
              filters
            });
          }
          if (activityFilters && activityFilters.length) {
            if (!body.filterData) body = { filterData: [] };
            body.filterData.push({
              type: "ACTIVITY",
              filters: activityFilters
            });
          }
        }
        let resp = null;
        if (body.queryData || body.filterData) {
         resp = await ApiEndpoints.CampaignFilter.userFilters(body)
        await this.setState({userFilterList : resp.data.data.count});}
        if (!resp || resp.data.message)
        store.dispatch(setSuccess({show: true, message: resp ? resp.data.message : "Please select any filter"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));

    }

    onChangeCreateCampaign = (e) => {
      let { createCampaignDetails } = this.state; 
      createCampaignDetails[e.target.name] = e.target.value;
      this.setState({createCampaignDetails})
    }

    createCampaign = async (e) => {
      e.preventDefault();
      let {createCampaignDetails, selectedQuery, activityFilters, filters, userFilterList} = this.state;
      store.dispatch(setLoader(true))
      try{
        var body = {
          title: createCampaignDetails.title,
          description: createCampaignDetails.description,
          total_users: userFilterList
        };
        var campaign_data = {};
              if (selectedQuery) campaign_data = { queryData: selectedQuery };
              else {
                if (filters && filters.length) {
                  campaign_data = { filterData: [] };
                  campaign_data.filterData.push({
                    type: "USER",
                    filters
                  });
                }
                if (activityFilters && activityFilters.length) {
                  if (!campaign_data.filterData) campaign_data = { filterData: [] };
                  campaign_data.filterData.push({
                    type: "ACTIVITY",
                    filters: activityFilters
                  });
                }
              }
              body.campaign_data = campaign_data
        let resp = await ApiEndpoints.CampaignFilter.createCampaign(body)
        // await this.setState({createCampaignDetails : resp.data.data});
        this.setState({isOpencampaignCreateModal: false, createCampaignDetails: {}, isOpenFilterCollapse: null, userFilterList: null});
        this.fetchCampaignList();

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));

    }

    fetchCampaignList = async() => {
      store.dispatch(setLoader(true))
      try{
          let apiData = await ApiEndpoints.CampaignFilter.fetchCampaigns();
          let campaignList = apiData.data.data;
          this.setState({ campaignList })
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false));

  }


  render() {
    let {campaignList, createCampaignDetails, isOpenPushNotificationModal, isOpenFilterCollapse, isOpencampaignCreateModal, selectedQuery, userFilterList, addUserFilter, applicationJobId, isOpenWhatsappModal, isOpenEmailModal, genderType, selectedPmwId, selectedMobileNumber, ageType, stateType, interestType, durationType, didActivityType, didDurationType, didNotActivityType, didNotDurationType, selectedPincode, activityFilters } = this.state;
    let jobList = store.getState().jobList
    let categories = [];
    let includedIds = [];
    jobList.forEach(job => {
      if (!includedIds.includes(job.job_category_id)){
        categories.push(job.JobCategory);
        includedIds.push(job.job_category_id);
      }
  })

    return (
      <>
            <Row className=" mb-2 border-bottom align-items-end" >
              <Col style={{fontSize: 20}}><h4>Campaigns</h4></Col>
                <Col className="text-right mb-3">
                  <Button outline color="danger" className=" ml-2" style={{height: 23, paddingTop: 1, fontSize: 12 }} onClick={this.selectFilterType}>{isOpenFilterCollapse === null ? "Create New Campaign" : "← Back to Campaign List"}</Button>{' '}
                </Col>
            </Row>

           {isOpenFilterCollapse === null ? 
              <div> 
                <CampaignLists/>
              </div>
               : " "
           }
            <div>
                <Collapse className="w-100" isOpen={!!isOpenFilterCollapse }>
                  <Card className="mt-2">
                    <CardBody>
                          <Row className="mx-1">
                            <span className="ml-2"><h5>Find Partners</h5></span>
                            <Col xs={2}>
                              <Button size="sm" className="buttonred mr-2" style={{height: 23, paddingTop: 1}} onClick={()=> {this.setState({isOpenFilterCollapse : isOpenFilterCollapse === 2 ? 1 : 2 , userFilterList: null})}}>Query</Button>
                            </Col>
                            <Col xs={8} className="text-right text-danger">
                              {/* <strong>12345</strong>
                              <span style={{fontSize: 14}}>Total Partners</span> */}
                            </Col>
                          </Row>

                          <Collapse className="w-100" isOpen={isOpenFilterCollapse === 2 }>
                            <Card>
                              <CardBody>
                                    <Row className="mx-1 mt-2">
                                      <Col xs={12}>
                                        <textarea required placeholder="Enter Your Query" name="query_msg" rows={6} className=" w-100 mt-2 mb-3" style={{fontSize: 14}} value={selectedQuery || ""} onChange ={(e) => this.selectQuery(e.target.value)}/>
                                      </Col>
                                    </Row>
                              </CardBody>
                            </Card>
                          </Collapse>

                  <Collapse className="w-100" isOpen={isOpenFilterCollapse === 1 }>
                    <Card>
                      <CardBody>
                                    {/* {first row} */}
                        <Row className="mx-2 mt-2">
                          <Col className="border mr-2" style={{borderRadius: 3}}>
                            <Row className="mt-2">
                              <Col xs={9}><strong style={{fontSize: 14}}>By Profile</strong> 
                                <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.User demographics profile)</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col><span style={{fontSize: 14}}>Gender</span></Col>
                              <Col><span style={{fontSize: 14}}>Age Group</span></Col>
                            </Row>

                            <Row className="mb-3">
                              <Col>
                                <SelectList placeholder="Select Gender" style={{fontSize:12}} value={genderType} onChange ={(e) => this.selectGenderType(e.target.value)}>
                                    <option value={"1"}>Male</option>
                                    <option value={"2"}>Female</option>
                                    <option value={"3"}>Others</option>
                                </SelectList>
                              </Col>
                              <Col>
                                <SelectList placeholder="Select Age Group" style={{fontSize:12}} value={ageType} onChange ={(e) => this.selectAgeType(e.target.value)}>
                                    <option value={18}>18 - 20 year</option>
                                    <option value={20}>20 - 25 year</option>
                                    <option value={25}>25 - 30 year</option>
                                    <option value={30}>30 - 35 year</option>
                                    <option value={35}>35 - 40 year</option>
                                    <option value={40}>40 - 45 year</option>
                                    <option value={45}>45 - 50 year</option>
                                    <option value={50}>50 - 55 year</option>
                                    <option value={55}>55 - 60 year</option>
                                    <option value={0}>All Age Group</option>
                                </SelectList>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="border" style={{borderRadius: 3}}>
                            <Row className="mt-2">
                              <Col xs={9}><strong style={{fontSize: 14}}>By Identify</strong>
                                <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.User demographics profile)</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col><span style={{fontSize: 14}}>PMW ID</span></Col>
                              <Col><span style={{fontSize: 14}}>Mobile Number</span></Col>
                            </Row>

                            <Row className="mb-3">
                              <Col>
                                <InputFeild style={{fontSize: 12}} name="pmw_id" placeholder="Enter PMW ID" value={selectedPmwId || ""} onChange ={(e) => this.selectPmwId(e.target.value)}/>
                              </Col>
                              <Col>
                                <InputFeild type="number" style={{fontSize: 12}} placeholder="Enter Mobile Number" value={selectedMobileNumber || ""} onChange ={(e) => this.selectMobileNumber(e.target.value)}/>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                                {/* {second row} */}
                        <Row className="mx-2 mt-2">
                          <Col className="border mr-2" style={{borderRadius: 3}}>
                              <Row className="mt-2">
                                <Col xs={9}><strong style={{fontSize: 14}}>By Location</strong> 
                                  <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.User geographics location)</span>
                                </Col>
                              </Row>

                              <Row>
                                <Col><span style={{fontSize: 14}}>City Pincode</span></Col>
                                <Col><span style={{fontSize: 14}}>state or Region</span></Col>
                              </Row>

                              <Row className="mb-3">
                                <Col>
                                  <InputFeild style={{fontSize: 12}} placeholder="Enter Pincode" value={selectedPincode || ""} onChange ={(e) => this.selectPincode(e.target.value)}/>
                                </Col>
                                <Col>
                                  <SelectList search={true} multiple="checkbox" placeholder="Select Region" style={{fontSize: 12}} value={stateType} onChange={(e) => this.selectStateType(e.target.value)}>
                                      
                                    <option value={"Andhra Pradesh"}>Andhra Pradesh</option>
                                    <option value={"Arunachal Pradesh"}>Arunachal Pradesh</option>
                                    <option value={"Assam"}>Assam</option>
                                    <option value={"Bihar"}>Bihar</option>
                                    <option value={"Chhattisgarh"}>Chhattisgarh</option>
                                    <option value={"Delhi"}>Delhi</option>
                                    <option value={"Goa"}>Goa</option>
                                    <option value={"Gujarat"}>Gujarat</option>
                                    <option value={"Haryana"}>Haryana</option>
                                    <option value={"Himachal Pradesh"}>Himachal Pradesh</option>
                                    <option value={"Jammu & Kashmir"}>Jammu & Kashmir</option>
                                    <option value={"Jharkhand"}>Jharkhand</option>
                                    <option value={"Karnataka"}>Karnataka</option>
                                    <option value={"Kerala"}>Kerala</option>
                                    <option value={"Madhya Pradesh"}>Madhya Pradesh</option>
                                    <option value={"Maharashtra"}>Maharashtra</option>
                                    <option value={"Manipur"}>Manipur</option>
                                    <option value={"Meghalaya"}>Meghalaya</option>
                                    <option value={"Mizoram"}>Mizoram</option>
                                    <option value={"Nagaland"}>Nagaland</option>
                                    <option value={"Odisha"}>Odisha</option>
                                    <option value={"Punjab"}>Punjab</option>
                                    <option value={"Rajasthan"}>Rajasthan</option>
                                    <option value={"Sikkim"}>Sikkim</option>
                                    <option value={"Tamil Nadu"}>Tamil Nadu</option>
                                    <option value={"Telangana"}>Telangana</option>
                                    <option value={"Tripura"}>Tripura</option>
                                    <option value={"Uttarakhand"}>Uttarakhand</option>
                                    <option value={"Uttar Pradesh"}>Uttar Pradesh</option>
                                    <option value={"West Bengal"}>West Bengal</option>


                                  </SelectList>
                                </Col>
                              </Row>
                            </Col>

                            <Col className="border" style={{borderRadius: 3}}>
                              <Row className="mt-2">
                                <Col xs={9}><strong style={{fontSize: 14}}>By Interest</strong>
                                  <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.Where user visited but not did anything)</span>
                                </Col>
                              </Row>

                              <Row>
                                <Col><span style={{fontSize: 14}}>Interested In</span></Col>
                                <Col><span style={{fontSize: 14}}>Interest Duration</span></Col>
                              </Row>

                              <Row className="mb-3">
                                <Col>
                                <SelectList style={{fontSize:12}} placeholder="Select Option" value={this.state.applicationJobId} onChange ={(e) => this.selectApplicationJob(e.target.value)} >
                                    { [<option disabled>Categories</option>,
                                    ...categories.map(category => <option key={"CATEGORY_"+category.job_category_id} value={"CATEGORY_"+category.job_category_id}> {category.title}
                                </option>),
                                       <option disabled>Projects</option>,
                                ...jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                            </option>)]}
                                </SelectList>
                                </Col>
                                <Col>
                                  <SelectList placeholder="Select Duration" style={{fontSize:12}} value={durationType} onChange ={(e) => this.selectDurationType(e.target.value)}>
                                      <option value={JSON.stringify([0, "day", "day", "Today"])}>Today</option>
                                      <option value={JSON.stringify([1, "day", "day", "Yesterday"])}>Yesterday</option>
                                      <option value={JSON.stringify([7, "days", "day", "In Last 7 Days"])}>In Last 7 Days</option>
                                      <option value={JSON.stringify([15, "days", "day", "In Last 15 Days"])}>In Last 15 Days</option>
                                      <option value={JSON.stringify([30, "days", "day", "In Last 30 Days"])}>In Last 30 Days</option>
                                      <option value={JSON.stringify([60, "days", "day", "Last 2 Month"])}>Last 2 Months</option>
                                      <option value={JSON.stringify([90, "days", "day", "Last 3 Month"])}>Last 3 Months</option>
                                      <option value={JSON.stringify([180, "days", "day", "Last 6 Month"])}>Last 6 Months</option>
                                      <option value={JSON.stringify([0, "year", "year", "This Year"])}>This Year</option>
                                  </SelectList>
                                </Col>
                              </Row>
                            </Col>
                        </Row>

                                  {/* {third row} */}
                        <Row className="mx-2 mt-2">
                          <Col className="border mr-2" style={{borderRadius: 3}}>
                            <Row className="mt-2">
                              <Col xs={9}><strong style={{fontSize: 14}}>Who Did</strong>
                                <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.What user did yet)</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col><span style={{fontSize: 14}}>Activity Type</span></Col>
                              <Col><span style={{fontSize: 14}}>Projects</span></Col>
                              <Col><span style={{fontSize: 14}}>Activity Duration</span></Col>
                            </Row>

                            <Row className="mb-3">
                              <Col>
                                <SelectList placeholder="Select Activity" style={{fontSize:12}}  value={didActivityType} onChange ={(e) => this.selectDidActivityType(e.target.value)}>
                                    <option value={"APP_INSTALLED"}>Login</option>
                                    <option value={"LAST_TASK"}>Add Lead</option>
                                    <option value={"LAST_VERIFIED_TASK"}>Add Verified Lead</option>
                                    <option disabled value={"3"}>First Profile</option>
                                    <option disabled value={"4"}>Watch Intro</option>
                                    <option disabled value={"5"}>Add Payout Details</option>
                                    <option disabled value={"6"}>Join Training</option>
                                </SelectList>
                              </Col>

                              <Col>
                                <SelectList style={{fontSize:12}} placeholder="Select Option" value={this.state.applicationJobId} onChange ={(e) => this.selectApplicationJob(e.target.value)} >
                                    { [<option disabled>Categories</option>,
                                    ...categories.map(category => <option key={"CATEGORY_"+category.job_category_id} value={"CATEGORY_"+category.job_category_id}> {category.title}
                                </option>),
                                    <option disabled>Projects</option>,
                                ...jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                            </option>)]}
                                </SelectList>
                              </Col>

                              <Col>
                                <SelectList placeholder="Select Duration" style={{fontSize:12}} value={didDurationType} onChange ={(e) => this.selectDidDurationType(e.target.value)}>
                                    <option value={JSON.stringify([0, "day", "day", "Today"])}>Today</option>
                                    <option value={JSON.stringify([1, "day", "day", "Yesterday"])}>Yesterday</option>
                                    <option value={JSON.stringify([7, "days", "day", "In Last 7 Days"])}>In Last 7 Days</option>
                                    <option value={JSON.stringify([15, "days", "day", "In Last 15 Days"])}>In Last 15 Days</option>
                                    <option value={JSON.stringify([30, "days", "day", "In Last 30 Days"])}>In Last 30 Days</option>
                                </SelectList>
                              </Col>
                            </Row>
                        </Col>

                        <Col className="border text-muted" style={{borderRadius: 3}}>
                          <Row className="mt-2">
                            <Col xs={9}><strong style={{fontSize: 14}}>Who Did Not</strong>
                              <span className="text-muted ml-2" style={{fontSize: 12}}>(eg.What user not did yet)</span>
                            </Col>
                          </Row>

                          <Row>
                            <Col><span style={{fontSize: 14}}>Activity Type</span></Col>
                            <Col><span style={{fontSize: 14}}>Activity Duration</span></Col>
                          </Row>

                          <Row className="mb-3">
                            <Col>
                              <SelectList placeholder="Select Activity" style={{fontSize:12}} value={didNotActivityType} onChange ={(e) => this.selectDidNotActivityType(e.target.value)}>
                                  <option disabled value={"0"}>Referral</option>
                                  <option disabled value={"1"}>Watch Project Training</option>
                                  <option disabled value={"2"}>Search</option>
                                  <option disabled value={"4"}>Share QR</option>
                                  <option disabled value={"5"}>Create Query</option>
                                  <option disabled value={"6"}>App Uninstall</option>
                              </SelectList>
                            </Col>

                            <Col>
                              <SelectList placeholder="Select Duration" style={{fontSize:12}} value={didNotDurationType} onChange ={(e) => this.selectDidNotDurationType(e.target.value)}>
                                <option disabled value={"0"}>Today</option>
                                <option disabled value={"1"}>Yesterday</option>
                                <option disabled value={"2"}>In Last 7 days</option>
                                <option disabled value={"3"}>In Last 15 days</option>
                                <option disabled value={"4"}>In Last 30 days</option>
                              </SelectList>
                            </Col>
                         </Row>
                        </Col>
                      </Row>

                  </CardBody>
                </Card>
              </Collapse>


                      <Row className="my-1">
                        <Col xs={2}>
                          <span className="d-block ml-2 text-danger" style={{fontSize: 14}}>Total Partners Found</span>
                          <strong className="ml-2 text-danger">{userFilterList}</strong>                              
                        </Col>

                        {userFilterList ? 
                          <Col xs={1}>
                            <Button size="sm" className="buttonred mr-2 " style={{height: 23, paddingTop: 1}} onClick={()=> {this.setState({isOpencampaignCreateModal : true})}}>Create</Button>
                          </Col>
                          : ""
                        }

                        <Col className="text-right">
                          <Button className="buttonred mr-2" style={{height: 23, paddingTop: 1}} onClick={() => this.setState({filters: [], userFilterList: null, ageType : null, genderType: null, stateType: [], interestType: null, durationType: null , didActivityType: null , didDurationType: null , didNotActivityType: null , didNotDurationType: null , selectedQuery: null, selectedPmwId: null , selectedMobileNumber: null , selectedPincode: null, applicationJobId: null})}>
                            Clear
                          </Button>
                          <Button className="buttonred mr-2" style={{height: 23, paddingTop: 1}} onClick={() => this.findPartnerFilter()}>Find Partners</Button>
                        </Col>
                      </Row>
                  </CardBody>
                </Card>
              </Collapse>
          </div>

                 
                        <Modal centered isOpen={isOpencampaignCreateModal} toggle={() => this.setState({isOpencampaignCreateModal: false})}>
                              <ModalHeader toggle={() => this.setState({isOpencampaignCreateModal: false})}>
                                <h5 className="text-center text-dark mt-1" style={{fontSize: 16}}>Add New Campaign</h5>
                              </ModalHeader>
                              <ModalBody>

                                  <form onSubmit={this.createCampaign}>
                                      <Row>
                                        <Col>
                                          <InputFeild required name="title" className="mt-2 mb-3" placeholder="Enter your title" style={{fontSize: 14}} onChange={this.onChangeCreateCampaign} value={createCampaignDetails.title}/>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col>
                                          <textarea required placeholder="Enter your Description" name="description" rows={6} className=" w-100 mt-2 mb-3" style={{fontSize: 14}} onChange={this.onChangeCreateCampaign} value={createCampaignDetails.description}/>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={12} className="text-right">
                                          <Button type="submit" className="buttonred mr-2">Create</Button>
                                        </Col>
                                      </Row>  
                                  </form>                    
                              </ModalBody>
                        </Modal>

      </>
    );
  }
}

export default CampaignFilter;