import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button ,ModalBody, ModalHeader, Modal, Table } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import {uploadFile} from "../../../actions"
import store from "../../../store";
import SelectList from "../../global/form/select"
// import Story from "../../global/story"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import DummyImage from "../../../assets/images/dummy.jpg"
import { ChromePicker } from "react-color";
import RichTextEditor from "../../global/form/Richtext";
import InputFeild from "../../global/form/input"
import { Icon , TablePagination} from "@material-ui/core";
import moment from "moment";

class AddStory extends Component {
    constructor(props) {
      super(props);
      this.state = {
        storyType: "IMAGE",
        companyList : [],
        jobDetails : {}, 
        selectedFile : null,
        fileurl:null,
        storyTitle:"",
        storyText:"",
        displayColorPicker: false,
        rteSHOW:false,
        selectedColor:null,
        notification_title: this.props.defaultNotificationTitle || ""
        // storyList: [],
        // rowsPerPage : 20,
        // currentUserPage : 0,
        // isOpenConfirm : false,
      }
    }


    componentDidMount() {
      // this.fetchStory();
    }

  //   fetchStory = async () => {
  //     store.dispatch(setLoader(true))
  //     try{
  //       let resp = await ApiEndpoints.User.fetchStories ();
  //       this.setState({storyList : resp.data.data})
  //     }
  //     catch(e){
  //       store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
  //     }
  //     store.dispatch(setLoader(false))
  // }




    handleFile = async (e) => {
        let{selectedFile, fileurl, storyType} = this.state;
        fileurl= URL.createObjectURL(e.target.files[0]);
        
        selectedFile= e.target.files[0] 
        var fileSize = selectedFile.size/1024;
        if(storyType === "VIDEO" && fileSize > 3072){
          return store.dispatch(setError({show: true, message: "Video size must be below 3MB"}))
        }
        if(storyType === "IMAGE" && fileSize > 1024){
          return store.dispatch(setError({show: true, message: "Image size must be below 1MB"}))
        }

        this.setState({selectedFile,fileurl,selectedColor:null });
    }

    postStory = async (e) =>{
      e.preventDefault();
      store.dispatch(setLoader(true))
      let {storyType, selectedFile, selectedColor, notification_title} = this.state;
      let body = {
              gigger_name:"PickMyWork",
              text : this.state.storyText,
              notification_title,
              color: selectedColor,
              type: storyType,
              ...(this.props.prepareFilter ? this.props.prepareFilter() : {})
            }
      if((storyType === "IMAGE" || storyType === "VIDEO") && selectedFile) {
        let file = await uploadFile(selectedFile, "STORY_DATA");
        body.file_id = file.file_id;
      }
      try{
        await ApiEndpoints.STORY.addStory(body);
        store.dispatch(setSuccess({show: true, message: "Story added successfully."}))
        this.props.handleClose();
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))


    }

    render(){

        let { selectedColor, fileurl, storyType, storyList} = this.state;
        let { isOpenStory} = this.props;
        return(
                    <Modal size="lg" isOpen={isOpenStory} toggle={this.props.handleClose} >
                        <ModalHeader toggle={this.props.handleClose}>Add Story <br></br></ModalHeader>
                        <ModalBody>
                          <Row className="align-items-center">
                              <Col xs={12} md={6} >
                                <form onSubmit={(e) => this.postStory(e)}>
                                    <SelectList placeholder="Select Story Type" value={storyType} onChange={(e) => this.setState({storyType: e.target.value})}>
                                      <option value="TEXT">Text Story</option>
                                      <option value="IMAGE">Image Story</option>
                                      <option value="VIDEO">Video Story</option>
                                    </SelectList>
                                    <Row className="justify-content-around">
                                      {(storyType === "IMAGE" || storyType === "VIDEO") &&
                                        <Col>
                                        <span className="btn btn-secondary btn-file mt-3" >
                                            Upload {storyType === "IMAGE" ? "Image":"VIDEO"} <input  ref="file" type="file" multiple="false" onChange={this.handleFile}  />
                                        </span>
                                        </Col>
                                      }
                                      <Col>
                                        <span  onClick={() => this.setState({ displayColorPicker: !this.state.displayColorPicker })} className="btn btn-secondary btn-file mt-3" style={{backgroundColor:selectedColor}}>
                                            Choose Color 
                                        </span>
                                        { this.state.displayColorPicker ? <div style={{ position: 'absolute', zIndex: '2'}}>
                                              <div style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}}
                                                onClick={() => this.setState({ displayColorPicker: false })}></div>
                                              <ChromePicker color="#000" onChange={(color) => this.setState({selectedColor:color.hex})} />
                                            </div> : null }
                                      </Col>
                                    </Row>
                                   <InputFeild className="my-3" required placeholder="Notification Title" value={this.state.notification_title} onChange={(e) => this.setState({notification_title: e.target.value})} />
                                    <Row className="mt-2">
                                      <Col>
                                        <RichTextEditor required placeholder="Story Text" value={this.state.storyText || ""} onChange={(value)=> this.setState({storyText: value})}/>
                                      </Col>
                                    </Row>
                                    <Row className="justify-content-around my-2">
                                      <Button className="buttonred btn-file mt-3" style={{width:"170px"}}>Post Story</Button>
                                    </Row>
                                    {this.props.userCount !== undefined ? <em className="text-muted">Story will be add to {this.props.userCount} users</em> : null}
                                  </form>
                              </Col>
                              <Col xs={12} md={6}>
                                
                                <div style={{width: 180, height: 320, backgroundColor:selectedColor || "#ccc"}}>
                                {storyType==="VIDEO" && <video src={fileurl} controls loop autoPlay alt="story" style={{width: 180, position: "absolute", top: "50%", transform: "translateY(-50%)"}} />}
                                {storyType==="IMAGE" && <img src={fileurl || DummyImage} alt="story" style={{width: 180, position: "absolute", top: "50%", transform: "translateY(-50%)"}} />}
                                {storyType==="TEXT" && 
                                  <div style={{textAlign: "center", width: 180, position: "absolute", top: "50%", transform: "translateY(-50%)"}}>
                                    <span dangerouslySetInnerHTML={{__html: this.state.storyText||""}}></span>
                                  </div>
                                }
                                </div>
                                
                              </Col>
                          </Row>



                          {/* <Table striped className="table-sm" >
                                <thead>
                                    <tr>
                                    <th style={{width : "15%"}}>Created by</th>
                                    <th style={{width : "15%"}}>Date / Time</th>
                                    <th style={{width : "20%"}}>Project Name</th>
                                    <th>Image</th>
                                    <th style={{width : "40%"}}>Content</th>
                                    <th style={{width : "10%"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {storyList.slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage).map((obj) =>
                                    <tr style={{fontSize : "14px"}}>
                                        <td>{obj.user_data.first_name}</td>
                                        <td>{moment(obj.created_on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm A")}</td>
                                        <td>{obj.project_title}</td>
                                        <td style={{textAlign: "center"}}>
                                            { !!obj.story_image_id && !obj.file ?
                                                <Icon onClick={() => this.showStoryImage(obj.success_story_id)} className="mt-1" style={{cursor: "pointer", fontSize: "16px"}}>visibility</Icon>
                                            :"-"
                                            }
                                           {obj.file && <img src={obj.file.url} width={100}/>}
                                        </td>
                                        <td dangerouslySetInnerHTML={{__html: obj.text}}></td>
                                        <td><Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedStory(obj.success_story_id)}) } className="mt-1" style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                            <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={storyList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                                onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                                onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                            /> */}
                        </ModalBody>
                    </Modal>
        );
    }
}
export default AddStory;
