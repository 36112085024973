import { Edit } from "@material-ui/icons";
import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import SelectList from "../../global/form/select";

class CallersCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_active: this.props.element.is_active,
      caller_status: this.props.element.status,
      caller_phone: this.props.element.caller_phone,
      calling_zone: this.props.element.calling_zone,
      caller_name: this.props.element.caller_name,
      calling_team: this.props.element.calling_team,
      editModal: false,
      editZones: [],
      zones: [],
    };
  }
  componentDidMount() {
    this.stringZonesToArray();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.element.caller_name !== this.props.element.caller_name) {
      this.setState({
        is_active: this.props.element.is_active,
        caller_status: this.props.element.status,
        caller_phone: this.props.element.caller_phone,
        calling_zone: this.props.element.calling_zone,
        caller_name: this.props.element.caller_name,
        calling_team: this.props.element.calling_team,
      });
      this.stringZonesToArray();
    }
  }
  stringZonesToArray = () => {
    const zonesArray = this.props.element.calling_zone.split(",");
    if (zonesArray.includes("PAN")) {
      zonesArray[zonesArray.indexOf("PAN")] = "Non-South";
    }
    console.log(zonesArray);
    this.setState({ editZones: zonesArray, zones: zonesArray });
  };
  updateStatus = async (callerData, updateStatus) => {
    store.dispatch(setLoader(true));
    try {
      await ApiEndpoints.Calling.updateInboundStatus({
        status: updateStatus,
        caller_user_id: callerData.caller_user_id,
      });
      this.setState({ caller_status: updateStatus });
    } catch (error) {
      store.dispatch(
        setError({
          show: true,
          message: error.response
            ? error.response.data.message
            : error.toString(),
        })
      );
    }
    store.dispatch(setLoader(false));
  };
  updateCaller = async (callerData) => {
    store.dispatch(setLoader(true));
    try {
      const { editZones } = this.state;
      editZones.sort();
      if (editZones.includes("Non-South")) {
        editZones[editZones.indexOf("Non-South")] = "PAN";
      }
      const stringifiedZones = editZones.join(",");
      const body = {
        caller_user_id: callerData.caller_user_id,
        calling_zone: stringifiedZones,
      };
      await ApiEndpoints.Calling.updateCaller(body);
      this.setState({
        calling_zone: stringifiedZones,
        zones: editZones,
      });
    } catch (error) {
      store.dispatch(
        setError({
          show: true,
          message: error.response
            ? error.response.data.message
            : error.toString(),
        })
      );
    }
    this.setState({ editModal: false });
    store.dispatch(setLoader(false));
  };
  offlineButton = (callerData) => {
    const { caller_status } = this.state;
    return (
      <button
        type="button"
        className="btn btn-secondary flex-fill rounded m-1"
        onClick={() => this.updateStatus(callerData, "0")}
        disabled={caller_status === "0" ? true : false}
        style={{
          border: "0px",
          maxHeight: "36px",
          backgroundColor: caller_status === "0" ? "red" : "#999999",
        }}
      >
        Offline
      </button>
    );
  };
  inboundButton = (callerData) => {
    const { caller_status } = this.state;
    return (
      <button
        type="button"
        className="btn btn-secondary flex-fill rounded m-1"
        onClick={() => this.updateStatus(callerData, "1")}
        disabled={caller_status === "1" ? true : false}
        style={{
          border: "0px",
          maxHeight: "36px",
          backgroundColor: caller_status === "1" ? "green" : "#999999",
        }}
      >
        Inbound
      </button>
    );
  };
  autoDialerButton = (callerData) => {
    const { caller_status } = this.state;
    return (
      <button
        type="button"
        className="btn btn-secondary flex-fill rounded m-1"
        onClick={() => this.updateStatus(callerData, "2")}
        disabled={caller_status === "2" ? true : false}
        style={{
          border: "0px",
          maxHeight: "36px",
          backgroundColor: caller_status === "2" ? "green" : "#999999",
        }}
      >
        Auto Dialer
      </button>
    );
  };
  callerInfo = () => {
    const { caller_name, caller_phone, calling_team } = this.state;
    return (
      <div className="d-flex flex-column col-md-1 justify-content-center px-0">
        <span style={{ fontSize: 16, fontWeight: "bold" }}>{caller_name}</span>
        <span style={{ fontSize: 14 }}>{caller_phone}</span>
      </div>
    );
  };
  callingZone = () => {
    const { zones, calling_team } = this.state;
    return (
      <div className="col-md-6 px-0 mt-3 mt-md-0">
        {calling_team === 1 || calling_team === 3 ? (
          <span
            className="row justify-content-center"
            style={{ fontSize: 14, fontWeight: "bold" }}
          >
            Inbound state(s)
            <br />
          </span>
        ) : null}
        <div className="row justify-content-center m-0 p-0">
          {zones.map((state, index) => {
            return state === "PAN" ? (
              <span key={index} className="px-1" style={{ fontSize: 14 }}>
                {index === zones.length - 1 ? "Non-south." : "Non-south, "}
              </span>
            ) : (
              <span key={index} className="px-1" style={{ fontSize: 14 }}>
                {index === zones.length - 1 ? `${state}.` : `${state}, `}
              </span>
            );
          })}
        </div>
      </div>
    );
  };
  editZone = () => {
    const { is_active } = this.state;
    return (
      <button
        className="col-md-1"
        disabled={is_active === "1" ? false : true}
        style={{
          border: "0",
          backgroundColor: is_active === "1" ? "#FFFFFF" : "#e0e0e0",
        }}
        onClick={() => this.setState({ editModal: true })}
      >
        <Edit />
      </button>
    );
  };
  statusButtonGroup = (element) => {
    const { calling_team } = this.state;
    return (
      <div className="d-flex flex-column flex-md-row col-md-4">
        {this.offlineButton(element)}
        {calling_team === 1 ? this.inboundButton(element) : null}
        {calling_team === 2 ? this.autoDialerButton(element) : null}
        {calling_team === 3 ? (
          <>
            {this.inboundButton(element)}
            {this.autoDialerButton(element)}
          </>
        ) : null}
      </div>
    );
  };
  editZoneModal = (callerData) => {
    const { editModal, editZones } = this.state;
    return (
      <Modal
        isOpen={editModal}
        toggle={() => {
          this.setState({
            editModal: false,
          });
          this.stringZonesToArray();
        }}
      >
        {" "}
        <ModalBody>
          <SelectList
            multiple="checkbox"
            placeholder="Select state(s)"
            value={editZones}
            required
            onChange={(e) => this.setState({ editZones: e.target.value })}
          >
            {zones.map((item, i) => (
              <option key={i} value={item}>
                {" "}
                {item}
              </option>
            ))}
          </SelectList>
        </ModalBody>
        <ModalFooter>
          <button
            className="border-0 py-1 px-2 rounded text-light bg-danger"
            onClick={() => {
              this.setState({
                editModal: false,
              });
              this.stringZonesToArray();
            }}
          >
            Cancel
          </button>
          <button
            disabled={editZones.length > 0 ? false : true}
            className="border-0 py-1 px-2 rounded text-light"
            style={{
              backgroundColor: editZones.length > 0 ? "green" : "gray",
            }}
            onClick={() => this.updateCaller(callerData)}
          >
            Update
          </button>
        </ModalFooter>
      </Modal>
    );
  };
  render() {
    const { element, index } = this.props;
    const { is_active } = this.state;
    return (
      <div
        key={index}
        className="border shadow-sm rounded my-2 p-3 d-flex flex-column flex-md-row flex-md-1 align-items-center justify-content-between"
        style={{
          borderRadius: "12px",
          backgroundColor: is_active === "0" ? "#e0e0e0" : "#FFFFFF",
          color: is_active === "0" ? "#969696" : "#000000",
        }}
      >
        {this.callerInfo()}
        {this.callingZone()}
        {this.editZone()}
        {this.statusButtonGroup(element)}
        {this.editZoneModal(element)}
      </div>
    );
  }
}
export default CallersCard;

const zones = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu & Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Non-South",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "Uttaranchal",
  "West Bengal",
];
