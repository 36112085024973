import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ApiEndpoints from "../../../../constants/api_endpoints";
import Aux from "../../../global/AuxComp";
import InputFeild from "../../../global/form/input";
import ImageUploadFeild from "../../../global/form/upload-image";
import { Button } from "reactstrap";
import { uploadFile } from "../../../../actions"
import {setSuccess, setError, setLoader } from '../../../../actions/action-creators';
import store from '../../../../store';
import { Icon, Switch } from "@material-ui/core";

class CompanyCreate extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isOpenCardModel : false,
        companyInputs : this.props.company || {job_type_access : "INTERNAL"},
        companyLogo : null
        }
    }

    componentDidMount(){
      
    }

    onChangeCompanyFeild = (e) => {
      let { companyInputs } = this.state; 
      companyInputs[e.target.name] = e.target.value;
      this.setState({companyInputs})
    }
    
    onChangeLogo =(file) => {
      this.setState({companyLogo : file})
    }
    
    createCompany = async (e) => {
      e.preventDefault();
      store.dispatch(setLoader(true))
      let { companyInputs, companyLogo } = this.state;
      try{
        if(companyLogo){
            let fileName = "project/logos/"+ new Date().getTime() + "_" + companyLogo.name;
            let fileResp = await ApiEndpoints.Files.filePublicPutUrl({file_name: fileName});
            let file_url = fileResp.data.data.url;
            if (!file_url) throw new Error("Unable to upload file. Please try again later.");
            var config = { headers: { "Content-Type": companyLogo.type }}
            await ApiEndpoints.Files.uploadFileToS3(file_url, companyLogo, config);
            companyInputs.logo_key = fileResp.data.data.fileUrl;
          // let file = await uploadFile(companyLogo, "COMPANY_DATA");
          // companyInputs.logo_key = file.file_id;
        };

        companyInputs.employer_id ?
          await ApiEndpoints.Employer.editEmployer(companyInputs)
          :
          await ApiEndpoints.Employer.addEmployer(companyInputs);
        this.props.onClose && this.props.onClose();
        // this.setState({companyInputs : {}, companyLogo : null});
        // store.dispatch(setSuccess({show: true, message: "Company Added!"}));
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

    render() {
      let { companyInputs } = this.state;
        return (
          <Row>
            <Col className="px-5 py-3">
              <form onSubmit={this.createCompany}>
                <InputFeild required name="employer_name" className="my-3" placeholder="Company Name" onChange={this.onChangeCompanyFeild} value={companyInputs.employer_name || ""}/>
                <InputFeild required name="sector" className="my-3" placeholder="Type : Marketing" onChange={this.onChangeCompanyFeild} value={companyInputs.sector || ""}/>
                <InputFeild name="website" className="my-3" placeholder="Company Website" onChange={this.onChangeCompanyFeild} value={companyInputs.website || ""}/>
                <InputFeild name="location" className="my-3" placeholder="Company Address" onChange={this.onChangeCompanyFeild} value={companyInputs.location || ""} />
                <ImageUploadFeild rounded required={companyInputs.logo_url ? false : true} src={companyInputs.logo_url} onChange={this.onChangeLogo}/>
                <div className="mt-3">
                  Secondary Projects
                  <Switch checked={companyInputs.job_type_access === "INTERNAL" ? true : false} color="primary" onChange={(e) => this.onChangeCompanyFeild({target : {name : "job_type_access", value : (e.target.checked ? "INTERNAL" : "WALKIN")}})} />
                  Internal Projects
                </div>
                <Button type="submit" className="buttonred my-3">{companyInputs.employer_id ? "Edit" : "Add"} Company</Button>
              </form>
            </Col>
          </Row>
        );
    }
}

export default CompanyCreate;
