import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Collapse, Button, CardBody, Card, UncontrolledButtonDropdown } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css";
import { Progress } from 'reactstrap';
import {uploadFile} from "../../../actions";
import store from "../../../store";
import { setError , setLoader} from "../../../actions/action-creators";
import VideoUploadFeild from "../../global/form/upload-video";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import InputFeild from "../../global/form/input";
import { Checkbox, FormControlLabel, Select, Switch, Tab, Tabs, Tooltip } from "@material-ui/core";
import moment from "moment";
import FormGroup from "reactstrap/lib/FormGroup";
import Input from "reactstrap/lib/Input";
import ImageUploadFeild from "../../global/form/upload-image";

class JobMedia extends Component {
    constructor(props) {
      super(props);
      this.addEditTrainingQuesView = React.createRef()  
      this.state = {
            isAddJDMedia: false,
            addJDMediaprogress: false,
            mediaList : [] ,
            isAddMedia: false,
            isEditMedia: false,
            isAddMediaContent: false,
            isOpenConfirm : false,
            isLoadingAdd : false,
            addMediaContentprogress : false,
            selectedTab: 0,
            selectedTrainingMedia: false,
            selectedRejections: false,
            mediaReasons: [],
            isEditReason: false,
            isAddReason: false,
            isOpenTrainingQues: false,
            trainingQuestions: [],
            isAddQuestion: false,
            isEditQuestionTiming: false,
            trainingQuesTimingData: []
        }
    }
    componentDidMount(){
        this.fetchMedia()
    }

    onChangeAddMedia = (key, value) => {
        let { addMedia } = this.state;
        addMedia[key] = value;
        this.setState({addMedia});
    }
    
    fetchMedia = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id : this.props.selectedJob.job_id ,
            }
            let apiData = await ApiEndpoints.Jobs.fetchJobMedia(body);
            let mediaList = apiData.data.data.sort((a, b) => a.step_number > b.step_number ? 1 : -1);
            
            await Promise.all(mediaList.map(async media => {
                await Promise.all(media.JobMediaContents.map(async content => {
                    if(content.file_key){
                        let fileResp = await ApiEndpoints.Files.getFileID({file_id : content.file_key});
                        content.url = fileResp.data.data[0] && fileResp.data.data[0].url;
                    }
                }))
            }));
            // await Promise.all(mediaList.map(async media => {
            //    let fileResp = await ApiEndpoints.Files.getFileID({file_id : media.file_key})
            //    media.url = fileResp.data.data[0] && fileResp.data.data[0].url;
            // }))
            this.setState({ mediaList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    submitJDVideo = async (e, jobMedia = {}) => {
        e.preventDefault();
        let { isAddJDMedia } = this.state;
        var jdVideoResp = await uploadFile(isAddJDMedia.file, "JOB_DATA", (progress) => this.setState({addJDMediaprogress : progress}));
        if(!jobMedia.job_media_id){
            var contentBody = {
                job_id : this.props.selectedJob.job_id ,
                type : "JD",
                title: "Job Description",
                step_number: 1
            };
            var resp = await ApiEndpoints.Jobs.addJobMedia(contentBody);
            jobMedia = resp.data.data;
        }
        var body = {
            job_media_id: jobMedia.job_media_id,
            file_key : jdVideoResp.file_id,
            language : isAddJDMedia.language,
            media_length : 1,
            description: "Job Description"
        }
        await ApiEndpoints.Jobs.addJobMediaContent(body);
        this.fetchMedia()
        this.setState({addJDMediaprogress : false, isAddJDMedia : false})
    }

    addMediaSubmit = async (e) => {
        e.preventDefault();
        let {title, category, step_number, consumer_type} = e.target;
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id : this.props.selectedJob.job_id ,
                type : "TRAINING",
                title: title.value,
                category: category.checked ? "SECONDARY": "PRIMARY",
                consumer_type: consumer_type.value || 0
            };
            if(step_number && step_number.value) body.step_number = step_number.value;
            if(this.state.isEditMedia) {
                body.job_media_id = this.state.isEditMedia.job_media_id;
                await ApiEndpoints.Jobs.editJobMedia(body);
            }
            else {
                body.step_number = this.state.mediaList.filter(o => o.type === "TRAINING").length + 1;
                await ApiEndpoints.Jobs.addJobMedia(body);
            }
            this.setState({isAddMedia: false, isEditMedia: false});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.fetchMedia();
        store.dispatch(setLoader(false));
    }

    deleteMedia = async (job_media_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {job_media_id};
            await ApiEndpoints.Jobs.inactiveJobMedia(body);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.fetchMedia();
        store.dispatch(setLoader(false));
    }

    showMediaContent = async (job_media_id) => {
        let {selectedTrainingMedia} = this.state;
        if(selectedTrainingMedia === job_media_id) this.setState({selectedTrainingMedia: false});
        else{
            store.dispatch(setLoader(true));
            try{
                // var body = {job_media_id};
                // var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
                // this.setState({mediaReasons: reasonResp.data.data, selectedRejections: false})
                this.setState({selectedTrainingMedia: selectedTrainingMedia === job_media_id ? false : job_media_id})
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }

    showRejections = async (job_media_id) => {
        let {selectedRejections} = this.state;
        if(selectedRejections === job_media_id) this.setState({selectedRejections: false});
        else{
            store.dispatch(setLoader(true));
            try{
                var body = {job_media_id};
                var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
                this.setState({mediaReasons: reasonResp.data.data, selectedTrainingMedia: false, isOpenTrainingQues: false})
                this.setState({selectedRejections: selectedRejections === job_media_id ? false : job_media_id})

            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }

    showTrainingQuestions = async (job_media_id) => {
        let {isOpenTrainingQues} = this.state;
        if(isOpenTrainingQues === job_media_id) this.setState({isOpenTrainingQues: false});
        else{
            store.dispatch(setLoader(true));
            try{
                var body = {job_media_id};
                var reasonResp = await ApiEndpoints.TrainingQuestion.fetchTrainingQuestion(body);
                this.setState({trainingQuestions: reasonResp.data.data, selectedTrainingMedia: false, selectedRejections: false})
                this.setState({isOpenTrainingQues: job_media_id})

            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }
    openTrainingQuestionTiming = async (content) => {
        let {isEditQuestionTiming} = this.state;
        if(isEditQuestionTiming === content.job_media_id) this.setState({isEditQuestionTiming: false});
        else{
            store.dispatch(setLoader(true));
            try{
                var body = {job_media_id: content.job_media_id};
                var reasonResp = await ApiEndpoints.TrainingQuestion.fetchTrainingQuestion(body);
                this.setState({trainingQuesTimingData: reasonResp.data.data})
                this.setState({isEditQuestionTiming: content.job_media_content_id})

            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }


    addMediaContentSubmit = async () => {
        store.dispatch(setLoader(true));
        let { isAddMediaContent } = this.state;
        try{

            var contentBody = {
                job_media_id : isAddMediaContent.job_media_id ,
                language : isAddMediaContent.language,
                media_length : isAddMediaContent.media_length,
                description: isAddMediaContent.description,
                youtube_url: isAddMediaContent.youtube_url
            };
            if(isAddMediaContent.file) {
                var jdVideoResp = await uploadFile(isAddMediaContent.file, "JOB_DATA", (progress) => this.setState({addMediaContentprogress : progress}));
                contentBody.file_key = jdVideoResp.file_id;
            }
            if(!contentBody.file_key && !isAddMediaContent.youtube_url){
                throw new Error("Mandatory details missing: Video file or youtube link");
            }
            await ApiEndpoints.Jobs.addJobMediaContent(contentBody);
            this.fetchMedia();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({addMediaContentprogress : false, isAddMediaContent: false})
        store.dispatch(setLoader(false));
    }

    deleteMediaContent = async (job_media_content_id) => {
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.inactiveJobMediaContent({job_media_content_id});
            this.fetchMedia();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    editTaggedReason = async (e, media) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try{
            let body = {
                job_id: this.props.selectedJob.job_id,
                job_media_id: media.job_media_id,
                reason: e.target.taggedReason.value.split(",").map(v => v.trim())
            }
            await ApiEndpoints.JobTask.editTaggedReason(body);
            this.showMediaContent(media.job_media_id);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    addMediaReason = async (e, media) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let {mediaReasons} = this.state;
        try{
            let body = {
                job_id: this.props.selectedJob.job_id,
                job_media_id: media.job_media_id,
                reason: e.target.reason.value,
                is_audit_required: e.target.audit.checked ? 1 : 0,
                penalty_amount: e.target.penalty.value || 0
            }
            if(media.task_training_media_id) {
                body.task_training_media_id = media.task_training_media_id;
                await ApiEndpoints.JobTask.editRejectionReason(body);
                mediaReasons = mediaReasons.map(reason => {
                    if(reason.task_training_media_id === media.task_training_media_id){
                        reason = {...reason, ...body};
                    };
                    return reason;
                })
            }
            else {
                let addResp = await ApiEndpoints.JobTask.addRejectionReason(body);
                mediaReasons.push(addResp.data.data);
            }
            this.setState({isAddReason: false, isEditReason: false, mediaReasons});

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    addTrainingQuestion = async (e, media) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let {trainingQuestions} = this.state;
        let {question, option_1, option_2, option_3, option_4, right_answer, language, is_mandatory} = e.target;
        try{
            let body = {
                job_media_id: media.job_media_id,
                question: question.value,
                options: [option_1.value.trim(), option_2.value.trim(), option_3.value.trim(), option_4.value.trim(),].join(";;"),
                right_answer: e.target[right_answer.value].value.trim(),
                language: language.value,
                is_mandatory: is_mandatory.checked ? 1 : 0
            }
            if(media.job_training_question_id) {
                body.job_training_question_id = media.job_training_question_id;
                await ApiEndpoints.TrainingQuestion.editTrainingQuestion(body);
                trainingQuestions = trainingQuestions.map(ques => {
                    if(ques.job_training_question_id === media.job_training_question_id){
                        ques = {...ques, ...body};
                    };
                    return ques;
                })
            }
            else {
                let addResp = await ApiEndpoints.TrainingQuestion.addTrainingQuestion(body);
                trainingQuestions.push(addResp.data.data);
            }
            this.setState({isAddQuestion: false, isEditQuestion: false, trainingQuestions});

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    inactiveMediaReason = async data => {
        store.dispatch(setLoader(true));
        let {mediaReasons} = this.state;
        try{
            let body = {
                task_training_media_id: data.task_training_media_id
            }
            await ApiEndpoints.JobTask.inactiveMediaReason(body);
            mediaReasons.splice(mediaReasons.findIndex(reason => reason.task_training_media_id === data.task_training_media_id), 1);
            this.setState({mediaReasons})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    inactiveQuestion = async data => {
        store.dispatch(setLoader(true));
        let {trainingQuestions} = this.state;
        try{
            let body = {
                job_training_question_id: data.job_training_question_id
            }
            await ApiEndpoints.TrainingQuestion.deleteTrainingQuestion(body);
            trainingQuestions.splice(trainingQuestions.findIndex(ques => ques.job_training_question_id === data.job_training_question_id), 1);
            this.setState({trainingQuestions})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addTrainingQuestionTimings = async (e, content) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let {trainingQuesTimingData} = this.state;
        try{
            await Promise.all(trainingQuesTimingData.map(async ques => {
                var timingData = ques.JobTrainingMediaQuestions.filter(o => o.job_media_content_id === content.job_media_content_id)[0];
                let body = {
                    job_training_question_id: ques.job_training_question_id,
                    job_media_content_id: content.job_media_content_id,
                    percentage_complete: e.target["question_"+ques.job_training_question_id].value
                }
                if(timingData){
                    body.job_training_media_question_id = timingData.job_training_media_question_id;
                    return await ApiEndpoints.TrainingQuestion.editQuestionTiming(body);
                }
                else return await ApiEndpoints.TrainingQuestion.addQuestionTiming(body);
                
            }));
            this.setState({isEditQuestionTiming: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addVideoThumbnail = async (mediaContent, file) => {

        let mediaList = this.state.mediaList;
        try{
            let fileUrl = file ? URL.createObjectURL(file) : null;
            var body = {job_media_content_id: mediaContent.job_media_content_id};
            if(file) {
                var fileResp = await uploadFile(file, "JOB_DATA");
                body.thumbnail_file_key =  fileResp.file_id;
            } else body.thumbnail_file_key = 0;
            await ApiEndpoints.Jobs.editJobMediaContent(body);
            mediaList = mediaList.map(media => {
                media.JobMediaContents = media.JobMediaContents.map(content => {
                    if(content.job_media_content_id === mediaContent.job_media_content_id) content.thumbnail_file_url = fileUrl;
                    return content;
                });
                return media;
            });
            this.setState({mediaList});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    getTrainingVideoFile = (e) => {
        let { isAddMediaContent } = this.state;
        let checkSize = e.target.duration * 204.8; //per second 200kb (1024/10)
        var fileSize = isAddMediaContent.file.size/1024;

        if(fileSize > checkSize){
            isAddMediaContent.file = null;
            this.setState({ isAddMediaContent });
            return store.dispatch(setError({show: true, message: `Video size must be below ${checkSize}KB (200kb/second)`}));
        }
        isAddMediaContent.media_length = e.target.duration;
        this.setState({ isAddMediaContent });
    }

    render() {
        let { selectedTab, selectedTrainingMedia, isAddReason, selectedRejections, isOpenTrainingQues, mediaList, isAddJDMedia, isAddMedia, isEditMedia, isAddMediaContent, mediaReasons, isEditReason, isAddQuestion, trainingQuestions, isEditQuestionTiming } = this.state;
        let jdVideo = mediaList.filter(o => o.type === "JD")[0];
        let trainingVideo = mediaList.filter(o => o.type === "TRAINING")
        return(
        <>
            <Row className="border-bottom pb-1 mb-3">
                <Col>
                    <Tabs style={{minHeight: 30}} variant="standard" value={selectedTab} onChange={(e, i) => this.setState({selectedTab: i})} aria-label="basic tabs example">
                        <Tab style={{minHeight: 30}} label="Training Video"  id='simple-tab-TRAINING' aria-controls='simple-tabpanel-TRAINING' />
                        <Tab style={{minHeight: 30}} label="JD Video" id='simple-tab-JD' aria-controls='simple-tabpanel-JD' />
                    </Tabs>
                </Col>
            </Row>
            <div role="tabpanel" hidden={selectedTab !== 1} id={`simple-tabpanel-JD`} aria-labelledby={`simple-tab-JD`} >
                {selectedTab === 1 && (
                    <>
                    <Button className="buttonred my-2" onClick={() => this.setState({isAddJDMedia: isAddJDMedia ? false : {}})} >Add Video</Button>
                    {
                        isAddJDMedia && 
                        <Card>
                            <CardBody>
                            <form onSubmit={(e) => this.submitJDVideo(e, jdVideo)}>
                                {this.state.addJDMediaprogress !== false && <Progress animated color="info" value={this.state.addJDMediaprogress}>{this.state.addJDMediaprogress}%</Progress>}
                                <Row>
                                    <Col xs={6}>
                                        <VideoUploadFeild id="video_upload_input" required className="mt-2" label={"Add Video"} onChange={(file) => {isAddJDMedia.file = file; this.setState({isAddJDMedia})}}/>
                                    </Col>
                                    <Col>
                                        <SelectList id="select_language" placeholder="Select Language" value={isAddJDMedia.language} onChange={(e) => {isAddJDMedia.language = e.target.value; this.setState({isAddJDMedia})}}>
                                            <option value="Hindi">Hindi</option>
                                            <option value="English">English</option>
                                            <option value="Telugu">Telugu</option>
                                            <option value="Tamil">Tamil</option>
                                            <option value="Kannada">Kannada</option>
                                        </SelectList>
                                    </Col>
                                </Row>
                                <Row className="justify-content-end">
                                    <Col xs={3}>
                                        <Button id="video_upload_submit" className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                        <Button id="video_upload_cancel" type="button" color="secondary" onClick={()=> {this.setState({isAddJDMedia : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                    </Col>
                                </Row>
                            </form>
                            </CardBody>
                        </Card>
                    }
                    <Row >
                    {
                        jdVideo && jdVideo.JobMediaContents.map((media, i) => 
                            <Col xs={3}  className="my-1">
                                <video style={{width: "100%"}} preload="none" className="d-block mb-1" alt="Job Media" src={media.url} controls height={100} />
                                <div><span className="pl-1">{media.language}</span><Button id={"delete_media" + i} onClick={() => this.setState({isOpenConfirm : () => this.deleteMediaContent(media.job_media_content_id)}) } color="link" className="text-dark float-right p-0" ><Icon style={{fontSize : 18}}>delete</Icon></Button></div>
                            </Col>
                        )
                    }
                    </Row>
                    </>
                )}
            </div>
            <div role="tabpanel" hidden={selectedTab !== 0} id={`simple-tabpanel-TRAINING`} aria-labelledby={`simple-tab-TRAINING`} >
                {selectedTab === 0 && (
                    <>
                        <Button className="buttonred my-2" onClick={() => this.setState({isAddMedia: isAddMedia ? false : {}})} >Add New Part</Button>
                        {!!isAddMedia &&
                        <Row className="shadow-sm my-2 card mx-1">
                            <Col>
                                <form id="addMediaForm" onSubmit={this.addMediaSubmit}>
                                <Row className="border-bottom py-2 align-items-center">
                                    
                                    <Col xs={6}>
                                        <InputFeild required name="title" placeholder="Enter Title" />
                                    </Col>
                                    <Col xs={4} className="">
                                        <FormControlLabel className="mb-0" control={<Switch name="category" size="small" className="" />} label="Rejection Training?" />
                                        <Select defaultValue={0} name="consumer_type" className="w-100">
                                            <option value={""} disabled>Select Audience</option>
                                            <option value={0}>Everyone</option>
                                            <option value={1}>Only Individual Partners</option>
                                            <option value={2}>Only Shop Owners</option>
                                        </Select>
                                    </Col>
                                    <Col xs={2} className="pt-2 text-right">
                                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 18}} className="mr-2">done</Icon></Button>
                                        <Button color="link" className="p-0" type="button" onClick={() => this.setState({isAddMedia: false})}><Icon style={{fontSize: 18}} className="mr-2">close</Icon></Button>
                                    </Col>
                                </Row>
                                </form>
                            </Col>
                        </Row>
                        }
                        {trainingVideo.map((media, i) => 
                            <Row className="shadow-sm my-2 card align-items-center mx-1">
                                <Col>
                                    {isEditMedia.job_media_id === media.job_media_id ? 
                                    <form id="addMediaForm" onSubmit={this.addMediaSubmit}>
                                        <Row className="border-bottom py-2 align-items-center">
                                            <Col xs={1}>
                                                <InputFeild required name="step_number" placeholder="Step No" defaultValue={media.step_number} />
                                            </Col>
                                            <Col xs={5}>
                                                <InputFeild required name="title" placeholder="Enter Title" defaultValue={media.title} />
                                            </Col>
                                            <Col xs={4} className="">
                                                <FormControlLabel className="mb-0" control={<Switch defaultChecked={media.category !== "PRIMARY"} name="category" size="small" color="default" className="" />} label="Rejection Training?" />
                                                <Select defaultValue={media.consumer_type || 0} name="consumer_type" className="w-100">
                                                    <option value={""} disabled>Select Audience</option>
                                                    <option value={0}>Everyone</option>
                                                    <option value={1}>Only Individual Partners</option>
                                                    <option value={2}>Only Shop Owners</option>
                                                </Select>
                                            </Col>
                                            <Col xs={2} className="pt-2 text-right">
                                                <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 18}} className="mr-2">done</Icon></Button>
                                                <Button color="link" className="p-0" type="button" onClick={() => this.setState({isEditMedia: false})}><Icon style={{fontSize: 18}} className="mr-2">close</Icon></Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    :
                                    <Row className="border-bottom py-2 align-items-center" >
                                        <Col xs={5}>
                                            <h6 className="my-2">{i+1}. {media.title}</h6>
                                        </Col>
                                        <Col xs={4}>
                                            {media.category === "SECONDARY" && <span style={{fontSize: 12, color: "red"}}>Rejection Training</span>}
                                            {!!media.consumer_type && <span className="d-block" style={{fontSize: 12, color: "#666"}}>{media.consumer_type === 1 ? "For Individual Partner" : "For Shop Owner"}</span>}
                                        </Col>
                                        <Col xs={3} className="pt-2">
                                            {media.category === "SECONDARY" && <Button className="py-1 p-0 pr-2" size="sm" color="link" style={{marginTop: "-10px", color: "black", fontSize : 12, fontWeight: "bold"}} onClick={() => this.showRejections(media.job_media_id)}>Rejections</Button>}
                                            {media.category === "PRIMARY" && <Button className="py-1 p-0 pr-2" size="sm" color="link" style={{marginTop: "-10px", color: "black", fontSize : 12, fontWeight: "bold"}} onClick={() => this.showTrainingQuestions(media.job_media_id)}>Questions</Button>}
                                            <Icon style={{fontSize: 18, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.deleteMedia(media.job_media_id) })}>delete</Icon>
                                            <Icon style={{fontSize: 18, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditMedia: media})}>edit</Icon>
                                            <Icon style={{fontSize: 20, cursor: "pointer"}} className="" onClick={() => this.showMediaContent(media.job_media_id)}>unfold_more</Icon>
                                        </Col>
                                    </Row>
                                    }

                                    {selectedRejections === media.job_media_id &&
                                       <div className="py-2">
                                            <Button className="buttonred my-2 p-0 px-1" onClick={() => this.setState({isAddReason : !isAddReason})}>Add Reason</Button>
                                            <Row className="border-bottom mb-2" style={{fontWeight : "bold"}}>
                                                <Col xs={5}><span>Reason</span></Col>
                                                <Col xs={2} className="text-center"><span>Audit</span></Col>
                                                <Col xs={3} className="text-center"><span>Penalty</span></Col>
                                                <Col xs={2}><span>Action</span></Col>
                                            </Row>
                                            {!!isAddReason &&
                                            <form onSubmit={(e) => this.addMediaReason(e, media)}>
                                                <Row className="border-bottom mb-2" style={{fontWeight : "bold"}}>
                                                    <Col xs={5}><InputFeild required name="reason" placeholder="Type Reason"/></Col>
                                                    <Col xs={2} className="text-center"><Checkbox name="audit" size="small" className="" /></Col>
                                                    <Col xs={3} className="text-center"><InputFeild type="number" inputProps={{min: 0}} placeholder="Amount (if any)" name="penalty" /></Col>
                                                    <Col xs={2}>
                                                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 14}} className="mr-2">done</Icon></Button>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isAddReason : false})}>close</Icon>
                                                    </Col>
                                                </Row>
                                            </form>
                                            }
                                            {
                                            mediaReasons.map((obj,i) => 
                                            isEditReason.task_training_media_id === obj.task_training_media_id ?
                                                <form onSubmit={(e) => this.addMediaReason(e, obj)}>
                                                    <Row className="my-2">
                                                        <Col xs={5}><InputFeild required name="reason" style={{fontSize: 14}} placeholder="Type Reason" defaultValue={obj.reason} /></Col>
                                                        <Col xs={2} className="text-center"><Checkbox defaultChecked={!!obj.is_audit_required} name="audit" size="small" className="" /></Col>
                                                        <Col xs={3} className="text-center"><InputFeild type="number" inputProps={{min: 0}} defaultValue={obj.penalty_amount || ""} placeholder="Amount (if any)" name="penalty" /></Col>
                                                        <Col xs={2}>
                                                            <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 14}} className="mr-2">done</Icon></Button>
                                                            <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditReason : false})}>close</Icon>
                                                        </Col>
                                                    </Row>
                                                </form>
                                                :
                                                <Row className="" style={{fontSize : 14}}>
                                                    <Col xs={5}>{i+1}. {obj.reason}</Col>
                                                    <Col xs={2} className="text-center">{obj.is_audit_required ? "Yes" : "No"}</Col>
                                                    <Col xs={3} className="text-center">{obj.penalty_amount ? obj.penalty_amount+"Rs" : "No"}</Col>
                                                    <Col xs={2}>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditReason: obj})}>edit</Icon>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.inactiveMediaReason(obj)}) }>delete</Icon>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        
                                        </div>
                                    }

                                    
                                    {isOpenTrainingQues === media.job_media_id &&
                                       <div className="py-2">
                                            <Button className="buttonred my-2 p-0 px-1" onClick={() => this.setState({isAddQuestion : !isAddQuestion})}>Add Question</Button>
                                            {!!isAddQuestion &&
                                            <form onSubmit={(e) => this.addTrainingQuestion(e, media)}>
                                                <Row className="border-bottom mb-2" style={{fontWeight : "bold"}}>
                                                    <Col xs={12}><InputFeild required name="question" placeholder="Type Question"/></Col>
                                                    <Col xs={6}><InputFeild required name="option_1" placeholder="Option 1"/></Col>
                                                    <Col xs={6}><InputFeild required name="option_2" placeholder="Option 2"/></Col>
                                                    <Col xs={6}><InputFeild required name="option_3" placeholder="Option 3"/></Col>
                                                    <Col xs={6}><InputFeild required name="option_4" placeholder="Option 4"/></Col>
                                                    <Col xs={6} className="mt-1" >
                                                        <FormGroup>
                                                            <Input required name="right_answer" type="select">
                                                                <option value="">Select Correct Answer</option>
                                                            {
                                                                ["option_1", "option_2", "option_3", "option_4"].map((v, i) => 
                                                                    <option value={v}>Option {i+1}</option>
                                                                )
                                                            }
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6} className="mt-1">
                                                        <FormGroup>
                                                            <Input required name="language" defaultValue="English" type="select">
                                                            {
                                                                ["English", "Hindi", "Telugu", "Tamil", "Kannada"].map(lang => 
                                                                    <option value={lang}>{lang}</option>
                                                                )
                                                            }
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs={6}> 
                                                        <Tooltip title="Mandatory ?">
                                                            <Switch name="is_mandatory" color="primary" />
                                                        </Tooltip>
                                                    </Col>
                                                    <Col xs={6} className="text-right">
                                                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 14}} className="mr-2">done</Icon></Button>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isAddQuestion : false})}>close</Icon>
                                                    </Col>
                                                </Row>
                                                <span style={{fontSize: 14}} >*Timings must be add of Questions with videos</span>
                                            </form>
                                            }
                                            {
                                            trainingQuestions.map((obj,i) => 
                                                <Row className="shadow-sm align-items-center py-2" style={{fontSize : 14}}>
                                                    <Col xs={9}>
                                                        <strong className="d-block">{obj.question}</strong>
                                                        <span className="d-block">Options: {obj.options.split(";;").join(", ")}</span>
                                                        <span>Correct Answer: {obj.right_answer}</span>
                                                    </Col>
                                                    <Col>
                                                        <span className="d-block">{obj.language}</span>
                                                        <span>{obj.is_mandatory ? "Mandatory" : ""}</span>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.inactiveQuestion(obj)}) }>delete</Icon>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        
                                        </div>
                                    }

                                    {selectedTrainingMedia === media.job_media_id &&
                                    <div className="px-1">
                                        {
                                        media.JobMediaContents.map(content => 
                                            <Row className="mt-2 border-bottom align-items-center">
                                                <Col xs={4}>
                                                    {
                                                        content.youtube_url ? 
                                                        <iframe style={{width: "100%"}} src={"https://www.youtube.com/embed/"+content.youtube_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        : 
                                                        <video style={{width: "100%"}} preload="none" className="d-block mb-1" alt="Job Media" src={content.url} controls height={100} />
                                                    }
                                                </Col>
                                                <Col xs={4}>

                                                    {
                                                        content.thumbnail_file_url ? 
                                                        <div className="visible-on-hover-container">
                                                            <img style={{width: "100%"}} src={content.thumbnail_file_url} alt="video_thumbnail" />
                                                            <div className="visible-on-hover-item" style={{position: "absolute", right: 0, top: 0}}>
                                                                <Icon onClick={()=> this.addVideoThumbnail(content, null)} className="mt-1" style={{cursor: "pointer", fontSize: 16}}>delete</Icon>
                                                            </div>
                                                        </div>
                                                        :
                                                        <ImageUploadFeild required className="my-2 text-center" label="Add Thumbnail" onChange={(file) => this.addVideoThumbnail(content, file)}/>
                                                    }

                                                </Col>
                                                <Col xs={4}>
                                                    <p className="m-0">
                                                        <strong>{content.language}</strong>
                                                        <span className="float-right">
                                                            <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.deleteMediaContent(content.job_media_content_id) })}>delete</Icon>
                                                        </span>
                                                    </p>
                                                    <span style={{fontSize: 10}} className="text-muted d-block">{content.description}</span>
                                                    <p style={{fontSize: 10}} className="text-muted mt-2 mb-0">
                                                        <span>Duration: {Math.round(content.media_length)}sec</span>
                                                        <span className="float-right">{moment(content.modified_on || content.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                                                    </p>
                                                    { media.category === "PRIMARY" && <span onClick={() => this.openTrainingQuestionTiming(content)} style={{color: "black", fontSize: 12, fontWeight: "bold", cursor: "pointer", textDecoration: "underline"}}>Training Questions Timings</span>}
                                                </Col>
                                                {isEditQuestionTiming === content.job_media_content_id && 
                                                <form className="my-2 py-2 border w-100" onSubmit={(e) => this.addTrainingQuestionTimings(e, content)}>
                                                    {
                                                        this.state.trainingQuesTimingData.map((ques, i) => {
                                                        let timeData  = ques.JobTrainingMediaQuestions.filter(obj => obj.job_media_content_id === content.job_media_content_id)[0];
                                                        return <Row key={i} className="m-1 p-1 shadow-sm row align-items-center">
                                                                <Col xs={8}>
                                                                    {ques.question}
                                                                </Col>
                                                                <Col xs={4}>
                                                                    <InputFeild required name={"question_"+ques.job_training_question_id} defaultValue={timeData ? timeData.percentage_complete : ""} placeholder="Percentage of Video Time" />
                                                                </Col>
                                                            </Row>
                                                        })
                                                    }
                                                    <div className="text-right mt-2">
                                                        <Button color="link" className="p-0 mr-2" type="submit"><Icon style={{fontSize: 20, lineHeight: "15px"}} className="mr-2">done</Icon></Button>
                                                        <Icon style={{fontSize: 20,  cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditQuestionTiming: false})}>close</Icon>
                                                    </div>
                                                </form>
                                                }
                                            </Row>
                                        )
                                        }
                                        {isAddMediaContent.job_media_id === media.job_media_id ? 
                                        <>
                                            <Row className={"mt-3"}>
                                                <Col xs={12} lg={6}>
                                                    <SelectList id="select_language" placeholder="Select Language" value={isAddMediaContent.language} onChange={(e) => {isAddMediaContent.language = e.target.value; this.setState({isAddMediaContent}) }}>
                                                    {
                                                        ["English", "Hindi", "Telugu", "Tamil", "Kannada"].map(lang => 
                                                            <option value={lang}>{lang}</option>
                                                        )
                                                    }
                                                    </SelectList>
                                                </Col>
                                                {this.state.addMediaContentprogress !== false && <Col xs={12}><Progress animated color="info" value={this.state.addMediaContentprogress}>{this.state.addMediaContentprogress}%</Progress></Col>}
                                            </Row>
                                            <Row className="my-3 align-items-center">
                                                {/* <Col xs={3} className="text-center">
                                                    <VideoUploadFeild id="video_upload_input_training" label={"Add Training Video"} onChange={(file) => {isAddMediaContent.file = file; this.setState({isAddMediaContent})}} onLoadedMetadata={this.getTrainingVideoFile} />
                                                    <span className="small">Duration: {Math.round(isAddMediaContent.media_length) || 0}sec</span>
                                                </Col>
                                                <Col xs={"auto"}><h5>Or</h5></Col> */}
                                                <Col>
                                                    <h6>Youtube Video</h6>
                                                    <Row className="align-items-center" noGutters>
                                                        <Col xs={"auto"}><span>https://www.youtube.con/embed/</span></Col><Col><InputFeild placeholder="Youtube Video Id"  value={isAddMediaContent.youtube_url} onChange={(e) => {isAddMediaContent.youtube_url = e.target.value; this.setState({isAddMediaContent}) }} /></Col>
                                                    </Row>
                                                </Col>
                                                {/* <Col>
                                                    <textarea className="form-control mt-2" placeholder="Enter video description" value={isAddMediaContent.description}  onChange={(e) => {isAddMediaContent.description = e.target.value; this.setState({isAddMediaContent}) }}/>
                                                </Col> */}
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="py-2 text-right">
                                                    <Button color="danger" size="sm" className="mr-3 py-0" onClick={this.addMediaContentSubmit}>Save</Button>
                                                    <Button size="sm" className="mr-2 py-0" onClick={() => this.setState({isAddMediaContent: false})}>Cancel</Button>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <Row className="border shadow-sm py-1 my-2">
                                            <Col className="text-muted text-center" style={{cursor: "pointer"}} onClick={() => this.setState({isAddMediaContent: {job_media_id: media.job_media_id}})}>
                                                <Icon style={{lineHeight : 1.5, fontSize: 18}}>add</Icon>Add Video
                                            </Col>
                                        </Row>
                                        }
                                    </div>
                                    }
                                </Col>
                            </Row>
                        )}
                    </>

                )}
            </div>
            
            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
        </>
      );
  }
}
export default JobMedia;




