import { Input } from "@material-ui/core";
import React, { Component } from "react";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import CallersCard from "./callersCard";

class Callers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callers: [],
      isOpenConfirm: false,
      confirmMessage: false,
      searchResult: [],
    };
  }

  async componentDidMount() {
    await this.getData();
  }

  getData = async () => {
    store.dispatch(setLoader(true));
    try {
      const params = {
        status: ["0", "1", "2"],
        is_active: ["0", "1"],
      };
      const callersData = await ApiEndpoints.Calling.fetchCallers(params);
      this.setState({ callers: callersData.data.data });
    } catch (error) {
      store.dispatch(
        setError({
          show: true,
          message: error.response
            ? error.response.data.message
            : error.toString(),
        })
      );
    }
    store.dispatch(setLoader(false));
  };

  searchCaller = (keyWord) => {
    const { callers } = this.state;
    if (keyWord.length === 0) return this.setState({ searchResult: [] });
    const filteredCallers = callers.filter((user) =>
      user.caller_name.toLowerCase().startsWith(keyWord.toLowerCase())
    );
    this.setState({ searchResult: filteredCallers });
  };
  render() {
    const { callers, searchResult } = this.state;
    return (
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-end">
          <Input
            name="searchUser"
            placeholder="Search Caller"
            onChange={(e) => this.searchCaller(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {searchResult.length > 0
            ? searchResult.map((element, index) => {
                return (
                  <CallersCard
                    key={index}
                    index={index}
                    element={element}
                    refresh={this.getData}
                  />
                );
              })
            : callers.map((element, index) => {
                return (
                  <CallersCard
                    key={index}
                    index={index}
                    element={element}
                    refresh={this.getData}
                  />
                );
              })}
        </div>
      </div>
    );
  }
}

export default Callers;
