
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import store from "../../store";
import { setError, setLoader } from "../../actions/action-creators";
import ApiEndpoints from "../../constants/api_endpoints";
import { Col, Row } from "reactstrap";
import { Badge } from "@material-ui/core";
import { groupArrayOnKey } from "../../actions";
import WhatsappIcon from "../../assets/images/icons8-whatsapp.svg"


class PendingNotifications extends Component {
    constructor(props) {
      super(props);
      this.state = {
        pendingNotificationData : [] ,
        }
      }

    componentDidMount(){
      this.fetchNotificationData();
    }


    fetchNotificationData = async () => {
      store.dispatch(setLoader(true));
      try{
        let apiData = await ApiEndpoints.Notification.fetchPendingActionsData();
        this.setState({pendingNotificationData: apiData.data.data});
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    redirectNotification = (item) => {
      this.props.onClose && this.props.onClose();
      if(item.entity_type === "New_USER"){
        this.props.history.push("/recruitment/new-user");
      } else if (["JOB", "JOB_HELP"].includes(item.entity_type)){
        this.props.history.push(`/recruitment/job/${item.entity_id}?status=PENDING_ACTION`)
      }
    }

    render() {
      let user = store.getState().user;
      let { pendingNotificationData } = this.state;
      let { jobListById } = this.props;

        return(
            <div style={{minWidth: 350}}>
              <Icon fontSize="small" style={{ cursor: "pointer", position: "absolute", right: 5, top: 5}} onClick={() => this.fetchNotificationData()}>autorenew</Icon>
              <h6 className="text-center mt-3">Pending communication Actions</h6>
              {
                pendingNotificationData.map((item, i) => 
                    <Row key={i} className="shadow-sm bg-light mt-2 border-secondary mx-1 py-3 align-items-center" style={{borderRadius: 10, cursor: "pointer"}} onClick={() => this.redirectNotification(item)}>
                      <Col>
                        <strong className="d-block">
                          {item.entity_type === "New_USER" && "New Users"}
                          {(item.entity_type === "JOB" || item.entity_type === "JOB_HELP") && "Project"}
                        </strong>
                        <span>
                          {
                          (item.entity_type === "JOB" || item.entity_type === "JOB_HELP") && <>
                            {jobListById[item.entity_id] ? jobListById[item.entity_id].Employer.employer_name + " - "+ jobListById[item.entity_id].title : "Project not found"}
                          </>}
                        </span>
                      </Col>
                      <Col xs={"auto"}>
                        <Badge sx color="secondary" invisible={!item.whatsappCount} badgeContent={item.whatsappCount}>
                      <img src={WhatsappIcon} alt="" style={{ cursor: "pointer", height: 33} }/></Badge>
                      </Col> 
                      <Col xs={"auto"}>
                        <Badge sx color="secondary" invisible={!item.missedCalls} badgeContent={item.missedCalls}>
                          <Icon>call</Icon>
                        </Badge>                      
                      </Col>
                    </Row>
                )
              }
          </div>
        );
     }
  }
  export default withRouter(props => <PendingNotifications {...props} />);


