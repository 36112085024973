
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Col, Input, Row } from "reactstrap";
import { Button, Chip, FormControl, Select, TextField } from "@material-ui/core";
import moment from "moment";
import SelectList from "../form/select";
import InputFeild from "../form/input";
import CONSTANTS from "../../../constants";



class TicketCreate extends Component {
    constructor(props) {
      super(props);
      this.state = {
       createTicket: {},
       teamMembers: [],
       formResponseList: [],
       rechargeList: []
      }
    }

    componentDidMount(){
    }

    onChangeTicket = async (k, v) => {
      let { createTicket } = this.state;
      createTicket[k] = v;
      await this.setState({ createTicket });

      if(k === "ticket_type"){
        if([20, 30, 40].includes(v)) this.onChangeTicket("tag_type", "JOB");
        else if([60].includes(v)) this.onChangeTicket("tag_type", "SERVICE_PURCHASE");
        else this.onChangeTicket("tag_type", null);
      }
      if(k === "tag_type") this.onChangeTicket("tag_id", null);
      if(k === "ticket_team_id") {
        this.onChangeTicket("agent_user_id", null);
        this.selectTeamMember(v);
      }
      if(k === "tag_id" && !!v && [30, 40].includes(createTicket.ticket_type)) {
        this.onChangeTicket("ref_type", "FORM_RESPONSE")
        this.onChangeTicket("ref_id", null)
        this.fetchJobFormResponse(v);
      }
      if(k === "tag_id" && !!v && [60].includes(createTicket.ticket_type)) {
        this.onChangeTicket("ref_type", "ID");
        this.onChangeTicket("ref_id", null)
        this.fetchRechargeList();
      }
      this.setTicketTitle();
    }

    setTicketTitle = () => {
      let { createTicket, formResponseList, rechargeList } = this.state;
      if(createTicket.tag_type === "JOB" && createTicket.ref_type === "FORM_RESPONSE" && createTicket.ref_id){
        let formResponse = formResponseList.filter(o => o.form_response_id === parseInt(createTicket.ref_id))[0];
        createTicket.ticket_title = formResponse.name + " - " + formResponse.number;
      }
      else if(createTicket.tag_type === "JOB" && createTicket.tag_id && !createTicket.ref_id){
        let jobData = store.getState().jobList.filter(job => job.job_id === parseInt(createTicket.tag_id))[0];
        createTicket.ticket_title = jobData.Employer.employer_name + " - " + jobData.title;
      }
      else if(createTicket.tag_type === "SERVICE_PURCHASE" && createTicket.ref_id){
        let recharge = rechargeList.filter(o => o.service_purchase_id === parseInt(createTicket.ref_id))[0];
        createTicket.ticket_title = "RTXN" + recharge.service_purchase_id + " / " + recharge.account_number + " - " + recharge.amount/100;
      }
      else if (createTicket.tag_type === "SERVICE_PURCHASE"  && createTicket.tag_id && !createTicket.ref_id){
        createTicket.ticket_title = createTicket.tag_id === 1 ? "Prepaid Mobile Recharge" : "DTH Recharge"
      }
      else if(createTicket.ticket_type){
        createTicket.ticket_title = CONSTANTS.TICKET_TYPE_TEXT[createTicket.ticket_type];
      }
      this.setState({ createTicket })
    }
      
  
    createTicket = async () => {
      let { createTicket } = this.state;
      store.dispatch(setLoader(true));
      let user = store.getState().user;
      try{
        let { user_id, tag_type, tag_id, ref_type, ref_id, ticket_team_id, agent_user_id, ticket_title } = createTicket;
        let body = {
          user_id, 
          priority: 3,
          ticket_team_id,
          agent_user_id: agent_user_id || user.user_id,
          ticket_source: 0,
          tag_type, tag_id, ref_type, ref_id,
          ticket_title: ticket_title || "General Query"
        }
        let resp = await ApiEndpoints.Support.createTicket(body);
        this.props.addTicket({ ...body, ...(resp.data.data || {}), User: createTicket.User }, user.user_id === body.user_id)
        this.props.onClose();
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    getUser = async (teamId) => {
      store.dispatch(setLoader(true));
      let { createTicket } = this.state;
      try{
        let resp = await ApiEndpoints.User.fetch({id: createTicket.pmw_id});
        let user = resp.data.data[0];
        if(user){
          createTicket.user_id = user.user_id
          createTicket.User = user;
          this.setState({ createTicket })
        }
        else store.dispatch(setError({show: true, message: "Not Found!"}));
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }
    
    selectTeamMember = async (teamId) => {
      store.dispatch(setLoader(true));
      try{
        let resp = await ApiEndpoints.Support.fetchTeamMembers({ticket_team_id: teamId});
        this.setState({ teamMembers: resp.data.data })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    fetchJobFormResponse = async (jobId) => {
      store.dispatch(setLoader(true));
      let { createTicket } = this.state;
      try{
        let resp = await ApiEndpoints.Support.fetchUserFormResponse({user_id: createTicket.user_id, job_id: jobId, status: createTicket.ticket_type === 30 ? 0 : 2});
        this.setState({ formResponseList: resp.data.data })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    fetchRechargeList = async () => {
      store.dispatch(setLoader(true));
      let { createTicket } = this.state;
      try{
        let resp = await ApiEndpoints.Support.fetchUserRechargeList({ user_id: createTicket.user_id });
        this.setState({ rechargeList: resp.data.data })
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
      store.dispatch(setLoader(false));
    }

    render() {
      // let user = store.getState().user;
      let { createTicket, formResponseList, rechargeList, teamMembers } = this.state;
      let jobList = store.getState().jobList;
        return(
        <div className="h-100" style={{width: "100%", height: "100%", display: "flex", flexDirection: "column"}}>
          <main className="h-100 p-2" style={{ overflowY: "auto" }}>
            <strong>Create New Ticket</strong>

            <Row className="align-items-center mt-4">
              <Col xs={4}><span className="small">Partner PMW ID</span></Col>
              <Col xs={5}>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Input type="number" placeholder="1234567" onChange={(e) => this.onChangeTicket("pmw_id", e.target.value)} />
                </FormControl>
              </Col>
              <Col xs={3}>
                <Button color="link" size="small" onClick={() => this.getUser()}>Get Partner</Button>
              </Col>
            </Row>
            {createTicket.User && <Row><Col xs={4}></Col><Col xs={8}><span>{createTicket.User.first_name}</span></Col></Row>}
            {!!createTicket.user_id && 
            <>   
                <Row className="align-items-center mt-4">
                  <Col xs={4}><span className="small">Issue Type</span></Col>
                  <Col xs={8}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <SelectList placeholder="Select Issue Type" value={createTicket.ticket_type} onChange={e => this.onChangeTicket("ticket_type", parseInt(e.target.value))}>
                        {
                          Object.keys(CONSTANTS.TICKET_TYPE_TEXT).map(typeKey => <option value={typeKey}>{CONSTANTS.TICKET_TYPE_TEXT[typeKey]}</option>)
                        }
                      </SelectList>
                    </FormControl>
                  </Col>
                </Row>
                
                {["JOB","SERVICE_PURCHASE"].includes(createTicket.tag_type) &&
                <Row className="align-items-center mt-4">
                  <Col xs={4}><span className="small">Project / Module</span></Col>
                  <Col>
                    <FormControl  variant="outlined" size="small" fullWidth>
                      {createTicket.tag_type === "JOB" &&
                        <SelectList search={true} placeholder="Select Project" value={createTicket.tag_id} onChange={(e) => this.onChangeTicket("tag_id", parseInt(e.target.value))}>
                            {jobList.map(job => <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title}
                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                              </option>
                            )}
                        </SelectList>
                      }
                      {createTicket.tag_type === "SERVICE_PURCHASE" &&
                        <SelectList search={true} placeholder="Select Service" value={createTicket.tag_id} onChange={(e) => this.onChangeTicket("tag_id", parseInt(e.target.value))}>
                            <option value="1">Prepaid Mobile Recharge</option>
                            <option value="2">DTH Recharge</option>
                        </SelectList>
                      }
                    </FormControl>
                  </Col>
                </Row>
                }
                {["JOB","SERVICE_PURCHASE"].includes(createTicket.tag_type) && [30, 40, 60].includes(createTicket.ticket_type) && !!createTicket.tag_id && 
                <Row className="align-items-center mt-4">
                  <Col xs={4}>
                    <span className="small">Task / Transaction</span>
                  </Col>
                  <Col>
                    <FormControl  variant="outlined" size="small" fullWidth>
                      {createTicket.tag_type === "JOB" &&
                        <SelectList placeholder="Select Task" value={createTicket.ref_id} onChange={(e) => this.onChangeTicket("ref_id", e.target.value)}>
                          {
                          formResponseList.map(resp => 
                          <option value={resp.form_response_id}>
                              <span className="d-block">{resp.name} - {resp.number}</span>{" "}
                              <span className="small">({moment(resp.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")})</span>
                          </option>
                          )}
                        </SelectList>
                      }
                      {createTicket.tag_type === "SERVICE_PURCHASE" &&
                        <SelectList placeholder="Select Transaction" value={createTicket.ref_id} onChange={(e) => this.onChangeTicket("ref_id", e.target.value)}>
                          {
                          rechargeList.map(resp => 
                          <option value={resp.service_purchase_id}>
                              <span className="d-block">RTXN{resp.service_purchase_id} / {resp.account_number} - {resp.amount/100}</span>
                              <span className="small">({moment(resp.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mm A")})</span>
                          </option>
                          )}
                        </SelectList>
                      }
                    </FormControl>
                  </Col>
                </Row>
                }
                <Row className="align-items-center mt-4">
                  <Col xs={4}><span className="small">Ticket Title</span></Col>
                  <Col xs={8}>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <Input placeholder="Enter short title of ticket" value={createTicket.ticket_title} onChange={(e) => {createTicket.ticket_title = e.target.value; this.setState({createTicket});}} />
                    </FormControl>
                  </Col>
                </Row>
                <Row className="align-items-center mt-4">
                  <Col xs={4}><span className="small">Assign To</span></Col>
                  <Col>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <SelectList placeholder="Select Team" value={createTicket.ticket_team_id} onChange={e => this.onChangeTicket("ticket_team_id", parseInt(e.target.value))}>
                        {
                          Object.keys(CONSTANTS.TICKET_TEAMS).map(teamKey => <option value={teamKey}>{CONSTANTS.TICKET_TEAMS[teamKey]}</option>)
                        }
                      </SelectList>
                    </FormControl>
                  </Col>
                </Row>
                <Row className="align-items-center mt-4">
                  <Col xs={4}></Col>
                  <Col>
                    <FormControl variant="outlined" size="small" fullWidth>
                      <SelectList placeholder="Select Member" value={createTicket.agent_user_id} onChange={e => this.onChangeTicket("agent_user_id", e.target.value)}>
                        {
                          teamMembers.map(member => <option value={member.user_id}>{member.User.first_name}</option>)
                        }
                      </SelectList>
                    </FormControl>
                  </Col>
                </Row>
            </>}
          </main>
          <footer style={{flex: 0, position: "sticky", bottom: 0, background: "#fff", marginLeft: -15, marginRight: -15}} className="px-3">
            <Row className="align-items-center justify-content-end py-4 border-top mt-4">
              <Col xs={3}>
                <Button className="w-100 text-danger border-danger" variant="outlined" color="secondary" style={{borderRadius: 25}} onClick={this.props.onClose}>Cancel</Button>
              </Col>
              <Col xs={3}>
                <Button disabled={!Object.keys(createTicket).length} onClick={() => this.createTicket()} className="w-100 bg-danger" variant="contained" color="secondary" style={{borderRadius: 25}}>Submit</Button>
              </Col>
            </Row>
          </footer>

        </div>
        );
     }
  }
  export default withRouter(props => <TicketCreate {...props} />);


