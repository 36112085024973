
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, ButtonGroup, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import { Badge, Icon, TablePagination } from "@material-ui/core";
import moment from "moment";
import PartnerDetail from "../user_profile";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import WhatsappChat from "../../global/whatsapp_chat";
import ConfirmAction from "../../global/confirm-action";
import DatePicker from "react-datepicker";
import { downloadApiFile } from "../../../actions";
import SelectList from "../../global/form/select";
import UserCard from "../user_profile/user_card";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"

class NewUsers extends Component {
    constructor(props) {
        super(props);
        var params = this.props.match.params;
        this.state = {
            userList: [],
            userCount: 0,
            currentUserPage : 0,
            rowsPerPage : 20,
            searchText: "",
            isOpenProfileModel: false,
            callDetails: null,
            whatsappChat: false,
            isOpenNotificationModal: false,
            activeStateTab: "NEW_USERS",
            callData: [],
            isFilters: { start_date: moment().subtract(30, "days").toDate() },
            managerList: []
        };
    }


    componentDidMount(){
        this.init("NEW_USERS");
    }

    init = async(tab, isDownload) => {
        store.dispatch(setLoader(true))
        let {isFilters} = this.state;
        try{

            let limit = this.state.rowsPerPage;
            let offset = this.state.currentUserPage * this.state.rowsPerPage;
            var body = {};
            if(isFilters.start_date) body = {...body, start_date: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, end_date: moment(isFilters.end_date).endOf("day").toDate()}
            if(isFilters.state) body = {...body, state: isFilters.state}
            if(isFilters.city) body = {...body, city: isFilters.city}
            switch (tab) {
                case "TO_DO_ACTION":
                    body.onlyPendingAction = true;
                    body.include_open_conversation = true;
                    if(isFilters.manager_id) body.manager_id = isFilters.manager_id;
                    break;
                case "NEW_USERS":
                    body.isConnected = 0;
                    break;
                case "CONNECTED":
                    if(isFilters.manager_id) body.manager_id = isFilters.manager_id;
                    body.isConnected = 1;
                    break;
                case "INTERESTED":
                    body.isConnected = 1;
                    if(isFilters.manager_id) body.manager_id = isFilters.manager_id;
                    body.application_status= [1, 2];
                    break;
                case "IN-TRAINING":
                    if(isFilters.manager_id) body.manager_id = isFilters.manager_id;
                    body.isConnected = 1;
                    body.application_status= [5];
                    break;
                case "IN-TASK":
                    if(isFilters.manager_id) body.manager_id = isFilters.manager_id;
                    body.isConnected = 1;
                    body.application_status= [7];
                    break;
                default:
                    break;
            }
            /*if(tab !== "TO_DO_ACTION")*/ 
            if(this.state.searchText) {
                body.search = this.state.searchText.toLowerCase().replace("pmw", "");
            }
            if(!isDownload){
                body = {...body, limit, offset}
                let apiData = await ApiEndpoints.User.fetchNewUsers(body);
                let userList = apiData.data.data.rows;
                let userCount = apiData.data.data.count;
                this.setState({ userList, userCount })
            } else {
                let apiData = await ApiEndpoints.User.downloadNewUsers(body);
                downloadApiFile(apiData, "Bew Users Data.csv")
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchManagers = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {
            }
            let apiData = await ApiEndpoints.User.fetchManagers(body);
            this.setState({managerList : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : this.state.isOpenNotificationModal,
            notification_type : e.target.notification_type.value,
            origin_type : "GENERAL",
            sms : false
        } 
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }
  

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "NEW_USER",
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }

    changeUserTab = async (tab) => {
        await this.setState({activeStateTab: tab, currentUserPage: 0});
        // var isTodoTab = tab === "TO_DO_ACTION" ? true : false;
        this.init(tab);
    }

    showCallData = async (user) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                // status: "completed",
                user_id: user.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
  render() {
    let { userList , activeStateTab, isFilters, managerList} = this.state;
    return (<>
        <Row className={"my-2 align-items-end"}>
            <Col>
                <h4 className="">New Users</h4>
            </Col>  
            <Col xs={12} md={4} className="justify-self-end">
                <form onSubmit={async (e) => {e.preventDefault(); await this.setState({currentUserPage: 0}); this.init(activeStateTab)}}>
                    <InputFeild onChange={(e) => this.setState({searchText :e.target.value})} value={this.state.searchText} icon="search" onClickIcon={this.applyFilter} placeholder=" search by Name/Mobile/PMW-Id" />
                </form>
            </Col>
            <Col xs="auto">
                {/* <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.toggleFilter()}>{isFilters ? "filter_alt_off" : "filter_alt"}</Icon> */}
                <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.init(this.state.activeStateTab, true)}>{"download"}</Icon>
            </Col>
        </Row>
        <Row className="align-items-center my-3">
            <Col xs={1} style={{maxWidth: "3%"}}>
                <Icon className="align-middle mr-3 " style={{fontSize: 18}} >{"filter_alt"}</Icon>
            </Col>
            <Col xs={2} className="">
                <DatePicker
                    selected={isFilters.start_date || null}
                    onChange={date => {isFilters.start_date= date; this.setState({isFilters})}}
                    isClearable
                    dateFormat='dd-MMM-yyyy'
                    placeholderText="Start - Download Date"
                />
            </Col>
            <Col xs={2}>
                <DatePicker
                    selected={isFilters.end_date || null}
                    onChange={date => {isFilters.end_date= date; this.setState({isFilters})}}
                    isClearable
                    dateFormat='dd-MMM-yyyy'
                    placeholderText="End - Download Date"
                />
            </Col>
            <Col xs={2}><InputFeild onChange={(state) => {isFilters.state= state.target.value ;this.setState({isFilters})}} value={isFilters.state || null} placeholder="Enter state name"/></Col>
            <Col xs={2}><InputFeild onChange={(city) => {isFilters.city = city.target.value ;this.setState({isFilters})}} value={isFilters.city || null} placeholder="Enter city name"/></Col>
            <Col xs={2}>
            { activeStateTab !== "NEW_USERS" &&
                <SelectList placeholder="Select Calling Member" search={true} value={isFilters.manager_id} onChange ={(e) => { isFilters.manager_id = e.target.value; this.setState({isFilters})} } >
                    { managerList.map(obj => <option key={obj.user_id} value={obj.user_id}> {obj.first_name}</option>)}
                </SelectList>
            }
            </Col>
                
            <Col xs={"auto"}><Button color="dark" size="sm" onClick={() => this.init(activeStateTab)}>Apply Filter</Button></Col>                         
        </Row>
        <Row noGutters className="border-bottom border-top w-100 border" >
            <Col md={12} xs={12}>
                <ButtonGroup variant="text" aria-label="text button group">
                    {/* <Button className="pr-3" onClick={() => this.changeUserTab("TO_DO_ACTION")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "TO_DO_ACTION" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link"><Icon style={{fontSize: 14}} className="align-middle">pending_actions</Icon>ToDo Action</Button> */}
                    <Button className="px-3" onClick={() => this.changeUserTab("NEW_USERS")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "NEW_USERS" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">New Users</Button>
                    <Button className="px-3" onClick={() => this.changeUserTab("CONNECTED")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "CONNECTED" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">Contacted Users</Button>
                    <Button className="px-3" onClick={() => this.changeUserTab("INTERESTED")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "INTERESTED" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">Interested</Button>
                    <Button className="px-3" onClick={() => this.changeUserTab("IN-TRAINING")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "IN-TRAINING" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">In-Training</Button>
                    <Button className="px-3" onClick={() => this.changeUserTab("IN-TASK")} style={{fontSize: 14, fontWeight: "bold", color: "#000", textDecoration: "none", ...(activeStateTab === "IN-TASK" ? {background: "#f002", borderRadius: 0, color: "red"} : {})}} color="link">In-Task</Button>
                </ButtonGroup>
            </Col>
        </Row>
        {
        userList.length ? 
        <>
        {
        userList
        .map((user, i) =>
        <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                <Col md={3} xs={12}>
                    <Row noGutters className="align-items-center">
                        <Col className="align-items-center">
                       <UserCard data={user} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} xs={12} className="text-center my-1">
                    <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(user)}>{user.connected_call_counts || ""}</span> 
                    <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!user.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(user), isConfirmMessage : "Are you sure to connect with "+ user.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!user.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: user})}><img src={WhatsappIcon} alt="" style={{fontSize:16, cursor: "pointer"} }/></Button></Badge> */}
                    <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: user.user_id})}>notifications_none</Icon></Button>
                    {/* <Button outline className="px-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.showApplicationComment(applicant)}>comment</Icon></Button> */}
                </Col>
                <Col md={2} xs={12} className="text-center">
                    {
                         !!user.isPendingWhatsapp &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Whatsapp - {moment(user.isPendingWhatsapp.created_on).fromNow()} </span>
                    }
                    {
                       !!user.isMissedCall &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(user.isMissedCall.created_on).fromNow()} </span>
                    }
                </Col>
                <Col md={3} xs={12} className="text-center">
               
              
               </Col>

                <Col md={2} xs={12} className="text-right"> 
                    <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{moment(user.UserConfigs && user.UserConfigs[0] ? user.UserConfigs[0].created_on : user.modified_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                </Col>
            </Row>
            )}
            {/*activeStateTab !== "TO_DO_ACTION" &&*/ <TablePagination rowsPerPageOptions={[2, 20, 40, 60, 100, 200]} component="div" count={this.state.userCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                onChangePage={async (e, page) => {await this.setState({currentUserPage : page}); this.init(activeStateTab)} }
                onChangeRowsPerPage={async (e) => {await this.setState({rowsPerPage : e.target.value}); this.init(activeStateTab)}}
            />}
            </>
            : 
            <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
            
        }

               
        <Modal isOpen={this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
            <form onSubmit={this.handleNotificationSubmit}>
                <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                    <ModalBody>
                        <label className="radio-inline"><input type="radio" className="mr-3" name="notification_type" value="NOTIFICATION" checked />General Notification</label>
                        <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                        <InputFeild required placeholder="Title" name="title" />
                        <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                        {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                    </ModalBody>
                    <ModalFooter style={{height: "45px"}}>
                    <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                    <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>

        <Modal size={"lg"} centered isOpen={!!this.state.callData.length} toggle={()=> {this.setState({callData : []})}} >
                    <ModalHeader toggle={()=> {this.setState({callData : []})}}>Call Details</ModalHeader>
                    <ModalBody className="p-2">
                    <Table bordered className="table-sm mt-2 mb-0 text-center" style={{fontSize: 14}}>
                                <thead>
                                    <tr>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.callData.map((call, i) =>
                                    <tr>
                                      <td>{(call.userDetail || {}).first_name}</td>
                                      <td>{call.direction === 1 ? "In" : "Out"}</td>
                                      <td>
                                        {call.call_recording_url ?
                                        <audio preload={true} style={{maxWidth: 185}} controls><source src={call.call_recording_url} type="audio/ogg"  /></audio>
                                        :
                                        <span>{call.status}</span>
                                        }
                                      </td> 
                                      <td><span>{call.comment}</span></td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        {/* <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em> */}
                                      </td>
                                    </tr>
                                  ) 
                                  }
                                </tbody>
                            </Table>  
                    </ModalBody>
            </Modal>







        {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}
        {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}



    </>)
  }
}


export default NewUsers;
