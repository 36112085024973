
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";

import ConfirmAction from "../../global/confirm-action";

class PortfolioManageUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            IDText: ""
        };
    }


    componentDidMount(){
    }


    onChangeIDText = (text = "") => {
        text = text.toString().toLowerCase().replace(/\n\r?/g, ',').replace(/pmw/g, "");
        this.setState({ IDText: text })
    }


    removeUsers = async () => {
        let { IDText } = this.state;
        let IDs = IDText.split(",").map(o => o.trim()).filter(v => !!v);
        let { portfolioId } = this.props;
        store.dispatch(setLoader(true))
          try{
            let accessBody = {
                portfolio_id: portfolioId,
                pmw_id: IDs,
            }
            await ApiEndpoints.Portfolio.removePartnerFromPortfolio(accessBody);
            this.props.onClose();
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addUsers = async () => {
        let { IDText } = this.state;
        let IDs = IDText.split(",").map(o => o.trim()).filter(v => !!v);
        let { portfolioId } = this.props;
        store.dispatch(setLoader(true))
          try{
            let accessBody = {
                portfolio_id: portfolioId,
                pmw_id: IDs,
            }
            await ApiEndpoints.Portfolio.mapPartnerToPortfolio(accessBody);
            this.props.onClose();
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   

    removeAccess = async (user) => {
        let { portfolioId } = this.props;
        store.dispatch(setLoader(true))
          try{
            let accessBody = {
                portfolio_id: portfolioId,
                user_id: user.user_id,
            }
            await ApiEndpoints.Portfolio.removeAccess(accessBody);
            await this.fetchUsers();
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

  render() {
    let { IDText } = this.state;
    let { portfolioId } = this.props;

    var userRole = store.getState().user.UserRole
    var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;
    
    return (<>
         
        <form onSubmit={e => e.preventDefault()}>
            <Row className="shadow sm my-2 mx-0 py-2 align-items-center">
                <Col className="">
                    <strong className="small">Enter PMW IDs of Partners ({IDText.split(",").map(o => o.trim()).filter(v => !!v).length})</strong>
                    <textarea required rows={10} value={IDText} className="form-control" onChange={(e) => this.onChangeIDText(e.target.value)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button size="sm" className="buttonred w-100" onClick={() => this.removeUsers()}>Remove Partners</Button>
                </Col>
                <Col>
                    <Button size="sm" className="buttonred w-100" onClick={() => this.addUsers()}>Add Partners</Button>
                </Col>
            </Row>
        </form>
        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />



    </>)
  }
}


export default PortfolioManageUsers;
