import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import { groupArrayOnKey } from "../../../actions";
import WhatsappChat from "../../global/whatsapp_chat";


class UserWhatsappDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            threadData : [],
            selectedJob: null,
            jobList: [],
            currentPage: 0,
            whatsappChat: null
        }
    }

    componentDidMount(){
        this.getJob();
        this.fetchWhatsappThreads();
    }


    getJob = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
            await this.setState({ jobList });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    

    fetchWhatsappThreads = async (selectedJob) => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.Whatsapp.fetchConversationThreads(body)
            let threadData = resp.data.data.sort((a, b) => new Date(b.created_on) - new Date(a.created_on) )
            this.setState({ threadData, selectedJob, currentPage: 0 })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    render() {
        let { threadData, jobList, whatsappChat} = this.state;
        let { userInfo } = this.props;
        let jobListById = groupArrayOnKey(jobList, "job_id", true);
        return (
            <div className="p-1">
                <strong className="small mb-2">Whatsapp Conversations</strong>
                {threadData.length ? 
                <>
                {threadData
                .slice(this.state.currentPage*10, this.state.currentPage*10 + 10)
                .map(thread => 
                        <Row style={{fontSize: 14, borderRadius: 12, cursor: "pointer"}} className="bg-light border align-items-center py-1 my-2" onClick={() => this.setState({whatsappChat: thread})} >
                            <Col xs={6}>
                                <strong className="d-block">Status: {" "}
                                    {thread.last_message_sent >= 1 ? "Closed" : "Active"}
                                </strong>
                                {!!thread.end_time && <span className="small d-block">{moment(thread.end_time).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>}
                                {!!thread.is_pending && <span className="small d-block">{"Pending Conversation"}</span>}
                                <span className="small d-block" style={{fontWeight: 600}}>Rating: {thread.rating || " - "}</span>
                            </Col>
                            <Col xs={6} className="text-right">
                                 <span className="d-block" style={{fontSize: 12}}>
                                    {["JOB", "JOB_HELP"].includes(thread.entity_type) && <>Project: { jobListById[thread.entity_id] ? jobListById[thread.entity_id].Employer.employer_name + "-"+jobListById[thread.entity_id].title : "Not Found"}</> }
                                    {thread.entity_type === "NEW_USER" && "New User Acquisition"}
                                  </span> 
                                  
                                <span className="px-2 small">{moment(thread.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                            </Col>
                        </Row>
                )}
                 <TablePagination rowsPerPageOptions={[15]} component="div" count={threadData.length} rowsPerPage={10} page={this.state.currentPage}
                    onChangePage={ (e, page) => this.setState({currentPage : page}) }
                /> 
                </>
                : <em className="m-4 text-secondary d-block">No Whatsapp history found</em> }
                     
                {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} {...whatsappChat} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

            </div>
           
        );
    }
}
export default UserWhatsappDetails;
