import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";


class UserDetailsMyTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countDetails : {},
        }
    }

    componentDidMount(){
        this.fetchUserTeamCount();
     }

    fetchUserTeamCount = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
           
            let resp = await ApiEndpoints.MyTeamUserDetails.fetchCountDetails(body)
            let countDetails = resp.data.data[0] || {};
            if(countDetails.ExternalUsers){
                countDetails = countDetails.ExternalUsers.reduce((prev, curr) => {
                    prev.pending = (curr.task_count.pending || 0) + (prev.pending || 0);
                    prev.verified = (curr.task_count.verified || 0) + (prev.verified || 0);
                    prev.rejected = (curr.task_count.rejected || 0) + (prev.rejected || 0);
                    prev.archived = (curr.task_count.archived || 0) + (prev.archived || 0);
                    return prev;
                }, countDetails)
            }
            this.setState({ countDetails })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
      
    render() {
        let {countDetails  } = this.state;
        return (

<>

            <div className="px-1">
                <Row  style={{fontSize: 14, borderRadius: 12, backgroundColor: "#545556 "}} className=" border align-items-center py-1 my-2 ">
                    <Col>
                        <strong className="d-block text-white">{(countDetails.ExternalUsers || []).length} Members</strong>
                        {/* <span></span> */}
                    </Col>
                    <Col xs={12}>
                        <Row className="mt-3 mb-1 g-2">
                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small text-white">{countDetails.pending || 0}</span>
                                    <span className="d-block small text-white">Pending</span>
                                </div>
                            </Col>

                            <Col > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small text-white">{countDetails.verified || 0}</span>
                                    <span className="d-block small text-white">Verified</span>
                                </div>
                            </Col>

                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small text-white">{countDetails.rejected || 0}</span>
                                    <span className="d-block small text-white">Rejected</span>
                                </div>
                            </Col>

                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small text-white">{countDetails.archived || 0}</span>
                                    <span className="d-block small text-white">Expired</span>
                                </div>
                            </Col>
                    
                        </Row>
                    </Col>
                </Row>
            </div>

            {(countDetails.ExternalUsers || []).length ?
            (countDetails.ExternalUsers || []).map(data =>

            <div className="px-1">
                <Row style={{fontSize: 14, borderRadius: 12}} className="bg-light border align-items-center py-1 my-2">
                    <Col xs={6}>
                        <strong className="d-block">{data.first_name}</strong>
                        <span className="small d-block">{data.mobile}</span>
                    </Col>
                    <Col xs={6} className="mb-3">
                        <span className="ml-5 small">{moment(data.created_on).utcOffset("+05:30").format("Do MMM'YY hh:mmA")}</span>
                    </Col>
                    <Col xs={12}>
                        <Row className="mt-3 mb-1 g-2">
                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small">{(data.task_count ||{}).pending || 0}</span>
                                    <span className="d-block small">Pending</span>
                                </div>
                            </Col>

                            <Col > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small">{(data.task_count ||{}).verified || 0}</span>
                                    <span className="d-block small">Verified</span>
                                </div>
                            </Col>

                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small">{(data.task_count ||{}).rejected || 0}</span>
                                    <span className="d-block small">Rejected</span>
                                </div>
                            </Col>

                            <Col  > 
                                <div className="border text-center"  style={{borderRadius: 5}}>
                                    <span className="font-weight-bold small">{(data.task_count ||{}).archived || 0}</span>
                                    <span className="d-block small">Expired</span>
                                </div>
                            </Col>
                    
                        </Row>
                    </Col>
                </Row>
            
            </div>
            ) : <em className="m-4 text-center text-secondary d-block">No data</em>  }
            </>
        );
    }
}
export default UserDetailsMyTeam;
