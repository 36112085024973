import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import {groupArrayOnKey} from "../../../actions"
import { setError, setLoader } from "../../../actions/action-creators";
import Aux from "../../global/AuxComp";
import moment from "moment";
import SelectList from "../../global/form/select";

class MaterialIssue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            returnedMaterialData : {} ,
            spentMaterialData : {} ,
            userLastCount: {},
            isEditMaterialCount : false,
            materialTransaction : [],
            materialList : [] ,
            last3IssedTransaction: [],
            selectedStoreId : null,
            stores : [],
        }
    }

    async componentDidMount(){
        this.fetchMaterial();
        this.getLastMaterialTask();
    }

   
    addDispatchMaterial = async (e) => {
        e.preventDefault();
            store.dispatch(setLoader(true))
            let {selectedStoreId, } = this.state;

            try{
                var body = {
                    job_id : this.props.job_id ,
                    store_id : this.props.store_id,
                    user_id : this.props.user_id,
                    item : this.state.materialList.filter(obj => !!obj.value).map(obj => {return {material_description_id : obj.material_description_id, value : obj.value}})
                }
                await ApiEndpoints.StoreManager.dispatchMaterial(body);
                this.props.refresh();
                this.props.toggle();
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false))
    }

    getLastMaterialTask = async () => {
        store.dispatch(setLoader(true))
        if(this.props.last_issued_date){
            try{
                var body = {
                    job_id : this.props.job_id,
                    user_id : this.props.user_id,
                    store_id : this.props.store_id,
                    last_date : moment( this.props.last_issued_date).format("YYYY-MM-DD")
                }
                let resp = await ApiEndpoints.StoreManager.fetchMaterialData(body);
                let userLastCount = resp.data.data[0] || {};
                let returnedMaterialData = userLastCount.returned_data
                 ? 
                 userLastCount.returned_data.reduce((prev, curr) => {
                     prev[curr.material_description_id] = curr.user_input_count;
                     return prev;
                 }, {})
                 : {};
                let spentMaterialData = userLastCount.material_info
                 ? 
                 userLastCount.material_info.reduce((prev, curr) => {
                     prev[curr.item] = curr.total_value_spent;
                     return prev;
                 }, {})
                 : {};
                this.setState({ userLastCount, returnedMaterialData, spentMaterialData })

            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
        }
        store.dispatch(setLoader(false))
    }

    fetchMaterial = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           job_id : this.props.job_id ,
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialItem(body );
          let materialList = resp.data.data;

        //   let countResp = await ApiEndpoints.StoreManager.fetchMaterialInputCount(body );
        //   let counts = countResp.data.data.reduce((prev, curr) => {prev[curr.material_description_id] = curr; return prev;}, {});
          
          let userResp = await ApiEndpoints.StoreManager.fetchAllTransaction({job_id : this.props.job_id, user_id: this.props.user_id , store_id: this.props.store_id});
          let materialLimit = await ApiEndpoints.StoreManager.fetchMaterialLimits({store_id: this.props.store_id});
          materialLimit = groupArrayOnKey(materialLimit.data.data, "material_description_id", true);
          let materialIssed = userResp.data.data.reduce((prev, curr)=> {
              curr.material_info.map(obj => {
                prev[obj.material_description_id] = prev[obj.material_description_id] || 0;
                prev[obj.material_description_id] = prev[obj.material_description_id]+obj.value;
              })
              return prev;
          }, {})

          materialList = materialList.map(material => {
            //   material.left_in_store = counts[material.material_description_id] ? counts[material.material_description_id].left_in_store : 0;
              material.issued_to_user = materialIssed[material.material_description_id] || 0;
              material.value_in_task = (materialLimit[material.material_description_id] && materialLimit[material.material_description_id].value_in_task)||0;
            //   material.last_3 = materialIssedTransaction[material.material_description_id] ? materialIssedTransaction[material.material_description_id].slice(Math.max(materialIssedTransaction[material.material_description_id].length - 3, 0)) : [] 
              return material;
          })

          let last3IssedTransaction = userResp.data.data.slice(Math.max(userResp.data.data.length -3, 0)).reverse().map(transaction => {
                transaction.material_info = transaction.material_info.reduce((prev, curr)=> {
                    prev[curr.material_description_id] = curr;
                    return prev;
                }, {})
                return transaction;
            })
          this.setState({materialList, last3IssedTransaction})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }



    onChangeMaterialIssueCount = (i, value) => {
        let {materialList} = this.state;
        materialList[i].value = value;
        this.setState({materialList});
    }
   
    render() {
        let{materialList, last3IssedTransaction , selectedStoreId ,stores, spentMaterialData, returnedMaterialData} = this.state;
        let materialById = materialList.reduce((prev, curr) => {
            prev[curr.material_description_id] = curr;
            return prev;
        }, {});
        return (
            <Aux>
                    <Modal size="lg"  isOpen={true} toggle={()=> this.props.toggle()} className="modal-dialog-centered">
                        <ModalHeader toggle={()=> this.props.toggle()}>Issue material to the user</ModalHeader>
                            <form onSubmit={this.addDispatchMaterial}>
                                <ModalBody className="p-3" style={{overflowX: "scroll"}}>
                                 {this.props.last_issued_date && <Row>
                                    <Col className="ml-3">  
                                        <h6 className="r-subheading-3">Count of task from last issued date  <b> - {this.state.userLastCount.count || 0}</b></h6>
                                    </Col>
                                     </Row>
                                 }

                                   
                                    <Row className="flex-nowrap font-weight-bold py-3 mb-2 border-bottom">
                                        <Col xs={1}>#</Col>
                                        <Col xs={2} style={{minWidth: 150}}>Name</Col>
                                        <Col xs={3}>Count</Col>
                                        <Col xs={2} style={{minWidth: 100}}>Remaining</Col>
                                        <Col xs={2}  style={{minWidth: 120}}>Total Issued</Col>
                                        {last3IssedTransaction.map( transaction =>
                                            <Col xs={2}>
                                                Last Issued
                                                <em class="d-block" style={{fontSize : 11}}>{moment(transaction.created_on).utcOffset("+05:30").format("ddd DD MMM YYYY")}</em>
                                            </Col>
                                        )}
                                    </Row>
                                    {materialList.map((obj, i) => 
                                        <Row key={i} className="flex-nowrap">
                                            <Col xs={1} >{i+1}</Col>
                                            <Col xs={2}  style={{minWidth: 150}}>{obj.item_name}</Col>
                                            <Col xs={3}>
                                                <InputFeild value={obj.value} onChange={(e) => this.onChangeMaterialIssueCount(i, e.target.value)} required type="number" placeholder="Quantity to be issued" />
                                            </Col>
                                            <Col xs={2} style={{minWidth: 100}}>{(obj.issued_to_user||0) - (returnedMaterialData[obj.material_description_id] || 0) - (spentMaterialData[obj.item_name] || 0)}</Col>
                                            <Col xs={2} style={{minWidth: 120}} className="text-center">{obj.issued_to_user}</Col>
                                            {last3IssedTransaction.map( transaction =>
                                            <Col xs={2}>
                                                {transaction.material_info[obj.material_description_id] ? transaction.material_info[obj.material_description_id].value : 0}
                                            </Col>
                                        )}
                                        </Row>
                                    )}
                                 </ModalBody>
                                <ModalFooter>
                                    <Button className="buttonred" size="xs" style={{ height: "27px", paddingTop: "3px"}}>Issued</Button>{' '}
                                </ModalFooter>
                        </form>
                    </Modal>
               
            </Aux>
            
            
        );
    }
}
export default MaterialIssue;
