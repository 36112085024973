import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { groupArrayOnKey } from "../../../actions";
import { Icon } from "@material-ui/core";


class UserDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents : [],
            imagesData: {}
        }
    }

    componentDidMount(){
        this.fetchDocuments();
    }

   
      fetchDocuments = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let documentResp = await ApiEndpoints.UserProfile.fetchDocument(body)
            let documents = documentResp.data.data;
           
            this.setState({ documents })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

      
      downloadFileByID = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileBody = {file_id : fileId}
            let urlResp = await ApiEndpoints.Files.getFileID(fileBody);
            let fileUrl = urlResp.data.data[0] ? urlResp.data.data[0].url : null;

            let imagesData = {[fileId.toString()]: fileUrl};
            this.setState({ imagesData });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

      
    render() {
        let { documents, imagesData } = this.state;
        let documentByType = groupArrayOnKey(documents, "type", true);
        if(documentByType["AADHAAR"]) console.log(imagesData, documentByType["AADHAAR"].file_id, imagesData[documentByType["AADHAAR"].file_id]);
        return (
            <>
               <Row>
                    <Col xs={12}>
                        { documents.length ? <>
                            <div className="my-3">
                                <h6 className="mb-3">Aadhaar Card</h6>
                                {
                                    documentByType["AADHAAR"] ? 
                                    <>
                                        <span className="d-block">Number: ******{(documentByType["AADHAAR"].number || "").slice(6)}</span>
                                        {documentByType["AADHAAR"].file_id && <Button onClick={() => this.downloadFileByID(documentByType["AADHAAR"].file_id)} type="button" size="24px" className="buttonred m-2" style={{ fontSize: "10px"}}>Front Attached <Icon style={{fontSize: 12}} className="align-middle">visibility</Icon></Button>}
                                        {documentByType["AADHAAR"].file_id2 && <Button onClick={() => this.downloadFileByID(documentByType["AADHAAR"].file_id2)} type="button" size="24px" className="buttonred m-2" style={{fontSize: "10px"}}>Back Attached <Icon  style={{fontSize: 12}} className="align-middle">visibility</Icon></Button>}
                                        <br />
                                        {imagesData[documentByType["AADHAAR"].file_id] && <img src={imagesData[documentByType["AADHAAR"].file_id]} style={{width: "45%", marginRight: "5%"}} alt="Aadhaar Front" />}
                                        {imagesData[documentByType["AADHAAR"].file_id2] && <img src={imagesData[documentByType["AADHAAR"].file_id2]} style={{width: "45%"}} alt="Aadhaar Front" />}
                                    </> : 
                                    <span className="small">No Aadhaar card added</span>
                                }

                                <h6 className="my-3">PAN Card</h6>
                                {
                                    documentByType["PAN"] ? 
                                    <>
                                        <span className="d-block">Number: ****{(documentByType["PAN"].number || "").slice(4)}</span>
                                        {documentByType["PAN"].file_id && <Button onClick={() => this.downloadFileByID(documentByType["PAN"].file_id)} type="button" size="24px" className="buttonred m-2" style={{ fontSize: "10px"}}>Front Attached <Icon style={{fontSize: 12}} className="align-middle">visibility</Icon></Button>}
                                        <br />
                                        {imagesData[documentByType["PAN"].file_id] && <img src={imagesData[documentByType["PAN"].file_id]} style={{width: "45%", marginRight: "5%"}} alt="PAN" />}
                                    </> : 
                                    <span className="small">No PAN card added</span>
                                }
                            </div>
                           
                        </>
                        : <em className="m-4 text-secondary d-block">No Aadhaar/PAN details added</em> }
                    </Col>
                </Row>
            </>
           
        );
    }
}
export default UserDocuments;
