import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser, groupArrayOnKey } from "../../../actions";
import Aux from "../../global/AuxComp"
import ConfirmAction from "../../global/confirm-action";
import { Dialog, DialogActions, DialogContent, DialogTitle, Switch } from "@material-ui/core";

class ManageTL extends Component {
    constructor(props) {
      super(props);
      this.state = {
            teamLeaders : [],
            isAddNewTeamLeader : false,
            isOpenAddTeamLeader : false,
            user : {},
            editTeamLeader : {},
            editJobLocationId : [],
            isOpenConfirm : false,
            isOpenUsers: false,
            assignedApplicants : [],
            selectedApplicants : [],
            mappedApplicants : []
        }
    }
    componentDidMount(){
        this.fetchTeamLeader();
    }
    
    fetchTeamLeader = async () => {
        store.dispatch(setLoader(true));
        let { job } = this.props;
        try{
          var accessOpts = {
            access_type : "EMPLOYER",
            access_type_id : this.props.job.employer_id,
            role_id: 8
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let teamLeaders = accessResp.data.data;
          var locationAccess = {};
          if(job.JobLocations.length){
              let locationAccessResp = await ApiEndpoints.User.fetchMappedAccess({
                access_type : "JOB_LOCATION",
                access_type_id : job.JobLocations.map(loc => loc.job_location_id).join(","),
                role_id: 8
              });
              locationAccess = locationAccessResp.data.data;
              var locations = groupArrayOnKey(job.JobLocations, "job_location_id", true)
              locationAccess = locationAccess.map(access => {
                 access.location = locations[access.access_type_id] || null;
                 return access;
              })
              locationAccess = groupArrayOnKey(locationAccess, "user_id")
          }
          teamLeaders = teamLeaders.map(manager => {
              manager.JobLocations = (locationAccess[manager.user_id] || []).filter(access => !!access.location).map(access => {return {...access.location, ...access}});
              return manager;
          })
          this.setState({teamLeaders})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }

    addTeamLeader = async (e) =>{
        e.preventDefault();
        let { user} = this.state;
        store.dispatch(setLoader(true))
        try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var body = {...user, role_id : 8, access_type : "EMPLOYER", access_type_id : this.props.job.employer_id}
            await addUser(body);
            await this.fetchTeamLeader()
            this.setState({isOpenAddTeamLeader: false , user : {}})
            store.dispatch(setSuccess({show: true, message: "Intervewier Added!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    editTeamLeader = async () => {
        let {editJobLocationId, editTeamLeader} = this.state;
        store.dispatch(setLoader(true))
        try{

            let mappedJobLocationIds = editTeamLeader.JobLocations.map(o => o.job_location_id);
            let newJobLocationIds = editJobLocationId.filter(v => mappedJobLocationIds.indexOf(v) === -1);

            let deletedAccessId = editTeamLeader.JobLocations.filter(a => editJobLocationId.indexOf(a.job_location_id) === -1).map(a => a.access_id);
            deletedAccessId.length && await ApiEndpoints.User.inactiveAccess({access_id : deletedAccessId});
            await Promise.all(newJobLocationIds.map(job_location_id => {
                var userBody = {
                  user_id : editTeamLeader.user_id,
                  user_role_id : editTeamLeader.user_role_id,
                  access_type : "JOB_LOCATION",
                  access_type_id : job_location_id
                }
                return addUser(userBody)
            }));
            this.setState({editTeamLeader : {}})
            await this.fetchTeamLeader()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }
   
  
    changeTLPayType = async (userRoleId, payType) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                user_role_id: userRoleId, 
                pay_type: payType
              }
            await ApiEndpoints.User.editMappedRole(body);
            await this.fetchTeamLeader()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    deletedTeamLeader = async (access_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                access_id
              }
            await ApiEndpoints.User.inactiveAccess(body);
            await this.fetchTeamLeader()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchAssignedUsers = async (manager) => {
        store.dispatch(setLoader(true))
        this.setState({selectedApplicants : [], mappedApplicants : []});
        try{
            var body = {
                job_id : this.props.job.job_id,
                job_location_id : manager.JobLocations.map(loc => loc.job_location_id)
            }
            let applicantsResp = await ApiEndpoints.Applicants.fetch(body);
            let assignedApplicants = applicantsResp.data.data;
            let assignedResp = await ApiEndpoints.Applicants.fetchApplicationAccess({user_role_id: manager.user_role_id});
            let selectedApplicants = assignedResp.data.data.map(obj => obj.application_id);
            this.setState({assignedApplicants, selectedApplicants: [...selectedApplicants], mappedApplicants : selectedApplicants});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    assignApplicants = async () => {
        store.dispatch(setLoader(true))
        let { isOpenUsers, selectedApplicants, mappedApplicants } = this.state;
        try{
            var body = {
                user_role_id : isOpenUsers.user_role_id,
            }
            let assignUsers = selectedApplicants.filter(selected => mappedApplicants.indexOf(selected) === -1);
            body.application_id = assignUsers;
            if(assignUsers.length) await ApiEndpoints.Applicants.addApplicationAccess(body);
            let removeUsers = mappedApplicants.filter(mapped => selectedApplicants.indexOf(mapped) === -1);
            body.application_id = removeUsers;
            if(removeUsers.length) await ApiEndpoints.Applicants.inactiveApplicationAccess(body);

            this.setState({isOpenUsers : false})
            store.dispatch(setSuccess({show: true, message: "Users assigned successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render(){
           let {teamLeaders, isOpenAddTeamLeader, editTeamLeader, editJobLocationId, isOpenUsers, assignedApplicants, selectedApplicants} = this.state;
           let {job} = this.props;
           
           return(
               
            <div>
                <Row>
                    <Col>
                    <Button className="buttonred my-3" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isOpenAddTeamLeader : true})}>Add Team Leader</Button>{' '}
                    </Col>
                </Row>
                <Row>
                    <Col xs={1} ><h6>S.No</h6> </Col>
                    <Col xs={3}> <h6>Team Leaders</h6> </Col>
                    <Col xs={2}> <h6>Variable Pay?</h6> </Col>
                    <Col xs={3}> <h6>City Name</h6> </Col>
                    <Col xs={1}></Col>
                    <Col xs={2}></Col>
                </Row>
                <Collapse isOpen={isOpenAddTeamLeader}>
                    <Card>
                    <CardBody>
                    <form onSubmit={this.addTeamLeader}>
                            <Row style={{marginTop: "-20px"}}>
                                <Col xs={4}>
                                    <InputFeild required name="first_name" onChange={this.onChangeUser}  className="mt-3" placeholder="Name" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild required name="mobile" type="number" onChange={this.onChangeUser} className="mt-3" placeholder="Phone Number" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild name="email" onChange={this.onChangeUser} className="mt-3" placeholder="Email Address" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "20px", float: "right"}}>
                                <Col style={{marginRight : "-22px"}}>
                                    <Button className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </Col>
                                <Col>
                                    <Button onClick={() => {this.setState({isOpenAddTeamLeader : false})}}  type="button" color="secondary" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    </Card>
                </Collapse>
                {teamLeaders.map((tl, i) => 
                    editTeamLeader.user_id !== tl.user_id ?
                    <Aux key={i}>
                        <Row className={"align-items-center"}>
                            <Col xs={1} >{i+1}</Col>
                            <Col xs={3}>{tl.User.first_name}<br />{tl.User.mobile}</Col>
                            <Col xs={2} className="text-center"><Switch size="small" checked={tl.UserRole.pay_type} onChange={(e) => this.changeTLPayType(tl.user_role_id, e.target.checked ? 1 : 0)} /></Col>
                            <Col xs={3}>
                                <span className="float-left">{tl.JobLocations.map(loc => loc.city).join(", ") }</span>
                            </Col>
                            <Col xs={1}>
                                {!!tl.JobLocations.length && <Button className="mb-2" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}} size="sm" color="secondary" onClick={() => {this.setState({isOpenUsers : tl}); this.fetchAssignedUsers(tl)}}>Partners</Button>}
                            </Col>
                            <Col xs={2}>
                                <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedTeamLeader([tl.access_id, ...(tl.JobLocations.map(loc => loc.access_id))])}) } style={{cursor: "pointer"}} fontSize="small" className="float-right">delete</Icon>
                                <Icon onClick={() => this.setState({editTeamLeader : tl, editJobLocationId : tl.JobLocations.map(o => o.job_location_id)})} style={{cursor: "pointer"}} fontSize="small" className="float-right ml-2 mr-2">edit</Icon>
                            </Col>
                        </Row>
                    </Aux>
                     : 
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={5}>{tl.User.first_name+" | "+tl.User.mobile}</Col>
                        <Col xs={3}>
                            <SelectList multiple="checkbox" placeholder="Select City" value={editJobLocationId} required onChange={(e) => this.setState({editJobLocationId : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option key={i} value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                        </Col>
                        <Col>
                            <div style={{marginTop: "7px"}}>
                                <Icon onClick={this.editTeamLeader} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({editTeamLeader : {}})}>close</Icon>
                            </div>
                        </Col>
                    </Row>
                )}
                <Dialog open={!!isOpenUsers} onClose={() => this.setState({isOpenUsers : false})}>
                    <DialogTitle>Assign Partners</DialogTitle>
                    <DialogContent>
                        <div  style={{width: 400, padding: 20}}>
                            <SelectList multiple="checkbox" search={true} placeholder="Select Users" value={selectedApplicants} onChange={(e) => {this.setState({selectedApplicants : e.target.value})}} >
                                {
                                    assignedApplicants.map(applicant => 
                                        <option key={applicant.user_id} value={applicant.application_id}>
                                            {applicant.User.first_name}, PMW{applicant.User && applicant.User.id}
                                        </option>
                                    )
                                }
                            </SelectList>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button size="sm" onClick={() => this.setState({isOpenUsers : false})} color="secondary">
                            Close
                        </Button>
                        <Button size="sm" onClick={this.assignApplicants} color="secondary">
                            Save
                        </Button>
                        </DialogActions>
                </Dialog>
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
            </div>

           );
       }
    }
export default ManageTL;
