import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import DUMMY_PROFILE_IMG from "../../../assets/images/company-default.png";
import CustomerLeadDetail from "./customer_lead_details";



class CustomerCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenUserModel: null 
        }
    }

    componentDidMount(){
    }

   

    render() {
        // let {} = this.state;
        let { data = {} } = this.props;
        return (
        <>        
            <Row noGutters className="align-items-center">
                <Col xs={"auto"} className="p-2" style={{position:"relative"}}>
                    <img className="rounded-circle" style={{width: "25px" , height: "25px", fontSize: 8, display: "block"}} src={data.profile_picture_url ? data.profile_picture_url : DUMMY_PROFILE_IMG} onError={(e)=>{e.target.onerror = null; e.target.src=DUMMY_PROFILE_IMG}}  alt="Profile Pic.." />
                </Col>
                <Col>
                    <b onClick={() => this.setState({isOpenUserModel: true})} style={{cursor : "pointer"}}>{data.name || ""}</b>< br />
                    <span style={{fontSize: 12}}>{ data.mobile }</span>
                    <em style={{fontSize: 12}}>{data.city ? data.city : data.UserConfigs && data.UserConfigs[0] && data.UserConfigs[0].city ? data.UserConfigs[0].city : "" }</em> 
                    <em style={{fontSize: 12}}>{data.state ? ", "+data.state  : data.UserConfigs && data.UserConfigs[0] && data.UserConfigs[0].state ? ", "+data.UserConfigs[0].state : ""}</em> 
                </Col>
            </Row>
            {!!this.state.isOpenUserModel && <CustomerLeadDetail {...data} toggle={() => this.setState({isOpenUserModel : false})} />}
         </>   
        );
    }
}
export default CustomerCard;
