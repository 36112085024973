import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import moment from "moment";

class UserPortfolio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfolioList : []
        }
    }

    componentDidMount(){
        this.fetchPortfolioMapping();
    }
    
    fetchPortfolioMapping = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {  user_id: this.props.user_id, includeAll: true, archive_status: [0, 1]  };
            
            let apiData = await ApiEndpoints.Portfolio.fetchMappedPortfolio(body);
            await this.setState({ portfolioList: apiData.data.data })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

      
    
    render() {
        let { portfolioList } = this.state;
        return (
            <div className="px-1" style={{fontSize: 14}}>                   
                {
                portfolioList.map(obj => {
                    return <div style={{fontSize: 14, borderRadius: 12}} className="bg-light border px-2 py-2 my-3">
                        <Row className="align-items-center">
                            <Col xs={8}>
                                <div className="pl-2">
                                    <span className="font-weight-bold d-block">{obj.Portfolio.portfolio_name}</span>
                                </div>
                            </Col>
                            <Col xs={4} className="text-right">
                                <span className="d-block" style={{fontSize: 10}}>{moment(obj.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm A")}</span>
                            </Col>
                        </Row>
                    </div>
                })
                }
               
            </div>
           
        );
    }
}
export default UserPortfolio;
