import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import { Link } from "react-router-dom";
import CreateBanner from "./create_banner"

let moment = require("moment");


class Marketing extends Component {
render() {
    
    return (
        <>
                <Row className="mb-3">
                        <Col style={{marginRight: "27px"}}>
                        <Button id="Banner" onClick={()=>{this.props.history.push("/marketing/create_Banner");}}  type="button" className="buttonred" size="lg" style={{ height: "50px", paddingTop: "25px"}}><b>Banner</b></Button>
                        </Col>
                </Row>
        </>
    )}};

export default Marketing;