import React, { Component } from "react";
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import JobCreation from '../views/project/job_creation';
// import Shortlisting from '../views/recruitment/shortlisting';
import Recruitment from '../views/recruitment';
import ProjectOverview from '../views/project';
import Company from '../views/company';
import Header from "../global/header";
import Aux from '../global/AuxComp';
import { Row, Col } from 'reactstrap';
import SideMenu from '../global/sidebar_menu';
import ApiEndpoints from "../../constants/api_endpoints";
import Login from "../views/login";
import Page404 from "../views/404";
import {SuccessAlert, ErrorAlert} from "../global/response-alert"
import Loader from "../global/loader"
import { setUser, setLoader, setJobs } from "../../actions/action-creators";
import store from "../../store";
import Interviewer from "../views/interviewer";
import Recruiter from "../views/recruiter";
import NewGigWorkers from "../views/project/new_gig_workers";
import Giggers from "../views/giggers/index";
import StoreManager from "../views/store";
import Dashboard from "../views/dashboard/index";
import LeadsDashboard from "../views/dashboard/leads";
import CallLogs from "../views/calling/logs";
import Handshake from "../views/handshake";
import Payout from "../views/payout";
import SendFeedback from "../views/feedback/send_feedback";
import { InitGA, TrackUser, PageView } from "../../actions/ga"
import NewUsers from "../views/recruitment/new_users";
import Webinar from "../views/webinar";
import WebinarReport from "../views/webinar/webinar_report";
import Leads from "../views/leads";
import ManageAccess from "../views/manage_access";
import Portfolio from "../views/portfolio";
import DormantUsers from "../views/to_be_activated_partners";
import ToBeActivate from "../views/to_be_activated_partners/to_be_activate";
import FirstTaskAttempted from "../views/to_be_activated_partners/first_task_attempted";
import Reports from "../views/reports";
import Invoice from "../views/invoice";
import Callers from "../views/callers/callers";
import CampaignFilter from "../views/campaign_filter/index";
import Marketing from "../views/marketing/index";
import CreateBanner from "../views/marketing/create_banner";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJobId : null
    };
  }
  async componentDidMount(){
    console.log(process.env.REACT_APP_ENV)
    if(process.env.REACT_APP_ENV === "production"){
      InitGA("UA-186176441-2", { debug: false });
      this.trackGA();
    }
    await this.getUser();
  }

 
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.trackGA()    
    }
    if(this.props.location.pathname.includes("job") ){
            const urlData = this.props.location.pathname.split("/");
            if(this.state.selectedJobId!=urlData[3] )
            this.setState({selectedJobId:urlData[3]})           
    }
    if(this.props.location.pathname.includes("/dashboard") && !this.props.location.pathname.includes(this.state.selectedJobId)){
    if(this.state.selectedJobId!==null)
      this.props.history.push(`/dashboard/${this.state.selectedJobId}`)
    }
    if(this.props.location.pathname.includes("/call/logs") && !this.props.location.pathname.includes(this.state.selectedJobId)){
      if(this.state.selectedJobId!==null)
      this.props.history.push(`/call/logs/${this.state.selectedJobId}`)
    }
  }


  trackGA = () => {
    if(process.env.REACT_APP_ENV === "production"){
      let user =  store.getState().user;
      user && TrackUser(user.user_id);
      PageView(this.props.location.pathname);
    }
  }


  getUser = async () =>{
    store.dispatch(setLoader(true))
    try{
      let apiData = await ApiEndpoints.Auth.getUser();
      var roles = apiData.data.data.UserRole || [];
      roles = roles.filter(obj => obj.role_id !== 2)
      if(roles.length){
        await this.fetchJobs();
        store.dispatch(setLoader(false))
        store.dispatch(setUser(apiData.data.data));        
      }
      else{
        store.dispatch(setLoader(false))

        store.dispatch(setUser(null));
      }
    }
    catch(e){
      store.dispatch(setLoader(false))

      store.dispatch(setUser(null));
    }

  }


  fetchJobs = async () => {
    try{
        let apiData = await ApiEndpoints.Jobs.fetchAll();
        var jobList = apiData.data.data.sort((a,b) => a.Employer.employer_name > b.Employer.employer_name ? 1 : -1);
        jobList = jobList
        .sort((a, b) => a.Employer.employer_name.toLowerCase() > b.Employer.employer_name.toLowerCase() ? -1 : 1 )
        .sort((a, b) => a.archive_status > b.archive_status ? 1 : -1);
        await store.dispatch(setJobs(jobList));       
    }
    catch(e){
      console.log(e)
    }
}
  
  logout = async() => {
      store.dispatch(setLoader(true))
      await ApiEndpoints.Auth.logout()
      await store.dispatch(setUser(null));
      this.props.history.push('/')
      store.dispatch(setLoader(false))
  }

  render() {
    let user = store.getState().user;
    let userRoles = user && user.UserRole ? user.UserRole : [];
    var defaultRouteByRole = {
      "_1": "/project/overview",
      "_3": "/dashboard",
      "_7": "/dashboard",
      "_4": "/recruitment",
      "_5": "/recruitment",
      "_8": "/portfolio",
      "_9": "/webinar",
      "_6": "/company/store"
    }
    let defaultRoute = "/dashboard";
    Object.keys(defaultRouteByRole).some(roleId => {
      if(userRoles.filter(userRole => "_"+userRole.Role.role_id === roleId).length ){
        defaultRoute = defaultRouteByRole[roleId];
        return true;
      }
      return false;
    });
    return (
      <Aux>
        <Loader />
      {
        !store.getState().user ?
        <Login getUser = {this.getUser}   />
        :
        <Aux>
            <Header />
            <Row noGutters className="bg-light">
              {window.innerWidth > 720 && 
              <Col xs={2} className="">
                  <div style={{width: "100%", height: "calc(100vh - 50px)", overflowY: "scroll", background : "#ffffff"}} className="shadow pt-3">
                   <SideMenu />
                </div>

              </Col>
              }
              <Col xs={window.innerWidth > 720 ? 10:12} className="py-2" >
                <SuccessAlert />
                <ErrorAlert />
                <div style={{width: "auto", height: "calc(100vh - 80px)", overflow: "scroll", background: "#fff"}} className="p-3 mx-2 shadow">
                  <Switch>
                    {/* <Route exact path="/" component={Dashboard} /> */}
                    <Route exact path="/giggers" component={Giggers}/>
                    {/* <Route exact path="/shortlisting" component={Shortlisting}/> */}
                    <Route exact path="/recruitment" component={Recruitment}/>
                    {/* <Route exact path="/recruitment/new-user" component={NewUsers}/> */}
                    <Route exact path="/recruitment/job/:job_id" component={Recruitment}/>
                    <Route exact path="/recruitment/job/:job_id/loc/:job_location_id" component={Recruitment}/>
                    <Route exact path="/project/worker" render={() => <Recruitment flowControl={false} />}/>
                    <Route exact path="/project/create" component={JobCreation}/>
                    <Route exact path="/project/overview" component={ProjectOverview}/>
                    <Route exact path="/project/overview/:job_id" component={ProjectOverview}/>
                    <Route exact path="/company" component={Company}/>
                    {/* <Route exact path="/company/store" component={StoreManager}/> */}
                    <Route exact path="/dashboard" component={Dashboard}/>
                    <Route exact path="/dashboard/:job_id" component={Dashboard}/>
                    <Route exact path="/dashboard/tab/leads" component={LeadsDashboard}/>
                    <Route exact path="/interviewer/manage" component={Interviewer}/>
                    <Route exact path="/recruiter/manage" component={Recruiter}/>
                    <Route exact path="/call/logs" component={CallLogs}/>
                    <Route exact path="/call/logs/:job_id" component={CallLogs}/>
                    <Route exact path="/handshake" component={Handshake}/>
                    {/* <Route exact path="/project/training" component={ProjectTraining}/> */}
                    <Route exact path="/new_gig_workers" component={NewGigWorkers}/>
                    <Route exact path="/payout" component={Payout}/>

                    <Route exact path="/campaign" component={CampaignFilter}/>

                    <Route exact path="/marketing" component={Marketing}/>
                    <Route exact path="/marketing/create_Banner" component={CreateBanner}/>

                    <Route exact path="/webinar" component={Webinar}/>
                    <Route exact path="/webinar/report" component={WebinarReport}/>

                    <Route exact path="/leads" component={Leads}/>
                    <Route exact path="/access/manage" component={ManageAccess}/>
                    <Route exact path="/portfolio" component={Portfolio}/>
                    <Route exact path="/dormant_users" component={DormantUsers}/>
                    <Route exact path="/to_be_activate" component={ToBeActivate}/>
                    <Route exact path="/task_attempted" component={FirstTaskAttempted}/>
                    <Route exact path="/send-feedback" component={SendFeedback}/>

                    <Route exact path="/reports" component={Reports}/>
                    <Route exact path="/invoice" component={Invoice}/>

                    <Route exact path="/" render={() => <Redirect to={defaultRoute} />} />
                    <Route exact path="/callers" component={Callers} />
                    <Route exact path="/log_out" component={() => {this.logout();return <Redirect to='/' /> }} />
                    <Route component={Page404} />
                  </Switch>
                </div>
              </Col>
            </Row>
          
        </Aux>
      }
      </Aux>
        
    );
}
}

export default withRouter((props) =>  <Routes {...props} />);
