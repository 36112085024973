import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon } from "@material-ui/core";
import { addUser } from "../../../actions";
import { Link } from "react-router-dom";
import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import moment from "moment";

class MaterialInputAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
           materialList : [],
           addMaterialInput : {input_type : "JOB"}
        }
    }

    componentDidMount(){
        this.fetchMaterial();
    }

    onChangeAdd = (key, value) => {
        let {addMaterialInput} = this.state;
        addMaterialInput[key] = value;
        this.setState({addMaterialInput});
    }

    fetchMaterial = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           job_id : this.props.selectedJob.job_id ,
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialItem(body );
          this.setState({materialList : resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    submitMaterialInput = async () => {
        store.dispatch(setLoader(true))
        let { addMaterialInput, materialList } = this.state;
        try{
          var body = {
           job_id : this.props.selectedJob.job_id ,
           input_type : addMaterialInput.input_type,
           ref_value : addMaterialInput.ref_value,
           store_id: this.props.store_id,
           item : []
        }
          materialList.map(material => {
              if(addMaterialInput[material.material_description_id]){
                  body.item.push({material_description_id : material.material_description_id, value : addMaterialInput[material.material_description_id] });
              }
          })
          let resp = await ApiEndpoints.StoreManager.addMaterialInput(body );
          this.props.toggle();
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


   
    render() {
        let { selectedJob, applicantList } = this.props;
        let { addMaterialInput, materialList } = this.state;
       
        return (
            <Aux>

                <Modal isOpen={true} toggle={()=> this.props.toggle()} className="modal-dialog-centered">
                    <ModalHeader toggle={()=> this.props.toggle()}>Store Input</ModalHeader>
                        <ModalBody className="p-3">

                                <Row className="mt-2 mb-3">
                                    <Col xs={6} className="ml-4">
                                        <Label check><Input checked={addMaterialInput.input_type === "JOB"} type="radio" name="radio1" onChange={() => this.onChangeAdd("input_type", "JOB")} />{' '}<span> Input from {selectedJob.Employer.employer_name+"-"+selectedJob.title}</span></Label>
                                    </Col>
                                    <Col xs={4} className="ml-3">
                                        <Label check><Input checked={addMaterialInput.input_type === "USER"} type="radio" name="radio1" onChange={() => this.onChangeAdd("input_type", "USER")} />{' '}<span> Input by Partner</span></Label>                          
                                    </Col>
                                </Row>

                                {addMaterialInput.input_type === "USER" ?
                                <Row className=" mb-4">
                                    <Col>
                                        <SelectList onChange={e => this.onChangeAdd("ref_value", e.target.value)} placeholder="Select User - Emp Id" value={addMaterialInput.ref_value} >
                                        { 
                                        applicantList.map(obj => 
                                        <option key={obj.user_id} value={obj.user_id}> {obj.first_name+" - "+obj.id} </option>
                                        )
                                        }
                                        </SelectList>
                                    </Col>
                                </Row>
                                :
                                <Row className="mb-4">
                                    <Col>
                                        <InputFeild onChange={e => this.onChangeAdd("ref_value", e.target.value)} placeholder="Invoice No. / Courier No. / Docket No" />
                                    </Col>
                                </Row>
                                }
                      
                        {materialList.map((obj, i) => 
                            <Row key={i}>
                                <Col xs={1} >{i+1}</Col>
                                <Col xs={4}>{obj.item_name}</Col>
                                <Col xs={6}>
                                    <InputFeild value={addMaterialInput[obj.material_description_id]} onChange={(e) => this.onChangeAdd(obj.material_description_id, e.target.value)} required type="number" placeholder="Material count to be issued" />
                                </Col>
                            </Row>
                        )}
                                         
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonred" onClick={() => this.submitMaterialInput()} style={{ fontSize: "12px",height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                            <Button type="button" color="secondary" onClick={()=> this.props.toggle()} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                        </ModalFooter>
                </Modal>
            </Aux>
            
            
        );
    }
}
export default MaterialInputAdd;
