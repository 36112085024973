import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon , TablePagination} from "@material-ui/core";
import { addUser, downloadApiFile } from "../../../actions";
import { Link } from "react-router-dom";
import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import GoogleAddress from "../../global/google-address";
import DatePicker from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import TaskScheduleDetails from "./task_schedule_details";

class ManageSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subLocations : [],
            selectedMonth : moment().get("month"),
            isCopiedSchedule: false
        }
    }

    componentDidMount() {
        this.getSubLocations();
    }

    getSubLocations = async () => {
        store.dispatch(setLoader(true))
        let { task } = this.props;
        try{
        var body = {
            job_id : task.job_id, 
            tree_level: 2 
        }
        let resp = await ApiEndpoints.Jobs.fetchJobLocation(body);
        await this.setState({subLocations : resp.data.data})
        this.getTaskScheduling();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    getTaskScheduling = async () => {
        store.dispatch(setLoader(true))
        let { subLocations } = this.state;
        let { task } = this.props;
        try{
        var body = {
            job_task_id : task.job_task_id, 
        }
        let resp = await ApiEndpoints.JobTask.fetchScheduling(body);
        let scheduling = resp.data.data;
        let schedulingByLoc = scheduling.reduce((prev, curr) => {
            prev[curr.job_location_id] = prev[curr.job_location_id] || [];
            prev[curr.job_location_id].push(curr);
            return prev;
        }, {});
        Object.keys(schedulingByLoc).map(job_location_id => {
            let locTaskScheduling = schedulingByLoc[job_location_id];
            locTaskScheduling = locTaskScheduling.reduce((prev, curr) => {
                let date = moment(curr.start_date).utcOffset("+05:30").format("YYYY-MM-DD");
                prev[date] = prev[date] || [];
                prev[date].push(curr);
                return prev;
            }, {});
            schedulingByLoc[job_location_id] = locTaskScheduling;
        })
        subLocations = subLocations.map(loc => {
            loc.schedulingInfo = schedulingByLoc[loc.job_location_id] || {};
            return loc;
        })
        this.setState({subLocations})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
     
    copyTaskSchedule = (data) => {
        this.setState({isCopiedSchedule: data});
        document.addEventListener("keydown", this.escFunction, false);
    }
    escFunction = (e) => {
        if(e.keyCode === 27) {
            this.setState({isCopiedSchedule: false});
            document.removeEventListener("keydown", this.escFunction, false);
        }
    }
    render() {
        let { subLocations , selectedMonth, isCopiedSchedule} = this.state;
        let { task } = this.props;
        var totalShift = this.state.subLocations.length*new Array(moment().month(selectedMonth).daysInMonth()).fill(null).length;
        var scheduledShifts = 0;
        subLocations.map(loc => {
            if(loc.schedulingInfo){
               Object.keys(loc.schedulingInfo).filter(date => moment(date).month() === selectedMonth).map(date => {
                scheduledShifts = scheduledShifts + loc.schedulingInfo[date].length;
               })
            }
        })
        return (
            <Aux>
                {/* <Button className="buttonred mb-3" size="sm" onClick={() => this.setState({isAddNewSchedule : !isAddNewSchedule})}>Add Schedule</Button>{' '} */}
                     <Row className="mb-3 justify-content-end">
                         <Col xs={9}>
                            <h6 style={{fontSize : 14}}>Total shifts in the month - {totalShift}</h6>
                            <h6 style={{fontSize : 14}}>Count of Scheduled shifts - {scheduledShifts}</h6>
                            <h6 style={{fontSize : 14}}>Count of Unscheduled shifts - {totalShift - scheduledShifts}</h6>
                         </Col>
                        <Col xs={3} className="">
                            <SelectList value={selectedMonth} onChange={(e) => this.setState({selectedMonth :  e.target.value})} >
                                {Array.apply(0, Array(12)).map((_,i) =>
                                    <option value={i}>{ moment().month(i).format('MMMM')}</option>
                                )}
                            </SelectList>
                        </Col>
                    </Row>
                <div style={{ overflow: "scroll", height:500}}>
                    
                    <Row noGutters style={{flexWrap: "nowrap", fontSize : "14px", textAlign: "center"}}>
                        <Col xs={3} className="border-bottom border-right" style={{fontWeight : "bold"}}>Sub Location</Col>
                        <Col xs={2} className="border-bottom border-right" style={{fontWeight : "bold"}}>Location Code</Col>
                        {new Array(moment().month(selectedMonth).daysInMonth()).fill(null).map((v, i) => 
                                <Col xs={2} className="border-bottom border-right" style={{fontWeight : "bold"}}>{moment().month(selectedMonth).startOf("month").add(i, "days").format("Do (ddd)")}</Col>
                        )}
                    </Row>
                    {
                        subLocations.map(loc=> 
                            <Row noGutters className="border-bottom" style={{flexWrap: "nowrap", fontSize : "14px", minHeight: 100}}>
                                <Col xs={3} className="border-right p-2">{loc.address}</Col>
                                <Col xs={2} className="border-right p-2">{loc.location_title}</Col>
                                {
                                new Array(moment().month(selectedMonth).daysInMonth()).fill(null).map((v, dayIndex) => {
                                    let date = moment().month(selectedMonth).startOf("month").add(dayIndex, "days").format("YYYY-MM-DD");
                                    return <Col xs={2} className="border-bottom border-right text-center p-0 py-2">
                                        <TaskScheduleDetails 
                                            copyTaskSchedule={this.copyTaskSchedule}
                                            isCopiedSchedule={isCopiedSchedule.job_location_id == loc.job_location_id ? isCopiedSchedule : false}
                                            locationData={loc} 
                                            taskData={task} 
                                            selected_date={date} 
                                            schedulingData = {loc.schedulingInfo && loc.schedulingInfo[date] ? loc.schedulingInfo[date] : []} 
                                            refresh = {this.getTaskScheduling} 
                                        />
                                    </Col>
                                })}
                            </Row>
                        )
                    }
                    {/* <Row className="border-bottom" style={{fontSize : "13px" , flexWrap: "nowrap"}}>
                        <Col xs={2} className="border-right py-2"> <p> sigra</p> </Col>
                        <Col xs={2} className="border-right py-2  visible-on-hover-container">
                            <div className="visible-on-hover-item" style={{position: "absolute", right: 2, top: 0}}>
                                <Icon className="" style={{cursor: "pointer", fontSize: "13px"}}>edit</Icon>
                                <Icon className="ml-1" style={{cursor: "pointer", fontSize: "13px"}}>delete</Icon> 
                            </div>
                             <p className=" mb-0">Form - 4:25 pm</p>
                             <p className=" mb-0">To - 05:25pm</p>
                             <p className=" mb-0"><Button color="link" size="sm">4 Partner</Button> </p>
                        </Col>
                        <Col xs={2} className="border-right py-2">
                             <p className=" mb-0">Form - 12:00 pm</p>
                             <p className=" mb-0">To - 02:10 pm</p>
                             <p className=" mb-0"><Button color="link" size="sm">150 Partner</Button> </p>
                        </Col>
                        <Col xs={2} className="border-right py-2 text-center align-items-center">
                             <div style={{background : "#f2e8e8" , height : 24 , width : 45 , borderRadius : 4 ,  marginTop : 18, marginLeft : 27}}> <Icon style={{color : "#c6c0c0", cursor : "pointer"}}>add</Icon> </div>
                        </Col>
                    </Row>

                    <Row className="border-bottom" style={{fontSize : "13px" , flexWrap: "nowrap"}}>
                        <Col xs={2} className="border-right py-2"> <p>Kanahi</p> </Col>
                        <Col xs={2} className="border-right py-2">
                             <p className=" mb-0">Form - 8:00 am</p>
                             <p className=" mb-0">To - 10:10 am</p>
                             <p className=" mb-0"><Button color="link" size="sm">50 Partner</Button> </p>
                        </Col>
                        <Col xs={2} className="border-right py-2 text-center">
                             <div style={{background : "#f2e8e8" , height : 24 , width : 45 , marginTop : 18, marginLeft : 27 , borderRadius : 4}}> <Icon style={{color : "#c6c0c0", cursor : "pointer"}}>add</Icon> </div>
                        </Col>
                        <Col xs={2} className="border-right py-2">
                             <p className=" mb-0">Form - <ReactDatePicker showTimeSelectOnly showTimeSelect placeholderText="Start time"/></p>
                             <p className=" mb-0">To - <ReactDatePicker showTimeSelectOnly showTimeSelect placeholderText="End time"/></p>
                             <p className=" mb-0"> 
                                <SelectList multiple value={[]} placeholder="Partners" required>
                                        <option >Jaishree jaiswal</option>
                                        <option>Neha sharma</option>
                                        <option>Pranshu pranshu</option>
                                        <option>Anshul garg</option>
                                        <option>Ashish ashish</option> 
                                </SelectList>
                             </p>
                            <Row>
                                <Col className="pt-2">
                                    <Icon className="float-right" style={{cursor: "pointer", fontSize: "13px"}}>close</Icon>
                                    <Icon className="mr-3 float-right" style={{cursor: "pointer", fontSize: "13px"}}>done</Icon> 
                                </Col>
                            </Row>
                        </Col>
                    </Row> */}
                    
                </div>    
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

            </Aux>
            
            
        );
    }
}
export default ManageSchedule;
