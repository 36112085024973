
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { Icon} from "@material-ui/core";
import InputFeild from "../../global/form/input"
import moment from "moment";
import Collapse from "reactstrap/lib/Collapse";
import SelectList from "../../global/form/select";
import { downloadApiFile, uploadFile } from "../../../actions";
import ImageUploadFeild from "../../global/form/upload-image";

class JobCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
           categoryList : [],
           isAddNewCategory: false,
           displayColorPicker: false,
           isOpenConfirm: false
          
        }
    }

    componentDidMount(){
        this.fetchCategories();
    }

    fetchCategories = async (page = 0) => {
        store.dispatch(setLoader(true));
        try{
            var body = {}
              
            let resp = await ApiEndpoints.Jobs.fetchCategory(body);
            this.setState({categoryList: resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    downloadEvents = async (opts = {}) => {
        store.dispatch(setLoader(true));
        let {isFilters} = this.state;
        try {
            var body = {download: true};
            if(isFilters.job_id) body = {...body, entity_type: "JOB", entity_id: isFilters.job_id}
            if(isFilters.start_date) body = {...body, start_date_range: moment(isFilters.start_date).startOf("day").toDate()}
            if(isFilters.end_date) body = {...body, start_date_range: moment(isFilters.end_date).endOf("day").toDate()}
           
            let resp = await ApiEndpoints.Events.fetchEvents(body);
            downloadApiFile(resp, "Events Data.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    onChangeAddCategory = (k, v) => {
        let { isAddNewCategory } = this.state;
        isAddNewCategory[k] = v;
        this.setState({isAddNewCategory})
    }

    createCategory = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try {
            let {job_category_id, title, category_type, theme_color, icon_file, logo_file, slack_channel} = this.state.isAddNewCategory;
            var body = {
                job_category_id, title, category_type, theme_color, slack_channel
            };
            if(logo_file){
                let fileResp = await uploadFile(logo_file, "COMPANY_DATA");
                body.logo_file_id = fileResp.file_id;
            }
            if(icon_file){
                let fileResp = await uploadFile(icon_file, "COMPANY_DATA");
                body.icon_file_id = fileResp.file_id;
            }
            if(job_category_id) await ApiEndpoints.Jobs.editCategory(body);
            else await ApiEndpoints.Jobs.addCategory(body);
            this.setState({isAddNewCategory: false});
            await this.fetchCategories();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }



    render() {
        let { categoryList, isAddNewCategory, displayColorPicker} = this.state;

        return (
            <>
                <Row className="my-3 align-items-end">
                    <Col>
                        <Button className="buttonred" onClick={() => this.setState({isAddNewCategory: isAddNewCategory ? false : {}})}>Add Category</Button>
                    </Col>
                </Row>
                
                <Collapse isOpen={isAddNewCategory}>
                    <form onSubmit={this.createCategory}>
                        <Row className="align-items-center">
                            <Col xs={3}>
                                <ImageUploadFeild label="Upload Icon"  src={isAddNewCategory.icon_url} required onChange={(file) =>  this.onChangeAddCategory("icon_file", file)}/>
                            </Col>
                            <Col xs={3}>
                                <ImageUploadFeild label="Upload Logo"  src={isAddNewCategory.logo_url} required onChange={(file) =>  this.onChangeAddCategory("logo_file", file)}/>
                            </Col>
                            <Col xs={6}>
                                <InputFeild required className="" placeholder="Category Name" onChange={(e) => this.onChangeAddCategory("title", e.target.value)} value={isAddNewCategory.title || ""}/>
                                <SelectList required className="mt-3" placeholder="Category Type" value={isAddNewCategory.category_type}  onChange={({target}) =>  this.onChangeAddCategory("category_type", target.value)}>
                                    {
                                    ["Finance", "Data", "Merchant"].map((v, i) => 
                                    <option value={i}>{v}</option> 
                                    )}
                                </SelectList> 
                                <InputFeild required className="mt-3" placeholder="Slack Channel" onChange={(e) => this.onChangeAddCategory("slack_channel", e.target.value)} value={isAddNewCategory.slack_channel || ""}/>
                                <input className="w-25 mt-3 float-left" type="color" value={isAddNewCategory.theme_color} onChange={(e) => this.onChangeAddCategory("theme_color", e.target.value)} />
                                <Button type="submit" className="buttonred my-3 float-right">{isAddNewCategory.job_category_id ? "Save":"Add New"} Category</Button>
                            </Col>
                        </Row>
                    </form>
                    <hr className="mb-4"/>
                </Collapse>

                <Row>                           
                {
                categoryList.map((cat, i) => 
                    <Col xs={6} className="mt-3 " key={i}>
                        <div className="m-3 visible-on-hover-container">
                            <Row className="py-3 align-items-center" style={{background: (cat.theme_color || "#000000")+"30", borderRadius: 12}}>
                                <Col xs={3}>
                                    <img className="" src={cat.logo_url} style={{width: "80%"}} alt="logo"/>
                                </Col>
                                <Col xs={3}>
                                    <img className="" src={cat.icon_url} style={{width: "80%"}} alt="logo"/>
                                </Col>
                                <Col xs={6}>
                                    <strong className="my-3">{cat.title}</strong>
                                    <span className="d-block">Slack: {cat.slack_channel}</span>
                                    <span>Earn upto ₹{cat.max_earning || 0}</span>
                                    <div className="visible-on-hover-item" style={{position: "absolute", right: 0, top: -12}}>
                                        <Icon onClick={()=>this.setState({isAddNewCategory : cat})} className="mr-2 mt-1" style={{cursor: "pointer", fontSize: 20}}>edit</Icon> 
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                )
                }
                
                </Row>

                        
                        {/* <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} /> */}

            </>
            
            
        );
    }
}
export default JobCategories;
