
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import InputFeild from "../../global/form/input"
import "../../../styles/button.css"
import {Icon} from '@material-ui/core';
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Input, FormGroup } from 'reactstrap';
import ConfirmAction from "../../global/confirm-action";

class JobPayment extends Component {
    constructor(props) {
      super(props);
      this.state = {
           isAddNew : false,
           paymentData : [] ,
           isConfirm : false,

        }
    }

    async componentDidMount(){
        await this.init() ;
    }

    init = async () =>{
        store.dispatch(setLoader(true));
        var body = {
            job_id : this.props.job.job_id
        }
        if(this.props.jobTask){
            body.ref_type = "JOB_TASK";
            body.ref_id = this.props.jobTask.job_task_id;
        }
        try{
            let apiData = await ApiEndpoints.JobTaskPayment.fetch(body);
            this.setState({paymentData : apiData.data.data}) ;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    addPayment = async (e) =>{
        e.preventDefault();
        e.persist();
        let { paymentData } = this.state;
        store.dispatch(setLoader(true));
        let { payment_type, target_value, amount, sendNotification, comment } = e.target;
        let body = {
            job_id: this.props.job.job_id,
            payment_type: payment_type.value,
            target_type: "TASK_COUNT",
            target_value: target_value.value,
            job_location_id:0,
            amount: amount.value,
            // comment: comment.value
        } 
        if(this.props.jobTask){
            body.ref_type = "JOB_TASK";
            body.ref_id = this.props.jobTask.job_task_id;
        }
        try {
            var resp = await ApiEndpoints.JobTaskPayment.add(body);

            if(sendNotification && sendNotification.checked){
                ApiEndpoints.JobTaskPayment.sendUpdateNotification({ job_id: this.props.job.job_id, payment_type: "FIXED" })
            }

            paymentData.push(resp.data.data)
            e.target.reset();
            this.setState({paymentData, isAddNew: false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deletedPayment = async (job_payment_id) =>{
        let {paymentData} = this.state;
        store.dispatch(setLoader(true))
        let body = { 
                    "job_payment_id": job_payment_id
                } 
        try {
            ApiEndpoints.JobTaskPayment.delete(body);
            paymentData = paymentData.filter( obj => { return obj.job_payment_id !== job_payment_id });
            this.setState({paymentData})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    
    render(){
        let {isAddNew, paymentData , isConfirm} = this.state;
        let { jobTask } = this.props;
        return(
        <div>
            <Button id="add_payment_structure_entry" onClick={()=> {this.setState({isAddNew : {}})}} className="buttonred mb-3" style={{height: "23px", paddingTop: "1px"}}>Add Payment</Button>{' '}
            <Row>
                <Col>
                    <Row className="border-bottom py-1 mb-2">
                        <Col xs={3}> <h6>Payment Type</h6> </Col>
                        <Col xs={2}> <h6>Target Value</h6> </Col>
                        <Col xs={2}> <h6>Amount</h6> </Col>
                        <Col xs={3}> </Col>
                        <Col xs={2}> </Col>
                    </Row>

                    { isAddNew ?
                    <form onSubmit={this.addPayment}>
                        <Row className="py-3">
                            <Col xs={3} style={{ marginBottom: "10px"}}>
                                <FormGroup>
                                    <Input onChange={(e) => { isAddNew.paymentType = e.target.value; this.setState({isAddNew}) }} required name="payment_type" id="add_payment_structure_input1" type="select">
                                        <option  value="">Select</option>
                                    {
                                        Object.keys(paymentType).map(status => 
                                            <option key={status} value={status}>{paymentType[status]}</option>
                                        )
                                    }
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col xs={2}>
                                {isAddNew.paymentType === "ACTIVATION" ? 
                                <Input required name="target_value" type="select">
                                    <option  value="">Select</option>
                                    {
                                        jobTask.formData && jobTask.formData.response_status && jobTask.formData.response_status.status2 ? 
                                        Object.keys(jobTask.formData.response_status.status2).map(activationStatus => {
                                            var statusData = jobTask.formData.response_status.status2[activationStatus];
                                            return statusData.status === 1 ? <option key={activationStatus} value={activationStatus}>{statusData.label}</option> : <></>
                                        })
                                        :
                                        <option disabled>No Activation Configuration</option>

                                    }
                                </Input>
                                :
                                <InputFeild required name="target_value" id="add_payment_structure_input2" placeholder="Target Value" />
                            }
                            </Col>
                            <Col xs={2}>
                                <InputFeild required name="amount" inputProps={{min: 1}} type="number" id="add_payment_structure_input3" placeholder="Amount in rupees" />
                            </Col>
                            <Col xs={3}>
                                {isAddNew.paymentType === "FIXED" && 
                                    <div>
                                        <input name="sendNotification" className="align-middle" type="checkbox" />
                                        <span className="ml-3 align-middle">Notify partners?</span>
                                    </div>
                                }
                                {/* <InputFeild name="comment" id="add_payment_structure_input4" placeholder="Comments" /> */}
                            </Col>
                            <Col xs={2} className="text-right"> 
                                <div style={{marginTop: "7px"}}>
                                    <Button className="p-0 mr-3" color="link"><Icon id="add_payment_structure_submit" className="" style={{color: "#000", fontSize: "18px"}}>done</Icon> </Button>
                                    <Icon id="add_payment_structure_cancel" style={{cursor: "pointer", fontSize: "18px"}} onClick={() => this.setState({isAddNew : false})}>close</Icon>
                                </div>
                                </Col>
                        </Row>
                    </form>
                    : 
                    ""
                    }

                    { paymentData.map((payment, i)=> 
                    <Row key={i}>
                        <Col xs={3} style={{fontSize: "14px"}}>{paymentType[payment.payment_type]}</Col>
                        <Col xs={2} style={{fontSize: "14px"}}>
                            {payment.payment_type === "ACTIVATION" ? 
                                jobTask.formData && jobTask.formData.response_status && jobTask.formData.response_status.status2 && jobTask.formData.response_status.status2[payment.target_value] && jobTask.formData.response_status.status2[payment.target_value].label
                                :
                                payment.target_value
                            }
                        </Col>
                        <Col xs={2} style={{fontSize: "14px"}}>{payment.amount}</Col>
                        <Col xs={3} style={{fontSize: "14px"}}>{payment.comment}</Col>
                        <Col xs={2} className="text-right"> 
                                {/* <Icon className="" style={{cursor: "pointer", fontSize: "16px"}}>edit</Icon>  */}
                                <Icon id="delete_payment_structure" onClick={() => this.setState({isConfirm: () => this.deletedPayment(payment.job_payment_id) }) } style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon>
                        </Col>
                    </Row>
                    )
                }
                    </Col>
            </Row>


            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
        </div>
        


        );
    }
}

export default JobPayment;

const paymentType = {
    "FIXED": "Fix Pay",
    "DAILY_INCENTIVE": "Daily Incentive",
    "CYCLE_INCENTIVE": "Cycle Incentive",
    "ACTIVATION": "Activation Bonus"
}
