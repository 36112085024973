
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from 'reactstrap/lib/Button';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";

import { uploadFile } from "../../../actions";

class UploadLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReadyToSubmit: true,
            success: "",
            error: ""
        }
    }

    
    async componentDidMount(){
    }


    uploadLeadByCsv = async (e) =>{
        e.preventDefault();
        this.setState({isReadyToSubmit : false})
        var file = e.target.csvFile.files[0];
        let { jobData } = this.props;
        store.dispatch(setLoader(true))
        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                origin_type: "JOB",
                lead_source: "UPLOAD",
                job_id: jobData.job_id,
                key : fileResp.file_key,
                consumer_type : jobData.consumer_type
            }
            let resp = await ApiEndpoints.Customer.uploadLeads(body);
            store.dispatch(setSuccess({show: true, message: resp.data.data.length + " Records created successfully"}))
            this.props.close && this.props.close()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({isReadyToSubmit : true})
        store.dispatch(setLoader(false))
    }

    render() {
        let { isReadyToSubmit, success, error } = this.state;
        let { jobData } = this.props;
        return (
            <form className="py-2" onSubmit={this.uploadLeadByCsv}>
                
               
                <input id="upload_leads" required type="file" name="csvFile" className="mt-4 mb-2" />
                <p style={{fontSize: 12}}><em>Download Sample File, Fill the details and upload here</em></p>
                
                {jobData.consumer_type === 2 ? 
                    <a target="_blank" download={true} rel="noopener noreferrer" href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/customer+leads+sample.xlsx">Download B2C Sample File</a>
                    :
                    <a target="_blank" download={true} rel="noopener noreferrer" href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/customer+leads+sample+B2B.xlsx">Download B2B Sample File</a>
                }
                            
                <hr className="mt-3" />
                <Row>
                    <Col xs={12} className="text-right">
                        
                        <Button className="buttonred mr-2" onClick={this.props.close}>Cancel</Button>
                     
                        <Button disabled={!isReadyToSubmit} className="buttonred">Submit Lead</Button>
                      
                    </Col>
                </Row>
                {success && <em style={{color: "rgb(12, 68, 12)", fontSize: 12}}>{success}</em>}
                {error && <em style={{color: "#f00", fontSize: 12}}>{error}</em>}
            </form>
        ) 
    }
}

export default UploadLeads;
