
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

import { Chip } from "@material-ui/core";
import JobCategories from "./categories";
import Employer from "./employer";
import Clients from "./clients";


class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }

    selectTab = (i) => {
        this.setState({ selectedTab: i })
    }


  render() {
    let { selectedTab } = this.state;

    return (
    <div className="">
      

        <Row className={"py-3 border-bottom align-items-end"}>
            <Col xs={6}>
                <strong className="large">Manage Project Entities</strong>
            </Col>  
            <Col className="text-right">
            {
                ["Project Company", "Clients", "Project Categories"].map((tab, i) => 
                <Chip key={i} className="mr-2" label={tab} style={{ ...(selectedTab === i ? { color: "#e40d0d", background: "#fef2f2" } : {}) }} onClick={() => this.selectTab(i)} />
                )
            }
            </Col>
        </Row>
        { selectedTab === 0 && <Employer /> }
        { selectedTab === 1 && <Clients /> }
        { selectedTab === 2 && <JobCategories /> }
     
        
    </div>
    )
  }

}


export default Company;
