import React, { Component } from 'react';
import {format as utilFormat} from "util"
import { Chip, Drawer, Icon, Input, styled, TextField } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import store from '../../store';
import ApiEndpoints from "../../constants/api_endpoints";
import { setError, setLoader } from '../../actions/action-creators';
import { Switch } from "@material-ui/core";
import DUMMY_PROFILE_IMG from "../../assets/images/dummy_profile.jpg";
import moment from 'moment';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,} from 'reactstrap';
import {io} from "socket.io-client";
import Button from 'reactstrap/lib/Button';
import { uploadFile } from '../../actions';
import SelectList from "../global/form/select";
import CONSTANTS from '../../constants';
import { TrackEvent } from "../../actions/ga"

var socket = null;

class WhatsappChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: null,
			message: "",
			file: null,
			chatData: [],
			isAllowCustomMessage: false,
			selectedLanguage: "English",
			isVisibleAllMedia: false,
			isVisibleAllChats: false,
			isHideTextInput: false,
			projects: [],
			isTransferChat: false
		}
	}

	componentDidMount() {
		TrackEvent("WHATSAPP", "WHATSAPP_CHATBOT_OPEN", "WHATSAPP_CHATBOT");
		this.fetchConversation();
		const SERVER = process.env.REACT_APP_BASE_URL;
		socket = io (SERVER, { transports: ['websocket']});
		socket.emit("join_whatsapp", {userId: this.props.user.user_id});
		socket.on('whatsapp', (resp) => {
			this.listenIncominMessage(resp);
		});
	}
	
	componentWillUnmount(){
		socket.disconnect();
	}

	fetchConversation = async () => {
		let {user, entity_type, entity_id} = this.props;
		let { isVisibleAllChats } = this.state;
		store.dispatch(setLoader(true));
        try{
			var userBody = {user_id: user.user_id};
			var userData = await ApiEndpoints.UserProfile.fetchProfile(userBody);
			userData = userData.data.data;
			if(!(userData.details && userData.details.whatsapp_no)) throw new Error("Not Opted-In for Whatsapp yet");
			var body = {
				user_id: userData.user_id,
				// mobile_no: userData.details.whatsapp_no,
				include_open_conversation: true
			}
			if(!isVisibleAllChats) body = {...body, entity_type, entity_id};
			if(this.props.thread_id) body.thread_id = this.props.thread_id;
            let apiData = await ApiEndpoints.Whatsapp.fetchConversation(body);
			var chatData = apiData.data.data;
			var incomingChat = chatData.filter(chat => chat.direction === 1);
			var isAllowCustomMessage = incomingChat.length ? moment().subtract(24,"hour").isBefore(incomingChat[incomingChat.length - 1].created_on) : false;
            this.setState({ chatData, isAllowCustomMessage, userData });
			// this.scrollToBottom();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
			this.props.onClose();
        }
        store.dispatch(setLoader(false))
    }

	addConversation = async (message, extraOpts = {}) => {
		let {userData, file} = this.state;
		if(!(message || this.state.message) && !file) return;
		store.dispatch(setLoader(true));
		let { chatData } = this.state;
        try{
			var body = {
				user_id: userData.user_id,
				// mobile_no: userData.details.whatsapp_no,
				message_text: message || this.state.message,
				job_id: this.props.entity_id,
				...extraOpts
			}
			if(file){
				let fileResp = await uploadFile(file, "WHATSAPP");
				body.file_id = fileResp.file_id;
				body.content_type = file.type;
				body.file_key = fileResp.file_key;
			};
            let apiData = await ApiEndpoints.Whatsapp.addConversation(body);
			chatData.push(apiData.data.data);
            this.setState({ chatData, message: "", file: null });
			this.props.onMarkRead && this.props.onMarkRead();
			this.scrollToBottom();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

	listenIncominMessage = (data) => {
		var { chatData, isAllowCustomMessage } = this.state;
		if(data.isNew) {
			chatData.push(data);
			isAllowCustomMessage = true
		}
		else chatData = chatData.map(chat => {
			if(chat.message_id === data.message_id) chat.status = data.status;
			return chat;
		})
		this.setState({chatData, isAllowCustomMessage});
		this.scrollToBottom();
	}

	scrollToBottom = () => {/*this.messagesEnd.scrollIntoView({ behavior: "smooth" }); */}

	fileChangedHandler = (e) => {
		if(e){
			let file = e.target.files[0];
			e.target.value = null;
			var fileSize = file.size / 1024 / 1024;
			if( (fileSize >= 15 )) return store.dispatch(setError({show: true, message: "File size is "+fileSize.toFixed(2)+"MB, It should be less than 15MB"}));
			this.setState({file: file})
		} else{
			this.setState({file: null})
		}
	}

	renderChatMedia = (chatId, media, isShowMedia) => {
		media = media.split(";;");
		if(media[0].includes("image")){
			return <div className='text-center'>
				{isShowMedia || this.state.isVisibleAllMedia ? 
					<img className='d-block' style={{maxWidth: 200}} alt="WA_Media" src={media[1]} />:
					<span><Icon fontSize="large" className="m-4" style={{color: "#aaa"}}>photo_camera</Icon></span>
				}
				<div className='border-top border-bottom py-1 mb-2 w-100 text-center' style={{fontWeight: "bold", fontSize: 12 , color: "#333"}}>
					{!this.state.isVisibleAllMedia && <span className='mr-4' style={{cursor: "pointer"}} onClick={() => this.changeChatData(chatId, "isShowMedia", true, true)}><Icon style={{fontSize : 12, lineHeight: "15px", color: "#333"}}>{isShowMedia ? "visibility_off" : "visibility"}</Icon> View</span>}
					<span><Icon style={{fontSize : 12, lineHeight: "15px", color: "#333"}}> download</Icon><a style={{color: "black"}} href={media[1]} target="_blank" download >Download</a></span>
				</div>
			</div> 
		} else if (media[0].includes("video")){
			return <div className='text-center'> 
				{isShowMedia || this.state.isVisibleAllMedia ? 
					<video controls className="d-block" width={200} src={media[1]} /> :
					<span><Icon fontSize="large" className="m-4" style={{color: "#aaa"}}>videocam</Icon></span>
				}
				<div className='border-top border-bottom py-1 mb-2 w-100 text-center' style={{fontWeight: "bold", fontSize: 12 , color: "#333"}}>
					{!this.state.isVisibleAllMedia && <span className='mr-4' style={{cursor: "pointer"}} onClick={() => this.changeChatData(chatId, "isShowMedia", true, true)}><Icon style={{fontSize : 12, lineHeight: "15px", color: "#333"}}>{isShowMedia ? "stop" : "play_arrow"}</Icon> Play</span>}
					<span><Icon style={{fontSize : 12, lineHeight: "15px", color: "#333"}}> download</Icon><a style={{color: "black"}} href={media[1]} target="_blank" download >Download</a></span>
				</div>
			</div> 
			
		} else if(media[0].includes("audio")){
			return <audio src={media[1]} controls/>
		} else return <div className='d-flex align-items-center border-bottom py-1 mb-2 w-100 text-center' style={{fontWeight: "bold", fontSize: 12 , color: "#333"}}>
			<Icon fontSize="small" className="mr-2" style={{color: "#aaa"}} sty>description</Icon>
			<a download={true} target="_blank" href={media[1]}>Download File</a>
		</div>
	}


	toggleAllMediaVisibility = () => this.setState({isVisibleAllMedia: !this.state.isVisibleAllMedia});


	toggleAllChatVisibility = async () => {
		await this.setState({isVisibleAllChats: !this.state.isVisibleAllChats});
		this.fetchConversation();
	}

	changeChatData = (chatId, k, v, isToggle) => {
		let { chatData } = this.state;
		chatData = chatData.map(chat => {
			if(chat.id === chatId) chat[k] = isToggle && chat[k] === v ?  false : v;
			return chat;
		});
		this.setState({chatData})
	}


	endConversation = () => {
		if(!window.confirm("Are you sure ?")) return;
		var text = this.state.selectedLanguage === "Hindi" ? 
		"आशा है कि हमारी टीम ने %s प्रोजेक्ट के लिए आपके प्रश्न का समाधान कर दिया है। कृपया हमें अपनी प्रतिक्रिया बताएं।" :
		"Hope our team has addressed your query for %s project. Please let us know your feedback."
		this.addConversation(text.includes("%s") ? utilFormat(text, this.props.jobTitle) : text, { close_conversation: true })
	}


	fetchProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user.user_id }
            let projectsResp = await ApiEndpoints.UserProfile.fetchUserProjects(body)
            let projects = projectsResp.data.data
            this.setState({ projects })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
	}

	handoverConversation = async (activeConversation, job_id) => {
		store.dispatch(setLoader(true));
        try{
			var body = { close_thread_id: activeConversation.thread_id, job_id  };
            await ApiEndpoints.Whatsapp.handoverConversation(body);
			this.setState({isTransferChat: false})
			this.fetchConversation();
			this.props.onMarkRead && this.props.onMarkRead();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
	}

	render() {
		
		let loggedInUser = store.getState().user;
		let { isOpen, user={} } = this.props;
		let { chatData, selectedLanguage, file, projects } = this.state;
		chatData = [...chatData].reverse();
		var isActiveConversation = chatData.length && chatData[0]["WhatsappConversationThread.last_message_sent"] === 0 ? chatData[0] : false
		return (
			<Drawer PaperProps={{style: {maxWidth: 500, width: "100%"}}} style={{position: "relative"}} anchor={"right"} open={isOpen} onClose={this.props.onClose}>
				<div style={{width: "100%", height: "100vh", display: "flex", flexDirection: "column", background: "#8eff0015"}}>
					<header style={{flex: 1, position: "sticky", top: 0, zIndex: 99}}>
						<Row noGutters className="align-items-center bg-light p-3">
							<Col>
								<img className="rounded-circle" style={{width: "40px" , height: "40px", fontSize: 8}} src={(user.profile_picture_url) || DUMMY_PROFILE_IMG} onError={(e)=>{e.target.onerror = null; e.target.src=DUMMY_PROFILE_IMG}}  alt="Profile Pic.." />
								<strong className="ml-3" style={{textTransform: "capitalize"}}>{user.first_name}</strong>
							</Col>
							<Col xs={"auto"} className="text-right">
								<UncontrolledDropdown >
									<DropdownToggle tag="span" className=" mr-1" style={{fontSize: 11}} >
										<Icon style={{cursor: "pointer"}}>more_vert</Icon>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem onClick={this.toggleAllMediaVisibility} style={{fontSize: 12}}>{this.state.isVisibleAllMedia ? "Hide":"Show"} All Media</DropdownItem>
										<DropdownItem onClick={this.toggleAllChatVisibility} style={{fontSize: 12}}>{this.state.isVisibleAllChats ? "Show only Relevent Chats" : "Show All Chats"}</DropdownItem>
									</DropdownMenu>
								</UncontrolledDropdown>
							</Col>
							<Col xs={"auto"} className="text-right">
								<Icon style={{cursor: "pointer"}} onClick={this.props.onClose}>close</Icon>
							</Col>
						</Row>
					</header>
					<article style={{flex: 8, overflowY: "scroll", display: "flex", flexDirection: "column-reverse"}}>
					    <Row noGutters className="text-center" style={{backgroundColor: "rgb(207, 234, 174)"}}>
							{!!isActiveConversation && <Col className="py-1">
								<Button color="link" onClick={() => this.endConversation()} style={{fontSize: 12, fontWeight: "bold", padding: 0}}><Icon style={{fontSize: 14, paddingTop: 2}}>highlight_off</Icon>Mark closed this conversation</Button>
							</Col>}
							{!!isActiveConversation && isActiveConversation["WhatsappConversationThread.entity_type"] === "JOB" && 
							<Col className="py-1 border-left">
								{
									this.state.isTransferChat ? 
									<Row className="align-items-center">
										<Col xs={8}>
											<SelectList placeholder="Select Project" value={this.state.isTransferChat.job_id} onChange ={(e) => this.setState({isTransferChat: {job_id: e.target.value } })} style={{fontSize: 14}}>
												{ projects.map(app => <option key={app.job_id} value={app.job_id}> {app.Job.Employer.employer_name +  "  -  "  + app.Job.title}
												{app.Job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[app.Job.archive_status]} /> }
											</option>)}
											</SelectList>
										</Col>
										<Col xs={4}>
											{!!this.state.isTransferChat.job_id && <Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => this.handoverConversation(isActiveConversation, this.state.isTransferChat.job_id)}>done</Icon>}{' '}
											<Icon style={{cursor: "pointer"}} fontSize="small" onClick={() => this.setState({isTransferChat: false})}>close</Icon>
										</Col>
									</Row>
								  	:
									<Button color="link" onClick={() => {this.fetchProjectDetails(); this.setState({isTransferChat : {}}) }} style={{fontSize: 12, fontWeight: "bold", padding: 0}}><Icon style={{fontSize: 14, paddingTop: 2}}>redo</Icon> Transfer this conversation to other</Button>
								}
							</Col>
							}
							
						</Row>
						

						{chatData.map((chat, i) => 
							<Row key={i}>
								<Col style={{maxWidth: "80%", ...( chat.direction === 2 ? {marginLeft: "20%"} : {}) }}>
								<div style={{...(chat.direction === 2 ? senderChatStyle : receiverChatStyle), ...chatBubbleStyle}}>
										<div>
											{chat.media_data && this.renderChatMedia(chat.id, chat.media_data, chat.isShowMedia)}
											{chat.message_text}
										</div>
										<em style={{fontSize: 10}} className="text-muted d-block text-right">
											{moment(chat.created_on).utcOffset("+05:30").format("hh:mm A, Do MMM'YY")}
											{chat.direction === 2 && 
											<>
												{" "}
												{chat.status === "read" &&<Icon className="align-middle" style={{fontSize: 14, color: "#6CB2E7"}}>done_all</Icon>}
												{chat.status === "delivered" && <Icon className="align-middle" style={{fontSize: 14}}>done_all</Icon>}
												{chat.status === "queued" && <Icon className="align-middle" style={{fontSize: 14}}>done</Icon>}
												{chat.status === "sent" && <Icon className="align-middle" style={{fontSize: 14}}>done</Icon>}
												{chat.status === "failed" && <Icon className="align-middle" style={{fontSize: 14, color: "red"}}>error</Icon>}
											</>
											}
										</em>
										{chat.direction === 2 && 
											<em style={{fontSize: 12}} className="text-muted d-block text-right">
												~ {["WHATSAPP_AUTOMATION", "KUE_JOB"].includes(chat.created_by) ? "Picu Bot" : ( chat.User && chat.User.first_name )}
											</em>
										}
									</div>
								</Col>
							</Row>
							
						)}
						<div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }}></div>
					</article>
					<footer style={{flex: 1, position: "sticky", bottom: 0}}>
					{ !this.state.isAllowCustomMessage ? 
						<Row noGutters className="bg-light">
							<Col className="text-center">
								<Row className="mx-3 align-items-center">
									<Col>
										<span className="my-2 text-secondary" style={{fontSize: 12}}>Send Message from specified content set</span>
									</Col>
									<Col xs="auto mt-2 pr-3">
										<div style={{fontSize: 10}}>
											<span>English</span>
											<Switch size="small" color="primary" checked={selectedLanguage === "Hindi"} onChange={(e) => this.setState({ selectedLanguage: e.target.checked ? "Hindi" : "English"})} />
											<span>Hindi</span>
										</div>
									</Col>
									{this.state.isAllowCustomMessage === 0 && <Col xs={"auto"}>
										<Icon fontSize="small" className="mt-2" style={{cursor: "pointer"}} onClick={() => this.setState({isAllowCustomMessage: true})}>close</Icon>
									</Col>}
								</Row>
								<div style={{maxHeight: 250, overflowY: "scroll"}}>
								{WATemplates[selectedLanguage].map((text, i) => 
									<span onClick={() => this.addConversation(text.includes("%s") ? utilFormat(text, this.props.jobTitle) : text)} className="d-block m-2 p-1 bg-light border" style={{fontSize: 12, fontWeight: "bold", borderRadius: 15, cursor: "pointer"}} key={i}>
										{text.includes("%s") ? utilFormat(text, this.props.jobTitle) : text} 
									</span>
								)}		
								</div>						
							</Col>
						</Row>
						:
						<>
							
							<Row noGutters className="p-3 bg-light align-items-center">
								{file && <Col xs={12} className="text-center mb-2">
								 <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#ddd", cursor: "pointer"}} onClick={() => this.fileChangedHandler(false)}>
                                    <Icon fontSize='small'>close</Icon>
                                </span>
									{
										file.type.includes("image") ? <img width={240} alt="WA_Media" src={URL.createObjectURL(file)} /> :
										file.type.includes("video") ? <video width={240} controls src={URL.createObjectURL(file)} /> :
										<a target="_blank" href={URL.createObjectURL(file)}>File Attached, {file.name}</a>
									}
								</Col>}
								<Col xs={"auto"}><Icon onClick={() => this.setState({isAllowCustomMessage: 0})} className="mr-2" fontSize="small" style={{cursor: "pointer"}}>drafts</Icon></Col>
								<Col>
									<RedTextField
										placeholder='Enter message here...'
										
										InputProps={{
											disabled: file && !file.type.includes("image") ? true : false ,
											style: {borderRadius: 25, padding: 10, borderColor: "red"},
											endAdornment: <>
												<input accept="image/png, image/jpg, image/jpeg, video/mp4, application/pdf" id="icon-button-photo" style={{display: "none"}} onChange={this.fileChangedHandler} type="file"/>
											 	<Icon onClick={() => document.getElementById('icon-button-photo').click()} style={{cursor: "pointer"}}>attach_file</Icon>
											</>
										}}
										className="w-100" variant="outlined" multiline
										value={this.state.message}
										onChange={(e) => this.setState({message: e.target.value})}
										
									/>
								</Col>
								<Col xs={"auto"} className="text-right px-3">
									<Icon style={{cursor: "pointer"}} onClick={() => this.addConversation()}>send</Icon>
								</Col>
							</Row>
						</>
					}
					</footer>
				</div>
			</Drawer>
		)
	}
}

export default WhatsappChat;

const RedTextField = styled(TextField)({
	'& .MuiOutlinedInput-root': {
	  '&.Mui-focused fieldset': {
		borderColor: '#666'
	  },
	},
  });
  
const chatBubbleStyle = {
	backgroundColor: "#dbffdb",
	margin: "5px 15px",
    borderRadius: 7,
    padding: "5px 15px",
	boxShadow: "0 0.125rem 0.25rem rgb(0 0 0 / 20%)",
	fontSize: 14,
	whiteSpace: "pre-wrap"
}
const senderChatStyle= {
	float: "right"
}
const receiverChatStyle= {
	float: "left"
}


var WATemplates = {
	English: [
		"Sorry for the delay in replying back. How can we help you with %s project?",
		"Do you need more help with %s project? Please let us know",
		"Hi, Where are you stuck with %s project? Please tell us",
		"We tried reaching out to you for %s but could not connect. Please let us know when to call.",
		"Your training is still pending for %s project. Please complete it and start earning",
		"Your interview slot has been rescheduled for %s project. Please open PickMyWork app and book a new interview slot.",
		// "Hope our team has addressed your query for %s project. Please let us know your feedback.",
		// "Thank you! Please rate this experience with PickMyWork by responding with a number from 1 (worst experience) to 5 (a great experience)"
	],
	Hindi: [
		"जवाब देने में देरी के लिए खेद है। हम %s प्रोजेक्ट में आपकी कैसे मदद कर सकते हैं?",
		"क्या आपको %s प्रोजेक्ट के लिए और सहायता चाहिए? कृपया हमें बताएं",
		"नमस्ते, आप %s प्रोजेक्ट में कहां फंस गए हैं? कृपया हमें बताएं",
		"हमने %s के लिए आपसे संपर्क करने का प्रयास किया, लेकिन कनेक्ट नहीं हो सके। कृपया हमें बताएं कि कब कॉल करना है।",
		"%s प्रोजेक्ट के लिए आपकी ट्रेनिंग अभी भी बची हुई है। कृपया इसे पूरा करें और कमाई शुरू करें",
		"आपका इंटरव्यू स्लॉट %s प्रोजेक्ट के लिए पुनर्निर्धारित किया गया है। कृपया PickMyWork ऐप खोलें और एक नया इंटरव्यू स्लॉट बुक करें।",
		// "आशा है कि हमारी टीम ने %s प्रोजेक्ट के लिए आपके प्रश्न का समाधान कर दिया है। कृपया हमें अपनी प्रतिक्रिया बताएं।",
		// "आपको धन्यवाद! कृपया 1 (सबसे खराब अनुभव) से लेकर 5 (एक बेहतरीन अनुभव) तक की संख्या के साथ जवाब देकर PickMyWork के साथ इस अनुभव का मूल्यांकन करें।"
	]
	
}
