import { Switch } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Collapse from "reactstrap/lib/Collapse";
import { io } from "socket.io-client";
import { setError, setLoader } from "../../actions/action-creators";
import Constants from "../../constants";
import ApiEndpoints from "../../constants/api_endpoints";
import store from "../../store";
import OutboundCallingStatus from "./outbound-calling-status";
var socket = null;

class SideMenu extends Component {
    constructor(props) {
      super(props);
      this.state = {
          opsAssociateRole: false,
          callDetails: false,
          callDetailsWaiting: false,
          isInboundCaller: false,
          inboundCallerStatus: false,
          isAutoDialerCaller: false,
          autoDialerStatus: false,
          calling_team: 0
        }
      }

    async componentDidMount(){
      var opsAssociateRole = store.getState().user.UserRole.filter(role => role.role_id === 10)[0];
      this.setState({opsAssociateRole : opsAssociateRole});

      if(opsAssociateRole){
        const SERVER = process.env.REACT_APP_BASE_URL;
        socket = io (SERVER, { transports: ['websocket']});
        socket.emit("join_auto_dialer", {id: opsAssociateRole.user_id});
        socket.on('CALL_CONNECTED', (resp) => {
          if(resp.from_user_id === opsAssociateRole.user_id) this.showAutoDialerBox(resp);
        });

        socket.on('error', err => {
          console.log("Socket Error, Reconnecting....: ", err);
          socket.emit("join_auto_dialer", {id: opsAssociateRole.user_id});
        });
        socket.on('disconnect', () => {
          console.log("Socket Disconnect, Reconnecting...");
          socket.emit("join_auto_dialer", {id: opsAssociateRole.user_id});
        })
        socket.on('connect', () => console.log("Connected"))
      }
      await this.fetchCallerStatus()
    }

    fetchCallerStatus = async () => {
      try {
        const response = await ApiEndpoints.Calling.fetchInboundStatus({
          caller_user_id: store.getState().user.user_id,
        });
        const { calling_team, status } = response.data.data;
        this.setState({ calling_team: response.data.data.calling_team });
        if (calling_team === 1) {
          this.setState({ isInboundCaller: true });
          const currentStatus = status === "1" ? true : false;
          this.setState({ inboundCallerStatus: currentStatus });
        }
        if (calling_team === 2) {
          this.setState({ isAutoDialerCaller: true });
          const currentStatus = status === "2" ? true : false;
          this.setState({ autoDialerStatus: currentStatus });
        }
        if (calling_team === 3) {
          this.setState({
            isAutoDialerCaller: true,
            isInboundCaller: true,
          });
          const isOnline = status === "0" ? false : true;
          if (isOnline) {
            const currentStatus = status === "2" ? true : false;
            this.setState({
              autoDialerStatus: currentStatus,
              inboundCallerStatus: !currentStatus,
            });
          }
        }
      } catch (error) {
        store.dispatch(
          setError({
            show: true,
            message: error.response
              ? error.response.data.message
              : error.toString(),
          })
        );
      }
    }

    changeCallerStatus = async (toggleValue, toggleType) => {
      store.dispatch(setLoader(true));
      const { calling_team, inboundCallerStatus, autoDialerStatus } =
        this.state;
      try {
        let updateStatus;
        if (toggleType === 2) {
          updateStatus = toggleValue ? "2" : "0";
        }
        if (toggleType === 1) {
          updateStatus = toggleValue ? "1" : "0";
        }
        await ApiEndpoints.Calling.updateInboundStatus({
          status: updateStatus,
          caller_user_id: store.getState().user.user_id,
        });
        if (calling_team === 3) {
          if (toggleType === 1) {
            this.setState({
              inboundCallerStatus: toggleValue,
            });
            if (autoDialerStatus) this.setState({ autoDialerStatus: false });
          } else {
            this.setState({
              autoDialerStatus: toggleValue,
            });
            if (inboundCallerStatus)
              this.setState({ inboundCallerStatus: false });
          }
        } else {
          toggleType === 1
            ? this.setState({ inboundCallerStatus: toggleValue })
            : this.setState({ autoDialerStatus: toggleValue });
        }
      } catch (error) {
        store.dispatch(
          setError({
            show: true,
            message: error.response
              ? error.response.data.message
              : error.toString(),
          })
        );
      }
      store.dispatch(setLoader(false));
    }

    showAutoDialerBox = async (data) => {
      let { callDetails } = this.state;
      //await this.setState({ callDetails: false });
      let callObj = { user: data.User, calling_id: data.Sid, remark: data.reason }
      if(callDetails) this.setState({ callDetailsWaiting: callObj })
      else this.setState({ callDetails: callObj })
    }

    closeAutoDialer = async () => {
      let { callDetailsWaiting } = this.state;
      if(callDetailsWaiting){
        let nextData = {...callDetailsWaiting};
        await this.setState({ callDetails: false, callDetailsWaiting: false })
        setTimeout(() => this.setState({ callDetails: nextData }), 700);
      }
      else this.setState({ callDetails: false });
    }


    UpdateUserAvailability = async (isAvailable) => {
      let { opsAssociateRole } = this.state;
      var body = { role_id: 10, is_available : isAvailable}
      opsAssociateRole.is_available = isAvailable;
      this.setState({ opsAssociateRole });
      await ApiEndpoints.User.UpdateUserAvailability(body)
    }

    render() {
      let user = store.getState().user;
      var userRoles = user.UserRole;
      userRoles = userRoles.map(obj => obj.role_id);
      var activeMenu=this.props.location.pathname;
      const {
        isInboundCaller,
        inboundCallerStatus,
        isAutoDialerCaller,
        autoDialerStatus,
      } = this.state;
        return(
            <div>
              <h6 className="ml-3" style={{zIndex: 99999, position: "relative"}}>
                Hi, {user.first_name} 
              </h6>
              {
                isAutoDialerCaller ? (
                  <div
                    style={{
                      marginLeft: 15,
                      display: "flex", 
                      alignItems: "center",
                      fontSize: 14
                    }}
                  >
                    <span>Auto-dialer</span>
                    <div style={{zIndex: 999999}}>
                      <Switch
                        onChange={(e) => this.changeCallerStatus(e.target.checked, 2)}
                        checked={autoDialerStatus}
                        size="small"
                      />
                    </div>
                  </ div> 
                ) : null
              }
              {
                isInboundCaller ? (
                  <div
                    style={{
                      marginLeft: 15,
                      display: "flex", 
                      alignItems: "center",
                      fontSize: 14
                    }}
                  >
                    <span>Inbound Calls</span>
                    <div style={{zIndex: 999999}}>
                      <Switch
                        onChange={(e) => this.changeCallerStatus(e.target.checked, 1)}
                        checked={inboundCallerStatus}
                        size="small"
                      />
                    </div>
                  </div>
                ) : null
              }
              <hr/>
              <ul style={{listStyleType : "none", fontSize: 16, paddingBottom: 50}} className="px-3">
              {
                Constants.NAV_ITEMS.map((item, i) => {
                  let isAllowed = userRoles.filter(value => item.requiredRole.indexOf(value) >= 0 );
                  if(!isAllowed.length) return true;
                  let navContent =  <span className="d-flex w-100"><Icon className="mr-3">{item.icon}</Icon> {item.label}</span>;
                  let navColor = item.activeRoutes && item.activeRoutes.indexOf(activeMenu) >= 0 ? "text-danger" : "text-dark";
                  return  <li key={i} className="mt-4">
                            {
                            item.link ? 
                              <Link id={item.id || `tab_${i}`} className={"text-decoration-none "+navColor} to={item.link}>{navContent}</Link>
                              : navContent
                            }
                            {item.subMenu && navColor === "text-danger" &&
                              <Collapse isOpen={true}>
                              <ul>
                                {item.subMenu.map((subItem, i) => {
                                  if(!userRoles.filter(value => subItem.requiredRole.indexOf(value) >= 0 ).length) return true;
                                  let navColor = subItem.activeRoutes && subItem.activeRoutes.indexOf(activeMenu) >= 0 ? "text-danger" : "text-dark";
                                  return <li key={i} className="mt-4 list-unstyled">
                                    {
                                    item.link ? 
                                      <Link id={subItem.id || `tab_${i}`} className={"text-decoration-none "+navColor} to={subItem.link}><span className="d-flex w-100" style={{fontSize: 14}}>{subItem.label}</span></Link>
                                      : navContent
                                    }
                                    </li>
                                })}
                              </ul>
                              </Collapse>
                            }
                          </li>
                })
              }
              </ul>
              <div style={{fontSize: 10, position: "absolute", bottom: 10, left: 0}} className="text-center text-muted mt-2 py-2 bg-light">
                <Link className={"text-decoration-none"} style={{color: "#666"}} to="/send-feedback">
                  <strong className="d-block" style={{fontSize: 12, cursor: "pointer"}}>
                    <Icon style={{fontSize: 14}} className="align-middle">feedback</Icon> Send Feedback or Suggestion
                  </strong>
                </Link>
                <em>Copyright 2023 PickMyWork. All rights reserved.</em>
              </div>


              {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.closeAutoDialer()} isAutoDialer={true} remark={this.state.callDetails.remark} />}

          </div>
        );
     }
  }
  export default withRouter(props => <SideMenu {...props} />);


