import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Icon, TablePagination } from "@material-ui/core";
import { addUser } from "../../../actions";
import { Link } from "react-router-dom";
import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import moment from "moment";
import MaterialInputAdd from "./material_input_add";
import MaterialInputView from "./material_input_view";
import MaterialIssue from "./material_issue";
import ManageMaterial from "../store/manage_material";
import ManageStore from "./manage_store";
import ConfirmAction from "../../global/confirm-action";
import ManageMaterialDownloads from "./manage_downloads";


class StoreManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedJobId : null,
            selectedJob : null,
            jobList : [] ,
            selectedJobLocationId : null,
            applicantList : [] ,
            isOpenIssueModal : false,
            isOpenStoreInputModal : false,
            isOpenStoreInputView : false,
            isOpenCountModal : false ,
            searchText : "",
            userLastCount : 0 ,
           isEditMaterialCount : false,
           materialTransaction : [],
           materialList : [] ,
           addInput : {} ,
           rowsPerPage : 20,
           currentUserPage : 0,
           isOpenMaterialModal : false,
           isOpenManageStoreModal : false,
            stores : [],
            selectedStoreId : null,
            isOpenConfirm : false,

        }
    }

    componentDidMount(){
        this.getJob();
        // this.getApplicants();
    }

    getJob = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetch();
            await this.setState({ jobList : apiData.data.data})
            if(this.state.jobList.length){
                this.setJobId(this.state.jobList[0].job_id)
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    setJobId = async (job_id) => {
        let { jobList } = this.state;
        store.dispatch(setLoader(true))
        var selectedJob = jobList.filter(obj => obj.job_id == job_id)[0];
        if( !selectedJob.JobLocations.length ){
            store.dispatch(setError({show: true, message: "No Locations found for this job"}))
        }
        await this.setState({selectedJob, selectedJobId : job_id, selectedJobLocationId : selectedJob.JobLocations[0].job_location_id})
        await this.fetchStores();
        this.getApplicants();
        store.dispatch(setLoader(false))
    }
    fetchStores = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
            job_id :this.state.selectedJobId ,
            job_location_id : this.state.selectedJob.JobLocations.map(loc => loc.job_location_id)
        }
          let resp = await ApiEndpoints.StoreManager.fetchStore(body);
          var stores = resp.data.data;
          stores = stores.map(store => {
            let location = this.state.selectedJob.JobLocations.filter(loc => loc.job_location_id == store.job_location_id)
              store.JobLocation = location[0] || {};
              return store;
          })
          this.setState({stores, selectedStoreId : stores[0] ? stores[0].store_id : null})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    setStoreId = async (e) => {
        await this.setState({selectedStoreId : e.target.value})
        this.getApplicants();
    }

    getApplicants = async () => {
        let {stores, selectedStoreId} = this.state;
        if(selectedStoreId){
            var body = {
                job_id : this.state.selectedJobId
            }
            
            body.job_location_id = stores.filter(obj => obj.store_id == selectedStoreId)[0].job_location_id
            
            store.dispatch(setLoader(true))
            try{
                let apiData = await ApiEndpoints.StoreManager.fetchAllUser(body);
                let applicantList = apiData.data.data;
                applicantList = applicantList.map(applicant => {
                    applicant.emp_id = applicant.user_id.includes("ext_") ? applicant.id : "PMW"+applicant.id;
                    return applicant;
                })
                this.setState({ applicantList})
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false))
        }
    }

    getAllTransaction = async () => {
        store.dispatch(setLoader(true))
        try{
            await this.fetchMaterial();
            var body = {
                job_id : this.state.selectedJobId,
                user_id : this.state.isOpenCountModal,
            }
            let resp = await ApiEndpoints.StoreManager.fetchAllTransaction(body);
            this.setState({ materialTransaction : resp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    editMaterialCount = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        let {isEditMaterialCount} = this.state;
        try{
            // var body = {
            //     material_id : this.state.isEditMaterialCount.material_id , 
            //     count : e.target.count.value
            // }
            // let resp = await ApiEndpoints.StoreManager.edit(body);
            await Promise.all(isEditMaterialCount.material_info.map(obj => ApiEndpoints.StoreManager.editIssuedMaterial({material_information_id : obj.material_information_id, value : obj.value})))
            this.setState({isEditMaterialCount : false, isOpenCountModal : false})
            this.getApplicants();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    onChangeMaterialEditCount = (i, value) => {
        let {isEditMaterialCount} = this.state;
        isEditMaterialCount.material_info[i].value = value;
        this.setState({isEditMaterialCount});
    }
    fetchMaterial = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           job_id : this.state.selectedJobId ,
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialItem(body );
          this.setState({materialList : resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deletedUsers = async (user_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id: this.state.selectedJob.job_id,
                user_id,
                store_id: this.state.selectedStoreId
            }
            await ApiEndpoints.StoreManager.inactiveUser(body);
            await this.getApplicants();
            store.dispatch(setSuccess({show: true, message: "User deleted successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { jobList , selectedJob , isOpenIssueModal, isOpenManageStoreModal,isOpenStoreInputModal, isOpenStoreInputView , materialList  ,applicantList , userLastCount, isOpenCountModal ,searchText, materialTransaction, isEditMaterialCount ,isOpenMaterialModal, stores, selectedStoreId } = this.state;
        let locationList = selectedJob ? selectedJob.JobLocations : [];
        var userRole = store.getState().user.UserRole
        let filteredApplicant = [];
        if(searchText.trim()){
            filteredApplicant = applicantList.filter(app => {
                return app.emp_id.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 
                ||
                (app.first_name || "").toLowerCase().indexOf(searchText.toLowerCase()) !== -1
            });
        }
        else filteredApplicant = applicantList;
        let materialById = materialList.reduce((prev, curr) => {
            prev[curr.material_description_id] = curr;
            return prev;
        }, {});
        filteredApplicant = filteredApplicant.slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage);
        return (
            <Aux>
                <Row className=" text-align-center justify-content-between">
                    <Col md={7} xs={12}>
                        <Row>
                            <Col>
                            <SelectList value={this.state.selectedJobId} onChange ={(e) => this.setJobId(e.target.value)} >
                                { 
                                jobList.map(job => 
                                <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title} {job.JobLocations.length ? " ( " : ""} {job.JobLocations.map(obj => obj.city).join( ", ")}  {job.JobLocations.length ? " ) " : ""} </option>
                                )
                                }
                                </SelectList>
                            </Col>
                            <Col>
                            <SelectList placeholder="Select Store*" value={selectedStoreId} required onChange={this.setStoreId} >
                                               
                                {stores.map((details) =>
                                <option value={details.store_id}>{details.JobLocation.city}</option>
                                )}
                            </SelectList>
                            </Col>
                        </Row>
                    </Col>

                    <Col md={4} xs={12} className="mt-3 text-right">
                    {userRole.filter(role => role.role_id === 1 || role.role_id === 3 ).length ?
                        <Button size="sm" onClick={() => this.setState({isOpenManageStoreModal : true})} type="button" className="buttonred mr-2"  >Manage Store</Button>
                  : null  }
                        <Button disabled={!this.state.selectedStoreId} size="sm" onClick={() => this.setState({isOpenStoreInputModal : true})} type="button" className="buttonred"  >Store Input</Button>
                        <Button disabled={!this.state.selectedStoreId} size="sm" color="link" onClick={() => this.setState({isOpenStoreInputView : true})} type="button" className="text-dark pl-1"><Icon style={{fontSize: 18}}>visibility</Icon></Button>
                        {!userRole.filter(role => role.role_id === 1 || role.role_id === 3 ).length ? <ManageMaterialDownloads store={this.state.stores.filter(store => store.store_id == this.state.selectedStoreId)[0]} job={selectedJob} />: null}
                       {userRole.filter(role => role.role_id === 1 || role.role_id === 3 ).length ? <Button disabled={!this.state.selectedStoreId} size="sm" onClick={()=> {this.setState({isOpenMaterialModal : true})}} type="button"  className="buttonred ml-3">Material</Button> : null  }
                    </Col>
                </Row>
                {isOpenStoreInputView && <MaterialInputView store_id={this.state.selectedStoreId} selectedJob={selectedJob} applicantList={applicantList} toggle={() => this.setState({isOpenStoreInputView : false})} />}
                {isOpenStoreInputModal && <MaterialInputAdd store_id={this.state.selectedStoreId} selectedJob={selectedJob} applicantList={applicantList} toggle={() => this.setState({isOpenStoreInputModal : false})} />}

                <Modal size={"lg"} isOpen={isOpenMaterialModal} toggle={()=> {this.setState({isOpenMaterialModal : false})}} >
                    <ModalBody>
                    {isOpenMaterialModal && <ManageMaterial job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={()=> {this.setState({isOpenMaterialModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Modal size={"lg"} isOpen={isOpenManageStoreModal} toggle={()=> {this.setState({isOpenManageStoreModal : false})}} >
                    <ModalBody>
                    {isOpenManageStoreModal && <ManageStore job={selectedJob} onClose={() => {this.setSelectedJob(selectedJob.job_id); }}  />}
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={()=> {this.setState({isOpenManageStoreModal : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Row className="justify-content-end mt-2">
                    <Col md={3} xs={10} >
                        <InputFeild  icon="search" onChange={ (e) => this.setState({searchText: e.target.value}) }  placeholder="Search by employee id/Name" />
                    </Col>
                </Row>  
               
                <Table hover responsive className="mt-1 text-center border-bottom">
                    <thead>
                        <tr>
                        <th  style={{width : "20px"}} >#</th>
                        <th style={{minWidth : "150px"}}>Name</th>
                        <th style={{minWidth : "150px"}}>Employee Id</th>
                        <th style={{minWidth : "150px"}}>Issued Date</th>
                        {/* <th>Issued Count</th> */}
                        <th style={{minWidth : "100px"}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredApplicant.map((obj, i) =>
                        <tr>
                            <th scope="row">{i+1+(this.state.rowsPerPage*this.state.currentUserPage)}</th>
                            <td>{obj.first_name}</td>
                            <td>{obj.emp_id}</td>
                            {/* <td>{obj.last_material_data.last_date}</td> */}
                            <td>{obj.last_material_data.created_on ? moment(obj.last_material_data.created_on).utcOffset("+05:30").format(" DD MMM YYYY , h:mm a ") : "-"}</td>
                            {/* <td>{obj.last_material_data.count}
                                <Icon onClick={()=> {this.setState({isOpenCountModal : obj.user_id})}} style={{cursor: "pointer" , fontSize : 15}} className="ml-3">visibility</Icon>
                            </td> */}
                            <td><Button onClick={()=> {this.setState({isOpenIssueModal : {user_id : obj.user_id, last_issued_date : obj.last_material_data.created_on}})}} type="button" className="buttonred" size="xs" style={{ height: "27px", paddingTop: "3px"}}>Issue</Button></td>
                            {userRole.filter(role => role.role_id === 1 || role.role_id === 3 ).length ?
                                <td><Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedUsers(obj.user_id)}) } style={{cursor: "pointer"}} fontSize="small" className="float-right ml-3">delete</Icon></td>
                            : null}
                        </tr>
                        )}
                    </tbody>
                </Table>
                <TablePagination classes="p-0" labelRowsPerPage="Per Page"  rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={applicantList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                        onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                        onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                    />
                {!!isOpenIssueModal && <MaterialIssue store_id={this.state.selectedStoreId} job={this.state.selectedJob} user_id={isOpenIssueModal.user_id} last_issued_date={isOpenIssueModal.last_issued_date} job_id={this.state.selectedJobId} toggle={() => {this.setState({isOpenIssueModal : false})}} refresh={this.getApplicants} /> }

                <Modal onOpened={this.getAllTransaction} isOpen={isOpenCountModal} toggle={()=> {this.setState({isOpenCountModal : false})}} className="modal-dialog-centered">
                    <ModalHeader toggle={()=> {this.setState({isOpenCountModal : false})}}>Issue material to the user</ModalHeader>
                        <ModalBody className="p-3">
                    <form onSubmit={this.editMaterialCount}>

                        <Table hover className="mt-4 text-center border-bottom">
                            <thead>
                                <tr >
                                    <th>S.No</th>
                                    <th >Material Count</th>
                                    <th>Issued Date</th>
                                </tr>
                            </thead>
                            <tbody>
                           

                            {materialTransaction.map((item, i) =>
                            {  
                            
                            return isEditMaterialCount.material_id === item.material_id ? 
                                        <tr>
                                            <td>{i+1}</td>
                                            <td>
                                             { isEditMaterialCount.material_info.map((obj, i) =>
                                                <Row>
                                                    <Col>{materialById[obj.material_description_id].item_name}</Col>
                                                    <Col><InputFeild onChange={(e)=> this.onChangeMaterialEditCount(i, e.target.value)} value={obj.value} /></Col>
                                                </Row>
                                             )}
                                            </td>
                                            <td>
                                                <Button size="sm" onClick={this.editMaterialCount} color="dark">Save</Button>
                                                <Button type="button" size="sm" color=""  onClick={() => this.setState({isEditMaterialCount : false})}>Cancel</Button>
                                            </td>
                                        </tr>
                                :
                            
                           
                                    <tr>
                                    <th scope="row">{i+1}</th>
                                    <td>{item.material_info.map(obj => obj.value+" "+ materialById[obj.material_description_id].item_name).join(", ")}</td>
                                    <td>{moment(item.created_on).utcOffset("+05:30").format(" DD MMM YYYY , h:mm a ")}
                                        {materialTransaction.length-1 === i && <Icon className="cursor-pointer float-right" onClick={() => this.setState({isEditMaterialCount : item})} style={{cursor: "pointer" , fontSize : 15}}>edit</Icon>}
                                    </td>
                                    
                                    </tr>
                            

                                })}  
                             </tbody>
                            </Table>
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button className="buttonred" size="xs" style={{ height: "27px", paddingTop: "3px"}}>Save</Button>{' '}
                        </ModalFooter>
                </Modal>
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />

            </Aux>
            
            
        );
    }
}
export default StoreManager;
