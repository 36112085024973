import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import {Link} from "react-router-dom";
import Icon from '@material-ui/core/Icon';
import { Button, ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import {uploadFile} from "../../../actions"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess } from "../../../actions/action-creators";
import RichTextEditor  from '../../global/form/Richtext';
import InputFeild from "../../global/form/input"

class NewGigWorkers extends Component {
    constructor(props) {
      super(props);
      this.state = {
       var : "hello",
        }
    }

       render(){
           return(
               
            <div>
                <Row className="">
                    <span className="mt-2 pl-4 " style={{fontSize: "22px"}}>All the Gig Workers</span>
                </Row>

                <Row className="mt-2">
                   <Col xs={3}>
                     <Button type="button" className="buttonred ml-4 ">Recruit New</Button>
                   </Col>
                </Row>

                <Row>
                    <Col></Col>
                </Row>
            </div>

           );
       }
    }
export default NewGigWorkers;