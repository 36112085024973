import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CustomerBasicDetails from "./basic_details";
import CustomerProjects from "./customer_projects";
import CustomerCallDetails from "./call_details";
import { TrackEvent } from "../../../actions/ga"



class CustomerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerDetail: {},
            selectedProfileTab : 0,
        }
    }

    componentDidMount(){
        TrackEvent("CUSTOMER_PROFILE", "CUSTOMER_PROFILE_DATA_VIEW", "CUSTOMER_PROFILE_DATA");
        this.profileDetail();

    }

    profileDetail = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { customer_lead_id : this.props.customer_lead_id }

            let customerResp = await ApiEndpoints.Customer.customerDetails(body)
            let customerDetail = customerResp.data.data[0];
            if(!customerDetail) throw new Error("No found customer details");
            
            this.setState({ customerDetail })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

     

    
    switchTab = (value) => {
        this.setState({ selectedProfileTab: value, isShowProjectDetails: false })
    }


     
    render() {
        let { selectedProfileTab, customerDetail } = this.state;
        return (
            <>

                <Modal size="lg" style={{maxWidth: 600}} isOpen={true} toggle={()=> this.props.toggle()} >
                    <ModalHeader toggle={()=> this.props.toggle()} ><h5>Customer Details</h5></ModalHeader>
                    <ModalBody className="pt-0">
                        <Row>
                            <Col xs={3} className="bg-light">
                                {
                                    MENU.map((item, i) => 
                                        <div className="my-4">
                                            <h6 style={{cursor : "pointer", fontSize: 14}} className={"pb-1 "+(selectedProfileTab === i && "border-bottom border-danger")} onClick={() => this.switchTab(i)}>{item}</h6>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col xs={9}>
                                { selectedProfileTab === 0 && <CustomerBasicDetails customer_lead_id={this.props.customer_lead_id} customerDetail={customerDetail}  /> }
                              
                                { selectedProfileTab === 1 && <CustomerProjects customer_lead_id={this.props.customer_lead_id}  /> }

                                { selectedProfileTab === 2 && <CustomerCallDetails customer_lead_id={this.props.customer_lead_id} /> }
                            </Col> 
                        </Row>
                    </ModalBody>
                </Modal>
            </>
            
            
        );
    }
}
export default CustomerProfile;


const MENU = ["Customer Details", "Projects",  "Call Data"];
