
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Modal, ModalBody, ModalFooter, ModalHeader, Table, Button, Collapse} from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import { Badge, Checkbox, Chip, Icon, Switch, TablePagination } from "@material-ui/core";
import moment from "moment";
import PartnerDetail from "../user_profile";
import OutboundCallingStatus from "../../global/outbound-calling-status";
import WhatsappChat from "../../global/whatsapp_chat";
import ConfirmAction from "../../global/confirm-action";
import DatePicker from "react-datepicker";
import SelectList from "../../global/form/select";
import UserCard from "../user_profile/user_card";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"
import CONSTANTS from "../../../constants";

class PortfolioUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
            userList: [],
            userCount: 0,
            currentUserPage : 0,
            rowsPerPage : 20,
            searchText: "",
            isOpenProfileModel: false,
            callDetails: null,
            whatsappChat: false,
            isOpenNotificationModal: false,
            callData: [],
            selectedUserIds: [],
            isShowOnlyPendingAction: false,
            selectedDateRange: ["ALL"],
            isSelectCustomDate: false,
            taskCounts : {},
            isSelectedConsumerType: [this.props.portfolioType > 1 ? 2 : 1],
            isSelectedJobCategory: this.props.portfolioType > 1 ? [2, 4, 9] : [1], 
            isSelectedJob:  this.props.portfolioType > 1 ? [] : this.props.portfolioType === 1 ? [7, 57, 382] : [201],
            categoryList:[],
            appliedFilter: false,
            isLoadingCounts: false,
            isCountValuesFilter: false,
            isCountValuesSort: false
        };
    }


    componentDidMount(){
        this.fetchCategories();
        this.fetchUsers(this.props.portfolioId);
        this.selectDateRange("MONTH")
    }



    componentDidUpdate(prevProps){
        if(prevProps.portfolioId !== this.props.portfolioId) this.fetchUsers(this.props.portfolioId);
    }



    fetchUsers = async(portfolioId, page = 0) => {
        store.dispatch(setLoader(true));
        let {selectedDateRange, appliedFilter} = this.state;
        try{
            let limit = this.state.rowsPerPage;
            let offset = this.state.currentUserPage * this.state.rowsPerPage;
            var body = { portfolio_id: portfolioId };
            if(this.state.isShowOnlyPendingAction) body.onlyPendingAction = true;
            if(this.state.isSelectedJobCategory) body.job_category_id = this.state.isSelectedJobCategory;
            if(this.state.isSelectedJob) body.job_id = this.state.isSelectedJob;
            if(this.state.searchText)  body.search = this.state.searchText.toLowerCase().replace("pmw", "");
            if(appliedFilter) body = {...body, ...appliedFilter}
            else body = {...body, limit, offset}
            if(selectedDateRange.length) body = {...body, start_date: selectedDateRange[1], end_date : selectedDateRange[2]}
            let apiData = await ApiEndpoints.Portfolio.fetchMappedUsers(body);
            let userList = apiData.data.data.rows;
            let userCount = apiData.data.data.count;
            this.setState({ userList, userCount, currentUserPage: page });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchCounts =async (portfolioId) => {
        // store.dispatch(setLoader(true));
        this.setState({ isLoadingCounts: true })
        let {selectedDateRange} = this.state;
        try{
           var body = { portfolio_id: portfolioId };

            if(this.state.isSelectedConsumerType) body.consumer_type = this.state.isSelectedConsumerType;
            if(this.state.isSelectedJobCategory) body.job_category_id = this.state.isSelectedJobCategory;
            if(this.state.isSelectedJob) body.job_id = this.state.isSelectedJob;
            if(selectedDateRange.length) body = {...body, start_date: selectedDateRange[1], end_date : selectedDateRange[2]}
            let resp = await ApiEndpoints.Portfolio.fetchTaskCounts(body);
            await this.setState({taskCounts: resp.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({ isLoadingCounts: false })
        // store.dispatch(setLoader(false))
    }

    selectDateRange = async (type, dateRangeData) => {
        var dateRange = [type];
        if(type === "TODAY") dateRange.push(moment().startOf("day").toDate(), moment().endOf("day").toDate());
        if(type === "YESTERDAY") dateRange.push(moment().subtract(1, "day").startOf("day").toDate(), moment().subtract(1, "day").endOf("day").toDate());
        if(type === "WEEK") dateRange.push(moment().startOf("isoWeek").toDate(), moment().endOf("isoWeek").toDate());
        if(type === "PREV_WEEK") dateRange.push(moment().subtract(7, "days").startOf("isoWeek").toDate(), moment().subtract(7, "days").endOf("isoWeek").toDate());
        if(type === "MONTH") dateRange.push(moment().startOf("month").toDate(), moment().endOf("month").toDate());
        if(type === "PREV_MONTH") dateRange.push(moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate());
        if(type === "CUSTOM") dateRange.push(moment(dateRangeData[0]).startOf("day").toDate(), moment(dateRangeData[1]).endOf("day").toDate());
        await this.setState({selectedDateRange: dateRange, isSelectCustomDate: false});       
        this.fetchCounts(this.props.portfolioId); 
        if(this.state.appliedFilter) this.applyCountFilter(false)
    }

    

    selectConsumerType = async (consumerType) => {
        await this.setState({isSelectedConsumerType: consumerType, isSelectedJobCategory: [], isSelectedJob: []}); 
        this.fetchCounts(this.props.portfolioId);
        this.applyCountFilter(false)
    }
    
    selectJobCategory = async (job_category_id) => {
        await this.setState({isSelectedJobCategory: job_category_id, isSelectedJob: []}); 
        this.fetchCounts(this.props.portfolioId);
        this.applyCountFilter(false)
    }

    selectJob = async (job_id) => {
        var user = store.getState().user;
        let specialAccessUserIds = this.props.specialAccessUserIds;
        if(!specialAccessUserIds.includes(user.user_id) && !job_id.length) return;
        await this.setState({isSelectedJob: job_id}); 
        this.fetchCounts(this.props.portfolioId);
        this.applyCountFilter(false)
    }

    applyCountFilter = async (key, value) => {
        let { appliedFilter } = this.state;
        if(!key) appliedFilter = false;
        else appliedFilter = { [key] : value };
        await this.setState({ appliedFilter });
        this.fetchUsers(this.props.portfolioId);
    }

    onChangePendingActionToggle = async (isOn) => {
        await this.setState({isShowOnlyPendingAction: isOn});
        this.fetchUsers(this.props.portfolioId)
    }


    handleNotificationSubmit = async(e) => {
        e.preventDefault();
        var body = {
            title : e.target.title.value,
            message : e.target.message.value,
            user_id : this.state.isOpenNotificationModal,
            notification_type : e.target.notification_type.value,
            origin_type : "GENERAL",
            sms : false
        } 
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.User.sendNotification(body);
            this.setState({isOpenNotificationModal: false});
            store.dispatch(setSuccess({show: true, message: "Sent successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }  
        store.dispatch(setLoader(false))

    }
  

    makePhoneCall = async (user) => {
        store.dispatch(setLoader(true));
          try{
            if(this.state.callDetails){
                store.dispatch(setError({show: true, message: "You are already on another call."}))
                store.dispatch(setLoader(false));
                return;
            }
            var body = {
                user_id : user.user_id,
                entity_type: "PORTFOLIO",
                entity_id: this.state.selectedPortfolioId,
                direction: 2
            }
            let resp = await ApiEndpoints.Calling.connectOutboundCall(body);
            
            this.setState({callDetails: {
                calling_id : resp.data.data.Sid,
                user: user
            }});
          }
          catch(e){
            store.dispatch(setError({show: true, message: typeof e.response.data.message === "string" ? e.response.data.message :  "Not able to connect call"}))
          }
          store.dispatch(setLoader(false));
    }


    showCallData = async (user) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                // status: "completed",
                user_id: user.user_id
            }
            let apiData = await ApiEndpoints.Calling.fetchDetailsByUser(body);
            this.setState({callData : apiData.data.data});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchCategories = async (page = 0) => {
        let jobList = store.getState().jobList;
        try{
            var body = {}
            let resp = await ApiEndpoints.Jobs.fetchCategory(body);

            let categoryList = resp.data.data;

            let jobCategoryConsumerType = jobList.reduce((prev, job) => {
                prev[job.job_category_id] = prev[job.job_category_id] || {};
                prev[job.job_category_id][job.consumer_type] = true;
                return prev;
            }, {});

            categoryList = categoryList.map(cat => {
                let consumerTypes =  jobCategoryConsumerType[cat.job_category_id] || {};
                cat.consumerTypes = consumerTypes[1] && consumerTypes[2] ? null : consumerTypes[1] ? 1 : 2;
                return cat;
            })

            this.setState({categoryList})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    } 

    onSelectUser = (e) => {
        let {selectedUserIds} = this.state;
        if(e.target.checked) selectedUserIds.push(e.target.value);
        else {
            selectedUserIds.splice(selectedUserIds.indexOf(e.target.value), 1)
        }
        this.setState({selectedUserIds})
    }

    removePortfolioUsers = async() => {
        store.dispatch(setLoader(true));
        let { selectedUserIds } = this.state;
        let { portfolioId }= this.props;
        try{
            var body = { portfolio_id: portfolioId, user_id: selectedUserIds };
            await ApiEndpoints.Portfolio.removePartnerFromPortfolio(body);
            this.setState({  selectedUserIds: [], isOpenPortfolioMapping: false })
            this.fetchUsers(this.props.portfolioId);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    toggleCountValuesFilter = async () => {
        let { isCountValuesFilter } = this.state;
        if(isCountValuesFilter)  await this.setState({isCountValuesFilter: false});
        else this.setState({ isCountValuesFilter: {} })
      }
   
  render() {
    let {  userList, selectedUserIds,selectedDateRange,isSelectCustomDate, taskCounts, appliedFilter, isLoadingCounts, isCountValuesFilter, isCountValuesSort} = this.state;
    let { portfolioId, specialAccessUserIds = [] } = this.props;
    var user = store.getState().user;
    let jobList = store.getState().jobList;
    if(!specialAccessUserIds.includes(user.user_id)){
        let filteredJobs = this.props.portfolioType > 1 ? [] : this.props.portfolioType === 1 ? [7, 57, 382] : [201];
        jobList = jobList.filter(job => !filteredJobs.length || filteredJobs.includes(job.job_id));
    }
    var userRole = store.getState().user.UserRole
    var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;
    if(isCountValuesFilter && isCountValuesFilter.filter_value){
        userList = userList.filter(user => isCountValuesFilter.comparison_operator === '<' ? (user.task_count || user.earning) <= isCountValuesFilter.filter_value : (user.task_count || user.earning) >= isCountValuesFilter.filter_value)
    }
    if(isCountValuesSort){
        userList = userList.sort((a, b) => isCountValuesSort === "ASC" ? (a.task_count || a.earning) - (b.task_count || b.earning) :  (b.task_count || b.earning) - (a.task_count || a.earning))
    }
    return (<>
         
        
        <Row noGutters className="justify-content-end align-items-center text-center mt-3">

            <Col xs={4} md={1} className="mr-2">
                <SelectList disabled={!specialAccessUserIds.includes(user.user_id)} multiple="checkbox" search={true} placeholder="Project Types" value={this.state.isSelectedConsumerType} onChange ={async (e) => this.selectConsumerType(e.target.value) }>
                        <option value={1}>{"B2B"}</option>
                        <option value={2}>{"B2C"}</option>
                </SelectList>
            </Col>
            <Col xs={4} md={2} className="mr-2">
                <SelectList  disabled={!specialAccessUserIds.includes(user.user_id)} multiple="checkbox" search={true} placeholder="Project Categories" value={this.state.isSelectedJobCategory} onChange ={async (e) => this.selectJobCategory(e.target.value) }>
                    { 
                        this.state.categoryList.filter(cat => !(cat.consumerTypes && this.state.isSelectedConsumerType.indexOf(cat.consumerTypes) === -1)).map(category => 
                        <option key={category.job_category_id} value={category.job_category_id}>{category.title}</option>
                    )}
                </SelectList>
            </Col>
            {!!this.state.isSelectedJobCategory &&
            <Col xs={4} md={2} className="mr-2">
                <SelectList  multiple="checkbox" search={true} placeholder="Select Project" value={this.state.isSelectedJob} onChange ={async (e) => this.selectJob(e.target.value) }>
                    { 
                        jobList.filter(job => this.state.isSelectedJobCategory.indexOf(job.job_category_id) !== -1).map(job => 
                        <option key={job.job_id} value={job.job_id}> 
                            {job.Employer.employer_name +  "  -  "  + job.title} 
                            {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                        </option>
                    )}
                </SelectList>
            </Col>
            }
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("ALL")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "ALL" ? {borderColor: "red", color: "red"}:{})}}>All</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("TODAY")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "TODAY" ? {borderColor: "red", color: "red"}:{})}}>Today</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("YESTERDAY")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "YESTERDAY" ? {borderColor: "red", color: "red"}:{})}}>Yesterday</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("WEEK")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "WEEK" ? {borderColor: "red", color: "red"}:{})}}>This Week</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("PREV_WEEK")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "PREV_WEEK" ? {borderColor: "red", color: "red"}:{})}}>Previous Week</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("MONTH")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "MONTH" ? {borderColor: "red", color: "red"}:{})}}>This Month</Button>
            </Col>
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.selectDateRange("PREV_MONTH")} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "PREV_MONTH" ? {borderColor: "red", color: "red"}:{})}}>Previous Month</Button>
            </Col>                                
            <Col xs="auto" className="mr-1">
                <Button onClick={() => this.setState({isSelectCustomDate: {}})} color="secondary" outline size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "CUSTOM" ? {borderColor: "red", color: "red"}:{})}}>Custom Date</Button>
            </Col>
        </Row>
      
        {taskCounts &&  
            <Row style={{justifyContent:"center"}}>   
                <Col  md={2} xs={6} className="mx-2">
                    <Row className="border shadow-sm bg-light py-3 px-2 mt-4 mb-3" style={{color: "#a87932"}}>
                        {appliedFilter.status === 0 && 
                        <span style={{position: "absolute", top: 18, right: 0, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.applyCountFilter(false)}>
                            <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                        </span>}
                        <Col md={8} xs={12} className="p-1 mx-2" style={{fontSize: 12}}><span>Pending Tasks</span></Col>
                        <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.applyCountFilter("status", 0)}><span>{isLoadingCounts ? "-" : taskCounts["PENDING"] || 0}</span></Col>
                    </Row>
                </Col>
                <Col md={2} xs={6} className="mx-2">
                    <Row className="border shadow-sm bg-light py-3 px-2 mt-4 mb-3" style={{color: "#339900"}}>  
                        {appliedFilter.status === 1 && 
                        <span style={{position: "absolute", top: 18, right: 0, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.applyCountFilter(false)}>
                            <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                        </span>}
                        <Col md={8} xs={12} className="p-1 mx-2" style={{fontSize: 12}}><span>Verified Tasks</span></Col>
                        <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.applyCountFilter("status", 1)}><span>{isLoadingCounts ? "-" : taskCounts["VERIFIED"] || 0}</span></Col>
                    </Row>
                </Col>
                <Col md={2} xs={6} className="mx-2">
                    <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3" style={{color: "#cc3300"}}>  
                        {appliedFilter.status === 2 && 
                        <span style={{position: "absolute", top: 18, right: 0, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.applyCountFilter(false)}>
                            <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                        </span>}
                        <Col md={8} xs={12} className="p-1 mx-2" style={{fontSize: 12}}><span>Rejected Tasks</span></Col>
                        <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.applyCountFilter("status", 2)}><span>{isLoadingCounts ? "-" : taskCounts["REJECTED"] || 0}</span></Col>
                    </Row>
                </Col>
                <Col md={2} xs={6} className="mx-2">
                    <Row className="border shadow-sm bg-light py-3 px-2 mt-4 mb-3">    
                        {appliedFilter.earned === 1 && 
                        <span style={{position: "absolute", top: 18, right: 0, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.applyCountFilter(false)}>
                            <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                        </span>}
                        <Col md={8} xs={12} className="p-1 mx-2" style={{fontSize: 12}}><span>Earned Counts</span></Col>
                        <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.applyCountFilter("earned", 1)}><span>{isLoadingCounts ? "-" : taskCounts["EARNED"] || 0}</span></Col>
                    </Row>
                </Col>
                <Col md={2} xs={6} className="mx-2">
                    <Row className="border shadow-sm bg-light py-3 px-2 mt-4 mb-3">    
                        <Col md={8} xs={12} className="p-1 mx-2" style={{fontSize: 12}}><span>Earning</span></Col>
                        <Col md={2} xs={12} className=" p-0 mx-0 text-center"><span>{isLoadingCounts ? "-" : taskCounts["EARNING"] || 0}</span></Col>
                    </Row>
                </Col>
            </Row>
        }
        <Row className={"my-2 pt-2 border-top align-items-end"}>
            <Col xs={12} md={8}>
                <strong className="m-0">Portfolio Partners</strong>
                {!!selectedUserIds.length && <Button size="sm" className="buttonred ml-3"  onClick={() => this.setState({isConfirm: () => this.removePortfolioUsers(), isConfirmMessage : "Are you sure to remove selected partners?"})}>Remove from Portfolio</Button>}
                {/* <Switch size="small" color="secondary" checked={this.state.isShowOnlyPendingAction} onChange={(e) => this.onChangePendingActionToggle(e.target.checked)} /><strong className="m-0 ml-2">Pending Actions</strong> */}
            </Col>  
            <Col>
                <form onSubmit={async (e) => {e.preventDefault(); await this.setState({currentUserPage: 0}); this.fetchUsers(portfolioId)}}>
                    <InputFeild onChange={(e) => this.setState({searchText :e.target.value})} value={this.state.searchText} icon="search" onClickIcon={this.applyFilter} placeholder=" search by Name/PMW-Id" />
                </form>
            </Col>
            {!!appliedFilter &&
            <Col xs="auto">
                <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer", transform: `rotate(${isCountValuesSort === "ASC" ? 0 : 180}deg)`}} onClick={() => this.setState({ isCountValuesSort: isCountValuesSort === "ASC" ?  "DESC" : "ASC" })}>sort</Icon>
                <Icon className="align-middle mr-3 " style={{fontSize: 18, cursor: "pointer"}} onClick={() => this.toggleCountValuesFilter()}>{isCountValuesFilter ? "filter_alt_off" : "filter_alt"}</Icon>
            </Col>
            }
        </Row>
        <Collapse isOpen={!!isCountValuesFilter}>
            <Row className="align-items-center justify-content-end border px-3 py-2" >
                <Col xs={6} lg={2}>
                    <SelectList className="" placeholder="Select Comparison Type" value={isCountValuesFilter.comparison_operator || ""} onChange ={(e) => {isCountValuesFilter.comparison_operator = e.target.value; this.setState({isCountValuesFilter})}} >
                        <option value={">"}>Greater Then</option>
                        <option value={"<"}>Less Then</option>
                    </SelectList>
                </Col>
                <Col xs={6} lg={2}>
                    <InputFeild type="number" placeholder="Enter Value" value={isCountValuesFilter.value || isCountValuesFilter.value} onChange={(e) => { isCountValuesFilter.value = e.target.value; this.setState({isCountValuesFilter}) }} />
                </Col>
                <Col xs={6} lg={2}>
                    <div className="text-right py-3"><Button color="dark" size="sm" onClick={() => {isCountValuesFilter.filter_value = isCountValuesFilter.value; this.setState({isCountValuesFilter})}}>Apply Filter</Button></div>
                </Col>
            </Row>
        </Collapse>
        {
        userList.length ? 
        <>
        {
        userList
        .map((mappedUser, i) =>
             <Row key={i} noGutters className="border shadow-sm py-2 mx-0 my-2 align-items-center" style={{borderRadius: 12}}>
                <Col style={{maxWidth: 25}}>
                    {isAllowManagerActions && <Checkbox color="secondary" size="small" value={mappedUser.user_id} checked={selectedUserIds.indexOf(mappedUser.user_id) !== -1} onChange={this.onSelectUser} />}
                </Col>
                <Col>
                    <Row noGutters className="align-items-center">
                        <Col className="align-items-center">
                            <UserCard data={mappedUser.User} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} xs={12} className="text-center my-1">
                    <span className="mr-1" style={{cursor: "pointer" , color: "#007bff"}} onClick={() => this.showCallData(mappedUser)}>{mappedUser.connected_call_counts || ""}</span> 
                    <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isMissedCall}><Button outline className="px-1 pb-1 pt-0" color="primary"> <Icon className="align-middle mx-1" onClick={() => this.setState({isConfirm: () => this.makePhoneCall(mappedUser.User), isConfirmMessage : "Are you sure to connect with "+ mappedUser.User.first_name+"?"})} style={{fontSize:16, cursor: "pointer"} }>call</Icon></Button></Badge>
                    {/* <Badge sx color="secondary" className="mx-1" variant="dot" invisible={!mappedUser.isPendingWhatsapp}><Button outline className="px-1 pb-1 pt-0" color="success" onClick={() => this.setState({whatsappChat: mappedUser.User})}><img src={WhatsappIcon} alt="" style={{fontSize:16, cursor: "pointer"} }/></Button></Badge> */}
                    <Button outline className="px-1 mr-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.setState({isOpenNotificationModal: mappedUser.user_id})}>notifications_none</Icon></Button>
                    {/* <Button outline className="px-1 pb-1 pt-0" color="secondary"><Icon className="mx-1 align-middle" style={{fontSize:16, cursor: "pointer"}} onClick={() => this.showApplicationComment(applicant)}>comment</Icon></Button> */}
                </Col>
                <Col md={2} xs={12} className="text-center">
                    {!!mappedUser.task_count && <span className="d-block small">Task Count: {mappedUser.task_count}</span>}
                    {!!mappedUser.earning && <span className="d-block small">Earning: {mappedUser.earning}</span>}
                    {
                         !!mappedUser.isPendingWhatsapp &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Whatsapp - {moment(mappedUser.isPendingWhatsapp.created_on).fromNow()} </span>
                    }
                    {
                       !!mappedUser.isMissedCall &&
                        <span className="d-block" style={{fontSize: 12, color: "red"}}><Icon style={{fontSize: 12}}>timelapse</Icon> Call - {moment(mappedUser.isMissedCall.created_on).fromNow()} </span>
                    }
                </Col>
                <Col md={3} xs={12} className="text-center">
                    {
                        !!mappedUser.last_call && <span className="small" style={{fontSize: 10}}><span role="img" aria-label="icon" style={{fontSize: 10}}>&#128222;</span>{mappedUser.last_call.status}-{moment(mappedUser.last_call.call_scheduled_on || mappedUser.last_call.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")} {!!mappedUser.last_call.comment && `(${mappedUser.last_call.comment})`}</span>
                    }
                </Col>

                <Col md={2} xs={12} className="text-right"> 
                  <em style={{fontSize: 10, fontWeight: "bold"}} className="mt-0 px-2 text-muted">{mappedUser.created_on && moment(mappedUser.created_on).utcOffset("+05:30").format("ddd, Do MMM'YY, h:mma")}</em>
                </Col>
            </Row>
            )}
            {!appliedFilter && <TablePagination rowsPerPageOptions={[2, 20, 40, 60, 100, 200]} component="div" count={this.state.userCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                onChangePage={async (e, page) => this.fetchUsers(portfolioId, page) }
                onChangeRowsPerPage={async (e) => {await this.setState({rowsPerPage : e.target.value});  this.fetchUsers(portfolioId)}}
            />}
            </>
            : 
            <Row><Col xs={12} ><p className="text-center text-muted mt-3"> <em></em></p></Col></Row>
            
        }


        <Modal isOpen={this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal: false})}>
            <form onSubmit={this.handleNotificationSubmit}>
                <ModalHeader toggle={() => this.setState({isOpenNotificationModal: false})}> <h5>Notification Content</h5></ModalHeader>
                    <ModalBody>
                        <label className="radio-inline"><input type="radio" className="mr-3" name="notification_type" value="NOTIFICATION" checked />General Notification</label>
                        <label className="radio-inline ml-3"><input className="mr-3" type="radio" name="notification_type" value="ALERT" />Alert</label>
                        <InputFeild required placeholder="Title" name="title" />
                        <textarea required name="message" placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.setState({comments: e.target.value})} />
                        {/* <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" /> Notify with SMS</label> */}
                    </ModalBody>
                    <ModalFooter style={{height: "45px"}}>
                    <Button type="submit" size={"sm"} color="primary" >Send</Button>{' '}
                    <Button type="button" size={"sm"} onClick={() => this.setState({isOpenNotificationModal: false})}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>

        <Modal size={"lg"} centered isOpen={!!this.state.callData.length} toggle={()=> {this.setState({callData : []})}} >
                    <ModalHeader toggle={()=> {this.setState({callData : []})}}>Call Details</ModalHeader>
                    <ModalBody className="p-2">
                    <Table bordered className="table-sm mt-2 mb-0 text-center" style={{fontSize: 14}}>
                                <thead>
                                    <tr>
                                      <th>Manager</th>
                                      <th>Direction</th>
                                      <th>Recording</th>
                                      <th>Comment</th>
                                      <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {this.state.callData.map((call, i) =>
                                    <tr>
                                      <td>{(call.userDetail || {}).first_name}</td>
                                      <td>{call.direction === 1 ? "In" : "Out"}</td>
                                      <td>
                                        {call.call_recording_url ?
                                        <audio preload={true} style={{maxWidth: 185}} controls><source src={call.call_recording_url} type="audio/ogg"  /></audio>
                                        :
                                        <span>{call.status}</span>
                                        }
                                      </td> 
                                      <td><span>{call.comment}</span></td>
                                      <td>
                                        {moment(call.created_on).utcOffset("+05:30").format("hh:mmA Do MMM'YY")}
                                        {/* <em className="d-block" style={{fontSize: 12}}><strong>{CONSTANTS.APPLICATION_STATUS_TEXT[call.subentity_id] || ""}</strong></em> */}
                                      </td>
                                    </tr>
                                  ) 
                                  }
                                </tbody>
                            </Table>  
                    </ModalBody>
            </Modal>

            <Modal isOpen={isSelectCustomDate} toggle={()=> {this.setState({isSelectCustomDate : false})}} >
                        <ModalBody className="p-5">
                            <DatePicker
                                placeholderText="Start Date"
                                selected={isSelectCustomDate.startDate || null}
                                onChange={(date) => {isSelectCustomDate.startDate = date; this.setState({isSelectCustomDate});}}
                                selectsStart
                                startDate={isSelectCustomDate.startDate || null}
                                endDate={isSelectCustomDate.endDate || null}
                            />
                            <DatePicker
                                className="mt-3"
                                placeholderText="End Date"
                                selected={isSelectCustomDate.endDate || null}
                                onChange={(date) => {isSelectCustomDate.endDate = date; this.setState({isSelectCustomDate});}}
                                selectsEnd
                                startDate={isSelectCustomDate.startDate || null}
                                endDate={isSelectCustomDate.endDate || null}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.selectDateRange("CUSTOM", [isSelectCustomDate.startDate, isSelectCustomDate.endDate])} color="danger" >Apply Filter</Button>{' '}
                        </ModalFooter>
                    </Modal>





        {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}
        {!!this.state.whatsappChat && <WhatsappChat jobList={[]} user={this.state.whatsappChat} jobTitle={"PickMyWork"} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />
        {!!this.state.callDetails && <OutboundCallingStatus calledUser={this.state.callDetails.user} calling_id={this.state.callDetails.calling_id} onClose={() => this.setState({callDetails : null})} />}



    </>)
  }
}


export default PortfolioUsers;
