import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { TablePagination} from "@material-ui/core";
import { downloadApiFile } from "../../../actions";
import Aux from "../../global/AuxComp";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";

class ReferralView extends Component {
    constructor(props) {
        super(props);
        this.state = {
           referralList : [],
           rowsPerPage : 20,
           currentUserPage : 0,
           isOpenConfirm: false
        }
    }

    componentDidMount(){
        this.fetchRefferal();
    }

    fetchRefferal = async () => {
        store.dispatch(setLoader(true))
        try{
            let resp = await ApiEndpoints.Referral.fetchReferral();
            var referralList = resp.data.data;
            referralList = referralList.sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
            this.setState({referralList})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    rejectReferral = async (referral) => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                referral_tracking_id: referral.referral_tracking_id
            };
            await ApiEndpoints.Referral.rejectReferral(body);
            await this.fetchRefferal();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    getReferralStatus = (referral) => {
        if(referral.referral_status === 3) return "Rejected";
        if(referral.referral_status === 2) return "Success & Paid";
        else if(referral.task_count >= 10) return "Success";
        else if(referral.task_count > 0) return referral.task_count+ " Task";
        else return "Signed In";
    }

    downloadReferral = async () => {
        store.dispatch(setLoader(true))
        try {
            var resp = await ApiEndpoints.Referral.downloadReferral();
            downloadApiFile(resp, "Referral.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render() {
        let {  } = this.props;
        let { referralList } = this.state;
        return (
            <Aux>

                <Modal size="lg" isOpen={true} toggle={()=> this.props.toggle()}>
                    <ModalHeader toggle={()=> this.props.toggle()}>
                        <Row>
                            <Col xs={3}>Referral</Col>
                            <Col ><Button id="download_referral" onClick={this.downloadReferral} type="button" className="buttonred ml-3 " size="xs" style={{ height: "27px", paddingTop: "3px"}}>Download Referral</Button></Col>
                        </Row>
                    </ModalHeader>
                        <ModalBody className="p-3">
                            <Table striped className="table-sm text-center" style={{fontSize: 12}}>
                                <thead>
                                    <tr>
                                    <th>Referral Code</th>
                                    <th>Referred By</th>
                                    <th>Referral Used By</th>
                                    <th>Updated On</th>
                                    <th>status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {referralList.slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage).map((obj) =>
                                        <tr>
                                            <td>{obj.referral_id}</td>
                                            <td>
                                                <span className="d-block">{obj.referred_by_user.first_name}</span>
                                                <span style={{fontSize : 12 , color : "#7a7c80"}}>{obj.referred_by_user.id && "PMW"+obj.referred_by_user.id}</span>
                                            </td>
                                            <td>
                                                <span className="d-block">{obj.used_by_user.first_name}</span>
                                                <span style={{fontSize : 12 , color : "#7a7c80"}}>{obj.used_by_user.id && "PMW"+obj.used_by_user.id}</span></td>
                                            <td>{moment(obj.modified_on || obj.created_on).utcOffset("+05:30").format("DD-MM-YYYY HH:mm A")}</td>
                                            <td>
                                                <span className="d-block ">{this.getReferralStatus(obj)}</span>
                                                {obj.referral_status === 2 && <Button className="p-0" style={{fontSize: 12}} color="link" onClick={() => this.setState({isOpenConfirm: () => this.rejectReferral(obj)})}>Reject</Button>}
                                            </td>
                                        </tr>
                                )}
                                </tbody>
                            </Table>
                            <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={referralList.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                                onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                                onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {/* <Button className="buttonred" onClick={() => this.submitMaterialInput()} style={{ fontSize: "12px",height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                            <Button type="button" color="secondary" onClick={()=> this.props.toggle()} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button> */}
                        </ModalFooter>
                        <ConfirmAction message={"Are you sure to reject this referral, Credited wallet payment will be inactive ?"} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

                </Modal>
            </Aux>
            
            
        );
    }
}
export default ReferralView;
