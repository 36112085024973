import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../constants/api_endpoints";
import InputFeild from "../global/form/input"
import SelectList from "../global/form/select"
import "../../styles/button.css"
import {Icon} from '@material-ui/core';
import store from "../../store";
import { setError, setSuccess } from "../../actions/action-creators";
import { addUser } from "../../actions"
class Recruiter extends Component {
    constructor(props) {
      super(props);
      this.state = {
            companyList: [],
            selectedCompany : null,
            selectedJob : [],
            recruiterList : [],
            selectedRecruiter : {},
            jobList : [],
            isNew : true,
            user : {},
            jobLocationList : [],
            selectedLocation : []
        }
    }

    async componentDidMount(){
       await this.fetchCompany();
        this.fetchRecruiters();
    }
    fetchCompany = async () =>{
        try{
            let companyResp = await ApiEndpoints.Employer.fetchEmployer();
            this.setState({companyList : companyResp.data.data});
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
    }

    fetchRecruiters = async () =>{
        let { companyList } = this.state;
        try{
            var accessOpts = {
              access_type : "EMPLOYER",
              access_type_id : companyList.map(company => company.employer_id).toString()
            }
            let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
            let recruiterList = accessResp.data.data.filter(access =>  access.UserRole && access.UserRole.role_id === 4);
            this.setState({recruiterList})
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
    }

    onChangeCompany = async (e) => {
        await this.setState({selectedCompany : e.target.value})
        this.fetchJobs();
    } 

    fetchJobs = async () => {
        let { selectedCompany } = this.state;
        try{
            let apiData = await ApiEndpoints.Jobs.select({employer_id : selectedCompany});
            this.setState({ jobList : apiData.data.data })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    onChangeJob = async (e) => {
        await this.setState({selectedJob : e.target.value}); 
        this.fetchJobLocation()
    }
    fetchJobLocation = () => {
        let { selectedJob, jobList } = this.state;
        let job = jobList.filter(job => selectedJob.indexOf(job.job_id) >= 0);
        var jobLocationList = [];
        job.map(obj => {
            obj.JobLocations = obj.JobLocations.map(location => {location.job_title = obj.title; return location;})
            jobLocationList = jobLocationList.concat(obj.JobLocations)
        })
        this.setState({jobLocationList})
    }

    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }
    handleSubmit = async (e) => {
        e.preventDefault();
        e.persist();
        let { selectedJob, selectedLocation, user, selectedCompany } = this.state;
        try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            if(!selectedCompany) throw new Error("Company Required!");
            var body = {...user, role_id : 4, access_type : "EMPLOYER", access_type_id : selectedCompany}
            user = await addUser(body);
            if(selectedJob.length && !selectedLocation.length) throw new Error("Job Location Required!")
            await Promise.all(selectedLocation.map(async job_location_id => {
                var body = {...user, access_type : "JOB_LOCATION", access_type_id : job_location_id}
                return addUser(body);
            }))
            e.target.reset();
            this.setState({selectedLocation : [], selectedJob : [], selectedCompany : "", user : {}})
            this.fetchRecruiters()
            store.dispatch(setSuccess({show: true, message: "Recruiter Added!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    render(){
        let {isNew, companyList, selectedCompany, recruiterList, selectedRecruiter, selectedJob, jobList, jobLocationList, selectedLocation} = this.state;
        return(
            <div className="">
                <Row className=" mb-2 border-bottom pb-3">
                <Button color="link text-dark ml-2" onClick={() =>this.props.history.goBack()}><Icon>arrow_back_ios</Icon></Button>
                    <span className="mt-1 pl-2 " style={{fontSize: "22px"}}>Recruiter</span>
                </Row>
                <Row className="border-bottom align-items-center">
                    <Col xs={6} className="border-right py-3" >
                        <span className="ml-3" style={{fontSize: "18px"}} >All Recruiters</span>
                        {/* <Button type="button" className="buttonred ml-5" style={{width: "85px", paddingLeft : "0px"}}>Add New </Button> */}
                    </Col>             
                    <Col xs={6} className="py-3">
                        <span className="" style={{fontSize: "18px", paddingLeft: "20px"}}>Add Recruiter's Information</span>
                    </Col>
                </Row>

                <Row>
                    
                    <Col xs={6} className="border-right pt-2"> 
                        {recruiterList.map(recruiter => 
                            <Row style={{cursor: "pointer"}} className={"border-bottom m-2 pt-1 align-items-center "+(selectedRecruiter && selectedRecruiter.access_id === recruiter.access_id ? "row-active":"")} onClick={() => this.setState({selectedRecruiter : recruiter})}>
                                <Col xs={2} className="pr-2 pb-1" style={{marginRight: "-20px"}}>
                                    <img className="rounded-circle" style={{width: "100%", marginLeft: "-10px"}} src="/images/dummy_profile.jpg" alt="Profile"></img>
                                </Col>
                                <Col className="pb-2 ">
                                    <p className="mb-0" style={{fontSize: "15px"}}>{recruiter.User.first_name}</p>
                                    {/* <p className="mb-0" style={{fontSize: "12px"}}>Current Project Recruitement : Google Pay</p> */}
                                    <p className="mb-0" style={{fontSize: "12px"}}>Phone Number : {recruiter.User.mobile}</p>
                                </Col>
                            </Row>
                        )}

                    </Col>

                    {/* <Col xs={4} className="border-right"> 
                    </Col> */}

                    <Col xs={6}> 
                        <form className="mt-3" onSubmit={this.handleSubmit}>
                            <SelectList placeholder="Select Company" value={selectedCompany} onChange={this.onChangeCompany}>
                                {
                                    companyList.map(company => <option value={company.employer_id}>{company.employer_name}</option>)
                                }
                            </SelectList>
                            <SelectList 
                                placeholder="Select Project"
                                multiple 
                                value={selectedJob}  
                                onChange={this.onChangeJob}
                                renderValue={selected => !selected.length ? <em>Select Project</em> : jobList.filter(job => selected.indexOf(job.job_id) >= 0).map(job => job.title).join(', ')}
                            >
                                {
                                    jobList.map(job => <option value={job.job_id}>{job.title}</option>)
                                }
                            </SelectList>
                            <SelectList 
                                placeholder="Select Locations"
                                multiple 
                                value={selectedLocation}  
                                onChange={(e) => this.setState({selectedLocation : e.target.value})}
                                renderValue={selected => !selected.length ? <em>Select Locations</em> : jobLocationList.filter(loc => selected.indexOf(loc.job_location_id) >= 0).map(loc => loc.city).join(', ')}
                            >
                                {
                                    jobLocationList.map(loc => <option value={loc.job_location_id}>{loc.job_title +" | "+loc.city}</option>)
                                }
                            </SelectList>
                            {isNew ?
                            <div>
                                <InputFeild required name="first_name" onChange={this.onChangeUser}  className="mt-3" placeholder="Name Of Recuiter" />
                                <InputFeild required name="mobile" onChange={this.onChangeUser} className="mt-3" placeholder="Phone Number" />
                                <InputFeild name="email" onChange={this.onChangeUser} className="mt-3" placeholder="Email Address" />
                            </div>:null
                            }

                                <Row className="align-items-center">
                                <Col>
                                <Button  type="submit" size="24px" block className="buttonred mt-3" style={{width: "150px"}}>Add New Recruiter</Button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>

        </div>
        


        );
    }
}

export default Recruiter;