import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, Popover, PopoverBody, PopoverHeader } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";
import moment from "moment";

class CustomerProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerJobs: [],
            isShowFormResponseData: false
        }
    }

    componentDidMount(){
       this.fetchProjectDetails();
    }

   
     

    fetchProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { customer_lead_id : this.props.customer_lead_id }
            let projectsResp = await ApiEndpoints.Customer.customerProjectDetails(body)
            let customerJobs = projectsResp.data.data
            this.setState({ customerJobs })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    showCustomerFormResponse = async (customerJobData) => {
        store.dispatch(setLoader(true))
        try{
            var body = { job_id : customerJobData.job_id }
            let jobTaskResp = await ApiEndpoints.JobTask.fetch(body)
            let jobTask = jobTaskResp.data.data[0];
            if(!jobTask) throw new Error("No Task Config found");
            let formResponseResp = await ApiEndpoints.JobTask.fetchTaskResp({ form_id: jobTask.form_id, form_response_id: customerJobData.form_response_id })
            this.setState({
                isShowFormResponseData: {
                    customer_job_mapping_id: customerJobData.customer_job_mapping_id,
                    formData: jobTask.formData,
                    formResponseData: formResponseResp.data.data[0]
                }
            })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    render() {
        let { customerJobs, selectedJob } = this.state;
        return (
           
            <>
                {
                    customerJobs.map(customerJob => 
                    <div>
                        <Row className="align-items-center bg-light shadow-sm mt-3 mx-1 border py-2" style={{fontSize: 14, borderRadius: 10}}>
                            <Col xs={2} className="text-center clickable" >
                                <img src={customerJob.Job.Employer.logo_key} style={{width: "100%", aspectRatio: "1", borderRadius: "50%"}} alt="project_logo" />
                            </Col>
                            <Col xs={7}>
                                <strong className="" >{customerJob.Job.Employer.employer_name} - { customerJob.Job.job_title }</strong>
                                {customerJob.Job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[customerJob.Job.archive_status]} /> }
                                <span className="d-block small">Lead Added On: {moment(customerJob.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                            </Col>
                            <Col xs={3} className="text-right">
                                <span className="small clickable" onClick={() => this.showCustomerFormResponse(customerJob)}>Show Data</span>
                            </Col>
                        </Row>
                    </div>
                    )
               
                }
                
            </>
                       
        );
    }
}
export default CustomerProjects;
