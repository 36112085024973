
import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';
import {withRouter} from 'react-router-dom';
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import { Col, Row ,Button} from "reactstrap";
import { Drawer, Switch } from "@material-ui/core";
import InputField from "../form/input";
import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import CONSTANTS from "../../../constants";
import SelectList from "../form/select";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

class VerificationInvoice extends Component {
    constructor(props) {
      super(props);
      this.state = {
        invoiceData: {invoice_date: moment().toDate(), items: [] },

      }
    }

    onChangeInvoiceData = (k, v) => {
      let { invoiceData } = this.state;
      if(k === "dates"){
          if(v === null) invoiceData.start_date = invoiceData.end_date = null;
          else if(invoiceData.start_date) invoiceData.end_date = v;
          else invoiceData.start_date = v;
      }
      else invoiceData[k] = v;
      this.setState({ invoiceData });
  }

    render() {
      let { invoiceData } = this.state;

        return(
          <div>
              <Row className="align-items-center mx-3 my-3 border-bottom">
                 <Col>
                    <strong>Update Verify Tasks by Email File</strong>
                 </Col>
              </Row>

              <Row className="px-3 align-items-center">
                <Col xs={3}><span>Attached File:</span></Col>
                <Col xs={4} className="text-left"><span style={{fontSize: 12}}> Kotak PMW till 11th july .xlsr</span></Col>
                <Col xs={1}><span className="text-danger" style={{fontSize: 12}}> Ignore</span></Col>
                <Col xs={3}></Col>
              </Row>

              <Row className=" px-3 mt-3 align-itmes-center">
                <Col xs={3}><span style={{fontSize: 12}}>Database Field</span></Col>
                <Col xs={4} className="text-left"><span style={{fontSize: 12}}>Mapped Column</span></Col>
                <Col xs={2}><span style={{fontSize: 12}}>Row/column</span></Col>
                <Col xs={3}></Col>
              </Row>

              <Row className=" px-3 mt-2 align-itmes-center">
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="Task ID" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="Select Value" value="">
                        <option value={1}>Mobile Number</option>
                        <option value={2}>Emails</option>
                        <option value={2}>Contact</option>
                    </SelectList></Col>
                <Col xs={3}><InputField type="" style={{fontSize: 12}} placeholder="B1" value="" /></Col>
                <Col xs={2}></Col>
              </Row>

              <Row className=" px-3 mt-2 align-itmes-center">
                <Col xs={3}><InputField style={{fontSize: 12}}  placeholder="Task Status" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="Select Value" value="">
                        <option value={1}>Updated values</option>
                        <option value={2}>Updated values</option>
                        <option value={2}>Updated values</option>
                    </SelectList></Col>
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="B2" value="" /></Col>
                <Col xs={2}></Col>
              </Row>

              <Row className=" px-3 mt-2 align-itmes-center">
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="Comment" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="Select Value" value="">
                        <option value={1}>Notes</option>
                        <option value={2}>Notes</option>
                        <option value={2}>Notes</option>
                    </SelectList></Col>
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="B3" value="" /></Col>
                <Col xs={2}></Col>
              </Row>

              <Row className="my-1 p-2 border-top border-bottom">
                <Col xs={3}><span style={{fontSize: 12}}>Update status till</span></Col>
                <Col xs={3}>
                  <ReactDatePicker
                          selected={invoiceData.start_date || null}
                          onChange={(dates) => this.onChangeInvoiceData("dates", dates)}
                          startDate={invoiceData.start_date || null}
                          endDate={invoiceData.end_date || null}
                          shouldCloseOnSelect={!(!invoiceData.start_date || Boolean(invoiceData.start_date && invoiceData.end_date))}
                          selectsRange={true}
                          isClearable={true}
                          placeholderText="Select Date"
                          customInput={<div><input value={`${invoiceData.start_date ? moment(invoiceData.start_date).format("Do MMM'YY") : ""} - ${invoiceData.end_date ? moment(invoiceData.end_date).format("Do MMM'YY") : ""}`} /></div>}
                      />
                </Col>
                <Col xs={1}>-</Col>

                <Col xs={3}>
                  <ReactDatePicker
                          selected={invoiceData.start_date || null}
                          onChange={(dates) => this.onChangeInvoiceData("dates", dates)}
                          startDate={invoiceData.start_date || null}
                          endDate={invoiceData.end_date || null}
                          shouldCloseOnSelect={!(!invoiceData.start_date || Boolean(invoiceData.start_date && invoiceData.end_date))}
                          selectsRange={true}
                          isClearable={true}
                          placeholderText="Select Date"
                          customInput={<div><input value={`${invoiceData.start_date ? moment(invoiceData.start_date).format("Do MMM'YY") : ""} - ${invoiceData.end_date ? moment(invoiceData.end_date).format("Do MMM'YY") : ""}`} /></div>}
                      />
                </Col>
                <Col xs={2}>
                  <Button className=" buttonred py-1" style={{fontSize: 10}} >Get Details</Button>
                </Col>
              </Row>

          <div className="bg-light border-top border-bottom">
              <Row className="px-3 align-items-center">
                <Col xs={5}><span>Review Task Status</span></Col>
              </Row>

              <Row className=" px-3 mt-3 align-itmes-center">
                <Col xs={3}><span style={{fontSize: 12}}>Task Counts</span></Col>
                <Col xs={4} className="text-left"><span style={{fontSize: 12}}>Client Status</span></Col>
                <Col xs={4}><span style={{fontSize: 12}}>PickMyWork Status</span></Col>
                <Col xs={2}></Col>
              </Row>

              <Row className=" px-3 mt-2 align-itmes-center">
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4} className="text-left"><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="No Status" value="">
                        <option value={1}>test 1</option>
                        <option value={2}>test 2</option>
                        <option value={2}>test 3</option>
                    </SelectList>
                </Col>
                <Col xs={2}></Col>
              </Row>
           
              <Row className=" px-3 mt-2 align-itmes-center">
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4} className="text-left"><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="No Status" value="">
                        <option value={1}>test 1</option>
                        <option value={2}>test 2</option>
                        <option value={2}>test 3</option>
                    </SelectList>
                </Col>
                <Col xs={2}></Col>
              </Row>

              <Row className=" px-3 mt-2 align-itmes-center mb-3">
                <Col xs={3}><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4} className="text-left"><InputField style={{fontSize: 12}} placeholder="" value="" /></Col>
                <Col xs={4}>
                    <SelectList style={{fontSize: 12}} search placeholder="No Status" value="">
                        <option value={1}>test 1</option>
                        <option value={2}>test 2</option>
                        <option value={2}>test 3</option>
                    </SelectList>
                </Col>
                <Col xs={2}></Col>
              </Row>

          </div>
              <Row className="px-3 mt-3 ">
                <Col xs={8} className=""></Col>
                <Col xs={4}>
                  <Button className="mr-2 buttonred py-1" style={{fontSize: 10}} >Close</Button>
                  <Button className="mr-2 buttonred py-1" style={{fontSize: 10}} >Submit</Button>
                </Col>
              </Row>
              
          </div>
        );
     }
  }
  export default withRouter(props => <VerificationInvoice {...props} />);


