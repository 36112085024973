
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, ButtonGroup, Collapse, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input";
import { Badge, Checkbox, Icon, TablePagination } from "@material-ui/core";
import moment from "moment";
import ConfirmAction from "../../global/confirm-action";
import SelectList from "../../global/form/select";
import { addUser } from "../../../actions";


class PortfolioManageAccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdd: false,
            userList: [],
        
        };
    }


    componentDidMount(){
        this.fetchUsers();
    }




    fetchUsers = async() => {
        store.dispatch(setLoader(true));
        try{
         
            var body = { portfolio_id: this.props.portfolioId };
            let apiData = await ApiEndpoints.Portfolio.fetchAccessDetails(body);
            let userList = apiData.data.data;
            this.setState({ userList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addAccess = async (e) => {
        e.preventDefault();
        let { isAdd } = this.state;
        let { portfolioId } = this.props;
        store.dispatch(setLoader(true))
          try{
            if(isAdd.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var userBody = {mobile: isAdd.mobile, first_name: isAdd.first_name, role_id : 10}
            let user = await addUser(userBody);
            let accessBody = {
                portfolio_id: portfolioId,
                user_id: user.user_id,
                access_type: isAdd.access_type
            }
            await ApiEndpoints.Portfolio.addAccess(accessBody);
            this.setState({isAdd : false});
            await this.fetchUsers()
            store.dispatch(setSuccess({show: true, message: "Access Added!"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    removeAccess = async (user) => {
        let { portfolioId } = this.props;
        store.dispatch(setLoader(true))
          try{
            let accessBody = {
                portfolio_id: portfolioId,
                user_id: user.user_id,
            }
            await ApiEndpoints.Portfolio.removeAccess(accessBody);
            await this.fetchUsers();
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

  render() {
    let {  isAdd, userList } = this.state;
    let { portfolioId } = this.props;

    var userRole = store.getState().user.UserRole
    var isAllowManagerActions = !!userRole.filter(role => role.role_id === 1 || role.role_id === 3).length;
    
    return (<>
         
        <Button size="sm" className="buttonred m-2" onClick={() => this.setState({isAdd: {}})}>Add Access</Button>

        {!!isAdd && 
            <Row className="border shadow sm my-3 mx-1 py-3 align-items-center">
                <Col xs={6} className="mt-2">
                    <InputFeild placeholder="Name" value={isAdd.first_name} onChange={(e) => { isAdd.first_name = e.target.value; this.setState({isAdd}) }} />
                </Col>
                <Col xs={6} className="mt-2">
                    <InputFeild placeholder="Mobile" value={isAdd.mobile} onChange={(e) => { isAdd.mobile = e.target.value; this.setState({isAdd}) }} />
                </Col>
                <Col xs={6} className="mt-2">
                    <InputFeild placeholder="Email" value={isAdd.email} onChange={(e) => { isAdd.email = e.target.value; this.setState({isAdd}) }} />
                </Col>
                <Col xs={4} className="mt-2">
                    <SelectList  placeholder="Select Role" value={isAdd.access_type} onChange={(e) => {isAdd.access_type = e.target.value; this.setState({isAdd})}} >
                        <option value={1}>Manager</option>
                        <option value={2}>Ops Associate</option>
                    </SelectList>
                </Col>
                <Col xs={2} className="mt-2">
                    <Icon style={{cursor: "pointer", fontSize: 20}} onClick={this.addAccess} className="mr-1">done</Icon> 
                    <Icon style={{cursor: "pointer", fontSize: 20}} onClick={() => this.setState({isAdd : false})}>close</Icon>
                </Col>

            </Row>
        }

        {
            userList.length ? 
            userList.map(user => 
                <Row className="my-2 mx-1 py-2 rounded border shadow-sm align-items-center">
                    <Col xs={6}><strong>{user.User.first_name}</strong></Col>
                    <Col xs={4}>{user.access_type == 1 ? "Manager" : "Ops Associate"}</Col>
                    <Col xs={2}>
                        <Icon className="" style={{cursor: "pointer", fontSize: 18}} onClick={()=> {this.setState({isConfirm :  () => this.removeAccess(user) })}} >delete</Icon>
                    </Col>
                </Row>

            )
            :
            <strong className="m-4"><em className="text-light">No access mapped yet</em></strong>
        }
       

      
        <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />



    </>)
  }
}


export default PortfolioManageAccess;
