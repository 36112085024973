import React, { Component }from "react";
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import TablePagination from '@material-ui/core/TablePagination';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader, setSuccess } from "../../../actions/action-creators";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Icon, Button, Collapse, Chip} from "@material-ui/core";
import SelectList from "../../global/form/select";
import InputFeild from "../../global/form/input";
import AddStory from "./add-story";
import { downloadApiFile, uploadFile } from "../../../actions";
import ScheduleNotifications from "./schedule_notifications";
import CONSTANTS from "../../../constants";
import Table from "reactstrap/lib/Table";
import moment from "moment";
import PartnerDetail from "../user_profile";
import SendNotifications from "./send_notification";
let filterList = [
    {
        filter_id : 1,
        name : "Partners Not Applied Anywhere",
        type : "JOB_NOT_INVOLVED",
        scope : "GENERIC"
    },
    {
        filter_id : 1.1,
        name : "App not downloaded",
        type : "APP_NOT_DOWNLOAD",
        scope : "GENERIC"
    },
    {
        filter_id : 2,
        name : "Job Involved",
        type : "JOB_INVOLVED",
        scope : "JOB_SPECIFIC"
    },
    {
        filter_id : 2.1,
        name : "Leads",
        type : "JOB_STATUS",
        scope : "JOB_SPECIFIC",
        params : {application_status : 0}
    },
    // {
    //     filter_id : 3,
    //     name : "Applied - Slot Not Booked",
    //     type : "JOB_APPLIED_NOT_SLOT",
    //     scope : "JOB_SPECIFIC"
    // },
    // {
    //     filter_id : 4,
    //     name : "Slot Booked",
    //     type : "JOB_SLOT_BOOKED",
    //     scope : "JOB_SPECIFIC"
    // },
    // {
    //     filter_id : 5,
    //     name : "Interviewed",
    //     type : "JOB_STATUS",
    //     scope : "JOB_SPECIFIC",
    //     params : {application_status : 4}
    // },
    {
        filter_id : 6,
        name : "Interested",
        type : "JOB_STATUS",
        scope : "JOB_SPECIFIC",
        params : {application_status : 2}
    },
    // {
    //     filter_id : 7,
    //     name : "Rejected",
    //     type : "JOB_STATUS",
    //     scope : "JOB_SPECIFIC",
    //     params : {application_status : 3}
    // },
    {
        filter_id : 8,
        name : "In Training",
        type : "JOB_STATUS",
        scope : "JOB_SPECIFIC",
        params : {application_status : 5}
    },
    // {
    //     filter_id : 9,
    //     name : "Training Completed",
    //     type : "JOB_STATUS",
    //     scope : "JOB_SPECIFIC",
    //     params : {application_status : 6}
    // },
    {
        filter_id : 10,
        name : "In Task",
        type : "JOB_STATUS",
        scope : "JOB_SPECIFIC",
        params : {application_status : 7}
    },
    {
        filter_id : 11,
        name : "Terminated",
        type : "JOB_STATUS",
        scope : "JOB_SPECIFIC",
        params : {application_status : 8}
    },
    {
        filter_id : 12,
        name : "Inactive Partners",
        type : "INACTIVE",
        scope : "JOB_SPECIFIC"
    },
    // {
    //     filter_id : 13,
    //     name : "Quit Project",
    //     type : "JOB_STATUS",
    //     scope : "JOB_SPECIFIC",
    //     params : {application_status : 9}
    // },
    // {
    //     filter_id : 14,
    //     name : "Project Completed",
    //     type : "JOB_STATUS",
    //     scope : "JOB_SPECIFIC",
    //     params : {application_status : 10}
    // }
];

class Giggers extends Component {
    constructor(props) {
        super(props);
        var userRole = store.getState().user.UserRole
        this.state = {
            userList : [],
            userCount : 0,
            isOpenFilters : false,
            jobList : [],
            filterType : "GENERIC" ,
            selectedJobId : [],
            selectedJobLocationId : null,
            selectedFilterId : null,
            currentUserPage : 0,
            rowsPerPage : 20,
            isOpenNotificationModal : false,
            isOpenStory : false,
            searchText : "",
            isUploadProfile: false,
            originType: "GENERAL",
            smsTemplateId : null,
            smsTemplateList : [],
            isSms : false,
            message: "",
            isOpenPayoutData : false,
            isOpenProfileModel: false,
            isFilterApplied: false
        }
    }

    componentDidMount(){
        this.init();
        this.fetchJob();
    }

    init = async() => {
        store.dispatch(setLoader(true))
        try{
            var filterBody = this.prepareFilter();
            let limit = this.state.rowsPerPage;
            let offset = this.state.currentUserPage * this.state.rowsPerPage;
            var body = {...(filterBody || {}), limit, offset}
            if(this.state.searchText) {
                body.search = this.state.searchText.toLowerCase().replace("pmw", "");
            }
            let apiData = await ApiEndpoints.User.fetchUsersByFilter(body);
            let userList = apiData.data.data.rows;
            let userCount = apiData.data.data.count;
            this.setState({ userList, userCount })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchJob = async () => {
        store.dispatch(setLoader(true))
            try{
                let apiData = await ApiEndpoints.Jobs.fetch();
                await this.setState({ jobList : apiData.data.data})
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false))
    }
    changeFilterType = async (e) => {
        var value = e.target.value;
        await this.setState({filterType : value, selectedFilterId : null, selectedJobId: []});
        if(this.state.isFilterApplied) this.init();
        this.setState({ isFilterApplied: false});
    }
    setJobId = (job_id) => {

        this.setState({selectedJobId : job_id, selectedJobLocationId : null})
    }
    setJobLocationId = (location_id) => {
        this.setState({selectedJobLocationId : this.state.selectedJobLocationId === location_id ? null : location_id})
    }
    selectFilter = async (e) => {
        var filterType = filterList.filter(f => f.filter_id == e.target.value)[0].scope;
        await this.setState({filterType, selectedFilterId : null, selectedJobId: []});
        this.setState({selectedFilterId : e.target.value})
    }
    applyFilter = async () => {
        await this.setState({currentUserPage : 0})
        this.init();
    }
    prepareFilter = () => {
        var filterbody = {filters : []};
        if(this.state.selectedFilterId){
            let filter = filterList.filter(filter => filter.filter_id == this.state.selectedFilterId)[0];
            if(filter){
                filterbody.filters.push(filter.type);
                if(filter.scope === "JOB_SPECIFIC" && this.state.selectedJobId.length > 0) filterbody.job_id = this.state.selectedJobId;
                if(filter.scope === "JOB_SPECIFIC" && this.state.selectedJobLocationId) filterbody.job_location_id = this.state.selectedJobLocationId;
                filterbody = {...filterbody, ...(filter.params || {})}
            }
        }
        return filterbody;
    }
    


    downloadWallet = async (user_id) => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                user_id
            }
            var resp = await ApiEndpoints.Userwallet.downloadWalletTransaction(body);
            downloadApiFile(resp, "Wallet Transactions.csv")
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    handleProfileUploadSubmit = async (e) => {
        e.preventDefault();
        let {isUploadProfile} = this.state;
        var file = e.target.csvFile.files[0];
        if(isUploadProfile === "APPLICANTS" && (!(this.state.selectedJobId && this.state.selectedJobId[0]) || !this.state.selectedJobLocationId)){
            store.dispatch(setError({show: true, message: "Please select Project and Location"}))
            return;
        }
        store.dispatch(setLoader(true))
        try{
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key
            }
            if(isUploadProfile === "APPLICANTS"){
                body.job_id = this.state.selectedJobId;
                body.job_location_id = this.state.selectedJobLocationId;
                await ApiEndpoints.Applicants.addApplicantsByExcel(body);
            }
            isUploadProfile === "BANK" && await ApiEndpoints.UserProfile.uploadUsersBankDetail(body)
            isUploadProfile === "DOC" && await ApiEndpoints.UserProfile.uploadUsersDocuments(body)
            isUploadProfile === "PERSONAL" && await ApiEndpoints.UserProfile.uploadUsersPersonal(body)
            this.setState({isUploadProfile : false});
            store.dispatch(setSuccess({show: true, message: "Uploaded Successfully!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchSmsTemplate = async () => {
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Templates.fetchSmsTemplates();
            await this.setState({ smsTemplateList : apiData.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
            store.dispatch(setLoader(false))
    }
    showPayoutData = async (user_id, job_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                entity_type: "JOB",
                entity_id: job_id || this.state.jobList[0].job_id
            };
            let payoutResp = await ApiEndpoints.JobTaskPayment.fetchPayouts(body);
            payoutResp = payoutResp.data.data;
            var payoutData = [];
            if(payoutResp.length){
            var payoutDataBody = {
                user_id, 
                payout_id : payoutResp.map(o => o.payout_id)
            }
            let payoutDataResp = await ApiEndpoints.JobTaskPayment.fetchPayoutData(payoutDataBody);
            payoutData = payoutDataResp.data.data;
            }
            await this.setState({ isOpenPayoutData : {user_id, job_id: body.entity_id, data: payoutData}})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    render() {

        let { userList, userCount, isOpenFilters, jobList, filterType, selectedFilterId, isOpenStory, selectedJobId, searchText, isOpenNotificationModal, isOpenPayoutData } = this.state;
        let JobLocations = selectedJobId.length === 1 ? jobList.filter(job => job.job_id == selectedJobId)[0].JobLocations : [];
        var userRole = store.getState().user.UserRole
        var isAdmin = !!userRole.filter(role => role.role_id === 1).length;
        return (
                <Container>
                    {/* <Row>
                        <Col>
                            <FormControl size="small" component="fieldset">
                                <RadioGroup row aria-label="filter_type" name="filter_type" value={filterType} onChange={this.changeFilterType} >
                                    <Icon fontSize="small" className="mt-2 mr-2" style={{color: "#aaa"}}>filter_alt</Icon>
                                    <FormControlLabel value="JOB_SPECIFIC" control={<Radio />} label="Project Specific" />
                                    {!!userRole.filter(role => role.role_id === 1).length && <FormControlLabel value="GENERIC" control={<Radio />} label="Other" />}
                                </RadioGroup>
                            </FormControl>
                        </Col>
                    </Row> */}
                            
                    <Row>
                        <Col md={3} xs={12}>
                            <SelectList size="small" value={selectedFilterId} placeholder="Select Filter" onChange={this.selectFilter}>
                            { filterList.map((filter, i) => 
                                <option key={i} value={filter.filter_id}>{filter.name}</option> 
                            )}
                            </SelectList>
                        </Col>
                        {filterType === "JOB_SPECIFIC" && selectedFilterId &&

                        <Col md={3} xs={12}>
                            <SelectList search={true} multiple="checkbox" placeholder="Any Project" value={this.state.selectedJobId} onChange ={(e) => this.setJobId(e.target.value)} >
                                { 
                                    jobList.map(job => 
                                    <option key={job.job_id} value={job.job_id}> 
                                        {job.Employer.employer_name +  "  -  "  + job.title} 
                                        {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                    </option>
                                    )
                                }
                            </SelectList>
                            
                        </Col>
                        }
                        {!!JobLocations.length &&
                        <Col md={3} xs={12}>
                            <SelectList placeholder="Any Location" value={this.state.selectedJobLocationId} onChange ={(e) => this.setJobLocationId(e.target.value)} >
                                { 
                                    JobLocations.map(loc => 
                                    <option key={loc.job_location_id} value={loc.job_location_id}>
                                        {loc.city}
                                        {loc.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[loc.archive_status]} /> }
                                    </option>
                                    )
                                }
                            </SelectList>
                            
                        </Col>
                        }
                        <Col md={3} xs={12}>
                            {selectedFilterId && <Button variant="outlined" onClick={() => this.changeFilterType({target: {value: "GENERIC"}})} className="mr-3" size="small" style={{fontSize: 10}}>Reset</Button>}
                            <Button disabled={!this.state.selectedFilterId} variant="contained" onClick={() => {this.applyFilter(); this.setState({isFilterApplied: true});}} className="" size="small" style={{fontSize: 10}}>Apply</Button>
                        </Col>
                    </Row>

                    <Row className="border-bottom align-items-end my-2">
                        <Col xs={12} md={4} className="">
                            <h5>Partners</h5>
                        </Col>
                        <Col  xs={12} md={8} className="text-right">
                            {/* {this.state.isFilterApplied && */}
                            <>
                                <Button variant="contained" size="small" style={{fontSize: 10 , backgroundColor: "black"}} startIcon={<Icon style={{fontSize: 12}}>notifications</Icon>} color="secondary" onClick={() => {this.setState({isOpenNotificationModal : 1, smsTemplateId: null}); this.fetchSmsTemplate()}}>Add Notification</Button>
                                <Button variant="contained" size="small" style={{fontSize: 10 , backgroundColor: "black"}} startIcon={<Icon style={{fontSize: 12}}>photo_camera_back</Icon>} className="ml-3" color="secondary" onClick={() => this.setState({isOpenStory : true})}>Add Story</Button>
                            </>
                            {/* } */}
                            {!!userRole.filter(role => role.role_id === 1).length && 
                            <UncontrolledDropdown style={{display: "inline-block"}}>
                                <DropdownToggle tag="span" className="btn btn-link text-dark">
                                        <Icon style={{marginTop: 10, marginRight: 5}}>more_vert</Icon>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem style={{fontSize: "14px"}} onClick={() => this.setState({isUploadProfile : "APPLICANTS"})}>{"Add Partners to Job"}</DropdownItem>
                                    <DropdownItem style={{fontSize: "14px"}} onClick={() => this.setState({isUploadProfile : "PERSONAL"})}>{"Add/Update Partner Basic/Personal details"}</DropdownItem>
                                    <DropdownItem style={{fontSize: "14px"}} onClick={() => this.setState({isUploadProfile : "DOC"})}>{"Upload User Documents"}</DropdownItem>
                                    <DropdownItem style={{fontSize: "14px"}} onClick={() => this.setState({isUploadProfile : "BANK"})}>{"Upload Bank Details"}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            }
                        </Col>
                    </Row> 
                    <Row className="">
                        <Col lg={8} xs={12}><span style={{fontSize: 10, fontWeight: "bold"}}>Showing Partners: {userCount}</span></Col>
                        <Col lg={4} xs={12}>
                            <form onSubmit={(e) => {e.preventDefault(); this.applyFilter()}}>
                                <InputFeild onChange={(e) => this.setState({searchText :e.target.value})} value={searchText} icon="search" onClickIcon={this.applyFilter} placeholder=" search by Name/Mobile/PMW-Id" />
                            </form>
                        </Col>
                    </Row>             
                    <Row>
                        {
                        userList.length ? userList.map(user => 
                            <Col key={user.id} md={3} xs={6}>
                                <Card key={user.user_id} className="w-100 my-3 bg-light">
                                    <Icon style={{position : "absolute", right : 5, top : 5, fontSize : 14, cursor : "pointer"}} fontSize="inherit" onClick={() => this.downloadWallet(user.user_id)}>account_balance_wallet</Icon>
                                    <Icon style={{position : "absolute", right : 25, top : 5, fontSize : 14, cursor : "pointer"}} fontSize="inherit" onClick={() => this.showPayoutData(user.user_id)}>paid</Icon>
                                    <CardBody style={{margin: "-11px"}}>
                                        <Row noGutters className="align-items-center mt-2">
                                            <Col xs={4} className="pr-3">
                                            <img className="rounded-circle" style={{width: "100%", aspectRatio: "1"}} src={user.profile_picture_url || "/images/dummy_profile.jpg"}  alt="Profile"></img>
                                            </Col>
                                            <Col xs={8} className="text-muted ">
                                            <p className="text-dark mb-0" style={{fontSize: 13, cursor: "pointer"}} onClick={() => this.setState({isOpenProfileModel: user})}  >{user.first_name}</p>                                
                                            <p className="text-dark mb-0" style={{fontSize: 11}}>PMW{user.id} </p>
                                            <p className="mb-0" style={{fontSize: 11}}>Mobile : {user.mobile && "******"+user.mobile.toString().substr(-4)}</p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ):
                        <Col className="text-center"><em>No Users Found</em></Col>
                        }
                    </Row>
                    <TablePagination rowsPerPageOptions={[20, 40, 60, 100, 200, 500]} component="div" count={userCount} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                        onChangePage={async (e, page) => {await this.setState({currentUserPage : page}); this.init()} }
                        onChangeRowsPerPage={async (e) => {await this.setState({rowsPerPage : e.target.value}); this.init()}}
                    />
                   
                    <Modal size="lg" isOpen={!!this.state.isOpenNotificationModal} toggle={() => this.setState({isOpenNotificationModal : false, originType: "GENERAL"})}>
                        <ModalHeader toggle={() => this.setState({isOpenNotificationModal : false })}>
                            <Row className="px-2">
                                <Col xs="auto" onClick={() => this.setState({isOpenNotificationModal: 1 })} className={" " + (isOpenNotificationModal === 1 && "border-bottom")} style={{cursor: "pointer"}}><h6>Send Now</h6></Col>
                                {isAdmin && <Col xs="auto" className="border-right px-2"></Col>}
                                {isAdmin && <Col xs="auto" onClick={() => this.setState({isOpenNotificationModal: 2})} className={"ml-2 " + (isOpenNotificationModal === 2 && "border-bottom")} style={{cursor: "pointer"}}><h6>Schedule</h6></Col>}
                            </Row>
                        </ModalHeader>
                        
                        <ModalBody>
                            {isOpenNotificationModal === 1 && <SendNotifications prepareFilter={this.prepareFilter}  onClose={() => this.setState({isOpenNotificationModal : false})}  />}
                            {isOpenNotificationModal === 2 && <ScheduleNotifications templates={this.state.smsTemplateList} filters={this.prepareFilter()} filterList={filterList} jobList={jobList} />}
                            
                        </ModalBody>
                    </Modal>


                    <Modal isOpen={this.state.isUploadProfile} toggle={() => this.setState({isUploadProfile : false})}>
                        <form onSubmit={this.handleProfileUploadSubmit}>
                        <ModalHeader toggle={() => this.setState({isUploadProfile : false})}> 
                            <h5>
                                {this.state.isUploadProfile === "DOC" && "Update Partner's Documents"}
                                {this.state.isUploadProfile === "BANK" && "Upload Partner's Bank Details"}
                                {this.state.isUploadProfile === "APPLICANTS" && "Add Partners to Job"}
                                {this.state.isUploadProfile === "PERSONAL" && "Add/Update Partners basic/personal details"}
                            </h5>
                        </ModalHeader>
                        <ModalBody>
                            {
                                this.state.isUploadProfile === "APPLICANTS" && <div>
                                    <SelectList placeholder="Any Project" value={this.state.selectedJobId ? this.state.selectedJobId[0] : ""} onChange ={(e) => this.setJobId([e.target.value])} >
                                        { 
                                            jobList.map(job => 
                                            <option key={job.job_id} value={job.job_id}> 
                                                {job.Employer.employer_name +  "  -  "  + job.title} 
                                                {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                            </option>
                                            )
                                        }
                                    </SelectList>
                                    <SelectList placeholder="Any Location" value={this.state.selectedJobLocationId} onChange ={(e) => this.setJobLocationId(e.target.value)} >
                                        { 
                                            JobLocations.map(loc => 
                                            <option key={loc.job_location_id} value={loc.job_location_id}>{loc.city}</option>
                                            )
                                        }
                                    </SelectList>
                                    </div>
                            }
                            <input required type="file" name="csvFile" className="mt-3" />
                            <p style={{fontSize: 12}}><em>Download Sample File, Fill the detils and upload here</em></p>
                            {this.state.isUploadProfile === "DOC" && <a download={true}  href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/upload_user_docs_sample.xlsx">Download Sample File</a>}
                            {this.state.isUploadProfile === "PERSONAL" && <a download={true}  href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/upload_user_personal_sample.xlsx">Download Sample File</a>}
                            {this.state.isUploadProfile === "BANK" && <a download={true}  href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/upload_bank_detail_sample.xlsx">Download Sample File</a>}
                            {this.state.isUploadProfile === "APPLICANTS" && <a download={true} href="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/excel_sample/upload_applicants_by_status_sample.xlsx">Download Sample File</a>}
                        </ModalBody>
                        <ModalFooter className="p-2">
                            <Button type="submit" variant="contained">Upload</Button>{' '}
                            <Button type="button" variant="outlined"  onClick={() => this.setState({isUploadProfile : false})}>Cancel</Button>
                        </ModalFooter>
                        </form>
                    </Modal>
                    <Modal size={"lg"} isOpen={!!isOpenPayoutData} toggle={() => this.setState({isOpenPayoutData : false})}>
                        <ModalHeader toggle={() => this.setState({isOpenPayoutData : false})}>Payout Details</ModalHeader>
                        <ModalBody>
                            <SelectList placeholder="Select Project" value={isOpenPayoutData.job_id} onChange ={(e) => this.showPayoutData(isOpenPayoutData.user_id, e.target.value)} >
                                { 
                                    jobList.map(job => 
                                    <option key={job.job_id} value={job.job_id}> {job.Employer.employer_name +  "  -  "  + job.title} {job.JobLocations.length ? " ( " : ""} {job.JobLocations.map(obj => obj.city).join( ", ")}  {job.JobLocations.length ? " ) " : ""} </option>
                                    )
                                }
                            </SelectList>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Payout Date</th>
                                        <th>Payout Amount</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isOpenPayoutData.data || []).map(payout => 
                                        <tr>
                                            <td>{moment(payout.created_on).utcOffset("+05:30").format("Do MMM YY")}</td>
                                            <td>{payout.amount}</td>
                                            <td>{payout.status_text}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            
                        </ModalBody>
                    </Modal>

                    {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}


                    <AddStory prepareFilter={this.prepareFilter} userCount={userCount}  isOpenStory={isOpenStory}  handleClose={()=> {this.setState({isOpenStory : false})}} />

                </Container>            
        );
    }
}
export default Giggers;
