import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import { Icon, Chip, TablePagination } from "@material-ui/core";
import Aux from "../../global/AuxComp";
import store from "../../../store";
import { setLoader, setError, setSuccess } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import CONSTANTS from "../../../constants";
import moment from "moment";
import GoogleMap from "../../global/google_map";
import { copyCodeToClipboard, groupArrayOnKey } from "../../../actions";
import { ModalBody, ModalHeader, ModalFooter, Modal, Button } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import SelectList from "../../global/form/select";
import ConfirmAction from "../../global/confirm-action";
import OutboundCustomerCallingStatus from "../../global/outbound-customer-calling-status";


class PartnerMapDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTaskCycle : false,
            selectedStatus : this.props.selectedStatus || "ALL",
            selectedTask : this.props.taskList[0] || { formData: {} },
            taskCounts: {},
            userLocations : [],
            taskData: {},
            hoveredTaskId : false,
            showMap: false,
            isOpenUpdateFormResponse : false,
            selectedFormResponse : [],
            rowsPerPage : 20,
            currentUserPage : 0, 
            reasonList : [],
            videoPlayUrl: null,
            showImageUrl: null,
            isAddMissingDetails: false
        }
    }

    async componentDidMount(){
        await this.selectTaskCycle(this.props.selectedCycle || "ALL")
        this.selectRejectionReason();
        this.fetchTaskCounts();
    }
    
    fetchTaskCounts = async () => {
        store.dispatch(setLoader(true))
        let { user_id, isShowPendingOnboardings } = this.props.user;
        let {selectedTaskCycle, selectedTask} = this.state;
        try{
            if(selectedTask.formData && selectedTask.formData.response_data_type === "GROUP_COUNT"){
                let response = await ApiEndpoints.Jobs.getGroupFormResponse({form_id : selectedTask.form_id, ...selectedTaskCycle, user_id});
                let responseData = response.data.data.reduce((prev, curr) => {prev[curr.status] = curr; return prev;}, {})
                this.setState(
                {taskCounts : {
                        VERIFIED : responseData["VERIFIED"] ? responseData["VERIFIED"].response_counts  : 0,
                        PENDING : responseData["PENDING"] ? responseData["PENDING"].response_counts  : 0,
                        REJECTED : responseData["REJECTED"] ? responseData["REJECTED"].response_counts  : 0,
                }});   
            }
            else{
                var body = {form_id : selectedTask.form_id, ...selectedTaskCycle, user_id, archive_status: [0, 1]};
                // if(!isShowPendingOnboardings) body.sub_status = [0, 2];
                let resp = await ApiEndpoints.Jobs.getFormCount(body);
                this.setState({taskCounts : resp.data.data});
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchUserTask = async () => {
        let { user_id, isShowPendingOnboardings } = this.props.user;
        let {selectedTaskCycle, selectedTask} = this.state;
        try{
          let formResBody = {
            form_id : selectedTask.form_id,
            user_id,
            archive_status: [0, 1],
            ...selectedTaskCycle
          }
        //   if(!isShowPendingOnboardings) formResBody.sub_status = [0, 2];

          if(selectedTask.formData && selectedTask.formData.response_data_type === "GROUP_COUNT"){
            let response = await ApiEndpoints.JobTask.fetchGroupedTaskResp(formResBody);
            let responseData = response.data.data.reduce((prev, curr) => {prev[curr.status] = curr; return prev;}, {})
            this.setState({taskData : {
                VERIFIED : responseData["VERIFIED"] ? parseInt(responseData["VERIFIED"].response_counts)  : 0,
                PENDING : responseData["PENDING"] ? parseInt(responseData["PENDING"].response_counts)  : 0,
                REJECTED : responseData["REJECTED"] ? parseInt(responseData["REJECTED"].response_counts)  : 0,
              }});
          }
          else{
            let response = await ApiEndpoints.JobTask.fetchTaskResp(formResBody);
            let taskData = response.data.data;
            if(selectedTask.formData.attendance){
                let taskAttendanceResp = await ApiEndpoints.Dashboard.fetchAttendance({user_id, attendance_type : "TASK", ref_id : selectedTask.job_task_id, ...selectedTaskCycle});
                let taskAttendanceData = await groupArrayOnKey(taskAttendanceResp.data.data, "date", true);
                taskData = taskData.map(task => {
                    let taskAttendaceForDate = taskAttendanceData[moment(task.created_on).utcOffset("+05:30").format("YYYY-MM-DD")] || {};
                    task.attendance_time = taskAttendaceForDate.created_on || null;
                    return task;
                })
            }
            taskData = groupArrayOnKey(taskData, "status", false);
            this.setState({taskData : taskData});
          }
        }
        catch(e){
            console.log(e)
          this.setState({taskData : {}})
          store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
        }
      } 
      fetchUserLocation = async () => {
        store.dispatch(setLoader(true))
        let {user} = this.props;
        let {selectedTaskCycle} = this.state;
          try{
            var body = {
                user_id: user.user_id,
                ...selectedTaskCycle
            }
            let resp = await ApiEndpoints.Dashboard.fetchUserLocationData(body);
            let userLocations =  Object.keys(resp.data.data).reduce((prev, curr)=> {
                prev = prev.concat(resp.data.data[curr]);
                return prev;
            } , []);
            this.setState({userLocations});
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
      }
      
    selectTaskCycle = async (cycle) => {
        let { selectedJob } = this.props;
        let dateObj = {};
        if(cycle === "CURR_CYCLE") dateObj = this.props.getCycleDates(selectedJob.pay_cycle_type, selectedJob.pay_cycle_origin, 0);
        if(cycle === "PREV_CYCLE") dateObj = this.props.getCycleDates(selectedJob.pay_cycle_type, selectedJob.pay_cycle_origin, 1);
        if(cycle === "CURR_MONTH") dateObj = {start_date: moment().startOf("month").toDate(), end_date: moment().endOf("month").toDate()}
        if(cycle === "PREV_MONTH") dateObj = {start_date: moment().subtract(1, "month").startOf("month").toDate(), end_date: moment().subtract(1, "month").endOf("month").toDate()}
        await this.setState({selectedTaskCycle : {cycle, ...dateObj}});
        this.fetchTaskCounts();
        this.fetchUserLocation();
        this.fetchUserTask();
        this.setState({selectedFormResponse: []});
    }
    selectTask = async (task) => {
        let { selectedTask} = this.state;
        if(selectedTask === task) return;
        await this.setState({selectedTask : task});
        await this.selectTaskCycle("ALL")
        this.setState({selectedFormResponse: []});

    }
    selectStatus = async (status) => {
        let { selectedStatus} = this.state;
        if(selectedStatus === status) return;
        await this.setState({selectedStatus : status});
        this.setState({selectedFormResponse: [], currentUserPage : 0});
    }

    onSelectFormResponse = (e) => {
        let {selectedFormResponse} = this.state;
        if(e.target.checked) selectedFormResponse.push(parseInt(e.target.value));
        else {
            selectedFormResponse.splice(selectedFormResponse.indexOf(parseInt(e.target.value)), 1)
        }
        this.setState({selectedFormResponse})
    }
    selectRejectionReason = async () => {
        let {selectedJob} = this.props;

          try{
            var body = {
                job_id: selectedJob.parent_job_id || selectedJob.job_id
            }
            let resp = await ApiEndpoints.JobTask.fetchTaggedReason(body)
            this.setState({reasonList: resp.data.data});
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
    }
    formResponseUpdate = async (e, data) => {
        e.preventDefault();
        let {selectedTask, selectedFormResponse} = this.state;
        if(["REJECTED", "REJECTED_AUDIT"].indexOf(data.status) !== -1 && !data.reason)
        return store.dispatch(setError({show: true, message: "Please select Reason, It is mandatory for rejection"}))

        store.dispatch(setLoader(true))
        try {
            var body = {
                form_id: selectedTask.form_id,
                job_task_id: selectedTask.job_task_id,
                status: data.status,
                form_response_id: selectedFormResponse,
                status_comment: data.reason
            }
            if(data.status === "REJECTED_AUDIT"){
                await Promise.all(selectedFormResponse.map(async formRespId => {
                    return await ApiEndpoints.JobTask.rejectAuditRequiredResponse({...body, form_response_id: formRespId});
                }));
            }
            else await ApiEndpoints.JobTask.verifyResponse(body);
            this.setState({isOpenUpdateFormResponse : false})
            await this.selectTaskCycle(this.state.selectedTaskCycle.cycle)
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    playVideo = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            this.setState({videoPlayUrl : fileResp.data.data[0].url})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    showImage = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            this.setState({showImageUrl : fileResp.data.data[0].url})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    showFile = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileResp = await ApiEndpoints.Files.getFileID({file_id: fileId});
            const newWindow = window.open(fileResp.data.data[0].url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    submitAddMissingDetails = async (formElem, isAddMissingDetails, status) => {
        let { taskData } = this.state;
        formElem.preventDefault();
        store.dispatch(setLoader(true));
        let value = formElem.target.input_value.value;
        let body = {
            form_response_id: isAddMissingDetails.form_response_id,
            input_id: isAddMissingDetails.input_id, 
            value
        }
        await ApiEndpoints.JobTask.addResponseInput(body);
        taskData[status] = (taskData[status] || []).map(response => {
            if(response.form_response_id === body.form_response_id){
                response.FormResponseData[body.input_id] = body;
            }
            return response;
        });
        this.setState({ taskData, isAddMissingDetails: false });
        store.dispatch(setLoader(false))

    }


    copyFormResponseShortUrl = async (formResponseId) => {
        store.dispatch(setLoader(true));
        try {
            var resp = await ApiEndpoints.UrlShortener.getLink({
                ref_type: "FORM_RESPONSE",
                ref_id: formResponseId
            });
            if(!resp.data.data[0]) throw new Error("No link found");
            copyCodeToClipboard(resp.data.data[0].url); 
            store.dispatch(setSuccess({show: true, message: "Link Copied to your Clipboard!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }



    connectCustomerCall = async (formResponse) => {
        store.dispatch(setLoader(true));
        let { jobTask } = this.props;
        try{
          if(this.state.callCustomerDetails){
              store.dispatch(setError({show: true, message: "You are already on another call."}))
              store.dispatch(setLoader(false));
              return;
          }
          var body = {
              customer_lead_id : formResponse.customer_lead_id,
              entity_type: "JOB",
              entity_id: jobTask.job_id,
              subentity_type: "FORM_RESPONSE",
              subentity_id: formResponse.form_response_id,
              direction: 2
          }
          let resp = await ApiEndpoints.Calling.connectOutboundCustomerCall(body);
          
          this.setState({callCustomerDetails: { calling_id : resp.data.data.Sid, customerData: resp.data.data.customerData, formResponse }});
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }




    render() {
        let { selectedStatus , selectedTaskCycle , selectedTask, taskCounts, userLocations, taskData, hoveredTaskId, showMap, isOpenUpdateFormResponse, selectedFormResponse, reasonList, isAddMissingDetails} = this.state;
        let {taskList} = this.props;

        let taskReasons = reasonList.reduce((prev, curr) => {
            if(curr.reason_text && curr.reason) prev[curr.reason.toLowerCase()] = curr.reason_text;
            return prev;
        }, {}); 

        var tasks = Object.keys(taskData).reduce((prev, status) => {
            var statusTask = taskData[status] || [];
            statusTask = statusTask.reduce((prev, task) => {
                prev[task.form_response_id] = task;
                return prev;
            }, {})
            prev = {...prev, ...statusTask};
            return prev;
        }, [])
        userLocations = userLocations.map(loc => {
            if(loc.location_type == "ATTENDANCE") {
                loc.marker_title = "Attendance Location"
                loc.marker_image = "pin_grey.svg"
            }
            else if(loc.location_type === "TASK"){
                loc.task_status = tasks[loc.ref_id] ? tasks[loc.ref_id].status : "REJECTED";
                loc.marker_title = "Task Location"
                if(loc.task_status === "PENDING") loc.marker_image = "pin_yellow.svg"
                if(loc.task_status === "VERIFIED") loc.marker_image = "pin_green.svg"
                if(loc.task_status === "REJECTED") loc.marker_image = "pin_red.svg";
            }
            return loc;
        })
        if(selectedStatus !== "ALL"){
            userLocations = userLocations.filter(loc => (loc.location_type === "TASK" && loc.task_status === selectedStatus) || loc.location_type !== "TASK")
        }
        if(hoveredTaskId){
            userLocations = userLocations.filter(loc => (loc.location_type === "TASK" && loc.ref_id === hoveredTaskId) || loc.location_type !== "TASK")
        }
        let taskDataByStatus = [];
        if(selectedStatus !== "ALL"){
            taskDataByStatus = taskData[selectedStatus] || [];
        } else{
            taskDataByStatus = Object.keys(taskData).reduce((prev, status) => {
                prev = prev.concat(taskData[status]);
                return prev;
            }, [])
        } 
        return (
            <Aux>
                <Button size="sm" color="link" sizze style={{position:"absolute", top:5, right: 25}} onClick={() => this.setState({showMap: !showMap})}>{showMap ? "Hide": "Show"} Map</Button>
                <Row noGutters className="mt-3">
                    <Col xs={showMap ? 6 : 12} style={{ transition:"ease-in-out 0.5s"}}>
                        <Row className="mt-1">
                            <Col md={1} xs={12}><h6 className="mt-1 mr-1">Task</h6></Col>
                            <Col>
                            {taskList.length ?
                            taskList.map(task =>
                                <Chip key={task.job_task_id} size="small" label={task.task_title} onClick={() => this.selectTask(task)}   style={{color : "white", background : selectedTask.job_task_id === task.job_task_id ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                )
                                :
                                <p className="pt-1"><em className="text-muted" style={{fontSize: 14}}>No Task Added</em></p>
                            }
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="" style={{fontSize: 12}}>
                                <strong className="mr-2">Cycle</strong>
                                <Chip size="small" label="All" onClick={() => this.selectTaskCycle("ALL")}   style={{color : "white", background : selectedTaskCycle.cycle === "ALL" ? "#f1061c" : "#aaa"}} clickable className=""/>
                                <Chip size="small" label="Curr Month" onClick={() => this.selectTaskCycle("CURR_MONTH")}  style={{color : "white", background : selectedTaskCycle.cycle === "CURR_MONTH" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                <Chip size="small" label="Prev Month" onClick={() => this.selectTaskCycle("PREV_MONTH")}  style={{color : "white", background : selectedTaskCycle.cycle === "PREV_MONTH" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                {/* <Chip size="small" label="Last Cycle" onClick={() => this.selectTaskCycle("PREV_CYCLE")}  style={{color : "white", background : selectedTaskCycle.cycle === "PREV_CYCLE" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                <Chip size="small" label="Current Cycle" onClick={() => this.selectTaskCycle("CURR_CYCLE")}  style={{color : "white", background : selectedTaskCycle.cycle === "CURR_CYCLE" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/> */}
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-2 mr-3">
                            <Col className="">
                                <strong className="mr-2">Status</strong>
                                <Chip size="small" label={`${(taskCounts.PENDING || 0)+(taskCounts.REJECTED || 0)+(taskCounts.VERIFIED || 0) + (taskCounts.PENDING_PARTIAL || 0) + (taskCounts.archived_count || 0)} All`} onClick={() => this.selectStatus("ALL")}   style={{color : "white", background : selectedStatus === "ALL" ? "#f1061c" : "#aaa"}} clickable className=""/>
                                <Chip size="small" label={`${(taskCounts.PENDING || 0) + (taskCounts.PENDING_PARTIAL || 0)} Pending`} onClick={() => this.selectStatus("PENDING")}   style={{color : "white", background : selectedStatus === "PENDING" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                <Chip size="small" label={`${taskCounts.REJECTED || 0} Rejected`} onClick={() => this.selectStatus("REJECTED")}  style={{color : "white", background : selectedStatus === "REJECTED" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                                <Chip size="small" label={`${taskCounts.VERIFIED || 0} Verified`} onClick={() => this.selectStatus("VERIFIED")}  style={{color : "white", background : selectedStatus === "VERIFIED" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                            </Col>
                        </Row>
                        {!!selectedFormResponse.length && !!(store.getState().user.UserRole.filter(role => [1, 3, 4].indexOf(role.role_id) !== -1).length) &&
                        <Row className="mt-2 mb-2 mr-3">
                            <Col md={1} xs={12}><h6 className="mt-1">Action</h6></Col>
                            <Col className="">
                            {["PENDING", "REJECTED"].indexOf(selectedStatus) !== -1 && <Button onClick={() => this.setState({isOpenUpdateFormResponse: {status: "VERIFIED"}})} size="sm" color="danger" className="py-0">Verify</Button>}
                            {["PENDING", "VERIFIED"].indexOf(selectedStatus) !== -1 && <Button onClick={() => this.setState({isOpenUpdateFormResponse: {status: "REJECTED"}})} size="sm" color="danger" className="ml-2 py-0">Reject</Button>}
                            {/* {["PENDING", "VERIFIED"].indexOf(selectedStatus) !== -1 && <Button onClick={() => this.setState({isOpenUpdateFormResponse: {status: "REJECTED_AUDIT"}})} size="sm" color="danger" className="ml-2 py-0">Reject with Audit requred</Button>} */}
                            </Col>
                        </Row>
                        }
                        <div className="table-responsive" style={{minHeight: 370}}>
                        {selectedTask.formData.response_data_type === "GROUP_COUNT" ?
                        <Table size="sm" striped className="table-bordered text-center" >
                            <thead>
                                <tr>
                                    <th>Counts</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={2}><em>No Data</em></td>
                                </tr>
                            </tbody>
                        </Table>
                        :
                        <Table size="sm" striped className="table-bordered text-center" >
                            <thead className="sticky-top" style={{fontSize: 14, fontWeight: "bold"}}>
                            <tr>
                              {/* <td className="align-middle"><Checkbox color="primary" size="small" checked={selectedFormResponse.length && taskDataByStatus.length === selectedFormResponse.length} onChange={(e) => this.setState({selectedFormResponse: e.target.checked ? taskDataByStatus.map(res => res.form_response_id) : []})} /></td> */}
                              <td className="align-middle">REF Id</td>
                              <td style={{minWidth: 100}}>Task Time</td>
                              <td style={{minWidth: 100}}>Modified</td>
                              <td>Status Comment</td>
                                {
                                    !!(selectedTask.formData && selectedTask.formData.response_status && selectedTask.formData.response_status.status2) &&
                                    <td>Activation Status</td>
                                }
                              {selectedTask.formData.attendance && <td style={{minWidth: 150}}>{selectedTask.formData.attendance.button_label}</td>  }
                              {
                                selectedTask.formData && selectedTask.formData.input && selectedTask.formData.input.sections.map(section => 
                                    section.input_groups.map(inputGroup => 
                                    inputGroup.inputs.map(input => 
                                    <td key={input.input_id} style={{minWidth: 100}}>{input.label}</td>
                                    )
                                    )
                                )
                              }
                                {!!selectedTask.formData.audit && 
                                    <>
                                    <td>Audit Status</td>
                                    {selectedTask.formData.audit.inputs.map(input => 
                                        <td>{input.label}</td>
                                    )}
                                    </>
                                }
                            </tr>
                            </thead>
                            <tbody style={{fontSize: 12}}>
                                {
                                taskDataByStatus.length ? 
                                taskDataByStatus.slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage).map(response => 
                                 <tr key={response.form_response_id} onMouseEnter={() => this.setState({hoveredTaskId : response.form_response_id})} onMouseOut={() => this.setState({hoveredTaskId : false})} style={{...(hoveredTaskId == response.form_response_id ? {backgroundColor:"#00000066"}:{})}}>
                                    {/* <td className="align-middle"><Checkbox  color="primary" size="small" value={response.form_response_id} checked={selectedFormResponse.indexOf(response.form_response_id) !== -1} onChange={this.onSelectFormResponse} /></td> */}
                                    <td className="align-middle visible-on-hover-container">
                                        REF{response.form_response_id}
                                        {!!response.customer_lead_id && <Icon className="visible-on-hover-item align-middle clickable mr-2" style={{fontSize: 17}} onClick={() => this.setState({isConfirm: () => this.connectCustomerCall(response), isConfirmMessage : "Are you sure to connect with customer?"})}>call</Icon>}
                                        <Icon className="visible-on-hover-item align-middle clickable" style={{fontSize: 20}} onClick={() => this.copyFormResponseShortUrl(response.form_response_id)}>link</Icon>
                                    </td>
                                    <td>
                                        <span className="d-block">{moment(response.created_on).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a")}</span>
                                        {!!response.archive_status && <span className="bg-danger text-light small rounded px-2 py-1 font-weight-bold">Expired</span>}
                                    </td>
                                    <td>
                                        {response.modified_on !== response.created_on && <span className="d-block">{moment(response.modified_on).utcOffset("+05:30").format("DD-MM-YYYY hh:mm a")}</span>}
                                    </td>
                                    <td>
                                        <span className="d-block">{response.status_comment || "-"}</span>
                                        {!!response.status_comment && !!taskReasons[response.status_comment.toLowerCase()] && <span className="small d-block">{taskReasons[response.status_comment.toLowerCase()]}</span>}
                                        {!!response.remark && <span className="small d-block">({response.remark})</span>}
                                        {/* {response.sub_status == "1" && <span className="text-danger small font-weight-bold">Partial Onboarding</span>} */}
                                    </td>
                                    {
                                    !!(selectedTask.formData && selectedTask.formData.response_status && selectedTask.formData.response_status.status2) &&
                                        <td>{response.status2 || ""}</td>
                                    }

                                    {selectedTask.formData.attendance && <td>{response.attendance_time && moment(response.attendance_time).utcOffset("+05:30").format("hh:mm a")}</td>}

                                    {
                                        selectedTask.formData && selectedTask.formData.input.sections.map(section => 
                                            section.input_groups.map(inputGroup => 
                                            inputGroup.inputs.map(input => {
                                                return (
                                                    <td className="visible-on-hover-container">{response.FormResponseData[input.input_id] ? 
                                                        (response.FormResponseData[input.input_id].value ? 
                                                        <>
                                                        {["VIDEO", "IMAGE", "FILE"].indexOf(input.input_type) === -1 ? 
                                                            response.FormResponseData[input.input_id].value
                                                            :
                                                            <>
                                                                {input.input_type === "VIDEO" && <Button onClick={() => this.playVideo(response.FormResponseData[input.input_id].value)} color="link" size="sm">Play Video</Button>}
                                                                {input.input_type === "IMAGE" && <Button onClick={() => this.showImage(response.FormResponseData[input.input_id].value)} color="link" size="sm">Image</Button>}
                                                                {input.input_type === "FILE" && <Button onClick={() => this.showFile(response.FormResponseData[input.input_id].value)} color="link" size="sm">File</Button>}
                                                            </>
                                                        }
                                                        </> 
                                                        : 
                                                        "-")
                                                        : 
                                                        (
                                                            ["VIDEO", "IMAGE", "FILE"].indexOf(input.input_type) === -1 && <>
                                                                {isAddMissingDetails && isAddMissingDetails.form_response_id === response.form_response_id && isAddMissingDetails.input_id === input.input_id ?
                                                                <form onSubmit={(e) => this.submitAddMissingDetails(e, isAddMissingDetails, response.status)}><input name="input_value" placeholder="Enter Value" /></form>
                                                                :
                                                                <Icon className="clickable visible-on-hover-item" onClick={() => this.setState({ isAddMissingDetails: { form_response_id: response.form_response_id, input_id: input.input_id } })}>add</Icon>
                                                                }
                                                            </>
                                                        )
                                                        }
                                                     </td>
                                                )}
                                            )
                                            )
                                        )
                                    }
                                    {!!selectedTask.formData.audit && <>
                                    <td>{CONSTANTS.AUDIT_STATUS[response.audit_status]}</td>
                                    {selectedTask.formData.audit.inputs.map(input => {
                                        return (
                                            <td>{response.FormResponseData[input.input_id] && response.FormResponseData[input.input_id].value ? (
                                                input.input_type === "VIDEO" ? <Button onClick={() => this.playVideo(response.FormResponseData[input.input_id].value)} color="link" size="sm">Play Video</Button> : response.FormResponseData[input.input_id].value
                                             ) : "-"}</td>
                                        )
                                    })}
                                    </>
                                    }
                                  </tr>
                                )
                                : 
                                <tr><td colSpan="10"><em>No Data</em></td></tr>
                                }
                            </tbody>
                          </Table>
                        }
                        
                        </div>
                        <TablePagination rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={taskDataByStatus.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                            onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                            onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                        />
                    </Col>
                    <Col xs={showMap ? 6 : 12} style={{minHeight: 500, ...(showMap? {}:{maxWidth: 0}), transition:"ease-in-out 0.5s"}}>
                        <div className="p-3">
                            <GoogleMap markers={userLocations} />
                        </div>
                    </Col>
                </Row>
                <Modal isOpen={!!isOpenUpdateFormResponse} toggle={() => this.setState({isOpenUpdateFormResponse : false})}>
                    <ModalHeader toggle={() => this.setState({isOpenUpdateFormResponse : false})}>Update Task Response</ModalHeader>
                    <form onSubmit={(e) => this.formResponseUpdate(e, isOpenUpdateFormResponse)}>
                    <ModalBody>
                        {/* <textarea required={isOpenUpdateFormResponse === "REJECTED"} name="comment" rows={5} className="form-control" />
                        {isOpenUpdateFormResponse === "REJECTED_AUDIT"  && <em className="text-danger my-2">*Panelty will be deduct from partner wallet</em>}
                    */}
                    <Row>
                        <Col>
                            <SelectList name="reason" required={isOpenUpdateFormResponse.status !== "VERIFIED"} placeholder="Select Reason" value={isOpenUpdateFormResponse.reason} onChange={(e) => {isOpenUpdateFormResponse.reason = e.target.value; this.setState({isOpenUpdateFormResponse})}}  >
                                {reasonList.map((obj, i) => 
                                <option key={i} value={obj.reason}> 
                                    {obj.reason} 
                                </option>
                                )}
                            </SelectList>
                        </Col>
                    </Row>
                    </ModalBody>

                    <ModalFooter>
                        <em className="text-muted">{selectedFormResponse.length} Task{selectedFormResponse.length > 1 && "s"} selected</em>{' '}
                        <Button size="sm" color="danger">
                            {isOpenUpdateFormResponse.status === "VERIFIED" && "Verify"}
                            {isOpenUpdateFormResponse.status === "REJECTED" && "Reject"}
                            {isOpenUpdateFormResponse.status === "REJECTED_AUDIT" && "Reject with Audit Required"}
                        </Button>
                        <Button size="sm" className="ml-3" onClick={() => this.setState({isOpenUpdateFormResponse : false})}>Cancel</Button>
                    </ModalFooter>
                    </form>
                </Modal>
                <Modal centered isOpen={!!this.state.videoPlayUrl} toggle={() => this.setState({videoPlayUrl: null})}>
                    <ModalBody className="text-center">
                        <video autoPlay={true} controls={true} width={320} src={this.state.videoPlayUrl} />
                    </ModalBody>
                </Modal>
                <Modal centered isOpen={!!this.state.showImageUrl} toggle={() => this.setState({showImageUrl: null})}>
                    <ModalBody className="text-center">
                        <img alt="" width={360} src={this.state.showImageUrl} />
                    </ModalBody>
                </Modal>
                <ConfirmAction message={this.state.isConfirmMessage} isOpen={!!this.state.isConfirm} onClose={() => this.setState({isConfirm : false})} callback={this.state.isConfirm} />

                {!!this.state.callCustomerDetails && <OutboundCustomerCallingStatus customerData={this.state.callCustomerDetails.customerData || {}} calling_id={this.state.callCustomerDetails.calling_id} onClose={() => this.setState({callCustomerDetails : null})} />}


            </Aux>
            
            
        );
    }
}
export default PartnerMapDetail;
