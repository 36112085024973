import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Table  } from "reactstrap/lib";
import Input from "reactstrap/lib/Input";
import { Button, Collapse, Modal, ModalHeader, ModalBody, ModalFooter, Label, Card, CardBody } from 'reactstrap';
import InputFeild from "../../global/form/input"
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import { Chip, Icon, TablePagination} from "@material-ui/core";
import Aux from "../../global/AuxComp";
import SelectList from "../../global/form/select";
import ConfirmAction from "../../global/confirm-action";
import moment from "moment";
import CONSTANTS from "../../../constants";

const notificationTime = {
    "02:30:00" : "8 AM",
    "03:30:00" : "9 AM",
    "04:30:00" : "10 AM",
    "05:30:00" : "11 AM",
    "06:30:00" : "12 PM",
    "07:30:00" : "1 PM",
    "08:30:00" : "2 PM",
    "09:30:00" : "3 PM",
    "10:30:00" : "4 PM",
    "11:30:00" : "5 PM",
    "12:30:00" : "6 PM",
    "13:30:00" : "7 PM",
    "14:30:00" : "8 PM",
    "15:30:00" : "9 PM",
    "16:30:00" : "10 PM"
}

class ScheduleNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAdd : false,
            scheduledNotifications : [],
            selectedTime : "ALL",
            rowsPerPage : 10,
            currentUserPage : 0,
            isConfirm : null,
            editScheduled : {},
            originType: "GENERAL",
            isSms : false,
            message : "",
            smsTemplateId : null
        }
    }

    componentDidMount(){
        this.fetchScheduledNotifications();
    }

    fetchScheduledNotifications = async () => {
        store.dispatch(setLoader(true))
        try{
            var selectedTime = this.state.selectedTime !== "ALL" ? this.state.selectedTime : Object.keys(notificationTime);
            let body = { time : selectedTime };
            if(this.props.fetchFilter) body = {...body, ...this.props.fetchFilter}
            let apiData = await ApiEndpoints.Notification.fetchScheduled(body);
            let scheduledNotifications = apiData.data.data;
            scheduledNotifications = this.calculateNotificationFilter(scheduledNotifications);
            await this.setState({ scheduledNotifications})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
   


    fetchJobTaskForNotification = async (job_id) => {
        store.dispatch(setLoader(true));
        let { isAdd } = this.state;
        try{
            let apiData = await ApiEndpoints.JobTask.fetch({job_id});
            isAdd.taskData = apiData.data.data;
            await this.setState({ isAdd });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addScheduleNotification = async (e) => {
        e.preventDefault();
        e.persist();
        store.dispatch(setLoader(true))
        let { isAdd } = this.state;
        let { filters } = this.props;
        try{
            if(!isAdd.time) throw new Error("Please select Time");
            if(!isAdd.days) throw new Error("Please select Days");
            if(!isAdd.originType) throw new Error("Please select screen redirection");
            // if(e.target.sms.checked && !this.state.smsTemplateId) throw new Error("Please select SMS Template");
            // var origin_type = "GENERAL";
            // var origin_id = null;
            // var suborigin_type = null;
            // if(this.state.originType === "JOB_TASK"){
            //     origin_type = "JOB"; suborigin_type="JOB_TASK";
            // }else origin_type = this.state.originType;
            // if(["JOB", "JOB_TASK", "WALLET_TRANSACTION", "LEADERBOARD"].indexOf(this.state.originType) > -1) origin_id = filters.job_id;
            delete isAdd.taskData;
            var body = {
                ...isAdd,
                filter : filters.filters && filters.filters.length ? "1" : "0",
                filter_type : filters.filters && filters.filters[0],
                filter_param : "application_status",
                filter_id : filters.application_status,
                origin_type : filters.job_id ? "JOB" : "GENERAL",
                origin_id : filters.job_id,
                template_id: this.state.smsTemplateId,
                redirection_screen: isAdd.originType
            }

            if(["STAGES", "JOB_TASK", "TASK_LEADERBOARD"].indexOf(isAdd.originType) !== -1){
                if(!isAdd.redirection_entity_id) throw new Error("Select Project for redirection");
                body.redirection_entity_type = "JOB";
                body.redirection_entity_id = isAdd.redirection_entity_id;
            }

            if(["JOB_TASK", "TASK_LEADERBOARD"].indexOf(isAdd.originType) !== -1){
                if(!isAdd.redirection_sub_entity_id) throw new Error("Select Project Task for redirection");
                body.redirection_sub_entity_type = "JOB_TASK";
                body.redirection_sub_entity_id = isAdd.redirection_sub_entity_id;
            }
            if(filters.job_location_id){
                body.suborigin_type = "JOB_LOCATION";
                body.suborigin_id = filters.job_location_id;
            }
            await ApiEndpoints.Notification.addScheduled(body);
            e.target.reset();
            await this.setState({selectedTime : body.time, isAdd : false});
            await this.fetchScheduledNotifications();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    deleteScheduleNotification = async (schedule_notification_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                schedule_notification_id
            }
            await ApiEndpoints.Notification.deleteScheduled(body);
            await this.fetchScheduledNotifications();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeAdd = (k, v) => {
        let {isAdd} = this.state;
        isAdd[k] = v;
        this.setState({isAdd});
    }
    onChangeEdit = (k, v) => {
        let {editScheduled} = this.state;
        editScheduled[k] = v;
        this.setState({editScheduled});
    }

    calculateNotificationFilter = (notifications) => {
        let { jobList, filterList } = this.props;
        notifications = notifications.map(notification => {
            notification.formattedFilter = {};
            if(notification.filter === '0') notification.formattedFilter["All"] = "All";
            else {
                if(notification.origin_type === "JOB"){
                    var jobTitle = jobList.filter(job => notification.origin_id.includes(job.job_id)).map(job => job.Employer.employer_name +  "  -  "  + job.title).join(", ") || "Not Found";
                    notification.formattedFilter["Job"] = jobTitle;
                }
                if(notification.filter_id){
                    var appliedFilter = filterList.filter(filter => filter.params && filter.params.application_status == notification.filter_id)[0];
                    notification.formattedFilter["Status"] = (appliedFilter && appliedFilter.name);
                }
            }
            return notification;
        })
        return notifications;
        // return status.map(value => <em style={{fontSize: 12, display: "block"}}>{value}</em>);
    }

    editScheduledNotification = async (e) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        let { editScheduled } = this.state;
        try{
            // editScheduled.is_sms =  e.target.sms.checked;
            await ApiEndpoints.Notification.editScheduled(editScheduled);
            await this.setState({ editScheduled : {}});
            await this.fetchScheduledNotifications();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { isAdd, scheduledNotifications, isConfirm , editScheduled} = this.state;
        scheduledNotifications = scheduledNotifications.sort((a, b)  => a.formattedFilter.Job > b.formattedFilter.Job);
        let { filterList, jobList } = this.props;
        return (
            <Aux>
                <Button onClick={() => this.setState({isAdd : isAdd ? false : {}})} className="buttonred" size="sm">Add</Button>
                <Collapse isOpen={isAdd}>
                    <Card>
                        <CardBody>
                            <form onSubmit={this.addScheduleNotification}>
                            <Row>
                                <Col>
                                    <SelectList multiple="checkbox" placeholder="Select Time" value={isAdd.time || []} onChange={(e) => this.onChangeAdd("time", e.target.value)}>
                                        {Object.keys(notificationTime).map(time => 
                                            <option value={time}>{notificationTime[time]}</option>
                                        )}
                                    </SelectList>
                                </Col>
                                <Col>
                                    <SelectList multiple="checkbox" placeholder="Select Days" value={isAdd.days ? isAdd.days.split(";;"): []} onChange={(e) => this.onChangeAdd("days", e.target.value.join(";;"))}>
                                        {["1","2","3","4","5","6"].map(dayIndex => 
                                            <option value={dayIndex}>{moment().day(dayIndex).format("dddd")}</option>
                                        )}
                                    </SelectList>
                                </Col>
                                {/* <Col>
                                    <label className="radio-inline ml-3 mt-3"><input name="sms" type="checkbox" checked={this.state.isSms} onChange={e => this.setState({isSms : e.target.checked})}  /> Notify with SMS</label>
                                </Col> */}
                            </Row>
                            {this.state.isSms && <SelectList placeholder="Select SMS Template" value={this.state.smsTemplateId} onChange ={(e) => {this.setState({smsTemplateId : e.target.value}); this.onChangeAdd("message", this.props.templates.filter((t) => t.template_id == e.target.value)[0].content)}} >
                                {
                                    this.props.templates.map(template => 
                                        <option value={template.template_id}>{template.slug_name}</option>
                                    )
                                }    
                            </SelectList>}
                            <InputFeild className="mt-3" required placeholder="Title" value={isAdd.title} onChange={(e) => this.onChangeAdd("title", e.target.value)} />
                            <textarea rows={5} required placeholder="Message" className="form-control mt-3" type="text" onChange={(e) => this.onChangeAdd("message", e.target.value)} />
                            
                            
                            
                            <label className="mt-2">Select Notification Redirection Screen: </label><br />
                            {CONSTANTS.NOTIFICATION_ORIGIN.map(origin => 
                                <Chip size="small" label={origin.title} onClick={(e) => {isAdd.originType = origin.id; this.setState({isAdd})}} style={{background: isAdd.originType === origin.id ? "#999": "#ddd"}} clickable className="mr-2"/>
                            )}
                            <Row className="mt-2">
                                {["STAGES", "JOB_TASK", "TASK_LEADERBOARD"].indexOf(isAdd.originType) !== -1 && <Col>
                                    <SelectList search={true} placeholder="Select Project" value={isAdd.redirection_entity_id || ""} onChange ={(e) => { isAdd.redirection_entity_id = e.target.value; isAdd.redirection_sub_entity_id = null; this.setState({ isAdd }); this.fetchJobTaskForNotification(e.target.value) } } >
                                    { 
                                        jobList.map(job => 
                                        <option key={job.job_id} value={job.job_id}> 
                                            {job.Employer.employer_name +  "  -  "  + job.title} 
                                            {job.archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.archive_status]} /> }
                                        </option>
                                        )
                                    }
                                    </SelectList>
                                </Col>}
                                {["JOB_TASK", "TASK_LEADERBOARD"].indexOf(isAdd.originType) !== -1 && <Col>
                                    <SelectList search={true} placeholder="Select Task" value={isAdd.redirection_sub_entity_id || ""} onChange ={(e) => { isAdd.redirection_sub_entity_id = e.target.value; this.setState({ isAdd }); } }  >
                                        {isAdd && isAdd.taskData && isAdd.taskData.map(task => 
                                            <option key={task.job_task_id} value={task.job_task_id}>{task.task_title}</option>
                                        )}
                                    </SelectList>
                                </Col>}
                            </Row>
                            
                            
                            
                            {/* <label className="mt-2">Select Notification reference: </label><br />
                            {this.props.filters.job_id && CONSTANTS.NOTIFICATION_ORIGIN.map(origin => 
                                <Chip size="small" label={origin.title} onClick={(e) => this.setState({originType : origin.id})} style={{background: this.state.originType === origin.id ? "#999": "#ddd"}} clickable className="mr-2"/>
                            )} */}
                            {this.state.isSms && <span className="mt-3" style={{fontSize: 12}}><b>Example:</b> {this.state.smsTemplateId && this.props.templates.filter((t) => t.template_id == this.state.smsTemplateId)[0].example}</span>}
                            <br />
                            <Row className="mt-2">
                                <Col className="text-right">
                                    <Button type="submit" size="sm" variant="contained" className="ml-3" >Add</Button>{' '}
                                    <Button type="button" size="sm" variant="outlined"  onClick={() => this.setState({isAdd : false})}>Cancel</Button>
                                </Col>
                            </Row>
                            </form>
                        </CardBody>
                    </Card>
                </Collapse>
                <Row>
                    <Col></Col>
                    <Col>
                        <SelectList placeholder="Select Time" value={this.state.selectedTime} onChange={async (e) => {await this.setState({selectedTime : e.target.value, currentUserPage: 0}); this.fetchScheduledNotifications() }}>
                            {Object.keys({"ALL": "ALL", ...notificationTime}).map(time => 
                                <option value={time}>{time === "ALL" ? "All" : notificationTime[time]}</option>
                            )}
                        </SelectList>
                    </Col>
                </Row>
                <Row className="border-bottom font-weight-bold mt-2">
                    <Col xs={1}>#</Col>
                    <Col xs={2}>Title</Col>
                    <Col xs={5}>Message</Col>
                    <Col xs={3}>Filters</Col>
                    <Col xs={1}></Col>
                </Row>
                {
                    scheduledNotifications.slice(this.state.currentUserPage*this.state.rowsPerPage, this.state.currentUserPage*this.state.rowsPerPage+this.state.rowsPerPage).map((notification, i) =>
                     editScheduled  &&  editScheduled.schedule_notification_id !== notification.schedule_notification_id ?
                       <Row className="border-bottom py-1" style={{fontSize : 14}}>
                            <Col xs={1}>{(this.state.currentUserPage*this.state.rowsPerPage+i+1)}</Col>
                            <Col xs={2}>{notification.title}</Col>
                            <Col xs={5}>{notification.message}</Col>
                            <Col xs={3}>
                                {Object.keys(notification.formattedFilter).map(filter => <em style={{fontSize: 12, display: "block"}}>{filter}: {notification.formattedFilter[filter]}</em>)}
                                <em style={{fontSize: 12, display: "block"}}>Days:  
                                <em className="ml-1">
                                {
                                    (notification.days ? notification.days.split(";;"):[]).map(dayIndex => 
                                        moment().day(dayIndex).format("ddd")
                                    ).toString()
                                }
                                </em>
                                </em>
                                <em style={{fontSize: 12, display: "block"}}>Time: 
                                   <em className="ml-1">{moment(notification.time, "HH:mm:ss").add(5, 'hours').add(30, 'minutes').format("hh A")}</em>
                                </em>
                                {/* <em style={{fontSize: 12, display: "block"}}>SMS: {!!notification.is_sms ? "Yes": "No"} </em> */}
                            </Col>
                            <Col xs={1}>
                            <Icon onClick={() => this.setState({editScheduled : notification })}  style={{fontSize: 16, cursor : "pointer"}}>edit</Icon>
                            <Icon className="" onClick={() => this.setState({isConfirm : () => this.deleteScheduleNotification(notification.schedule_notification_id)})} style={{fontSize: 16, cursor : "pointer"}}>delete</Icon>
                                
                            </Col>
                        </Row> 
                            :
                        <form onSubmit={this.editScheduledNotification}>
                        <Row key={i} className="border-bottom py-1">
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={2}> 
                            <InputFeild className="mt-2" required placeholder="Title" value={editScheduled.title} onChange={(e) => this.onChangeEdit("title", e.target.value)} />
                        </Col>
                        <Col xs={5}>
                            <textarea rows={3} value={editScheduled.message} required placeholder="Message" className="form-control mt-2" type="text" onChange={(e) => this.onChangeEdit("message", e.target.value)} />
                        </Col>
                        <Col xs={3}>
                        {Object.keys(notification.formattedFilter).map(filter => <em style={{fontSize: 12, display: "block"}}>{filter}: {notification.formattedFilter[filter]}</em>)}
                                 <SelectList multiple="checkbox" placeholder="Select Days" value={editScheduled.days ? editScheduled.days.split(";;"): []} onChange={(e) => this.onChangeEdit("days", e.target.value.join(";;"))}>
                                    {["1","2","3","4","5","6"].map(dayIndex => 
                                        <option value={dayIndex}>{moment().day(dayIndex).format("dddd")}</option>
                                    )}
                                </SelectList>
                                <SelectList placeholder="Select Time" value={editScheduled.time} onChange={(e) => this.onChangeEdit("time", e.target.value)}>
                                    {Object.keys(notificationTime).map(time => 
                                        <option value={time}>{notificationTime[time]}</option>
                                    )}
                                </SelectList>
                                <label className="radio-inline mt-3"><input type="checkbox" name="sms" defaultChecked={!!notification.is_sms} /> Notify with SMS</label>
                        </Col>
                        <Col xs={1}>
                            <div style={{marginTop: "7px"}}>
                                <Button color="link" type="submit"><Icon className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon></Button>
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({editScheduled : {}})}>close</Icon>
                            </div>
                        </Col>
                        </Row>
                        </form>
                    )
                }
                <TablePagination rowsPerPageOptions={[10, 20, 50, 100, 200]} component="div" count={scheduledNotifications.length} rowsPerPage={this.state.rowsPerPage} page={this.state.currentUserPage}
                    onChangePage={ (e, page) => this.setState({currentUserPage : page}) }
                    onChangeRowsPerPage={ (e) =>  this.setState({rowsPerPage : e.target.value})}
                />
                <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />

            </Aux>
            
            
        );
    }
}
export default ScheduleNotifications;
