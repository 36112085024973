
import React, { Component }from "react";
import  { Row, Col } from "reactstrap/lib";
import { Icon, Chip, TablePagination, MenuItem, Menu, ButtonGroup, Button, Switch } from "@material-ui/core";
import store from "../../../store";
import { setLoader, setError } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import moment from "moment";
import { groupArrayOnKey } from "../../../actions";
import DatePicker from "react-datepicker";
import InputFeild from "../../global/form/input";
import Modal from "reactstrap/lib/Modal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalBody from "reactstrap/lib/ModalBody";
import UserCard from "../user_profile/user_card"
// import DUMMY_PROFILE_IMG from "../../../assets/images/dummy_profile.jpg";
import SelectList from "../../global/form/select";
import WhatsappChat from "../../global/whatsapp_chat";
import WhatsappIcon from "../../../assets/images/icons8-whatsapp.svg"



class ActivePartners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDateRange: ["MONTH", moment().startOf("month").toDate(), moment().endOf("month").toDate()],
            attendanceCounts : {},
            taskCounts : {},
            attendanceUserList: [],
            selectedAttendanceType : "",
            sortAttendance : {},
            attendaceUserSearchText: "",
            attendanceRowsPerPage : 20,
            attendanceCurrentPage : 0,
            isSelectCustomDate: false,
            newActivatedPartners: [],
            EarnedPartners: [],
            appliedFilter: null,
            selectedTaskId: null,
            whatsappChat: false,
            isShowPendingOnboardings: "2" //Show default completed onboarding 

        }
    }

    async componentDidMount(){
        this.selectDateRange("MONTH");
    }
    
    selectDateRange = async (type, dateRangeData) => {
        var dateRange = [type];
        if(type === "TODAY") dateRange.push(moment().startOf("day").toDate(), moment().endOf("day").toDate());
        if(type === "YESTERDAY") dateRange.push(moment().subtract(1, "day").startOf("day").toDate(), moment().subtract(1, "day").endOf("day").toDate());
        if(type === "WEEK") dateRange.push(moment().startOf("isoWeek").toDate(), moment().endOf("isoWeek").toDate());
        if(type === "PREV_WEEK") dateRange.push(moment().subtract(7, "days").startOf("isoWeek").toDate(), moment().subtract(7, "days").endOf("isoWeek").toDate());
        if(type === "MONTH") dateRange.push(moment().startOf("month").toDate(), moment().endOf("month").toDate());
        if(type === "PREV_MONTH") dateRange.push(moment().subtract(1, "month").startOf("month").toDate(), moment().subtract(1, "month").endOf("month").toDate());
        if(type === "CUSTOM") dateRange.push(moment(dateRangeData[0]).startOf("day").toDate(), moment(dateRangeData[1]).endOf("day").toDate());
        await this.setState({selectedDateRange: dateRange, isSelectCustomDate: false});
        await this.getAttendanceCount();
        this.fetchAttendanceByUser("PRESENT");
        // this.onChangeActiveInactive("PRESENT")
    }



    getAttendanceCount = async () => {
        store.dispatch(setLoader(true))
        let {selectedJobId, selectedJobLocationId, taskList, selectedJobLocations } = this.props;
        let { selectedDateRange, selectedTaskId, isShowPendingOnboardings } = this.state;
        try{
            var body = {
                ref_id : selectedJobId,
                attendance_type : "JOB",
                job_id : selectedJobId,
                job_location_id : selectedJobLocationId,
                start_date: selectedDateRange[1],
                end_date: selectedDateRange[2],
                archive_status: [0, 1]
            }
            // if(isShowPendingOnboardings) body.sub_status = isShowPendingOnboardings === "2" ? [0, 2] : isShowPendingOnboardings;
            if(selectedTaskId) body.form_id = selectedTaskId;
            if(this.props.selectedTlId) body.user_role_id= this.props.selectedTlId;
            let resp = await ApiEndpoints.Dashboard.fetchCount(body);
            await this.setState({attendanceCounts : resp.data.data[0] || {}});
            if(taskList.length){
                var countBody = {
                    job_id: selectedJobId,
                    form_id : selectedTaskId || taskList.map(o => o.form_id), 
                    start_date: selectedDateRange[1],
                    end_date: selectedDateRange[2],
                    archive_status: [0, 1]
                };
                var userRole = store.getState().user.UserRole
                var isManager = !!userRole.filter(role => [1, 3].includes(role.role_id)).length;
                if(selectedJobLocationId) countBody.job_location_id = selectedJobLocationId;
                if(!isManager) countBody.job_location_id = selectedJobLocationId || selectedJobLocations.map(o => o.job_location_id);
                // if(isShowPendingOnboardings) countBody.sub_status = isShowPendingOnboardings === "2" ? [0, 2] : isShowPendingOnboardings;

                let resp2 = await ApiEndpoints.Jobs.getFormCount(countBody);
                await this.setState({taskCounts: resp2.data.data});
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    fetchAttendanceByUser = async (type) => {
        store.dispatch(setLoader(true))
        let {selectedJobId, selectedJobLocationId} = this.props;
        let { selectedDateRange, selectedTaskId, isShowPendingOnboardings } = this.state;
        try{
            var body = {
                ref_id : selectedJobId,
                start_date: selectedDateRange[1],
                end_date: selectedDateRange[2],
                attendance_type : "JOB",
                status : type,
                job_id : selectedJobId,
                job_location_id : selectedJobLocationId,
                rating : selectedJobId,
                archive_status: [0, 1]
            }
            // if(isShowPendingOnboardings) body.sub_status = isShowPendingOnboardings === "2" ? [0, 2] : isShowPendingOnboardings;
            if(selectedTaskId) body.form_id = selectedTaskId;
            if(this.props.selectedTlId) body.user_role_id= this.props.selectedTlId;
            let resp = await ApiEndpoints.Dashboard.getAttendanceUsersByStatus(body);
            await this.setState({attendanceUserList : resp.data.data});
            this.sortAttendance('todayTaskCount', "desc");
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

  
    // onChangeActiveInactive = (type) => {
    //     this.setState({selectedAttendanceType : type});
    //     this.fetchAttendanceByUser(type);
    // }


    sortAttendance = (type, order) => {
        let { attendanceUserList, sortAttendance, appliedFilter } = this.state;
        sortAttendance= {
        [type] : order || (sortAttendance[type] === "asc" ? "desc" : "asc")
        } 
        switch(type){
          case "lastSeenApp":
            attendanceUserList = sortAttendance[type] === "desc" ? 
            attendanceUserList.sort(function(a,b){
              return new Date(b.last_active_on) - new Date(a.last_active_on);
            }) 
            : 
            attendanceUserList.sort(function(a,b){
              return new Date(a.last_active_on) - new Date(b.last_active_on);
            });
            break;
          case "lastSeenTask":
            attendanceUserList = sortAttendance[type] === "desc" ? 
            attendanceUserList.sort(function(a,b){
              return new Date(b.last_task_active_on) - new Date(a.last_task_active_on);
            }) 
            : 
            attendanceUserList.sort(function(a,b){
              return new Date(a.last_task_active_on) - new Date(b.last_task_active_on);
            });
            break;
          case "todayTaskCount":
            var taskStatusFilter = { PENDING: 0, VERIFIED: 1, REJECTED: 2 }[appliedFilter];
            attendanceUserList = attendanceUserList.sort(function(a,b){
              var aTaskCount = taskStatusFilter === undefined ? ((a.taskCount[0] || 0) + (a.taskCount[1] || 0) + (a.taskCount[2] || 0)) : (a.taskCount[taskStatusFilter] || 0);
              var bTaskCount = taskStatusFilter === undefined ? ((b.taskCount[0] || 0) + (b.taskCount[1] || 0) + (b.taskCount[2] || 0)) : (b.taskCount[taskStatusFilter] || 0);
              return sortAttendance[type] === "desc" ? bTaskCount - aTaskCount : aTaskCount - bTaskCount;
            });
            break;
          default:
        }
        this.setState({attendanceUserList, sortAttendance, isOpenSortTodayMenu1: false, isOpenSortTodayMenu2: false})
    }

    filterTaskCount = async (filter) => {
        await this.setState({appliedFilter: filter});
        this.sortAttendance("todayTaskCount", "desc");
    }

    showNewActivatedPartners = async () => {
        store.dispatch(setLoader(true))
        let {selectedJobId, selectedJobLocationId, taskList, selectedJobLocations} = this.props;
        let { selectedDateRange, selectedTaskId, isShowPendingOnboardings } = this.state;
        
        try{
            var body = {
                form_id : selectedTaskId || taskList.map(o => o.form_id), 
                start_date: selectedDateRange[1],
                end_date: selectedDateRange[2],
                job_id : selectedJobId,
                archive_status: [0, 1]
            }
            var userRole = store.getState().user.UserRole
            var isManager = !!userRole.filter(role => [1, 3].includes(role.role_id)).length;
            if(selectedJobLocationId) body.job_location_id = selectedJobLocationId;
            if(!isManager) body.job_location_id = selectedJobLocationId || selectedJobLocations.map(o => o.job_location_id);
            // if(isShowPendingOnboardings) body.sub_status = isShowPendingOnboardings === "2" ? [0, 2] : isShowPendingOnboardings;
            if(this.props.selectedTlId) body.user_role_id= this.props.selectedTlId;
            let resp = await ApiEndpoints.Dashboard.fetchNewActivatedUser(body);
            let newActivatedPartners = groupArrayOnKey(resp.data.data, "user_id", true);
            this.setState({newActivatedPartners, appliedFilter: "ACTIVATED",})
            this.sortAttendance("todayTaskCount", "desc");
                     
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    showEarnedPartners = async () => {
        store.dispatch(setLoader(true))
        let {selectedJobId, selectedJobLocationId, taskList, selectedJobLocations} = this.props;
        let { selectedDateRange, selectedTaskId, isShowPendingOnboardings } = this.state;
        try{
            var body = {
                form_id : selectedTaskId || taskList.map(o => o.form_id), 
                start_date: selectedDateRange[1],
                end_date: selectedDateRange[2],
                job_id : selectedJobId,
                archive_status: [0, 1]
            }
            var userRole = store.getState().user.UserRole
            var isManager = !!userRole.filter(role => [1, 3].includes(role.role_id)).length;
            if(selectedJobLocationId) body.job_location_id = selectedJobLocationId;
            if(!isManager) body.job_location_id = selectedJobLocationId || selectedJobLocations.map(o => o.job_location_id);
            // if(isShowPendingOnboardings) body.sub_status = isShowPendingOnboardings === "2" ? [0, 2] : isShowPendingOnboardings;
            if(this.props.selectedTlId) body.user_role_id= this.props.selectedTlId;
                        
            let resp = await ApiEndpoints.Dashboard.fetchEarnedPartners(body);
            let EarnedPartners = groupArrayOnKey(resp.data.data, "user_id", true);
            this.setState({EarnedPartners, appliedFilter: "EARNED"})
            this.sortAttendance("todayTaskCount", "desc");
            
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
     render() {
        let {selectedDateRange, attendanceCounts, attendanceUserList, selectedAttendanceType, sortAttendance, attendaceUserSearchText, isSelectCustomDate, taskCounts, newActivatedPartners,EarnedPartners, appliedFilter } = this.state;
        let {selectedJobLocations, taskList, selectedJob} = this.props;
        let locationListById = groupArrayOnKey(selectedJobLocations, "job_location_id", true);

       if(appliedFilter === "ACTIVATED") attendanceUserList = attendanceUserList.filter(user => !!newActivatedPartners[user.user_id]);
        if(appliedFilter === "EARNED") attendanceUserList = attendanceUserList.filter(counts => !!EarnedPartners[counts.user_id]);
        attendanceUserList = attendanceUserList.filter(data => !!attendaceUserSearchText ? data.User.first_name && data.User.first_name.toLowerCase().includes(attendaceUserSearchText.toLowerCase()) :true);
        
        return (
            <>
                    <Row className="border-bottom mt-3 pb-1 pt-3 justify-content-between">
                        <Col xs={12} md={4}>
                            <h5 className="mb-0">Active Partners ({attendanceUserList.length})</h5>
                        </Col >
                        <Col md={"8"} xs={12} className="text-right">
                            <Row noGutters className="justify-content-end align-items-center text-center">
                                {/* {
                                    !!taskList.filter(task => task.formData && task.formData.custom_link).length && 
                                    <Col xs="auto">
                                        <SelectList placeholder="All Submissions" value={this.state.isShowPendingOnboardings || ""} onChange ={async (e) => {await this.setState({isShowPendingOnboardings: e.target.value}); this.selectDateRange(selectedDateRange[0])} } >
                                            <option value="">All Submission</option>
                                            <option value={"1"}>Partial Submission</option>
                                            <option value={"2"}>Completed Submission</option>
                                        </SelectList>
                                    </Col>
                                } */}
                                {taskList.length > 1 && <Col xs={4} md={2} className="mr-3">
                                    <SelectList placeholder="All Tasks" value={this.state.selectedTaskId} onChange ={async (e) => {await this.setState({selectedTaskId: e.target.value}); this.selectDateRange("TODAY")} } >
                                    { 
                                    taskList.map(task => 
                                    <option key={task.job_task_id} value={task.form_id}>{task.task_title}</option>
                                    )}
                                    </SelectList>
                                </Col>}
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("TODAY")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "TODAY" ? {borderColor: "red", color: "red"}:{})}}>Today</Button>
                                </Col>
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("YESTERDAY")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "YESTERDAY" ? {borderColor: "red", color: "red"}:{})}}>Yesterday</Button>
                                </Col>
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("WEEK")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "WEEK" ? {borderColor: "red", color: "red"}:{})}}>This Week</Button>
                                </Col>
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("PREV_WEEK")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "PREV_WEEK" ? {borderColor: "red", color: "red"}:{})}}>Previous Week</Button>
                                </Col>
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("MONTH")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "MONTH" ? {borderColor: "red", color: "red"}:{})}}>This Month</Button>
                                </Col>
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.selectDateRange("PREV_MONTH")} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "PREV_MONTH" ? {borderColor: "red", color: "red"}:{})}}>Previous Month</Button>
                                </Col>                                
                                <Col xs="auto" className="mr-1">
                                    <Button onClick={() => this.setState({isSelectCustomDate: {}})} color="danger" variant="outlined" size="small" style={{fontSize: 10, ...(selectedDateRange[0] === "CUSTOM" ? {borderColor: "red", color: "red"}:{})}}>Custom Date</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2} xs={6} className="mx-auto">
                            <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3">
                                {appliedFilter === "ACTIVATED" && <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.setState({appliedFilter: null})}>
                                    <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                                </span>}
                                <Col md={10} xs={12} className="p-1" style={{fontSize: 14}}><span>Activated Partners</span></Col>
                                <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.showNewActivatedPartners()}><span>{taskCounts["ACTIVATED"] || 0}</span></Col>
                            </Row>
                        </Col>
                        <Col md={2} xs={6} className="mx-auto">
                            <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3">
                                {appliedFilter === "EARNED" && <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.setState({appliedFilter: null})}>
                                    <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                                </span>}
                                <Col md={10} xs={12} className="p-1" style={{fontSize: 14}}><span>Earned Counts</span></Col>
                                <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.showEarnedPartners()}><span>{taskCounts["EARNED"] || 0}</span></Col>
                            </Row>
                        </Col>
                        <Col  md={2} xs={6} className="mx-auto">
                            <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3" style={{color: "#a87932"}}>
                                {appliedFilter === "PENDING" && <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.filterTaskCount(null)}>
                                    <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                                </span>}
                                <Col md={10} xs={12} className="p-1" style={{fontSize: 14}}><span>Pending Tasks</span></Col>
                                <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.filterTaskCount("PENDING")}><span>{taskCounts["PENDING"] || 0}</span></Col>
                            </Row>
                        </Col>
                        <Col md={2} xs={6} className="mx-auto">
                            <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3" style={{color: "#339900"}}>
                                {appliedFilter === "VERIFIED" && <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.filterTaskCount(null)}>
                                    <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                                </span>}
                                <Col md={10} xs={12} className="p-1" style={{fontSize: 14}}><span>Verified Tasks</span></Col>
                                <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.filterTaskCount("VERIFIED")}><span>{taskCounts["VERIFIED"] || 0}</span></Col>
                            </Row>
                        </Col>
                        <Col md={2} xs={6} className="mx-auto">
                            <Row className="border shadow-sm bg-light py-3 px-2  mt-4 mb-3" style={{color: "#cc3300"}}>
                                {appliedFilter === "REJECTED" && <span style={{position: "absolute", top: 5, right: 17, lineHeight: 0.75, borderRadius: 15, background: "#f00", cursor: "pointer"}} onClick={() => this.filterTaskCount(null)}>
                                    <Icon style={{fontSize: 14, color: "#fff"}}>close</Icon>
                                </span>}
                                <Col md={10} xs={12} className="p-1" style={{fontSize: 14}}><span>Rejected Tasks</span></Col>
                                <Col md={2} xs={12} className=" p-0 mx-0 text-center" style={{textDecoration: "underline", cursor: "pointer"}} onClick={() => this.filterTaskCount("REJECTED")}><span>{taskCounts["REJECTED"] || 0}</span></Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="border-top mt-2 pt-2 justify-content-end"> 
                        <Col xs={12} md={4}>
                            <InputFeild onChange={(e) => this.setState({attendaceUserSearchText: e.target.value})} icon="search" placeholder="Search by name" />
                        </Col>
                        
                        {/* <Col md={"6"} xs={12} className="text-right">
                            <Chip size="small" label={(attendanceCounts.PRESENT || 0)+" Active"} onClick={()=>  this.onChangeActiveInactive("PRESENT")}  style={{color : "white", background : selectedAttendanceType === "PRESENT" ? "#f1061c" : "#aaa"}} clickable className=""/>
                            <Chip size="small" label={(attendanceCounts.ABSENT || 0)+" Inactive"} onClick={()=> this.onChangeActiveInactive("ABSENT")}   style={{color : "white", background : selectedAttendanceType === "ABSENT" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                            <Chip size="small" label={(attendanceCounts.IGNORED || 0)+" No Response"} onClick={()=> this.onChangeActiveInactive("IGNORED")}   style={{color : "white", background : selectedAttendanceType === "IGNORED" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>
                            <Chip size="small" label={(attendanceCounts.PRESENT || 0)+(attendanceCounts.ABSENT || 0)+(attendanceCounts.IGNORED || 0)+" All"} onClick={() => this.onChangeActiveInactive("ALL")}   style={{color : "white", background : selectedAttendanceType === "ALL" ? "#f1061c" : "#aaa"}} clickable className="ml-2"/>                              
                        </Col> */}
                    </Row>  
                    <Row className="mt-2"  style={{maxHeight : 600 , overflowY : "scroll"}}>
                    <Col>
                        <Row className="sticky-top bg-light py-2 text-center" style={{fontWeight: "bolder", fontSize: 14, zIndex: 99}}  noGutters>
                            <Col xs={5} className="text-left">User</Col>
                            <Col xs={3}>
                                <span>Task Counts</span>
                                <Icon style={{cursor: "pointer"}} className="d-inline-flex align-middle" onClick={(e) => this.setState({isOpenSortTodayMenu1: e.currentTarget})}>keyboard_arrow_down</Icon>
                                <Menu anchorEl={this.state.isOpenSortTodayMenu1 || null} open={!!this.state.isOpenSortTodayMenu1} onClose={() => this.setState({isOpenSortTodayMenu1: null})}>
                                    <MenuItem onClick={() => this.sortAttendance("todayTaskCount", "asc")} selected={sortAttendance.todayTaskCount === "asc"}>{"Ascending"}</MenuItem>
                                    <MenuItem onClick={() => this.sortAttendance("todayTaskCount", "desc")} selected={sortAttendance.todayTaskCount === "desc"}>{"Descending"}</MenuItem>
                                </Menu>
                            </Col>
                            <Col xs={4} title="Last Seen on APP/TASK" >
                                <span>Last Seen</span>
                                <Icon style={{cursor: "pointer"}} className="d-inline-flex align-middle" onClick={(e) => this.setState({isOpenSortTodayMenu2: e.currentTarget})}>keyboard_arrow_down</Icon>
                                <Menu anchorEl={this.state.isOpenSortTodayMenu2 || null} open={!!this.state.isOpenSortTodayMenu2} onClose={() => this.setState({isOpenSortTodayMenu2: null})}>
                                    <MenuItem onClick={() => this.sortAttendance("lastSeenApp", "asc")} selected={sortAttendance.lastSeenApp === "asc"}>{"Last Seen - Ascending"}</MenuItem>
                                    <MenuItem onClick={() => this.sortAttendance("lastSeenApp", "desc")} selected={sortAttendance.lastSeenApp === "desc"}>{"Last seen - Descending"}</MenuItem>
                                    <MenuItem onClick={() => this.sortAttendance("lastSeenTask", "asc")} selected={sortAttendance.lastSeenTask === "asc"}>{"Last Task - Ascending"}</MenuItem>
                                    <MenuItem onClick={() => this.sortAttendance("lastSeenTask", "desc")} selected={sortAttendance.lastSeenTask === "desc"}>{"Last Task - Descending"}</MenuItem>
                                </Menu>
                            </Col>
                            
                        </Row>
                            
                            {attendanceUserList.length ? attendanceUserList.slice(this.state.attendanceCurrentPage*this.state.attendanceRowsPerPage, this.state.attendanceCurrentPage*this.state.attendanceRowsPerPage+this.state.attendanceRowsPerPage).map((data, i) =>
                            {
                                return <Row key={i} className="align-items-center border-bottom" noGutters style={{fontSize: 14}}>
                                <Col>
                                    <UserCard data={{...data, ...data.User}}/>
                                </Col>
                                <Col xs={1} >
                                <Icon className="align-middle mx-2" onClick={() => this.props.makePhoneCall(data.User) }  style={{fontSize:16, cursor: "pointer"} }>call</Icon>
                                        {/* <img src={WhatsappIcon} alt="" style={{cursor : "pointer"}} onClick={() => this.setState({whatsappChat: data.User})}/> */}
                                </Col>
                                <Col xs={3} className="text-center">
                                    <span className="d-block" onClick={() => this.props.openTaskDetail({...data.User, isShowPendingOnboardings: this.state.isShowPendingOnboardings})} style={{cursor: "pointer"}}>
                                        {
                                        appliedFilter === "PENDING" ? data.taskCount[0] || 0 :
                                        ( appliedFilter === "VERIFIED" ? data.taskCount[1] || 0 : (
                                            appliedFilter === "REJECTED" ? data.taskCount[2] || 0 : ((
                                                data.taskCount[0] || 0) + (data.taskCount[1] || 0) + (data.taskCount[2] || 0
                                            ))
                                        ) )
                                        }
                                    </span>
                                </Col>
                                <Col xs={4} className="text-center">
                                    <span className="d-block">App: {data.last_active_on && moment(data.last_active_on).utcOffset("+05:30").format("Do MMM YYYY")}</span>
                                    <span className="d-block">Task: {data.last_task_active_on && moment(data.last_task_active_on).utcOffset("+05:30").format("Do MMM YYYY")}</span>
                                    {appliedFilter === "ACTIVATED" && 
                                        <span>Activation Date: {moment(newActivatedPartners[data.user_id].activation_date).utcOffset("+05:30").format("Do MMM YYYY")}</span>
                                    }
                                </Col>
                                
                                </Row>
                            }) : 
                            <Row><Col colSpan={11}><p className="text-center text-muted mt-3"> <em>No data</em></p></Col></Row>
                            }
                        
                    </Col>
                    </Row>
                    <TablePagination labelRowsPerPage="" rowsPerPageOptions={[20, 50, 100, 200]} component="div" count={attendanceUserList.length} rowsPerPage={this.state.attendanceRowsPerPage} page={this.state.attendanceCurrentPage}
                        onChangePage={ (e, page) => this.setState({attendanceCurrentPage : page}) }
                        onChangeRowsPerPage={ (e) =>  this.setState({attendanceRowsPerPage : e.target.value})}
                    />




                    <Modal isOpen={isSelectCustomDate} toggle={()=> {this.setState({isSelectCustomDate : false})}} >
                        <ModalBody className="p-5">
                            <DatePicker
                                placeholderText="Start Date"
                                selected={isSelectCustomDate.startDate || null}
                                onChange={(date) => {isSelectCustomDate.startDate = date; this.setState({isSelectCustomDate});}}
                                selectsStart
                                startDate={isSelectCustomDate.startDate || null}
                                endDate={isSelectCustomDate.endDate || null}
                            />
                            <DatePicker
                                className="mt-3"
                                placeholderText="End Date"
                                selected={isSelectCustomDate.endDate || null}
                                onChange={(date) => {isSelectCustomDate.endDate = date; this.setState({isSelectCustomDate});}}
                                selectsEnd
                                startDate={isSelectCustomDate.startDate || null}
                                endDate={isSelectCustomDate.endDate || null}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => this.selectDateRange("CUSTOM", [isSelectCustomDate.startDate, isSelectCustomDate.endDate])} color="danger" >Apply Filter</Button>{' '}
                        </ModalFooter>
                    </Modal>

                    {!!this.state.whatsappChat && <WhatsappChat jobList={this.props.jobList} user={this.state.whatsappChat} entity_type={"JOB"} entity_id={this.props.selectedJobId} jobTitle={selectedJob.Employer.employer_name + "-" + selectedJob.title} isOpen={!!this.state.whatsappChat} onClose={() => this.setState({whatsappChat: false})} onMarkRead={() => {}} />}

            </>  
        );
    }
}
export default ActivePartners;
