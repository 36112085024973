import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";


class CustomerBasicDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailList : [],
            
        }
    }
   
    componentDidMount(){
        this.fetchBasicDetails();
    }

    fetchBasicDetails = async () => {
        try{
           let resp = await ApiEndpoints.Customer.customerDetails({customer_lead_id: this.props.customer_lead_id});
           let detailList = resp.data.data;
           this.setState({detailList})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }

    render() {
        let { detailList } = this.state;
        return (
            <>

                <Row className="mt-2">
                    <Col>
                        <span className="mt-2" style={{fontSize: 14}}>Name</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.name || ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>D.O.B</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.year_of_birth || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Gender</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.gender || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Email</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.email || ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Mobile Number</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.mobile || "-"}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Address</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}>  {list.CustomerDatum ? list.CustomerDatum.address || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Cibil Score</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.cibil_score || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Pan Number</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.pan_no || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Aadhar Number</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.aadhaar_no || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Occupation</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.occupation || "" : ""}</span>
                        )}
                    </Col>
                </Row>
                
                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Earning</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.earning || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>State</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.state || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>City</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.city || "" : ""}</span>
                        )}
                    </Col>
                </Row>

                <Row className="mt-1">
                    <Col>
                        <span className="mt-" style={{fontSize: 14}}>Pincode</span> - 
                        {detailList.map(list =>
                            <span className="" style={{fontSize: 12}}> {list.CustomerDatum ? list.CustomerDatum.pincode || "" : ""}</span>
                        )}
                    </Col>
                </Row>

            </>
            
            
        );
    }
}
export default CustomerBasicDetails;
