import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import "../../../../styles/button.css"
import InputFeild from "../../../global/form/input";


class JobCriteriaInfo extends Component {
    constructor(props) {
      super(props);
      this.state = {
            data: [[], [], []],
        }
    }

    initState = () => {
        let infoData = (this.props.info || "").split(";;").map(v => (v || "").split("_"));
        this.setState({ data: [infoData[0] || [], infoData[1] || [], infoData[2] || [] ] });
    }

    async componentDidMount(){
      this.initState();
    }

    componentDidUpdate(prevProps){
        if(prevProps.language !== this.props.language) {
            console.log(this.props.info);
            this.initState();
        }
    }

    onChangeInput = (i, j, value) => {
        let { data } = this.state;
        data[i] = data[i] || [];
        data[i][j] = value;
        this.setState({ data });
    }

    addProjectInfo = () => {
        let { data } = this.state;
        let content = data.map(value => {
            return (value || []).map(o => o || "").join("_")
        }).join(";;")
        this.props.addProjectInfo({ content })
    }
    

    render(){
        let {data, } = this.state;
        let { isOnlyView } = this.props;
        return(
        <>  
            <Row className={"align-items-center m-3"}>
                <Col>
                    <Row className="my-4 align-items-center">
                        <Col xs={4}><span>Consideration 1</span></Col>
                        <Col xs={4}>
                            <InputFeild inputProps={{maxLength: 20}} placeholder="Enter Heading (Joining fee)" value={(data[0] && data[0][0]) || ""} onChange={(e) => this.onChangeInput(0, 0, e.target.value)} />
                        </Col>
                        <Col xs={4}>
                            <InputFeild inputProps={{maxLength: 20}} placeholder="Enter Value" value={(data[0] && data[0][1]) || ""} onChange={(e) => this.onChangeInput(0, 1, e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="my-4 align-items-center">
                        <Col xs={4}><span>Consideration 2</span></Col>
                        <Col xs={4}>
                            <InputFeild inputProps={{maxLength: 20}} placeholder="Enter Heading (Annual fee)" value={(data[1] && data[1][0]) || ""} onChange={(e) => this.onChangeInput(1, 0, e.target.value)} />
                        </Col>
                        <Col xs={4}>
                            <InputFeild inputProps={{maxLength: 20}} placeholder="Enter Value" value={(data[1] && data[1][1]) || ""} onChange={(e) => this.onChangeInput(1, 1, e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="my-4 align-items-center">
                        <Col xs={4}><span>Product Benefits (Emojis)</span></Col>
                        <Col xs={8}>
                            <InputFeild inputProps={{maxLength: 10}} placeholder="Paste Emojis here" value={(data[2] && data[2][1]) || ""} onChange={(e) => this.onChangeInput(2, 1, e.target.value)} />
                        </Col>
                    </Row>
                    {!this.props.isOnlyView && 
                    <Row className="align-items-center mt-3">
                        <Col className={"text-right"}>
                            <Button type="button" className="buttonred" onClick={()=> this.addProjectInfo()} style={{fontSize: "14px"}}>Update</Button>
                        </Col>
                    </Row>}
                </Col>
            </Row>
        </>
        );
    }
}

export default JobCriteriaInfo;
