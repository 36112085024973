import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col , Collapse, Table  } from "reactstrap/lib";
import { Icon, Chip, TablePagination } from "@material-ui/core";
import store from "../../../store";
import { setLoader, setError } from "../../../actions/action-creators";
import ApiEndpoints from "../../../constants/api_endpoints";
import moment from "moment";
import { groupArrayOnKey } from "../../../actions";
import Button from "reactstrap/lib/Button";
import CONSTANTS from "../../../constants";
import SelectList from "../../global/form/select";


class AuditTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            auditTaskData : [],
            selectedData: null ,
            videoInputId: null,
            auditVideoInputId: null,
            isOpenrejectCollapse: false,
            reasonList: []
        }
    }

    async componentDidMount(){
        this.fetchAuditTasks();
        this.selectRejectionReason();
    }
    selectRejectionReason = async () => {
        let {taskList} = this.props;
        if(!taskList.length) return;
        store.dispatch(setLoader(true));
          try{
            var body = {
                job_id: taskList[0].job_id
            }
            let resp = await ApiEndpoints.JobTask.fetchTaggedReason(body)
            this.setState({reasonList: resp.data.data});
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
    }
    fetchAuditTasks = async () => {
        let {taskList} = this.props;
        if(!taskList.length) return;
        store.dispatch(setLoader(true))
        try{
            let body = {
                form_id: taskList[0].form_id,
                status: 0,
                audit_status: [0, 3]
            }            
            var resp = await ApiEndpoints.JobTask.fetchTaskRespWithUser(body);
            var formData = taskList[0].formData;
            var videoInputId = formData.input.sections[0].input_groups[0].inputs.filter(input => input.input_type === "VIDEO")[0]
            var auditVideoInputId = null;
            if(formData.audit){
                var auditVideoInput = formData.audit.inputs.filter(input => input.input_type === "VIDEO")[0];
                if(auditVideoInput){
                    auditVideoInputId = auditVideoInput.input_id;
                }
            }
            if(!videoInputId) {
                return store.dispatch(setLoader(false));
            }
            videoInputId = videoInputId.input_id;
            var auditTaskData = resp.data.data.rows || [];
            auditTaskData = auditTaskData.filter(obj => !!obj.FormResponseData[videoInputId]);
            this.setState({auditTaskData, videoInputId, auditVideoInputId})
            if(auditTaskData.length) await this.selectTaskData(auditTaskData[0], 0);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }

    selectTaskData = async (data, index) => {
        this.setState({selectedData: null});
        try{
            var videoFileId = null;

            if(data.FormResponseData[this.state.videoInputId] && data.FormResponseData[this.state.videoInputId].value){
                videoFileId = data.FormResponseData[this.state.videoInputId].value
            }
            if(this.state.auditVideoInputId && data.FormResponseData[this.state.auditVideoInputId] && data.FormResponseData[this.state.auditVideoInputId].value){
                videoFileId = data.FormResponseData[this.state.auditVideoInputId].value
            }
            if(videoFileId){
                var resp = await ApiEndpoints.Files.getFileID({file_id : videoFileId})
                data.vedio_url = resp.data.data[0] && resp.data.data[0].url;
            }
            this.setState({isOpenrejectCollapse: false, selectedData : {...data, index}})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
    }
    nextSelect = () => {
        let { selectedData, auditTaskData } = this.state;
        if(!selectedData || !auditTaskData[selectedData.index+1]) return;
        this.selectTaskData(auditTaskData[selectedData.index+1], selectedData.index+1);
    }
    prevSelect = () => {
        let { selectedData, auditTaskData } = this.state;
        if(!selectedData || !auditTaskData[selectedData.index-1]) return;
        this.selectTaskData(auditTaskData[selectedData.index-1], selectedData.index-1);
    }

    rejectTask = async (e) => {
        e.preventDefault();
        e.persist();
        let {selectedData, auditTaskData, isOpenrejectCollapse} = this.state;
        if(!isOpenrejectCollapse.reason)
        return store.dispatch(setError({show: true, message: "Please select Reason, It is mandatory for rejection"}))

        store.dispatch(setLoader(true))
        try {
            var body = {
                form_id: selectedData.form_id,
                status : 'REJECTED',
                form_response_id: selectedData.form_response_id,
                status_comment: isOpenrejectCollapse.reason,
                job_task_id: this.props.taskList[0].job_task_id
            }
            isOpenrejectCollapse.status === 2 ?
            await ApiEndpoints.JobTask.rejectAuditRequiredResponse(body)
            :
            await ApiEndpoints.JobTask.verifyResponse(body);
            e.target.reset();
            auditTaskData.splice(selectedData.index, 1);
            this.setState({isOpenrejectCollapse : false, auditTaskData});
            if(auditTaskData[selectedData.index]) this.selectTaskData(auditTaskData[selectedData.index], selectedData.index);
            else this.setState({selectedData: null});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    markAuditDone = async (e) => {
        let {selectedData, auditTaskData} = this.state;
        store.dispatch(setLoader(true))
        try {
            var body = {
                audit_status: 1,
                form_response_id: selectedData.form_response_id,
            }
            await ApiEndpoints.JobTask.updateTaskAuditStatus(body);
            auditTaskData.splice(selectedData.index, 1);
            if(auditTaskData[selectedData.index]) this.selectTaskData(auditTaskData[selectedData.index], selectedData.index);
            else this.setState({selectedData: null});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { auditTaskData, selectedData, isOpenrejectCollapse } = this.state;
        let {taskList} = this.props;
        var task = taskList[0] || {};
        return (
            <>
            <div className="px-3">
                <Row className="border py-2 shadow-sm align-items-center"> 
                    <Col xs={"auto"}> 
                        <Icon style={{cursor: "pointer"}} className="d-flex align-middle" onClick={this.prevSelect}>chevron_left</Icon> 
                    </Col>
                    <Col className="text-center">
                        <h6 className="m-0">{selectedData && selectedData.index + 1} / {auditTaskData.length}</h6>
                    </Col>
                    <Col xs={"auto"} className="text-right"> 
                        <Icon style={{cursor: "pointer"}} className="d-flex align-middle" onClick={this.nextSelect}>chevron_right</Icon> 
                    </Col>
                </Row>
                {selectedData && <Row className="align-items-center mt-2">
                    <Col xs={12} md={6} className="border-right" style={{wordBreak: "break-all"}}>
                        <div className="p-2">
                            <h6 className="border-bottom">Partner Details: </h6>
                            <span className="d-block"><b>Name: </b>{selectedData.User.first_name}</span>
                            <span className="d-block"><b>PMW ID:</b> PMW{selectedData.User.id}</span>
                            <span className="d-block"><b>Mobile :</b> {selectedData.User.mobile}</span>
                        </div>
                        <div className="p-2">
                            <div className="border-bottom">
                                <h6 className="d-inline-block">Merchant Details: </h6>
                                {selectedData.audit_status === 3 && <span className="float-right" style={{color: "#f1061c"}}>*Audit Submitted</span>}
                            </div>
                            {
                                task.formData && task.formData.input.sections[0].input_groups[0].inputs.map(input => {
                                    return ["VIDEO"].indexOf(input.input_type) !== -1 ? null : <span className="d-block"><b>{input.label}</b>: {selectedData.FormResponseData[input.input_id] && selectedData.FormResponseData[input.input_id].value}</span>
                                })
                            }
                        </div>
                        <Row className="text-center mt-3 justify-content-center">
                            <Col xs={4}>
                                <Button onClick={() => this.setState({isOpenrejectCollapse: this.state.isOpenrejectCollapse ? false : {status: 1}})} className="buttonred w-100">Reject</Button>
                            </Col>
                            
                            <Col xs={4}>
                                <Button className="buttonred w-100" onClick={() => this.markAuditDone()}>Ok & Next</Button>
                            </Col>
                            <Col xs={4}>
                                <Button className="buttonred w-100" onClick={() => this.nextSelect()}>Next</Button>
                            </Col>
                        </Row>
                        {/* <Row className="mt-2">
                            <Col xs={12}>
                                <Button onClick={() => this.setState({isOpenrejectCollapse: isOpenrejectCollapse ? false : {status: 2}})} className="buttonred w-100">Reject with Audit Required</Button>
                            </Col>
                        </Row> */}
                        <Collapse isOpen={!!this.state.isOpenrejectCollapse}>
                                <Row className="m-3 border pt-3">
                                    <Col>
                                    <form onSubmit={this.rejectTask}>
                                        <SelectList name="reason" required placeholder="Select Reason" value={isOpenrejectCollapse.reason} onChange={(e) => {isOpenrejectCollapse.reason = e.target.value; this.setState({isOpenrejectCollapse})}}  >
                                            {this.state.reasonList.map((obj, i) => 
                                            <option key={i} value={obj.reason}> 
                                                {obj.reason} 
                                            </option>
                                            )}
                                        </SelectList>     
                                        <div className="text-right m-2">
                                            <Button size="sm" type="reset" onClick={() => {this.setState({isOpenrejectCollapse : false})}} className="border-danger text-danger bg-light mr-2">Cancel</Button>
                                            <Button type="submit" className="buttonred">Reject</Button>
                                        </div>
                                    </form>
                                    </Col>
                                </Row>
                            </Collapse>
                    </Col>
                    <Col xs={12} md={6} className="pt-3">
                        <video autoPlay controls style={{width: "100%"}} height={320} src={selectedData.vedio_url} />
                    </Col>
                </Row>
                }
            </div>
            </>  
        );
    }
}
export default AuditTask;
