import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css"
import {Chip, Icon, Tab, Tabs} from '@material-ui/core';
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import RichTextEditor from "../../../global/form/Richtext";
import JobSharingMaterial from "./sharing_material";
import Events from "../../webinar";
import JDVideo from "./jd_video";
import TrainingVideo from "./training_video";
import JobCriteriaInfo from "./job_critieria_info";
import { getTextTranslationWithLang } from "../../../../actions";
import moment from "moment";
import TaskRejectionManage from "./task_rejection_manage";

const PARENT_INFO_TYPE = {
    INSTRUCTIONS_TAB: {
        label: "How to Earn",
        tabs: ["INSTRUCTIONS", "TNC", "ACCURACY"],
    },
    DETAILS_TAB: {
        label: "Details",
        tabs: ["DESCRIPTION", "FEATURES", "CRITERIA"],
    },
    TRAINING_TAB: {
        label: "Training",
        tabs: ["TRAINING_SESSIONS", "TRAINING_VIDEO"],
    },
    MARKETING_TAB: {
        label: "Marketing",
        tabs: ["JD_VIDEO", "MARKETING_BANNERS", "MARKETING_TIPS"],
    },
    CALLING_TAB: {
        label: "Calling Notes",
        tabs: ["CALLING_NOTES"],
    },
    REQUIREMENTS_TAB: {
        label: "Rejection Reasons",
        tabs: ["REQUIREMENTS", "TASK_REJECTIONS"],
    }
};
const INFO_TYPE = {
    DESCRIPTION: "Description",
    FEATURES: "Benefits to Customer",
    CRITERIA: "Considerations to Customer",
    INSTRUCTIONS: "How to Earn",
    TNC: "Customer Eligibility",
    REQUIREMENTS: "Rejection Description",
    TASK_REJECTIONS: "Rejection Reason Manage",
    TRAINING_VIDEO: "Training Videos",
    TRAINING_SESSIONS: "Training Sessions",
    JD_VIDEO: "JD Video",
    MARKETING_BANNERS: "Marketing Banners",
    MARKETING_TIPS: "Marketing Tips",
    CALLING_NOTES: "Calling Notes",
    ACCURACY: "Accuracy"
}

class JobInformation extends Component {
    constructor(props) {
      super(props);
      this.state = {
            info: [],
            taskData: {},
            selectedTab: props.selectedTab || "INSTRUCTIONS_TAB",
            selectedInfo: {
                info_type: props.selectedSubTab || PARENT_INFO_TYPE[props.selectedTab || "INSTRUCTIONS_TAB"].tabs[0],
                language: "ENGLISH"
            }
        }
    }

    async componentDidMount(){
        await this.init() ;
    }

    init = async () =>{
        store.dispatch(setLoader(true))
        try{
            let apiData = await Promise.all([
                ApiEndpoints.Jobs.fetchJobInfo({job_id : this.props.job.job_id}),
                ApiEndpoints.Jobs.fetchJobMetadata({job_id : this.props.job.job_id})
            ]);
            this.setState({info: apiData[0].data.data, taskData: apiData[1].data.data[0] || {} });
            this.selectParentTab(this.state.selectedTab);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    selectParentTab = (tab) => {
        this.setState({selectedTab: tab});
        this.selectInfo("info_type", PARENT_INFO_TYPE[tab].tabs[0])
    }

    selectInfo = async (key, value) => {
        let { info, selectedInfo } = this.state;
        var setInfo = null;
        if(key === "info_type"){
            selectedInfo.info_type = value;
            selectedInfo.translatedData = undefined;
            setInfo = info.filter(o => o.info_type === value && o.language === selectedInfo.language)[0];
        } else if(key === "language"){
            selectedInfo.language = value;
            setInfo = info.filter(o => o.language === value && o.info_type === selectedInfo.info_type)[0];
        }
        selectedInfo.content = setInfo ? setInfo.content : "";
        this.setState({selectedInfo})
    }
    
    onChangeRTE = (value)=> {
        let { selectedInfo } = this.state;
        if(selectedInfo.info_type === "DESCRIPTION"){
            let stripedValue = value.replace(/<[^>]+>/g, '');
            if(stripedValue.split(' ').length >= 50) {
                alert("Description can not be longer than 50 Words");
                value = selectedInfo.content || "";
            }
        }
        selectedInfo.content = value; 
        this.setState({selectedInfo});
    } 


    addProjectInfo = async (infoOpts={}) => {
        let { selectedInfo } = this.state;
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.addJobInfo({...selectedInfo, ...infoOpts, job_id : this.props.job.job_id});
            store.dispatch(setSuccess({show: true, message: "Content saved successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addProjectInfoAll = async (infoOpts={}) => {
        let { selectedInfo } = this.state;
        store.dispatch(setLoader(true))
        try{
            for (const element of selectedInfo.translatedData) {
                await ApiEndpoints.Jobs.addJobInfo({...element, ...infoOpts, job_id: this.props.job.job_id});
            }
            store.dispatch(setSuccess({show: true, message: "Content saved successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    translateInAllLang = async () =>
    {
        let { selectedInfo } = this.state;
        store.dispatch(setLoader(true))
        let stripedValue = selectedInfo.content;
        if(stripedValue == undefined || stripedValue == "")
        {
            store.dispatch(setLoader(false))
            store.dispatch(setError({show: true, message: "Enter content in English tab"}))
            return;   
        }
        let apiData = await Promise.all([
            getTextTranslationWithLang(stripedValue,"en","hi",selectedInfo.info_type),
            getTextTranslationWithLang(stripedValue,"en","ta",selectedInfo.info_type),
            getTextTranslationWithLang(stripedValue,"en","te",selectedInfo.info_type),
            getTextTranslationWithLang(stripedValue,"en","kn",selectedInfo.info_type),
        ])
        apiData.unshift({info_type:selectedInfo.info_type,language:"ENGLISH",content:stripedValue})
        selectedInfo.translatedData = apiData;
        this.setState({selectedInfo});
        store.dispatch(setLoader(false))
    }

    contentFinder = () =>
    {
        let { selectedInfo } = this.state;
        if(selectedInfo.translatedData == undefined)
            return "";
        let str = selectedInfo.translatedData.find((val) => val.language == selectedInfo.language);
        return (str.content || "")
    }

    updateContentToAll = async () => {
        await this.translateInAllLang();
        await this.addProjectInfoAll();
        store.dispatch(setLoader(true));
        try {
            const jobInfo = await ApiEndpoints.Jobs.fetchJobInfo({ job_id: this.props.job.job_id });
            const jobMetadata = await ApiEndpoints.Jobs.fetchJobMetadata({ job_id: this.props.job.job_id });
            this.setState({
                info: jobInfo.data.data,
                taskData: jobMetadata.data.data[0] || {}
            });
            store.dispatch(setSuccess({ show: true, message: "Content updated successfully" }));
        } catch (e) {
            store.dispatch(setError({ show: true, message: e.response ? e.response.data.message : e.toString() }));
        } finally {
            store.dispatch(setLoader(false));
        }
    };

    updateContent = async () =>
    {
        let { selectedInfo , info } = this.state;
        if(selectedInfo.content == "")
        {
            store.dispatch(setLoader(false))
            store.dispatch(setError({show: true, message: "Enter content"}))
            return;
        }
        await this.addProjectInfo();
        store.dispatch(setLoader(true));
        try {
            const jobInfo = await ApiEndpoints.Jobs.fetchJobInfo({ job_id: this.props.job.job_id });
            const jobTaskInfo = await ApiEndpoints.Jobs.fetchJobMetadata({ job_id: this.props.job.job_id });
            this.setState({
                info: jobInfo.data.data,
                taskData: jobTaskInfo.data.data[0] || {}
            });
            store.dispatch(setSuccess({ show: true, message: "Content updated successfully" }));
        }
        catch (e) {
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        } finally {
            store.dispatch(setLoader(false));
        }
        // info = info.map((subInfoData)=>{
        //     if(subInfoData.info_type === selectedInfo.info_type && subInfoData.language === selectedInfo.language)
        //         subInfoData.content = selectedInfo.content;
        //     return subInfoData;
        // })
        // this.setState({info});
    }

    getNextVerificationETA = (lastDate, bufferDays = 7) => {
        let nextEta = moment(lastDate).add(bufferDays, "days");
        let nextMaxDelayedETA = moment(nextEta).add(Math.round(bufferDays / 2), "days");
        if (nextMaxDelayedETA.isBefore(moment())) {
          return this.getNextVerificationETA(nextEta, bufferDays);
        } else return `${nextEta.format("Do MMM'YY")}(${nextEta.diff(moment(), "days")}D)`;
      };
      
    
    render(){
        let {selectedTab, selectedInfo, taskData} = this.state;
        let {job, isOnlyView} = this.props;
        return(
        <>  
            {!!isOnlyView && 
                <Row className="my-2 font-weight-bold small">
                    <Col xs={6}>
                        <span className="text-muted">Earning: </span><span>{job.max_salary}</span>
                    </Col>
                    <Col xs={6}>
                        <span className="text-muted">Last Verification: </span><span>{taskData.metaData && taskData.metaData.LAST_VERIFICATION_DATE ? `${moment(taskData.metaData.LAST_VERIFICATION_DATE).utcOffset("+05:30").format("Do MMM'YY")}(${moment().diff(moment(taskData.metaData.LAST_VERIFICATION_DATE), "days")}D)` : "--"}</span>
                    </Col>
                    <Col>
                        <span className="text-muted">Verification Days: </span><span>{taskData.clientDataBuffer || "--"}</span>
                    </Col>
                    <Col>
                        <span className="text-muted">Next ETA: </span><span> {taskData.metaData && taskData.metaData.LAST_VERIFICATION_DATE ? this.getNextVerificationETA(taskData.metaData.LAST_VERIFICATION_DATE, taskData.clientDataBuffer): "--"}</span>
                    </Col>
                </Row>
            }
            <Tabs className="sticky-top" style={{minHeight: 35, background: "#ccc"}} value={selectedTab} onChange={(e, v) => this.selectParentTab(v)} variant="scrollable" scrollButtons="auto">
                {Object.keys(PARENT_INFO_TYPE).map(key => 
                    <Tab style={{minWidth: 110, padding: 5, minHeight: 35}} key={key} itemProp="" value={key} label={PARENT_INFO_TYPE[key].label} />
                )}
            </Tabs>
            <Tabs className="mt-2" style={{minHeight: 35}} value={selectedInfo.info_type} onChange={(e, v) => this.selectInfo("info_type", v)}  centered>
                {PARENT_INFO_TYPE[selectedTab].tabs.map(key => 
                    <Tab style={{minWidth: 125, padding: 5, minHeight: 35, marginLeft: 15}} key={key} itemProp="" value={key} label={INFO_TYPE[key]} />
                )}
            </Tabs>
            <div className="w-100 text-center my-3">
                {["English", "Hindi", "Tamil", "Telugu", "Kannada"].map(lang =>
                    <Chip key={lang} size="small" className="mr-3" label={lang} variant={selectedInfo.language === lang.toUpperCase() ? undefined : "outlined"} onClick={() => this.selectInfo("language", lang.toUpperCase())} />
                )}
            </div>
            
            {
            selectedInfo.info_type === "CRITERIA" ? <JobCriteriaInfo isOnlyView={isOnlyView} jobId={job.job_id} info={selectedInfo.content} addProjectInfo={this.addProjectInfo}  language={selectedInfo.language}  />
            :
            selectedInfo.info_type === "MARKETING_BANNERS" ? <JobSharingMaterial isOnlyView={isOnlyView} jobId={job.job_id} type={selectedInfo.info_type} language={selectedInfo.language} />
            :
            selectedInfo.info_type === "TRAINING_SESSIONS" ? <Events isOnlyView={isOnlyView} jobList={[job]} job_id = {job.job_id} />
            :
            selectedInfo.info_type === "TRAINING_VIDEO" ? <TrainingVideo isOnlyView={isOnlyView} selectedJob={job} language={selectedInfo.language}  />
            :
            selectedInfo.info_type === "JD_VIDEO" ?  <JDVideo isOnlyView={isOnlyView} selectedJob={job} language={selectedInfo.language} />
            :
            selectedInfo.info_type === "TASK_REJECTIONS" ?  <TaskRejectionManage isOnlyView={isOnlyView} selectedJob={job} language={selectedInfo.language} />
            :
                <>
                    {isOnlyView ? 
                    <div style={{maxHeight: 200, minHeight: 100, overflowY: "scroll"}}  dangerouslySetInnerHTML={{__html: selectedInfo.content||"<em>No data</em>"}} ></div>
                    :
                    <>
                    <RichTextEditor height="250px"  placeholder="Enter the content here" value={selectedInfo.content !== "" ? (selectedInfo.content || "") : this.contentFinder()} onChange={this.onChangeRTE}/>
                    <Row className="align-items-center mt-3">
                        <Col className={"text-right"}>
                            <Button type="button" className="buttonred" onClick={()=> this.updateContent()} style={{fontSize: "14px"}}>Update</Button>
                            {selectedInfo.language === "ENGLISH" && <Button type="button" className="buttonred ml-2" onClick={()=> this.updateContentToAll()} style={{fontSize: "14px"}}>Update All</Button>}
                            {/* <Button type="button" className="buttonred mr-2" onClick={()=> this.translateInAllLang()} style={{fontSize: "14px"}}>Translate in All Languages</Button> */}
                            {/* <Button type="button" className="buttonred" onClick={()=> this.addProjectInfo()} style={{fontSize: "14px"}}>Update</Button> */}
                            {/* {selectedInfo.translatedData && <Button type="button" className="buttonred ml-2" onClick={()=> this.addProjectInfoAll()} style={{fontSize: "14px"}}>Update in All</Button>} */}
                        </Col>
                    </Row>
                    </>
                    }
                </>
            }

        </>
        );
    }
}

export default JobInformation;