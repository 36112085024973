
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, CardBody, Card } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css";
import { Progress } from 'reactstrap';
import {uploadFile} from "../../../../actions";
import store from "../../../../store";
import { setError , setLoader} from "../../../../actions/action-creators";
import VideoUploadFeild from "../../../global/form/upload-video";
import ConfirmAction from "../../../global/confirm-action";
import InputFeild from "../../../global/form/input";
import { Checkbox, FormControlLabel, Select, Switch } from "@material-ui/core";

import ImageUploadFeild from "../../../global/form/upload-image";

class TrainingVideo extends Component {
    constructor(props) {
      super(props);
      this.addEditTrainingQuesView = React.createRef()  
      this.state = {
           
            mediaList : [] ,
            isAddMedia: false,
            isEditMedia: false,
            isAddMediaContent: {},
            isOpenConfirm : false,
            isLoadingAdd : false,
            addMediaContentProgress : false,
            selectedTab: 0,
            selectedTrainingMedia: false,
            selectedRejections: false,
            mediaReasons: [],
            isEditReason: false,
            isAddReason: false,
          
        }
    }
    componentDidMount(){
        this.fetchMedia()
    }

    onChangeAddMedia = (key, value) => {
        let { addMedia } = this.state;
        addMedia[key] = value;
        this.setState({addMedia});
    }
    
    fetchMedia = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id : this.props.selectedJob.job_id ,
                type: "TRAINING",
                category: "PRIMARY"
            }
            let apiData = await ApiEndpoints.Jobs.fetchJobMedia(body);
            let mediaList = apiData.data.data.sort((a, b) => a.step_number > b.step_number ? 1 : -1);
            this.setState({ mediaList })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addMediaSubmit = async (e) => {
        e.preventDefault();
        let {title, category, step_number, consumer_type} = e.target;
        store.dispatch(setLoader(true))
        try{
            var body = {
                job_id : this.props.selectedJob.job_id ,
                type : "TRAINING",
                title: title.value,
                category: "PRIMARY",
                // category: category.checked ? "SECONDARY": "PRIMARY",
                consumer_type: consumer_type.value || 0
            };
            if(step_number && step_number.value) body.step_number = step_number.value;
            if(this.state.isEditMedia) {
                body.job_media_id = this.state.isEditMedia.job_media_id;
                await ApiEndpoints.Jobs.editJobMedia(body);
            }
            else {
                body.step_number = this.state.mediaList.filter(o => o.type === "TRAINING").length + 1;
                await ApiEndpoints.Jobs.addJobMedia(body);
            }
            this.setState({isAddMedia: false, isEditMedia: false});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.fetchMedia();
        store.dispatch(setLoader(false));
    }

    deleteMedia = async (job_media_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {job_media_id};
            await ApiEndpoints.Jobs.inactiveJobMedia(body);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.fetchMedia();
        store.dispatch(setLoader(false));
    }

    showMediaContent = async (job_media_id) => {
        let {selectedTrainingMedia} = this.state;
        if(selectedTrainingMedia === job_media_id) this.setState({selectedTrainingMedia: false});
        else{
            store.dispatch(setLoader(true));
            try{
                // var body = {job_media_id};
                // var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
                // this.setState({mediaReasons: reasonResp.data.data, selectedRejections: false})
                this.setState({selectedTrainingMedia: selectedTrainingMedia === job_media_id ? false : job_media_id})
            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }

    showRejections = async (job_media_id) => {
        let {selectedRejections} = this.state;
        if(selectedRejections === job_media_id) this.setState({selectedRejections: false});
        else{
            store.dispatch(setLoader(true));
            try{
                var body = {job_media_id};
                var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
                this.setState({mediaReasons: reasonResp.data.data, selectedTrainingMedia: false, isOpenTrainingQues: false})
                this.setState({selectedRejections: selectedRejections === job_media_id ? false : job_media_id})

            }
            catch(e){
                store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
            }
            store.dispatch(setLoader(false));
        }
    }

   
    addMediaContentSubmit = async () => {
        store.dispatch(setLoader(true));
        let { isAddMediaContent, selectedTrainingMedia } = this.state;
        try{

            var contentBody = {
                job_media_id : selectedTrainingMedia,
                language : this.props.language,
                media_length : isAddMediaContent.media_length,
                description: isAddMediaContent.description,
                youtube_url: isAddMediaContent.youtube_url
            };
            if(isAddMediaContent.youtube_url){
                if(isAddMediaContent.youtube_url.includes("watch")){
                    contentBody.youtube_url = new URL(isAddMediaContent.youtube_url).searchParams.get("v");
                } else {
                    const segments = new URL(isAddMediaContent.youtube_url).pathname.split('/');
                    contentBody.youtube_url = segments.pop() || segments.pop();
                }
            }
            if(isAddMediaContent.file) {
                var jdVideoResp = await uploadFile(isAddMediaContent.file, "JOB_DATA", (progress) => this.setState({addMediaContentProgress : progress}));
                contentBody.file_key = jdVideoResp.file_id;
            }
            if(!contentBody.file_key && !isAddMediaContent.youtube_url){
                throw new Error("Mandatory details missing: Video file or youtube link");
            }
            await ApiEndpoints.Jobs.addJobMediaContent(contentBody);
            this.fetchMedia();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        this.setState({addMediaContentProgress : false, isAddMediaContent: {}})
        store.dispatch(setLoader(false));
    }

    deleteMediaContent = async (job_media_content_id) => {
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.inactiveJobMediaContent({job_media_content_id});
            this.fetchMedia();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    editTaggedReason = async (e, media) => {
        e.preventDefault();
        store.dispatch(setLoader(true))
        try{
            let body = {
                job_id: this.props.selectedJob.job_id,
                job_media_id: media.job_media_id,
                reason: e.target.taggedReason.value.split(",").map(v => v.trim())
            }
            await ApiEndpoints.JobTask.editTaggedReason(body);
            this.showMediaContent(media.job_media_id);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    addMediaReason = async (e, media) => {
        e.preventDefault();
        store.dispatch(setLoader(true));
        let {mediaReasons} = this.state;
        try{
            let body = {
                job_id: this.props.selectedJob.job_id,
                job_media_id: media.job_media_id,
                reason: e.target.reason.value,
                is_audit_required: e.target.audit.checked ? 1 : 0,
                penalty_amount: e.target.penalty.value || 0
            }
            if(media.task_training_media_id) {
                body.task_training_media_id = media.task_training_media_id;
                await ApiEndpoints.JobTask.editRejectionReason(body);
                mediaReasons = mediaReasons.map(reason => {
                    if(reason.task_training_media_id === media.task_training_media_id){
                        reason = {...reason, ...body};
                    };
                    return reason;
                })
            }
            else {
                let addResp = await ApiEndpoints.JobTask.addRejectionReason(body);
                mediaReasons.push(addResp.data.data);
            }
            this.setState({isAddReason: false, isEditReason: false, mediaReasons});

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    inactiveMediaReason = async data => {
        store.dispatch(setLoader(true));
        let {mediaReasons} = this.state;
        try{
            let body = {
                task_training_media_id: data.task_training_media_id
            }
            await ApiEndpoints.JobTask.inactiveMediaReason(body);
            mediaReasons.splice(mediaReasons.findIndex(reason => reason.task_training_media_id === data.task_training_media_id), 1);
            this.setState({mediaReasons})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    addVideoThumbnail = async (mediaContent, file) => {

        let mediaList = this.state.mediaList;
        try{
            let fileUrl = file ? URL.createObjectURL(file) : null;
            var body = {job_media_content_id: mediaContent.job_media_content_id};
            if(file) {
                var fileResp = await uploadFile(file, "JOB_DATA");
                body.thumbnail_file_key =  fileResp.file_id;
            } else body.thumbnail_file_key = 0;
            await ApiEndpoints.Jobs.editJobMediaContent(body);
            mediaList = mediaList.map(media => {
                media.JobMediaContents = media.JobMediaContents.map(content => {
                    if(content.job_media_content_id === mediaContent.job_media_content_id) content.thumbnail_file_url = fileUrl;
                    return content;
                });
                return media;
            });
            this.setState({mediaList});
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    getTrainingVideoFile = (e) => {
        let { isAddMediaContent } = this.state;
        let checkSize = e.target.duration * 204.8; //per second 200kb (1024/10)
        var fileSize = isAddMediaContent.file.size/1024;

        if(fileSize > checkSize){
            isAddMediaContent.file = null;
            this.setState({ isAddMediaContent });
            return store.dispatch(setError({show: true, message: `Video size must be below ${checkSize}KB (200kb/second)`}));
        }
        isAddMediaContent.media_length = e.target.duration;
        this.setState({ isAddMediaContent });
    }

    render() {
        let { selectedTrainingMedia, isAddReason, selectedRejections, mediaList, isAddMedia, isEditMedia, isAddMediaContent, mediaReasons, isEditReason } = this.state;
        let { language, isOnlyView } = this.props;
        let trainingVideo = mediaList.map(media => {
            media.JobMediaContentsByLang = media.JobMediaContents.filter(o => o.language.toLowerCase() === language.toLowerCase())
            return media;
        });
        return(
        <>
           
                    <>
                        {!isOnlyView && <Button className="buttonred my-2" onClick={() => this.setState({isAddMedia: isAddMedia ? false : {}})} >Add New Part</Button>}
                        {!!isAddMedia &&
                        <Row className="shadow-sm my-2 card mx-1">
                            <Col>
                                <form id="addMediaForm" onSubmit={this.addMediaSubmit}>
                                <Row className="border-bottom py-2 align-items-center">
                                    
                                    <Col xs={5}>
                                        <InputFeild required name="title" placeholder="Enter Title" />
                                    </Col>
                                    {/* <Col xs={4} className="">
                                        <FormControlLabel className="mb-0" control={<Switch name="category" size="small" className="" />} label="Rejection Training?" />

                                    </Col> */}
                                    <Col xs={3}>
                                        <Select defaultValue={0} name="consumer_type" className="w-100">
                                            <option value={""} disabled>Select Audience</option>
                                            <option value={0}>Everyone</option>
                                            <option value={1}>Only Individual Partners</option>
                                            <option value={2}>Only Shop Owners</option>
                                        </Select>
                                    </Col>
                                    <Col xs={3} className="pt-2 text-right">
                                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 18}} className="mr-2">done</Icon></Button>
                                        <Button color="link" className="p-0" type="button" onClick={() => this.setState({isAddMedia: false})}><Icon style={{fontSize: 18}} className="mr-2">close</Icon></Button>
                                    </Col>
                                </Row>
                                </form>
                            </Col>
                        </Row>
                        }
                        {trainingVideo.map((media, i) => 
                            <Row className="shadow-sm my-2 card align-items-center mx-1">
                                <Col>
                                    {isEditMedia.job_media_id === media.job_media_id ? 
                                    <form id="addMediaForm" onSubmit={this.addMediaSubmit}>
                                        <Row className="border-bottom py-2 align-items-center">
                                            <Col xs={1}>
                                                <InputFeild required name="step_number" placeholder="Step No" defaultValue={media.step_number} />
                                            </Col>
                                            <Col xs={4}>
                                                <InputFeild required name="title" placeholder="Enter Title" defaultValue={media.title} />
                                            </Col>
                                            {/* <Col xs={4} className="">
                                                <FormControlLabel className="mb-0" control={<Switch defaultChecked={media.category !== "PRIMARY"} name="category" size="small" color="default" className="" />} label="Rejection Training?" />
                                               
                                            </Col> */}
                                            <Col xs={3}>
                                                <Select defaultValue={media.consumer_type || 0} name="consumer_type" className="w-100">
                                                    <option value={""} disabled>Select Audience</option>
                                                    <option value={0}>Everyone</option>
                                                    <option value={1}>Only Individual Partners</option>
                                                    <option value={2}>Only Shop Owners</option>
                                                </Select>
                                            </Col>
                                            <Col xs={12} className="pt-2 text-right">
                                                <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 18}} className="mr-2">done</Icon></Button>
                                                <Button color="link" className="p-0" type="button" onClick={() => this.setState({isEditMedia: false})}><Icon style={{fontSize: 18}} className="mr-2">close</Icon></Button>
                                            </Col>
                                        </Row>
                                    </form>
                                    :
                                    <Row className={"border-bottom py-2 align-items-center "+ (!media.JobMediaContentsByLang.length && "border border-danger") } >
                                        <Col xs={5}>
                                            <h6 className="my-2">{i+1}. {media.title}</h6>
                                        </Col>
                                        <Col xs={4}>
                                            {media.category === "SECONDARY" && <span style={{fontSize: 12, color: "red"}}>Rejection Training</span>}
                                            {!!media.consumer_type && <span className="d-block" style={{fontSize: 12, color: "#666"}}>{media.consumer_type === 1 ? "For Individual Partner" : "For Shop Owner"}</span>}
                                        </Col>
                                        <Col xs={3} className="pt-2 text-right">
                                            {media.category === "SECONDARY" && <Button className="py-1 p-0 pr-2" size="sm" color="link" style={{marginTop: "-10px", color: "black", fontSize : 12, fontWeight: "bold"}} onClick={() => this.showRejections(media.job_media_id)}>Rejections</Button>}
                                            {!isOnlyView && <><Icon style={{fontSize: 18, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.deleteMedia(media.job_media_id) })}>delete</Icon>
                                                <Icon style={{fontSize: 18, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditMedia: media})}>edit</Icon>
                                            </>}
                                            <Icon style={{fontSize: 20, cursor: "pointer"}} className="" onClick={() => this.showMediaContent(media.job_media_id)}>unfold_more</Icon>

                                            {!media.JobMediaContentsByLang.length && <span className="text-danger">No Video</span>}
                                        </Col>
                                    </Row>
                                    }

                                    {selectedRejections === media.job_media_id &&
                                       <div className="py-2">
                                            <Button className="buttonred my-2 p-0 px-1" onClick={() => this.setState({isAddReason : !isAddReason})}>Add Reason</Button>
                                            <Row className="border-bottom mb-2" style={{fontWeight : "bold"}}>
                                                <Col xs={5}><span>Reason</span></Col>
                                                <Col xs={2} className="text-center"><span>Audit</span></Col>
                                                <Col xs={3} className="text-center"><span>Penalty</span></Col>
                                                <Col xs={2}><span>Action</span></Col>
                                            </Row>
                                            {!!isAddReason &&
                                            <form onSubmit={(e) => this.addMediaReason(e, media)}>
                                                <Row className="border-bottom mb-2" style={{fontWeight : "bold"}}>
                                                    <Col xs={5}><InputFeild required name="reason" placeholder="Type Reason"/></Col>
                                                    <Col xs={2} className="text-center"><Checkbox name="audit" size="small" className="" /></Col>
                                                    <Col xs={3} className="text-center"><InputFeild type="number" inputProps={{min: 0}} placeholder="Amount (if any)" name="penalty" /></Col>
                                                    <Col xs={2}>
                                                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 14}} className="mr-2">done</Icon></Button>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isAddReason : false})}>close</Icon>
                                                    </Col>
                                                </Row>
                                            </form>
                                            }
                                            {
                                            mediaReasons.map((obj,i) => 
                                            isEditReason.task_training_media_id === obj.task_training_media_id ?
                                                <form onSubmit={(e) => this.addMediaReason(e, obj)}>
                                                    <Row className="my-2">
                                                        <Col xs={5}><InputFeild required name="reason" style={{fontSize: 14}} placeholder="Type Reason" defaultValue={obj.reason} /></Col>
                                                        <Col xs={2} className="text-center"><Checkbox defaultChecked={!!obj.is_audit_required} name="audit" size="small" className="" /></Col>
                                                        <Col xs={3} className="text-center"><InputFeild type="number" inputProps={{min: 0}} defaultValue={obj.penalty_amount || ""} placeholder="Amount (if any)" name="penalty" /></Col>
                                                        <Col xs={2}>
                                                            <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 14}} className="mr-2">done</Icon></Button>
                                                            <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditReason : false})}>close</Icon>
                                                        </Col>
                                                    </Row>
                                                </form>
                                                :
                                                <Row className="" style={{fontSize : 14}}>
                                                    <Col xs={5}>{i+1}. {obj.reason}</Col>
                                                    <Col xs={2} className="text-center">{obj.is_audit_required ? "Yes" : "No"}</Col>
                                                    <Col xs={3} className="text-center">{obj.penalty_amount ? obj.penalty_amount+"Rs" : "No"}</Col>
                                                    <Col xs={2}>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isEditReason: obj})}>edit</Icon>
                                                        <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.inactiveMediaReason(obj)}) }>delete</Icon>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        
                                        </div>
                                    }

                                    
                          

                                    {selectedTrainingMedia === media.job_media_id &&
                                    <div className="px-1">
                                        {media.JobMediaContentsByLang.length ? 
                                        media.JobMediaContentsByLang.map(content => 
                                            <Row className="mt-2 border-bottom align-items-center">
                                                <Col xs={6}>
                                                    {
                                                        content.youtube_url ? 
                                                        <iframe style={{width: "100%"}} src={"https://www.youtube.com/embed/"+content.youtube_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                        : 
                                                        <video style={{width: "100%"}} preload="none" className="d-block mb-1" alt="Job Media" src={content.url} controls />
                                                    }
                                                    <div>
                                                        {!isOnlyView && <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2 float-right" onClick={() => this.setState({isOpenConfirm : () => this.deleteMediaContent(content.job_media_content_id) })}>delete</Icon>}
                                                    </div>
                                                </Col>
                                                <Col xs={6}>
                                                    {
                                                        content.thumbnail_file_url ? 
                                                        <div className="">
                                                            <img style={{width: "100%"}} src={content.thumbnail_file_url} alt="video_thumbnail" />
                                                            <div className="text-right">
                                                                <Icon onClick={()=> this.addVideoThumbnail(content, null)} className="mt-1" style={{cursor: "pointer", fontSize: 18}}>delete</Icon>
                                                            </div>
                                                        </div>
                                                        :
                                                        <ImageUploadFeild required className="my-2 text-center" label="Add Thumbnail" onChange={(file) => this.addVideoThumbnail(content, file)}/>
                                                    }

                                                </Col>
                                            </Row>
                                        )
                                        : 
                                        ( isOnlyView ? 
                                            <p className="m-4"><em>No Video</em></p>
                                            :
                                            <>
                                                <Row>{this.state.addMediaContentProgress !== false && <Col xs={12}><Progress animated color="info" value={this.state.addMediaContentProgress}>{this.state.addMediaContentProgress}%</Progress></Col>}</Row>
                                                <Row className="my-3 align-items-center">
                                                    {/* <Col xs={3} className="text-center">
                                                        <VideoUploadFeild id="video_upload_input_training" label={"Add Training Video"} onChange={(file) => {isAddMediaContent.file = file; this.setState({isAddMediaContent})}} onLoadedMetadata={this.getTrainingVideoFile} />
                                                        <span className="small">Duration: {Math.round(isAddMediaContent.media_length) || 0}sec</span>
                                                    </Col>
                                                    <Col xs={"auto"}><h5>Or</h5></Col> */}
                                                    <Col>
                                                        <h6>Youtube Video</h6>
                                                        <Row className="align-items-center" noGutters>
                                                            <Col>
                                                                <InputFeild placeholder="Youtube Video Link"  value={isAddMediaContent.youtube_url} onChange={(e) => {isAddMediaContent.youtube_url = e.target.value; this.setState({isAddMediaContent}) }} />
                                                            </Col>
                                                            {/* <Col xs={"auto"}><span>https://www.youtube.con/embed/</span></Col><Col><InputFeild placeholder="Youtube Video Id"  value={isAddMediaContent.youtube_url} onChange={(e) => {isAddMediaContent.youtube_url = e.target.value; this.setState({isAddMediaContent}) }} /></Col> */}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} className="py-2 text-right">
                                                        <Button color="danger" disabled={this.state.addMediaContentProgress} size="sm" className="mr-3 py-0" onClick={this.addMediaContentSubmit}>Save</Button>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                        
                                        }
                                    </div>
                                    }
                                </Col>
                            </Row>
                        )}
                    </>
            
            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
        </>
      );
  }
}
export default TrainingVideo;




