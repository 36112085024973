import { Icon } from '@material-ui/core';
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import store from '../../store';
import ApiEndpoints from "../../constants/api_endpoints";
import { setError, setLoader } from '../../actions/action-creators';
import moment from 'moment';

class UnpublishedNotification extends Component {
    constructor(props) {
      super(props)
    
      this.state ={
        pendingNotification :[],       
      }
    }
    componentDidMount(){
      this.fetchPendingNotification();
    }

    fetchPendingNotification = async () => {
      store.dispatch(setLoader(true));
      try{
      var body ={
          notification_type : "NOTIFICATION",
          is_publish: 0
        }
        let apiData = await ApiEndpoints.Notification.fetchPendingNotification(body);
        let pendingNotification = apiData.data.data;
        this.setState({ pendingNotification })
      }catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false))
    }

  updateNotification = async (status, notification) => {    
      store.dispatch(setLoader(true));
      let { pendingNotification } = this.state;
      try{
          var body = {
            notification_id : notification.notification_id,
            notification_type : "NOTIFICATION",
            is_publish: status,
            origin_type: notification.origin_type ? "JOB" : "GENERAL",
            origin_id: notification.origin_id
          }
          await ApiEndpoints.Notification.updateNotification(body); 
          pendingNotification = pendingNotification.filter(o => o.notification_id !== notification.notification_id);
          this.setState({ pendingNotification });
      }
      catch(e){
          store.dispatch(setError({show: true,  message: e.response ? e.response.data.message : e.toString()  }))
      }
      store.dispatch(setLoader(false));
  }

  render() {
    let { pendingNotification } = this.state;
    let {jobListById} =this.props
    return (
      <div style={{minWidth: 350,maxWidth: 400}}>
        <Icon fontSize="small" style={{ cursor: "pointer", position: "absolute", right: 5, top: 5}} onClick={() => this.fetchPendingNotification()}>autorenew</Icon>
          <h6 className="text-center mt-3">Pending Message Actions</h6>
           
             {pendingNotification.length ?  pendingNotification.map((notification) => {
                return <div className="shadow-sm bg-light m-3 border-secondary p-2 align-items-center" style={{borderRadius: 10, cursor: "pointer"}}>
              <strong> {jobListById[notification.origin_id] ? jobListById[notification.origin_id].Employer.employer_name + " - "+ jobListById[notification.origin_id].title : ""}</strong>
                <span className="d-block" style={{fontSize:14}}>{notification.title}</span>
                <span className="d-block" style={{fontSize:14}}>{notification.message}</span>
                <Row>
                  <Col>
                    <em className='small'>{notification.UserDetail && notification.UserDetail.first_name}</em>
                  </Col>
                  <Col>
                  <em className='small'>{moment(notification.created_on).utcOffset("+05:30").format("hh:mm A, Do MMM'YY")}</em>
                  </Col>
                </Row>
                <div className="mt-2">
                    <Button className='btn w-50 btn-success' onClick={()=> this.updateNotification(1,notification)} style={{fontSize:12}}>Approve</Button>
                    <Button className='btn w-50 btn-danger' onClick={()=> this.updateNotification(2,notification)} style={{fontSize:12}}>Reject</Button>
                </div> 

            </div>          
             }
       ) : <em className='m-3 d-block'>no data found</em>}
       
      </div>
    )
  }
}

export default withRouter(props => <UnpublishedNotification {...props} />);