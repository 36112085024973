import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow  } from 'google-maps-react';

const google = window.google;

class GoogleMap extends Component {
 
  constructor(props) {
    super(props);
    
  }

  rad2degr = (rad) => { return rad * 180 / Math.PI; }
  degr2rad = (degr) => { return degr * Math.PI / 180; }
  getLatLngCenter =(latLngInDegr) => {
   if(latLngInDegr.length == 0) return [28.6466772, 76.8130658, 50];
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i=0; i<latLngInDegr.length; i++) {
        var lat = this.degr2rad(latLngInDegr[i]["lat"]);
        var lng = this.degr2rad(latLngInDegr[i]["long"]);
        // sum of cartesian coordinates
        sumX += Math.cos(lat) * Math.cos(lng);
        sumY += Math.cos(lat) * Math.sin(lng);
        sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longtitude
    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);
    var coordDistFromCenter = latLngInDegr.map(latLong => this.distance(this.rad2degr(lat), this.rad2degr(lng), latLong.lat, latLong.long, "K"))
    return [this.rad2degr(lat), this.rad2degr(lng), Math.max(...coordDistFromCenter)];
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1/180
    var radlat2 = Math.PI * lat2/180
    var theta = lon1-lon2
    var radtheta = Math.PI * theta/180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180/Math.PI
    dist = dist * 60 * 1.1515
    if (unit=="K") { dist = dist * 1.609344 }
    if (unit=="N") { dist = dist * 0.8684 }
    return dist
  }
  render() {
    let { markers } = this.props;
    let center = this.getLatLngCenter(markers)
		return (
      <Map
          google={this.props.google}
          zoom={parseInt(2000/center[2]) > 14 ? 14 : parseInt(2000/center[2])}
          // onZoomChanged={console.log}
          style={{height : "95%" , width : "94%"}}
          center={{lat: center[0], lng: center[1] }}
        >
        {markers.map((loc, i) => 
            <Marker
               key={i}
                onClick={console.log}
                name={'User Location'}
                title={loc.marker_title || "User Location"}
                position={{lat: loc.lat, lng: loc.long}}
                icon={{
                    url: loc.marker_image || "pin_grey.svg",
                    scaledSize: loc.marker_size ? new window.google.maps.Size(loc.marker_size,loc.marker_size) : new window.google.maps.Size(40,40)
                }} />
          
        )}
      </Map>
		)
	}
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBl-oVe_L607C1NZtZXtsdUXxhWR8NwIYI'
})(GoogleMap);
