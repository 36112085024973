


const HelloWorldText = ( 
  `
  
  <html>
  <head>
  <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
  <style>
      .logo1 {
          width: 175px;
      }
      .wrapper {
          background-color: #fff;
          padding: 20px;
          font-family: "Times New Roman";
          width: 1100px;
          /* -webkit-transform-origin: 0 0;
          transform-origin: 0 0;  */
          margin: auto;
          z-index: 99999999;
      }
      .sub-heading-1 {
        font-size: 1.6rem;
        font-weight: 500;
      }
      .sub-heading-2 {
        font-size: 1.4rem;
        font-weight: 500;
      }
      .sub-heading-3 {
        font-size: 1.4rem;
        font-weight: 700; 
      }
      .r-heading-5{
        font-size: 1.4rem;
        font-weight: 700; 
      }
      table {
        min-width: 50%;
      }
      table, td, th{
        border: 1px solid black;
      }
      td{
        padding: 5px
      }
  </style>
  </head>
  <body>
  <div class="wrapper sub-heading-1" >
          <div class="row justify-content-between align-items-center  mr-2">
              <!-- <img class="logo1" src="https://s3.ap-south-1.amazonaws.com/pmw.assets.public/img/PMW_300.png" alt="logo"/>
              <img style="height:35px" src="https://www.reculta.com/assets/images/logo/Reculta%20white%20logo%202.png" alt="logo"/> -->
          </div>
            <div class="mx-0 mt-5 text-center">
              <span class="sub-heading-3" >WO/PMW/{{work_order_id}}}</span>
            </div>
            <div class="mx-0 text-center" >
              <span class="sub-heading-3 " >SERVICE PARTNER - WORK AGREEMENT </span>
            </div>
            <div class="mx-0 text-center" >
              <span style="font-size:16px;" >(An initiative by Zipgrid, powered by PickMyWork)</span>
            </div>
            <div class="mx-0 my-2 text-center sub-heading-3" >
              <span style="font-size:18px;" >Lakshya</span>
            </div>
            <div class="mx-0 my-2 text-center sub-heading-3" >
              <span style="font-size:18px;" >We Progress</span>
            </div>
            <div style="min-height:500px" class="sub-heading-2 mt-4">
              <div class="mx-0">
                <p><strong>MyAashiana Management Services Private Limited</strong>, a company registered under&nbsp;Companies Act, 2013 having its registered office at B 605, Universal Business Park, Off. Saki Vikar Raod, Andheri East, Mumbai City, Maharashtra, 400072 (“<strong>Zipgrid</strong>” or “<strong>Company</strong>”) which term shall, unless repugnant to the context or meaning thereof, mean and include its assigns/assignees, affiliates, subsidiaries, associates, administrators and successors) of the <strong>First Part</strong>;</p>
                <p><strong>AND</strong></p>
                <p><i>{{user.first_name}}</i>, Aadhar Number <i>{{aadhar_number}}</i> (hereinafter referred to as <strong>Service Partner</strong>) which expression shall unless repugnant to the context or meaning thereof be deemed to mean and include his legal heirs (where applicable), successors and assigns) of the <strong>Second Part</strong>;</p><p>The party of first part and of second part be individually referred to as “Party” and collectively referred to as “Parties”.</p>
                %s
              </div>
              <div class="mx-0" >
                <span class="r-heading-5 my-4" >Remuneration</span>
              </div>
              <div class="mx-0 mt-3" >
                %s
              </div>
              <div class="mx-0" >
                  <span class="r-heading-5 my-4" >Important</span>
                </div>
                <div class="mx-0 my-4">  
                  %s
                </div>
            </div>
            <div class="footer">
                <div class="row mt-5 justify-content-between" >
                  <div class="col-6">
                      <div><span>On behalf of MyAashiana Management Services Private Limited</span></div>
                      <div class="col-6 border" style="width: 250px; height:150px;">
                        <img onerror="this.style.display = 'none'" style="width : 90%; max-height:90%; margin:5%;" src="{{authSignUrl}}" alt="logo"/>
                      </div>
                      <div><span>Jaydip Popat, Chief Executive Officer</span></div>
                      <div><span>Date: {{shortlistedDate}}</span></div>
                  </div>
                  <div class="col-6 text-right">
                    <span>Acknowledged by </span>
                    <div class="row justify-content-end">
                      <div class="col-6 border" style="width: 250px; height:150px;">
                        <img onerror="this.style.display = 'none'" style="width : 90%; max-height:90%; margin:5%;" src="{{userSignUrl}}" alt="logo"/>
                      </div>
                    </div>
                    <div><span>Signature of Zipgrid Service Partner</span></div>
                    <div><span class="r-heading-5">Partner Name : {{user.first_name}}</span></div>
                    <div><span class="r-heading-5">Partner ID : PMW{{user.id}}</span></div>
                    </div>
                </div>
               
                </div>
        </div>
   </body>
  </html>
  `
    );
  export default HelloWorldText;
  