import React, { Component } from "react";
import { Input, InputAdornment, Icon, Button } from '@material-ui/core';

class InputFeild extends Component {
    constructor(props) {
      super(props);
      this.state = {
        variable : "Hello" ,
        }
      }


    render() {
        let props = {...this.props};
        props.className = "w-100 "+ (props.className || "");
        if(props.icon){
          props[(props.iconPosition || "end")+"Adornment"]=
            <InputAdornment position={props.iconPosition || "end"}>
              { props.onClickIcon ? 
              <Button onClick={props.onClickIcon}><Icon className="text-muted">{props.icon}</Icon></Button>
              :
              <Icon style={{fontSize: 14}} className="text-muted">{props.icon}</Icon>
              }
            </InputAdornment>
        }
        return(
            <span className="d-block"><Input className {...props} /></span>
        );
     }
    }
    export default InputFeild;