

import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import "../../../../styles/button.css";
import ApiEndpoints from "../../../../constants/api_endpoints";
import InputFeild from "../../../global/form/input";
import JobTaskInputs from "./job_task_inputs";
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";

let moment = require("moment");

class JobTaskConfigCreate extends Component {
    constructor(props) {
      super(props);
      this.state = {
            gsheet_id: "",
            isEditInput:null,
            inputs: [
                { 
                    "label": "User Mobile Number",
                    "placeholder": "",
                    "is_primary": true,
                    "input_by": "CUSTOMER",
                    "input_category": "MOBILE",
                    "input_format": "number",
                    "validation": { "required": true, "unique": true, "length": 10, "exactLength": 10 }
                },
                {
                    "label": "User Name",
                    "is_primary": true,
                    "input_by": "CUSTOMER",
                    "input_category": "MERCHANT_NAME",
                    "input_format": "text",
                    "validation": { "required": true }
                  }
            ]
        }
       }

    async componentDidMount(){
    }

    appendNewInput = () => {

        let { inputs } = this.state;

        let newInput = { };

        inputs.push(newInput);

        this.setState({ inputs })
    }

    removeInput=(indexInput) => {
        let { inputs } = this.state;
        inputs.splice(indexInput, 1);
        this.setState({inputs});
    }

    onChangeInput = (inputIndex, updatedInput) => {
        this.setState(prevState => {
            const inputs = [...prevState.inputs];
            inputs[inputIndex] = updatedInput;
            return { inputs };
        });
        // let { inputs } = this.state;
        // inputs[inputIndex] = updatedInput;
        // this.setState({inputs});
    }


    onSubmitForm = async (e) => {
         e.preventDefault();
         let inputs = this.state.inputs.map((input,inputIndex) => ({
          input_id: inputIndex+1,       
          ...input               
         }));
         let formData = {
            "job_id": this.props.job.job_id,
            "input_by": "USER",
            "target_label": "How many Leads can you on-board in a week?",
            "reportNotificationTitle": this.props.job.Employer.employer_name + "-" + this.props.job.title,
            "google_sheet_id": this.state.gsheet_id,
            "appendManager": false,
            "is_payment_automated": "GENERIC",
            "response_status": {
              "status": {
                "label": "Verification Status",
                "values": {
                  "PENDING": { "value": 0, "label": "Pending", "default": true },
                  "VERIFIED": {"value": 1,"label": "Verified" },
                  "REJECTED": {"value": 2,"label": "Rejected" }
                }
              }
            },
            "input": {
              "sections": [
                {
                  "heading": "Onboarding",
                  "title": "",
                  "input_groups": [ {   "heading": "",   "title": "",   "inputs": inputs}]
                }
              ]
            }
          };
          store.dispatch(setLoader(true));
          let formBody = {form_config: JSON.stringify(formData)};
          let formResponse = await ApiEndpoints.JobTask.createForm(formBody);
          let formID = formResponse.data.data.form_id;
          let jobTaskBody = {
            job_id: this.props.job.job_id,
            form_id: formID,
            task_type: "USER_ONBOARD", 
            task_title: "Onboarding Form"
           };
          let jobTaskResponse = await ApiEndpoints.JobTask.create(jobTaskBody);
          store.dispatch(setLoader(false));
          this.props.onClose();
    }

   
    render() {
        let { gsheet_id, inputs } = this.state;
        let {  isEditInput } = this.state;
        
        return(
           
            <>
             <form onSubmit={this.onSubmitForm}>
                
                            <Row className={"my-2 align-items-center bg-light border-secondary pt-2"}>
                                <Col className={"text-left"} xs={"auto"}>
                                    <strong><a className="inputfield" target="_blank">G-Sheet Link</a></strong>
                                </Col>
                                <Col className={"text-right"}>
                                    <InputFeild placeholder="GSheet link" value={gsheet_id} onChange={(e) => { this.setState({ gsheet_id: e.target.value }) }}></InputFeild>
                                </Col>
                                <Col className={"text-right"} xs={12}>
                                  <em className={"small"}>*Please ensure to transfer ownership of this sheet to support@pickmywork.com</em>
                                </Col>
                            </Row>
                            

                            <Row className={"my-3 border-bottom align-items-center bg-light border-secondary pt-2"}>
                                <Col>
                                    <h6 className="">Inputs</h6>
                                </Col>
                            </Row>
                            {inputs.map((input, inputIndex) => 
                                <>

                                    <Row id={inputIndex} key={inputIndex} xs={4} className=" border shadow-sm mx-3 my-2 py-1"  >
                                        <Col><strong>{input.label}</strong></Col>
                                        <Col xs={2}>{input.input_by === "USER" ? "Partner" : input.input_by === "CUSTOMER" ? "Customer" : input.input_by}</Col>
                                        <Col xs={2} className="text-right"> 
                                            <Icon onClick={() => this.setState({ isEditInput: isEditInput ? null : {...input, inputIndex}})} className="mr-2" style={{cursor: "pointer"}} fontSize="small">edit</Icon>
                                            { inputIndex > 1 &&  <Icon onClick = {()=> this.removeInput(inputIndex)} style={{cursor: "pointer"}} fontSize="small">close</Icon>}
                                        </Col>
                                    </Row>
                                    {
                                        !!isEditInput && isEditInput.inputIndex === inputIndex && <JobTaskInputs  taskInput={isEditInput} onClose={() => this.setState({ isEditInput: null })}  onChangeInput={(updatedInput) => this.onChangeInput(inputIndex, updatedInput) } />
                                    }
                                    
                                </>
                            )}

                            <Row  xs={4} className=" border shadow-sm mx-3 my-2 py-1"style={{ height: '26px', lineHeight: '26px' }}>
                            <Col  className="text-center"><Icon onClick={() => this.appendNewInput()} style={{cursor: "pointer"}} fontSize="small">add</Icon></Col>
                            </Row>
                            
                            <div className="text-right my-3 border-top">
                                <Button id="add_comment_submit" type="submit" color="danger"  >Save</Button>{' '}
                                <Button id="add_comment_cancel" type="button" color="secondary" onClick={() => { this.setState({ iscreatenewclaim: false }) }} >Cancel</Button>{' '}
                            </div>
                        </form>
            </>
              
    );
  }
 }
export default JobTaskConfigCreate;
