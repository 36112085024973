import { ACTIONS } from "../constants/actions";

export default (state = false, action) => {
  switch (action.type) {
    case ACTIONS.SET_LOADER:
      return action.payload;
    default:
      return state
  }
}
