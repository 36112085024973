import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody, ModalBody, ModalHeader, ModalFooter, Modal ,FormGroup } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser ,downloadApiFile, uploadFile} from "../../../actions";
import ConfirmAction from "../../global/confirm-action";
import DatePicker from "react-datepicker";
import moment from "moment";
import { keys } from "@material-ui/core/styles/createBreakpoints";

class ManageMaterial extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isAddNewMaterial : false,
        materialList : [] ,
        isEditMaterial : false  ,
        isOpenTransactionDownload : false,
        isOpenStoreTransfer : false,
        selectedJobLocation : null,
        selectedStoreId :null,
        addMaterialInput : {input_type : "JOB"},
        stores :[],
        isOpenLimits: false,
        materialLimits : [],
        isOpenStoreReportDownload: false,
        isOpenMaterialConsumptionModal : false

    }
    }
    componentDidMount(){
        this.fetchMaterial();
        this.fetchStores();
    }

    fetchStores = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
            job_id :  this.props.job.job_id ,
        }
          let resp = await ApiEndpoints.StoreManager.fetchStore(body);
          var stores = resp.data.data;
          stores = stores.map(store => {
            let location =  this.props.job.JobLocations.filter(loc => loc.job_location_id == store.job_location_id)
              store.JobLocation = location[0] || {};
              return store;
          })
          this.setState({stores})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addMaterial = async (e) =>{
        e.preventDefault();
        e.persist()
        store.dispatch(setLoader(true))
        try{
          var body = {
              job_id : this.props.job.job_id ,
              item : [e.target.item_name.value] ,
          }
          let resp = await ApiEndpoints.StoreManager.addMaterialItem(body);
          e.target.reset();
          this.setState({isAddNewMaterial : false})
          this.fetchMaterial();
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    editMaterial = async (e) =>{
        e.preventDefault();
        e.persist()
        store.dispatch(setLoader(true))
        try{
          var body = {
              material_description_id : this.state.isEditMaterial,
              item_name : e.target.item_name.value,
              value_in_task : e.target.count.value,
              max_value_to_give : e.target.max_value_to_give.value
          }
          let resp = await ApiEndpoints.StoreManager.editMaterialItem(body);
          e.target.reset();
          this.setState({isEditMaterial : false})
          this.fetchMaterial();
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchMaterial = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           job_id : this.props.job.job_id ,
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialItem(body );
          this.setState({materialList : resp.data.data})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadMaterial = async () => {
        store.dispatch(setLoader(true))
        let { isOpenStoreReportDownload } = this.state;
        try {
            // if(!isOpenStoreReportDownload.job_location_id)
            // throw new Error("Please select a Store");
            var body = {
                job_id : this.props.job.job_id ,
                // job_location_id: isOpenStoreReportDownload.job_location_id
               }
            var resp = await ApiEndpoints.StoreManager.downloadMaterial(body);
            downloadApiFile(resp, "Download Material.csv")

        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    downloadTransaction = async () => {
        store.dispatch(setLoader(true))
        let {selectedStoreId, isOpenTransactionDownload} = this.state;
        try {
            var body = {
                job_id : this.props.job.job_id ,
                store_id : selectedStoreId,
                start_date : isOpenTransactionDownload.start_date ? moment(isOpenTransactionDownload.start_date).startOf("day").toDate() : null,
                end_date : isOpenTransactionDownload.end_date ? moment(isOpenTransactionDownload.end_date).endOf("day").toDate() : null
               }
            var resp = await ApiEndpoints.StoreManager.downloadTransaction(body);
            downloadApiFile(resp, "Download Material.csv")

        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeAdd = (key, value) => {
        let {addMaterialInput} = this.state;
        addMaterialInput[key] = value;
        this.setState({addMaterialInput});
    }
    onChangeMaterialTransfer = (key, value, isItem)=> {
        let { isOpenStoreTransfer } = this.state;
        if(!isOpenStoreTransfer.items) isOpenStoreTransfer.items = {};
        if(isItem) isOpenStoreTransfer.items[key] = value;
        else isOpenStoreTransfer[key] = value;
        this.setState({isOpenStoreTransfer})
    }
    transferMaterial = async(e) => {
        e.preventDefault();
        e.persist()
        let { isOpenStoreTransfer } = this.state;
        if(!isOpenStoreTransfer.from_store_id || !isOpenStoreTransfer.to_store_id){
            store.dispatch(setError({show: true, message: "Mandatory fields required"}));
            return;
        }
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.props.job.job_id ,
                from_store: isOpenStoreTransfer.from_store_id,
                to_store: isOpenStoreTransfer.to_store_id,
                item : Object.keys(isOpenStoreTransfer.items).map(material_description_id => { return {material_description_id, value : isOpenStoreTransfer.items[material_description_id]}} )
               }
            var resp = await ApiEndpoints.StoreManager.storeTransfer(body);
            this.setState({isOpenStoreTransfer : false})
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeMaterialLimit = (store_id, k, v) => {
        let { materialLimits } = this.state;
        materialLimits[store_id] = materialLimits[store_id] || {};
        materialLimits[store_id][k] = v;
        this.setState({materialLimits});
    }

    fetchMaterialLimit = async (material_description_id) => {
        store.dispatch(setLoader(true))
        try{
          var body = {
           material_description_id
          }
          let resp = await ApiEndpoints.StoreManager.fetchMaterialLimits(body);
          var materialLimits = resp.data.data.reduce((prev, curr) => {
              prev[curr.store_id] = curr;
              return prev;
          }, {});
          this.setState({materialLimits});
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    submitMaterialLimit = async () => {
        store.dispatch(setLoader(true))
        let { materialLimits, isOpenLimits } = this.state;
        try{
         await Promise.all(Object.keys(materialLimits).filter(storeId => !!materialLimits[storeId].material_value_id).map(async storeId => {
            await ApiEndpoints.StoreManager.editMaterialLimits(materialLimits[storeId]);
         }))
         await Promise.all(Object.keys(materialLimits).filter(storeId => !materialLimits[storeId].material_value_id).map(async storeId => {
            var body={
                store_id: storeId,
                material_description_id: isOpenLimits,
                value_in_task: materialLimits[storeId].value_in_task || 0,
                max_value_to_give: materialLimits[storeId].max_value_to_give || 0
            }
            await ApiEndpoints.StoreManager.addMaterialLimits(body);
         }))
         store.dispatch(setSuccess({show: true, message: "Material Limits updated Successfully!"}));
         
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    uploadMaterialConsumption = async (e) => {
        e.preventDefault();
        e.persist();
        let {csvFile, store_id} = e.target;
        var file = csvFile.files[0];
        store.dispatch(setLoader(true))
        try{
            // if(!store_id.value) throw new Error("Please select Store");
            let fileResp = await uploadFile(file, "REPORT_UPLOAD");
            var body = {
                key : fileResp.file_key,
                job_id : this.props.job.job_id ,
                // store_id : store_id.value
            }
            let resp = await ApiEndpoints.StoreManager.uploadMaterialConsumption(body)
            e.target.reset();
            this.setState({isOpenMaterialConsumptionModal : false});
            if(!resp.data.data){
                downloadApiFile(resp);
                store.dispatch(setError({show: true, message: "There was some error to upload data, please check downloaded CSV"}))
            }
            else{
                store.dispatch(setSuccess({show: true, message: resp.data.message || "Data uploaded successfully!"}))
            }
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }
    downloadSampleMaterialConsumption = async () => {
        store.dispatch(setLoader(true))
        try {
            var body = {
                job_id : this.props.job.job_id ,
               }
            var resp = await ApiEndpoints.StoreManager.downloadSampleMaterialConsumption(body);
            downloadApiFile(resp, "Material Consumption Sample.csv");
        }
        catch(e){
            console.log(e.response)
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
       render(){
           let {isAddNewMaterial, stores, materialList, isEditMaterial, isOpenMaterialConsumptionModal  ,isOpenTransactionDownload, selectedJobLocation, selectedStoreId,isOpenStoreTransfer,addMaterialInput, isOpenLimits, materialLimits, isOpenStoreReportDownload} = this.state;
           let {job} = this.props;
           return(   
            <div>
                <Row className="border-bottom mb-4">
                    
                    <Col md={12} xs={12} className="mb-2">
                        <Button className="buttonred" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isAddNewMaterial : true})}>Add</Button>{' '}
                        <Button onClick={this.downloadMaterial} className="buttonred ml-2" style={{height: "23px", paddingTop: "1px" , marginRight : 20}} >Download Store Report</Button>{' '}
                        {/* <Button onClick={() => this.setState({isOpenStoreReportDownload : isOpenStoreReportDownload ? false:{}, isOpenTransactionDownload: false, isOpenStoreTransfer: false})} className="buttonred ml-2" style={{height: "23px", paddingTop: "1px" , marginRight : 20}} >Download Store Report</Button>{' '} */}
                        <Button onClick={() => this.setState({isOpenTransactionDownload:isOpenTransactionDownload ? false : {}, isOpenStoreReportDownload: false, isOpenStoreTransfer: false})} className="buttonred" style={{height: "23px", paddingTop: "1px"}} >Download Issue Report</Button>{' '}
                        <Button onClick={() => this.setState({isOpenStoreTransfer:isOpenStoreTransfer ? false : {}, isOpenTransactionDownload: false, isOpenStoreReportDownload: false})} className="buttonred ml-3" style={{height: "23px", paddingTop: "1px"}} >Store Transfer</Button>{' '}
                        <Button onClick={() => this.setState({isOpenMaterialConsumptionModal: {}})} className="buttonred ml-3" style={{height: "23px", paddingTop: "1px"}} >Material Consumption</Button>{' '}
                    </Col>
                    
                    <Col xs={12}>

                        {/* <Collapse isOpen={!!isOpenStoreReportDownload}>
                            <Card className="mt-2 mb-3">
                            <CardBody>
                                <Row>
                                <Col md={5} xs={7}  style={{ marginBottom: "8px"}}>
                                    <SelectList placeholder="Select Store*" value={isOpenStoreReportDownload.job_location_id} required onChange={(e) => {isOpenStoreReportDownload.job_location_id = e.target.value; this.setState({isOpenStoreReportDownload})}} >
                                        
                                        {stores.map((details) =>
                                          <option value={details.job_location_id}>{details.JobLocation.city}</option>
                                        )}
                                    </SelectList>
                                </Col>
                                </Row>
                            <Row className=" mt-1">
                                <Col className="mt-3">
                                    <Button onClick={this.downloadMaterial} className="buttonred" style={{height: "23px", paddingTop: "1px"}} >Download</Button>{' '}
                                </Col>
                            </Row>
                            </CardBody>
                            </Card>
                        </Collapse> */}
                        <Collapse isOpen={!!isOpenTransactionDownload}>
                            <Card className="mt-2 mb-3">
                            <CardBody>
                                <Row>
                                <Col md={5} xs={8}  style={{ marginBottom: "8px"}}>
                                    <SelectList placeholder="Select Store*" value={selectedStoreId} required onChange={(e) => this.setState({selectedStoreId : e.target.value})} >
                                        
                                        {stores.map((details) =>
                                          <option value={details.store_id}>{details.JobLocation.city}</option>
                                        )}
                                    </SelectList>
                                </Col>
                                </Row>
                            <Row className=" mt-1">
                                <Col >
                                    <DatePicker
                                        selected={isOpenTransactionDownload ? isOpenTransactionDownload.start_date : null}
                                        onChange={date => {isOpenTransactionDownload.start_date = date; this.setState({isOpenTransactionDownload})}}
                                        className="mt-3"
                                        placeholderText="Start Date"
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={isOpenTransactionDownload ? isOpenTransactionDownload.end_date : null}
                                        onChange={date => {isOpenTransactionDownload.end_date = date; this.setState({isOpenTransactionDownload})}}
                                        className="mt-3"
                                        placeholderText="End Date"
                                    />
                                </Col>
                                <Col className="mt-3">
                                    <Button onClick={this.downloadTransaction} className="buttonred" style={{height: "23px", paddingTop: "1px"}} >Download</Button>{' '}
                                </Col>
                            </Row>
                            </CardBody>
                            </Card>
                        </Collapse>

                        <Collapse isOpen={!!isOpenMaterialConsumptionModal} >
                        <form onSubmit={this.uploadMaterialConsumption}>
                        <Card>
                            <CardBody>
                                <h6>Add Material Consumption by CSV</h6>
                                {/* <SelectList required name='store_id' placeholder="Select Store" value={isOpenMaterialConsumptionModal.store_id} onChange ={(e) => {isOpenMaterialConsumptionModal.store_id= e.target.value; this.setState({isOpenMaterialConsumptionModal})}}>
                                    {stores.map((details) =>
                                        <option value={details.store_id}>{details.JobLocation.city}</option>
                                    )}
                                </SelectList> */}
                               
                                <input required type="file" name="csvFile" className="mt-4 mb-2" />
                                <span className="my-1 d-block" style={{fontSize: 12}}><em>Download Sample File, Fill the details and upload here</em></span>
                                <Button onClick={this.downloadSampleMaterialConsumption} color="link">Download Sample File</Button>
                                
                                <Row className="mt-3">
                                    <Col className="text-right">
                                        <Button type="submit" size='sm' color="primary" >Upload Data</Button>{' '}
                                        <Button type="button" size="sm" color="secondary" onClick={()=> {this.setState({isOpenMaterialConsumptionModal : false})}}>Cancel</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        </form>
                        </Collapse>
                   
                        <Collapse isOpen={!!isOpenStoreTransfer}>
                            <Card className="mt-2 mb-3">
                            <CardBody>
                                <form onSubmit={this.transferMaterial}>
                                <Row>
                                    <Col xs={6} md={4} style={{ marginBottom: "8px"}}>
                                        <SelectList placeholder="From Store*" value={isOpenStoreTransfer.from_store_id} required onChange={(e) => this.onChangeMaterialTransfer("from_store_id", e.target.value)} >
                                        
                                                {stores.map((details) =>
                                                  <option value={details.store_id}>{details.JobLocation.city}</option>
                                                )}
                                            </SelectList>
                                    </Col>

                                    <Col xs={6} md={4} style={{ marginBottom: "8px"}}>
                                        <SelectList placeholder="To Store*" value={isOpenStoreTransfer.to_store_id} required onChange={(e) => this.onChangeMaterialTransfer("to_store_id", e.target.value)} >
                                         {stores.map((details) =>
                                                  <option value={details.store_id}>{details.JobLocation.city}</option>
                                                )}
                                            </SelectList>
                                    </Col>
                               </Row>
                               <Row className="mt-2 border-bottom">
                                    <Col xs={1}><h6>#</h6></Col>
                                    <Col xs={5}><h6>Material</h6></Col>
                                    <Col xs={6}><h6>Counts</h6></Col>
                               </Row>
                                        {materialList.map((obj, i) => 
                                            <Row key={i}>
                                                <Col xs={1} >{i+1}</Col>
                                                <Col xs={5}>{obj.item_name}</Col>
                                                <Col xs={6}>
                                                    <InputFeild value={(isOpenStoreTransfer.items && isOpenStoreTransfer.items[obj.material_description_id]) || ""} onChange={(e) => this.onChangeMaterialTransfer(obj.material_description_id, e.target.value, true)} type="number" placeholder="Material count to be issued" />
                                                </Col>
                                            </Row>
                                        )}
                                <Button className="buttonred float-right mt-3" style={{ fontSize: "12px",height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </form>
                            </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                    
                </Row>

                <Row className="mt-2">
                    <Col xs={1}><h6>#</h6> </Col>
                    <Col xs={5}> <h6>Material Name</h6> </Col>
                    {/* <Col xs={3}> <h6>Usage Per Task</h6> </Col>
                    <Col xs={3}> <h6>First Issue Limit</h6> </Col> */}
                </Row>
                <form onSubmit={this.addMaterial}>
                    {isAddNewMaterial && 
                        <Row className="">
                            <Col xs={1} ></Col>
                            <Col xs={5} style={{ marginBottom: "10px"}}>
                                <InputFeild name="item_name" placeholder="Material Name" />
                            </Col>
                            <Col xs={4}> 
                                <div style={{marginTop: "7px"}}>
                                   <Button color="link" type="submit"><Icon className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon>  </Button> 
                                    <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isAddNewMaterial : false})}>close</Icon>
                                </div>
                            </Col>
                         </Row>}
                </form>

            {materialList.map((obj, i) => 
                obj.material_description_id === isEditMaterial ? 
                <form onSubmit={this.editMaterial}>
                    <Row className="">
                        <Col xs={1} ></Col>
                        <Col xs={5} style={{ marginBottom: "10px"}}>
                            <InputFeild defaultValue={obj.item_name} name="item_name" placeholder="Material Name" />
                        </Col>
                        <Col xs={4}> 
                            <div style={{marginTop: "7px"}}>
                                <Button color="link" type="submit"><Icon className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon>  </Button> 
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isEditMaterial : false})}>close</Icon>
                            </div>
                        </Col>
                    </Row>
                </form>
                :
                    <Row className="my-1" key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={5}>{obj.item_name}</Col>
                        <Col xs={2} className="mb-2">
                            <Button style={{fontSize : 13,height: "23px" , paddingTop : 0}} className="py-0" onClick={() => {this.setState({isOpenLimits: obj.material_description_id}); this.fetchMaterialLimit(obj.material_description_id)}}>Limits</Button>
                        </Col>
                        <Col xs={2}>
                            <Icon onClick={() => this.setState({isEditMaterial : obj.material_description_id})} style={{cursor: "pointer"}} fontSize="small" className="float-right">edit</Icon>
                        </Col>
                        {isOpenLimits === obj.material_description_id && <Col xs="12">
                            <Collapse isOpen={isOpenLimits === obj.material_description_id}>
                            <Card style={{overflowX: "scroll"}}>
                                <CardBody style={{minWidth: 450}} >
                                    {stores.map((store) =>
                                    <Row>
                                        <Col >
                                            {store.JobLocation.city}
                                        </Col>
                                        <Col>
                                            <InputFeild value={materialLimits[store.store_id] ? materialLimits[store.store_id].value_in_task: ""} onChange={(e) => this.onChangeMaterialLimit(store.store_id, "value_in_task", e.target.value)} type="number" placeholder="Usage Per Task"/>
                                        </Col>
                                        <Col>
                                            <InputFeild value={materialLimits[store.store_id] ? materialLimits[store.store_id].max_value_to_give: ""} onChange={(e) => this.onChangeMaterialLimit(store.store_id, "max_value_to_give", e.target.value)} type="number" placeholder="Max Limit"/>
                                        </Col>
                                    </Row>
                                    )}
                                <Row className="mt-2">
                                    <Col className="text-right">
                                        <Button style={{fontSize : 13,height: "23px" , paddingTop : 0}} className="mr-2" onClick={() => this.submitMaterialLimit()} size="sm">Save</Button>
                                        <Button style={{fontSize : 13,height: "23px" , paddingTop : 0}} onClick={() => this.setState({isOpenLimits: false})} size="sm">Cancel</Button>
                                    </Col>
                                </Row>
                                </CardBody>
                            </Card>
                            </Collapse>
                        </Col>}
                    </Row>
            )}
             {/* <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} /> */}

            </div>

           );
       }
    }
export default ManageMaterial;
