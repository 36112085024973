import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { groupArrayOnKey } from "../../../actions";
import { TablePagination } from "@material-ui/core";
import moment from "moment";


class UserWalletDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            balanceData : {},
            transactions: [],
            balanceByProjects: {},
            currentTransactionPage: 0
        }
    }

    componentDidMount(){
        this.fetchProjectDetails();
        this.fetchBalance();
        this.fetchBalanceByProjects();
        this.fetchTransactions();
    }

    fetchProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, archive_status: [0 , 1, 2] }
            let projectsResp = await ApiEndpoints.UserProfile.fetchUserProjects(body)
            let projects = projectsResp.data.data
            this.setState({ projects })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }


    fetchBalance = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.Userwallet.fetchUserBalance(body)
            this.setState({ balanceData: resp.data.data[0] || {} })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchBalanceByProjects = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.Userwallet.fetchUserBalanceDetails(body)
            let balanceByProjects = groupArrayOnKey(resp.data.data, "job_id", true);
            this.setState({ balanceByProjects })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchTransactions = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id }
            let resp = await ApiEndpoints.Userwallet.fetchTransactions(body)
            let transactions = resp.data.data;
            this.setState({ transactions })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    
    
    render() {
        let { projects, balanceData, balanceByProjects, transactions } = this.state;
        return (
            <div className="px-1">
                <span style={{fontWeight: 600, borderRadius: 10}} className="bg-light border p-3 mx-3 my-2 d-inline-block">In Wallet:{" "} {balanceData.total_amount || 0}</span>
                <span style={{fontWeight: 600, borderRadius: 10}} className="bg-light border p-3 mx-3 my-2 d-inline-block">All Earning:{" "} {balanceData.credit_amount || 0}</span>
                {
                    projects.map((app, i) => balanceByProjects[app.job_id] ? 
                        <Row key={i} className={"bg-light py-2 border mt-2"} style={{borderRadius: 10}}>
                            <Col xs={7}>
                                <span style={{fontSize: 14, fontWeight: 600}}>{app.Job.Employer ? app.Job.Employer.employer_name : ""} - { app.Job.title }</span>
                            </Col>
                            <Col xs={5} className="small text-right">
                                <span className="d-block">Total Earnings: Rs {balanceByProjects[app.job_id] ? balanceByProjects[app.job_id].total_credit : 0}</span>
                            </Col>
                        </Row>
                        :
                        <></>
                    )
                }

                <h6 className="mt-3 pb-2 border-bottom">Transactions</h6>
                <Row noGutters className="border-bottom my-1" style={{fontSize: 12, fontWeight: 600}}>
                    <Col xs={2}>Amount</Col>
                    <Col xs={10}>Description</Col>
                </Row>
                {
                    transactions
                    .slice(this.state.currentTransactionPage*10, this.state.currentTransactionPage*10 + 10)
                    .map((transaction, i) => 
                        <Row noGutters key={i} className="border-bottom my-1" style={{fontSize: 12}}>
                            <Col xs={2} className={transaction.type === 1 ? "text-success": "text-danger"}>{transaction.type === 1 ? "+": "-"}{transaction.amount}</Col>
                            <Col xs={10}>
                                <span>{transaction.reason}</span>
                                <span className="d-block small">{transaction.remark} | {moment(transaction.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                            </Col>
                        </Row>
                    )
                }
                <TablePagination rowsPerPageOptions={[15]} component="div" count={transactions.length} rowsPerPage={10} page={this.state.currentTransactionPage}
                    onChangePage={ (e, page) => this.setState({currentTransactionPage : page}) }
                />
            </div>
           
        );
    }
}
export default UserWalletDetails;
