import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col, Popover, PopoverBody, PopoverHeader } from "reactstrap/lib";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import CONSTANTS from "../../../constants/index"
import { Icon, Chip } from "@material-ui/core";
import moment from "moment";
import PartnerMapDetail from "../dashboard/partner_map_detail";
import InputFeild from "../../global/form/input";
import JobInformation from "../project/job_information";

class UserProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            selectedJob: null,
            isOpenPopover: false,
            isShowJobDetails: false
        }
    }

    componentDidMount(){
       this.fetchProjectDetails();
    }

   
     

      fetchProjectDetails = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, archive_status: [0 , 1, 2] }
            let projectsResp = await ApiEndpoints.UserProfile.fetchUserActiveProjects(body)
            let projects = projectsResp.data.data.sort((a, b) => new Date(b.last_task_date) - new Date(a.last_task_date))
            this.setState({ projects })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

     


      showTaskData = async (job) => {
        store.dispatch(setLoader(true))
        try{
            var body = { job_id: job.job_id };
            let resp = await ApiEndpoints.JobTask.fetch(body);
            let taskList = resp.data.data;
            this.setState({ selectedJob: {...job, taskList} })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }
     
      downloadFileByID = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileBody = {file_id : fileId}
            let urlResp = await ApiEndpoints.Files.getFileID(fileBody);
            let fileUrl = urlResp.data.data[0] ? urlResp.data.data[0].url : null;

            const link = document.createElement('a');
            link.href = fileUrl;
            link.download = true;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    togglePopover = (job) => {
        this.setState({
            isOpenPopover: this.state.isOpenPopover ? false : {...job}
        });
    }

    onChangeArchiveStatus = (key,value) => {
        let { isOpenPopover } = this.state;
        isOpenPopover[key] = value;
        this.setState({ isOpenPopover });
    }

    archiveStatusSave = async (app) => {
        
        store.dispatch(setLoader(true))
        let { projects } = this.state;
        try{
            var body ={application_id : app.application_id , archive_status : app.application_archive_status };
            await ApiEndpoints.Applicants.update(body);
            projects = projects.map(obj => {
                if(obj.job_id === app.job_id) obj = app;
                return obj;
            });
            this.setState({projects, isOpenPopover: false})
          }
          catch(e){
              store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
          }
          store.dispatch(setLoader(false))
  
      }

    render() {
        let { projects, selectedJob, isShowJobDetails} = this.state;
        return (
           
            <>
                {!selectedJob ?
                    projects.map(job => 
                    <div>
                        <Row className="align-items-center bg-light shadow-sm mt-3 mx-1 border py-2" style={{fontSize: 14, borderRadius: 10}}>
                            <Col xs={2} className="text-center clickable" onClick={() => this.setState({ isShowJobDetails: job })}>
                                <img src={job.logo_key} style={{width: "100%", aspectRatio: "1", borderRadius: "50%"}} alt="project_logo" />
                            </Col>
                            <Col xs={7}>
                                <strong className="clickable" onClick={() => this.setState({ isShowJobDetails: job })}>{job.employer_name} - { job.job_title }</strong>
                                {job.job_archive_status !== 0 && <Chip size="small" style={{fontSize: 8}} className="ml-3" label={CONSTANTS.ARCHIVE_STATUS_TEXT[job.job_archive_status]} /> }
                                {job.first_task_date && <span className="d-block small">First Task On: {moment(job.first_task_date).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>}
                                {job.last_task_date && <span className="d-block small">Last Task On: {moment(job.last_task_date).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>}
                                <>
                                    {job.extra_details_status === 1 && <span  style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Initiated</span>}
                                    {job.extra_details_status === 2 && <span  style={{fontSize: 10, color: "orange", whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px", color: "orange"}}>check_circle_outline</Icon>ID Requested</span>}
                                    {job.extra_details_status === 3 && <span  style={{fontSize: 10, color: "green", whiteSpace: "nowrap"}} ><Icon style={{fontSize: 10, lineHeight: "14px"}}>task_alt</Icon>ID Added</span>}
                                    {job.extra_details_status === 4 && <span  style={{fontSize: 10, whiteSpace: "nowrap"}}><Icon style={{fontSize: 10, lineHeight: "12px"}}>warning</Icon>ID Expired</span>}
                                </>
                            </Col>
                            <Col xs={3} className="text-right">
                                <strong className="clickable d-block" onClick={() => this.showTaskData(job)}><u>{job.exact_task_done}</u></strong>
                                <span className="small">All Tasks</span>
                            </Col>
                            <Col xs={12} className="border-top mt-2 pt-2">
                                <Row>
                                    <Col>
                                        <span>Pending: </span><span>{(job.statusCount || {})['0'] || 0}</span>
                                        <span className="ml-3">Rejected: </span><span>{(job.statusCount || {})['2'] || 0}</span>
                                        <span className="ml-3">Verified: </span><span>{(job.statusCount || {})['1'] || 0}</span>
                                    </Col>
                                    <Col xs="auto">
                                        <span className="small font-weight-bold float-left"> {CONSTANTS.APPLICATION_STATUS_TEXT[job.status]}</span>
                                        {/* <div id={"project_"+job.job_id} onClick={() => this.togglePopover(job)} className="ml-2 small float-right"> */}
                                        <div id={"project_"+job.job_id} className="ml-2 small float-right" title={job.application_archive_message}>
                                            {/* {job.application_archive_status === 0 && <span style={{color: "green", cursor: "pointer"}}>● On</span>} */}
                                            {job.application_archive_status === 1 && <span style={{color: "orange", cursor: "pointer"}}>● Hold</span>}
                                            {job.application_archive_status === 2 && <span style={{color: "red", cursor: "pointer"}}>● Off</span>}                                    
                                        </div>
                                    </Col>
                                </Row>
                                {/*<Popover target={"project_"+job.job_id} placement="bottom" isOpen={this.state.isOpenPopover.job_id === job.job_id} toggle={() => this.setState({isOpenPopover : false })}>
                                        <PopoverHeader>Status <Icon className="float-right" style={{cursor: "pointer", fontWeight: 800, fontSize: 18}} onClick={() => this.setState({isOpenPopover : false })}>close</Icon></PopoverHeader>
                                        <PopoverBody>
                                            <Row className="justify-content-center my-3" style={{width: 300}}>
                                                <Col xs={6}>
                                                    <div className="switch-toggle" style={{fontSize: 12}}>
                                                    {
                                                        Object.keys(CONSTANTS.ARCHIVE_STATUS).map((key, i) => 
                                                            <div key={i} className={this.state.isOpenPopover.archive_status === CONSTANTS.ARCHIVE_STATUS[key] ? "active" : ""} onClick={() => this.onChangeArchiveStatus("archive_status", CONSTANTS.ARCHIVE_STATUS[key]) }>{key}</div>
                                                        )
                                                    }
                                                    </div>
                                                </Col>
                                            </Row>
                                            {this.state.isOpenPopover.archive_status === 1 && <InputFeild className="my-2 p-0" style={{fontSize: 12}} placeholder={"Enter the Hold message"} value={this.state.isOpenPopover.archive_message || ""} onChange={(e) => this.onChangeArchiveStatus("archive_message", e.target.value) } />}
                                        <Button id={"archive_status_submit"+job.job_id} size="sm" block className="buttonred mt-2" onClick={() => this.archiveStatusSave(this.state.isOpenPopover)}>Save</Button>
                                        </PopoverBody>
                                </Popover> */}
                            
                            </Col>
                        </Row>
                    </div>
                    )
                :
                <div>
                    <span className="m-2 d-block pb-2 border-bottom">
                        <Icon fontSize="small" style={{cursor: "pointer"}} className="mr-3 align-middle" onClick={() => this.setState({selectedJob: null})}>arrow_back</Icon>
                        <strong className="clickable" onClick={() => this.setState({ isShowJobDetails: selectedJob })}>{selectedJob.employer_name} - { selectedJob.job_title }</strong>
                    </span>
                    <PartnerMapDetail user={{user_id: this.props.user_id}} selectedJob={selectedJob} taskList={selectedJob.taskList} />
                </div>
                }
                <Modal centered size="lg" isOpen={!!isShowJobDetails} toggle={() => this.setState({ isShowJobDetails: null })}>
                    <ModalBody  toggle={() => this.setState({ isShowJobDetails: null })}>
                        {!!isShowJobDetails && <JobInformation job={isShowJobDetails} isOnlyView={true} />}
                    </ModalBody>
                </Modal>
            </>
                       
        );
    }
}
export default UserProjects;
