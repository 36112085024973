import React, { Component } from "react";
import {Link} from "react-router-dom";
import { Icon, Drawer, Badge, Snackbar, Slide, Input, Popover } from "@material-ui/core";
import SideMenu from "./sidebar_menu";
import store from "../../store";
import { setError, setLoader } from "../../actions/action-creators";
import ApiEndpoints from "../../constants/api_endpoints";
import PendingNotifications from "./pending_notifications";
import {io} from "socket.io-client";
import { groupArrayOnKey } from "../../actions";
import UnpublishedNotification from "./unpublished_notification";
import { Col, Row } from "reactstrap";
import PartnerDetail from "../views/user_profile";
import SupportTickets from "./support";
import VerificationInvoice from "./verification_invoice";
import OutboundCustomerCallingStatus from "./outbound-customer-calling-status";

var socket = null;
class Header extends Component {
    constructor(props) {
      super(props);
      this.ticketInterval = null;
      this.state = {
        isOpenDrawer : false,
        isOpenNotificationDrawer: false,
        isOpenMessageDrawer: false,
        pendingNotificationCount: 0,
        jobList: [],
        notificationCallAlert: false,
        notificationWhatsappAlert: false,
        isOpenPartnerSearch: null,
        userList: [],
        isOpenProfileModel: false,
        pendingTicketCount: 0,
        isOpenTicketDrawer: false,
        isOpenVerifyInvoiceDrawer: false,
        callCustomerDetails: null
        }
      }

    componentDidMount (){
      this.getJob();
      // this.fetchNotificationCount();
      this.fetchTicketCount();

      this.ticketInterval = setInterval(this.fetchTicketCount, 1000 * 120);


    const SERVER = process.env.REACT_APP_BASE_URL;
		  socket = io (SERVER, { transports: ['websocket']});
      socket.emit("join_ticket_support");
      socket.on('CUSTOMER_IN_CALL_' + store.getState().user.user_id, (resp) => {
        this.setState({callCustomerDetails: { calling_id : resp.call_id, customerData: resp }});

      });
    }

    componentWillUnmount(){
      clearInterval(this.ticketInterval)
      socket.disconnect();
    }

    getJob = async () => {
      try{
          var jobList = store.getState().jobList;
          jobList = groupArrayOnKey(jobList, "job_id", true);
          this.setState({ jobList });
      }
      catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
    }



    fetchNotificationCount = async () => {
      try{
        let apiData = await ApiEndpoints.Notification.fetchPendingActions();
        this.setState({pendingNotificationCount: apiData.data.data.counts || 0});
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
    }

    fetchTicketCount = async () => {
      try{
        let apiData = await ApiEndpoints.Support.fetchTicketCounts();
        let data = apiData.data.data;
        let pendingCounts = data[20] || 0;

        var user = store.getState().user
        // var userRole = user.UserRole
        // var isAdmin = !!userRole.filter(role => role.role_id === 1).length; 

        if(pendingCounts && user.user_id !== "yUnEHzXJuj") this.setState({isOpenTicketDrawer: true});
        // if(pendingCounts && !isAdmin) this.setState({isOpenTicketDrawer: true});

        pendingCounts = pendingCounts.toString().padStart(2, '0')

        this.setState({pendingTicketCount: pendingCounts });
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}));
      }
    }

    handlePendingWA = (data) => {
      this.setState({notificationWhatsappAlert: { message: data.message_text }});
    }

    handlePendingCall = (data) => {
      this.setState({notificationCallAlert: { message: `A new pending call` }});
    }


    searchUser = async (e) => {
      e.preventDefault();
      store.dispatch(setLoader(true));
      e.persist();
        try{
          let el = e.target.searchUser
            let text = el.value.trim();
            if(!text) return store.dispatch(setLoader(false));
            var body = {
              limit: 20,
              search : text //.toLowerCase().replace("pmw", "")
            }
            let apiData = await ApiEndpoints.User.fetchUsersByFilter(body);
            let userList = apiData.data.data.rows;
            this.setState({ userList, isOpenPartnerSearch: el });
            e.target.reset();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
   
    render() {
      let {isOpenDrawer, callCustomerDetails, isOpenNotificationDrawer,isOpenVerifyInvoiceDrawer,isOpenMessageDrawer, pendingNotificationCount, notificationCallAlert, notificationWhatsappAlert, userList} = this.state;
      var userRole = store.getState().user.UserRole
      var isAdmin = !!userRole.filter(role => role.role_id === 1).length; 
      return(

            <div className="container-fluid sticky-top bg-white border-bottom">
                 <nav className="navbar navbar-expand-lg navbar-light">
                    <Link to="/" className="navbar-brand" style={{fontSize: 18}}><img  width="90" src="/images/PMW_new_ 800X300.png" alt="" /> <b>| DASHBOARD</b> </Link>
                    {window.innerWidth < 720 && 
                      <Icon onClick={() => this.setState({isOpenDrawer : !isOpenDrawer})} className="float-right">menu</Icon>
                    }
                    <span className="ml-auto"></span>
                    
                    <ul className="navbar-nav" style={{width: 300}}>
                      <li className="nav-item w-100">
                        <form onSubmit={this.searchUser}>
                          <Input name="searchUser" className="w-100" placeholder="Search Partner" />
                        </form>
                        <Popover open={!!this.state.isOpenPartnerSearch} anchorEl={this.state.isOpenPartnerSearch} onClose={() => this.setState({ isOpenPartnerSearch: false, userList: [] })} anchorOrigin={{horizontal: "left", vertical: 'bottom' }} > 
                          <div style={{width: 300}} className="p-2">
                            {userList.length ? userList.map(user =>
                                <Row noGutters className="shadow-sm my-2 p-2 align-items-center" style={{cursor: "pointer"}} onClick={() => this.setState({isOpenProfileModel: user, isOpenPartnerSearch: null})} >
                                  <Col xs={2} className="">
                                    <img className="rounded-circle" style={{width: "100%", aspectRatio: "1"}} src={user.profile_picture_url || "/images/dummy_profile.jpg"}  alt="Profile"></img>
                                  </Col>
                                  <Col xs={10} className="pl-2">
                                    <p className="text-dark mb-0 font-weight-bold" style={{fontSize: 13}} >{user.first_name}</p>                                
                                    <p className="mb-0" style={{fontSize: 12}}>Mobile : {user.mobile && "**"+user.mobile.toString().substr(-4)} | PMW{user.id}</p>
                                  </Col>
                                </Row>
                            ) : <em className="m-3">Not found</em>}
                          </div>
                        </Popover>
                      </li>
                    </ul>

                    {isAdmin && 
                      <ul className="navbar-nav ml-3">
                        <li className="nav-item">
                        <Badge color="secondary" > 
                            <Icon className="align-middle mx-1" onClick={() => this.setState({isOpenMessageDrawer: !isOpenMessageDrawer})} style={{ cursor: "pointer"} }>notification_important</Icon>
                        </Badge>
                          
                        </li>
                      </ul>
                    }

                      {/* <ul className="navbar-nav ml-3">
                        <li className="nav-item">
                        <Badge color="secondary" > 
                            <Icon className="align-middle mx-1" onClick={() => this.setState({isOpenVerifyInvoiceDrawer: !isOpenVerifyInvoiceDrawer})} style={{ cursor: "pointer"} }>verified</Icon>
                        </Badge>
                          
                        </li>
                      </ul> */}

                    {/* <ul class="navbar-nav ml-3">
                      <li class="nav-item">
                        <Badge sx color="secondary" invisible={!pendingNotificationCount} badgeContent={pendingNotificationCount}> 
                          <Icon className="align-middle mx-1" onClick={() => this.setState({isOpenNotificationDrawer: !isOpenNotificationDrawer})} style={{ cursor: "pointer"} }>notifications</Icon>
                        </Badge>
                      </li>
                    </ul> */}

                    <ul className="navbar-nav ml-3">
                      <li className="nav-item">
                        <div style={{background: "#fef2f2", cursor: "pointer"}} className="p-2" onClick={() => this.setState({isOpenTicketDrawer: !this.state.isOpenTicketDrawer})}>
                            <Icon className="mr-2 align-middle" style={{color: "#e40708"}} fontSize="small">contact_support_outlined</Icon>
                            <span style={{color: "#e40708"}}>Partner Issues</span>
                            <span style={{color: "#fff", borderRadius: "50%", background: "#e40708"}} className="p-1 ml-2 small">{this.state.pendingTicketCount}</span>
                        </div>
                      </li>
                    </ul>

                  </nav>

                    {this.state.isOpenTicketDrawer && <SupportTickets onClose={() => this.setState({ isOpenTicketDrawer: false })} />}

                    <Drawer anchor={"left"} open={isOpenDrawer} onClose={() => this.setState({isOpenDrawer : !isOpenDrawer})}>
                      <SideMenu />
                    </Drawer>

                    <Drawer anchor={"right"} open={isOpenNotificationDrawer} onClose={() => this.setState({isOpenNotificationDrawer : !isOpenNotificationDrawer})}>
                      <PendingNotifications jobListById={this.state.jobList} onClose={() => this.setState({isOpenNotificationDrawer : !isOpenNotificationDrawer})}/>
                    </Drawer>

                    <Drawer style={{maxWidth : "90%"}} anchor={"right"} open={isOpenVerifyInvoiceDrawer} onClose={() => this.setState({isOpenVerifyInvoiceDrawer : !isOpenVerifyInvoiceDrawer})}>
                      <VerificationInvoice  onClose={() => this.setState({isOpenVerifyInvoiceDrawer : !isOpenVerifyInvoiceDrawer})}/>
                    </Drawer>

                    <Drawer anchor={"right"} open={isOpenMessageDrawer} onClose={() => this.setState({isOpenMessageDrawer : !isOpenMessageDrawer})}>
                      <UnpublishedNotification jobListById={this.state.jobList} onClose={() => this.setState({isOpenMessageDrawer : !isOpenMessageDrawer})}/>
                    </Drawer>
                    <Snackbar
                      autoHideDuration={2000}
                      TransitionComponent={(props) => notificationCallAlert ? <Slide {...props} direction="left" /> : <Slide {...props} direction="right" />}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      open={!!this.state.notificationCallAlert}
                      onClose={() => this.setState({notificationCallAlert: false})}
                      message= {notificationCallAlert && notificationCallAlert.message && (notificationCallAlert.message.substring(0, 25) + (notificationCallAlert.message.length >= 25 ? '...': ''))}
                      ContentProps={{className: "py-0", style: {minWidth: 225} }}
                      key={1}
                    />
                    <Snackbar 
                      autoHideDuration={2000}
                      TransitionComponent={(props) => <Slide {...props} direction="left" />}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      open={!!this.state.notificationWhatsappAlert}
                      onClose={() => this.setState({notificationWhatsappAlert: false})}
                      message= {notificationWhatsappAlert && notificationWhatsappAlert.message && (notificationWhatsappAlert.message.substring(0, 25) + (notificationWhatsappAlert.message.length >= 25 ? '...':''))}
                      ContentProps={{className: "py-0", style: {minWidth: 225} }}
                      key={2}
                    />

                    {!!this.state.isOpenProfileModel && <PartnerDetail {...this.state.isOpenProfileModel} toggle={() => this.setState({isOpenProfileModel : false})} />}

                    {!!this.state.callCustomerDetails && <OutboundCustomerCallingStatus customerData={this.state.callCustomerDetails.customerData || {}} calling_id={this.state.callCustomerDetails.calling_id} onClose={() => this.setState({callCustomerDetails : null})} />}

                </div>
        );
     }
    }
    export default Header;