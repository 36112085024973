
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css";
import {copyCodeToClipboard} from "../../../../actions";
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import ConfirmAction from "../../../global/confirm-action";

import InputFeild from "../../../global/form/input";
import JobTaskInputs from "./job_task_inputs";

let moment = require("moment");

class JobTaskConfig extends Component {
    constructor(props) {
      super(props);
      this.state = {
        jobTask: props.jobTask,
        isOpenConfirm : false,
        confirmMessage: null,
        isEditTaskData: false,
        draggedInputId: null,
        isEditInput: null
        }
       }

    async componentDidMount(){
    }

    addUtmLink = () => {
        let formData = this.state.jobTask.formData || {}
        if(!formData.description) formData.description = { "button_label": "Share Link","data": [] }
        formData.description.data.push({
            heading: "UTM Link",
            type: "LINK",
            data: "",
            isEdit: true
        });
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }

    onChangeTask = (k, v) => {
        let taskData = this.state.jobTask;
        taskData[k] = v;
        this.setState({ jobTask: taskData, isEditTaskData: true })
    }

    addPostBackUtmLink = () => {
        let formData = this.state.jobTask.formData || {}
        formData.custom_link ={"label": null ,link: "https://clientUrl.com?sub1={{pmw_id}}&sub2={{input_value}}", input_id: 1  }
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }

    onChangeUtmLink = (i, k, v) => {
        let formData = this.state.jobTask.formData || {}
        formData.description.data[i][k] = v;
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }

    onChangePostBackLink = (k, v) => {
        let formData = this.state.jobTask.formData || {}
        formData.custom_link[k] = v;
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }
  

    handleDragInput = (e) => {
        this.setState({draggedInputId: e.currentTarget.id});
    }

    handleDropInput = (e) => {
        let { draggedInputId } = this.state;
        let droppedInputId = e.currentTarget.id;
        if(!draggedInputId || !droppedInputId) return;
        
        let formData = this.state.jobTask.formData || {};
        let inputs = formData.input.sections[0].input_groups[0].inputs;
        let draggedInput = inputs.splice(draggedInputId, 1);
        if(!draggedInput[0]) return;
        inputs.splice(droppedInputId, 0, draggedInput[0])
        formData.input.sections[0].input_groups[0].inputs = inputs;
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }

    onChangeInput = (index, inputData) => {
        let formData = this.state.jobTask.formData || {};
        let inputs = formData.input.sections[0].input_groups[0].inputs;
        inputs[index] = inputData;
        this.setState({ jobTask: {...this.state.jobTask, formData} })
    }

    updateTaskForm = async () => {
        let {jobTask, isEditTaskData} = this.state;
        store.dispatch(setLoader(true))
        let formData = jobTask.formData || {};
        delete formData.created_on;
        delete formData.modified_on;
        delete formData.created_by;
        delete formData.modified_by;
        delete formData.is_active;
        delete formData.form_id;
        try{
            if(isEditTaskData) {
                let taskBody = {
                    job_task_id: jobTask.job_task_id,
                    client_data_buffer: jobTask.client_data_buffer,
                    expiry_buffer_days: jobTask.expiry_buffer_days,

                }
                await ApiEndpoints.JobTask.edit(taskBody);
            }
            console.log(formData);
            await ApiEndpoints.JobTask.editForm({ form_id: jobTask.form_id, form_config: JSON.stringify(formData) });
            this.props.onClose();
        }catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

   
    render() {
        let { jobTask, isEditInput } = this.state;
        let formData = jobTask.formData || {};
        var userRole = store.getState().user.UserRole;
        
        return(
           
                <div className="px-1">
                    <Row className={"my-3 border-bottom align-items-center bg-light border-secondary pt-2"}>
                        <Col>
                            <h6 className="">General</h6>
                        </Col>
                        <Col className={"text-right"}>
                            <strong><a href={"https://docs.google.com/spreadsheets/d/" + formData.google_sheet_id} target="_blank">G-Sheet Link</a></strong> 
                        </Col>
                    </Row>
                    <Row className="mt-3 align-items-center">
                        <Col xs={3}><strong>verification buffer days</strong></Col>
                        <Col xs={3}><InputFeild value={jobTask.client_data_buffer} onChange={(e) => this.onChangeTask("client_data_buffer", e.target.value)} placeholder="Enter No. of days" /></Col>
                        <Col xs={3}><strong>Expiry buffer days</strong></Col>
                        <Col xs={3}><InputFeild value={jobTask.expiry_buffer_days} onChange={(e) => this.onChangeTask("expiry_buffer_days", e.target.value)} placeholder="Enter No. of days" /></Col>
                    </Row>
                   <Row className={"my-3 border-bottom align-items-center bg-light border-secondary pt-2"}>
                        <Col>
                            <h6 className="">Inputs</h6>
                        </Col>
                    </Row>
                    {
                        formData.input.sections.map(inputSection => 
                        <>
                            <div className="small"><strong>Heading: </strong>{inputSection.heading}</div>
                            {/* {inputSection.qr_button_label && <div><strong>QR Scan button Label: </strong>{inputSection.qr_button_label}</div>} */}
                            {
                                inputSection.input_groups[0].inputs.map((input, inputIndex) => 
                                <>
                                    <Row id={inputIndex} key={inputIndex} xs={4} className="border shadow-sm mx-3 my-2 py-1" draggable={true} onDragOver={(ev) => ev.preventDefault()} onDragStart={this.handleDragInput} onDrop={this.handleDropInput}>
                                        <Col><strong>{input.label}</strong></Col>
                                        <Col xs={2}>{input.input_by === "USER" ? "Partner" : input.input_by === "CUSTOMER" ? "Customer" : input.input_by}</Col>
                                        <Col xs={2} className="text-right"> 
                                            <Icon onClick={() => this.setState({ isEditInput: isEditInput ? null : input })} className="mr-2" style={{cursor: "pointer"}} fontSize="small">unfold_more</Icon> 
                                            <Icon>reorder</Icon> 
                                        </Col>
                                    </Row>
                                    {
                                        !!isEditInput && isEditInput.input_id === input.input_id && <JobTaskInputs taskId={jobTask.job_task_id} taskInput={isEditInput} onClose={() => this.setState({ isEditInput: null })} onChangeInput={(updatedInput) => this.onChangeInput(inputIndex, updatedInput) }  />
                                    }
                                </>
                            )}
                        </>
                    )
                    } 

                    <Row className={"my-3 mt-5 border-bottom align-items-center bg-light border-secondary pt-2"}>
                        <Col>
                            <h6 className="">Links</h6>
                        </Col>
                        <Col className={"text-right"}>
                            <Icon style={{fontWeight: "bold", cursor: "pointer"}} onClick={() => this.addUtmLink()}>add</Icon>
                        </Col>
                    </Row>

                    {
                        formData.description && formData.description.data && formData.description.data.length ? 
                        formData.description.data.map((utmLink, i) => {
                            return !utmLink.isEdit ?
                            <Row className="my-2">
                                <Col xs={4}><strong className="">{utmLink.heading}: </strong></Col>
                                <Col xs={7}><span style={{wordBreak: "break-word"}}>{utmLink.data}</span></Col>
                                <Col><Icon className="font-weight-bold" style={{cursor: "pointer", fontSize: 14}}  onClick={(e) => this.onChangeUtmLink(i, "isEdit", true)} >edit</Icon></Col>
                            </Row>
                            :
                            <Row>
                                <Col xs={4}>
                                    <InputFeild value={utmLink.heading} placeholder="Link Heading" onChange={(e) => this.onChangeUtmLink(i, "heading", e.target.value)} /> 
                                </Col>
                                <Col xs={7}>
                                    <InputFeild value={utmLink.data} placeholder="Link URL" className="mt-1"  onChange={(e) => this.onChangeUtmLink(i, "data", e.target.value)} />
                                </Col>
                                <Col><Icon className="font-weight-bold" style={{cursor: "pointer", fontSize: 14}}  onClick={(e) => this.onChangeUtmLink(i, "isEdit", false)} >done</Icon></Col>
                            </Row>
                        })
                        :
                        <em>No links configured yet</em>
                    }
                    
                    <Row className={"my-3 mt-5 border-bottom align-items-center bg-light border-secondary pt-2"}>
                        <Col>
                            <h6 className="">PostBack URL</h6>
                        </Col>
                        
                        <Col className={"text-right"}>
                        {formData.custom_link ?
                            <Button color="link" style={{fontSize: 12, color: "#000"}} className="p-1" onClick={() => copyCodeToClipboard(`https://merchant.pickmywork.com/api/v2/task/data/verification/pull/callback/${jobTask.form_id}?sub2={sub2}&event={goal}`)}><Icon style={{fontSize: 12, color: "#000"}}>content_copy</Icon> URL</Button>
                            :
                            <Icon style={{fontWeight: "bold", cursor: "pointer"}} onClick={() => this.addPostBackUtmLink()}>add</Icon>
                        }
                        </Col>
                    </Row>
                    {
                        formData.custom_link && <>
                        {!formData.custom_link.isEdit ?
                            <Row className="my-2">
                                <Col xs={4}><strong className="">Client Tracking URL: </strong></Col>
                                <Col xs={7}><span style={{wordBreak: "break-word"}}>{formData.custom_link.link}</span></Col>
                                <Col xs={1}><Icon className="font-weight-bold" style={{cursor: "pointer", fontSize: 14}}  onClick={(e) => this.onChangePostBackLink("isEdit", true)} >edit</Icon></Col>
                            </Row>
                            :
                            <Row>
                                <Col xs={12} style={{fontSize: 12}}><em>Note: Use `{`{{input_value}}`}` for Mobile No, `{`{{ref_id}}`}` for Lead ID and `{`{{pmw_id}}`}` for Partner ID in client link</em></Col>
                                <Col xs={11}>
                                    <InputFeild placeholder="Paste client tracking URL" value={formData.custom_link.link} className="mt-1"  onChange={(e) => this.onChangePostBackLink("link", e.target.value)} />
                                </Col>
                                <Col><Icon className="font-weight-bold" style={{cursor: "pointer", fontSize: 14}}  onClick={(e) => this.onChangePostBackLink("isEdit", false)} >done</Icon></Col>
                            </Row>
                        }
                        </>
                    }

                    <div className="mt-4 border-top p-2 text-right">
                        <Button className="buttonred" onClick={() => this.updateTaskForm()}>Save</Button>
                    </div>


                    <ConfirmAction message={this.state.confirmMessage} isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false, confirmMessage : null})} />

                    
            </div>
              
    );
  }
 }
export default JobTaskConfig;
