import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import { addUser, groupArrayOnKey } from "../../../actions";
import Aux from "../../global/AuxComp"
import moment from "moment";
import DatePicker from "react-datepicker";
import ConfirmAction from "../../global/confirm-action";

class ManageInterviewer extends Component {
    constructor(props) {
      super(props);
      this.state = {
            interviewers : [],
            isAddNewInterviewer : false,
            isOpenAddInterviewer : false,
            selectedInterviewer : {},
            selectedJobLocationId : null,
            user : {},
            editInterviewer : {},
            editJobLocationId : null,
            isOpenInterviewerSlot : null,
            slots : [],
            newSlots : [],
            isOpenConfirm : false,

        }
    }
    componentDidMount(){
        this.fetchInterviewers();
    }
    
    fetchInterviewers = async () => {
        store.dispatch(setLoader(true));
        let { job } = this.props;
        try{
          var accessOpts = {
            access_type : "EMPLOYER",
            access_type_id : this.props.job.employer_id,
            role_id: 5
          }
          let accessResp = await ApiEndpoints.User.fetchMappedAccess(accessOpts);
          let interviewers = accessResp.data.data;
          var locationAccess = {};
          if(job.JobLocations.length){
              let locationAccessResp = await ApiEndpoints.User.fetchMappedAccess({
                access_type : "JOB_LOCATION",
                access_type_id : job.JobLocations.map(loc => loc.job_location_id).join(","),
                role_id: 5
              });
              locationAccess = locationAccessResp.data.data;
              var locations = groupArrayOnKey(job.JobLocations, "job_location_id", true)
              locationAccess = locationAccess.map(access => {
                 access.location = locations[access.access_type_id] || null;
                 return access;
              })
              locationAccess = groupArrayOnKey(locationAccess, "user_id")
          }
          interviewers = interviewers.map(manager => {
              manager.JobLocations = (locationAccess[manager.user_id] || []).filter(access => !!access.location).map(access => {return {...access.location, ...access}});
              return manager;
          })
          this.setState({interviewers})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    onChangeUser = (e) => {
        let { user } = this.state;
        user[e.target.name] = e.target.value;
        this.setState(user);
    }
    onChangeInterviewer = (e) =>{
        let {interviewers} = this.state;
        if (e.target.value !== "ADD_NEW") 
        this.setState({selectedInterviewer : {...interviewers[e.target.value], index : e.target.value} });
        else this.setState({isOpenAddInterviewer : true, user : {}})
    }

    addInterviewer = async (e) =>{
        e.preventDefault();
        let { user} = this.state;
        store.dispatch(setLoader(true))
        try{
            if(user.mobile.toString().length !== 10) throw new Error("Mobile number should be in 10 digit format.");
            var body = {...user, role_id : 5, access_type : "EMPLOYER", access_type_id : this.props.job.employer_id}
            await addUser(body);
            await this.fetchInterviewers()
            this.setState({isOpenAddInterviewer : false , user : {}})
            store.dispatch(setSuccess({show: true, message: "Intervewier Added!"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

    // addInterviewerToLocation = async () =>{
    //     let { selectedJobLocationId, selectedInterviewer } = this.state;
    //     try{
    //           var userBody = {
    //             user_id : selectedInterviewer.user_id,
    //             user_role_id : selectedInterviewer.user_role_id,
    //             access_type : "JOB_LOCATION",
    //             access_type_id : selectedJobLocationId
    //           }
    //         await addUser(userBody)
    //       this.setState({isAddNewInterviewer : false, selectedJobLocationId : null, selectedInterviewer : {}})
    //       this.props.onClose();
    //       store.dispatch(setSuccess({show: true, message: "Interviewer have been associated with Locations"}))
    //     }
    //     catch(e){
    //       store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
    //   }

    // }

    editInterviewer = async () => {
        let {editJobLocationId, editInterviewer} = this.state;
        store.dispatch(setLoader(true))
        try{
            let deletedAccessId = editInterviewer.JobLocations.filter(a => a.job_location_id !== editJobLocationId).map(a => a.access_id);
            deletedAccessId.length && await ApiEndpoints.User.inactiveAccess({access_id : deletedAccessId});
            if(editJobLocationId){
                var userBody = {
                  user_id : editInterviewer.user_id,
                  user_role_id : editInterviewer.user_role_id,
                  access_type : "JOB_LOCATION",
                  access_type_id : editJobLocationId
                }
                await addUser(userBody)
            }
            // }));
            this.setState({editInterviewer : {}})
            await this.fetchInterviewers()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))

    }
    fetchInterviewerSlots = async () => {
        store.dispatch(setLoader(true))
        let { isOpenInterviewerSlot } = this.state;
        try{
            var body = {
                job_id : this.props.job.job_id,
                user_id : isOpenInterviewerSlot.user_id,
                job_location_id : isOpenInterviewerSlot.job_location_id
            }
        let resp = await ApiEndpoints.InterviewSlot.fetchSlot(body)
        this.setState({slots : resp.data.data})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    toggleSlotCollapse = async data => {
        if(this.state.isOpenInterviewerSlot){
            this.setState({isOpenInterviewerSlot : null, newSlots : []})
        }else{
            await this.setState({isOpenInterviewerSlot : data})
            this.fetchInterviewerSlots()
        }
    }
    addNewSlots = () => {
        let { newSlots } = this.state;
        var l = newSlots.length;
        for(var i = l; i<= l+5; i++){
            newSlots.push({
                slot_date : moment().add(i, 'days').format("YYYY-MM-DD"),
                start_time : "00:00:00",
                end_time : "00:00:00"
            })
        }

        this.setState({newSlots})
    }
    onChangeSlot = (key, value, i) => {
        let { newSlots } = this.state;
        newSlots[i][key] = value;
        this.setState({newSlots});
    }
    removeNewSlot = (i) => {
        let { newSlots } = this.state;
        newSlots.splice(i, 1);
        this.setState({newSlots});
    }
    submitAddSlots = async (e) =>{
        store.dispatch(setLoader(true))
        e.preventDefault();
        let { newSlots,  isOpenInterviewerSlot } = this.state;
        try{
            await Promise.all(newSlots.map(async slot => {
                var body = {
                    ...slot,
                    job_id : this.props.job.job_id,
                    user_id : isOpenInterviewerSlot.user_id,
                    job_location_id : isOpenInterviewerSlot.job_location_id,
                    location : this.props.job.JobLocations.filter(loc => loc.job_location_id === isOpenInterviewerSlot.job_location_id)[0].city
                }
                await ApiEndpoints.InterviewSlot.addSlot(body)
            }));
            this.setState({newSlots : []})
            await this.fetchInterviewerSlots()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    deleteSlot = async (slot_id) => {
        store.dispatch(setLoader(true))
        try{
            var body = {
                slot_id 
            }
            await ApiEndpoints.InterviewSlot.inactiveSlot(body)
            await this.fetchInterviewerSlots()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deletedInterviewer = async (access_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
                access_id
              }
            await ApiEndpoints.User.inactiveAccess(body);
            await this.fetchInterviewers()
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
       render(){
           let {interviewers, isAddNewInterviewer, selectedInterviewer, selectedJobLocationId, isOpenAddInterviewer, editInterviewer, editJobLocationId, isOpenInterviewerSlot, slots, newSlots} = this.state;
           let {job} = this.props;
           let interviewerLocations = interviewers.map(interviewer => {
                    interviewer.JobLocations = [];
                    job.JobLocations && job.JobLocations.map(loc => {
                        let userAssignedLoc = loc.interviewers.filter(obj => obj.user_role_id == interviewer.user_role_id);
                        if(userAssignedLoc.length){
                            loc.access_id = userAssignedLoc[0].access_id;
                            interviewer.JobLocations.push(loc);
                        }
                    });
                return interviewer;
            })
           return(
               
            <div>
                <Row>
                    <Col>
                    <Button className="buttonred my-3" style={{height: "23px", paddingTop: "1px"}} onClick={() => this.setState({isOpenAddInterviewer : true})}>Add Interviewer</Button>{' '}
                    </Col>
                </Row>
                <Row>
                    <Col xs={1} ><h6>S.No</h6> </Col>
                    <Col xs={6}> <h6>Interviewers</h6> </Col>
                    <Col xs={5}> <h6>City Name</h6> </Col>
                </Row>
                {/* {isAddNewInterviewer && 
                <Row>
                    <Col xs={1} ></Col>
                    <Col xs={6} style={{ marginBottom: "10px"}}>
                    <SelectList addNew={true} placeholder="Select Interviewer" value={selectedInterviewer.index} required onChange={(e) => this.onChangeInterviewer(e)} >
                        {
                            interviewers.map((interviewer, i) => 
                                    <option value={i}>{interviewer.User.first_name} | {interviewer.User.mobile}</option>
                            )
                        }
                        </SelectList>
                    </Col>
                    <Col xs={3}  style={{ marginBottom: "10px"}}>
                        <SelectList placeholder="Select City" value={selectedJobLocationId} required onChange={(e) => this.setState({selectedJobLocationId : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                    </Col>
                    <Col xs={2}> 
                        <div style={{marginTop: "7px"}}>
                            <Icon onClick={this.addInterviewerToLocation} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                            <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isAddNewInterviewer : false})}>close</Icon>
                        </div>
                    </Col>
                </Row>} */}
                <Collapse isOpen={isOpenAddInterviewer}>
                    <Card>
                    <CardBody>
                    <form onSubmit={this.addInterviewer}>
                            <Row style={{marginTop: "-20px"}}>
                                <Col xs={4}>
                                    <InputFeild required name="first_name" onChange={this.onChangeUser}  className="mt-3" placeholder="Name" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild required name="mobile" type="number" onChange={this.onChangeUser} className="mt-3" placeholder="Phone Number" />
                                </Col>

                                <Col xs={4}>
                                    <InputFeild name="email" onChange={this.onChangeUser} className="mt-3" placeholder="Email Address" />
                                </Col>
                            </Row>

                            <Row style={{marginTop: "20px", float: "right"}}>
                                <Col style={{marginRight : "-22px"}}>
                                    <Button className="buttonred" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                </Col>
                                <Col>
                                    <Button onClick={() => {this.setState({isOpenAddInterviewer : false})}}  type="button" color="secondary" style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                    </Card>
                </Collapse>
                {interviewerLocations.map((interviewer, i) => 
                    editInterviewer.user_id !== interviewer.user_id ?
                    <Aux key={i}>
                        <Row>
                            <Col xs={1} >{i+1}</Col>
                            <Col xs={6}>{interviewer.User.first_name+" | "+interviewer.User.mobile}</Col>
                            <Col xs={5}>
                                <span className="float-left">{interviewer.JobLocations.map(loc => loc.city).join(", ") }</span>
                                <Icon onClick={() => this.setState({isOpenConfirm : () => this.deletedInterviewer([interviewer.access_id, ...(interviewer.JobLocations.map(loc => loc.access_id))])}) } style={{cursor: "pointer"}} fontSize="small" className="float-right">delete</Icon>
                                <Icon onClick={() => this.setState({editInterviewer : interviewer, editJobLocationId : interviewer.JobLocations.length ? interviewer.JobLocations[0].job_location_id : null})} style={{cursor: "pointer"}} fontSize="small" className="float-right ml-2 mr-2">edit</Icon>
                                {interviewer.JobLocations.length ? <Icon title="Manage Slots" onClick={() =>  this.toggleSlotCollapse({...interviewer, job_location_id : interviewer.JobLocations[0].job_location_id})} style={{cursor: "pointer"}} fontSize="small" className="float-right mr-2">schedule</Icon> : null }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            <Collapse className="my-3" isOpen={isOpenInterviewerSlot && isOpenInterviewerSlot.user_id === interviewer.user_id}>
                                <Card>
                                <CardBody>
                                        <Button size="sm" onClick={this.addNewSlots} className="mb-3">Add Slots</Button><br />
                                        {
                                        !slots.length ? 
                                        <em>No Slots Available</em>
                                        :
                                        slots.map(slot => 
                                        <Row>
                                            <Col xs={3}>
                                                <label>Date: {slot.slot_date}</label>
                                            </Col>
                                            <Col xs={3}>
                                                <label>Start Time: {slot.start_time}</label>
                                            </Col>
                                            <Col xs={3}>
                                                <label>End Time: {slot.end_time}</label>
                                            </Col>
                                            <Col xs={2}>
                                                <label>Interval: {slot.slot_interval}</label>
                                            </Col>
                                            <Col xs={1}>
                                                <Icon onClick={() => this.deleteSlot(slot.slot_id) } style={{cursor: "pointer"}} fontSize="small" className="float-right">delete</Icon>
                                            </Col>
                                        </Row>)
                                        }
                                        <form onSubmit={this.submitAddSlots}>
                                        {
                                            newSlots.map((slot, i) => 
                                                <Row key={i} className="my-2 align-items-center">
                                                    <Col xs={3}>
                                                        <label>Date: {slot.slot_date}</label>
                                                    </Col>
                                                    <Col xs={3} className="px-3">
                                                       <label>Start Time:</label>
                                                        <DatePicker
                                                            selected={moment(slot.start_time, "HH:mm:ss").toDate()}
                                                            onChange={date => this.onChangeSlot("start_time", moment(date).format("HH:mm:ss"), i)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </Col>
                                                    <Col xs={3}  className="px-3">
                                                       <label>End Time:</label>
                                                        <DatePicker
                                                            selected={moment(slot.end_time, "HH:mm:ss").toDate()}
                                                            onChange={date => this.onChangeSlot("end_time", moment(date).format("HH:mm:ss"), i)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </Col>
                                                    <Col xs={2}>
                                                        <InputFeild type="number" value={slot.slot_interval} required placeholder="Interval" onChange={(e) => this.onChangeSlot("slot_interval", e.target.value, i)} />
                                                    </Col>
                                                    <Col>
                                                        <Icon onClick={() => this.removeNewSlot(i) } style={{cursor: "pointer"}} fontSize="small" className="float-right">close</Icon>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        { newSlots.length ?
                                            <div className="float-right">
                                                <Button size="sm" className="mr-3" color="dark">Save</Button>
                                                <Button type="button" onClick={() => this.setState({newSlots : []})} size="sm" color="light">Cancel</Button>
                                            </div> : null
                                        }
                                        </form>
                                </CardBody>
                                </Card>
                            </Collapse>
                            </Col>
                        </Row>
                    </Aux>
                     : 
                    <Row key={i}>
                        <Col xs={1} >{i+1}</Col>
                        <Col xs={6}>{interviewer.User.first_name+" | "+interviewer.User.mobile}</Col>
                        <Col xs={4}>
                            <SelectList placeholder="Select City" value={editJobLocationId} required onChange={(e) => this.setState({editJobLocationId : e.target.value})} >
                            {
                                job.JobLocations && job.JobLocations.map((item, i)=> 
                                <option key={i} value={item.job_location_id}> {item.city}</option>
                                )
                            }
                            </SelectList>
                        </Col>
                        <Col>
                            <div style={{marginTop: "7px"}}>
                                <Icon onClick={this.editInterviewer} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({editInterviewer : {}})}>close</Icon>
                            </div>
                        </Col>
                    </Row>
                )}
                <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
            </div>

           );
       }
    }
export default ManageInterviewer;
