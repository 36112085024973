import React, { Component } from "react";
import DummyVideo from "../../../assets/images/videoimg.png"
import ApiEndpoints from "../../../constants/api_endpoints";

class VideoUploadFeild extends Component {
    constructor(props) {
      super(props);
      this.state = {
        video : null ,
        }
        if(this.props.src)
        this.setState({video : this.props.src})
      }
    componentDidUpdate(prevProps){
      if(this.props.src && prevProps.src !== this.props.src){
        if(typeof this.props.src === "string")
          this.setState({video : this.props.src})
        else {
          ApiEndpoints.Files.getFileID({file_id : this.props.src}).then(resp => {
            this.setState({video : resp.data.data.url})
          });
        }
      }
    }
    videoPreview = (type) =>{  
      this.setState({video: null});
        var file = this.refs.file.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          this.setState({video: reader.result});
          // var video = document.createElement('video');
          // video.preload = 'metadata';
          // video.src = reader.result;
          // video.onloadedmetadata = function() {
          //   window.alert(video.duration);
          // }
        }.bind(this);
        this.props.onChange(file)
    }

    render() {
    
        return(
           <div className={" "+(this.props.className || "")}>
              <div>
                  {this.state.video ? <video preload="metadata" onLoadedMetadata={(e) => {if(this.props.onLoadedMetadata){this.props.onLoadedMetadata(e)}}} controls alt="" src={this.state.video || DummyVideo} style={{width: "100%"}} />
                  : <img alt="" src={DummyVideo} style={{width: "100%", height: 120}} />
                  }  
              </div>
              <span className="btn btn-secondary btn-file mt-3">
                {this.props.label ||  "Upload Video"} <input accept=".mp4" id={this.props.id || "generic_video_input"} required={!this.state.video && !!this.props.required} ref="file" type="file" multiple={false} onChange={this.videoPreview}  />
              </span>
           </div>
        );
     }
    }
    export default VideoUploadFeild;
