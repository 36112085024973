import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Chip } from "@material-ui/core";

import ServicePurchase from "./service_purchase";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
        reports: [
            {
                label: "Recharge Service",
                type: "SERVICE_PURCHASE"
            }
        ],
        selectedReport: false
    };
  }

  componentDidMount() {}

  selectReport = (reportType) => {
    this.setState({ selectedReport: reportType })
  }

  render() {
    let { reports, selectedReport } = this.state;

    return <>
       {
        reports.map(obj => 
          <Chip className="mr-2" label={obj.label} style={{ ...(selectedReport === obj.type ? { color: "#e40d0d", background: "#fef2f2" } : {}) }} onClick={() => this.selectReport(obj.type)} />
        )
       }

       {
        selectedReport === "SERVICE_PURCHASE" && <ServicePurchase.ServicePurchase />
       }


    </>;
  }
}

export default Reports;
