
import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button, CardBody, Card } from 'reactstrap';
import Icon from '@material-ui/core/Icon';
import ApiEndpoints from "../../../../constants/api_endpoints";
import "../../../../styles/button.css";
import { Progress } from 'reactstrap';
import {uploadFile} from "../../../../actions";
import store from "../../../../store";
import { setError , setLoader} from "../../../../actions/action-creators";
import VideoUploadFeild from "../../../global/form/upload-video";
import ConfirmAction from "../../../global/confirm-action";
import InputFeild from "../../../global/form/input";
import { Checkbox, FormControlLabel, Select, Switch } from "@material-ui/core";

import ImageUploadFeild from "../../../global/form/upload-image";

class TaskRejectionManage extends Component {
    constructor(props) {
      super(props);
      this.addEditTrainingQuesView = React.createRef()  
      this.state = {
           
            rejectionReasons: [],
            isAddReason: false,
            reasonMedia: false
          
        }
    }
    componentDidMount(){
        this.fetchReasons()
    }

   
    fetchReasons = async () => {
        store.dispatch(setLoader(true));
        try{
            var body = {job_id: this.props.selectedJob.job_id};
            var reasonResp = await ApiEndpoints.JobTask.fetchTaggedReason(body);
            this.setState({rejectionReasons: reasonResp.data.data})

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }

    addEditReason = async (e) => {
        e.preventDefault()
        store.dispatch(setLoader(true));
        let { isAddReason } = this.state;
        try{
            var body = {
                job_id: this.props.selectedJob.job_id,
                ...isAddReason
            };
            isAddReason.task_training_media_id ?  await ApiEndpoints.JobTask.editRejectionReason(body) : await ApiEndpoints.JobTask.addRejectionReason(body);
            this.setState({ isAddReason: false })
            await this.fetchReasons();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }


    inactiveMediaReason = async data => {
        store.dispatch(setLoader(true));
        let {rejectionReasons} = this.state;
        try{
            let body = { task_training_media_id: data.task_training_media_id }
            await ApiEndpoints.JobTask.inactiveMediaReason(body);
            rejectionReasons.splice(rejectionReasons.findIndex(reason => reason.task_training_media_id === data.task_training_media_id), 1);
            this.setState({rejectionReasons})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    fetchMedia = async (reasonObj) => {
        if(!reasonObj.job_media_id) return this.setState({ reasonMedia: { selectedReason: reasonObj  } })
        store.dispatch(setLoader(true));
        try{
            var body = {
                job_id : reasonObj.job_id,
                job_media_id: reasonObj.job_media_id,
                type: "TRAINING",
                category: "SECONDARY"
            }
            let apiData = await ApiEndpoints.Jobs.fetchJobMedia(body);
            let reasonMedia = { selectedReason: reasonObj, ...(apiData.data.data[0] || {}) };
            this.setState({ reasonMedia })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    addReasonMedia = async (e) => {
        e.preventDefault();
        let { reasonMedia } = this.state;
        let youtubeLink = e.target.youtube_link.value;
        store.dispatch(setLoader(true));
        try{
            if(!reasonMedia.job_media_id){
                var body = {
                    job_id : this.props.selectedJob.job_id ,
                    type : "TRAINING",
                    title: reasonMedia.selectedReason.reason,
                    category: "SECONDARY",
                    consumer_type: 0,
                    step_number: 0
                };
                let mediaResp = await ApiEndpoints.Jobs.addJobMedia(body);
                reasonMedia.job_media_id = mediaResp.data.data.job_media_id;
            }

            var contentBody = {
                job_media_id : reasonMedia.job_media_id,
                language : this.props.language,
            };
            if(youtubeLink.includes("watch")){
                contentBody.youtube_url = new URL(youtubeLink).searchParams.get("v");
            } else {
                const segments = new URL(youtubeLink).pathname.split('/');
                contentBody.youtube_url = segments.pop() || segments.pop();
            }
            await ApiEndpoints.Jobs.addJobMediaContent(contentBody);

            await ApiEndpoints.JobTask.editRejectionReason({ task_training_media_id: reasonMedia.selectedReason.task_training_media_id, job_media_id: reasonMedia.job_media_id });

            this.setState({ reasonMedia: false })

            await this.fetchReasons();

        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deleteMediaContent = async (mediaContent) => {
        store.dispatch(setLoader(true));
        let { reasonMedia } = this.state;
        try{
            await ApiEndpoints.Jobs.inactiveJobMediaContent({job_media_content_id: mediaContent.job_media_content_id});
            await this.fetchMedia(reasonMedia.selectedReason);
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { rejectionReasons, isAddReason, reasonMedia } = this.state;
        let { language, isOnlyView } = this.props;
        if(reasonMedia && reasonMedia.JobMediaContents){
            reasonMedia.filteredJobMediaContents = reasonMedia.JobMediaContents.filter(o => o.language === language);
        }
        return(
        <div className="p-2">
            <Button className="buttonred my-2 p-0 px-1" onClick={() => this.setState({isAddReason : isAddReason ? false : {}})}>Add Reason</Button>
            {!!isAddReason && !isAddReason.task_training_media_id &&
            <form className="shadow-sm my-2 p-2" onSubmit={(e) => this.addEditReason(e)}>
                <Row className="my-2" style={{fontWeight : "bold"}}>
                    <Col xs={6}><InputFeild required placeholder="Type Client Reason" value={isAddReason.reason} onChange={(e) => { isAddReason.reason = e.target.value; this.setState({ isAddReason }) } }  /></Col>
                    <Col xs={6}><InputFeild required placeholder="Type Reason Text" value={isAddReason.reason_text} onChange={(e) => { isAddReason.reason_text = e.target.value; this.setState({ isAddReason }) } } /></Col>
                </Row>
                <Row>
                    <Col xs={2} className="text-center">
                        {!isAddReason.isExtra && <span className="small clickable text-primary" onClick={() => { isAddReason.isExtra = true; this.setState({ isAddReason }) }}>Extra Details</span>}
                        {!!isAddReason.isExtra && <><Checkbox size="small" className="" checked={!!isAddReason.is_audit_required} onChange={(e) => { isAddReason.is_audit_required = e.target.checked; this.setState({ isAddReason }) } } /> <label>Audit?</label></>}
                    </Col>
                    <Col xs={4} className="text-center">
                        {!!isAddReason.isExtra && <InputFeild type="number" inputProps={{min: 0}} placeholder="Penalty Amount (if any)" value={isAddReason.penalty_amount} onChange={(e) => { isAddReason.penalty_amount = e.target.value; this.setState({ isAddReason }) } } />}
                    </Col>
                    <Col xs={6} className="text-right">
                        <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 20}} className="mr-2">done</Icon></Button>
                        <Button color="link" className="p-0" type="button"><Icon style={{fontSize: 20}} className="mr-2" onClick={() => this.setState({isAddReason : false})}>close</Icon></Button>
                    </Col>
                </Row>
         </form>
            }

            <Row className="border-bottom my-2 small">
                <Col xs={5}><span>Reason</span></Col>
                <Col xs={2} className="text-center"><span>Audit</span></Col>
                <Col xs={2} className="text-center"><span>Penalty</span></Col>
                <Col xs={2} className="text-center"><span>Video</span></Col>
                <Col xs={1}><span>Action</span></Col>
            </Row>
            {
                rejectionReasons.map((reasonObj, i) => 
                !!isAddReason && isAddReason.task_training_media_id === reasonObj.task_training_media_id ?
                <form onSubmit={(e) => this.addEditReason(e)}>
                    <Row className="my-2" style={{fontWeight : "bold"}}>
                        <Col xs={6}><InputFeild required placeholder="Type Client Reason" value={isAddReason.reason} onChange={(e) => { isAddReason.reason = e.target.value; this.setState({ isAddReason }) } }  /></Col>
                        <Col xs={6}><InputFeild required placeholder="Type Reason Text" value={isAddReason.reason_text} onChange={(e) => { isAddReason.reason_text = e.target.value; this.setState({ isAddReason }) } } /></Col>
                    </Row>
                    <Row>
                        <Col xs={2} className="text-center">
                            {!isAddReason.isExtra && <span className="small clickable text-primary" onClick={() => { isAddReason.isExtra = true; this.setState({ isAddReason }) }}>Extra Details</span>}
                            {!!isAddReason.isExtra && <><Checkbox size="small" className="" checked={!!isAddReason.is_audit_required} onChange={(e) => { isAddReason.is_audit_required = e.target.checked; this.setState({ isAddReason }) } } /> <label>Audit?</label></>}
                        </Col>
                        <Col xs={4} className="text-center">
                            {!!isAddReason.isExtra && <InputFeild type="number" inputProps={{min: 0}} placeholder="Penalty Amount (if any)" value={isAddReason.penalty_amount} onChange={(e) => { isAddReason.penalty_amount = e.target.value; this.setState({ isAddReason }) } } />}
                        </Col>
                        <Col xs={6} className="text-right">
                            <Button color="link" className="p-0" type="submit"><Icon style={{fontSize: 20}} className="mr-2">done</Icon></Button>
                            <Button color="link" className="p-0" type="button"><Icon style={{fontSize: 20}} className="mr-2" onClick={() => this.setState({isAddReason : false})}>close</Icon></Button>
                        </Col>
                    </Row>
                </form>
                :
                <>
                    <Row key={i} className="align-items-center my-2 py-2 border border-light shadow-sm" style={{fontSize: 14}}>
                        <Col xs={5}>
                            <span className="font-weight-bold d-block">{reasonObj.reason}</span>
                            <span className="small">{reasonObj.reason_text}</span>
                        </Col>
                        <Col xs={2} className="text-center">{reasonObj.is_audit_required ? "Yes" : "No"}</Col>
                        <Col xs={2} className="text-center">{reasonObj.penalty_amount ? reasonObj.penalty_amount+"Rs" : "No"}</Col>
                        <Col xs={2} className="text-center">
                            <Button onClick={() => this.fetchMedia(reasonObj)} color="link" size="sm" style={{color: "#000"}}>{reasonObj.job_media_id ? "Video" : "Add Video"}</Button>
                        </Col>
                        <Col xs={1}>
                            <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isAddReason: {...reasonObj}})}>edit</Icon>
                            <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2" onClick={() => this.setState({isOpenConfirm : () => this.inactiveMediaReason(reasonObj)}) }>delete</Icon>
                        </Col>
                    </Row>
                    {!!reasonMedia.selectedReason && reasonMedia.selectedReason.task_training_media_id === reasonObj.task_training_media_id &&
                    <Row className="mt-2 border-bottom align-items-center">
                        {reasonMedia.filteredJobMediaContents && reasonMedia.filteredJobMediaContents[0] ?
                        <Col xs={6}>
                            {
                                reasonMedia.filteredJobMediaContents[0].youtube_url ? 
                                <iframe style={{width: "100%"}} src={"https://www.youtube.com/embed/"+reasonMedia.filteredJobMediaContents[0].youtube_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                : 
                                <video style={{width: "100%"}} preload="none" className="d-block mb-1" alt="Job Media" src={reasonMedia.filteredJobMediaContents[0].url} controls />
                            }
                            <div>
                                {!isOnlyView && <Icon style={{fontSize: 14, cursor: "pointer"}} className="mr-2 float-right" onClick={() => this.setState({isOpenConfirm : () => this.deleteMediaContent(reasonMedia.filteredJobMediaContents[0]) })}>delete</Icon>}
                            </div>
                        </Col>
                        :
                        <Col>
                            <h6>Youtube Video</h6>
                            <form onSubmit={(e) => this.addReasonMedia(e)}>
                                <Row className="align-items-center" noGutters>
                                    <Col>
                                        <InputFeild name="youtube_link" placeholder="Youtube Video Link" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} className="py-2 text-right">
                                        <Button color="danger" size="sm" className="mr-3 py-0">Save</Button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                        }
                    </Row>
                    }
                </>
                )
            }

           
            <ConfirmAction isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
        </div>
      );
  }
}

export default TaskRejectionManage;




