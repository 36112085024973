import util from "util";

import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Aux from "../../global/AuxComp";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import RichTextEditor from "../../global/form/Richtext";
import WORK_ORDER_CONTENT from "../../../constants/work_order_template";
import WORK_ORDER_CONTENT_ZIPGRID from "../../../constants/work_order_zipgrid_template";
import { Icon, IconButton } from "@material-ui/core";

class WorkOrder extends Component {
    constructor(props) {
      super(props);
      this.state = {
        workOrderList : [] ,
        selectedWorkOrder : {},
        isSureToRegenerate : false
        }
    }
    
    componentDidMount(){
        this.init();
    }
    printPreview = () => {
        let id = "work_order_print";
        const iframe = document.frames
            ? document.frames[id]
            : document.getElementById(id);
        const iframeWindow = iframe.contentWindow || iframe;
        
        iframe.focus();
        iframeWindow.print();
        
        return false;
    }
    init = async () => {
        store.dispatch(setLoader(true))
        try{
           let resp = await ApiEndpoints.Jobs.getWorkOrder({job_id :this.props.selectedJob.job_id });
           let workOrderList = resp.data.data;
           this.setState({workOrderList, selectedWorkOrder : workOrderList[0] || {}})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeAddText = (key , value) => {
        let {selectedWorkOrder} = this.state ;  
        selectedWorkOrder[key] = value ;
        this.setState({selectedWorkOrder}) ;
    }

    addWorkOrder = async() => {
        let { selectedWorkOrder } = this.state;
        store.dispatch(setLoader(true))
        try{
            let body = {
                job_id : this.props.selectedJob.job_id,
                ...selectedWorkOrder
            }
            if(selectedWorkOrder.id) await ApiEndpoints.Jobs.editWorkOrder(body);
            else await ApiEndpoints.Jobs.addWorkOrder(body);
            this.props.toggle()
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    regenerateWorkOrder = async() => {
        let { selectedWorkOrder } = this.state;
        store.dispatch(setLoader(true))
        try{
            let body = {
                job_id : this.props.selectedJob.job_id,
                work_order_id : selectedWorkOrder.id
            }
            if(selectedWorkOrder.id) await ApiEndpoints.Jobs.regenerateWorkOrder(body);
            this.setState({isSureToRegenerate: false})
            store.dispatch(setSuccess({show: true, message: "Request submitted successfully!"}))
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    render(){
           let {workOrderList, selectedWorkOrder, isSureToRegenerate} = this.state;
           let WORK_ORDER_CONTENT_F = "";
           if(this.props.selectedJob.job_id == '46' || this.props.selectedJob.job_id == '47'){
                WORK_ORDER_CONTENT_F = util.format(WORK_ORDER_CONTENT_ZIPGRID, selectedWorkOrder.content, selectedWorkOrder.remuneration, selectedWorkOrder.instruction)
           }
           else {
                WORK_ORDER_CONTENT_F = util.format(WORK_ORDER_CONTENT, selectedWorkOrder.content, selectedWorkOrder.remuneration, selectedWorkOrder.instruction)
           }

           return(
            <div>
                <Row className="border-bottom mb-3 pb-3">
                    <Col>
                    {
                        workOrderList.map(wo => 
                                <Button key={wo.id} size="sm" className="buttonred mr-3" style={{boxShadow: wo.id === selectedWorkOrder.id ? "#000000 0px 0px 5px 0px" : "none"}} onClick={() => this.setState({selectedWorkOrder: wo})}>{wo.category}</Button>
                        )
                    }
                        <Button size="sm" className="buttonred" style={{boxShadow: selectedWorkOrder.addNew ? "#000000 0px 0px 5px 0px":"none"}} onClick={() => this.setState({selectedWorkOrder: {addNew : true}})}>Add Work Order</Button>
                        {/* <Button size="sm" className="buttonred" style={{opacity : selectedWorkOrder.id ? 0.7 : 1}} onClick={() => this.setState({selectedWorkOrder: {}})}>Add Work Order</Button> */}
                    </Col>
                </Row>
                {!!workOrderList.length || selectedWorkOrder.addNew ?
                    <Aux>
                        <Row>
                            <Col xs={5}>
                                <Row>
                                    <Col xs={12} className="mt-2 pb-1 mb-1">
                                        <h6 className="mb-2">Category</h6>
                                        <InputFeild placeholder="Work order category" onChange={(e) => this.onChangeAddText("category",e.target.value)} value={selectedWorkOrder.category || ""}/>
                                    </Col>
                                    <Col xs={12} className="mt-2 pb-1 mb-1">
                                        <h6 className="mb-2">Work Order Description</h6>
                                        <RichTextEditor placeholder="Work order description" onChange={(v) => this.onChangeAddText("content",v)} value={selectedWorkOrder.content || ""}/>
                                    </Col>
                                    <Col xs={12} className="mt-3 pb-1 mb-1">
                                        <h6 className="mb-2">Payment Reimbursement</h6>
                                        <RichTextEditor placeholder="Payment reimbursement" onChange={(v) => this.onChangeAddText("remuneration",v)} value={selectedWorkOrder.remuneration || ""}/>
                                    </Col>
                                    <Col xs={12} className="mt-3 pb-1 mb-1">
                                        <h6 className="mb-2">Instruction</h6>
                                        <RichTextEditor  placeholder="instruction" onChange={(v) => this.onChangeAddText("instruction",v)} value={selectedWorkOrder.instruction || ""} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={7}>
                                <IconButton className="d-block float-right" onClick={() => this.printPreview()}><Icon>print</Icon></IconButton>
                                <iframe  id="work_order_print" title="Work Order Preview" style={{width : "200%", height : "1300px", transform: "scale(0.5)", transformOrigin : "0 0"}} srcDoc={WORK_ORDER_CONTENT_F}></iframe>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                {!!selectedWorkOrder.id &&
                                <div className="float-left">
                                    {isSureToRegenerate ? 
                                    <div>
                                        <h6>Are you sure to renegenerate, Partners have to re-sign the updated Work Order</h6>
                                        <Button className="buttonred" onClick={() => this.regenerateWorkOrder()}>Regenerate for Partners</Button>
                                    </div>
                                    :
                                    <Button className="buttonred float-left" onClick={() => this.setState({isSureToRegenerate : true})}>Regenerate for Partners</Button>
                                    }
                                </div>
                                }
                                <Button type="button" onClick={this.addWorkOrder} className="buttonred float-right mr-2" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                            </Col>
                        </Row>
                </Aux>
                    : 
                    <p><em>No work order added for this project</em></p>
                }
            </div>

           );
       }
    }
export default WorkOrder;
