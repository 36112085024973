
import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import store from "../../../store";
import ApiEndpoints from "../../../constants/api_endpoints";
import {setSuccess, setError, setLoader } from '../../../actions/action-creators';
import ConfirmAction from "../../global/confirm-action";
import InputFeild from "../../global/form/input";
import { Button } from "reactstrap";

class SendFeedback extends Component {
    constructor(props) {
      super(props);
      this.state = {
            isConfirm: false,

        }
    }

    async componentDidMount(){
     }
    
   
    submitFeedback = async (e) => {
        e.preventDefault();
        e.persist();
        store.dispatch(setLoader(true))
        let { title, description, category } = e.target;
        console.log(category);
        try {
            var body = {
                type: "DASHBOARD",
                title: title.value,
                description: description.value,
            }
            category.forEach(elem => {
                if(elem.checked) body.category = elem.value;
            })
            await ApiEndpoints.Feedback.addSuggestion(body);
            store.dispatch(setSuccess({show: true, message: "Thank You for the Feedback, Team will review it"}))
            e.target.reset();
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    render() {
        let { isConfirm } = this.state;
       
        return (
          <>
            <div className="m-3">
                <form onSubmit={this.submitFeedback}>
                    <h4 className="text-center">Feedback or Suggestion</h4>
                    <em className="small d-block text-center">Have feedback? We'd love to hear it, but please don't share sensitive information. </em>
                    <hr className="mt-3 mb-5" />
                    <strong>Title*</strong>
                    <InputFeild required={true} name="title" className="mt-2 mb-3" placeholder="Type of Feedback (Calling, Whatsapp, User Profile, Reports....)" />

                    <strong>Feedback/Suggestion Description*</strong>
                    <textarea required={true} placeholder="Enter your content in detail" name="description" rows={6} className="form-control mt-2 mb-3" />

                    <strong className="mr-4 d-block">Category</strong> 
                    <input type="radio" name="category" value="Standard" defaultChecked={true} /> <span className="mr-3">Standard</span>
                    <input type="radio" name="category" value="Important" /> <span className="mr-3">Important</span>
                    <input type="radio" name="category" value="Urgent" /> <span className="mr-3">Urgent</span>

                    <div className="mt-4 text-center">
                        <Button color="dark" className="">Submit Feedback</Button>
                    </div>
                </form>

                <em style={{position: "absolute", bottom: 50, right: 20}} className="small text-center">
                    Have questions? Send your query to <a className="text-dark" href="mailto:support@pickmywork.com">support@pickmywork.com</a>
                </em>
            </div>
            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />

          </>
        );
    }
}

export default SendFeedback;
