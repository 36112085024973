import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Icon from '@material-ui/core/Icon';
import { Button, Collapse, Card, CardBody, Table } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import "../../../styles/button.css"
import store from "../../../store";
import SelectList from "../../global/form/select"
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import InputFeild from "../../global/form/input"
import Aux from "../../global/AuxComp";
import ManageStoreManager from "../store/manage_storeManager";
import ConfirmAction from "../../global/confirm-action";

class ManageStore extends Component {
    constructor(props) {
      super(props);
        this.state = {
            isOpenAdd : false,
            stores :[],
            isOpenStoreManager : null
        }
    }
    componentDidMount(){
        this.fetchStores();
    }

    onChangeAdd = async (key, value) => {
        let {isOpenAdd} = this.state;
        isOpenAdd[key] = value;
        await this.setState({isOpenAdd})
    }

    fetchStores = async () => {
        store.dispatch(setLoader(true))
        try{
          var body = {
            job_id : this.props.job.job_id,
        }
          let resp = await ApiEndpoints.StoreManager.fetchStore(body);
          var stores = resp.data.data;
          stores = stores.map(store => {
            let location = this.props.job.JobLocations.filter(loc => loc.job_location_id == store.job_location_id)
              store.JobLocation = location[0] || {};
              return store;
          })
          this.setState({stores})
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    createStore = async () => {
        let {job_location_id} = this.state.isOpenAdd;
        if(!job_location_id){
            store.dispatch(setError({show: true, message: "Please Select Location"}))
            return;
        }
        store.dispatch(setLoader(true))
          try{
            if(!/^([a-zA-Z0-9 /:-]+)$/.test(this.state.isOpenAdd.timings)) throw new Error("Timing : Invalid format")
            var body = {
                job_id : this.props.job.job_id,
               ...this.state.isOpenAdd
              }
              await ApiEndpoints.StoreManager.addStore(body)
              this.setState({isOpenAdd : false})
              this.fetchStores();
            store.dispatch(setSuccess({show: true, message: "Store Added!"}))
          }
          catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    resetStore = async (store_id) =>{
        store.dispatch(setLoader(true))
        try{
            var body = {
            
                store_id
            }
            await ApiEndpoints.StoreManager.resetStore(body);
            await this.fetchStores();
            store.dispatch(setSuccess({show: true, message: "Store reset successfully"}))
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
       render(){
           let {isOpenAdd , stores, isOpenStoreManager} = this.state;
           let {job} = this.props;
           let locationList = job ? job.JobLocations : [];

           return(
               
            <Aux>
                <Row noGutters>
                    <Col>
                    <Button onClick={()=> this.setState({isOpenAdd : isOpenAdd ? false : {}})} className="buttonred mb-3" style={{height: "23px", paddingTop: "1px"}} >Add Store</Button>{' '}
                    </Col>
                </Row>
               
                <Table responsive>
                <thead >
                    <tr>
                        <th style={{minWidth : 100}}> <h6>City Name</h6> </th>
                        <th style={{minWidth : 175}}> <h6>Address</h6> </th>
                        <th style={{minWidth : 150}}> <h6>Timing</h6> </th>
                        <th style={{minWidth : 100}}> <h6>Actions</h6> </th>
                    </tr>
                </thead>
                <tbody>
                <tr style={{display : isOpenAdd ? "table-row" : "none", transition:"fade-in"}} >
                        <td>
                            <SelectList placeholder="Select City" value={isOpenAdd.job_location_id} onChange ={(e)=> this.onChangeAdd("job_location_id" ,e.target.value)} >
                            { 
                                locationList.map(loc => 
                                <option key={loc.job_location_id} value={loc.job_location_id}> {loc.city} </option>
                                )
                            }
                            </SelectList>
                        </td>
                        <td><InputFeild placeholder="Address" name="address" value={isOpenAdd.address || ""} onChange ={(e)=> this.onChangeAdd("address" ,e.target.value)}></InputFeild></td>
                        <td><InputFeild placeholder="Timing" name="timings" value={isOpenAdd.timings || ""} onChange ={(e)=> this.onChangeAdd("timings" ,e.target.value)}></InputFeild></td>
                        <td> 
                            <Icon onClick={this.createStore} className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                            <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isOpenAdd : false})}>close</Icon>
                        </td>
                    </tr>
                
                {stores.map((store) =>
                <Aux>
                    <tr>
                        <td>{store.JobLocation.city}</td>
                        <td>{store.address}</td>
                        <td>{store.timings}</td>
                        <td> 
                            <Icon style={{cursor: "pointer" ,fontSize: 20}} onClick={() => this.setState({isOpenStoreManager : isOpenStoreManager === store.store_id ? false : store.store_id})}>supervisor_account</Icon>
                            <Icon onClick={() => this.setState({isOpenConfirm : () => this.resetStore(store.store_id)}) } style={{cursor: "pointer" ,fontSize: "18px"}} className="ml-3">rotate_left</Icon>
                        </td>
                       
                    </tr>
                    <tr style={{display : isOpenStoreManager === store.store_id ? "table-row" : "none", transition:"fade-in"}}>
                        <td colSpan={4}>
                            <Collapse className="my-2" isOpen={isOpenStoreManager === store.store_id}>
                                <Card>
                                    <CardBody>
                                    {isOpenStoreManager === store.store_id && <ManageStoreManager store={store} job={job} onClose={() => {}}  />}
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </td>
                    </tr>
                </Aux>
                )}
                </tbody>
                </Table>
                <ConfirmAction message="Resetting store will delete all store material, material in market and also delete all users mapped to the store along with the material they have?" isOpen={!!this.state.isOpenConfirm} callback={this.state.isOpenConfirm} onClose={() => this.setState({isOpenConfirm : false})} />
            </Aux>
           

           );
       }
    }
export default ManageStore;
