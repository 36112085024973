import React, { Component } from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import InputFeild from "../../global/form/input"
import SelectList from "../../global/form/select"
import "../../../styles/button.css"
import {Icon, Switch, FormControlLabel, Tooltip} from '@material-ui/core';
import store from "../../../store";
import { setError, setSuccess, setLoader } from "../../../actions/action-creators";
import RichTextEditor from "../../global/form/Richtext";
import { Collapse, CardBody, Card, UncontrolledButtonDropdown, Input } from 'reactstrap';
import { ModalBody, ModalHeader, ModalFooter, Modal } from 'reactstrap';
import ConfirmAction from "../../global/confirm-action";
import CONSTANTS from "../../../constants";

class JobQuestion extends Component {
    constructor(props) {
      super(props);
      this.state = {
           addQuesDetails : {},
           isOpenAddQues : false,
           quesList : [] ,
           isConfirm : false,

        }
    }

    async componentDidMount(){
        await this.init() ;
    }

    init = async () =>{
        store.dispatch(setLoader(true))
        try{
            let apiData = await ApiEndpoints.Jobs.fetchJobQues({job_id : this.props.job.job_id});
            this.setState({quesList : apiData.data.data}) ;
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    onChangeAddQues = (key , value) => {
        let {addQuesDetails} = this.state ;  
        addQuesDetails[key] = value ;
        this.setState({addQuesDetails}) ;
    }

    addQues = async (e) => {
        e.preventDefault() ;
        let {addQuesDetails} = this.state;
        try{
            if(!addQuesDetails.question_category) throw new Error("Please select state");
           var body = {
               job_id : this.props.job.job_id ,
               question_category: addQuesDetails.question_category,
               heading : addQuesDetails.heading ,
               title : " " ,
               question_type : addQuesDetails.ques_type,
               options : addQuesDetails.options && addQuesDetails.options.split(",").map(o => o.trim()),
               is_mandatory : addQuesDetails.is_mandatory ? "1" : "0"
           }
         let resp = await ApiEndpoints.Jobs.addJobQues(body);
         this.setState({isOpenAddQues : false, addQuesDetails : {}}) ;
         this.init() ;
        }
        catch(e){
          store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }

    deletedJobQues = async (job_question_id) =>{
        store.dispatch(setLoader(true))
        try{
            await ApiEndpoints.Jobs.deleteJobQues({job_question_id});
            await this.init()
            this.setState({isOpenDeleteModal : false})
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
    }
    
    render(){
        let {addQuesDetails , isOpenAddQues , quesList , isConfirm} = this.state;
        return(
            <div>
               <Button id="add_question" className="buttonred" style={{height: "23px", paddingTop: "1px" }} onClick={() => {this.setState({isOpenAddQues : !isOpenAddQues})}}>Add Questions</Button>{' '}
                  <Row>
                    <Col>
                        <Collapse className="w-100" isOpen={isOpenAddQues}>
                            <Card className="mt-3">
                               <CardBody>
                               <form onSubmit={this.addQues}>
                                <Row>
                                    <Col xs={5}>
                                        <SelectList id="select_state" placeholder="Select State" value={addQuesDetails.question_category} onChange={(e) => this.onChangeAddQues("question_category", e.target.value)}>
                                            {
                                                Object.keys(CONSTANTS.JOB_QUESTION_STATES).map(state => 
                                                    <option value={state}>{CONSTANTS.JOB_QUESTION_STATES[state]}</option>
                                                )
                                            }
                                        </SelectList>
                                    </Col>
                                    <Col xs={5}>
                                        <SelectList id="question_type" placeholder="Type" value={addQuesDetails.ques_type} onChange={(e) => this.onChangeAddQues("ques_type", e.target.value)}>
                                            <option value="TEXT">Text</option>
                                            {/* <option value="TEXTAREA">Long Text</option> */}
                                            <option value="DROPDOWN">Dropdown</option>
                                            <option value="IMAGE">Image Upload</option>
                                            {/* <option value="CHECKBOX">Checkbox</option> */}
                                            {/* <option value="RADIO">Radio Options</option> */}
                                        </SelectList>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={8}>
                                        <RichTextEditor id="question_heading"  basic placeholder="Question Heading" onChange={(v) => this.onChangeAddQues("heading",v)} value={addQuesDetails.heading || ""}/>
                                    </Col>
                                    <Col xs={2}>
                                        <Tooltip title="Mandatory ?">
                                        <Switch id="is_mandatory_switch" checked={addQuesDetails.is_mandatory}  onChange={(e) => this.onChangeAddQues("is_mandatory", e.target.checked )} color="primary" />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col xs={10}>
                                        {/* <InputFeild required className="mt-2" placeholder="Question Title" onChange={(e) => this.onChangeAddQues("title", e.target.value)} value={addQuesDetails.title} /> */}
                                        {["DROPDOWN", "CHECKBOX", "RADIO"].indexOf(addQuesDetails.ques_type) > -1 && <InputFeild id="option_with_comma" className="mt-2" placeholder="Options with comma" onChange={(e) => this.onChangeAddQues("options", e.target.value)} value={addQuesDetails.options} required />}
                                    </Col>
                                    {/* <Col xs={2}> 
                                        <div style={{marginTop: "7px"}}>
                                            <Icon className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>done</Icon> 
                                            <Icon style={{cursor: "pointer", fontSize: "16px"}} onClick={() => this.setState({isOpenAddQues : false})}>close</Icon>
                                        </div>
                                    </Col> */}

                                </Row>
                                <Row className="mt-3">
                                    <Col>
                                        <Button id="ques_cancel" type="button" color="secondary" className="float-right" onClick={()=> {this.setState({isOpenAddQues : false})}} style={{fontSize: "12px",height: "23px", paddingTop: "1px"}}>Cancel</Button>
                                        <Button id="ques_save" type="submit" className="buttonred float-right mr-2" style={{height: "23px", paddingTop: "1px"}}>Save</Button>{' '}
                                    </Col>
                                </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Collapse>
                </Col>
            </Row>
            <Row className="mt-3 border-bottom pb-1 mb-1">
                <Col xs={1} ><h6>S.No</h6> </Col>
                <Col xs={2} ><h6>State</h6> </Col>
                <Col xs={2}> <h6>Type</h6> </Col>
                <Col xs={4} > <h6>Question Heading</h6> </Col>
                <Col xs={2} > <h6>Mandatory ?</h6> </Col>
                <Col xs={1}> <h6>Action</h6> </Col>
            </Row>

            {quesList.map((question , i)=>
                <Row key={i} className="mb-1">
                    <Col xs={1} style={{fontSize: "14px"}}>{i+1}</Col>
                    <Col xs={2} style={{fontSize: "14px"}}>{CONSTANTS.JOB_QUESTION_STATES[question.question_category]}</Col>
                    <Col xs={2} style={{fontSize: "14px"}}>{question.question_type}</Col>
                    <Col xs={4} style={{fontSize: "14px"}} >
                        <div dangerouslySetInnerHTML={{__html : question.heading}} className="richtext-view"></div>
                        {["DROPDOWN", "CHECKBOX", "RADIO"].indexOf(addQuesDetails.ques_type) > -1 ? <span className="" style={{fontSize : 10}}>( {(question.options || "").split(";;").join(", ")} )</span>:""}
                    </Col>
                    <Col xs={2}>{question.is_mandatory ? "Yes" : "No"}</Col>
                    <Col xs={1}> 
                        {/* <Icon className="mr-1" style={{cursor: "pointer", fontSize: "16px"}}>edit</Icon>  */}
                        <Icon id={"ques_delete" + i} className="ml-2" onClick={()=> {this.setState({isConfirm :  () => this.deletedJobQues(question.job_question_id)})}} style={{cursor: "pointer", fontSize: "16px"}}>delete</Icon>
                    </Col>
                </Row>
            )
            }

            <ConfirmAction isOpen={!!isConfirm} onClose={() => this.setState({isConfirm : false})} callback={isConfirm} />
                        
        </div>
        


        );
    }
}

export default JobQuestion;