import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../../constants/api_endpoints";
import store from "../../../../store";
import { setError, setSuccess, setLoader } from "../../../../actions/action-creators";
import LineChart from "../../../global/form/line_chart";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Table from "reactstrap/lib/Table";

let moment = require("moment");


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortAttendance : {},
            referralData: {},
            referralChartCycle: "W",
            isOpenReferralData : false,
            referralDetails : []
        }
    }
    async componentDidMount(){
      this.getReferralData();
    }
   
    getReferralData = async () => {
      store.dispatch(setLoader(true))
        var days = this.state.referralChartCycle === "W" ? 7 : 30;
        try{
          var body = {
              start_date : moment().subtract(days, "days").toDate(),
          }
          let resp = await ApiEndpoints.Referral.getCountByChannelAndDate(body);
          let referralData = resp.data.data;
          this.setState({referralData });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false));
    }
    openLeadChartData = async (axisLabel, axisValue) => {
      store.dispatch(setLoader(true));
      try{
        var body = {
          channel_subtype : axisLabel,
          start_date: moment(axisValue).startOf("day").toDate(),
          end_date: moment(axisValue).endOf("day").toDate()
        }
        let resp = await ApiEndpoints.Referral.getReferralsByChannel(body);
        console.log(resp.data.data)

        this.setState({referralDetails: resp.data.data});
      }
      catch(e){
        store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
      }
      store.dispatch(setLoader(false));

      this.setState({ isOpenReferralData : {axisLabel, axisValue} })
    }
    render() {
      let { referralData, isOpenReferralData, referralDetails } = this.state;
      
        return (
            <div className="p-3">
              

                  <Row>
                    <Col xs={8}>
                      <h4 className="border-bottom my-3">Referral Data</h4>
                      <div className="mt-3">
                        <div className="text-right">
                          <Button onClick={async () => {await this.setState({referralChartCycle: "M"}); this.getReferralData()}} className="py-0 px-1" color={this.state.referralChartCycle === "M" ? "dark": "light"} size="sm">Month</Button>
                          <Button onClick={async () => {await this.setState({referralChartCycle: "W"}); this.getReferralData()}}className="ml-2 px-1 py-0" color={this.state.referralChartCycle === "W" ? "dark": "light"} size="sm">Week</Button>
                        </div>
                        <LineChart data = {referralData} dataKey={"count"} axisKey={"date"} chartTitle={"Referral Data by Channel and date"} onClick={this.openLeadChartData}/>
                        <Modal isOpen={!!isOpenReferralData} toggle={() => this.setState({isOpenReferralData: false})}>
                          <ModalHeader toggle={() => this.setState({isOpenReferralData: false})}><h5>{isOpenReferralData.axisLabel}</h5></ModalHeader>
                          <ModalBody>
                            <Table>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Name</th>
                                  <th>Mobile</th>
                                  <th>PMW ID</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  referralDetails.map((data, i) => 
                                    <tr>
                                      <td>{i+1}</td>
                                      <td>{data.User.first_name}</td>
                                      <td>{data.User.mobile}</td>
                                      <td>PMW{data.User.id}</td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </Table>
                          </ModalBody>
                        </Modal>
                      </div>
                    </Col>
                  </Row>
               
               
            </div>
            
            
        );
    }
}
export default Dashboard;
